import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchData } from '../../hooks/useFetchData';
import { equiposProvider } from '../../api';
import Button from '../common/Button';
import NewEquipoDialog from '../equipos/NewEquipoDialog';
import AddIcon from '@material-ui/icons/Add';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import BaseEquiposList from '../equipos/BaseEquiposList';

export default function EquiposList() {
    const { id } = useParams();
    const fetchDataFn = useCallback(() => equiposProvider.getByInstalacion(id), []);
    const { data, fetchData } = useFetchData(fetchDataFn);
    const { data: proyecto } = useEditFormData();

    const options = useMemo(
        () => ({
            batchComponents: (
                <NewEquipoDialog
                    clienteId={proyecto.cliente_id}
                    direccionId={proyecto.direccion_id}
                    instalacionId={id}
                    button={
                        <Button color='info' aria-label='Añadir equipo' startIcon={<AddIcon />}>
                            Añadir equipo
                        </Button>
                    }
                    onSave={fetchData}
                />
            ),
        }),
        [],
    );

    return (
        <BaseEquiposList
            hideInstalacion
            hideCliente
            tableId='equipos-instalacion'
            data={data}
            fetchData={fetchData}
            extraOptions={options}
        />
    );
}
