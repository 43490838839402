import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Field, useField } from 'formik';
import SelectField from '../../../common/fields/SelectField';
import { tipoEnvioOptions } from '../../../../api/companies';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { SwitchWithLabel } from '../../../common/fields/Switch';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useAuthState from '../../../../AuthState';

const useStyles = makeStyles(
    (theme) => ({
        notificacion: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
        },
        row: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'AjustesNotificaciones' },
);

export default function AjustesNotificaciones({ classes, values }) {
    const localClasses = useStyles();

    const {
        userInfo: {
            preferencias: {
                usar_solicitudes_material: usarSolicitudesMaterial,
                usar_incidencias: usarIncidencias,
            } = {},
        },
    } = useAuthState();

    const [, , { setValue: setTipoEnvioCopiaAlbaran }] = useField('tipo_envio_email_copia_albaran');

    return (
        <Paper elevation={0}>
            <Typography className={classes.title}>Notificaciones por email a administración</Typography>
            <Grid container spacing={4} className={classes.content}>
                <Grid item xs={12} className={localClasses.notificacion}>
                    <SwitchWithLabel
                        label='Recibir un email cuando un operario genera un albarán de servicio'
                        field={{
                            onChange: (ev, checked) => {
                                setTipoEnvioCopiaAlbaran(checked ? 'SIEMPRE' : 'NUNCA');
                            },
                            value: values.tipo_envio_email_copia_albaran !== 'NUNCA',
                        }}
                    />
                    {values.tipo_envio_email_copia_albaran !== 'NUNCA' && (
                        <div className={localClasses.row}>
                            <Field
                                name='tipo_envio_email_copia_albaran'
                                id='tipo_envio_email_copia_albaran'
                                component={SelectField}
                                label='¿Qué tipo de albaranes quieres recibir?'
                                fullWidth
                                selectOptions={tipoEnvioOptions}
                            />
                            <TextField
                                name='email_copia_albaran_list'
                                label='Enviar a esta dirección de email'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                placeholder='Escribe una dirección de email o varias separadas por comas'
                            />
                        </div>
                    )}
                </Grid>
                <Grid item xs={12} className={localClasses.notificacion}>
                    <Field
                        name='enviar_email_salir_servicio'
                        component={SwitchWithLabel}
                        label='Recibir un email cuando un operario sale de un servicio'
                    />
                    {values.enviar_email_salir_servicio && (
                        <TextField
                            name='email_salir_servicio_list'
                            label='Enviar a esta dirección de email'
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            placeholder='Escribe una dirección de email o varias separadas por comas'
                        />
                    )}
                </Grid>
                <Grid item xs={12} className={localClasses.notificacion}>
                    <Field
                        name='enviar_email_cerrar_servicio'
                        component={SwitchWithLabel}
                        label='Recibir un email cuando un operario cierra un servicio'
                    />
                    {values.enviar_email_cerrar_servicio && (
                        <TextField
                            name='email_cerrar_servicio_list'
                            label='Enviar a esta dirección de email'
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            placeholder='Escribe una dirección de email o varias separadas por comas'
                        />
                    )}
                </Grid>
                <Grid item xs={12} className={localClasses.notificacion}>
                    <Field
                        name='enviar_email_solicitud_vacaciones_ausencias'
                        component={SwitchWithLabel}
                        label='Recibir un email cuando un operario solicita vacaciones o registra ausencias'
                    />
                    {values.enviar_email_solicitud_vacaciones_ausencias && (
                        <TextField
                            name='email_solicitud_vacaciones_ausencias_list'
                            label='Enviar a esta dirección de email'
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            placeholder='Escribe una dirección de email o varias separadas por comas'
                        />
                    )}
                </Grid>
                {usarSolicitudesMaterial && (
                    <Grid item xs={12} className={localClasses.notificacion}>
                        <Field
                            name='enviar_email_solicitud_material'
                            component={SwitchWithLabel}
                            label='Recibir un email cuando un operario solicita material para un servicio'
                        />
                        {values.enviar_email_solicitud_material && (
                            <TextField
                                name='email_solicitud_material_list'
                                label='Enviar a esta dirección de email'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                placeholder='Escribe una dirección de email o varias separadas por comas'
                            />
                        )}
                    </Grid>
                )}
                {usarIncidencias && (
                    <Grid item xs={12} className={localClasses.notificacion}>
                        <Field
                            name='enviar_email_incidencia'
                            component={SwitchWithLabel}
                            label='Recibir un email cuando un operario registra una incidencia en un servicio'
                        />
                        {values.enviar_email_incidencia && (
                            <TextField
                                name='email_incidencia_list'
                                label='Enviar a esta dirección de email'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                placeholder='Escribe una dirección de email o varias separadas por comas'
                            />
                        )}
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
}

AjustesNotificaciones.propTypes = {
    classes: PropTypes.any,
    file: PropTypes.any,
    values: PropTypes.any,
};
