import Grid from '@material-ui/core/Grid';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { documentosProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import OperariosField from '../common/fields/OperariosField';
import DialogForm from '../common/forms/DialogForm';

const DocumentosSchema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
    descripcion: Yup.string(),
});

function NewCarpetaDialog({ parentId, onSave, ...props }) {
    const snackbar = useSnackbar();

    return (
        <DialogForm
            title='Añadir carpeta'
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    nombre: '',
                    descripcion: '',
                    parent_id: parentId,
                    publica: true,
                    operarios: [],
                },
                validationSchema: DocumentosSchema,
                onSubmit: ({ publica, ...values }, { setSubmitting, setFieldError }) => {
                    if (publica) values.operarios = [];

                    documentosProvider
                        .saveCarpeta(values)
                        .then((data) => {
                            onSave && onSave(data.id);
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                if (err.message instanceof Object) {
                                    for (const [field, errors] of Object.entries(err.message)) {
                                        setFieldError(field, errors.join('\n'));
                                    }
                                } else {
                                    snackbar.showMessage(err.message);
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {({ setFieldValue, values }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField name='nombre' label='Nombre' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name='descripcion' label='Descripcion' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <CheckboxWithLabel
                            type='checkbox'
                            name='publica'
                            Label={{
                                label: 'Visible para todos los operarios',
                            }}
                        />
                    </Grid>
                    {!values.publica && (
                        <Grid item xs={12}>
                            <OperariosField />
                        </Grid>
                    )}
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(NewCarpetaDialog);

NewCarpetaDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    parentId: PropTypes.any,
};
