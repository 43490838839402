import { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogEditor from '../../common/forms/DialogEditor';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import GenerarAlbaranItem from './GenerarAlbaranItem';
import Typography from '@material-ui/core/Typography';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 600,
        },
        body: {
            gap: `${theme.spacing(2)}px`,
        },
        title: {
            color: theme.palette.neutral.grey4,
        },
        selectAll: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 13,
            '& .MuiCheckbox-root': {
                padding: 0,
                marginRight: theme.spacing(0.5),
            },
        },
    }),
    { name: 'GenerarAlbaranDialog' },
);

function SelectJornadasSinAlbaranDialog({ title, saveButtonText, onSave, itemTitleFn = null, ...props }) {
    const classes = useStyles();
    const [selectedJornadas, setSelectedJornadas] = useState([]);
    const { data: tarea } = useEditFormData();

    useEffect(() => {
        if (!props.open) return;

        setSelectedJornadas([]);
    }, [props.open]);

    const handleCheckJornada = (id) => (checked) => {
        if (checked) {
            setSelectedJornadas((selectedJornadas) =>
                selectedJornadas.includes(id) ? selectedJornadas : [...selectedJornadas, id],
            );
        } else {
            setSelectedJornadas((selectedJornadas) => selectedJornadas.filter((j) => j !== id));
        }
    };

    const jornadas = tarea.jornadas.filter((jornada) => jornada.albaran_id === null);

    function selectAllOrNothing(selectAll) {
        setSelectedJornadas(selectAll ? jornadas.map((j) => j.id) : []);
    }

    return (
        <DialogEditor
            title={title}
            onSave={() => onSave(selectedJornadas).then(props.onClose)}
            classes={{
                root: classes.root,
                body: classes.body,
            }}
            canSave={selectedJornadas.length > 0}
            saveButtonText={saveButtonText}
            {...props}
        >
            <Typography variant='subtitle1' className={classes.title}>
                Selecciona los partes de trabajo que quieras añadir al albarán de servicio.
            </Typography>
            <label className={classes.selectAll}>
                <Checkbox
                    checked={selectedJornadas.length === jornadas.length}
                    onClick={(ev) => selectAllOrNothing(ev.target.checked)}
                    indeterminate={selectedJornadas.length > 0 && selectedJornadas.length < jornadas.length}
                    color='primary'
                />
                <Typography variant='subtitle1'>Seleccionar todos</Typography>
            </label>
            {jornadas.map((jornada, index) => (
                <GenerarAlbaranItem
                    key={index}
                    jornada={jornada}
                    checked={selectedJornadas.includes(jornada.id)}
                    onClick={handleCheckJornada(jornada.id)}
                    itemTitleFn={itemTitleFn}
                />
            ))}
        </DialogEditor>
    );
}

SelectJornadasSinAlbaranDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    open: PropTypes.any,
    saveButtonText: PropTypes.string,
    title: PropTypes.string,
    itemTitleFn: PropTypes.func,
};

export default withButtonOpener(SelectJornadasSinAlbaranDialog);
