import { Dialog } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { eventosProvider } from '../../../api';
import { entityAccionLabels } from '../../../api/log_eventos';
import { useFetchData } from '../../../hooks/useFetchData';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import { formatFullDateTime } from '../../../utils';
import { DateColumnFilter, dateFilterFn } from '../../common/list/DateColumnFilter';
import ListView from '../../common/list/ListView';

function EventosDialog({ entityId, ...props }) {
    const fetchDataFn = useCallback(() => eventosProvider.getAllForEntity(entityId), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    useEffect(() => {
        if (!props.open) return;

        fetchData();
    }, [props.open, fetchData]);

    const columns = useMemo(
        () => [
            {
                Header: 'Fecha',
                accessor: 'fecha',
                Cell: ({ value }) => formatFullDateTime(new Date(value), true),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Usuario',
                accessor: 'user.username',
            },
            {
                Header: 'Acción',
                accessor: (row) => {
                    const label = entityAccionLabels[row.accion];

                    if (typeof label === 'function') {
                        return label(row);
                    }

                    return label ?? row.accion;
                },
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: false,
            tableOptions: {
                fillEmptySpace: true,
            },
        }),
        [],
    );

    return (
        <Dialog fullWidth maxWidth='md' {...props}>
            <DialogTitle disableTypography>
                <Typography variant='h6' style={{ flex: 1 }}>
                    Registro de actividad
                </Typography>
                <IconButton onClick={props.onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <ListView basePath='/eventos' columns={columns} data={data} options={options} />
        </Dialog>
    );
}

EventosDialog.propTypes = {
    entityId: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

export default withButtonOpener(EventosDialog);
