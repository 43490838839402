import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MuiTextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DescriptionIcon from '@material-ui/icons/Description';
import Alert from '@material-ui/lab/Alert';
import { Field, useField, useFormikContext } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { cuestionariosProvider } from '../../api';
import { SelectEquipo } from './SelectEquipo';

const Table = withStyles(
    (theme) => ({
        root: {
            margin: theme.spacing(1, 0),
        },
    }),
    { name: 'Table' },
)(MuiTable);

const TableCell = withStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(1, 2),
            borderBottom: `1px solid ${theme.palette.neutral.grey1}`,
            '.MuiTableHead-root &': {
                color: theme.palette.neutral.primary,
                borderBottom: `1px solid ${theme.palette.neutral.grey1}`,
                fontWeight: theme.typography.fontWeightRegular,
            },
            '&.underlined': {
                borderBottom: `1px solid ${theme.palette.neutral.grey1}`,
            },
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

const useStyles = makeStyles(
    (theme) => ({
        root: {
            gap: `${theme.spacing(2)}px`,
            overflowY: 'auto',
        },
        actions: {
            width: 64,
        },
        table: {
            margin: theme.spacing(3, -3),
            width: `calc(100% + ${theme.spacing(3) * 2}px)`,
        },
        equipoCell: {
            verticalAlign: 'top',
        },
        emptyRow: {
            '& .MuiTableCell-root': {
                padding: theme.spacing(2),
            },
        },
        cuestionarioSwitch: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(1)}px`,
        },
        disabledRow: {
            '& .MuiTableCell-root': {
                color: theme.palette.neutral.primary,
            },
        },
        fixedCol: {
            width: 300,
        },
        empty: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            margin: 'auto',
            gap: `${theme.spacing(3)}px`,
            width: 430,
        },
        emptyText: {
            color: theme.palette.neutral.grey4,
        },
        preventivo: {
            padding: theme.spacing(2),
            borderRadius: 8,
            background: theme.palette.neutral.grey3,
            display: 'flex',
            '& .MuiSvgIcon-root': {
                marginRight: theme.spacing(0.5),
            },
            '& .MuiTypography-body2': {
                fontWeight: theme.typography.fontWeightMedium,
            },
            '& .MuiTypography-subtitle1': {
                color: theme.palette.neutral.grey4,
            },
            '& .MuiTypography-subtitle1 strong': {
                color: theme.palette.text.primary,
                fontWeight: theme.typography.fontWeightMedium,
                marginRight: theme.spacing(0.5),
            },
        },
    }),
    { name: 'EquiposForm' },
);

export function EquiposForm({ disabled, style }) {
    const classes = useStyles();

    const [cuestionarioOptions, setCuestionarioOptions] = useState([]);
    useEffect(() => {
        cuestionariosProvider.getOnlyActiveAsOptions().then((cuestionarios) =>
            setCuestionarioOptions(
                cuestionarios.map(({ tipos_equipo: tipos, ...c }) => ({
                    ...c,
                    tiposEquipo: tipos.map((t) => t.id),
                })),
            ),
        );
    }, []);

    const { values } = useFormikContext();
    const [{ value: partesMantenimiento }, , { setValue }] = useField('partes_mantenimiento');
    const [{ value: mantenimientoPreventivo }, ,] = useField('mantenimientoPreventivo');

    const hasDatosMinimos = values.direccion?.id;

    const fixedEquipoIds = partesMantenimiento.filter((p) => p.respuestas?.length).map((p) => p.equipo_id);

    if (mantenimientoPreventivo !== null)
        return (
            <div style={style}>
                <div className={classes.preventivo}>
                    <DescriptionIcon color='primary' />
                    <div>
                        <Typography variant='body2'>Mantenimiento preventivo programado</Typography>
                        <Typography variant='subtitle1'>
                            <strong>Instalación:</strong>
                            {mantenimientoPreventivo.contrato_mantenimiento.instalacion}
                        </Typography>
                        <Typography variant='subtitle1'>
                            <strong>Contrato:</strong>
                            {mantenimientoPreventivo.contrato_mantenimiento.nombre}
                        </Typography>
                        <Typography variant='subtitle1'>
                            <strong>Equipos a revisar:</strong>
                            {mantenimientoPreventivo.tipos_equipo.map((t) => t.nombre).join(', ')}
                        </Typography>
                        <Typography variant='subtitle1'>
                            <strong>Tareas a realizar:</strong>
                            {mantenimientoPreventivo.cuestionarios.map((t) => t.nombre).join(', ')}
                        </Typography>
                    </div>
                </div>
            </div>
        );

    return (
        <div style={style}>
            {hasDatosMinimos ? (
                <>
                    <SelectEquipo
                        disabled={disabled}
                        direccionId={values.direccion?.id}
                        fixedEquipoIds={fixedEquipoIds}
                        onChange={(equipos) => {
                            setValue(
                                equipos.map(
                                    (equipo) =>
                                        partesMantenimiento.find((p) => p.equipo_id === equipo.id) || {
                                            equipo_id: equipo.id,
                                            equipo,
                                            cuestionarios: [],
                                        },
                                ),
                            );
                        }}
                        defaultEquipos={partesMantenimiento.map((parte) => parte.equipo_id)}
                    />
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.fixedCol}>Equipo</TableCell>
                                <TableCell>Cuestionarios</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {partesMantenimiento.length === 0 && (
                                <TableRow className={classes.emptyRow}>
                                    <TableCell colSpan={2}>
                                        <Alert severity='info'>
                                            Este servicio no tiene equipos asignados para realizar mantenimiento
                                        </Alert>
                                    </TableCell>
                                </TableRow>
                            )}
                            {partesMantenimiento.map((parte, i) => (
                                <ParteMantenimientoField
                                    key={i}
                                    index={i}
                                    disabled={disabled}
                                    cuestionarioOptions={cuestionarioOptions}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </>
            ) : (
                <div className={classes.empty}>
                    <img src='/images/empty-partes-trabajo.png' />
                    <Typography variant='h1'>Mantenimiento de equipos</Typography>
                    <Typography variant='body2' className={classes.emptyText}>
                        Tras seleccionar la dirección de un cliente, podrás elegir los equipos a los que realizar
                        mantenimiento en este servicio.
                    </Typography>
                </div>
            )}
        </div>
    );
}

function ParteMantenimientoField({ index, disabled, cuestionarioOptions }) {
    const classes = useStyles();
    const [{ value: parteMantenimiento }, ,] = useField(`partes_mantenimiento.${index}`);

    const hasRespuestas = Boolean(parteMantenimiento.respuestas?.length);

    const tipoEquipoId = parteMantenimiento.equipo.tipo_equipo_id;

    return (
        <TableRow className={hasRespuestas ? classes.disabledRow : null}>
            <TableCell>{parteMantenimiento.equipo.nombre}</TableCell>
            <TableCell>
                <Field
                    name={`partes_mantenimiento.${index}.cuestionarios`}
                    fullWidth
                    component={Autocomplete}
                    options={cuestionarioOptions.filter((c) => c.tiposEquipo.includes(tipoEquipoId))}
                    getOptionSelected={(option, value) => option.id === value?.id}
                    getOptionLabel={(option) => option.nombre ?? ''}
                    disabled={disabled}
                    renderInput={(params) => (
                        <MuiTextField
                            {...params}
                            placeholder={
                                parteMantenimiento.cuestionarios.length === 0 ? 'Ningún cuestionario a rellenar' : ''
                            }
                        />
                    )}
                    multiple
                />
            </TableCell>
        </TableRow>
    );
}

EquiposForm.propTypes = {
    disabled: PropTypes.any,
    style: PropTypes.any,
};

ParteMantenimientoField.propTypes = {
    cuestionarioOptions: PropTypes.any,
    disabled: PropTypes.any,
    index: PropTypes.any,
};
