import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import { useCallback, useEffect, useMemo } from 'react';
import { companiesProvider } from '../../../api';
import { tipoUsuarioLabels, tipoUsuarioOptions } from '../../../api/companies';
import { useFetchData } from '../../../hooks/useFetchData';
import { SwitchWithLabel } from '../../common/fields/Switch';
import BaseListView, { withTableState } from '../../common/list/ListView';
import { multipleSelectFilterFn, SelectColumnFilter } from '../../common/list/SelectColumnFilter';
import DatosAccesoDialog from '../../operarios/DatosAccesoDialog';

const ListView = withTableState('usuarios', BaseListView);

export default function UsuariosTab() {
    const fetchDataFn = useCallback(() => companiesProvider.getAll('users'), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const columns = useMemo(
        () => [
            {
                Header: 'Nombre de usuario',
                accessor: 'username',
                show: false,
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Tipo de usuario',
                accessor: 'tipo',
                Cell: ({ value }) => tipoUsuarioLabels[value],
                Filter: SelectColumnFilter,
                filter: multipleSelectFilterFn,
                filterOptions: {
                    options: tipoUsuarioOptions,
                    multiple: true,
                },
                exportValue: ({ value }) => tipoUsuarioLabels[value],
            },
            {
                Header: 'Activo',
                accessor: 'activo',
                // eslint-disable-next-line react/prop-types
                Cell: ({ value }) => (value ? <DoneIcon /> : null),
                filter: 'equals',
                disableGlobalFilter: true,
                disableFilters: true,
                disableSortBy: true,
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            tableOptions: {
                extraFilters: (setFilter, tableColumns) => {
                    const column = tableColumns.find((c) => c.id === 'activo');
                    const showingAll = column.filterValue === undefined;

                    return (
                        <SwitchWithLabel
                            label='Mostrar solo los usuarios activos'
                            field={{
                                name: 'solo-usuarios-activos',
                                onChange: () => column.setFilter(showingAll ? true : undefined),
                                value: !showingAll,
                            }}
                        />
                    );
                },
                initialState: {
                    filters: [{ id: 'activo', value: true }],
                },
            },
        }),
        [],
    );

    return (
        <ListView
            tableTitle='Lista de usuarios'
            basePath='/usuarios'
            columns={columns}
            data={data}
            options={options}
            extraActions={(row) => (
                <>
                    <DatosAccesoDialog
                        user={row}
                        onSave={fetchData}
                        button={
                            <Tooltip arrow title='Editar datos de acceso'>
                                <IconButton size='small'>
                                    <EditIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        }
                    />
                </>
            )}
        />
    );
}
