import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import MuiToggleButton from '@material-ui/lab/ToggleButton';
import MuiToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { forwardRef } from 'react';

export const ToggleButtonGroup = withStyles(
    (theme) => ({
        grouped: {},
    }),
    { name: 'ToggleButtonGroup' },
)(MuiToggleButtonGroup);

// eslint-disable-next-line react/prop-types
function TooltipToggleButton({ TooltipProps = { title: '' }, ...props }, ref) {
    return (
        <Tooltip {...TooltipProps}>
            <MuiToggleButton ref={ref} {...props} />
        </Tooltip>
    );
}

// TooltipToggleButton.propTypes = {
//     TooltipProps: PropTypes.object,
// };

export const ToggleButton = withStyles(
    (theme) => ({
        root: {
            textTransform: 'capitalize',
            padding: theme.spacing(0.75, 2),
            height: 32,
            '&.Mui-selected, &.Mui-selected:active, &.Mui-selected:focus': {
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.hover,
            },
        },
    }),
    { name: 'ToggleButton' },
)(forwardRef(TooltipToggleButton));
