import { useDroppable } from '@dnd-kit/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import addDays from 'date-fns/addDays';
import PropTypes from 'prop-types';
import { formatISODate } from '../../utils';
import { groupByToKey } from '../agenda/useTransformEvents';
import { SIN_ASIGNAR, useCalendarioPlanificacion } from './CalendarioContent';
import { EVENTO_DRAGGABLE_TYPE } from './Evento';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            margin: '-4px -2px',
        },
        noLaborable: {
            backgroundColor: theme.palette.error.backgroundTint,
        },
    }),
    { name: 'DayPlaceholder' },
);

export const DAY_DROPPABLE_TYPE = 'DAY';
export default function DayPlaceholder({ group, day, numRows, columnStart, noLaborables }) {
    const classes = useStyles();
    const groupId = group ? group.id : SIN_ASIGNAR;
    const { startDate } = useCalendarioPlanificacion();
    const thisDate = addDays(startDate, day);
    const thisDateStr = formatISODate(thisDate);
    const { setNodeRef, isOver, active } = useDroppable({
        id: `${groupId}:${day}`,
        data: {
            type: DAY_DROPPABLE_TYPE,
            groupId,
            group,
            day,
            date: thisDate,
        },
    });

    const isNoLaborable = noLaborables && noLaborables.includes(thisDateStr);

    const currentActive = active?.data?.current;
    const overStyle =
        isOver &&
        currentActive &&
        currentActive.type === EVENTO_DRAGGABLE_TYPE &&
        (currentActive.entity.offset !== day ||
            currentActive.entity[groupByToKey[currentActive.entity.groupBy]] !== groupId)
            ? {
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  borderRadius: 4,
                  zIndex: 9999,
              }
            : {};

    return (
        <div
            className={clsx(classes.root, isNoLaborable ? classes.noLaborable : null)}
            ref={setNodeRef}
            style={{
                gridColumnStart: columnStart,
                gridColumnEnd: 'span 1',
                gridRowStart: 1,
                gridRowEnd: `span ${numRows}`,
                ...overStyle,
            }}
        ></div>
    );
}

DayPlaceholder.propTypes = {
    group: PropTypes.any,
    day: PropTypes.any,
    numRows: PropTypes.number,
    columnStart: PropTypes.number,
    noLaborables: PropTypes.array,
};
