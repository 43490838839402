import EventIcon from '@material-ui/icons/Event';
import PartesTrabajoIcon from '@material-ui/icons/FileCopy';
import VacacionesIcon from '../icons/Vacaciones';
import AusenciaIcon from '@material-ui/icons/Feedback';
import FichajesIcon from '@material-ui/icons/Timer';
import PendienteIcon from '@material-ui/icons/Timelapse';
import RevisadoIcon from '../icons/Revisado';
import VerificadoIcon from '@material-ui/icons/VerifiedUser';

export const defaultColor = '#5B7995';

export const colors = [
    '#ECBB02',
    '#FDA729',
    '#F69DB3',
    '#FEAA93',
    '#EB354B',
    '#FD5627',
    '#FE2191',
    '#EE46D3',
    '#AB5DE6',
    '#47D9FC',
    '#14AAF5',
    '#4E8EE3',
    '#0064FB',
    '#00B5CE',
    '#30BDA1',
    '#A3D116',
];

export const eventIcons = {
    agenda: EventIcon,
    jornada: PartesTrabajoIcon,
    vacaciones: VacacionesIcon,
    ausencia: AusenciaIcon,
    fichaje: FichajesIcon,
};

export const fichajeEstadoIcons = {
    PENDIENTE: PendienteIcon,
    REVISADO: RevisadoIcon,
    VERIFICADO: VerificadoIcon,
};
