import { Route, Switch } from 'react-router-dom';
import { usePreferencias } from '../../AuthState';
import FichajesList from './FichajesList';
import RevisionFichajes from './RevisionFichajes';

export default function FichajesModule() {
    const onError = (err) => console.error(err);

    const usarNuevaRevisionFichajes = usePreferencias('usar_nueva_revision_fichajes');

    return (
        <>
            <Switch>
                <Route path='/fichajes'>
                    {usarNuevaRevisionFichajes ? (
                        <RevisionFichajes onError={onError} />
                    ) : (
                        <FichajesList onError={onError} />
                    )}
                </Route>
            </Switch>
        </>
    );
}
