import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import React from 'react';

export function LineasOptionsButton({
    onSetAllExpanded,
    onSetCapitulosExpanded,
    showDescripciones,
    setShowDescripciones,
}) {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

    const openMenu = (event) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
    };

    const closeMenu = (event) => {
        event && event.stopPropagation();
        setMenuAnchorEl(null);
    };

    function renderMenuItem(label, onClick) {
        return (
            <MenuItem
                onClick={(ev) => {
                    closeMenu(ev);
                    onClick();
                }}
            >
                {label}
            </MenuItem>
        );
    }

    return (
        <>
            <IconButton onClick={openMenu}>
                <MoreVertIcon />
            </IconButton>
            <Menu id='simple-menu' anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={closeMenu}>
                {renderMenuItem(showDescripciones ? 'Ocultar descripciones' : 'Mostrar descripciones', () =>
                    setShowDescripciones(!showDescripciones),
                )}
                {renderMenuItem('Colapsar todo', () => onSetAllExpanded(false))}
                {renderMenuItem('Expandir todo', () => onSetAllExpanded(true))}
                {renderMenuItem('Colapsar partidas', () => onSetCapitulosExpanded(false))}
                {renderMenuItem('Expandir partidas', () => onSetCapitulosExpanded(true))}
            </Menu>
        </>
    );
}

LineasOptionsButton.propTypes = {
    onSetAllExpanded: PropTypes.func.isRequired,
    showDescripciones: PropTypes.bool.isRequired,
    setShowDescripciones: PropTypes.func.isRequired,
    onSetCapitulosExpanded: PropTypes.func.isRequired,
};
