import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import sub from 'date-fns/sub';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import add from 'date-fns/add';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SelectFecha from '../solicitudes/SelectFecha';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 14,
            color: '#8F95AF',
            padding: theme.spacing(2, 3),
            marginBottom: theme.spacing(2),
        },
        helper: {
            marginTop: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
        },
        button: {
            textTransform: 'none',
            textDecoration: 'underline',
            color: 'white',
            padding: '1px 4px',
        },
    }),
    { name: 'FiltersFecha' },
);

export default function FiltersFecha({ selectedDate, setSelectedDate }) {
    const classes = useStyles();

    return (
        <>
            <Paper className={classes.root} elevation={0}>
                En el dia
                <IconButton onClick={() => setSelectedDate((date) => sub(date, { days: 1 }))}>
                    <NavigateBeforeIcon />
                </IconButton>
                <SelectFecha fecha={selectedDate} onChange={setSelectedDate} canChangeDay />
                <IconButton onClick={() => setSelectedDate((date) => add(date, { days: 1 }))}>
                    <NavigateNextIcon />
                </IconButton>
            </Paper>
        </>
    );
}

FiltersFecha.propTypes = {
    selectedDate: PropTypes.any,
    setSelectedDate: PropTypes.any,
};
