import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { proveedoresProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { formatDate } from '../../utils';
import DialogEditor from '../common/forms/DialogEditor';
import { TableCell } from '../presupuestos/PresupuestoView/Table';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 800,
        },
        body: {
            padding: 0,
        },
        noDownward: {
            marginLeft: 32,
        },
        observacionesCell: {
            width: '100%',
            whiteSpace: 'pre-wrap',
        },
    }),
    { name: 'AlbaranCompraLineaHistoricoPrecios' },
);

function AlbaranCompraLineaHistoricoPrecios({ proveedorId, material, lineaId, open, ...props }) {
    const classes = useStyles();
    const [precios, setPrecios] = useState([]);
    const snackbar = useSnackbar();

    useEffect(() => {
        if (!open) return;

        setPrecios([]);

        if (!material.material_interno_id) return;

        proveedoresProvider
            .getHistoricoPrecios(proveedorId, material.material_interno_id)
            .then((precios) => setPrecios(precios.filter((p) => p.id !== lineaId)))
            .catch((err) => {
                snackbar.showMessage(err.message);
            });
    }, [open]);

    return (
        <DialogEditor
            title={`Historico de precios para: ${material.descripcion}`}
            maxWidth='md'
            classes={{
                root: classes.root,
                body: classes.body,
            }}
            open={open}
            {...props}
        >
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Unidades</TableCell>
                        <TableCell>Precio</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {precios === null || precios.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={3}>No hay precios</TableCell>
                        </TableRow>
                    ) : (
                        precios.map((precio, idx) => (
                            <TableRow key={idx}>
                                <TableCell>{formatDate(precio.fecha_solicitud)}</TableCell>
                                <TableCell>{precio.unidades}</TableCell>
                                <TableCell>{precio.precio_unitario}</TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </DialogEditor>
    );
}

AlbaranCompraLineaHistoricoPrecios.propTypes = {
    proveedorId: PropTypes.any,
    material: PropTypes.any,
    lineaId: PropTypes.any,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.any,
};

export default withButtonOpener(AlbaranCompraLineaHistoricoPrecios);
