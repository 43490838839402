import { useRef } from 'react';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { capitalizeFirstLetter } from './utils';
import WeekHeader from './WeekHeader';
import Month from './Month';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            flex: '1',
            display: 'flex',
            maxHeight: '100%',
            overflow: 'hidden',
        },
        selectorContainer: {
            // marginTop: theme.spacing(2)
            // boxShadow: 'inset 0 0 10px #000000'
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        calendarContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            padding: `0 ${theme.spacing(1)}px`,
            // backgroundColor: 'white',
        },
        monthHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: `${theme.spacing(1)}px 0`,
        },
    }),
    { name: 'MonthCalendar' },
);

export function MonthCalendar({ month, year }) {
    const displayDate = new Date(year, month, 0);
    const calendar = useRef(null);
    const classes = useStyles();
    const dateTimeFormatted = moment(displayDate).format('MMMM YYYY');

    return (
        <div className={classes.calendarContainer}>
            <div className={classes.monthHeader}>
                <Typography variant='subtitle1'>{capitalizeFirstLetter(dateTimeFormatted)}</Typography>
            </div>
            <WeekHeader />
            <Month displayDate={displayDate} key={displayDate.toDateString()} readOnly={false} ref={calendar} />
        </div>
    );
}

MonthCalendar.propTypes = {
    month: PropTypes.any,
    year: PropTypes.any,
};
