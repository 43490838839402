import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { formatDate } from '../../utils';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import EditFormHeader from '../common/forms/EditForm/EditFormHeader';
import EditFormHeaderItem from '../common/forms/EditForm/EditFormHeaderItem';
import EditFormHeaderItemList from '../common/forms/EditForm/EditFormHeaderItemList';
import FormTabWrapper from '../common/forms/EditForm/FormTabWrapper';
import ClienteInfoTooltip from '../servicios/ClienteInfoTooltip';
import DireccionInfoTooltip from '../servicios/DireccionInfoTooltip';
import EditEquipoDialog from './EditEquipoDialog';
import { EditEquipoMenuOptions } from './EditEquipoMenuOptions';
import TrabajosEquipo from './TrabajosEquipo';

export function EditEquipoFormContent() {
    const { data, fetchData } = useEditFormData();

    return (
        <>
            <EditFormHeader
                label='Equipo'
                name={data.nombre}
                actions={
                    <>
                        <EditEquipoDialog
                            id={data.id}
                            button={
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            }
                            onSave={fetchData}
                        />
                        <EditEquipoMenuOptions />
                    </>
                }
            >
                <EditFormHeaderItemList fixedWidth>
                    <EditFormHeaderItem label='Referencia'>{data.referencia}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Nombre'>{data.nombre}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Marca'>{data.marca}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Modelo'>{data.modelo}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Número de serie'>{data.numero_serie}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Fin de garantía'>
                        {formatDate(data.fecha_fin_garantia)}
                    </EditFormHeaderItem>
                    <EditFormHeaderItem label='Tipo refrigerante/carburante'>
                        {data.tipo_refrigerante_carburante}
                    </EditFormHeaderItem>
                    <EditFormHeaderItem label='Potencia'>{`${data.potencia} W`}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Tipo de máquina'>{data.tipo_maquina}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Ubicación'>{data.ubicacion}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Cliente'>
                        <ClienteInfoTooltip id={data.cliente_id} label={data.cliente} />
                    </EditFormHeaderItem>
                    <EditFormHeaderItem label='Direccion'>
                        <DireccionInfoTooltip
                            direccion={data.direccion.direccion_completa}
                            label={data.direccion.nombre}
                        />
                    </EditFormHeaderItem>
                    <EditFormHeaderItem label='Instalación'>
                        {data.instalacion ? data.instalacion.nombre : null}
                    </EditFormHeaderItem>
                    <EditFormHeaderItem label='Activo'>{data.activo ? 'Si' : 'No'}</EditFormHeaderItem>
                </EditFormHeaderItemList>
            </EditFormHeader>

            <FormTabWrapper>
                <TrabajosEquipo
                    clienteId={data.cliente_id}
                    direccionId={data.direccion_id}
                    proyectoId={data.proyecto_id}
                />
            </FormTabWrapper>
        </>
    );
}
