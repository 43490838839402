import { Route, Switch } from 'react-router-dom';
import ClientesList from './ClientesList';
import CreateClienteForm from './CreateClienteForm';
import EditClienteForm from './EditClienteForm';

export default function ClientesModule() {
    const onError = (err) => console.error(err);

    return (
        <>
            <Switch>
                <Route
                    path='/clientes/create'
                    render={({ match }) => <CreateClienteForm basePath='/clientes' match={match} />}
                />
                <Route
                    path='/clientes/:id'
                    render={({ match }) => <EditClienteForm basePath='/clientes' match={match} onError={onError} />}
                />
                <Route path='/clientes'>
                    <ClientesList onError={onError} />
                </Route>
            </Switch>
        </>
    );
}
