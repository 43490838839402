import DialogForm from '../common/forms/DialogForm';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { companiesProvider } from '../../api';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import AuthService from '../auth/AuthService';
import useAuthState from '../../AuthState';
import API_ROOT from '../../api/api-config';

const ConfigurarHorquillaSchema = Yup.object().shape({
    dias_margen_renovacion_contrato_mantenimiento: Yup.number()
        .required('Requerido')
        .min(0, 'Tiene que ser un número positivo'),
});

const useStyles = makeStyles(
    (theme) => ({
        helper: {
            color: theme.palette.neutral.primary,
        },
    }),
    { name: 'ConfigurarHorquillaDialog' },
);

function ConfigurarHorquillaDialog({ onSave, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const {
        updateLoginInfo,
        userInfo: { preferencias: { dias_margen_renovacion_contrato_mantenimiento: diasMargen = 0 } = {} },
    } = useAuthState();

    return (
        <DialogForm
            title='Configurar aviso de renovaciones'
            maxWidth='xs'
            FormikProps={{
                initialValues: {
                    dias_margen_renovacion_contrato_mantenimiento: diasMargen,
                },
                enableReinitialize: true,
                validationSchema: ConfigurarHorquillaSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    companiesProvider
                        .savePreferencias({
                            ...values,
                        })
                        .then(() => {
                            const authService = new AuthService(API_ROOT);
                            authService.updateUserInfo().then(updateLoginInfo);
                            onSave && onSave(values.dias_margen_renovacion_contrato_mantenimiento);
                            snackbar.showMessage('Ajustes guardados con éxito');
                            props.onClose();
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='subtitle2' className={classes.helper}>
                            Con este formulario puedes configurar los días de margen hasta el vencimiento de un
                            contrato. De esta manera en la lista de contratos verás los que estan a punto de vencer
                            cuando falten menos dias de los que escribas aquí.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name='dias_margen_renovacion_contrato_mantenimiento'
                            type='number'
                            label='Días de margen'
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>días</InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(ConfigurarHorquillaDialog);

ConfigurarHorquillaDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};
