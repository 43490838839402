import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiTextField from '@material-ui/core/TextField';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { Autocomplete } from 'formik-material-ui-lab';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { operariosProvider, solicitudesProvider } from '../../api';
import { INCLUDE_ADMINISTRATIVOS } from '../../api/operarios';
import { solicitudEstadoSelectOptions } from '../../api/solicitudes';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { setBeginOfDay, setEndOfDay } from '../../utils';
import Button from '../common/Button';
import { DateRangePicker } from '../common/fields/DateRangePicker';
import SelectField from '../common/fields/SelectField';

const AusenciaSchema = Yup.object().shape({
    operario: Yup.mixed().required('Requerido'),
    motivo: Yup.string().required('Requerido'),
    fecha_inicio: Yup.date().typeError('La fecha de inicio debe ser una fecha').required('Requerido'),
    fecha_fin: Yup.date()
        .typeError('La fecha de fin debe ser una fecha')
        .required('Requerido')
        .min(Yup.ref('fecha_inicio'), 'La fecha de fin debe ser posterior a la de inicio'),
});

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 600,
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
    }),
    { name: 'CrearAusenciaDialog' },
);

function CrearAusenciaDialog({ open, onClose, onSave, styles }) {
    const classes = useStyles();
    const [operariosOptions, setOperariosOptions] = useState([]);

    useEffect(() => {
        if (!open) return;

        operariosProvider.getOnlyActiveAsOptions(INCLUDE_ADMINISTRATIVOS).then(setOperariosOptions);
    }, [open]);

    const today = setBeginOfDay(new Date());

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby='nueva-ausencia-title'
            open={open}
            classes={{
                paper: classes.root,
            }}
            fullWidth={false}
            maxWidth='lg'
        >
            <Formik
                initialValues={{
                    operario: null,
                    motivo: '',
                    allDay: true,
                    fecha_inicio: new Date(today),
                    fecha_fin: new Date(today),
                    estado: 'PENDIENTE',
                    retribuida: true,
                }}
                validationSchema={AusenciaSchema}
                onSubmit={(
                    // eslint-disable-next-line camelcase
                    { operario, allDay, fecha_inicio, fecha_fin, ...values },
                    { setSubmitting, setFieldError },
                ) => {
                    if (allDay) {
                        setBeginOfDay(fecha_inicio);
                        setEndOfDay(fecha_fin);
                    }

                    solicitudesProvider
                        .create({
                            tipo: 'AUSENCIA',
                            operario_id: operario.id,
                            operario: operario.nombre,
                            fecha_inicio,
                            fecha_fin,
                            ...values,
                        })
                        .then((updatedAusencia) => {
                            onClose();
                            setSubmitting(false);
                            onSave(updatedAusencia);
                        });
                }}
            >
                {({ isSubmitting, values, errors, touched, setFieldValue }) => {
                    return (
                        <Form className={classes.form}>
                            <DialogTitle id='parte-trabajo-title'>Añadir ausencia</DialogTitle>
                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field
                                            name='operario'
                                            fullWidth
                                            component={Autocomplete}
                                            options={operariosOptions}
                                            getOptionLabel={(option) => option.nombre}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            renderInput={(params) => (
                                                <MuiTextField
                                                    {...params}
                                                    error={touched.operario && !!errors.operario}
                                                    helperText={touched.operario && errors.operario}
                                                    label='Operario'
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField name='motivo' label='Motivo' fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DateRangePicker
                                            label='Fechas'
                                            allDay={values.allDay}
                                            fechaInicioKey='fecha_inicio'
                                            fechaFinKey='fecha_fin'
                                            autoAdjustTime
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CheckboxWithLabel
                                            type='checkbox'
                                            name='allDay'
                                            Label={{ label: 'Todo el dia' }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CheckboxWithLabel
                                            type='checkbox'
                                            name='retribuida'
                                            Label={{ label: 'Retribuida' }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name='estado'
                                            id='estado'
                                            component={SelectField}
                                            label='Estado'
                                            fullWidth
                                            selectOptions={solicitudEstadoSelectOptions}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button color='outlined' disabled={isSubmitting} onClick={onClose}>
                                    Cancelar
                                </Button>
                                <Button type='submit' color='info' disabled={isSubmitting}>
                                    Guardar
                                </Button>
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}

export default withButtonOpener(CrearAusenciaDialog);

CrearAusenciaDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
    styles: PropTypes.any,
};
