import MuiTextField from '@material-ui/core/TextField';
import { Field, useField } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { checklistsProvider } from '../../../api';

export default function SelectPlantillaField({ name, error, helperText, disabled }) {
    const [checklistPlantillaOptions, setChecklistPlantillaOptions] = useState([]);
    const [{ value: plantilla }, , { setValue: setPlantillaValue }] = useField(name);

    useEffect(() => {
        checklistsProvider.getOnlyActiveAsOptions().then((res) => {
            setChecklistPlantillaOptions(res);
        });
    }, []);

    useEffect(() => {
        if (checklistPlantillaOptions.length === 0) return;
        if (plantilla === undefined) return;

        setPlantillaValue(plantilla ? checklistPlantillaOptions.find((d) => d.id === plantilla.id) : null);
    }, [plantilla, checklistPlantillaOptions]);

    if (plantilla === undefined) return null;

    return (
        <Field
            name={name}
            fullWidth
            disabled={disabled}
            component={Autocomplete}
            options={checklistPlantillaOptions}
            getOptionSelected={(option, value) => option.id === value?.id}
            getOptionLabel={(option) => option.nombre ?? ''}
            renderInput={(params) => (
                <MuiTextField {...params} error={error} helperText={helperText} label='Usar la siguiente plantilla' />
            )}
        />
    );
}

SelectPlantillaField.propTypes = {
    disabled: PropTypes.any,
    error: PropTypes.any,
    helperText: PropTypes.any,
    name: PropTypes.string,
};
