import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { proyectosProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import DialogEditor from '../../common/forms/DialogEditor';
import RadioGroupField from '../../operarios/OperarioDialog/RadioGroupField';
import { afterGeneratingPdf } from '../../presupuestos/GenerarPdfDialog';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 600,
        },
        body: {
            gap: `${theme.spacing(2)}px`,
        },
        title: {
            color: theme.palette.neutral.grey4,
        },
        fields: {
            gap: `${theme.spacing(2)}px`,
        },
        field: {
            flex: 1,
        },
    }),
    { name: 'GenerarPdfDialog' },
);

const GenerarPdfDialogSchema = Yup.object().shape({
    modelo: Yup.string().required('Requerido'),
});

const COMPACTO = 'COMPACTO';
const EXTENDIDO = 'EXTENDIDO';
const FINAL = 'FINAL';

const modeloLabels = {
    [COMPACTO]: 'Compacto',
    [EXTENDIDO]: 'Extendido',
    [FINAL]: 'Hoja final',
};
export const modeloOptions = Object.entries(modeloLabels).map(([value, label]) => ({ value, label }));

function GenerarPdfDialog({ certificacionObra, open, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    return (
        <Formik
            initialValues={{
                modelo: COMPACTO,
            }}
            validationSchema={GenerarPdfDialogSchema}
            enableReinitialize
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                proyectosProvider
                    .generarCertificacionPdf(certificacionObra.id, values)
                    .then(
                        afterGeneratingPdf({
                            onFinish: () => {
                                setSubmitting(false);
                                props.onClose();
                            },
                            alternativeUrl: proyectosProvider.getCertificacionPdfURL(certificacionObra.id, values),
                            snackbar,
                        }),
                    )
                    .catch((err) => {
                        setSubmitting(false);
                        props.onClose();
                        console.error(err);
                        snackbar.showMessage(err.body.message);
                    });
            }}
        >
            {({ isSubmitting, submitForm }) => (
                <Form>
                    <DialogEditor
                        title='Generar PDF de la certificación de obra'
                        onSave={submitForm}
                        classes={{
                            root: classes.root,
                            body: classes.body,
                        }}
                        open={open}
                        canSave={!isSubmitting}
                        saveButtonText='Generar'
                        {...props}
                    >
                        <div className={classes.fields}>
                            <RadioGroupField name='modelo' label='Modelo de PDF' options={modeloOptions} />
                        </div>
                    </DialogEditor>
                </Form>
            )}
        </Formik>
    );
}

GenerarPdfDialog.propTypes = {
    certificacionObra: PropTypes.any,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.any,
};

export default withButtonOpener(GenerarPdfDialog);
