import { DialogActions, DialogContent } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Button from '../Button';

const useStyles = makeStyles(
    (theme) => ({
        root: {},
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            lineHeight: '50px',
            paddingLeft: theme.spacing(2),
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        body: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(3)}px`,
            padding: theme.spacing(2),
            '&.disableGutters': {
                padding: 0,
            },
            '&>div': {
                display: 'flex',
            },
        },
        actions: {
            marginTop: theme.spacing(2),
            justifyContent: 'flex-end',
        },
    }),
    { name: 'DialogEditor' },
);

export default function DialogEditor({
    title,
    children,
    open,
    onClose,
    onSave,
    className,
    saving: customSaving,
    classes: overrideClasses = {},
    saveButtonText = 'Guardar',
    cancelButtonText = 'Cancelar',
    canSave = true,
    maxWidth = 'sm',
    disableBodyGutters = false,
    customSaveButton,
}) {
    const classes = useStyles();
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (!open) setSaving(false);
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            id='mouse-over-popover'
            classes={{
                paper: clsx(classes.root, overrideClasses.root, className),
            }}
            fullWidth={true}
            maxWidth={maxWidth}
        >
            <DialogTitle id='alert-dialog-title' disableTypography={!(typeof title === 'string')}>
                {title}
            </DialogTitle>
            <DialogContent
                className={clsx(classes.body, disableBodyGutters ? 'disableGutters' : null, overrideClasses.body)}
            >
                {typeof children === 'function' ? children({ open }) : children}
            </DialogContent>
            <DialogActions>
                <Button color='outlined' onClick={onClose}>
                    {cancelButtonText}
                </Button>
                {onSave && (
                    <Button
                        color='info'
                        onClick={(ev) => {
                            if (customSaving ?? saving) return;

                            setSaving(true);

                            onSave(ev)?.catch(() => setSaving(false));
                        }}
                        disabled={!canSave || (customSaving ?? saving)}
                    >
                        {saveButtonText}
                    </Button>
                )}
                {customSaveButton}
            </DialogActions>
        </Dialog>
    );
}

DialogEditor.propTypes = {
    canSave: PropTypes.bool,
    cancelButtonText: PropTypes.string,
    children: PropTypes.any,
    className: PropTypes.any,
    classes: PropTypes.any,
    disableBodyGutters: PropTypes.bool,
    maxWidth: PropTypes.string,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
    saveButtonText: PropTypes.string,
    title: PropTypes.any,
    customSaveButton: PropTypes.any,
    saving: PropTypes.bool,
};
