import { useContext } from 'react';
import DateUtilities from './utils';
import { dateTimeFormat } from './dateUtils';
import Day from './Day';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { CalendarContext } from './YearCalendar';
import { formatISODate } from '../../../utils';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 32,
        marginBottom: 4,
    },
    day: {
        margin: 2,
    },
    blank: {
        width: 32,
        height: 32,
        margin: 2,
    },
});

function Week({ week, classes }) {
    const { onSelect, getDayProps, readOnly, disabledDates, selectedDates, minDate, maxDate } =
        useContext(CalendarContext);

    const isDisabled = (day) => {
        if (readOnly) return true;

        const disabledDate = disabledDates && disabledDates.find((d) => DateUtilities.isSameDay(d, day));
        if (disabledDate != null) return true;

        return (minDate && DateUtilities.isBefore(day, minDate)) || (maxDate && DateUtilities.isAfter(day, maxDate));
    };

    const handleOnSelect = (day) => {
        if (!isDisabled(day)) onSelect(day);
    };
    const isSelected = (day) => selectedDates && selectedDates.includes(day);

    // eslint-disable-next-line new-cap
    const dateInNumberic = new dateTimeFormat('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
    });

    const dateToday = dateInNumberic.format(new Date());

    // eslint-disable-next-line new-cap
    const dayInNumeric = new dateTimeFormat('en-US', {
        day: 'numeric',
    });

    return (
        <div className={classes.root}>
            {week.map((day, i) => {
                if (day) {
                    const isToday = day && dateToday === dateInNumberic.format(day);
                    const dayStr = formatISODate(day);
                    const dayProps = getDayProps(dayStr);

                    return (
                        <Day
                            key={`day-${day}`}
                            label={dayInNumeric.format(day)}
                            disabled={isDisabled(day)}
                            checked={isSelected(dayStr)}
                            onCheck={(e) => {
                                handleOnSelect(dayStr);
                            }}
                            dayProps={dayProps}
                            isToday={isToday}
                            className={classes.day}
                        />
                    );
                }
                return <div className={classes.blank} key={`blank-${i}`} />;
            })}
        </div>
    );
}

// class Week extends Component {
//     onSelect = (day) => {
//         if (!this.isDisabled(day)) this.props.onSelect(day);
//     };
//
//     isDisabled = (day) => {
//         if (this.props.readOnly) return true;
//
//         const disabledDate =
//             this.props.disabledDates && this.props.disabledDates.find((d) => DateUtilities.isSameDay(d, day));
//         if (disabledDate != null) return true;
//
//         const minDate = this.props.minDate;
//
//         const maxDate = this.props.maxDate;
//
//         return (minDate && DateUtilities.isBefore(day, minDate)) || (maxDate && DateUtilities.isAfter(day, maxDate));
//     };
//
//     isSelected = (day) => this.props.selectedDates && DateUtilities.dateIn(this.props.selectedDates, day);
//
//     render() {
//         const { classes } = this.props;
//
//         // eslint-disable-next-line new-cap
//         const dateInNumberic = new dateTimeFormat('en-US', {
//             day: 'numeric',
//             month: 'numeric',
//             year: 'numeric',
//         });
//
//         const dateToday = dateInNumberic.format(new Date());
//
//         // eslint-disable-next-line new-cap
//         const dayInNumeric = new dateTimeFormat('en-US', {
//             day: 'numeric',
//         });
//         return (
//             <div className={classes.root}>
//                 {this.props.week.map((day, i) => {
//                     if (day) {
//                         const isToday = day && dateToday === dateInNumberic.format(day);
//                         const isDisabled = this.isDisabled(day);
//                         const isSelected = this.isSelected(day);
//
//                         return (
//                             <Circle
//                                 key={`day-${day}`}
//                                 label={dayInNumeric.format(day)}
//                                 disabled={isDisabled}
//                                 checked={isSelected}
//                                 onCheck={(e) => {
//                                     this.onSelect(day);
//                                 }}
//                                 isToday={isToday}
//                                 className={classes.day}
//                             />
//                         );
//                     }
//                     return <div className={classes.blank} key={`blank-${i}`} />;
//                 })}
//             </div>
//         );
//     }
// }

Week.propTypes = {
    classes: PropTypes.any,
    displayDate: PropTypes.any,
    week: PropTypes.any,
};

export default withStyles(styles)(Week);
