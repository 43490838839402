import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form, Formik } from 'formik';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import { useSnackbar } from 'material-ui-snackbar-provider/lib';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { operariosProvider, tareasProvider } from '../../../api';
import Button from '../../common/Button';
import OperariosField from '../../common/fields/OperariosField';

const NewJornadaSchema = Yup.object().shape({
    operario: Yup.mixed().required('Escoge un operario'),
    fecha: Yup.mixed().required('Escoge una fecha'),
});

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 500,
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            gap: `${theme.spacing(2)}px`,
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    }),
    { name: 'NewJornadaDialog' },
);

const NewJornadaDialog = forwardRef(function ({ button, tareaId, onSave }, ref) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const snackbar = useSnackbar();

    const [operariosOptions, setOperariosOptions] = useState(null);

    useEffect(() => {
        if (!open || operariosOptions !== null) return;

        operariosProvider.getAll('as_options?includeAll').then(setOperariosOptions);
    }, [open, operariosOptions]);

    const formButton = React.cloneElement(button, { onClick: () => setOpen(true) });

    return (
        <>
            {formButton}
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby='nueva-tarea-title'
                open={open}
                classes={{
                    paper: classes.root,
                }}
                fullWidth={false}
                maxWidth='lg'
            >
                <DialogTitle id='nueva-tarea-title'>Añadir parte de trabajo</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={{
                            fecha: new Date(),
                            operario: null,
                        }}
                        validationSchema={NewJornadaSchema}
                        onSubmit={({ operario, ...values }, { setSubmitting, setFieldError }) => {
                            tareasProvider
                                .actionOnId(tareaId, 'jornadas', {
                                    ...values,
                                    operario_id: operario.id,
                                })
                                .then((updatedTarea) => {
                                    onSave(updatedTarea);

                                    setSubmitting(false);
                                    setOpen(false);
                                })
                                .catch((err) => {
                                    if (err.status === 400) {
                                        for (const [key, value] of Object.entries(err.message)) {
                                            setFieldError(key, value[0]);
                                        }
                                    } else {
                                        snackbar.showMessage('Ha ocurrido un error');
                                    }

                                    setSubmitting(false);
                                });
                        }}
                    >
                        {({ isSubmitting, touched, errors, values, setFieldValue }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <OperariosField
                                            operariosKey='operario'
                                            label='Operario'
                                            fullWidth
                                            multiple={false}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <KeyboardDatePicker
                                            name='fecha'
                                            label='Fecha'
                                            format={'dd/MM/yyyy'}
                                            fullWidth
                                            autoOk
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.actions}>
                                        <Button color='outlined' disabled={isSubmitting} onClick={() => setOpen(false)}>
                                            Cancelar
                                        </Button>
                                        <Button type='submit' color='info' disabled={isSubmitting}>
                                            Añadir
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
});

NewJornadaDialog.displayName = 'NewJornadaDialog';

NewJornadaDialog.propTypes = {
    button: PropTypes.any,
    onSave: PropTypes.any,
    tareaId: PropTypes.any,
};

export default NewJornadaDialog;
