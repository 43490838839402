import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import PropTypes from 'prop-types';
import CalendarioRow from './CalendarioRow';

export default function DraggableCalendarioRow({ style: outerStyle, draggable = true, ...props }) {
    const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } = useSortable({
        id: props.group.id,
        data: {
            type: 'GRUPO',
        },
    });

    const style = {
        ...outerStyle,
        transform: CSS.Translate.toString(transform),
        transition,
    };

    return (
        <CalendarioRow
            {...props}
            ref={setNodeRef}
            style={style}
            draggable={draggable}
            draggableProps={{
                ref: setActivatorNodeRef,
                ...listeners,
            }}
            {...attributes}
        />
    );
}

DraggableCalendarioRow.propTypes = {
    style: PropTypes.any,
    group: PropTypes.any,
    draggable: PropTypes.bool,
};
