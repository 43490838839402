import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { contratosMantenimientoProvider, cuestionariosProvider } from '../../api';
import DialogForm from '../common/forms/DialogForm';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import PropTypes from 'prop-types';
import FileUploader from '../common/fields/FileUploader';
import InstalacionField from '../common/fields/InstalacionField';
import { useEffect, useState } from 'react';
import ClienteField from '../common/fields/ClienteField';
import DireccionField from '../common/fields/DireccionField';
import { Field } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import MuiTextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '../common/fields/KeyboardDatePicker';

const ContratoMantenimientoSchema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
    instalacion: Yup.mixed().required('Selecciona una instalacion'),
    fecha_inicio: Yup.date().typeError('La fecha de inicio debe ser una fecha').required('Requerido'),
    fecha_fin: Yup.date()
        .typeError('La fecha de fin debe ser una fecha')
        .required('Requerido')
        .min(Yup.ref('fecha_inicio'), 'La fecha de fin debe ser posterior a la de inicio'),
});

function NewContratoMantenimientoDialog({ onSave, clienteId, direccionId, instalacionId, ...props }) {
    const [cuestionarioOptions, setCuestionarioOptions] = useState([]);
    useEffect(() => {
        cuestionariosProvider.getOnlyActiveAsOptions().then((cuestionarios) =>
            setCuestionarioOptions(
                cuestionarios.map(({ tipos_equipo: tipos, ...c }) => ({
                    ...c,
                    tiposEquipo: tipos.map((t) => t.id),
                })),
            ),
        );
    }, []);

    return (
        <DialogForm
            title='Añadir contrato de mantenimiento'
            FormikProps={{
                initialValues: {
                    cliente: clienteId ? { id: clienteId } : null,
                    direccion: direccionId ? { id: direccionId } : null,
                    instalacion: instalacionId ? { id: instalacionId } : null,
                    nombre: '',
                    fecha_inicio: null,
                    fecha_fin: null,
                    num_servicios: 0,
                    observaciones: '',
                    files: [],
                    cuestionarios: [],
                },
                validationSchema: ContratoMantenimientoSchema,
                onSubmit: (
                    {
                        instalacion,
                        cliente,
                        direccion,
                        files,
                        fecha_inicio: fechaInicio,
                        fecha_fin: fechaFin,
                        cuestionarios,
                        ...values
                    },
                    { setSubmitting, setFieldError },
                ) => {
                    const formData = new FormData();
                    Object.entries(values).forEach(([key, value]) => formData.append(key, value.toString()));
                    formData.append('instalacion_id', instalacion.id);
                    formData.append('fecha_inicio', fechaInicio.toISOString());
                    formData.append('fecha_fin', fechaFin.toISOString());
                    formData.append('cuestionarios', JSON.stringify(cuestionarios));
                    new Array(...files).forEach((file) => formData.append('files', file));

                    contratosMantenimientoProvider
                        .save(formData)
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ touched, errors, values, setFieldValue }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ClienteField
                            error={touched.cliente && !!errors.cliente}
                            helperText={touched.cliente && errors.cliente}
                            disabled={Boolean(clienteId)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DireccionField
                            error={touched.direccion && !!errors.direccion}
                            helperText={touched.direccion && errors.direccion}
                            disabled={Boolean(direccionId)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InstalacionField
                            error={touched.instalacion && !!errors.instalacion}
                            helperText={touched.instalacion && errors.instalacion}
                            disabled={Boolean(instalacionId)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name='nombre' label='Nombre' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDatePicker name='fecha_inicio' label='Desde' format={'dd/MM/yyyy'} fullWidth autoOk />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDatePicker name='fecha_fin' label='Hasta' format={'dd/MM/yyyy'} fullWidth autoOk />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name='num_servicios' label='Numero de revisiones' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name='cuestionarios'
                            fullWidth
                            component={Autocomplete}
                            options={cuestionarioOptions}
                            getOptionSelected={(option, value) => option.id === value?.id}
                            getOptionLabel={(option) => option.nombre ?? ''}
                            renderInput={(params) => (
                                <MuiTextField
                                    {...params}
                                    label='Tipos de revisión a realizar'
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                            multiple
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name='observaciones'
                            label='Observaciones'
                            fullWidth
                            multiline
                            minRows={4}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FileUploader
                            id='upload-contrato_mantenimiento-adjuntos-file'
                            label='Añadir adjuntos'
                            file={values.files}
                            setFile={(files) => setFieldValue('files', files)}
                            multiple={true}
                            isImage={false}
                            accept='image/jpeg,image/gif,image/png,application/pdf'
                        />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(NewContratoMantenimientoDialog);

NewContratoMantenimientoDialog.propTypes = {
    clienteId: PropTypes.any,
    direccionId: PropTypes.any,
    instalacionId: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
