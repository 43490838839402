import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { estadoLabels } from '../../api/tareas';
import Button from '../common/Button';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import { ListaServicios } from '../servicios/ListaServicios/ListaServicios';
import useDefaultServiciosColumns from '../servicios/useDefaultServiciosColumns';
import NuevaTareaDialog from '../tareas/NuevaTareaDialog';

const useStyles = makeStyles((theme) => ({
    title: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flex: 1,
    },
    button: {
        marginLeft: theme.spacing(1),
        '& svg': {
            marginRight: theme.spacing(1),
        },
    },
    estadoLabel: {
        backgroundColor: '#ccc',
        borderRadius: 4,
        fontSize: 11,
        padding: 6,
        textTransform: 'uppercase',
        '&.pendiente': {
            color: '#F03738',
            backgroundColor: '#FDEBEB',
        },
        '&.en_progreso': {
            color: '#F3AC3D',
            backgroundColor: '#f8ead3',
        },
        '&.finalizada': {
            color: '#2CBD96',
            backgroundColor: '#CFF3E9',
        },
    },
}));

export default function TrabajosProyecto({ clienteId, direccionId }) {
    const history = useHistory();
    const { id } = useParams();

    const { data, updateLocalData } = useEditFormData('tareas');

    const columns = useDefaultServiciosColumns(['cliente', 'proyecto', 'operarios-visible']);

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            hideDelete: true,
            batchComponents: (
                <NuevaTareaDialog
                    clienteId={clienteId}
                    direccionId={direccionId}
                    proyectoId={id}
                    button={
                        <Button color='transparent' aria-label='Nuevo servicio' startIcon={<AddIcon />}>
                            Añadir servicio
                        </Button>
                    }
                    onSave={(result) => history.push(`/servicios/${result.id}`)}
                />
            ),
        }),
        [],
    );

    return (
        <ListaServicios
            tableId='trabajosProyecto'
            tareas={data}
            setTareas={(newTareas) => {
                if (typeof newTareas === 'function') {
                    updateLocalData(null, newTareas(data));
                } else {
                    updateLocalData(null, newTareas);
                }
            }}
            columns={columns}
            options={options}
            basePath='/proyectos'
            tableTitle='Lista de servicios'
        />
    );
}

TrabajosProyecto.propTypes = {
    clienteId: PropTypes.any,
    direccionId: PropTypes.any,
};

function EstadoCell({ value }) {
    const styles = useStyles();

    return <span className={clsx(styles.estadoLabel, value.toLowerCase())}>{estadoLabels[value]}</span>;
}

EstadoCell.propTypes = {
    value: PropTypes.any,
};
