import DataProvider from './DataProvider';

export const empty = {
    nombre: '',
    items: [],
    activo: true,
};

class ChecklistsProvider extends DataProvider {
    constructor() {
        super('checklists', empty);
    }

    getOnlyActiveAsOptions = async () => this.getAll('as_options');

    duplicar = async (id) => this.actionOnId(id, 'duplicar');

    deleteChecklistTarea = async (id) => this.getAll(`tareas/${id}`, { method: 'delete' });
}

export const dataProvider = new ChecklistsProvider();
