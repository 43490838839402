import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { format } from '../../utils';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { DialogActions } from '@material-ui/core';
import Button from '../common/Button';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        body: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2, 2, 0, 2),
        },
        signature: {
            border: '1px dashed #bababa',
            borderRadius: 4,
            maxHeight: 200,
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
            '& img': {
                margin: theme.spacing(2),
                objectFit: 'cover',
                overflow: 'hidden',
            },
        },
        locationButton: {
            boxShadow: 'none',
            textTransform: 'none',
            color: 'white',
            backgroundColor: '#4D86FF',
            width: 160,
            marginTop: theme.spacing(2),
            '&:focus, &:active, &:hover': {
                backgroundColor: '#4D86FF',
            },
            '&:hover': {
                opacity: 0.8,
            },
        },
        row: {
            fontSize: 14,
            color: '#757575',
            marginTop: theme.spacing(1),
        },
    }),
    { name: 'FirmaDialog' },
);

function FirmaDialog({ open, onClose, firma, ...props }) {
    const classes = useStyles();
    return (
        <Dialog open={open} onClose={onClose} fullWidth {...props}>
            <DialogTitle id='alert-dialog-title'>Firma</DialogTitle>
            <DialogContent className={classes.body}>
                <div className={classes.signature}>
                    {firma.image_url ? (
                        <img src={firma.image_url} />
                    ) : (
                        <Typography style={{ margin: 40 }}>Sin firma</Typography>
                    )}
                </div>
                {firma.nombre && (
                    <div className={classes.row}>
                        Firmado por: <strong>{firma.nombre}</strong>
                    </div>
                )}
                <div className={classes.row}>
                    {/* eslint-disable-next-line quotes */}
                    Fecha: <strong>{format(new Date(firma.fecha), "dd/MM/yyyy' a las 'HH:mm")}</strong>
                </div>
                <div className={classes.row}>
                    Operario: <strong>{firma.operario || '(cerrado desde administracion)'}</strong>
                </div>
                {firma.coordenadas && (
                    <Button
                        className={classes.locationButton}
                        startIcon={<LocationOnIcon />}
                        onClick={() =>
                            window.open(
                                `https://www.google.com/maps/search/?api=1&query=${firma.coordenadas}`,
                                '_blank',
                            )
                        }
                    >
                        Ver ubicación
                    </Button>
                )}
            </DialogContent>
            <DialogActions>
                <Button color='outlined' onClick={onClose}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withButtonOpener(FirmaDialog);

FirmaDialog.propTypes = {
    firma: PropTypes.any,
    onClose: PropTypes.any,
    open: PropTypes.any,
};
