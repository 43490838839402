import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import VisitaConfirmadaIcon from '@material-ui/icons/EventAvailable';
import VisitaNoConfirmadaIcon from '@material-ui/icons/EventBusy';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import clsx from 'clsx';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { estadoPreparacionIcons, estadoPreparacionLabels } from '../../api/preparaciones_material';
import useAuthState from '../../AuthState';
import { eventIcons } from '../agenda/constants';
import { useCalendarioPlanificacion } from './CalendarioContent';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            borderRadius: 4,
            padding: theme.spacing(0.5, 0.5, 0.5, 1),
            overflow: 'hidden',
            cursor: 'pointer',
            position: 'relative',
            background: (props) =>
                props.urgente
                    ? theme.palette.error.main
                    : props.background
                    ? props.background
                    : theme.palette.primary.hover,
            color: (props) => (props.urgente ? 'white' : props.text ? props.text : theme.palette.text.primary),
            '& .MuiTypography-root': {
                lineHeight: `${theme.spacing(2)}px`,
                whiteSpace: 'nowrap',
            },
            '& .MuiSvgIcon-root': {
                fontSize: 16,
            },
            '&:hover $dragHandle': {
                display: 'block',
            },
            userSelect: 'none',
            transition: 'opacity 0.5s',
        },
        finalizada: {
            opacity: 0.5,
        },
        content: {
            flex: 1,
            overflow: 'hidden',
            transition: 'margin-left 0.5s',
        },
        title: {
            fontWeight: 600,
        },
        subtitle: {
            fontWeight: theme.typography.fontWeightMedium,
        },
        overflowText: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        vehicleText: {
            display: 'flex',
            alignItems: 'center',
        },
        icons: {
            display: 'flex',
            flexDirection: 'column',
            '& div': {
                display: 'flex',
                gap: '2px',
                height: 16,
            },
            '& div span': {
                display: 'flex',
                alignItems: 'center',
                fontSize: 12,
                fontWeight: 500,
            },
        },
        dragHandle: {
            cursor: 'grab',
            backgroundColor: 'white',
            color: theme.palette.neutral.primary,
            position: 'absolute',
            transform: 'translateX(-32px)',
            display: 'none',
        },
        dragging: {
            outline: (props) =>
                `2px solid ${props.urgente ? 'white' : props.text ? props.text : theme.palette.primary.main}`,
        },
        notBeingDragged: {
            opacity: 0.5,
        },
        hidden: {
            display: 'none',
        },
    }),
    { name: 'Evento' },
);

export const EVENTO_DRAGGABLE_TYPE = 'EVENTO';

export default function Evento({ singleColumn, event, style: outerStyle, onClick }) {
    const { checkIfEventoIsHovered, onEventoHovered, isAnyEventoHovered, enableDnd } = useCalendarioPlanificacion();
    const classes = useStyles({ ...event.customColor, urgente: event.urgente });
    const {
        userInfo: { preferencias: { usar_preparacion_materiales: usarPreparacionMateriales } = {} },
    } = useAuthState();
    const snackbar = useSnackbar();

    const PreparacionIcon = estadoPreparacionIcons[event.estado_preparacion];
    const EventIcon = eventIcons[event.icon];

    const operarios = event.operarios
        ? event.operarios.length > 0
            ? event.operarios.map((op) => op.nombre).join(', ')
            : 'Sin operarios'
        : null;

    const finalizado = event.estado && event.estado === 'FINALIZADA';

    const dragDisabled = event.fromPrevWeek || event.estado === 'FINALIZADA' || event.virtual;

    const { attributes, listeners, setNodeRef, transform, active } = useDraggable({
        id: event.uniqueId,
        disabled: dragDisabled || !enableDnd,
        data: {
            type: EVENTO_DRAGGABLE_TYPE,
            entity: event,
        },
    });

    const isThisSelected =
        active &&
        active.data.current.type === EVENTO_DRAGGABLE_TYPE &&
        active.data.current.entity.planificacionId === event.planificacionId;

    const style = {
        transform: event.virtual ? CSS.Translate.toString(transform) : undefined,
        zIndex: event.virtual ? 99999 : undefined,
        ...outerStyle,
    };

    return (
        <div
            className={clsx(
                classes.root,
                finalizado ? classes.finalizada : null,
                isThisSelected || checkIfEventoIsHovered(event) ? classes.dragging : null,
                !active && isAnyEventoHovered && !checkIfEventoIsHovered(event) ? classes.notBeingDragged : null,
                event.dragging ? classes.hidden : null,
            )}
            style={{
                gridColumnStart: singleColumn ? 1 : event.offset + 1,
                gridColumnEnd: `span ${singleColumn ? 1 : event.length}`,
                gridRowStart: event.row + 1,
                ...style,
            }}
            onClick={event.type === 'agenda' ? onClick : () => {}}
            {...attributes}
            {...listeners}
            onDragStart={(ev) => {
                if (dragDisabled) {
                    let error;
                    if (finalizado) error = 'No puedes mover un servicio finalizado';
                    else if (event.fromPrevWeek) error = 'No puedes mover un servicio que viene de la semana anterior';

                    if (error)
                        snackbar.showMessage(error, null, null, {
                            severity: 'error',
                            showCloseButton: true,
                        });
                }
                ev.preventDefault();
            }}
            draggable={dragDisabled}
            onMouseEnter={() => onEventoHovered(event)}
            onMouseLeave={() => onEventoHovered(null)}
            ref={setNodeRef}
        >
            <div className={clsx(classes.content, 'cp-evento-content')}>
                {event.cliente && (
                    <Typography
                        variant='subtitle2'
                        title={event.cliente}
                        className={clsx(classes.title, classes.overflowText)}
                    >
                        {event.cliente}
                    </Typography>
                )}
                <Typography
                    variant='subtitle2'
                    className={clsx(classes.subtitle, classes.overflowText)}
                    title={event.descripcion}
                >
                    {event.descripcion}
                </Typography>
                {operarios && (
                    <Typography
                        variant='subtitle2'
                        title={operarios}
                        className={clsx(classes.subtitle, classes.overflowText)}
                    >
                        {operarios}
                    </Typography>
                )}
            </div>

            <div className={classes.icons}>
                {event.type === 'solicitud' && (
                    <div>
                        <EventIcon />
                    </div>
                )}
                {event.type === 'agenda' && (
                    <>
                        <div>
                            {event.confirmada ? (
                                <Tooltip arrow title='Visita confirmada'>
                                    <VisitaConfirmadaIcon />
                                </Tooltip>
                            ) : (
                                <Tooltip arrow title='Visita no confirmada'>
                                    <VisitaNoConfirmadaIcon />
                                </Tooltip>
                            )}
                            {usarPreparacionMateriales && (
                                <Tooltip
                                    arrow
                                    title={`Preparación de material: ${
                                        estadoPreparacionLabels[event.estado_preparacion]
                                    }`}
                                >
                                    <span>
                                        <PreparacionIcon />{' '}
                                        {singleColumn ? estadoPreparacionLabels[event.estado_preparacion] : null}
                                    </span>
                                </Tooltip>
                            )}
                            {event.operario ? (
                                event.visible ? (
                                    <Tooltip arrow title='El operario ve este servicio en su móvil'>
                                        <VisibilityIcon />
                                    </Tooltip>
                                ) : (
                                    <Tooltip arrow title='El operario no ve este servicio en su móvil'>
                                        <VisibilityOffIcon />
                                    </Tooltip>
                                )
                            ) : null}
                        </div>
                        {event.operario && event.vehiculo && (
                            <Tooltip arrow title={event.vehiculo.nombre}>
                                <Typography variant='subtitle2' className={classes.vehicleText} component='div'>
                                    <DriveEtaIcon /> {singleColumn ? event.vehiculo.nombre : event.vehiculo.codigo}
                                </Typography>
                            </Tooltip>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

Evento.propTypes = {
    event: PropTypes.any,
    onClick: PropTypes.any,
    singleColumn: PropTypes.any,
    style: PropTypes.any,
};
