import makeStyles from '@material-ui/core/styles/makeStyles';
import AdjuntosItem from './AdjuntosItem';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(0.5)}px`,
        },
    }),
    { name: 'AdjuntosList' },
);

export default function AdjuntosList({ adjuntos, style, onDelete }) {
    const classes = useStyles();

    return (
        <div className={classes.root} style={style}>
            {adjuntos.map((adjunto, i) => (
                <AdjuntosItem key={i} adjunto={adjunto} onDelete={onDelete} />
            ))}
        </div>
    );
}

AdjuntosList.propTypes = {
    adjuntos: PropTypes.any,
    onDelete: PropTypes.any,
    style: PropTypes.any,
};
