import { useEffect, useState } from 'react';
import { companiesProvider } from '../api';

export default function useInfoSuscripcion(observable = []) {
    const [infoSuscripcion, setInfoSuscripcion] = useState(null);
    useEffect(() => {
        companiesProvider.getInfoSuscripcion().then(setInfoSuscripcion);
    }, observable);

    if (infoSuscripcion === null) {
        return {
            numeroLicencias: null,
            numeroOperarios: 0,
            agotadas: true,
        };
    }

    return {
        numeroLicencias: infoSuscripcion.numero_licencias,
        numeroOperarios: infoSuscripcion.numero_operarios,
        agotadas: infoSuscripcion.agotadas,
    };
}
