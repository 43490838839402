import MuiTextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { companiesProvider } from '../../../api';

export default function SelectHorarioEmpresaField({ error, helperText, disabled, onChange, value }) {
    const [horarioEmpresaOptions, setHorarioEmpresaOptions] = useState([]);

    useEffect(() => {
        companiesProvider.getHorariosEmpresaAsOptions().then((res) => {
            setHorarioEmpresaOptions(res);
        });
    }, []);

    return (
        <Autocomplete
            fullWidth
            disabled={disabled}
            options={horarioEmpresaOptions}
            getOptionSelected={(option, value) => option.id === value?.id}
            getOptionLabel={(option) => option.nombre ?? ''}
            onChange={(_, value) => {
                onChange(value);
            }}
            value={value}
            renderInput={(params) => (
                <MuiTextField
                    {...params}
                    error={error}
                    helperText={helperText}
                    label='Copiar del siguiente horario de empresa'
                    InputLabelProps={{ shrink: true }}
                    placeholder='Personalizado'
                />
            )}
        />
    );
}

SelectHorarioEmpresaField.propTypes = {
    disabled: PropTypes.any,
    error: PropTypes.any,
    helperText: PropTypes.any,
    onChange: PropTypes.any,
    value: PropTypes.any,
};
