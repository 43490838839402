import CircularProgress from '@material-ui/core/CircularProgress';
import ReplayIcon from '@material-ui/icons/Replay';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { companiesProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import useSynchronizationJob from '../../clientes/useSynchronizationJob';
import Button from '../../common/Button';
import { SwitchWithLabel } from '../../common/fields/Switch';
import DialogEditor from '../../common/forms/DialogEditor';

function AlbaranSyncButton({ onJobStarted, running, albaranIds, syncLineas }) {
    return (
        <Button
            color='info'
            startIcon={running ? <CircularProgress size={20} /> : <ReplayIcon />}
            onClick={() => {
                companiesProvider.startAlbaranesServicioSynchronizationJob(albaranIds, syncLineas).then(onJobStarted);
            }}
            disabled={running}
        >
            {running ? 'Sincronizando...' : 'Sincronizar con DELSOL'}
        </Button>
    );
}

AlbaranSyncButton.propTypes = {
    albaranIds: PropTypes.string,
    syncLineas: PropTypes.bool,
    onJobStarted: PropTypes.func,
    running: PropTypes.bool,
};

function SyncAlbaranDialog({ albaranIds, onSyncFinished, ...props }) {
    const open = props.open;

    const [syncLineas, setSyncLineas] = useState(true);
    const field = {
        name: 'syncAllLines',
        value: syncLineas,
        onChange: (_, checked) => {
            setSyncLineas(checked);
        },
    };

    const syncFn = useCallback(open ? companiesProvider.getCurrentAlbaranesServicioSynchronizationJob : null, [open]);

    const { syncButton } = useSynchronizationJob(
        AlbaranSyncButton,
        syncFn,
        {
            albaranIds,
            syncLineas,
        },
        onSyncFinished,
    );

    return (
        <DialogEditor
            title='Sincronizar albarán de servicio'
            maxWidth='xs'
            cancelButtonText='Cerrar'
            customSaveButton={syncButton}
            {...props}
        >
            <SwitchWithLabel label='Sincronizar todas las lineas' field={field} />
        </DialogEditor>
    );
}

export default withButtonOpener(SyncAlbaranDialog);

SyncAlbaranDialog.propTypes = {
    albaranIds: PropTypes.any,
    onSyncFinished: PropTypes.any,
    open: PropTypes.any,
};
