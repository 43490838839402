import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { tipoDesgloseAlbaranesOptions } from '../../../../api/companies';
import { usePreferencias } from '../../../../AuthState';
import FileUploader from '../../../common/fields/FileUploader';
import SelectField from '../../../common/fields/SelectField';
import { SwitchWithLabel } from '../../../common/fields/Switch';

const idiomaAlbaranesOptions = [
    { value: 'es', label: 'Español' },
    { value: 'ca', label: 'Català' },
];

export default function AjustesAlbaranes({ classes, setFile, values }) {
    const usarChecklists = usePreferencias('usar_checklists');

    return (
        <Paper elevation={0}>
            <Typography className={classes.title}>Albaranes de servicio</Typography>
            <Grid container spacing={4} className={classes.content}>
                <Grid item xs={12}>
                    <Field
                        name='enviar_mail_cliente'
                        component={SwitchWithLabel}
                        label='Enviar copia al cliente por defecto cuando se crea un albarán de servicio'
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        name='desglosar_marcajes_operario'
                        component={SwitchWithLabel}
                        label='Desglosar los marcajes de los operarios en los albaranes de servicio'
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        name='tipo_desglose_albaranes'
                        id='tipo_desglose_albaranes'
                        component={SelectField}
                        label='Desglosar mano de obra de los operarios en los albaranes de servicio'
                        fullWidth
                        selectOptions={tipoDesgloseAlbaranesOptions}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        name='desglosar_explicacion_operarios'
                        component={SwitchWithLabel}
                        label='Desglosar las explicaciones de los operarios por fechas en los albaranes de servicio'
                    />
                </Grid>
                {usarChecklists && (
                    <Grid item xs={12}>
                        <Field
                            name='desglosar_subtareas_operario'
                            component={SwitchWithLabel}
                            label='Desglosar las tareas de los operarios por fechas en los albaranes de servicio'
                        />
                    </Grid>
                )}
                {values.idioma_albaran && (
                    <Grid item xs={12}>
                        <Field
                            name='idioma_albaran'
                            id='idioma_albaran'
                            component={SelectField}
                            label='Idioma de los albaranes de servicio'
                            fullWidth
                            selectOptions={idiomaAlbaranesOptions}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <FileUploader
                        id='upload-logo-file'
                        label='Logo de la empresa'
                        value={values.logo_url}
                        file={values.logo_file}
                        setFile={setFile}
                        accept='image/*'
                    />
                    {values.logo_url && (
                        <Field name='borrar_logo' component={SwitchWithLabel} label='Eliminar logo existente' />
                    )}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name='pie_albaran'
                        label='Texto para el pie del albaran de servicio'
                        fullWidth
                        multiline
                        rows={8}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
}

AjustesAlbaranes.propTypes = {
    classes: PropTypes.any,
    setFile: PropTypes.any,
    values: PropTypes.any,
};
