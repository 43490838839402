import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { formatDate } from '../../../utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        item: {
            padding: theme.spacing(1.5),
            borderRadius: 8,
            border: `1px solid ${theme.palette.neutral.grey1}`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            '&>.MuiTypography-root': {
                color: theme.palette.neutral.primary,
                marginLeft: 28,
            },
        },
        title: {
            display: 'flex',
            alignItems: 'center',
        },
        checkbox: {
            padding: 0,
            marginRight: theme.spacing(0.5),
        },
    }),
    { name: 'GenerarAlbaranItem' },
);

export default function GenerarAlbaranItem({ jornada, checked, onClick, itemTitleFn = null }) {
    const classes = useStyles();

    let baseTitle = jornada.operario ? 'Parte de trabajo del ' : 'Imputación de albarán de compra del ';
    baseTitle = `${baseTitle}${formatDate(jornada.fecha)}`;
    const title = itemTitleFn ? itemTitleFn(jornada) : baseTitle;

    return (
        <div className={classes.item} onClick={() => onClick(!checked)}>
            <div className={classes.title}>
                <Checkbox
                    checked={checked}
                    onClick={(ev) => onClick(ev.target.checked)}
                    className={classes.checkbox}
                    color='primary'
                />
                <Typography variant='subtitle1'>{title}</Typography>
            </div>
            <Typography variant='subtitle1'>Por {jornada.operario || 'administación'}</Typography>
        </div>
    );
}
GenerarAlbaranItem.propTypes = {
    jornada: PropTypes.object.isRequired,
    checked: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    itemTitleFn: PropTypes.func,
};
