import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import AddLabelListItemDialog from './AddLabelListItemDialog';
import Button from '../../common/Button';
import { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import { DeleteDialogBase } from '../../common/dialogs/DeleteDialog';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        list: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            marginTop: theme.spacing(1),
        },
    }),
    { name: 'LabelListField' },
);

export default function LabelListField({ label, getItems, onAdd, onDelete, DialogProps }) {
    const classes = useStyles();

    const [deleteId, setDeleteId] = useState(false);
    const [items, setItems] = useState([]);

    function refreshLabels() {
        getItems().then(setItems);
    }

    useEffect(() => {
        refreshLabels();
    }, []);

    return (
        <>
            <DeleteDialogBase
                text='¿Deseas eliminar este elemento?'
                onClose={() => setDeleteId(false)}
                open={Boolean(deleteId)}
                onConfirm={() => {
                    setDeleteId(false);
                    onDelete(deleteId).then(refreshLabels);
                }}
            />
            <div>
                <InputLabel shrink>{label}</InputLabel>
                <div className={classes.list}>
                    {items.map((origen) => (
                        <Chip label={origen.nombre} key={origen.id} onDelete={() => setDeleteId(origen.id)} />
                    ))}
                    <AddLabelListItemDialog
                        {...DialogProps}
                        onSave={(nombre) => onAdd(nombre).then(refreshLabels)}
                        button={
                            <Button color='info' startIcon={<AddIcon />}>
                                Añadir
                            </Button>
                        }
                    />
                </div>
            </div>
        </>
    );
}

LabelListField.propTypes = {
    DialogProps: PropTypes.any,
    getItems: PropTypes.any,
    label: PropTypes.any,
    onAdd: PropTypes.any,
    onDelete: PropTypes.any,
};
