import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { preparacionesMaterialProvider } from '../../api';
import useAuthState from '../../AuthState';
import { useFetchData } from '../../hooks/useFetchData';
import { formatDateTime } from '../../utils';
import { DateColumnFilter, dateFilterFn } from '../common/list/DateColumnFilter';
import BaseListView, { withTableState } from '../common/list/ListView';
import { ClienteLinkCell } from '../dashboard/ClienteLink';
import PreparacionMaterialDrawer from './PreparacionMaterialDrawer';

const ListView = withTableState('solicitudes-material', BaseListView);

export default function SolicitudesMaterialList() {
    const {
        userInfo: { preferencias: { usar_referencias_tareas: usarReferencias } = {} },
    } = useAuthState();
    const fetchDataFn = useCallback(() => preparacionesMaterialProvider.getAllSolicitudes(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    const [drawerPreparacion, setDrawerPreparacion] = useState(false);

    const toggleDrawerPreparacion = (jornada) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawerPreparacion(jornada);
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const columns = useMemo(() => {
        const columns = [];

        if (usarReferencias)
            columns.push({
                Header: 'Ref. interna',
                accessor: 'referencia_interna',
            });

        columns.push(
            {
                Header: 'Referencia',
                id: 'numero',
                accessor: (row) => (row.numero ? row.numero.toString().padStart(4, '0') : 'N/D'),
            },
            {
                Header: 'Título',
                accessor: 'descripcion',
            },
            {
                Header: 'Cliente',
                accessor: 'cliente',
                Cell: ClienteLinkCell,
            },
            {
                Header: 'Direccion',
                accessor: 'direccion',
            },
            {
                Header: 'Operario',
                accessor: 'operario',
            },
            {
                Header: 'Fecha solicitud',
                accessor: 'fecha_solicitud',
                Cell: ({ value }) => formatDateTime(value),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
        );

        return columns;
    }, [usarReferencias]);

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            hideDelete: true,
            tableOptions: {
                fillEmptySpace: false,
            },
        }),
        [],
    );

    return (
        <>
            <PreparacionMaterialDrawer
                row={drawerPreparacion}
                solicitudId={drawerPreparacion ? drawerPreparacion.id : null}
                onClose={toggleDrawerPreparacion(false)}
            />
            <ListView
                tableTitle='Lista de solicitudes de material pendientes'
                basePath='/solicitud-material'
                columns={columns}
                data={data}
                options={options}
                extraActions={(row) => (
                    <>
                        <IconButton style={{ padding: 6 }} onClick={toggleDrawerPreparacion(row)}>
                            <VisibilityIcon fontSize='small' />
                        </IconButton>
                    </>
                )}
            />
        </>
    );
}
