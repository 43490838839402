import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { companiesProvider } from '../../../../api';
import Button from '../../../common/Button';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            backgroundColor: 'white',
            padding: theme.spacing(3, 2),
            gap: `${theme.spacing(1)}px`,
            alignItems: 'flex-start',
            '&:not(:first-of-type)': {
                borderTop: `1px solid ${theme.palette.divider}`,
            },
        },
        lockIcon: {
            margin: 9,
        },
        checkbox: {
            '&.Mui-checked': {
                color: theme.palette.success.main,
            },
        },
        text: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: theme.spacing(1),
        },
        error: {
            color: theme.palette.error.main,
        },
        actions: {
            display: 'flex',
            marginLeft: 'auto',
            gap: `${theme.spacing(1)}px`,
        },
    }),
    { name: 'PrimerosPasosStep' },
);

export default function PrimerosPasosStep({ step, idx, onToggleCompletado, onRequestSuccess }) {
    const classes = useStyles();
    const history = useHistory();
    const snackbar = useSnackbar();

    function onClickLink(link) {
        if (!link.url) {
            if (link.request) {
                companiesProvider.getAll(link.request, { method: 'post' }).then((result) => {
                    snackbar.showMessage(link.request_success_message);
                    onRequestSuccess(result);
                });
            }
        } else if (link.url.startsWith('http')) {
            window.open(link.url, '_blank');
        } else {
            history.push(link.url);
        }
    }

    return (
        <div className={classes.root}>
            {step.locked ? (
                <Tooltip title='Completa los pasos previos para desbloquear este paso' arrow>
                    <LockIcon className={classes.lockIcon} />
                </Tooltip>
            ) : (
                <Checkbox
                    className={classes.checkbox}
                    checked={Boolean(step.fecha_completado)}
                    onClick={() => {
                        if (step.fecha_completado) return;
                        onToggleCompletado(step);
                    }}
                />
            )}
            <div className={classes.text}>
                <Typography variant='body2'>
                    {step.show_step_number ? `Paso ${idx}: ` : null}
                    {step.titulo}
                </Typography>
                {step.error && (
                    <Typography variant='subtitle2' className={classes.error}>
                        {step.error}
                    </Typography>
                )}
            </div>
            {!step.locked && (
                <div className={classes.actions}>
                    <Button color='dark' onClick={() => onClickLink(step.link_primario)}>
                        {step.link_primario.texto}
                    </Button>
                    {step.link_secundario && (
                        <Button color='dark' onClick={() => onClickLink(step.link_secundario)}>
                            {step.link_secundario.texto}
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
}

PrimerosPasosStep.propTypes = {
    idx: PropTypes.any,
    step: PropTypes.shape({
        id: PropTypes.string,
        error: PropTypes.any,
        show_step_number: PropTypes.bool,
        fecha_completado: PropTypes.instanceOf(Date),
        link_primario: PropTypes.shape({
            texto: PropTypes.string,
            url: PropTypes.string,
        }),
        link_secundario: PropTypes.shape({
            texto: PropTypes.string,
            url: PropTypes.string,
        }),
        titulo: PropTypes.string,
        locked: PropTypes.bool,
    }),
    onToggleCompletado: PropTypes.func,
    onRequestSuccess: PropTypes.func,
};
