import { Typography } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: `${theme.spacing(1)}px`,
        },
        label: {
            color: theme.palette.neutral.primary,
        },
        value: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.primary.main,
        },
    }),
    { name: 'CustomFilter' },
);

export function CustomFilter({ label, options, defaultOption = 0 }) {
    const classes = useStyles();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const [selectedFilter, setSelectedFilter] = React.useState(defaultOption);

    useEffect(() => {
        setSelectedFilter(defaultOption);
    }, [defaultOption]);

    const openMenu = (event) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
    };

    const closeMenu = (event) => {
        event && event.stopPropagation();
        setMenuAnchorEl(null);
    };

    return (
        <div className={classes.root}>
            <Typography variant='subtitle1' className={classes.label}>
                {label}
            </Typography>
            <Typography component='div' variant='subtitle1' className={classes.value} onClick={openMenu}>
                {options[selectedFilter].label}
                <KeyboardArrowDownIcon />
            </Typography>
            <Menu id='simple-menu' anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={closeMenu}>
                {options.map((option, i) => (
                    <MenuItem
                        key={i}
                        onClick={(ev) => {
                            closeMenu(ev);
                            setSelectedFilter(i);
                            option.onClick();
                        }}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

CustomFilter.propTypes = {
    label: PropTypes.any,
    options: PropTypes.any,
    defaultOption: PropTypes.any,
};
