import { Tooltip } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { MASTER_ITEM, MASTER_ITEM_SELECTED } from './MasterItem';

const useStyles = makeStyles(
    (theme) => {
        return {
            root: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                whiteSpace: 'nowrap',
                color: (props) => (props.color ? theme.palette[props.color].main : theme.palette.neutral.grey4),
                backgroundColor: (props) =>
                    props.color ? theme.palette[props.color].backgroundTint : theme.palette.neutral.backgroundPrimary,
                borderRadius: 4,
                padding: theme.spacing(0.5, 1),
                '&>svg': {
                    fontSize: 14,
                    color: 'inherit',
                    marginRight: theme.spacing(0.5),
                },
                '&.uppercase': {
                    textTransform: 'uppercase',
                },
                '&.disabled': {
                    opacity: 0.5,
                },
                [`.${MASTER_ITEM}:hover:not(.${MASTER_ITEM_SELECTED}) &`]: {
                    backgroundColor: 'white',
                },
                [`.${MASTER_ITEM_SELECTED} &`]: {
                    color: (props) => (props.color ? theme.palette[props.color].main : theme.palette.primary.main),
                },
            },
        };
    },
    { name: 'ItemLabel' },
);

export default function ItemLabel({
    icon,
    label,
    tooltip = '',
    color,
    variant,
    width = 'fit-content',
    uppercase = false,
    disabled = false,
}) {
    const classes = useStyles({ color, variant });

    return (
        <Tooltip title={tooltip} arrow>
            <div className={clsx(classes.root, { uppercase, disabled })} style={{ width }}>
                {icon}
                <Typography variant='caption'>{label}</Typography>
            </div>
        </Tooltip>
    );
}

ItemLabel.propTypes = {
    color: PropTypes.any,
    disabled: PropTypes.bool,
    icon: PropTypes.object,
    label: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    uppercase: PropTypes.bool,
    width: PropTypes.any,
    variant: PropTypes.any,
};
