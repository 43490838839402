import IconButton from '@material-ui/core/IconButton';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import MasterItem from '../../common/forms/MasterDetailsViewOld/MasterItem';
import DiasVacacionesDialog from './DiasVacacionesDialog';

export default function DiasVacacionesItem({ item, index, href, navigate }) {
    const location = useLocation();

    const isSelected = location.pathname === href;

    return (
        <MasterItem
            icon={BeachAccessIcon}
            title={item.year}
            subtitle={`${item.dias} días de vacaciones`}
            navigate={navigate}
            isSelected={isSelected}
            actions={
                <DiasVacacionesDialog
                    button={
                        <IconButton>
                            <EditIcon />
                        </IconButton>
                    }
                    index={index}
                />
            }
        />
    );
}

DiasVacacionesItem.propTypes = {
    href: PropTypes.string.isRequired,
    index: PropTypes.any,
    item: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    showHeader: PropTypes.bool,
};
