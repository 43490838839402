import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        wrapper: {
            overflow: 'auto',
            position: 'relative',
            display: 'flex',
            '&::before': {
                content: '""',
                position: 'absolute',
                left: 0,
                right: 0,
                top: -10,
                height: 10,
                boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.10)',
            },
        },
        content: {
            overflow: 'auto',
            padding: theme.spacing(2),
            width: '100%',
        },
    }),
    { name: 'FormTabWrapper' },
);

export default function FormTabWrapper({ children }) {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <div className={classes.content}>{children}</div>
        </div>
    );
}

FormTabWrapper.propTypes = {
    children: PropTypes.any,
};
