import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { contratosMantenimientoProvider, cuestionariosProvider } from '../../api';
import { useSnackbar } from 'material-ui-snackbar-provider';
import FileUploader from '../common/fields/FileUploader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { FormControl } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { ButtonDialog } from '../common/dialogs/ButtonDialog';
import DialogForm from '../common/forms/DialogForm';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import PropTypes from 'prop-types';
import InfoField from '../common/fields/InfoField';
import { Field } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import MuiTextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '../common/fields/KeyboardDatePicker';

const ContratoMantenimientoSchema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
    fecha_inicio: Yup.date().typeError('La fecha de inicio debe ser una fecha').required('Requerido'),
    fecha_fin: Yup.date()
        .typeError('La fecha de fin debe ser una fecha')
        .required('Requerido')
        .min(Yup.ref('fecha_inicio'), 'La fecha de fin debe ser posterior a la de inicio'),
});

function EditContratoMantenimientoDialog({
    onSave,
    id,
    contratoMantenimiento: originalContratoMantenimiento,
    ...props
}) {
    const snackbar = useSnackbar();

    const [contratoMantenimiento, setContratoMantenimiento] = useState(originalContratoMantenimiento);
    useEffect(() => setContratoMantenimiento(originalContratoMantenimiento), [originalContratoMantenimiento]);

    const [cuestionarioOptions, setCuestionarioOptions] = useState([]);
    useEffect(() => {
        cuestionariosProvider.getOnlyActiveAsOptions().then((cuestionarios) =>
            setCuestionarioOptions(
                cuestionarios.map(({ tipos_equipo: tipos, ...c }) => ({
                    ...c,
                    tiposEquipo: tipos.map((t) => t.id),
                })),
            ),
        );
    }, []);

    return (
        <DialogForm
            title='Editar contrato de mantenimiento'
            FormikProps={{
                initialValues: {
                    nombre: contratoMantenimiento.nombre,
                    instalacion: { id: contratoMantenimiento.instalacion_id },
                    fecha_inicio: new Date(contratoMantenimiento.fecha_inicio),
                    fecha_fin: new Date(contratoMantenimiento.fecha_fin),
                    num_servicios: contratoMantenimiento.num_servicios,
                    files: [],
                    cuestionarios: contratoMantenimiento.cuestionarios,
                    observaciones: contratoMantenimiento.observaciones,
                },
                validationSchema: ContratoMantenimientoSchema,
                onSubmit: (
                    { instalacion, fecha_inicio: fechaInicio, fecha_fin: fechaFin, files, cuestionarios, ...values },
                    { setSubmitting, setFieldError },
                ) => {
                    const formData = new FormData();
                    Object.entries(values).forEach(([key, value]) => formData.append(key, value));
                    formData.append('instalacion_id', instalacion.id);
                    formData.append('fecha_inicio', fechaInicio.toISOString());
                    formData.append('fecha_fin', fechaFin.toISOString());
                    formData.append('cuestionarios', JSON.stringify(cuestionarios));
                    new Array(...files).forEach((file) => formData.append('files', file));

                    contratosMantenimientoProvider
                        .save(formData, id)
                        .then((result) => {
                            onSave(result);
                            props.onClose();
                            snackbar.showMessage('Se ha actualizado el contrato de mantenimiento con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ touched, values, errors, setFieldValue }) => {
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InfoField label='Cliente' value={contratoMantenimiento.cliente} />
                        </Grid>
                        <Grid item xs={12}>
                            <InfoField label='Instalación' value={contratoMantenimiento.instalacion} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name='nombre' label='Nombre' fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <KeyboardDatePicker
                                name='fecha_inicio'
                                label='Desde'
                                format={'dd/MM/yyyy'}
                                fullWidth
                                autoOk
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <KeyboardDatePicker name='fecha_fin' label='Hasta' format={'dd/MM/yyyy'} fullWidth autoOk />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name='num_servicios' label='Numero de revisiones' fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name='cuestionarios'
                                fullWidth
                                component={Autocomplete}
                                options={cuestionarioOptions}
                                getOptionSelected={(option, value) => option.id === value?.id}
                                getOptionLabel={(option) => option.nombre ?? ''}
                                renderInput={(params) => (
                                    <MuiTextField
                                        {...params}
                                        label='Tipos de revisión a realizar'
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                                multiple
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name='observaciones'
                                label='Observaciones'
                                fullWidth
                                multiline
                                minRows={4}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel shrink>Ficheros adjuntos</InputLabel>
                                <List dense style={{ marginTop: 16 }}>
                                    {contratoMantenimiento.adjuntos.length === 0 ? (
                                        <ListItem>
                                            <ListItemText>No hay ficheros adjuntos</ListItemText>
                                        </ListItem>
                                    ) : (
                                        contratoMantenimiento.adjuntos.map((adjunto) => {
                                            const isImage = adjunto.content_type.startsWith('image');

                                            return (
                                                <ListItem
                                                    key={adjunto.id}
                                                    dense
                                                    button
                                                    onClick={() =>
                                                        window.open(adjunto.image_url || adjunto.original_url, '_blank')
                                                    }
                                                >
                                                    <ListItemAvatar>
                                                        {isImage ? (
                                                            <Avatar variant='square' src={adjunto.thumbnail_url} />
                                                        ) : (
                                                            <Avatar variant='square'>
                                                                <PictureAsPdfIcon />
                                                            </Avatar>
                                                        )}
                                                    </ListItemAvatar>
                                                    <ListItemText primary={adjunto.original_url.split('/').pop()} />
                                                    <ListItemSecondaryAction>
                                                        <ButtonDialog
                                                            button={
                                                                <IconButton edge='end' aria-label='delete'>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            }
                                                            dialogTitle='¿Borrar adjunto?'
                                                            dialogText='Esta opcion no se puede deshacer. ¿Deseas continuar?'
                                                            onConfirm={() => {
                                                                contratosMantenimientoProvider
                                                                    .action(
                                                                        `${contratoMantenimiento.id}/adjuntos/${adjunto.id}`,
                                                                        {
                                                                            method: 'delete',
                                                                        },
                                                                    )
                                                                    .then(() => {
                                                                        setContratoMantenimiento((instalacion) => ({
                                                                            ...instalacion,
                                                                            adjuntos: instalacion.adjuntos.filter(
                                                                                (adj) => adj.id !== adjunto.id,
                                                                            ),
                                                                        }));
                                                                    });
                                                            }}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            );
                                        })
                                    )}
                                </List>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FileUploader
                                id='upload-contrato_mantenimiento-adjuntos-file'
                                label='Añadir adjuntos'
                                file={values.files}
                                setFile={(files) => setFieldValue('files', files)}
                                multiple={true}
                                isImage={false}
                                accept='image/jpeg,image/gif,image/png,application/pdf'
                            />
                        </Grid>
                    </Grid>
                );
            }}
        </DialogForm>
    );
}

export default withButtonOpener(EditContratoMantenimientoDialog);

EditContratoMantenimientoDialog.propTypes = {
    clienteId: PropTypes.any,
    id: PropTypes.any,
    contratoMantenimiento: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
