import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { materialesProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import DialogForm from '../common/forms/DialogForm';
import MarcaField from './MarcaField';

const NewMaterialSchema = Yup.object().shape({
    descripcion: Yup.string().required('Requerido'),
});

const useStyles = makeStyles(
    (theme) => ({
        dialogPaper: {},
        colorEditor: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        totalSemana: {
            display: 'flex',
            justifyContent: 'flex-end',
            fontWeight: 'bold',
        },
    }),
    { name: 'NewMaterialDialog' },
);

function NewMaterialDialog({ onSave, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    return (
        <DialogForm
            title='Añadir material'
            maxWidth='sm'
            className={classes.dialogPaper}
            FormikProps={{
                initialValues: {
                    referencia: '',
                    descripcion: '',
                    precio_unitario: 0,
                    precio_unitario_coste: 0,
                    marca: null,
                },
                enableReinitialize: true,
                validationSchema: NewMaterialSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    materialesProvider
                        .save(values)
                        .then((data) => {
                            onSave && onSave(data.id);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                if (typeof err.message === 'string') {
                                    snackbar.showMessage(err.message);
                                } else {
                                    for (const [field, errors] of Object.entries(err.message)) {
                                        setFieldError(field, errors.join('\n'));
                                    }
                                    snackbar.showMessage('Ha ocurrido un error');
                                }
                            }
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField name='referencia' label='Referencia' fullWidth />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField name='descripcion' label='Descripción' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField name='precio_unitario_coste' label='Precio coste' type='number' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField name='precio_unitario' label='Precio venta' type='number' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <MarcaField />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(NewMaterialDialog);

NewMaterialDialog.propTypes = {
    duplicateId: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};
