import withStyles from '@material-ui/core/styles/withStyles';
import MuiTab from '@material-ui/core/Tab';
import MuiTabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import FormTabWrapper from './FormTabWrapper';

const Tabs = withStyles(
    (theme) => ({
        root: {
            backgroundColor: 'white',
            minHeight: 40,
            boxSizing: 'border-box',
            borderTop: `1px solid ${theme.palette.neutral.borderInputDisable}`,
        },
        flexContainer: {
            gap: 0,
        },
    }),
    { name: 'Tabs' },
)((props) => <MuiTabs centered indicatorColor='primary' {...props} />);

const Tab = withStyles(
    (theme) => ({
        root: {
            fontSize: theme.typography.body2.fontSize,
            textTransform: 'none',
            minWidth: 60,
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.neutral.primary,
            padding: theme.spacing(0, 1),
            minHeight: 39,
            boxSizing: 'border-box',
            borderBottom: '2px solid transparent',
            '&:hover': {
                color: theme.palette.neutral.grey4,
                backgroundColor: theme.palette.neutral.backgroundPrimary,
                borderColor: theme.palette.neutral.grey4,
            },
            '&$selected': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.backgroundTint,
            },
            '&:focus': {},
        },
        wrapper: {
            lineHeight: '24px',
        },
        selected: {},
    }),
    { name: 'Tab' },
)((props) => <MuiTab disableRipple {...props} />);

const getTabFullPath = function (tab, index, baseUrl) {
    return '' + baseUrl + (tab.props.path ? '/' + tab.props.path : index > 0 ? '/' + index : '');
};

export function FormTabs({ beforeContentComponent, children }) {
    const location = useLocation();
    const match = useRouteMatch();

    const validTabPaths = React.Children.toArray(children).map((tab, index) => getTabFullPath(tab, index, match.url));

    const tabsValue = validTabPaths.findIndex((path) => location.pathname.startsWith(path));

    return (
        <React.Fragment>
            <Tabs value={tabsValue >= 0 ? tabsValue : 0}>
                {React.Children.map(children, (tab, index) => {
                    const tabPath = getTabFullPath(tab, index, match.url);

                    return <Tab label={tab.props.label} component={Link} to={tabPath} replace />;
                })}
            </Tabs>
            {beforeContentComponent}
            <Switch>
                {React.Children.map(children, (tab, index) => {
                    const tabPath = getTabFullPath(tab, index, match.path);

                    return <Route path={tabPath}>{tab}</Route>;
                })}
            </Switch>
        </React.Fragment>
    );
}

export function FormTab({ addWrapper, ...props }) {
    return React.Children.map(props.children, (child) => {
        let component = React.cloneElement(child);
        if (addWrapper) {
            component = <FormTabWrapper>{component}</FormTabWrapper>;
        }

        return component;
    });
}

FormTabs.propTypes = {
    beforeContentComponent: PropTypes.any,
    children: PropTypes.any,
};
