import IconButton from '@material-ui/core/IconButton';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { tareasProvider } from '../../../../api';
import { createTiempo } from '../../../../api/tareas-functions';
import { withButtonOpener } from '../../../../hooks/withButtonOpener';
import { format, formatTiempo } from '../../../../utils';
import DeleteDialog from '../../../common/dialogs/DeleteDialog';
import DialogEditor from '../../../common/forms/DialogEditor';
import { LocationButton } from '../../../fichajes/VerFichaje';
import EntradaIcon from '../../../icons/Entrada';
import SalidaIcon from '../../../icons/Salida';
import EditarMarcajeDialog from './EditarMarcajeDialog';

const List = withStyles(
    (theme) => ({
        root: {},
        padding: {
            padding: 0,
        },
    }),
    { name: 'List' },
)(MuiList);

const ListItem = withStyles(
    (theme) => ({
        root: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            '&>.MuiTypography-root': {
                fontWeight: 'normal',
                color: theme.palette.neutral.primary,
            },
            '& .marcaje': {
                display: 'flex',
                width: '100%',
                gap: `${theme.spacing(5)}px`,
            },
            '&>.marcaje>div': {
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                gap: `${theme.spacing(1)}px`,
            },
            '&>.marcaje>div.actions': {
                gap: 0,
            },
            '& .entrada svg.tipoIcon': {
                color: '#2CBD96',
            },
            '& .salida svg.tipoIcon': {
                color: '#E94F4F',
            },
            '& .coordsIcon': {
                color: theme.palette.neutral.primary,
                marginLeft: theme.spacing(0.5),
            },
            '&>.marcaje>div.entrada, &>.marcaje>div.salida': {
                minWidth: 180,
            },
            '& .timeDiff': {
                fontSize: 10,
                color: '#4C60FE',
                backgroundColor: '#e6eeef',
                padding: theme.spacing(0.5, 1),
                borderRadius: 20,
                marginLeft: 8,
                textAlign: 'center',
                width: 60,
                lineHeight: '16px',
            },
            '& .timeDiff.hidden': {
                visibility: 'hidden',
            },
            '& .MuiIconButton-root': {
                padding: theme.spacing(1),
            },
        },
    }),
    { name: 'ListItem' },
)(MuiListItem);

const useStyles = makeStyles(
    (theme) => ({
        root: {
            maxWidth: 650,
        },
        dialogTitle: {
            flex: 1,
        },
        info: {
            gap: `${theme.spacing(1)}px`,
            display: 'flex',
            alignItems: 'center',
            '& .MuiTypography-root': {
                fontWeight: 'normal',
            },
            '& .MuiSvgIcon-root': {
                fontSize: 18,
            },
        },
        total: {
            display: 'flex',
            '& strong': {
                display: 'block',
                width: 440,
                fontWeight: 500,
            },
        },
        emptyMarcaje: {
            minWidth: 400,
            color: theme.palette.neutral.grey4,
        },
    }),
    { name: 'MarcajesDialog' },
);

const tipoIcons = {
    entrada: EntradaIcon,
    salida: SalidaIcon,
};

function MarcajeItem({ marcaje, tipo }) {
    const hora = marcaje[`hora_${tipo}`];
    const horaReal = marcaje[`hora_${tipo}_real`];
    const coords = marcaje[`coords_${tipo}`];

    const Icon = tipoIcons[tipo];
    return (
        <div className={tipo}>
            <Icon className='tipoIcon' />
            {hora ? (
                <>
                    {format(hora, 'HH:mm')}

                    {coords && coords.latitude ? (
                        <LocationButton coords={coords} />
                    ) : (
                        <Tooltip arrow title='Sin coordenadas GPS registradas'>
                            <LocationOffIcon className='coordsIcon' />
                        </Tooltip>
                    )}
                    <span className='timeDiff'>{format(horaReal, 'HH:mm')}</span>
                </>
            ) : (
                'No especificado'
            )}
        </div>
    );
}

function MarcajesDialog({ jornada, tareaId, subtareas, onSave, ...props }) {
    const classes = useStyles();
    const tiempoTotal = useMemo(() => {
        let minutos = 0;

        jornada.marcajes.forEach((marcaje) => {
            minutos += marcaje.minutos;
        });

        return createTiempo(minutos);
    }, [jornada.marcajes]);

    return (
        <DialogEditor
            title={
                <>
                    <Typography variant='h6' className={classes.dialogTitle}>
                        Tiempo de trabajo
                    </Typography>

                    <EditarMarcajeDialog
                        button={
                            <IconButton>
                                <AddIcon />
                            </IconButton>
                        }
                        marcaje={null}
                        tareaId={tareaId}
                        fecha={jornada.fecha}
                        operarioId={jornada.operario_id}
                        onSave={onSave}
                        subtareas={subtareas}
                    />
                </>
            }
            disableBodyGutters
            maxWidth='xs'
            classes={{ root: classes.root }}
            cancelButtonText='Cerrar'
            {...props}
        >
            <List>
                <ListItem divider>
                    <div className={classes.info}>
                        <InfoIcon />{' '}
                        <Typography variant='subtitle2'>
                            Si editas los marcajes, la mano de obra del parte de trabajo se actualizará automáticamente.
                        </Typography>
                    </div>
                </ListItem>
                {jornada.marcajes.length === 0 && (
                    <ListItem divider>
                        <div className='marcaje'>
                            <div>No hay marcajes en este parte de trabajo</div>
                        </div>
                    </ListItem>
                )}
                {jornada.marcajes.map((marcaje, i) => {
                    return (
                        <ListItem key={i} divider>
                            {marcaje.subtarea && <Typography variant='subtitle2'>{marcaje.subtarea.titulo}</Typography>}
                            <div className={'marcaje'}>
                                {marcaje.hora_entrada ? (
                                    <>
                                        <MarcajeItem marcaje={marcaje} tipo='entrada' />
                                        <MarcajeItem marcaje={marcaje} tipo='salida' />
                                    </>
                                ) : (
                                    <div className={classes.emptyMarcaje}>
                                        No se ha especificado hora de entrada y salida
                                    </div>
                                )}
                                <div>{formatTiempo(createTiempo(marcaje.minutos))}</div>
                                <div className='actions'>
                                    <EditarMarcajeDialog
                                        button={
                                            <IconButton size='small'>
                                                <EditIcon fontSize='inherit' />
                                            </IconButton>
                                        }
                                        marcaje={marcaje}
                                        tareaId={tareaId}
                                        fecha={jornada.fecha}
                                        operarioId={jornada.operario_id}
                                        onSave={onSave}
                                        subtareas={subtareas}
                                    />
                                    <DeleteDialog
                                        text='¿Estás seguro que quieres eliminar este marcaje?'
                                        onConfirm={() => {
                                            tareasProvider
                                                .actionOnId(
                                                    tareaId,
                                                    `jornadas/${jornada.fecha}/marcajes/${marcaje.id}`,
                                                    {
                                                        operario_id: jornada.operario_id,
                                                    },
                                                    { method: 'delete' },
                                                )
                                                .then(onSave);
                                        }}
                                        button={
                                            <IconButton size='small' disabled={jornada.marcajes.length === 1}>
                                                <DeleteIcon fontSize='inherit' />
                                            </IconButton>
                                        }
                                    />
                                </div>
                            </div>
                        </ListItem>
                    );
                })}
                <ListItem divider>
                    <div className={classes.total}>
                        <strong>Duración del trabajo según marcajes:</strong> {formatTiempo(tiempoTotal)}
                    </div>
                </ListItem>
            </List>
        </DialogEditor>
    );
}

export default withButtonOpener(MarcajesDialog);

MarcajesDialog.propTypes = {
    jornada: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    subtareas: PropTypes.any,
    tareaId: PropTypes.any,
};

MarcajeItem.propTypes = {
    marcaje: PropTypes.any,
    tipo: PropTypes.any,
};
