import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { proyectosProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import { KeyboardDatePicker } from '../../common/fields/KeyboardDatePicker';
import DialogForm from '../../common/forms/DialogForm';

const FechaEditorSchema = Yup.object().shape({
    fecha_creacion: Yup.date().typeError('Fecha inválida').required('Requerido'),
});

function FechaEditor({ avanceObra, onSave, ...props }) {
    const snackbar = useSnackbar();

    return (
        <DialogForm
            title='Editar avance de obra'
            FormikProps={{
                initialValues: {
                    fecha_creacion: new Date(avanceObra.fecha_creacion),
                    certificacion_obra: avanceObra.certificacion_obra
                        ? {
                              id: avanceObra.certificacion_obra.id,
                              fecha_creacion: new Date(avanceObra.certificacion_obra.fecha_creacion),
                          }
                        : null,
                },
                enableReinitialize: true,
                validationSchema: FechaEditorSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    proyectosProvider
                        .action(`avances-obra/${avanceObra.id}`, {
                            method: 'post',
                            body: JSON.stringify(values),
                        })
                        .then(() => {
                            props.onClose();
                            setSubmitting(false);
                            onSave();
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [key, value] of Object.entries(err.message)) {
                                    setFieldError(key, value[0]);
                                }
                            } else {
                                snackbar.showMessage('Ha ocurrido un error');
                            }

                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ values }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <KeyboardDatePicker label='Fecha avance de obra' name='fecha_creacion' autoOk />
                    </Grid>

                    {values.certificacion_obra && (
                        <Grid item xs={12}>
                            <KeyboardDatePicker
                                label='Fecha certificación'
                                name='certificacion_obra.fecha_creacion'
                                autoOk
                            />
                        </Grid>
                    )}
                </Grid>
            )}
        </DialogForm>
    );
}

FechaEditor.propTypes = {
    avanceObra: PropTypes.object,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};

export default withButtonOpener(FechaEditor);
