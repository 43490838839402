import { useCallback, useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { clientesProvider } from '../../api';
import { useFetchData } from '../../hooks/useFetchData';
import EditForm from '../common/forms/EditForm/EditForm';
import { EditClienteFormContent } from './EditClienteFormContent';

export const SERVICIOS_ROUTE = 'servicios';
export const PROYECTOS_ROUTE = 'proyectos';
export const EQUIPOS_ROUTE = 'equipos';
export const DIRECCIONES_ROUTE = 'direcciones';
export const OBSERVACIONES_ROUTE = 'observaciones';

export default function EditClienteForm() {
    const { id } = useParams();
    const { isExact: isRouteExact, url } = useRouteMatch();
    const history = useHistory();

    const fetchDataFn = useCallback(() => clientesProvider.getAll(id), [id]);
    const { data, fetchData } = useFetchData(fetchDataFn, null);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (!isRouteExact || !data) return;

        history.replace(`${url}/${SERVICIOS_ROUTE}`);
    }, [data, url, isRouteExact]);

    return (
        data && (
            <>
                <EditForm data={data} fetchData={fetchData}>
                    <EditClienteFormContent />
                </EditForm>
            </>
        )
    );
}
