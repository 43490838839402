import PropTypes from 'prop-types';
import { CustomFilter } from '../../../common/CustomFilter';

export function ParteTrabajoAlbaranFilter({ onFilterChange }) {
    const options = [
        {
            label: 'Todos',
            onClick: () => onFilterChange(null),
        },
        {
            label: 'Si',
            onClick: () => {
                onFilterChange(true);
            },
        },
        {
            label: 'No',
            onClick: () => {
                onFilterChange(false);
            },
        },
    ];

    return <CustomFilter label='Tiene albarán' options={options} />;
}

ParteTrabajoAlbaranFilter.propTypes = {
    onFilterChange: PropTypes.any,
};
