import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import FilterListIcon from '@material-ui/icons/FilterList';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

function getTabFullPath(path, index, baseUrl) {
    return '' + baseUrl + (path ? '/' + path : index > 0 ? '/' + index : '');
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            overflow: 'hidden',
            flex: 1,
        },
        list: {
            display: 'flex',
            flexDirection: 'column',
            width: 385,
            background: 'white',
            borderTop: `1px solid ${theme.palette.neutral.borderInputDisable}`,
        },
        listHeaderContainer: {
            position: 'relative',
            '&::after': {
                content: '""',
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                height: 10,
                boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.10)',
            },
        },
        listHeader: {
            padding: theme.spacing(0, 1, 0, 2),
            minHeight: 64,
            display: 'flex',
            alignItems: 'center',
            '&>.MuiTypography-root': {
                flex: 1,
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        filterContainer: {
            display: 'flex',
            flexDirection: 'column',
            borderTop: `1px solid ${theme.palette.neutral.borderInputDisable}`,
            padding: theme.spacing(2),
            gap: `${theme.spacing(1)}px`,
        },
        itemsList: {
            overflowY: 'auto',
        },
        contentWrapper: {
            flex: 1,
            overflow: 'auto',
            position: 'relative',
            display: 'flex',
            '&::before': {
                content: '""',
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                boxShadow: 'inset 4px 4px 6px 0px rgba(0, 0, 0, 0.10)',
                pointerEvents: 'none',
            },
        },
        content: {
            flex: 1,
            padding: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
            overflowY: 'auto',
        },
        actions: {
            display: 'flex',
            '& .MuiIconButton-root:not(:first-child)': {
                marginLeft: theme.spacing(-1),
            },
        },
        empty: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            margin: theme.spacing(6, 'auto'),
            gap: `${theme.spacing(3)}px`,
        },
        emptyText: {
            color: theme.palette.neutral.grey4,
        },
        emptyComponentWrapper: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& .MuiPaper-root': {
                minWidth: 600,
            },
        },
    }),
    { name: 'MasterDetailsView' },
);

export default function MasterDetailsView({
    title,
    items: allItems,
    emptyComponent,
    masterComponent,
    masterExtraProps,
    detailsComponent,
    detailsExtraProps,
    actions,
    className,
    idKey = 'id',
    FilterComponent,
    isEmpty,
}) {
    const [items, setItems] = useState(allItems);
    const classes = useStyles();
    const match = useRouteMatch();
    const history = useHistory();
    const { isExact: isRouteExact, url } = useRouteMatch();
    const [showFilters, setShowFilters] = useState(false);

    useEffect(() => setItems(allItems), [allItems]);

    useEffect(() => {
        if (allItems.length === 0) return;

        if (!isRouteExact) {
            return;
        }

        history.replace(`${url}/${allItems[0][idKey]}`);
    }, [allItems, url, isRouteExact]);

    return (
        <div className={clsx(classes.root, className)}>
            {(allItems.length === 0 && isEmpty === undefined) || isEmpty === true ? (
                <div className={classes.emptyComponentWrapper}>{emptyComponent}</div>
            ) : (
                <>
                    <div className={classes.list}>
                        {title && (
                            <div className={classes.listHeaderContainer}>
                                <div className={clsx(classes.listHeader, showFilters ? classes.filterOpen : null)}>
                                    <Typography variant='h3'>{title}</Typography>
                                    <div className={classes.actions}>
                                        {FilterComponent && (
                                            <IconButton onClick={() => setShowFilters((show) => !show)}>
                                                <FilterListIcon color={showFilters ? 'primary' : 'inherit'} />
                                            </IconButton>
                                        )}
                                        {actions}
                                    </div>
                                </div>
                                {FilterComponent && (
                                    <Collapse in={showFilters}>
                                        <div
                                            className={clsx(
                                                classes.filterContainer,
                                                showFilters ? classes.filterOpen : null,
                                            )}
                                        >
                                            <FilterComponent items={allItems} onFilter={setItems} />
                                        </div>
                                    </Collapse>
                                )}
                            </div>
                        )}
                        {items.length === 0 && isEmpty === false && (
                            <Paper elevation={0}>
                                <div className={classes.empty}>
                                    <img src='/images/empty-master-items.png' />
                                    <Typography>No hemos encontrado resultados con este filtro</Typography>
                                </div>
                            </Paper>
                        )}
                        <div className={classes.itemsList}>
                            {items.map((item, index) => {
                                const tabPath = getTabFullPath(item[idKey], index, match.url);

                                const props = masterExtraProps ? masterExtraProps(item, index) : {};

                                return (
                                    <Link
                                        to={tabPath}
                                        key={index}
                                        component={masterComponent}
                                        index={index}
                                        item={item}
                                        replace
                                        {...props}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className={classes.contentWrapper}>
                        <div className={classes.content}>
                            <Switch>
                                {items.map((item, index) => {
                                    const tabPath = getTabFullPath(item[idKey], index, match.path);

                                    const props = detailsExtraProps
                                        ? typeof detailsExtraProps === 'function'
                                            ? detailsExtraProps(item, index)
                                            : detailsExtraProps
                                        : {};

                                    const indexOnAllItemsArray = allItems.findIndex((i) => i[idKey] === item[idKey]);

                                    if (indexOnAllItemsArray === -1) {
                                        return null;
                                    }

                                    return (
                                        <Route path={tabPath} key={index}>
                                            {React.createElement(detailsComponent, {
                                                item,
                                                index: indexOnAllItemsArray,
                                                ...props,
                                            })}
                                        </Route>
                                    );
                                })}
                            </Switch>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

MasterDetailsView.propTypes = {
    title: PropTypes.string,
    actions: PropTypes.object,
    className: PropTypes.any,
    detailsComponent: PropTypes.any.isRequired,
    detailsExtraProps: PropTypes.any,
    emptyComponent: PropTypes.any,
    FilterComponent: PropTypes.any,
    idKey: PropTypes.string,
    isEmpty: PropTypes.any,
    itemContainerClassName: PropTypes.any,
    items: PropTypes.array.isRequired,
    masterComponent: PropTypes.any.isRequired,
    masterExtraProps: PropTypes.func,
};
