import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { usePermisos } from '../../../AuthState';
import ServicioEstadoChip from '../../servicios/ServicioEstadoChip';
import EditarTareaButtonDialog from '../../tareas/EditarTareaDialog';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(2),
            '& .MuiTypography-body1': {
                fontWeight: theme.typography.fontWeightMedium,
            },
            '& .MuiTypography-subtitle1': {
                color: theme.palette.neutral.grey4,
            },
            '& .MuiTypography-root strong': {
                color: theme.palette.text.primary,
                fontWeight: theme.typography.fontWeightMedium,
                marginRight: theme.spacing(0.5),
            },
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(0.5)}px`,
            '& .MuiTypography-root': {
                flex: 1,
            },
        },
    }),
    { name: 'TareaCard' },
);

export default function TareaCard({ tarea, onSave }) {
    const classes = useStyles();
    const history = useHistory();
    const gestionarClientes = usePermisos('gestionar_clientes');

    const planificacion = tarea.planificaciones[0];
    const operarios = planificacion ? planificacion.operarios.map((o) => o.operario.nombre) : [];

    return (
        <Paper elevation={0} className={classes.root}>
            <div className={classes.header}>
                <Typography>
                    <strong>Servicio:</strong>
                    {tarea.descripcion}
                </Typography>

                <ServicioEstadoChip value={tarea.estado} />

                <Tooltip title='Ver ficha'>
                    <IconButton
                        style={{ padding: 6 }}
                        aria-label='Ver ficha'
                        onClick={() => history.push(`/servicios/${tarea.id}`)}
                    >
                        <VisibilityIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
                <EditarTareaButtonDialog
                    id={tarea.id}
                    button={
                        <Tooltip title='Editar servicio'>
                            <IconButton style={{ padding: 6 }} aria-label='Editar'>
                                <EditIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                    }
                    onSave={onSave}
                />
            </div>
            <Typography variant='subtitle1'>
                <strong>Cliente:</strong>
                {gestionarClientes ? <Link to={`/clientes/${tarea.cliente_id}`}>{tarea.cliente}</Link> : tarea.cliente}
            </Typography>
            <Typography variant='subtitle1'>
                <strong>Dirección:</strong>
                {tarea.direccion_completa}
            </Typography>
            <Typography variant='subtitle1'>
                <strong>Instalación:</strong>
                <Link to={`/instalaciones/${tarea.contrato_mantenimiento.instalacion_id}`}>
                    {tarea.contrato_mantenimiento.instalacion}
                </Link>
            </Typography>
            <Typography variant='subtitle1'>
                <strong>Contrato:</strong>
                <Link to={`/contratos-mantenimiento/${tarea.contrato_mantenimiento.id}`}>
                    {tarea.contrato_mantenimiento.nombre}
                </Link>
            </Typography>
            <Typography variant='subtitle1'>
                <strong>Operarios:</strong>
                {operarios.length > 0 ? operarios.join(', ') : 'Sin asignar'}
            </Typography>
            <Typography variant='subtitle1'>
                <strong>Equipos a revisar:</strong>
                {tarea.tipos_equipo.map((t) => t.nombre).join(', ')}
            </Typography>
            <Typography variant='subtitle1'>
                <strong>Tareas a realizar:</strong>
                {tarea.cuestionarios.map((t) => t.nombre).join(', ')}
            </Typography>
        </Paper>
    );
}

TareaCard.propTypes = {
    onSave: PropTypes.any,
    tarea: PropTypes.any,
};
