import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { preparacionesMaterialProvider } from '../../../api';
import { formatDateTime } from '../../../utils';
import { Accordion, AccordionDetails, AccordionSummary } from '../../common/Accordion';
import DeleteDialog from '../../common/dialogs/DeleteDialog';
import ItemLabel from '../../common/forms/MasterDetailsViewOld/ItemLabel';

const Table = withStyles(
    (theme) => ({
        root: {
            borderCollapse: 'separate',
            borderSpacing: '16px',
        },
    }),
    { name: 'Table' },
)(MuiTable);

const TableCell = withStyles(
    (theme) => ({
        root: {
            padding: 0,
            borderBottom: '1px solid #ccc',
            '&:not(:first-of-type)': {
                whiteSpace: 'nowrap',
            },
            '.MuiTableHead-root &': {
                border: 'none',
            },
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        sizeSmall: {
            '&:last-child': {
                padding: 0,
            },
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

const useStyles = makeStyles(
    (theme) => ({
        root: {
            // display: 'flex',
            // flexDirection: 'column',
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '50px',
            paddingLeft: theme.spacing(2),
        },
        notas: {
            whiteSpace: 'pre-wrap',
        },
        actions: {
            display: 'flex',
            marginLeft: 'auto',
            alignItems: 'center',
        },
        empty: {
            margin: theme.spacing(2),
        },
    }),
    { name: 'PreparacionSolicitudesList' },
);

export default function PreparacionSolicitudesList({ preparacionMaterial, onUpdate }) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();
    const [expanded, setExpanded] = useState();

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const id = hash.replace('#solicitud-', '');
            setExpanded(id);
        } else {
            setExpanded(false);
        }
    }, [location.hash]);

    const handleChange = (panel) => (event, newExpanded) => {
        if (newExpanded) history.replace(`${match.url}#solicitud-${panel}`);
        else history.replace(match.url);
    };

    const solicitudes = preparacionMaterial.solicitudes_material;

    return (
        <Paper elevation={0} className={classes.root}>
            <Typography variant='body1' className={classes.title}>
                Solicitudes de los operarios
            </Typography>
            {solicitudes.length === 0 && (
                <Typography variant='body2' className={classes.empty}>
                    No hay ninguna solicitud de material.
                </Typography>
            )}
            {solicitudes.map((solicitud) => (
                <Accordion
                    key={solicitud.id}
                    square
                    expanded={expanded === solicitud.id}
                    onChange={handleChange(solicitud.id)}
                >
                    <AccordionSummary>
                        {expanded === solicitud.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        <Typography variant='body2'>
                            {solicitud.operario.nombre} | {formatDateTime(solicitud.fecha_actualizacion)}
                        </Typography>
                        <div className={classes.actions}>
                            {solicitud.procesada && <ItemLabel label='Procesada' color='success' uppercase />}
                            {!solicitud.procesada && preparacionMaterial.estado === 'PREPARADO' && (
                                <ItemLabel label='No procesada' color='error' uppercase />
                            )}
                            {!solicitud.procesada && preparacionMaterial.estado !== 'PREPARADO' && (
                                <>
                                    <Tooltip arrow title='Marcar como procesada'>
                                        <IconButton
                                            onClick={(ev) => {
                                                ev.stopPropagation();
                                                preparacionesMaterialProvider
                                                    .procesarSolicitud(solicitud.id)
                                                    .then(onUpdate);
                                            }}
                                        >
                                            <DoneIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <DeleteDialog
                                        text='¿Quieres borrar la solicitud del operario?'
                                        button={
                                            <IconButton
                                                aria-label='Borrar solicitud de material'
                                                onClick={(ev) => {
                                                    ev.stopPropagation();
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                        deleteButtonText='Quitar'
                                        onConfirm={() => {
                                            preparacionesMaterialProvider.borrarSolicitud(solicitud.id).then(onUpdate);
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        {solicitud.notas && (
                            <Typography variant='body2' className={classes.notas}>
                                {solicitud.notas}
                            </Typography>
                        )}
                        {solicitud.lineas.length > 0 && (
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: '100%' }}>Descripción</TableCell>
                                        <TableCell>Uds. a preparar</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {solicitud.lineas.map((linea, i) => {
                                        return (
                                            <TableRow key={i}>
                                                <TableCell>
                                                    <div className={classes.descripcion}>{linea.descripcion}</div>
                                                </TableCell>
                                                <TableCell align='right'>{linea.unidades}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        )}
                    </AccordionDetails>
                </Accordion>
            ))}
        </Paper>
    );
}

PreparacionSolicitudesList.propTypes = {
    onUpdate: PropTypes.any,
    preparacionMaterial: PropTypes.any,
};
