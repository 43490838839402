import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useCallback, useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { tareasProvider } from '../../api';
import { dataProvider } from '../../api/equipos';
import { useFetchData } from '../../hooks/useFetchData';
import { formatDate } from '../../utils';
import { DateColumnFilter, dateFilterFn } from '../common/list/DateColumnFilter';
import BaseListView, { withTableState } from '../common/list/ListView';
import { ClienteLinkCell } from '../dashboard/ClienteLink';

const ListView = withTableState('albaranesVacios', BaseListView);

export default function AlbaranesVacios() {
    const fetchDataFn = useCallback(() => tareasProvider.getAlbaranesVacios(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        dataProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Núm. albarán',
                accessor: 'numero',
            },
            {
                Header: 'Fecha creacion',
                accessor: 'fecha_creacion',
                Cell: ({ value }) => formatDate(new Date(value)),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Ref. servicio',
                accessor: 'tarea_numero',
            },
            {
                Header: 'Título',
                accessor: 'descripcion',
            },
            {
                Header: 'Cliente',
                accessor: 'cliente',
                Cell: ClienteLinkCell,
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            hideDelete: true,
            tableOptions: {
                fillEmptySpace: false,
                expandedTable: false,
            },
        }),
        [],
    );

    if (data.length === 0) return null;

    return (
        <ListView
            tableTitle='Albaranes de servicio vacíos'
            basePath='/servicios'
            columns={columns}
            data={data}
            options={options}
            onDelete={onDelete}
            extraActions={(row) => (
                <>
                    <Tooltip title='Ver albarán de servicio'>
                        <IconButton
                            style={{ padding: 6 }}
                            aria-label='Ver'
                            to={`/servicios/${row.tarea_id}/albaranes-servicio/${row.id}`}
                            component={NavLink}
                        >
                            <VisibilityIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </>
            )}
        />
    );
}
