import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { tipoOptions } from '../../api/tareas';
import useAuthState from '../../AuthState';
import AdjuntosField from '../common/fields/AdjuntosField/AdjuntosField';
import CategoriaTareaField from '../common/fields/CategoriaTareaField';
import ClienteField from '../common/fields/ClienteField';
import DireccionField from '../common/fields/DireccionField';
import ProyectoField from '../common/fields/ProyectoField';
import SelectField from '../common/fields/SelectField';
import { SwitchWithLabel } from '../common/fields/Switch';
import ChecklistsFormControl from './checklists/ChecklistsFormControl';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: `${theme.spacing(4)}px`,
            marginTop: theme.spacing(2),
        },
        row: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
        },
        column: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
            paddingRight: 16,
        },
    }),
    { name: 'NuevaTareaForm' },
);

export function DatosGeneralesForm({ style, disabled = false, clienteDisabled, direccionDisabled, proyectoDisabled }) {
    const classes = useStyles();
    const {
        touched,
        errors,
        initialValues: { tipo: initialTipo },
    } = useFormikContext();

    const {
        userInfo: { preferencias: { usar_referencias_tareas: usarReferencias, usar_checklists: usarChecklists } = {} },
    } = useAuthState();

    return (
        <div className={classes.root} style={style}>
            <div className={classes.column}>
                <TextField name='descripcion' label='Título' fullWidth disabled={disabled} />
                <ClienteField
                    error={touched.cliente && !!errors.cliente}
                    helperText={touched.cliente && errors.cliente}
                    disabled={clienteDisabled || disabled}
                />
                <DireccionField
                    error={touched.direccion && !!errors.direccion}
                    helperText={touched.direccion && errors.direccion}
                    disabled={direccionDisabled || disabled}
                />
                <ProyectoField
                    error={touched.proyecto && !!errors.proyecto}
                    helperText={touched.proyecto && errors.proyecto}
                    disabled={proyectoDisabled}
                />
                <Field name='urgente' component={SwitchWithLabel} label='Servicio urgente' />
                <AdjuntosField disabled={disabled} />
            </div>
            <div className={classes.column}>
                <div className={classes.row}>
                    {usarReferencias && (
                        <TextField name='referencia_interna' label='Referencia interna' fullWidth disabled={disabled} />
                    )}
                    <FormControl fullWidth>
                        <InputLabel shrink>Duración estimada</InputLabel>
                        <div className={clsx(classes.row, 'MuiInput-formControl')}>
                            <TextField
                                name='tiempoEstimadoHoras'
                                disabled={disabled}
                                InputProps={{
                                    endAdornment: <InputAdornment position='end'>h</InputAdornment>,
                                }}
                                type='number'
                            />
                            <TextField
                                name='tiempoEstimadoMinutos'
                                disabled={disabled}
                                InputProps={{
                                    endAdornment: <InputAdornment position='end'>m</InputAdornment>,
                                }}
                                type='number'
                            />
                        </div>
                    </FormControl>
                </div>
                <div className={classes.row}>
                    <Field
                        name='tipo'
                        id='tipo'
                        component={SelectField}
                        label='Tipo de trabajo'
                        fullWidth
                        selectOptions={tipoOptions}
                        disabled={disabled || initialTipo === 'MANTENIMIENTO'}
                    />
                    <CategoriaTareaField />
                </div>
                {usarChecklists && <ChecklistsFormControl name='checklists_tarea' />}
                <TextField
                    name='detalles'
                    label='Detalles para el operario'
                    fullWidth
                    multiline
                    minRows={6}
                    disabled={disabled}
                    InputLabelProps={{ shrink: true }}
                    placeholder='Escribe detalles para que tus operarios sepan qué han de hacer'
                />
                <TextField
                    name='notas_internas'
                    label='Notas internas'
                    fullWidth
                    multiline
                    minRows={6}
                    disabled={disabled}
                    InputLabelProps={{ shrink: true }}
                    placeholder='Escribe notas para administración. P.ej. números de presupuesto, factura, pedidos de material, incidencias, etc...'
                />
            </div>
        </div>
    );
}

DatosGeneralesForm.fieldNames = [
    'descripcion',
    'cliente',
    'direccion',
    'proyecto',
    'adjuntos',
    'files',
    'tipo',
    'detalles',
    'referencia_interna',
    'notas_internas',
    'checklists_tarea',
];

DatosGeneralesForm.propTypes = {
    clienteDisabled: PropTypes.bool,
    direccionDisabled: PropTypes.any,
    proyectoDisabled: PropTypes.bool,
    disabled: PropTypes.bool,
    style: PropTypes.any,
};
