import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import { useCallback, useEffect, useState } from 'react';
import { operariosProvider } from '../../api';
import useAuthState from '../../AuthState';
import { useFetchData } from '../../hooks/useFetchData';
import { getInitials } from '../../utils';
import FicharView from '../fichajes/FicharDialog/FicharView';
import SalidaIcon from '../icons/Salida';
import { BaseEditOperarioDialog } from '../operarios/EditOperarioDialog';
import AboutDialog from './AboutDialog';
import ButtonMenu from './ButtonMenu';

const useStyles = makeStyles(
    (theme) => ({
        button: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: theme.spacing(2, 4, 2, 2),
            gap: '8px',
            cursor: 'pointer',
        },
        avatar: {
            width: 32,
            height: 32,
            backgroundColor: '#ECBB02',
            fontSize: 14,
            fontWeight: 500,
        },
        accountInfo: {
            display: 'flex',
            flexDirection: 'column',
            color: 'white',
            '& .MuiTypography-root': {
                lineHeight: '16px',
                fontWeight: theme.typography.fontWeightRegular,
                whiteSpace: 'nowrap',
            },
            '& strong': {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
    }),
    { name: 'AccountMenu' },
);

export default function AccountMenu() {
    const classes = useStyles();
    const { logout, userInfo, isAdministrativo } = useAuthState();
    const [open, setOpen] = useState(false);
    const [miCuentaOpen, setMiCuentaOpen] = useState(false);
    const [ficharOpen, setFicharOpen] = useState(false);

    const operarioId = userInfo.user_id;
    const fetchDataFn = useCallback(() => operariosProvider.getAll(operarioId), [operarioId]);

    const { data: operarioData, fetchData } = useFetchData(fetchDataFn, null);

    useEffect(() => {
        if (miCuentaOpen) fetchData();
    }, [miCuentaOpen, fetchData]);

    const options = isAdministrativo
        ? [
              {
                  // ButtonComponent: EditOperarioDialogButton,
                  // ButtonComponentProps: {
                  //     operarioId: userInfo.user_id,
                  // },
                  action: () => setMiCuentaOpen(true),
                  icon: <AccountCircleIcon />,
                  label: 'Mi cuenta',
              },
              {
                  action: () => setFicharOpen(true),
                  icon: <AddIcon />,
                  label: 'Fichar horario',
              },
          ]
        : [
              {
                  path: '/mi-cuenta',
                  icon: <AccountCircleIcon />,
                  label: 'Mi cuenta',
              },
              {
                  path: '/mi-cuenta/suscripcion',
                  icon: <CreditCardIcon />,
                  label: 'Suscripción',
              },
              {
                  path: '/mi-cuenta/ajustes',
                  icon: <SettingsIcon />,
                  label: 'Ajustes',
              },
          ];

    return (
        <>
            <AboutDialog open={open} onClose={() => setOpen(false)} />
            {operarioData && (
                <BaseEditOperarioDialog
                    open={miCuentaOpen}
                    fetchData={fetchData}
                    data={operarioData}
                    onClose={() => setMiCuentaOpen(false)}
                />
            )}
            <FicharView open={ficharOpen} onClose={() => setFicharOpen(false)} />
            <ButtonMenu
                button={
                    <div className={classes.button}>
                        <Avatar className={classes.avatar}>{getInitials(userInfo.company)}</Avatar>
                        <div className={classes.accountInfo}>
                            <Typography variant='subtitle2'>
                                <strong>{isAdministrativo ? userInfo.name : 'Administrador'}</strong>
                            </Typography>
                            <Typography variant='subtitle2'>{userInfo.company}</Typography>
                        </div>
                    </div>
                }
                items={[
                    ...options,
                    { divider: true },
                    {
                        action: () => window.open('https://labory.helpscoutdocs.com', '_blank'),
                        icon: <HelpIcon />,
                        label: 'Centro de ayuda',
                    },
                    // {
                    //     icon: <InfoIcon />,
                    //     label: 'Acerca de Labory',
                    //     action: () => setOpen(true),
                    // },
                    { divider: true },
                    {
                        action: logout,
                        icon: <SalidaIcon />,
                        label: 'Desconectar',
                    },
                ]}
            />
        </>
    );
}
