import InputLabel from '@material-ui/core/InputLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Field, Form, Formik, useField, useFormikContext } from 'formik';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { tareasProvider } from '../../../api';
import { usePreferencias } from '../../../AuthState';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import { SwitchWithLabel } from '../../common/fields/Switch';
import DialogEditor from '../../common/forms/DialogEditor';
import { afterGeneratingPdf } from '../../presupuestos/GenerarPdfDialog';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 600,
        },
        body: {
            gap: `${theme.spacing(2)}px`,
        },
        title: {
            color: theme.palette.neutral.grey4,
        },
        fields: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
        },
        field: {
            flex: 1,
        },
    }),
    { name: 'GenerarPdfDialog' },
);

export const GenerarPdfDialogSchema = Yup.object().shape({
    desglosar_explicacion_operarios: Yup.boolean().required('Requerido'),
    desglosar_marcajes_operario: Yup.boolean().required('Requerido'),
    desglosar_subtareas_operario: Yup.boolean().required('Requerido'),
    mostrar_precios_pdf: Yup.boolean().required('Requerido'),
});

export function useGenerarPdfDialogDefaultParams() {
    const preferencias = usePreferencias();

    return useMemo(
        () => ({
            desglosar_explicacion_operarios: preferencias.desglosar_explicacion_operarios,
            desglosar_marcajes_operario: preferencias.desglosar_marcajes_operario,
            desglosar_subtareas_operario: preferencias.desglosar_subtareas_operario,
            mostrar_precios_pdf: preferencias.mostrar_precios_pdf,
        }),
        [preferencias],
    );
}

export function GenerarPdfForm({ fieldName, label }) {
    const classes = useStyles();
    const usarChecklists = usePreferencias('usar_checklists');

    const prefix = fieldName ? `${fieldName}.` : '';

    const { setFieldValue } = useFormikContext();
    const [{ value: desglosarExplicaciones }, ,] = useField(`${prefix}desglosar_explicacion_operarios`);
    const [, { initialValue: desglosarSubtareasInitialValue }] = useField(`${prefix}desglosar_subtareas_operario`);
    const [, { initialValue: desglosarMaterialesInitialValue }] = useField(`${prefix}desglosar_materiales_operario`);

    useEffect(() => {
        if (desglosarExplicaciones) {
            setFieldValue(`${prefix}desglosar_subtareas_operario`, desglosarSubtareasInitialValue);
            setFieldValue(`${prefix}desglosar_materiales_operario`, desglosarMaterialesInitialValue);
            return;
        }

        setFieldValue(`${prefix}desglosar_subtareas_operario`, false);
        setFieldValue(`${prefix}desglosar_materiales_operario`, false);
    }, [desglosarExplicaciones]);

    return (
        <>
            <div className={classes.fields}>
                {label && <InputLabel shrink>{label}</InputLabel>}
                <Field name={`${prefix}mostrar_precios_pdf`} component={SwitchWithLabel} label='Mostrar precios' />
            </div>
            <div className={classes.fields}>
                <Field
                    name={`${prefix}desglosar_explicacion_operarios`}
                    component={SwitchWithLabel}
                    label='Desglosar explicaciones de los operarios por fecha'
                />
                {usarChecklists && (
                    <Field
                        name={`${prefix}desglosar_subtareas_operario`}
                        component={SwitchWithLabel}
                        label='Incluir tareas del operario en el desglose'
                        disabled={!desglosarExplicaciones}
                    />
                )}
                <Field
                    name={`${prefix}desglosar_materiales_operario`}
                    component={SwitchWithLabel}
                    label='Incluir material del operario en el desglose'
                    disabled={!desglosarExplicaciones}
                />
            </div>
            <div className={classes.fields}>
                <Field
                    name={`${prefix}desglosar_marcajes_operario`}
                    component={SwitchWithLabel}
                    label='Desglosar marcajes de entrada y salida del operario'
                />
            </div>
        </>
    );
}

GenerarPdfForm.propTypes = {
    label: PropTypes.string,
    fieldName: PropTypes.string,
};

function GenerarPdfDialog({ albaran, tareaId, open, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const initialValues = useGenerarPdfDialogDefaultParams();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={GenerarPdfDialogSchema}
            enableReinitialize
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                const promise = albaran
                    ? tareasProvider.generarAlbaranPdf(albaran.id, values)
                    : tareasProvider.getAll(`${tareaId}/pdf-async?${new URLSearchParams(values).toString()}`);

                const alternativeUrl = albaran
                    ? tareasProvider.getAlbaranPdfURL(albaran.id, values)
                    : tareasProvider.getTareaPdfURL(tareaId, values);

                promise
                    .then(
                        afterGeneratingPdf({
                            onFinish: () => {
                                setSubmitting(false);
                                props.onClose();
                            },
                            alternativeUrl,
                            snackbar,
                        }),
                    )
                    .catch((err) => {
                        setSubmitting(false);
                        props.onClose();
                        console.error(err);
                        snackbar.showMessage(err.body.message);
                    });
            }}
        >
            {({ isSubmitting, submitForm }) => (
                <Form>
                    <DialogEditor
                        title='Generar PDF del albarán de servicio'
                        onSave={submitForm}
                        classes={{
                            root: classes.root,
                            body: classes.body,
                        }}
                        open={open}
                        canSave={!isSubmitting}
                        saveButtonText='Generar'
                        {...props}
                    >
                        <GenerarPdfForm />
                    </DialogEditor>
                </Form>
            )}
        </Formik>
    );
}

GenerarPdfDialog.propTypes = {
    albaran: PropTypes.any,
    tareaId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.any,
};

export default withButtonOpener(GenerarPdfDialog);
