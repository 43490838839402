import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { useParams } from 'react-router-dom';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import EditFormHeader from '../common/forms/EditForm/EditFormHeader';
import EditFormHeaderItem from '../common/forms/EditForm/EditFormHeaderItem';
import EditFormHeaderItemList from '../common/forms/EditForm/EditFormHeaderItemList';
import { FormTab, FormTabs } from '../common/forms/EditForm/FormTabs';
import ClienteInfoTooltip from '../servicios/ClienteInfoTooltip';
import DireccionInfoTooltip from '../servicios/DireccionInfoTooltip';
import EditContratoMantenimientoDialog from './EditContratoMantenimientoDialog';
import { EQUIPOS_ROUTE, PLANIFICACION_ROUTE, SERVICIOS_ROUTE } from './EditContratoMantenimientoForm';
import { EditContratoMantenimientoMenuOptions } from './EditContratoMantenimientoMenuOptions';
import EquiposList from './EquiposList';
import PlanificadorAnual from './Planificador/PlanificadorAnual';
import ServiciosList from './ServiciosList';

export function EditContratoMantenimientoFormContent() {
    const { data, fetchData } = useEditFormData();
    const { id } = useParams();
    return (
        <>
            <EditFormHeader
                label='Contrato de mantenimiento'
                name={data.nombre}
                actions={
                    <>
                        <EditContratoMantenimientoDialog
                            button={
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            }
                            onSave={fetchData}
                            contratoMantenimiento={data}
                            id={id}
                        />
                        <EditContratoMantenimientoMenuOptions />
                    </>
                }
            >
                <EditFormHeaderItemList>
                    <EditFormHeaderItem label='Instalación'>{data.instalacion}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Cliente'>
                        <ClienteInfoTooltip id={data.cliente_id} label={data.cliente} />
                    </EditFormHeaderItem>
                    <EditFormHeaderItem label='Dirección'>
                        <DireccionInfoTooltip
                            direccion={data.direccion.direccion_completa}
                            label={data.direccion.nombre}
                        />
                    </EditFormHeaderItem>
                    <EditFormHeaderItem label='Num. de revisiones'>{data.num_servicios}</EditFormHeaderItem>
                </EditFormHeaderItemList>
            </EditFormHeader>

            <FormTabs>
                <FormTab addWrapper path={EQUIPOS_ROUTE} label='Equipos'>
                    <EquiposList />
                </FormTab>
                <FormTab addWrapper path={PLANIFICACION_ROUTE} label='Planificación'>
                    <PlanificadorAnual />
                </FormTab>
                <FormTab addWrapper path={SERVICIOS_ROUTE} label='Servicios'>
                    <ServiciosList />
                </FormTab>
            </FormTabs>
        </>
    );
}
