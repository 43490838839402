import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { regularizacionesHorasProvider } from '../../api';
import { tipoOptions } from '../../api/regularizaciones_horas';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { setBeginOfDay, setEndOfDay } from '../../utils';
import Button from '../common/Button';
import { DateRangePicker } from '../common/fields/DateRangePicker';
import SelectField from '../common/fields/SelectField';

const RegularizacionHorasSchema = Yup.object().shape({
    motivo: Yup.string().required('Requerido'),
    fecha_inicio: Yup.date().typeError('La fecha de inicio debe ser una fecha').required('Requerido'),
    fecha_fin: Yup.date()
        .typeError('La fecha de fin debe ser una fecha')
        .required('Requerido')
        .min(Yup.ref('fecha_inicio'), 'La fecha de fin debe ser posterior a la de inicio'),
    horas: Yup.number().typeError('Debe ser un número').required('Requerido').min(1, 'Debe ser mayor a 0'),
});

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 600,
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
    }),
    { name: 'NewRegularizacionDialog' },
);

function NewRegularizacionDialog({ open, onClose, onSave, operario }) {
    const classes = useStyles();

    const snackbar = useSnackbar();

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby='nueva-vacaciones-title'
            open={open}
            classes={{
                paper: classes.root,
            }}
            fullWidth={false}
            maxWidth='lg'
        >
            <Formik
                initialValues={{
                    tipo: 'EXTRAS',
                    motivo: '',
                    fecha_creacion: new Date(),
                    fecha_inicio: new Date(),
                    fecha_fin: new Date(),
                    horas: 0,
                }}
                enableReinitialize={true}
                validationSchema={RegularizacionHorasSchema}
                onSubmit={(
                    // eslint-disable-next-line camelcase
                    { fecha_inicio, fecha_fin, horas, ...values },
                    { setSubmitting, setFieldError },
                ) => {
                    setBeginOfDay(fecha_inicio);
                    setEndOfDay(fecha_fin);

                    regularizacionesHorasProvider
                        .save({
                            operario_id: operario.id,
                            fecha_inicio,
                            fecha_fin,
                            minutos: horas * 60,
                            ...values,
                        })
                        .then((updatedVacaciones) => {
                            onClose();
                            setSubmitting(false);
                            onSave(updatedVacaciones);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                }}
            >
                {({ isSubmitting, values, errors, touched, setFieldValue }) => {
                    return (
                        <Form className={classes.form}>
                            <DialogTitle id='parte-trabajo-title'>
                                Añadir regularización para {operario.nombre}
                            </DialogTitle>
                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field
                                            name='tipo'
                                            id='tipo'
                                            component={SelectField}
                                            label='Tipo de regularizacion'
                                            fullWidth
                                            selectOptions={tipoOptions}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name='horas'
                                            InputProps={{
                                                endAdornment: <InputAdornment position='end'>h</InputAdornment>,
                                            }}
                                            type='number'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DateRangePicker
                                            label='Fechas'
                                            allDay={true}
                                            fechaInicioKey='fecha_inicio'
                                            fechaFinKey='fecha_fin'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField name='motivo' label='Motivo' fullWidth multiline maxRows={5} />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button color='outlined' disabled={isSubmitting} onClick={onClose}>
                                    Cancelar
                                </Button>
                                <Button type='submit' color='info' disabled={isSubmitting}>
                                    Guardar
                                </Button>
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}

export default withButtonOpener(NewRegularizacionDialog);

NewRegularizacionDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
    operario: PropTypes.object,
};
