import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';

export const Accordion = withStyles(
    (theme) => ({
        root: {
            borderRadius: 8,
            borderBottom: `1px solid ${theme.palette.divider}`,
            boxShadow: 'none',
            '&:last-child': {
                borderBottom: 0,
            },
            '&:before': {
                display: 'none',
            },
            '&$expanded': {
                margin: 0,
            },
        },
        expanded: {},
    }),
    { name: 'Accordion' },
)(MuiAccordion);

export const AccordionSummary = withStyles(
    (theme) => ({
        root: {
            marginBottom: -1,
            minHeight: 50,
            '&$expanded': {
                minHeight: 50,
            },
            '& .MuiIconButton-root': {
                padding: 8,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        content: {
            margin: 0,
            alignItems: 'center',
            gap: `${theme.spacing(1)}px`,
            '&$expanded': {
                margin: 0,
                // margin: '12px 0',
            },
        },
        expanded: {},
    }),
    { name: 'AccordionSummary' },
)(MuiAccordionSummary);

export const AccordionDetails = withStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(0, 2, 2, 2),
        },
    }),
    { name: 'AccordionDetails' },
)(MuiAccordionDetails);
