import PropTypes from 'prop-types';
import PreparacionMaterialDetails from './PreparacionMaterialDetails';
import SolicitudMaterialDetails from './SolicitudMaterialDetails';

export default function PreparacionSolicitudDetails(props) {
    const Component = props.item.type === 'PREPARACION' ? PreparacionMaterialDetails : SolicitudMaterialDetails;

    return <Component {...props} />;
}

PreparacionSolicitudDetails.propTypes = {
    item: PropTypes.any,
};
