import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import { presupuestosProvider } from '../../../api';
import { tipoPresupuestoLineaLabels, tipoUnidadesOptions } from '../../../api/presupuestos';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import SelectField from '../../common/fields/SelectField';
import DialogForm from '../../common/forms/DialogForm';
import PrecioFields from './PrecioFields';
import ReferenciaField from './ReferenciaField';

const DescomposicionSchema = Yup.object().shape({
    referencia: Yup.string(),
    concepto: Yup.string().required('Requerido'),
    unidades: Yup.number()
        .required('Requerido')
        .test('gt-0', 'Debe ser mayor que 0', (value) => value > 0),
    precio_coste: Yup.number().required('Requerido').min(0, 'Mínimo 0'),
    precio_venta: Yup.number().required('Requerido').min(0, 'Mínimo 0'),
    margen: Yup.number().required('Requerido').min(0, 'Mínimo 0'),
});

function AddDescomposicionDialog({ presupuestoId, parentId, tipoLinea, onSave, ...props }) {
    const match = useRouteMatch();
    const proyectoId = match.url.startsWith('/proyectos') ? match.params.id : null;
    const [duplicateLineaId, setDuplicateLineaId] = useState(null);
    const snackbar = useSnackbar();

    useEffect(() => {
        if (props.open) {
            setDuplicateLineaId(null);
        }
    }, [props.open]);

    return (
        <DialogForm
            title={`Añadir ${tipoPresupuestoLineaLabels[tipoLinea].toLowerCase()}`}
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    referencia: '',
                    concepto: '',
                    unidades: 1,
                    tipo_unidades: tipoLinea === 'MANO_OBRA' ? 'HORAS' : 'UNIDADES',
                    descripcion: '',
                    observaciones: '',
                    precio_coste: 0,
                    precio_venta: 0,
                    margen: 0,
                },
                validationSchema: DescomposicionSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    values.tipo_linea = tipoLinea;
                    values.parent_id = parentId;
                    values.duplicate_linea_id = duplicateLineaId;

                    presupuestosProvider
                        .addLinea(presupuestoId, values)
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                if (err.message instanceof Object) {
                                    for (const [field, errors] of Object.entries(err.message)) {
                                        setFieldError(field, errors.join('\n'));
                                    }
                                } else {
                                    snackbar.showMessage(err.message);
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ values, setFieldValue }) => (
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <ReferenciaField
                            proyectoId={proyectoId}
                            presupuestoId={presupuestoId}
                            tipoLinea={tipoLinea}
                            clearSelection={() => setDuplicateLineaId(null)}
                            onSelect={(linea) => {
                                setFieldValue('referencia', linea.referencia);
                                setFieldValue('concepto', linea.concepto);
                                setFieldValue('tipo_unidades', linea.tipo_unidades);
                                setFieldValue('descripcion', linea.descripcion);
                                setFieldValue('precio_coste', linea.precio_coste);
                                setFieldValue('precio_venta', linea.precio_venta);

                                const margen =
                                    linea.precio_coste !== 0 ? (linea.precio_venta / linea.precio_coste - 1) * 100 : 0;
                                setFieldValue('margen', Math.round((margen + Number.EPSILON) * 100) / 100);

                                setDuplicateLineaId(linea.id);
                            }}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <TextField name='concepto' label='Concepto' fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField name='unidades' label='Unidades' fullWidth type='number' />
                    </Grid>
                    <Grid item xs={9}>
                        <Field
                            name='tipo_unidades'
                            id='tipo_unidades'
                            component={SelectField}
                            label='Tipo de unidades'
                            fullWidth
                            selectOptions={tipoUnidadesOptions}
                        />
                    </Grid>
                    <PrecioFields setFieldValue={setFieldValue} values={values} />
                    <Grid item xs={12}>
                        <TextField
                            name='descripcion'
                            label='Descripción'
                            fullWidth
                            multiline
                            minRows={4}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name='observaciones'
                            label='Observaciones'
                            fullWidth
                            multiline
                            minRows={4}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

AddDescomposicionDialog.propTypes = {
    presupuestoId: PropTypes.string,
    parentId: PropTypes.string,
    tipoLinea: PropTypes.string,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool,
};

export default withButtonOpener(AddDescomposicionDialog);
