import { Paper } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import EditFormHeaderItem from '../EditForm/EditFormHeaderItem';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            width: '100%',
        },
        title: {
            lineHeight: '24px',
            whiteSpace: 'nowrap',
            '& strong': {
                fontWeight: theme.typography.fontWeightMedium,
                lineHeight: '24px',
            },
        },
        header: {
            flex: 1,
            display: 'flex',
            padding: theme.spacing(2),
            gap: `${theme.spacing(4)}px`,
        },
        headerInfo: {
            display: 'flex',
            gap: `${theme.spacing(0.5, 2)}`,
            flexWrap: 'wrap',
        },
        actions: {
            marginLeft: 'auto',
            display: 'flex',
            alignItems: 'center',
            marginRight: theme.spacing(0.5),
        },
    }),
    { name: 'MasterDetailsHeader' },
);

export default function MasterDetailsHeader({ title, subtitle, items, actions }) {
    const classes = useStyles();

    return (
        <Paper elevation={0} className={classes.root}>
            <div className={classes.header}>
                <Typography variant='h3' className={classes.title}>
                    <strong>{title}</strong> {subtitle}
                </Typography>
                {items && (
                    <div className={classes.headerInfo}>
                        {items.map((item, index) => (
                            <EditFormHeaderItem key={index} label={item.label}>
                                {item.value}
                            </EditFormHeaderItem>
                        ))}
                    </div>
                )}
            </div>
            {actions && <div className={classes.actions}>{actions}</div>}
        </Paper>
    );
}

MasterDetailsHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.node.isRequired,
        }),
    ),
    actions: PropTypes.any,
};
