import { useEffect, useMemo, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import Grid from '@material-ui/core/Grid';
import * as Yup from 'yup';
import InfoField from '../common/fields/InfoField';
import { fichajesProvider, operariosProvider } from '../../api';
import Aviso from '../common/Aviso';
import MarcajesField from './MarcajesField';
import { getMarcajesFromHorarios } from '../../api/operarios';
import DialogForm from '../common/forms/DialogForm';
import PropTypes from 'prop-types';

const FichajeSchema = Yup.object().shape({
    marcajes: Yup.array().min(1, 'Tiene que haber al menos un marcaje'),
});

export function EditarMultiplesFichajesDialog({ fichajes, onSave, ...props }) {
    const [horario, setHorario] = useState({
        operario: '',
        marcajes: [],
    });

    useEffect(() => {
        if (!props.open || !fichajes || fichajes.length === 0) return;

        const fichaje = fichajes[0];
        const operarioId = fichaje.operario_id;

        operariosProvider.getHorarios(operarioId).then((horarios) => {
            setHorario({
                operario: fichaje.operario,
                marcajes: getMarcajesFromHorarios(horarios.length > 0 ? horarios[0].horas[0] : [], new Date()),
            });
        });
    }, [fichajes, props.open]);

    const fichajeIds = useMemo(() => fichajes && fichajes.map((fichaje) => fichaje.id), [fichajes]);

    return (
        <DialogForm
            title='Editar varios fichajes'
            FormikProps={{
                initialValues: {
                    marcajes: horario.marcajes,
                },
                enableReinitialize: true,
                validationSchema: FichajeSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    fichajesProvider
                        .saveMultiples(fichajeIds, values)
                        .then((updatedFichaje) => {
                            setSubmitting(false);
                            onSave(updatedFichaje);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                        });
                },
            }}
            {...props}
        >
            {({ values }) => {
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Aviso
                                icon={<InfoIcon />}
                                text='Se actualizarán los fichajes seleccionados según el horario de trabajo del operario.'
                                color='#8F95AF'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InfoField label='Operario' value={horario.operario} />
                        </Grid>
                        <MarcajesField marcajes={values.marcajes} />
                    </Grid>
                );
            }}
        </DialogForm>
    );
}

export function EditarMultiplesFichajesButton({ fichajes, onSave, ...props }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <IconButton onClick={() => setOpen(true)} {...props}>
                <EditIcon />
            </IconButton>
            <EditarMultiplesFichajesDialog
                open={open}
                onSave={() => {
                    onSave();
                    setOpen(false);
                }}
                onClose={() => setOpen(false)}
                fichajes={fichajes}
            />
        </>
    );
}

EditarMultiplesFichajesDialog.propTypes = {
    fichajes: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};

EditarMultiplesFichajesButton.propTypes = {
    fichajes: PropTypes.any,
    onSave: PropTypes.any,
};
