import PropTypes from 'prop-types';
import { CustomFilter } from '../common/CustomFilter';

export function OperariosActivoFilter({ onFilterChange }) {
    const options = [
        {
            label: 'Solo activos',
            onClick: () => onFilterChange(true),
        },
        {
            label: 'Todos',
            onClick: () => {
                onFilterChange(false);
            },
        },
    ];

    return <CustomFilter label='Operarios' options={options} />;
}

OperariosActivoFilter.propTypes = {
    onFilterChange: PropTypes.any,
};
