import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { formatDate } from '../../../utils';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterItem from '../../common/forms/MasterDetailsView/MasterItem';
import { useJornadasBetweenDates } from './ChecklistDetails/ChecklistDetails';
import ChecklistItemOptions from './ChecklistItemOptions';
import { CHECKLIST_RESUMEN_KEY } from './ChecklistsList';

const useStyles = makeStyles(
    (theme) => ({
        childItemTitle: {
            marginLeft: theme.spacing(2),
        },
    }),
    { name: 'ChecklistItem' },
);

export default function ChecklistItem({ item, index, href, navigate, onDelete, showHeader = false }) {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const { fetchData } = useEditFormData();

    const isSelected = location.pathname === href;

    const isChildSelected = location.pathname.startsWith(href);

    const isResumen = item.id === CHECKLIST_RESUMEN_KEY;
    const jornadas = useJornadasBetweenDates(item.fecha_inicio, item.fecha_fin);

    const uniqueJornadaFechas = useMemo(() => {
        return [...new Set(jornadas.map((j) => j.fecha))].sort();
    }, [jornadas]);

    const showChild = !isResumen && (isSelected || isChildSelected);

    const title = isResumen
        ? 'Resumen general'
        : `Tareas del ${formatDate(item.fecha_inicio)} al ${
              item.fecha_fin ? formatDate(item.fecha_fin) : 'próximo cambio'
          }`;

    return (
        <>
            <MasterItem
                title={title}
                navigate={navigate}
                isSelected={isSelected}
                actions={!isResumen && <ChecklistItemOptions item={item} onUpdate={fetchData} />}
            />
            {showChild &&
                uniqueJornadaFechas.map((fecha, idx) => {
                    const thisUrl = href + `/${fecha}`;
                    const thisChildSelected = location.pathname === thisUrl;
                    return (
                        <MasterItem
                            key={idx}
                            title={`Resumen del ${formatDate(fecha)}`}
                            navigate={() => history.replace(thisUrl)}
                            isSelected={thisChildSelected}
                            onDelete={onDelete}
                            classes={{
                                title: classes.childItemTitle,
                            }}
                        />
                    );
                })}
        </>
    );
}

ChecklistItem.propTypes = {
    href: PropTypes.string.isRequired,
    index: PropTypes.any,
    item: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    showHeader: PropTypes.bool,
};
