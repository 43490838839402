import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { notasDiaProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import { formatDate, formatISODate } from '../../../utils';
import Button from '../../common/Button';
import DialogForm from '../../common/forms/DialogForm';
import NotaAccordion from './NotaAccordion';

const NotasDiaSchema = Yup.object().shape({
    notas: Yup.array().of(
        Yup.object().shape({
            operarios: Yup.array().min(1, 'Selecciona al menos un operario'),
            titulo: Yup.string().required('Requerido'),
            mensaje: Yup.string(),
        }),
    ),
});

const useStyles = makeStyles(
    (theme) => ({
        dialogContent: {
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
        },
        empty: {
            margin: theme.spacing(2),
        },
    }),
    { name: 'NotasDiaDialog' },
);

function NotasDiaDialog({ fecha, onSave, ...props }) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [notas, setNotas] = useState([]);

    useEffect(() => {
        if (!props.open) {
            setExpanded(false);
            setNotas([]);
            return;
        }

        notasDiaProvider.getAll(formatISODate(fecha)).then(setNotas);
    }, [props.open]);

    const handleChange = (index) => (event, isExpanded) => {
        setExpanded(isExpanded ? index : false);
    };

    return (
        <DialogForm
            disablePadding
            title={`Notas del día: ${formatDate(fecha)}`}
            classes={{
                content: classes.dialogContent,
            }}
            FormikProps={{
                initialValues: {
                    notas,
                },
                validationSchema: NotasDiaSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    const notasExistentes = values.notas.filter((nota) => nota.id !== null);
                    const notasNuevas = values.notas.filter((nota) => nota.id === null).map(({ id, ...nota }) => nota);
                    const notasCambiadas = notasExistentes.filter((nota) => {
                        const original = notas.find((n) => n.id === nota.id);
                        const operariosChanged = nota.operarios.some(
                            (op) => !original.operarios.some((o) => o.operario.id === op.operario.id),
                        );

                        return nota.titulo !== original.titulo || nota.mensaje !== original.mensaje || operariosChanged;
                    });

                    const valuesToSave = {
                        notas: notasCambiadas,
                        new_notas: notasNuevas,
                    };

                    notasDiaProvider
                        .save(valuesToSave, formatISODate(fecha))
                        .then(() => {
                            onSave();
                            props.onClose();
                        })
                        .catch((error) => {
                            if (error.response?.status === 400) {
                                Object.entries(error.response.data).forEach(([key, value]) => {
                                    setFieldError(key, value);
                                });
                            }
                        });

                    setSubmitting(false);
                },
            }}
            {...props}
        >
            {({ values }) => (
                <>
                    <FieldArray name='notas'>
                        {({ push, remove }) => (
                            <>
                                {values.notas.length === 0 && (
                                    <Typography variant='body2' className={classes.empty}>
                                        No hay notas para este día.
                                    </Typography>
                                )}
                                {values.notas.map((nota, index) => (
                                    <NotaAccordion
                                        key={index}
                                        fieldKey={`notas.${index}`}
                                        expanded={expanded === index}
                                        onExpand={handleChange(index)}
                                        onDelete={() => remove(index)}
                                    />
                                ))}

                                <Button
                                    color='primary'
                                    startIcon={<AddIcon />}
                                    size='small'
                                    style={{ alignSelf: 'flex-start' }}
                                    onClick={() => {
                                        push({
                                            id: null,
                                            operarios: [],
                                            titulo: '',
                                            mensaje: '',
                                        });
                                        setExpanded(values.notas.length);
                                    }}
                                >
                                    Añadir nota
                                </Button>
                            </>
                        )}
                    </FieldArray>
                </>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(NotasDiaDialog);

NotasDiaDialog.propTypes = {
    open: PropTypes.any,
    fecha: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    selectedRows: PropTypes.any,
};
