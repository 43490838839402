import { useCallback, useState } from 'react';

export function useFetchData(fetchFn, defaultValue = [], resetWhileLoading = false) {
    const [data, setData] = useState(defaultValue);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = useCallback(
        function () {
            setIsLoading(true);
            if (resetWhileLoading) setData(defaultValue);
            return fetchFn()
                .then((data) => {
                    setIsLoading(false);
                    setData(data);

                    return data;
                })
                .catch((err) => {
                    setIsLoading(false);
                    setError(err);
                });
        },
        [fetchFn],
    );

    return { data, isLoading, error, fetchData, setData };
}
