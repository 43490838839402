import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import DialogForm from '../../common/forms/DialogForm';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import PropTypes from 'prop-types';
import { preparacionesMaterialProvider } from '../../../api';
import { KeyboardDatePicker } from '../../common/fields/KeyboardDatePicker';

const CompraMaterialSchema = Yup.object().shape({
    notas: Yup.string().required('Requerido'),
});

function NuevaCompraMaterialDialog({ onSave, preparacionMaterialId, ...props }) {
    return (
        <DialogForm
            title='Añadir compra de material'
            FormikProps={{
                initialValues: {
                    referencia: '',
                    fecha_llegada_prevista: null,
                    notas: '',
                },
                validationSchema: CompraMaterialSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    preparacionesMaterialProvider
                        .addCompra(preparacionMaterialId, values)
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ touched, errors, values, setFieldValue }) => (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField name='referencia' label='Referencia' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDatePicker label='Fecha prevista de llegada' name='fecha_llegada_prevista' autoOk />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name='notas'
                            label='Notas'
                            fullWidth
                            multiline
                            minRows={6}
                            InputLabelProps={{ shrink: true }}
                            placeholder='Escribe aqui notas como por ejemplo la lista de productos pedidos'
                        />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(NuevaCompraMaterialDialog);

NuevaCompraMaterialDialog.propTypes = {
    preparacionMaterialId: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
