import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import isPast from 'date-fns/isPast';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { tareasProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import DialogForm from '../common/forms/DialogForm';
import { Tab, Tabs } from '../common/Tabs';
import { createEmptyPlanificacion } from '../tareas/PlanificacionForm';
import { BasePlanificacionFormItem } from '../tareas/PlanificacionFormItem';

const PlanificacionSchema = Yup.object().shape({
    planificacion: Yup.object()
        .nullable()
        .shape({
            fecha_inicio: Yup.date().typeError('Hay que introducir una fecha').required('Hay que introducir una fecha'),
            fecha_fin: Yup.date()
                .typeError('Hay que introducir una fecha')
                .required('Hay que introducir una fecha')
                .min(Yup.ref('fecha_inicio'), 'La hora de fin debe ser posterior a la de inicio'),
            // operarios es un array donde entre todas las opciones como mucho haber solo 1 elemento que se haya establecido su propiedad es_responsable a true
            operarios: Yup.array()
                .of(
                    Yup.object().shape({
                        es_responsable: Yup.boolean(),
                    }),
                )
                .test('unique-responsable', 'Solo puede haber un responsable', (operarios) => {
                    const responsablesCount = operarios.filter((operario) => operario.es_responsable).length;
                    return responsablesCount <= 1;
                }),
        }),
    nuevaPlanificacion: Yup.object()
        .nullable()
        .shape({
            fecha_inicio: Yup.date().typeError('Hay que introducir una fecha').required('Hay que introducir una fecha'),
            fecha_fin: Yup.date()
                .typeError('Hay que introducir una fecha')
                .required('Hay que introducir una fecha')
                .min(Yup.ref('fecha_inicio'), 'La hora de fin debe ser posterior a la de inicio'),
        }),
});

const useStyles = makeStyles(
    (theme) => ({
        tabsRoot: {
            marginBottom: theme.spacing(1),
        },
        tabsContainer: {
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'EditPlanificacionDialog' },
);

const EDITAR_PLANIFICACION = 'editar-planificacion';
const NUEVA_PLANIFICACION = 'nueva-planificacion';

export function BaseEditPlanificacionDialog({ planificacionId, onSave, tarea, ...props }) {
    const classes = useStyles();
    const [planificacion, setPlanificacion] = useState(null);
    const [fixedOperarioIds, setFixedOperarioIds] = useState([]);
    const [modo, setModo] = useState(NUEVA_PLANIFICACION);
    useEffect(() => {
        if (!props.open || planificacionId === null || planificacionId === undefined) {
            setPlanificacion(null);
            setModo(NUEVA_PLANIFICACION);
            return;
        }

        tareasProvider.getAll(`planificaciones/${planificacionId}`).then((result) => {
            setPlanificacion(result.planificacion);
            setFixedOperarioIds(result.fixed_operarios);
            setModo(EDITAR_PLANIFICACION);
        });

        return () => setPlanificacion(null);
    }, [planificacionId, props.open]);

    function getPlanificacionInitialValues(planificacion) {
        if (!planificacion) return null;

        const fechaInicio = new Date(planificacion.fecha_inicio);
        const fechaFin = new Date(planificacion.fecha_fin);

        const allDay =
            fechaFin === null ||
            (fechaInicio.getHours() === 0 &&
                fechaInicio.getMinutes() === 0 &&
                fechaFin.getHours() === 23 &&
                fechaFin.getMinutes() === 59);

        return {
            ...planificacion,
            editable: !isPast(fechaFin),
            fecha_inicio: fechaInicio,
            fecha_fin: fechaFin,
            usarFechaInicioJornada: planificacion.fecha_inicio_jornada !== null,
            fecha_inicio_jornada: planificacion.fecha_inicio_jornada
                ? new Date(planificacion.fecha_inicio_jornada)
                : null,
            fecha_visita: planificacion.fecha_visita ? new Date(planificacion.fecha_visita) : null,
            allDay,
            vehiculo: planificacion.vehiculo_id
                ? { id: planificacion.vehiculo_id, nombre: planificacion.vehiculo.nombre }
                : null,
        };
    }

    return (
        <DialogForm
            title='Editar planificación'
            maxWidth='md'
            FormikProps={{
                initialValues: {
                    tarea,
                    planificacion: getPlanificacionInitialValues(planificacion),
                    nuevaPlanificacion: tarea?.planificacion ?? createEmptyPlanificacion(),
                },
                validationSchema: PlanificacionSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    // eslint-disable-next-line camelcase
                    const { fecha_inicio, fecha_fin, allDay, vehiculo, ...rest } =
                        modo === EDITAR_PLANIFICACION ? values.planificacion : values.nuevaPlanificacion;
                    if (allDay) {
                        fecha_inicio.setHours(0, 0, 0, 0);
                        fecha_fin.setHours(23, 59, 59, 0);
                    }
                    const planificacion = {
                        ...rest,
                        fecha_inicio,
                        fecha_fin,
                        vehiculo_id: vehiculo ? vehiculo.id : null,
                        fecha_cambio: new Date(),
                    };

                    const url =
                        modo === EDITAR_PLANIFICACION
                            ? `planificaciones/${planificacionId}`
                            : `${tarea.id}/planificaciones`;

                    tareasProvider
                        .action(url, {
                            method: 'post',
                            body: JSON.stringify(planificacion),
                        })
                        .then(onSave);
                },
            }}
            {...props}
        >
            {({ values }) => (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            name='tarea.cliente'
                            label='Cliente'
                            disabled
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name='tarea.descripcion'
                            label='Título'
                            disabled
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    {planificacionId && (
                        <Grid item xs={12}>
                            <Tabs
                                disableGutters
                                value={modo}
                                onChange={(ev, newValue) => setModo(newValue)}
                                aria-label='Modo de edición'
                                classes={{
                                    root: classes.tabsRoot,
                                    flexContainer: classes.tabsContainer,
                                }}
                            >
                                <Tab label='Editar planificación' value={EDITAR_PLANIFICACION} />
                                <Tab label='Añadir planificación' value={NUEVA_PLANIFICACION} />
                            </Tabs>
                        </Grid>
                    )}
                    {modo === EDITAR_PLANIFICACION ? (
                        <Grid item xs={12}>
                            <BasePlanificacionFormItem
                                fieldKey='planificacion'
                                planificacion={values.planificacion}
                                fixedOperarioIds={fixedOperarioIds}
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <BasePlanificacionFormItem
                                small
                                fieldKey='nuevaPlanificacion'
                                planificacion={values.nuevaPlanificacion}
                                fixedOperarioIds={[]}
                            />
                        </Grid>
                    )}
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(BaseEditPlanificacionDialog);

BaseEditPlanificacionDialog.propTypes = {
    onClose: PropTypes.func,
    planificacionId: PropTypes.any,
    tarea: PropTypes.any,
    open: PropTypes.bool,
    onSave: PropTypes.func,
};
