import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiRadio from '@material-ui/core/Radio';
import MuiOutlinedInput from '@material-ui/core/OutlinedInput';
import NotasDialog from './NotasDialog';
import IconButton from '@material-ui/core/IconButton';
import NotasIcon from '../../icons/Notas';

const TableCell = withStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(1, 2),
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '18px',
            '&:not(:first-of-type)': {
                textAlign: 'center',
            },
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

const Radio = withStyles(
    (theme) => ({
        root: {
            padding: 0,
        },
    }),
    { name: 'Radio' },
)(MuiRadio);

const Input = withStyles(
    (theme) => ({
        input: {
            padding: 6,
            minWidth: 32,
        },
    }),
    { name: 'Input' },
)(MuiOutlinedInput);

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '50px',
            paddingLeft: theme.spacing(2),
            '& ~ &': {
                // borderTop: `1px solid ${theme.palette.divider}`,
            },
        },
        actions: {
            whiteSpace: 'nowrap',
            textAlign: 'right',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
    }),
    { name: 'SeccionDetails' },
);

export default function SeccionDetails({ seccion, respuestas }) {
    const classes = useStyles();

    return (
        <>
            <Typography variant='body2' className={classes.title}>
                Sección: {seccion.titulo}
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '100%' }}></TableCell>
                        {seccion.columnas.map((label, i) => (
                            <TableCell key={i}>{label}</TableCell>
                        ))}
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {seccion.preguntas.map((pregunta) => {
                        const notas = respuestas[pregunta.id]?.notas;
                        let valor = respuestas[pregunta.id]?.valor;
                        if (valor && seccion.tipo === 'TEXT') valor = valor.split(',');

                        return (
                            <TableRow key={pregunta.id}>
                                <TableCell>{pregunta.texto}</TableCell>
                                {seccion.columnas.map((label, i) => (
                                    <TableCell key={i}>
                                        {seccion.tipo === 'CHOICE' ? (
                                            <Radio
                                                color='primary'
                                                checked={valor === label}
                                                value={label}
                                                name={pregunta.id}
                                            />
                                        ) : (
                                            <Input value={valor && valor[i]} disabled />
                                        )}
                                    </TableCell>
                                ))}
                                <TableCell className={classes.actions}>
                                    <NotasDialog
                                        button={
                                            <IconButton color={notas ? 'primary' : 'default'} disabled={!notas}>
                                                <NotasIcon />
                                            </IconButton>
                                        }
                                        notas={notas}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
}

SeccionDetails.propTypes = {
    seccion: PropTypes.any.isRequired,
    respuestas: PropTypes.any.isRequired,
};
