import { Route, Switch } from 'react-router-dom';
import InstalacionesList from './InstalacionesList';
import EditInstalacionForm from './EditInstalacionForm';

export default function InstalacionesModule() {
    return (
        <>
            <Switch>
                <Route
                    path='/instalaciones/:id'
                    render={({ match }) => <EditInstalacionForm basePath='/instalaciones' match={match} />}
                />
                <Route path='/instalaciones'>
                    <InstalacionesList />
                </Route>
            </Switch>
        </>
    );
}
