import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { companiesProvider } from '../../../../../../api';
import { FESTIVO, NOCTURNO, ORDINARIA, SABADO } from '../../../../../../api/companies';
import Button from '../../../../../common/Button';
import BaseListView, { withTableState } from '../../../../../common/list/ListView';
import EditTipoHoraDialog from './EditTipoHoraDialog';
import NewTipoHoraDialog from './NewTipoHoraDialog';
import PorDefectoButton from './PorDefectoButton';

const ListView = withTableState('tipos-hora', BaseListView);

export default function AjustesTiposHora({ data, fetchData }) {
    const snackbar = useSnackbar();

    const onDelete = (idx) => {
        companiesProvider
            .deleteTipoHora(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const PorDefectoCell = useCallback(
        (tipoHoraCategoria) =>
            // eslint-disable-next-line react/prop-types
            function PorDefectoCell({
                // eslint-disable-next-line react/prop-types
                value,
                // eslint-disable-next-line react/prop-types
                row: {
                    // eslint-disable-next-line react/prop-types
                    original: { id },
                },
            }) {
                return (
                    <PorDefectoButton value={value} id={id} tipoHoraCategoria={tipoHoraCategoria} onSave={fetchData} />
                );
            },
        [],
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Codigo',
                accessor: 'codigo',
            },
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
            {
                Header: 'Ordinaria',
                accessor: 'por_defecto',
                Cell: PorDefectoCell(ORDINARIA),
            },
            {
                Header: 'Nocturno',
                accessor: 'por_defecto_nocturno',
                Cell: PorDefectoCell(NOCTURNO),
            },
            {
                Header: 'Sabado',
                accessor: 'por_defecto_sabado',
                Cell: PorDefectoCell(SABADO),
            },
            {
                Header: 'Festivo',
                accessor: 'por_defecto_festivo',
                Cell: PorDefectoCell(FESTIVO),
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            deleteConfirmationText: 'borrar tipo hora',
            batchComponents: (
                <NewTipoHoraDialog
                    button={
                        <Button color='transparent' aria-label='Nuevo tipo hora' startIcon={<AddIcon />}>
                            Nuevo tipo de hora
                        </Button>
                    }
                    onSave={() => {
                        fetchData();
                    }}
                />
            ),
        }),
        [],
    );

    return (
        <Paper elevation={0}>
            <ListView
                tableTitle={'Lista de tipos de hora'}
                basePath='/tipos-hora'
                columns={columns}
                data={data}
                options={options}
                onDelete={onDelete}
                extraActions={(row) => (
                    <>
                        <EditTipoHoraDialog
                            id={row.id}
                            button={
                                <Tooltip title='Editar tipo de hora'>
                                    <IconButton>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            onSave={fetchData}
                        />
                    </>
                )}
            />
        </Paper>
    );
}

AjustesTiposHora.propTypes = {
    data: PropTypes.any,
    fetchData: PropTypes.any,
};
