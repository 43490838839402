import Collapse from '@material-ui/core/Collapse';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SaveIcon from '@material-ui/icons/Save';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import Button from '../../../common/Button';

const useStyles = makeStyles(
    (theme) => ({
        actions: {
            display: 'flex',
        },
        saveButton: {
            marginLeft: 'auto',
        },
    }),
    { name: 'AjustesDetails' },
);

export default function AjustesDetails({ item }) {
    const classes = useStyles();
    const { isSubmitting, dirty } = useFormikContext();
    return (
        <>
            <Collapse in={dirty}>
                <div className={classes.actions}>
                    <Button
                        type='submit'
                        color='info'
                        startIcon={<SaveIcon />}
                        disabled={isSubmitting || !dirty}
                        className={classes.saveButton}
                    >
                        Guardar
                    </Button>
                </div>
            </Collapse>
            {item.content}
        </>
    );
}

AjustesDetails.propTypes = {
    item: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
};
