import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { TablePaginationActions } from '../../list/TablePaginationActions';

function getTabFullPath(path, index, baseUrl) {
    return '' + baseUrl + (path ? '/' + path : index > 0 ? '/' + index : '');
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: `${theme.spacing(3)}px`,
            '&>div': {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: `${theme.spacing(2)}px`,
            },
            marginBottom: theme.spacing(3),
        },
        actions: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        empty: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            margin: theme.spacing(6, 'auto'),
            gap: `${theme.spacing(3)}px`,
            width: 430,
        },
        emptyText: {
            color: theme.palette.neutral.grey4,
        },
    }),
    { name: 'MasterDetailsView' },
);

export default function MasterDetailsView({
    items,
    emptyComponent,
    masterComponent,
    masterExtraProps,
    detailsComponent,
    detailsExtraProps,
    actions,
    className,
    idKey = 'id',
    itemContainerClassName,
    filterComponent,
    isEmpty,
    addPagination = false,
}) {
    const classes = useStyles();
    const match = useRouteMatch();
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const { isExact: isRouteExact, url } = useRouteMatch();
    const { pathname } = useLocation();

    useEffect(() => {
        if (items.length === 0) return;

        if (!isRouteExact) {
            return;
        }

        history.replace(`${url}/${items[0][idKey]}`);
    }, [items, url, isRouteExact]);

    useEffect(() => {
        if (isRouteExact) return;

        const itemId = pathname.split('/').pop();
        const idx = items.findIndex((item) => item[idKey] === itemId);
        if (idx < 0) return;

        const page = Math.floor(idx / pageSize);
        setPage(page);
    }, [pathname, isRouteExact]);

    const pageItems = useMemo(() => {
        if (!addPagination) return items;

        const fromIndex = page * pageSize;
        const toIndex = Math.min(items.length, (page + 1) * pageSize);

        return items.slice(fromIndex, toIndex);
    }, [items, page, pageSize, addPagination]);

    return (
        <div className={clsx(classes.root, className)}>
            {(items.length === 0 && isEmpty === undefined) || isEmpty === true ? (
                emptyComponent
            ) : (
                <>
                    <div className={itemContainerClassName}>
                        {filterComponent}
                        {items.length === 0 && isEmpty === false && (
                            <Paper elevation={0}>
                                <div className={classes.empty}>
                                    <img src='/images/empty-master-items.png' />
                                    <Typography variant='h3'>No hemos encontrado resultados con este filtro</Typography>
                                </div>
                            </Paper>
                        )}
                        {pageItems.map((item, index) => {
                            const tabPath = getTabFullPath(item[idKey], index, match.url);

                            const props = masterExtraProps ? masterExtraProps(item, index) : {};

                            return (
                                <Link
                                    to={tabPath}
                                    key={index}
                                    component={masterComponent}
                                    index={index}
                                    item={item}
                                    replace
                                    {...props}
                                />
                            );
                        })}
                        {addPagination && items.length > pageSize && (
                            <TablePagination
                                component={'div'}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                count={items.length}
                                rowsPerPage={pageSize}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'Filas por pagina' },
                                    native: true,
                                }}
                                onPageChange={(ev, page) => setPage(page)}
                                onRowsPerPageChange={(ev) => {
                                    setPageSize(parseInt(ev.target.value, 10));
                                    setPage(0);
                                }}
                                ActionsComponent={TablePaginationActions}
                            />
                        )}
                        <div className={classes.actions}>{actions}</div>
                    </div>
                    <div>
                        <Switch>
                            {items.map((item, index) => {
                                const tabPath = getTabFullPath(item[idKey], index, match.path);

                                const props = detailsExtraProps
                                    ? typeof detailsExtraProps === 'function'
                                        ? detailsExtraProps(item, index)
                                        : detailsExtraProps
                                    : {};

                                return (
                                    <Route path={tabPath} key={index}>
                                        {React.createElement(detailsComponent, { item, index, ...props })}
                                    </Route>
                                );
                            })}
                        </Switch>
                    </div>
                </>
            )}
        </div>
    );
}

MasterDetailsView.propTypes = {
    actions: PropTypes.object,
    addPagination: PropTypes.bool,
    className: PropTypes.any,
    detailsComponent: PropTypes.any.isRequired,
    detailsExtraProps: PropTypes.func,
    emptyComponent: PropTypes.any,
    filterComponent: PropTypes.any,
    idKey: PropTypes.string,
    isEmpty: PropTypes.any,
    itemContainerClassName: PropTypes.any,
    items: PropTypes.array.isRequired,
    masterComponent: PropTypes.any.isRequired,
    masterExtraProps: PropTypes.func,
};
