import { Route, Switch } from 'react-router-dom';
import AlbaranesCompraList from './AlbaranesCompraList';
import EditAlbaranCompraForm from './EditAlbaranCompraForm';

export default function AlbaranesCompraModule() {
    return (
        <>
            <Switch>
                <Route
                    path='/albaranes-compra/:id'
                    render={({ match }) => <EditAlbaranCompraForm basePath='/albaranes-compra' match={match} />}
                />
                <Route path='/albaranes-compra'>
                    <AlbaranesCompraList />
                </Route>
            </Switch>
        </>
    );
}
