import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { proyectosProvider } from '../../../api';
import { formatCurrency, formatDate, formatFullDate, formatNumber } from '../../../utils';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterDetailsHeader from '../../common/forms/MasterDetailsView/MasterDetailsHeader';
import PaperList from '../PaperList/PaperList';
import AvancePresupuestoTable from './AvancePresupuestoTable';

const useStyles = makeStyles(
    (theme) => ({
        totales: {
            width: 400,
            alignSelf: 'flex-end',
        },
    }),
    { name: 'AvanceObraDetails' },
);

export default function AvanceObraDetails({ index }) {
    const classes = useStyles();
    const baseKey = `avances_obra.${index}`;
    const { data: avanceObra, updateLocalData } = useEditFormData(baseKey);
    const { data: avancesObra } = useEditFormData('avances_obra');
    const { data: avanceObraNext } = useEditFormData(`avances_obra.${index + 1}`);

    const avanceObraPrevious = useMemo(() => {
        if (!avancesObra) return null;
        return avancesObra.slice(0, index).find((avance) => avance.certificado);
    }, [avancesObra]);

    function refreshAvanceObra() {
        proyectosProvider.getAll(`avances-obra/${avanceObra.id}`).then((avance) => {
            updateLocalData(null, avance);
        });
    }

    useEffect(() => {
        if (avanceObra.presupuestos) return;

        refreshAvanceObra();
    }, [avanceObra]);

    const totales = useMemo(() => {
        if (!avanceObra.presupuestos) return [];

        const totalPresupuesto = avanceObra.total_presupuestos_venta;
        const totalAvanceStart = avanceObraPrevious ? avanceObraPrevious.total_avance_venta : 0;
        const totalAvanceEnd = avanceObra.total_avance_venta;
        const totalCertificacion = totalAvanceEnd - totalAvanceStart;
        const totalRestante = totalPresupuesto - totalAvanceEnd;

        const totalAvanceStartPercentage = totalPresupuesto !== 0 ? (totalAvanceStart / totalPresupuesto) * 100 : 0;
        const totalAvanceEndPercentage = totalPresupuesto !== 0 ? (totalAvanceEnd / totalPresupuesto) * 100 : 0;
        const totalCertificacionPercentage = totalPresupuesto !== 0 ? (totalCertificacion / totalPresupuesto) * 100 : 0;
        const totalRestantePercentage = totalPresupuesto !== 0 ? (totalRestante / totalPresupuesto) * 100 : 0;

        return [
            {
                label: 'Presupuestado',
                value: formatCurrency(totalPresupuesto),
            },
            {
                label: 'Certificación anterior',
                value: `(${formatNumber(totalAvanceStartPercentage)}%) ${formatCurrency(totalAvanceStart)}`,
            },
            {
                label: 'Avance seleccionado',
                value: `(${formatNumber(totalAvanceEndPercentage)}%) ${formatCurrency(totalAvanceEnd)}`,
            },
            {
                label: 'Total a certificar',
                value: `(${formatNumber(totalCertificacionPercentage)}%) ${formatCurrency(totalCertificacion)}`,
                color: 'info',
            },
            {
                label: 'Restante',
                value: `(${formatNumber(totalRestantePercentage)}%) ${formatCurrency(totalRestante)}`,
            },
        ];
    }, [avanceObra.presupuestos]);

    if (!avanceObra.presupuestos) return null;

    const headerItems = [];

    if (avanceObra.certificacion_obra)
        headerItems.push({
            label: 'Certificado el',
            value: formatFullDate(avanceObra.certificacion_obra.fecha_creacion),
        });

    return (
        <>
            <MasterDetailsHeader
                title='Avance de obra:'
                subtitle={formatDate(avanceObra.fecha_creacion)}
                items={headerItems}
            />
            {avanceObra.presupuestos.map((presupuesto, index) => (
                <AvancePresupuestoTable
                    baseKey={baseKey}
                    index={index}
                    key={index}
                    avanceId={avanceObra.id}
                    onSave={refreshAvanceObra}
                    certificado={avanceObra.certificado}
                    bloqueado={Boolean(avanceObraNext)}
                />
            ))}
            <PaperList lineas={totales} className={classes.totales} />
        </>
    );
}

AvanceObraDetails.propTypes = {
    index: PropTypes.any.isRequired,
};
