import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { tareasProvider } from '../../api';
import Button from '../common/Button';
import DetailsDrawer from '../common/DetailsDrawer';
import { ButtonDialog } from '../common/dialogs/ButtonDialog';
import ParteTrabajoItemOptions from '../servicios/ParteTrabajoList/ParteTrabajoItemOptions';
import JornadaDrawerContent from './JornadaDrawerContent';
import JornadaRevisadaChip from './JornadaRevisadaChip';

export default function JornadaDrawer({ jornada, onRevisar, onUpdate, onClose }) {
    const fetchDataFn = useCallback(() => tareasProvider.getAll(jornada.tarea_id), [jornada.tarea_id]);

    const jornadaId = jornada && jornada.jornada_id ? jornada.jornada_id : jornada?.id;

    return (
        <DetailsDrawer
            open={Boolean(jornada)}
            onClose={onClose}
            fetchDataFn={fetchDataFn}
            openUrl={`/servicios/${jornada.tarea_id}/partes-trabajo/${jornadaId}`}
            extraActions={({ fetchData, tarea }) => {
                const fetchedJornada = tarea ? tarea.jornadas.find((j) => j.id === jornadaId) ?? jornada : jornada;

                if (!onRevisar) return null;

                return (
                    <>
                        {!fetchedJornada.revisada && (
                            <ButtonDialog
                                dialogTitle='Marcar como revisado'
                                dialogText={['¿Estás seguro de marcar este parte de trabajo como revisado?']}
                                onConfirm={() => {
                                    onRevisar(fetchedJornada.id);
                                    onClose();
                                }}
                                button={
                                    <Button
                                        color='info'
                                        aria-label='Marcar como revisado'
                                        style={{ marginLeft: 'auto' }}
                                    >
                                        Marcar como revisado
                                    </Button>
                                }
                            />
                        )}
                        {fetchedJornada.revisada && <JornadaRevisadaChip />}
                        <ParteTrabajoItemOptions
                            onDelete={null}
                            item={fetchedJornada}
                            onUpdate={() => {
                                fetchData();
                                onUpdate && onUpdate();
                            }}
                        />
                    </>
                );
            }}
        >
            <JornadaDrawerContent jornadaId={jornadaId} onUpdate={onUpdate} />
        </DetailsDrawer>
    );
}

JornadaDrawer.propTypes = {
    jornada: PropTypes.any,
    onClose: PropTypes.any,
    onRevisar: PropTypes.any,
    onUpdate: PropTypes.any,
};
