import DescriptionIcon from '@material-ui/icons/Description';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { formatDate } from '../../../utils';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import ItemLabel from '../../common/forms/MasterDetailsView/ItemLabel';
import MasterItem from '../../common/forms/MasterDetailsView/MasterItem';
import PresupuestoItemOptions from './PresupuestoItemOptions';

const FacturadoIcon = DescriptionIcon;

export default function PresupuestoItem({ item, href, navigate, onDelete }) {
    const location = useLocation();
    const { fetchData } = useEditFormData();

    const isSelected = location.pathname === href;

    let estadoLabel = {
        color: null,
        label: null,
        tooltip: '',
    };

    if (item.estado === 'PENDIENTE') {
        estadoLabel = {
            label: 'Pendiente',
        };
    } else if (item.estado === 'ENVIADO') {
        estadoLabel = {
            color: 'warning',
            label: 'Enviado',
            tooltip: `Enviado el ${formatDate(item.fecha_envio)}`,
        };
    } else if (item.estado === 'ACEPTADO') {
        estadoLabel = {
            color: 'success',
            label: 'Aceptado',
            tooltip: `Aceptado el ${formatDate(item.fecha_final)}`,
        };
    } else if (item.estado === 'RECHAZADO') {
        estadoLabel = {
            color: 'error',
            label: 'Rechazado',
            tooltip: `Rechazado el ${formatDate(item.fecha_final)}`,
        };
    } else if (item.estado === 'CANCELADO') {
        estadoLabel = {
            color: 'error',
            label: 'Cancelado',
        };
    }

    return (
        <MasterItem
            title={item.nombre}
            navigate={navigate}
            isSelected={isSelected}
            labels={estadoLabel.label && <ItemLabel icon={<FacturadoIcon />} {...estadoLabel} />}
            actions={
                estadoLabel.label && <PresupuestoItemOptions item={item} onUpdate={fetchData} onDelete={onDelete} />
            }
        />
    );
}

PresupuestoItem.propTypes = {
    item: PropTypes.object.isRequired,
    href: PropTypes.string.isRequired,
    navigate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};
