import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form, Formik } from 'formik';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { operariosProvider, tareasProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import { formatISODate } from '../../../utils';
import OperariosField from '../../common/fields/OperariosField';
import DialogEditor from '../../common/forms/DialogEditor';
import { afterGeneratingPdf } from '../../presupuestos/GenerarPdfDialog';

const ExportarJornadaSchema = Yup.object().shape({
    fechaInicio: Yup.mixed().required('Escoge una fecha'),
    fechaFin: Yup.mixed().required('Escoge una fecha'),
    operarios: Yup.array().min(1, 'Selecciona al menos un operario'),
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: 500,
    },
    fields: {
        display: 'flex',
        flexDirection: 'column',
        gap: `${theme.spacing(2)}px`,
        alignItems: 'flex-start',
    },
}));

function GenerarExcelDialog({ tareaId, open, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const today = new Date();
    const fromDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const toDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    return (
        <Formik
            initialValues={{
                fechaInicio: fromDate,
                fechaFin: toDate,
                operarios: [],
            }}
            validationSchema={ExportarJornadaSchema}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                const payload = {
                    fechaInicio: formatISODate(values.fechaInicio),
                    fechaFin: formatISODate(values.fechaFin),
                    operarios: values.operarios.map((o) => o.id).join(','),
                };

                tareasProvider
                    .getAll(`${tareaId}/exportar-async?${new URLSearchParams(payload).toString()}`)
                    .then(
                        afterGeneratingPdf({
                            onFinish: () => {
                                setSubmitting(false);
                                props.onClose();
                            },
                            alternativeUrl: () =>
                                tareasProvider
                                    .fetchAttachment(`${tareaId}/exportar?${new URLSearchParams(payload).toString()}`, {
                                        method: 'get',
                                    })
                                    .catch((err) => {
                                        snackbar.showMessage(err.body.message);
                                    }),
                            snackbar,
                        }),
                    )
                    .catch((err) => {
                        setSubmitting(false);
                        props.onClose();
                        console.error(err);
                        snackbar.showMessage(err.body.message);
                    });
            }}
            enableReinitialize
        >
            {({ isSubmitting, submitForm, setSubmitting }) => (
                <Form>
                    <DialogEditor
                        title='Generar Excel de partes de trabajo'
                        onSave={submitForm}
                        classes={{
                            root: classes.root,
                            body: classes.body,
                        }}
                        open={open}
                        saving={isSubmitting}
                        canSave={!isSubmitting}
                        saveButtonText='Generar'
                        {...props}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    name='fechaInicio'
                                    label='Desde'
                                    format={'dd/MM/yyyy'}
                                    fullWidth
                                    autoOk
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    name='fechaFin'
                                    label='Hasta'
                                    format={'dd/MM/yyyy'}
                                    fullWidth
                                    autoOk
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <OperariosField
                                    addSelectAll
                                    operariosOptionsFn={() =>
                                        operariosProvider.getAll(`as_options?tarea_id=${tareaId}`)
                                    }
                                    placeholder='Selecciona los operarios a incluir'
                                />
                            </Grid>
                        </Grid>
                    </DialogEditor>
                </Form>
            )}
        </Formik>
    );
}

export default withButtonOpener(GenerarExcelDialog);

GenerarExcelDialog.propTypes = {
    open: PropTypes.any,
    onClose: PropTypes.any,
    tareaId: PropTypes.string,
};
