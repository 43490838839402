import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DescriptionIcon from '@material-ui/icons/Description';
import isPast from 'date-fns/isPast';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { tareasProvider } from '../../api';
import {
    estadoLabels,
    estadoTareaOptions,
    facturadoLabels,
    facturadoOptions,
    revisadoLabels,
    revisadoOptions,
    tipoLabels,
} from '../../api/tareas';
import { useFetchData } from '../../hooks/useFetchData';
import { formatDate } from '../../utils';
import Button from '../common/Button';
import { ButtonDialog } from '../common/dialogs/ButtonDialog';
import { DateColumnFilter, dateFilterFn } from '../common/list/DateColumnFilter';
import { SelectColumnFilter } from '../common/list/SelectColumnFilter';
import { ClienteLinkCell } from '../dashboard/ClienteLink';
import { ListaServicios } from '../servicios/ListaServicios/ListaServicios';
import ServicioEstadoChip from '../servicios/ServicioEstadoChip';
import ServicioFacturadoChip from '../servicios/ServicioFacturadoChip';
import ServicioRevisadoChip from '../servicios/ServicioRevisadoChip';
import useOperariosTableColumn from '../servicios/useOperariosTableColumn';

export default function TareasAlbaranesPendientes({ onTableUpdated }) {
    const snackbar = useSnackbar();

    const fetchDataFn = useCallback(() => tareasProvider.getParaAlbaranes(), []);
    const { data, setData, fetchData } = useFetchData(fetchDataFn);
    const operariosColumn = useOperariosTableColumn();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const columns = useMemo(
        () => [
            {
                Header: 'Referencia',
                id: 'numero',
                accessor: (row) => (row.numero ? row.numero.toString().padStart(4, '0') : 'N/D'),
            },
            {
                Header: 'Título',
                accessor: 'descripcion',
            },
            {
                Header: 'Cliente',
                accessor: 'cliente',
                Cell: ClienteLinkCell,
            },
            {
                Header: 'Proyecto',
                id: 'proyecto',
                accessor: (row) => row.proyecto ?? 'Sin proyecto',
            },
            {
                Header: 'Direccion',
                accessor: 'direccion',
            },
            {
                Header: 'Tipo',
                id: 'tipo',
                accessor: (row) => tipoLabels[row.tipo],
            },
            {
                Header: 'Categoría',
                accessor: 'categoria',
            },
            {
                Header: 'Estado',
                accessor: 'estado',
                Cell: ServicioEstadoChip,
                Filter: SelectColumnFilter,
                filterOptions: {
                    options: estadoTareaOptions,
                    includeAllOption: {
                        value: 'todos',
                        label: 'Todos',
                    },
                },
                exportValue: ({ value }) => estadoLabels[value],
            },
            {
                Header: 'Inicio previsto',
                accessor: 'fecha',
                Cell: ({ value }) => (value ? formatDate(new Date(value)) : 'Sin fecha'),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Fin previsto',
                accessor: 'fecha_fin',
                Cell: ({ value }) => (value ? formatDate(new Date(value)) : 'Sin fecha'),
                getExtraCellProps: ({ value }) => ({ style: { color: isPast(new Date(value)) ? 'red' : 'initial' } }),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Inicio real',
                accessor: 'fecha_inicio_real',
                Cell: ({ value }) => (value ? formatDate(new Date(value)) : 'Sin registro'),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Fin real',
                accessor: 'fecha_fin_real',
                Cell: ({ value }) => (value ? formatDate(new Date(value)) : 'Sin registro'),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            operariosColumn,
            {
                Header: 'Notas internas',
                accessor: 'notas_internas',
            },
            {
                Header: 'Revisado',
                accessor: 'revisado',
                Cell: ServicioRevisadoChip,
                Filter: SelectColumnFilter,
                filter: 'equals',
                filterOptions: {
                    options: revisadoOptions,
                    includeAllOption: {
                        value: 'todos',
                        label: 'Todos',
                    },
                },
                exportValue: ({ value }) => revisadoLabels[value],
            },
            {
                Header: 'Facturado',
                accessor: 'facturado',
                Cell: ServicioFacturadoChip,
                Filter: SelectColumnFilter,
                filter: 'equals',
                filterOptions: {
                    options: facturadoOptions,
                    includeAllOption: {
                        value: 'todos',
                        label: 'Todos',
                    },
                },
                exportValue: ({ value }) => facturadoLabels[value],
            },
            {
                Header: 'Incidencias',
                id: 'sin_resolver',
                accessor: (row) => (row.sin_resolver ? 'Si' : 'No'),
                Filter: SelectColumnFilter,
                filterOptions: {
                    options: [
                        {
                            value: 'Si',
                            label: 'Si',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ],
                    includeAllOption: {
                        value: 'todos',
                        label: 'Todos',
                    },
                },
            },
        ],
        [operariosColumn],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            hideDelete: true,
            batchComponents: ({ rows }) => (
                <ButtonDialog
                    dialogTitle='Generar todos los albaranes de servicio pendientes'
                    dialogText={[
                        `Estás a punto de generar automáticamente ${rows.length} albaranes de servicio.`,
                        'Se creará un albarán de servicio por cada servicio que tenga partes de trabajo pendientes de agrupar. ¿Deseas continuar?',
                    ]}
                    onConfirm={() => {
                        const ids = rows.map((r) => r.original.id);
                        tareasProvider
                            .action('albaranes', { method: 'post', body: JSON.stringify({ ids }) })
                            .then(() => {
                                fetchData();
                                onTableUpdated && onTableUpdated();
                            });
                    }}
                    button={
                        <Tooltip title='Generar todos los albaranes de servicio pendientes'>
                            <Button color='transparent' startIcon={<DescriptionIcon />} disabled={rows.length === 0}>
                                Generar todo
                            </Button>
                        </Tooltip>
                    }
                />
            ),
            tableOptions: {
                fillEmptySpace: false,
                expandedTable: false,
            },
        }),
        [snackbar],
    );

    return (
        <ListaServicios
            tableId='tareasParaAlbaranes'
            tareas={data}
            setTareas={setData}
            columns={columns}
            options={options}
            style={{
                marginBottom: 24,
            }}
            basePath='/servicios'
            tableTitle='Servicios con albaranes pendientes de generar'
            TareaActionsProps={{
                canEdit: false,
            }}
            extraActions={(row) => (
                <ButtonDialog
                    dialogTitle='Generar albarán de servicio'
                    dialogText='Vas a generar un albarán de servicio con todos los partes de trabajo que haya pendientes de agrupar. ¿Deseas continuar?'
                    onConfirm={() => {
                        tareasProvider.actionOnId(row.id, 'albaranes', {}, { method: 'post' }).then(() => {
                            fetchData();
                            onTableUpdated && onTableUpdated();
                        });
                    }}
                    button={
                        <Tooltip title='Generar albarán de servicio'>
                            <IconButton style={{ padding: 6 }} aria-label='Generar albarán de servicio'>
                                <DescriptionIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                    }
                />
            )}
        />
    );
}

TareasAlbaranesPendientes.propTypes = {
    onTableUpdated: PropTypes.any,
};
