import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { presupuestosProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import DialogForm from '../../common/forms/DialogForm';

const CapituloSchema = Yup.object().shape({
    referencia: Yup.string(),
    concepto: Yup.string().required('Requerido'),
    unidades: Yup.number()
        .required('Requerido')
        .test('gt-0', 'Debe ser mayor que 0', (value) => value > 0),
});

function AddCapituloDialog({ presupuestoId, parentId, onSave, ...props }) {
    return (
        <DialogForm
            title='Añadir capítulo'
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    referencia: '',
                    concepto: '',
                    unidades: 1,
                },
                validationSchema: CapituloSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    values.tipo_linea = 'CAPITULO';
                    values.parent_id = parentId;
                    presupuestosProvider
                        .addLinea(presupuestoId, values)
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {() => {
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField name='referencia' label='Referencia' fullWidth />
                        </Grid>
                        <Grid item xs={9}>
                            <TextField name='concepto' label='Concepto' fullWidth />
                        </Grid>
                    </Grid>
                );
            }}
        </DialogForm>
    );
}

AddCapituloDialog.propTypes = {
    presupuestoId: PropTypes.string,
    parentId: PropTypes.string,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
};

export default withButtonOpener(AddCapituloDialog);
