import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { cuestionariosProvider } from '../../../api';
import { tipoSeccionLabels } from '../../../api/cuestionarios';
import DeleteDialog from '../../common/dialogs/DeleteDialog';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterItem from '../../common/forms/MasterDetailsViewOld/MasterItem';
import EditSeccionDialog from './EditSeccionDialog';

export default function SeccionItem({ item, index, href, navigate, onDelete, disabled, showHeader = false }) {
    const location = useLocation();

    const { id: cuestionarioId } = useParams();

    const isSelected = location.pathname === href;

    const { data: secciones, updateData } = useEditFormData('secciones');
    return (
        <MasterItem
            icon={DescriptionIcon}
            title={item.titulo}
            subtitle={`Preguntas de tipo ${tipoSeccionLabels[item.tipo].toLowerCase()} con ${
                item.columnas.length
            } respuestas (${item.columnas.join(', ')})`}
            navigate={navigate}
            isSelected={isSelected}
            actions={
                <>
                    <IconButton
                        disabled={index === 0}
                        onClick={() => cuestionariosProvider.moveSeccionUp(cuestionarioId, item.id).then(updateData)}
                    >
                        <ExpandLessIcon />
                    </IconButton>
                    <IconButton
                        disabled={index === secciones.length - 1}
                        onClick={() => cuestionariosProvider.moveSeccionDown(cuestionarioId, item.id).then(updateData)}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                    <EditSeccionDialog
                        index={index}
                        button={
                            <IconButton disabled={disabled}>
                                <EditIcon />
                            </IconButton>
                        }
                        onSave={updateData}
                    />
                    <DeleteDialog
                        text='¿Estás seguro que quieres eliminar esta sección?'
                        onConfirm={onDelete}
                        button={
                            <IconButton disabled={disabled}>
                                <DeleteIcon />
                            </IconButton>
                        }
                    />
                </>
            }
        />
    );
}

SeccionItem.propTypes = {
    disabled: PropTypes.any,
    href: PropTypes.string.isRequired,
    index: PropTypes.any,
    item: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    showHeader: PropTypes.bool,
};
