import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocationIcon from '@material-ui/icons/LocationOn';
import MuiDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { format, formatFullDate } from '../../utils';
import InfoField from '../common/fields/InfoField';
import withStyles from '@material-ui/core/styles/withStyles';
import EntradaIcon from '../icons/Entrada';
import SalidaIcon from '../icons/Salida';
import Button from '../common/Button';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

const MarcajeInfoField = withStyles((theme) => ({
    label: {
        display: 'flex',
        alignItems: 'center',
        color: (props) => (props.variant === 'entrada' ? '#2CBD96' : '#E94F4F'),
        '& svg': {
            fontSize: 14,
            marginRight: theme.spacing(0.5),
        },
    },
}))(InfoField);

const Dialog = withStyles((theme) => ({
    paper: {
        width: 435,
    },
}))(MuiDialog);

export const LocationButton = ({ coords, style, ...props }) => (
    <Tooltip arrow title='Abrir coordenadas GPS en Google Maps'>
        <span>
            <Button
                size='small'
                color='info'
                radius={4}
                style={{
                    minWidth: 0,
                    width: 24,
                    height: 24,
                    padding: 0,
                    marginLeft: 4,
                    ...style,
                }}
                {...props}
                onClick={() => {
                    const params = {
                        query: `${coords.latitude},${coords.longitude}`,
                    };

                    const url = `https://www.google.com/maps/search/?api=1&${new URLSearchParams(params).toString()}`;
                    window.open(url, '_blank');
                }}
            >
                <LocationIcon style={{ fontSize: 16, color: 'white' }} />
            </Button>
        </span>
    </Tooltip>
);

const useStyles = makeStyles((theme) => ({
    timeDiff: {
        fontSize: 10,
        color: '#4C60FE',
        backgroundColor: '#e6eeef',
        padding: theme.spacing(0.5, 1),
        borderRadius: 20,
        marginLeft: 8,
        textAlign: 'center',
        width: 60,
        marginRight: 8,
        '&.hidden': {
            visibility: 'hidden',
        },
    },
}));

export function VerFichajeDialog({ open, onClose, fichaje }) {
    const classes = useStyles();

    return (
        <Dialog onClose={onClose} aria-labelledby='nueva-fichaje-title' open={open}>
            <DialogTitle id='parte-trabajo-title'>
                <span style={{ fontWeight: 'bold' }}>Fichaje</span>
                <br />
            </DialogTitle>
            <DialogContent style={{ marginBottom: 16 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InfoField label='Operario' value={fichaje.operario} />
                    </Grid>
                    <Grid item xs={12}>
                        <InfoField label='Fecha' value={formatFullDate(fichaje.fecha)} />
                    </Grid>
                    {fichaje.marcajes.map((marcaje, i) => {
                        const entradaDifference = differenceInMinutes(
                            new Date(marcaje.hora_entrada_real),
                            new Date(marcaje.hora_entrada),
                        );
                        const salidaDifference = differenceInMinutes(
                            new Date(marcaje.hora_salida_real),
                            new Date(marcaje.hora_salida),
                        );

                        return (
                            <React.Fragment key={i}>
                                <Grid item xs={6}>
                                    <MarcajeInfoField
                                        variant='entrada'
                                        label={
                                            <>
                                                <EntradaIcon /> Entrada
                                            </>
                                        }
                                        value={
                                            <>
                                                {format(marcaje.hora_entrada, 'HH:mm')}
                                                <span
                                                    className={clsx(classes.timeDiff, {
                                                        hidden: entradaDifference === 0,
                                                    })}
                                                >
                                                    {entradaDifference > 0 ? '+' : ''}
                                                    {entradaDifference}min
                                                </span>
                                                {marcaje.coords_entrada && marcaje.coords_entrada.latitude && (
                                                    <LocationButton coords={marcaje.coords_entrada} />
                                                )}
                                            </>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MarcajeInfoField
                                        variant='salida'
                                        label={
                                            <>
                                                <SalidaIcon /> Salida
                                            </>
                                        }
                                        value={
                                            marcaje.hora_salida !== null ? (
                                                <>
                                                    {format(marcaje.hora_salida, 'HH:mm')}
                                                    <span
                                                        className={clsx(classes.timeDiff, {
                                                            hidden: salidaDifference === 0,
                                                        })}
                                                    >
                                                        {salidaDifference > 0 ? '+' : ''}
                                                        {salidaDifference}min
                                                    </span>
                                                    {marcaje.coords_salida && marcaje.coords_salida.latitude && (
                                                        <LocationButton coords={marcaje.coords_salida} />
                                                    )}
                                                </>
                                            ) : (
                                                'Abierto'
                                            )
                                        }
                                    />
                                </Grid>
                            </React.Fragment>
                        );
                    })}
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export function VerFichajeButton({ fichaje }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <IconButton onClick={() => setOpen(true)} style={{ padding: 6 }}>
                <VisibilityIcon fontSize='small' />
            </IconButton>
            <VerFichajeDialog open={open} onClose={() => setOpen(false)} fichaje={fichaje} />
        </>
    );
}

LocationButton.propTypes = {
    coords: PropTypes.any,
    style: PropTypes.any,
};

VerFichajeDialog.propTypes = {
    fichaje: PropTypes.any,
    onClose: PropTypes.any,
    open: PropTypes.any,
};

VerFichajeButton.propTypes = {
    fichaje: PropTypes.any,
};
