import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import { DialogActions } from '@material-ui/core';
import Button from '../Button';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { useEffect, useState } from 'react';

const useStyles = makeStyles(
    (theme) => ({
        paper: {
            // width: 300,
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(3)}px`,
        },
        text: {
            textAlign: 'center',
            whiteSpace: 'pre-line',
        },
        actions: {
            justifyContent: 'center',
        },
        confirmationText: {},
    }),
    { name: 'DeleteDialog' },
);

export function DeleteDialogBase({
    open,
    onClose,
    text,
    deleteButtonText = 'Eliminar',
    onConfirm,
    confirmationText = null,
    children,
    maxWidth = 'xs',
    ...props
}) {
    const classes = useStyles();
    const [confirmationValue, setConfirmationValue] = useState('');

    const canConfirm = confirmationText === null || confirmationValue === confirmationText;

    useEffect(() => {
        setConfirmationValue('');
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            classes={{ paper: classes.paper }}
            maxWidth={maxWidth}
            {...props}
        >
            <DialogContent className={classes.content}>
                <Typography variant='body2' className={classes.text}>
                    {text}
                </Typography>
                {typeof children === 'function' ? children({ open }) : children}
                {confirmationText && (
                    <div className={classes.confirmationText}>
                        <TextField
                            fullWidth
                            value={confirmationValue}
                            onChange={(ev) => setConfirmationValue(ev.target.value)}
                            onBlur={(ev) => setConfirmationValue(ev.target.value)}
                            error={!canConfirm}
                            helperText={`Escribe "${confirmationText}" para confirmar`}
                        />
                    </div>
                )}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button color='outlined' onClick={onClose}>
                    Cancelar
                </Button>
                <Button
                    color='error'
                    onClick={(ev) => {
                        onClose(ev);
                        onConfirm();
                    }}
                    disabled={!canConfirm}
                >
                    {deleteButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withButtonOpener(DeleteDialogBase);

DeleteDialogBase.propTypes = {
    children: PropTypes.any,
    confirmationText: PropTypes.any,
    deleteButtonText: PropTypes.string,
    maxWidth: PropTypes.string,
    onClose: PropTypes.any,
    onConfirm: PropTypes.any,
    open: PropTypes.any,
    text: PropTypes.any,
};
