import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { getTiempoTotalMarcajes } from '../../../../api/fichajes';
import { formatTiempo } from '../../../../utils';
import Marcaje from './Marcaje';

const useStyles = makeStyles(
    (theme) => ({
        item: {
            display: 'flex',
            flexDirection: 'column',
            borderTop: '1px solid #23395F',
            padding: theme.spacing(2, 3),
            gap: `${theme.spacing(2)}px`,
            '&:first-of-type': {
                marginTop: theme.spacing(2),
            },
        },
        row: {
            display: 'flex',
            alignItems: 'center',
        },
        label: {
            fontSize: 14,
            fontWeight: 500,
            flex: 1,
        },
        time: {
            fontSize: 18,
            fontWeight: 500,
        },
        subItems: {
            gap: `${theme.spacing(4)}px`,
        },
        subItem: {
            display: 'flex',
            flexDirection: 'column',
        },
        label2: {
            fontSize: 12,
            fontWeight: 400,
        },
        time2: {
            fontSize: 16,
            fontWeight: 500,
        },
    }),
    { name: 'ResumenDia' },
);
export default function ResumenDia({ fichaje }) {
    const classes = useStyles();

    const tiempoFichado = getTiempoTotalMarcajes(fichaje.marcajes);

    return (
        <>
            {fichaje.marcajes.map((marcaje, i) => (
                <Marcaje key={i} marcaje={marcaje} />
            ))}
            <div className={classes.item}>
                <div className={classes.row}>
                    <Typography className={classes.label}>Horas fichadas:</Typography>
                    <Typography className={classes.time}>{formatTiempo(tiempoFichado)}</Typography>
                </div>
            </div>
        </>
    );
}

ResumenDia.propTypes = {
    fichaje: PropTypes.object.isRequired,
};
