import MuiTextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import uuid from 'uuid';
import { materialesProvider } from '../../api';

const filter = createFilterOptions();

export default function MarcaField({ error, helperText, disabled, marcaKey = 'marca' }) {
    const [{ value }, , { setValue }] = useField(marcaKey);
    const [marcasOptions, setMarcasOptions] = useState([]);

    useEffect(() => {
        materialesProvider.getAll('marcas').then(setMarcasOptions);
    }, []);

    return (
        <Autocomplete
            fullWidth
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            disabled={disabled}
            value={value}
            onChange={(event, newValue) => {
                function getNewOption(value) {
                    if (typeof value === 'string') {
                        const newOption = {
                            id: uuid.v4(),
                            nombre: value,
                        };
                        setMarcasOptions([...marcasOptions, newOption]);
                        return newOption;
                    } else if (value && value.isNew) {
                        const newOption = {
                            id: value.id,
                            nombre: value.nombre,
                        };
                        setMarcasOptions([...marcasOptions, newOption]);
                        return newOption;
                    } else {
                        return value;
                    }
                }
                setValue(getNewOption(newValue));
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                    filtered.push({
                        nombre: params.inputValue,
                        id: uuid.v4(),
                        isNew: true,
                        label: `Añadir "${params.inputValue}"`,
                    });
                }

                return filtered;
            }}
            options={marcasOptions}
            getOptionLabel={(option) => option.label ?? option.nombre}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
                <MuiTextField
                    {...params}
                    error={error}
                    helperText={helperText}
                    label='Marca'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={disabled}
                />
            )}
            renderOption={(option) => option.nombre}
        />
    );
}

MarcaField.propTypes = {
    disabled: PropTypes.any,
    error: PropTypes.any,
    helperText: PropTypes.any,
    marcaKey: PropTypes.string,
};
