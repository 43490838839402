import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { preparacionesMaterialProvider, tareasProvider } from '../../api';
import { usePermisos, usePreferencias } from '../../AuthState';
import { ListItem } from './AppBarMenu';
import ButtonMenu, { MenuBadge } from './ButtonMenu';

export default function ServiciosMenu({ disabled }) {
    const [usarPreparacionMateriales, usarIncidencias] = usePreferencias(
        'usar_preparacion_materiales',
        'usar_incidencias',
    );

    const gestionarPreparacionMateriales = usePermisos('gestionar_preparaciones_material');

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [badgeState, setBadgeState] = useState({
        incidencias: 0,
        partesSinRevisar: 0,
        solicitudesPendientes: 0,
    });

    useEffect(() => {
        if (!menuIsOpen) return;

        Promise.all([
            tareasProvider.getAll('jornadas/sin-revisar-count'),
            tareasProvider.getAll('incidencias-count'),
            preparacionesMaterialProvider.getAll('solicitudes-count'),
        ])
            .then(([partesSinRevisar, incidencias, solicitudes]) => {
                setBadgeState({
                    incidencias: incidencias.count,
                    partesSinRevisar: partesSinRevisar.count,
                    solicitudesPendientes: solicitudes.count,
                });
            })
            .catch(() => {
                console.error('No se han podido obtener los datos de las incidencias y partes sin revisar');
            });
    }, [menuIsOpen]);

    const items = useMemo(() => {
        const items = [
            {
                path: '/',
                label: 'Lista de servicios',
            },
            {
                path: '/revisar-partes-trabajo',
                label: (
                    <MenuBadge badgeContent={badgeState.partesSinRevisar} color='primary'>
                        Revisar partes de trabajo
                    </MenuBadge>
                ),
            },
        ];

        if (usarIncidencias)
            items.push({
                path: '/incidencias',
                label: (
                    <MenuBadge badgeContent={badgeState.incidencias} color='primary'>
                        Incidencias
                    </MenuBadge>
                ),
            });

        if (usarPreparacionMateriales && gestionarPreparacionMateriales)
            items.push({
                path: '/preparacion-material',
                label: (
                    <MenuBadge badgeContent={badgeState.solicitudesPendientes} color='primary'>
                        Preparación de material
                    </MenuBadge>
                ),
            });

        return items;
    }, [usarPreparacionMateriales, badgeState]);

    return (
        <ButtonMenu button={<ListItem label='Servicios' disabled={disabled} />} items={items} onOpen={setMenuIsOpen} />
    );
}

ServiciosMenu.propTypes = {
    disabled: PropTypes.any,
};
