import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { presupuestosProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import Button from '../common/Button';
import DeleteDialog from '../common/dialogs/DeleteDialog';
import DialogEditor from '../common/forms/DialogEditor';
import { useCopyPaste } from './PresupuestoView/CopyPasteProvider';
import { TableCell, TableRow } from './PresupuestoView/Table';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 800,
        },
        body: {
            padding: 0,
        },
        noDownward: {
            marginLeft: 32,
        },
    }),
    { name: 'ReordenarLineasDialog' },
);

function EditBatchLineasDialog({
    presupuestoId,
    parentLineaId,
    concepto: fullConcepto,
    lineas,
    open,
    onSave,
    ...props
}) {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const [selectedLineaIds, setSelectedLineaIds] = useState([]);
    const { copy } = useCopyPaste();

    let concepto = fullConcepto;
    if (concepto?.length > 50) {
        concepto = concepto.substring(0, 50) + '...';
    }

    useEffect(() => {
        if (!open) return;

        setSelectedLineaIds([]);
    }, [open]);

    function handleCheckLinea(id, checked) {
        if (checked) {
            setSelectedLineaIds((selectedLineas) =>
                selectedLineas.includes(id) ? selectedLineas : [...selectedLineas, id],
            );
        } else {
            setSelectedLineaIds((selectedLineas) => selectedLineas.filter((j) => j !== id));
        }
    }

    function selectAllOrNothing(selectAll) {
        setSelectedLineaIds(selectAll ? lineas.map((j) => j.id) : []);
    }

    return (
        <DialogEditor
            title={`Editar lineas de la partida: ${concepto}`}
            maxWidth='md'
            classes={{
                root: classes.root,
                body: classes.body,
            }}
            open={open}
            customSaveButton={
                <>
                    <DeleteDialog
                        text='¿Deseas borrar las lineas seleccionadas?'
                        onConfirm={(ev) => {
                            presupuestosProvider.borrarLineas(presupuestoId, selectedLineaIds).then(() => {
                                snackbar.showMessage('Lineas eliminadas correctamente');
                                onSave();
                                props.onClose();
                            });
                        }}
                        button={
                            <Button color='error' disabled={selectedLineaIds.length === 0}>
                                Borrar seleccionadas
                            </Button>
                        }
                    />
                    <Button
                        color='info'
                        disabled={selectedLineaIds.length === 0}
                        onClick={() => {
                            copy(lineas.filter((linea) => selectedLineaIds.includes(linea.id)));
                            props.onClose();
                        }}
                    >
                        Copiar seleccionadas
                    </Button>
                </>
            }
            {...props}
        >
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Checkbox
                                checked={selectedLineaIds.length === lineas.length}
                                onClick={(ev) => selectAllOrNothing(ev.target.checked)}
                                indeterminate={selectedLineaIds.length > 0 && selectedLineaIds.length < lineas.length}
                                color='primary'
                            />
                        </TableCell>
                        <TableCell>Referencia</TableCell>
                        <TableCell style={{ width: '100%' }}>Concepto</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {lineas.map((linea, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <Checkbox
                                    checked={selectedLineaIds.includes(linea.id)}
                                    onClick={(ev) => handleCheckLinea(linea.id, ev.target.checked)}
                                    color='primary'
                                />
                            </TableCell>
                            <TableCell>{linea.referencia}</TableCell>
                            <TableCell>{linea.concepto}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </DialogEditor>
    );
}

EditBatchLineasDialog.propTypes = {
    presupuestoId: PropTypes.any,
    parentLineaId: PropTypes.any,
    concepto: PropTypes.string,
    lineas: PropTypes.array,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.any,
    open: PropTypes.any,
};

export default withButtonOpener(EditBatchLineasDialog);
