import { formatISODate } from '../utils';
import DataProvider from './DataProvider';
import { calendariosProvider } from './index';

export const empty = {
    year: '',
    dates: '',
    nombre: '',
    por_defecto: false,
};

class CalendariosProvider extends DataProvider {
    constructor() {
        super('calendarios', empty);
    }

    asignarOperarios = async (id, fecha, operarioIds) =>
        this.getAll(`${id}/asignar`, {
            method: 'post',
            body: JSON.stringify({
                fecha,
                operario_ids: operarioIds,
            }),
        });

    getDefaultNoLaborables = (currentYear) => {
        const day = new Date(currentYear, 0, 1);
        if (day.getDay() < 6) day.setDate(day.getDate() + 6 - day.getDay());

        const dates = [];
        while (day.getFullYear() <= currentYear) {
            dates.push(new Date(day));
            day.setDate(day.getDate() + 1);
            dates.push(new Date(day));
            day.setDate(day.getDate() + 6);
        }

        return dates;
    };

    setPorDefecto = async (id) => this.actionOnId(id, 'por-defecto');

    resetNoLaborables = async (id, currentYear) => {
        const dates = this.getDefaultNoLaborables(currentYear);

        return calendariosProvider.getAll(`${currentYear}`, {
            method: 'post',
            body: JSON.stringify({ dates: dates.map(formatISODate) }),
        });
    };

    getNoLaborables = async (currentYear, calendarioId) =>
        this.getAll(`${calendarioId ?? 'por-defecto'}/no_laborables/${currentYear}`);
}

export const dataProvider = new CalendariosProvider();
