import { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import * as Yup from 'yup';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import { fichajesProvider, operariosProvider } from '../../api';
import { Autocomplete } from 'formik-material-ui-lab';
import MuiTextField from '@material-ui/core/TextField';
import MarcajesField from './MarcajesField';
import Tooltip from '@material-ui/core/Tooltip';
import { useSnackbar } from 'material-ui-snackbar-provider';
import DialogForm from '../common/forms/DialogForm';
import PropTypes from 'prop-types';

const FichajeSchema = Yup.object().shape({
    marcajes: Yup.array().min(1, 'Tiene que haber al menos un marcaje'),
});

export function CrearFichajeDialog({ onSave, ...props }) {
    const snackbar = useSnackbar();

    const [operariosOptions, setOperariosOptions] = useState([]);

    useEffect(() => {
        if (!props.open) return;

        operariosProvider.getOnlyActiveAsOptions().then(setOperariosOptions);
    }, [props.open]);

    return (
        <DialogForm
            title='Añadir fichaje'
            FormikProps={{
                initialValues: {
                    operario: null,
                    fecha: new Date(),
                    marcajes: [],
                },
                enableReinitialize: true,
                validationSchema: FichajeSchema,
                onSubmit: ({ fecha, marcajes, ...values }, { setSubmitting, setFieldError }) => {
                    marcajes.forEach((marcaje) => {
                        marcaje.hora_entrada.setFullYear(fecha.getFullYear(), fecha.getMonth(), fecha.getDate());
                        marcaje.hora_salida.setFullYear(fecha.getFullYear(), fecha.getMonth(), fecha.getDate());
                    });

                    fichajesProvider
                        .create({
                            ...values,
                            fecha,
                            marcajes,
                        })
                        .then((updatedFichaje) => {
                            setSubmitting(false);
                            onSave(updatedFichaje);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [key, value] of Object.entries(err.message)) {
                                    setFieldError(key, value[0]);
                                }
                            } else {
                                snackbar.showMessage('Ha ocurrido un error');
                            }

                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ touched, values, errors }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Field
                            name='operario'
                            aria-label='Seleccionar operario'
                            fullWidth
                            component={Autocomplete}
                            options={operariosOptions}
                            getOptionLabel={(option) => option.nombre}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                                <MuiTextField
                                    {...params}
                                    error={touched.operario && !!errors.operario}
                                    helperText={touched.operario && errors.operario}
                                    label='Operario'
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <KeyboardDatePicker
                            name='fecha'
                            label='Fecha'
                            format='dd/MM/yyyy'
                            autoOk
                            ampm={false}
                            minutesStep={15}
                            fullWidth
                        />
                    </Grid>
                    <MarcajesField marcajes={values.marcajes} fecha={values.fecha} operarioId={values.operario?.id} />
                </Grid>
            )}
        </DialogForm>
    );
}

export function CrearFichajeButton({ onSave }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Tooltip title='Crear fichaje'>
                <IconButton onClick={() => setOpen(true)}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <CrearFichajeDialog
                open={open}
                onSave={() => {
                    onSave();
                    setOpen(false);
                }}
                onClose={() => setOpen(false)}
            />
        </>
    );
}

CrearFichajeDialog.propTypes = {
    onSave: PropTypes.any,
    open: PropTypes.any,
};

CrearFichajeButton.propTypes = {
    onSave: PropTypes.any,
};
