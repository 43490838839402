import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import Button from '../../common/Button';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import CalendariosTable from './CalendariosTable';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: `${theme.spacing(3)}px`,
            '&>div': {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: `${theme.spacing(2)}px`,
            },
        },
        empty: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            margin: theme.spacing(6, 'auto'),
            gap: `${theme.spacing(3)}px`,
            width: 430,
        },
        emptyText: {
            color: theme.palette.neutral.grey4,
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'CalendariosTab' },
);

export default function CalendariosTab() {
    const classes = useStyles();
    const history = useHistory();

    const { data: operario } = useEditFormData();

    return (
        <div className={classes.root}>
            {operario.calendarios.length === 0 ? (
                <Paper elevation={0}>
                    <div className={classes.empty}>
                        <img src='/images/empty-partes-trabajo.png' />
                        <Typography variant='h1'>Calendario laboral</Typography>
                        <Typography variant='body2' className={classes.emptyText}>
                            En esta sección verás los calendarios de trabajo que se han asignado al empleado. Si no se
                            asigna ningún calendario al empleado, se utilizará el calendario por defecto de la empresa.
                        </Typography>
                        <Typography variant='body2' className={classes.emptyText}>
                            Desde Ajustes de empresa podrás crear y asignar calendarios al empleado y registrar cambios
                            de calendario.
                        </Typography>
                        <Button
                            color='info'
                            aria-label='Ir a ajustes'
                            onClick={() => history.push('/mi-cuenta/ajustes/calendarios')}
                        >
                            Ir a ajustes
                        </Button>
                    </div>
                </Paper>
            ) : (
                <CalendariosTable />
            )}
        </div>
    );
}

CalendariosTab.propTypes = {};
