import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(0.5)}px`,
        },
        item: {
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    }),
    { name: 'Breadcrumbs' },
);

export function Breadcrumbs({ parents, onClick, className }) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, className)}>
            <Typography variant='h6' className={classes.item} onClick={() => onClick(0)}>
                Mis documentos
            </Typography>
            {parents.map((p, i) => (
                <React.Fragment key={i}>
                    <ChevronRightIcon />
                    <Typography variant='h6' className={classes.item} onClick={() => onClick(i + 1)}>
                        {p.nombre}
                    </Typography>
                </React.Fragment>
            ))}
        </div>
    );
}

Breadcrumbs.propTypes = {
    parents: PropTypes.array,
    onClick: PropTypes.func,
    className: PropTypes.any,
};
