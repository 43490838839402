import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { presupuestosProvider } from '../../../api';
import { tipoPresupuestoLineaLabels, tipoUnidadesOptions } from '../../../api/presupuestos';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import SelectField from '../../common/fields/SelectField';
import DialogForm from '../../common/forms/DialogForm';
import PrecioFields from './PrecioFields';

const DescomposicionSchema = Yup.object().shape({
    referencia: Yup.string(),
    concepto: Yup.string().required('Requerido'),
    unidades: Yup.number()
        .required('Requerido')
        .test('gt-0', 'Debe ser mayor que 0', (value) => value > 0),
    precio_coste: Yup.number().required('Requerido').min(0, 'Mínimo 0'),
    precio_venta: Yup.number().required('Requerido').min(0, 'Mínimo 0'),
    margen: Yup.number().required('Requerido').min(0, 'Mínimo 0'),
});

function EditDescomposicionDialog({ presupuestoId, linea, onSave, editable, ...props }) {
    const snackbar = useSnackbar();

    return (
        <DialogForm
            title={`Editar ${tipoPresupuestoLineaLabels[linea.tipo_linea].toLowerCase()}`}
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    referencia: linea.referencia ?? '',
                    concepto: linea.concepto,
                    unidades: linea.unidades,
                    tipo_unidades: linea.tipo_unidades,
                    descripcion: linea.descripcion,
                    observaciones: linea.observaciones,
                    precio_coste: linea.precio_coste,
                    precio_venta: linea.precio_venta,
                    margen: (linea.margen * 100).toFixed(2),
                },
                validationSchema: DescomposicionSchema,
                onSubmit: ({ margen, ...values }, { setSubmitting, setFieldError }) => {
                    presupuestosProvider
                        .updateLinea(presupuestoId, linea.id, values)
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                if (err.message instanceof Object) {
                                    for (const [field, errors] of Object.entries(err.message)) {
                                        setFieldError(field, errors.join('\n'));
                                    }
                                } else {
                                    snackbar.showMessage(err.message);
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ values, setFieldValue }) => {
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField name='referencia' label='Referencia' fullWidth disabled={!editable} />
                        </Grid>
                        <Grid item xs={9}>
                            <TextField name='concepto' label='Concepto' fullWidth disabled={!editable} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField name='unidades' label='Unidades' fullWidth type='number' disabled={!editable} />
                        </Grid>
                        <Grid item xs={9}>
                            <Field
                                name='tipo_unidades'
                                id='tipo_unidades'
                                component={SelectField}
                                label='Tipo de unidades'
                                fullWidth
                                selectOptions={tipoUnidadesOptions}
                                disabled={!editable}
                            />
                        </Grid>
                        <PrecioFields setFieldValue={setFieldValue} values={values} disabled={!editable} />
                        <Grid item xs={12}>
                            <TextField
                                name='descripcion'
                                label='Descripción'
                                fullWidth
                                multiline
                                minRows={4}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name='observaciones'
                                label='Observaciones'
                                fullWidth
                                multiline
                                minRows={4}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                );
            }}
        </DialogForm>
    );
}

EditDescomposicionDialog.propTypes = {
    presupuestoId: PropTypes.string,
    linea: PropTypes.object,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    editable: PropTypes.bool,
};

export default withButtonOpener(EditDescomposicionDialog);
