import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CopyIcon from '@material-ui/icons/FileCopy';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { presupuestosProvider } from '../../../api';
import { tipoPresupuestoLineaLabels } from '../../../api/presupuestos';
import { useFetchData } from '../../../hooks/useFetchData';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import Button from '../../common/Button';
import ListView from '../../common/list/ListView';
import SelectPresupuesto from './SelectPresupuesto';

function SearchLineaDialog({
    presupuestoId: originalPresupuestoId,
    proyectoId: originalProyectoId,
    tipoLinea,
    onSelect,
    ...props
}) {
    const [proyectoId, setProyectoId] = useState(originalProyectoId);
    const [presupuestoId, setPresupuestoId] = useState(originalPresupuestoId);

    const searchParams = useMemo(() => {
        const params = {
            tipo_linea: tipoLinea,
        };
        if (presupuestoId) params.presupuesto_id = presupuestoId;
        if (proyectoId) params.proyecto_id = proyectoId;

        return params;
    }, [proyectoId, presupuestoId, tipoLinea]);
    const fetchDataFn = useCallback(() => presupuestosProvider.searchLinea(searchParams), [searchParams]);
    const { data, fetchData } = useFetchData(fetchDataFn);

    useEffect(() => {
        if (!props.open) return;

        fetchData();
    }, [fetchData, props.open]);

    const columns = useMemo(
        () => [
            {
                Header: 'Referencia',
                accessor: 'referencia',
            },
            {
                Header: 'Concepto',
                accessor: 'concepto',
            },
            {
                Header: 'Descripción',
                accessor: 'descripcion',
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            hideDelete: true,
            tableOptions: {
                fillEmptySpace: true,
            },
        }),
        [],
    );

    return (
        <Dialog maxWidth='md' {...props}>
            <DialogTitle id='alert-dialog-title' disableTypography>
                <Typography variant='h6'>
                    Buscar {tipoPresupuestoLineaLabels[tipoLinea].toLowerCase()} en el presupuesto
                </Typography>

                <SelectPresupuesto
                    defaultPresupuestoId={originalPresupuestoId}
                    onChange={(presupuesto) => {
                        if (presupuesto) {
                            setPresupuestoId(presupuesto.id);
                            setProyectoId(presupuesto.proyecto_id);
                        } else {
                            setPresupuestoId(null);
                            setProyectoId(null);
                        }
                    }}
                />
            </DialogTitle>

            <ListView
                columns={columns}
                data={data}
                options={options}
                extraActions={(row) => (
                    <Tooltip title='Copiar'>
                        <IconButton
                            onClick={() => {
                                presupuestosProvider
                                    .searchLinea({
                                        ...searchParams,
                                        referencia: row.referencia,
                                    })
                                    .then((lineas) => {
                                        onSelect(lineas[0]);
                                        props.onClose();
                                    });
                            }}
                        >
                            <CopyIcon />
                        </IconButton>
                    </Tooltip>
                )}
            />
            <DialogActions>
                <Button onClick={props.onClose} color='outlined'>
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

SearchLineaDialog.propTypes = {
    presupuestoId: PropTypes.number,
    proyectoId: PropTypes.number,
    tipoLinea: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default withButtonOpener(SearchLineaDialog);
