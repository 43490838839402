import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import clsx from 'clsx';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            borderRadius: 4,
            color: theme.palette.primary.main,
            padding: theme.spacing(0.5, 1),
            whiteSpace: 'nowrap',
            textAlign: 'center',
            border: `1px solid ${theme.palette.primary.main}`,
            marginLeft: 'auto',
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightMedium,
                display: 'flex',
                alignItems: 'center',
                gap: `${theme.spacing(0.5)}px`,
            },
            '& .MuiSvgIcon-root': {
                fontSize: 18,
            },
        },
    }),
    { name: 'JornadaRevisadaChip' },
);

export default function JornadaRevisadaChip() {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root)}>
            <Typography variant='subtitle1'>
                <LockIcon />
                Parte de trabajo revisado
            </Typography>
        </div>
    );
}
