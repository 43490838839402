import { companiesProvider } from '../../../../api';
import { Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Button from '../../../common/Button';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { KeyboardDatePicker } from '../../../common/fields/KeyboardDatePicker';

const StartBackupSchema = Yup.object().shape({
    fecha_inicio: Yup.date().typeError('La fecha de inicio debe ser una fecha').required('Requerido'),
    fecha_fin: Yup.date()
        .typeError('La fecha de fin debe ser una fecha')
        .required('Requerido')
        .min(Yup.ref('fecha_inicio'), 'La fecha de fin debe ser posterior a la de inicio'),
});

export default function StartBackupForm({ onStart }) {
    const snackbar = useSnackbar();

    return (
        <Formik
            initialValues={{
                fecha_inicio: new Date(),
                fecha_fin: new Date(),
            }}
            validationSchema={StartBackupSchema}
            onSubmit={(values, { setSubmitting }) => {
                companiesProvider
                    .action('backups', {
                        method: 'post',
                        body: JSON.stringify(values),
                    })
                    .then((backup) => {
                        onStart(backup);
                    })
                    .catch((err) => {
                        snackbar.showMessage(err.message);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {({ isSubmitting }) => (
                <Form
                    style={{
                        marginTop: 16,
                        marginBottom: 16,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <KeyboardDatePicker name='fecha_inicio' label='Desde' fullWidth disabled={isSubmitting} />
                        </Grid>
                        <Grid item xs={4}>
                            <KeyboardDatePicker name='fecha_fin' label='Hasta' fullWidth disabled={isSubmitting} />
                        </Grid>
                        <Grid item xs={4}>
                            <Button type='submit' color='info' disabled={isSubmitting}>
                                Generar fichero
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}

StartBackupForm.propTypes = {
    onStart: PropTypes.func.isRequired,
};
