import DialogEditor from '../../common/forms/DialogEditor';
import { useState } from 'react';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import GoogleMapsAutocomplete from '../../common/fields/GoogleMapsAutocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

function ValidarDireccionDialog({ onSave, currentDireccionCompleta = null, currentSecundaria = '', ...props }) {
    const [result, setResult] = useState(null);

    function updateDireccionSecundaria(ev) {
        const value = ev.target.value;
        setResult((result) => ({
            ...result,
            address: {
                ...result.address,
                direccion_secundaria: value,
            },
        }));
    }

    return (
        <DialogEditor
            title='Buscar y validar dirección'
            onSave={() => {
                onSave(result);
                props.onClose();
            }}
            canSave={result !== null}
            saveButtonText='Usar esta dirección'
            {...props}
        >
            <Grid container spacing={2}>
                {currentDireccionCompleta && (
                    <Grid item xs={12}>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel shrink>Dirección actual</InputLabel>
                            <div style={{ marginTop: 20 }}>{currentDireccionCompleta}</div>
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={8}>
                    <GoogleMapsAutocomplete
                        placeholder='P.ej. Calle Balmes, 35, Barcelona'
                        label='Dirección'
                        onChange={(result) => {
                            setResult(result);
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        label='Dirección secundaria'
                        placeholder='Bloque, piso, puerta, ...'
                        InputLabelProps={{ shrink: true }}
                        onChange={updateDireccionSecundaria}
                        value={result?.address?.direccion_secundaria || currentSecundaria}
                    />
                </Grid>
                {result && (
                    <Grid container item xs={12} style={{ fontSize: 13, marginTop: 24 }}>
                        <Grid item xs={12} style={{ paddingBottom: 4 }}>
                            <strong>Detalles de la dirección encontrada:</strong>
                        </Grid>
                        <Grid item xs={12}>
                            <strong>Dirección:</strong> {result.address.direccion}
                        </Grid>
                        <Grid item xs={12}>
                            <strong>Código postal:</strong> {result.address.codigo_postal}
                        </Grid>
                        <Grid item xs={12}>
                            <strong>Población:</strong> {result.address.poblacion}
                        </Grid>
                        <Grid item xs={12}>
                            <strong>Provincia:</strong> {result.address.provincia}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </DialogEditor>
    );
}

export default withButtonOpener(ValidarDireccionDialog);

ValidarDireccionDialog.propTypes = {
    currentDireccionCompleta: PropTypes.any,
    currentSecundaria: PropTypes.string,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
