import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { GROUP_GAP, GROUP_SPACE } from './CalendarioHeader';
import { DiaSemanaFilter } from './DiaSemanaFilter';
import { OperariosActivoFilter } from './OperariosActivoFilter';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            height: 48,
        },
        operarioSpace: {
            display: 'flex',
            gap: `${theme.spacing(1.5)}px`,
            maxWidth: GROUP_SPACE + GROUP_GAP,
            minWidth: GROUP_SPACE + GROUP_GAP,
        },
    }),
    { name: 'CalendarioHeaderFilters' },
);

export default function CalendarioHeaderFilters({ onFilter }) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.operarioSpace}>
                <OperariosActivoFilter
                    onFilterChange={(soloActivos) => onFilter('soloOperariosActivos', soloActivos)}
                />
                <DiaSemanaFilter onFilterChange={(diaSemana) => onFilter('dia', diaSemana)} />
            </div>
        </div>
    );
}

CalendarioHeaderFilters.propTypes = {
    onFilter: PropTypes.func,
};
