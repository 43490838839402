import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PropTypes from 'prop-types';
import { format } from '../../../../utils';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            borderTop: `1px solid ${theme.palette.neutral.grey1}`,
            '&:last-of-type': {
                borderBottom: `1px solid ${theme.palette.neutral.grey1}`,
            },
            padding: theme.spacing(2, 0),
            gap: `${theme.spacing(1)}px`,
        },
        marcaje: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 2),
            '&>div': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flex: 1,
            },
        },
        marcajePausa: {
            textAlign: 'center',
        },
        tiempo: {
            fontSize: 32,
            lineHeight: '32px',
        },
        arrow: {
            fontSize: 32,
        },
    }),
    { name: 'Marcaje' },
);

export default function Marcaje({ marcaje, marcajePausa }) {
    const classes = useStyles();

    const pausaInside =
        marcajePausa &&
        marcajePausa.hora_entrada >= marcaje.hora_entrada &&
        (!marcaje.hora_salida || marcajePausa.hora_salida <= marcaje.hora_salida);

    return (
        <div className={classes.root}>
            <div className={classes.marcaje}>
                <div>
                    <Typography variant='body2'>Entrada</Typography>
                    <Typography className={classes.tiempo}>{format(marcaje.hora_entrada, 'HH:mm')}</Typography>
                </div>

                <ArrowForwardIcon className={classes.arrow} />

                <div>
                    <Typography variant='body2'>Salida</Typography>
                    <Typography className={classes.tiempo}>
                        {marcaje.hora_salida ? format(marcaje.hora_salida, 'HH:mm') : '--:--'}
                    </Typography>
                </div>
            </div>
            {pausaInside && marcajePausa.hora_entrada && (
                <Typography variant='body1' className={classes.marcajePausa}>
                    {marcajePausa.hora_salida
                        ? `Descanso registrado de ${format(marcajePausa.hora_entrada, 'HH:mm')} a ${format(
                              marcajePausa.hora_salida,
                              'HH:mm',
                          )}`
                        : `Descanso iniciado a las ${format(marcajePausa.hora_entrada, 'HH:mm')}`}
                </Typography>
            )}
        </div>
    );
}

Marcaje.propTypes = {
    marcaje: PropTypes.object.isRequired,
    marcajePausa: PropTypes.object,
};
