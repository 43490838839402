import { Route, Switch } from 'react-router-dom';
import EditOperarioForm from './EditOperarioForm';
import OperariosList from './OperariosList';

export default function OperariosModule() {
    const onError = (err) => console.error(err);

    return (
        <>
            <Switch>
                <Route
                    path='/empleados/:id'
                    render={({ match }) => <EditOperarioForm basePath='/empleados' match={match} onError={onError} />}
                />
                <Route path='/empleados'>
                    <OperariosList onError={onError} />
                </Route>
            </Switch>
        </>
    );
}
