import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { clientesProvider } from '../../../api';
import DialogForm from '../../common/forms/DialogForm';
import { Tab, Tabs } from '../../common/Tabs';
import { COORDS_GPS, MAPS } from './NewDireccionDialog';
import ValidarDireccion from './ValidarDireccion';

const DireccionSchema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
    coords: Yup.lazy((value, { parent }) =>
        !parent.direccion || parent.direccion.length === 0
            ? Yup.string().required('Requerido')
            : Yup.string().nullable(),
    ),
    direccion: Yup.lazy((value, { parent }) =>
        !parent.coords || parent.coords.length === 0 ? Yup.string().required('Requerido') : Yup.string(),
    ),
    google_place_id: Yup.lazy((value, { parent }) =>
        !parent.coords || parent.coords.length === 0 ? Yup.string().required('Requerido') : Yup.string().nullable(),
    ),
});

const useStyles = makeStyles(
    (theme) => ({
        tabs: {
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'NewDireccionDialog' },
);

export function EditDireccionDialog({ onSave, clienteId, direccion: originalDireccion, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const [direccion, setDireccion] = useState(originalDireccion);
    useEffect(() => setDireccion(originalDireccion), [originalDireccion]);

    const [tipoDireccion, setTipoDireccion] = useState(
        originalDireccion.direccion === '' && originalDireccion.coords && originalDireccion.coords.length > 0
            ? COORDS_GPS
            : MAPS,
    );

    return (
        <DialogForm
            title='Editar dirección'
            maxWidth='md'
            FormikProps={{
                initialValues: {
                    nombre: direccion.nombre,
                    telefono: direccion.telefono,
                    direccion: direccion.direccion,
                    direccion_secundaria: direccion.direccion_secundaria,
                    codigo_postal: direccion.codigo_postal,
                    poblacion: direccion.poblacion,
                    provincia: direccion.provincia,
                    notas: direccion.notas,
                    google_place_id: direccion.google_place_id,
                    coords: direccion.coords,
                },
                validationSchema: DireccionSchema,
                onSubmit: ({ cliente, ...values }, { setSubmitting, setFieldError }) => {
                    clientesProvider
                        .actionOnId(clienteId, `direcciones/${direccion.id}`, values)
                        .then((result) => {
                            onSave(result);
                            snackbar.showMessage('Se ha actualizado la dirección con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ values, setFieldValue }) => {
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                name='nombre'
                                label='Identificador'
                                fullWidth
                                helperText='P.ej: principal, casa, local en Gran Via, obra en Tarragona, ...'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField name='telefono' label='Telefono' fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <Tabs
                                disableGutters
                                value={tipoDireccion}
                                onChange={(ev, newValue) => setTipoDireccion(newValue)}
                                aria-label='Tipo de direccion'
                                classes={{
                                    flexContainer: classes.tabs,
                                }}
                            >
                                <Tab label='Google Maps' value={MAPS} />
                                <Tab label='Coordenadas GPS' value={COORDS_GPS} />
                            </Tabs>
                        </Grid>
                        {tipoDireccion === MAPS && <ValidarDireccion />}
                        {tipoDireccion === COORDS_GPS && (
                            <Grid item xs={6}>
                                <TextField name='coords' label='Coordenadas' fullWidth />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                name='notas'
                                label='Notas'
                                fullWidth
                                multiline
                                minRows={4}
                                InputLabelProps={{ shrink: true }}
                                placeholder='Información extra como nombre de contacto, horario de apertura de una tienda, etc... El operario podrá ver esta información en su móvil.'
                            />
                        </Grid>
                    </Grid>
                );
            }}
        </DialogForm>
    );
}

EditDireccionDialog.propTypes = {
    clienteId: PropTypes.any,
    direccion: PropTypes.any,
    onSave: PropTypes.any,
};
