import SvgIcon from '@material-ui/core/SvgIcon';

export default function CalendarioMonthIcon(props) {
    return (
        <SvgIcon viewBox='0 0 24 24' {...props}>
            <path d='M9.81445 14.2371C9.81445 14.7893 9.36674 15.2371 8.81445 15.2371C8.26217 15.2371 7.81445 14.7893 7.81445 14.2371C7.81445 13.6848 8.26217 13.2371 8.81445 13.2371C9.36674 13.2371 9.81445 13.6848 9.81445 14.2371Z' />
            <path d='M9.81445 17.8347C9.81445 18.387 9.36674 18.8347 8.81445 18.8347C8.26217 18.8347 7.81445 18.387 7.81445 17.8347C7.81445 17.2824 8.26217 16.8347 8.81445 16.8347C9.36674 16.8347 9.81445 17.2824 9.81445 17.8347Z' />
            <path d='M13.8201 14.2371C13.8201 14.7893 13.3724 15.2371 12.8201 15.2371C12.2678 15.2371 11.8201 14.7893 11.8201 14.2371C11.8201 13.6848 12.2678 13.2371 12.8201 13.2371C13.3724 13.2371 13.8201 13.6848 13.8201 14.2371Z' />
            <path d='M13.8201 17.8347C13.8201 18.387 13.3724 18.8347 12.8201 18.8347C12.2678 18.8347 11.8201 18.387 11.8201 17.8347C11.8201 17.2824 12.2678 16.8347 12.8201 16.8347C13.3724 16.8347 13.8201 17.2824 13.8201 17.8347Z' />
            <path d='M17.8809 14.2371C17.8809 14.7893 17.4331 15.2371 16.8809 15.2371C16.3286 15.2371 15.8809 14.7893 15.8809 14.2371C15.8809 13.6848 16.3286 13.2371 16.8809 13.2371C17.4331 13.2371 17.8809 13.6848 17.8809 14.2371Z' />
            <path d='M17.8809 17.8347C17.8809 18.387 17.4331 18.8347 16.8809 18.8347C16.3286 18.8347 15.8809 18.387 15.8809 17.8347C15.8809 17.2824 16.3286 16.8347 16.8809 16.8347C17.4331 16.8347 17.8809 17.2824 17.8809 17.8347Z' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M7.34761 6.95376C6.68487 6.95376 6.14761 7.49102 6.14761 8.15376V9.85068H19.5476V8.15376C19.5476 7.49102 19.0103 6.95376 18.3476 6.95376H7.34761ZM19.5476 11.4507H6.14761V19.1538C6.14761 19.8165 6.68487 20.3538 7.34761 20.3538H18.3476C19.0104 20.3538 19.5476 19.8165 19.5476 19.1538V11.4507ZM4.54761 8.15376C4.54761 6.60736 5.80121 5.35376 7.34761 5.35376H18.3476C19.894 5.35376 21.1476 6.60736 21.1476 8.15376V19.1538C21.1476 20.7002 19.894 21.9538 18.3476 21.9538H7.34761C5.80121 21.9538 4.54761 20.7002 4.54761 19.1538V8.15376Z'
            />
            <path fillRule='evenodd' clipRule='evenodd' d='M9.68105 3.65771V6.94116H8.08105V3.65771H9.68105Z' />
            <path fillRule='evenodd' clipRule='evenodd' d='M17.7143 3.65771V6.94116H16.1143V3.65771H17.7143Z' />
        </SvgIcon>
    );
}
