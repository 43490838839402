import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TiposEquipoField from '../TiposEquipoField';
import PropTypes from 'prop-types';

export default function AjustesEquipos({ classes }) {
    return (
        <Paper elevation={0}>
            <Typography className={classes.title}>Equipos</Typography>
            <Grid container spacing={4} className={classes.content}>
                <Grid item xs={12}>
                    <TiposEquipoField />
                </Grid>
            </Grid>
        </Paper>
    );
}

AjustesEquipos.propTypes = {
    classes: PropTypes.any,
};
