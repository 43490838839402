import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useField } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { notasDiaProvider } from '../../../api';
import { Accordion, AccordionDetails, AccordionSummary } from '../../common/Accordion';
import OperariosField from '../../common/fields/OperariosField';

const useStyles = makeStyles(
    (theme) => ({
        title: {
            flex: 1,
        },
        form: {
            width: '100%',
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
        },
        mensaje: {
            whiteSpace: 'pre-wrap',
        },
    }),
    { name: 'NotaAccordion' },
);
export default function NotaAccordion({ fieldKey, expanded, onExpand, onDelete }) {
    const classes = useStyles();
    const [{ value }] = useField(fieldKey);
    const [editing, setEditing] = useState(value.id === null);

    return (
        <Accordion square expanded={expanded} onChange={onExpand}>
            <AccordionSummary>
                {expanded === value.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                <Typography variant='body2' className={classes.title}>
                    {value.id === null ? (
                        'Nueva nota'
                    ) : editing ? (
                        'Editando nota'
                    ) : (
                        <>
                            <strong>Nota: </strong>
                            {value.operarios.map((op) => op.operario.nombre).join(', ')}
                        </>
                    )}
                </Typography>
                <div>
                    {value.id !== null && (
                        <IconButton
                            onClick={(ev) => {
                                ev.stopPropagation();
                                setEditing((editing) => !editing);
                                onExpand(null, true);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    )}
                    <IconButton
                        onClick={(ev) => {
                            ev.stopPropagation();

                            if (value.id === null) onDelete();
                            else notasDiaProvider.delete(value.id).then(onDelete);
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.form}>
                    {editing ? (
                        <>
                            <OperariosField
                                operariosKey={`${fieldKey}.operarios`}
                                getDefaultOption={(op) => ({ operario: op, vista: false })}
                                getOperarioFromOption={(op) => op.operario}
                            />
                            <TextField name={`${fieldKey}.titulo`} label='Titulo' fullWidth />
                            <TextField
                                name={`${fieldKey}.mensaje`}
                                label='Mensaje'
                                fullWidth
                                multiline
                                minRows={6}
                                InputLabelProps={{ shrink: true }}
                            />
                        </>
                    ) : (
                        <>
                            <Typography variant='body2'>{value.titulo}</Typography>
                            <Typography variant='body2' className={classes.mensaje}>
                                {value.mensaje}
                            </Typography>
                        </>
                    )}
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

NotaAccordion.propTypes = {
    fieldKey: PropTypes.any,
    expanded: PropTypes.bool,
    onExpand: PropTypes.func,
    onDelete: PropTypes.func,
};
