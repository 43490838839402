import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { clientesProvider } from '../../api';
import useAuthState from '../../AuthState';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import DelegacionField from '../common/fields/DelegacionField';
import SelectField from '../common/fields/SelectField';
import DialogForm from '../common/forms/DialogForm';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';

const ClientesSchema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
    // email: Yup.string().required('Requerido'),
});

function EditClienteDialog(props) {
    const snackbar = useSnackbar();

    const {
        userInfo: { preferencias: { usar_delegaciones: usarDelegaciones } = {} },
    } = useAuthState();

    const {
        data: {
            id: clienteId,
            nombre,
            codigo,
            nif,
            email,
            // eslint-disable-next-line camelcase
            email_envios_list,
            direccion,
            telefono,
            // eslint-disable-next-line camelcase
            codigo_postal,
            poblacion,
            provincia,
            activo,
            // eslint-disable-next-line camelcase
            persona_contacto,
            delegacion_id: delegacionId,
            desglosar_explicacion_operarios: desglosarExplicacionOperarios,
        },
        fetchData,
    } = useEditFormData();

    const desglosarOptions = [
        { value: '', label: 'Por defecto', realValue: null },
        { value: 'true', label: 'Si', realValue: true },
        { value: 'false', label: 'No', realValue: false },
    ];

    return (
        <DialogForm
            title='Editar cliente'
            maxWidth='md'
            FormikProps={{
                initialValues: {
                    nombre,
                    codigo,
                    nif,
                    email,
                    email_envios_list,
                    direccion,
                    telefono,
                    codigo_postal,
                    poblacion,
                    provincia,
                    activo,
                    persona_contacto,
                    delegacion: delegacionId ? { id: delegacionId } : null,
                    desglosar_explicacion_operarios: desglosarOptions.find(
                        (op) => op.realValue === desglosarExplicacionOperarios,
                    ).value,
                },
                validationSchema: ClientesSchema,
                onSubmit: (
                    { delegacion, desglosar_explicacion_operarios: desglosarExplicacionOperarios, ...values },
                    { setSubmitting, setFieldError },
                ) => {
                    clientesProvider
                        .save(
                            {
                                delegacion_id: delegacion ? delegacion.id : null,
                                desglosar_explicacion_operarios: desglosarOptions.find(
                                    (op) => op.value === desglosarExplicacionOperarios,
                                ).realValue,
                                ...values,
                            },
                            clienteId,
                        )
                        .then(() => {
                            fetchData();
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField name='nombre' label='Nombre' fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField name='codigo' label='Codigo de cliente' fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField name='nif' label='NIF' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='persona_contacto' label='Persona de contacto' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='telefono' label='Telefono' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='email' label='Email' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name='email_envios_list'
                            label='Email para envios de albaranes de servicio'
                            fullWidth
                            helperText='Puedes añadir varias direcciones separadas por comas'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name='direccion' label='Direccion' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField name='codigo_postal' label='Codigo postal' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField name='poblacion' label='Poblacion' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField name='provincia' label='Provincia' fullWidth />
                    </Grid>
                    <Grid item xs={usarDelegaciones ? 4 : 12}>
                        <CheckboxWithLabel type='checkbox' name='activo' Label={{ label: 'Activo' }} />
                    </Grid>
                    {usarDelegaciones && (
                        <Grid item xs={8}>
                            <DelegacionField />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Field
                            name='desglosar_explicacion_operarios'
                            id='desglosar_explicacion_operarios'
                            component={SelectField}
                            label='Desglosar las explicaciones de los operarios por fechas en los albaranes de servicio'
                            fullWidth
                            selectOptions={desglosarOptions}
                        />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(EditClienteDialog);

EditClienteDialog.propTypes = {
    onClose: PropTypes.any,
};
