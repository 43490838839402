import Button from '@material-ui/core/Button';
import MuiCheckbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import VisibilityIcon from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import { matchSorter } from 'match-sorter';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import { deepEqual } from '../../../utils';
import { DeleteDialogBase } from '../dialogs/DeleteDialog';
import { ColumnToggler } from './ColumnToggler';
import { DefaultColumnFilter } from './DefaultColumnFilter';
import { GlobalFilter } from './GlobalFilter';
import { operations } from './NumberFilter';
import { TablePaginationActions } from './TablePaginationActions';

const Checkbox = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0.5),
    },
}))(MuiCheckbox);

const TableCell = withStyles((theme) => ({
    root: {
        borderBottom: `1px solid ${theme.palette.neutral.grey2}`,
        '&.actions-cell': {
            width: 32,
        },
    },
}))(MuiTableCell);

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

export const numberOperatorFn = (valueTranslator) => (rows, id, filterValue) => {
    return rows.filter((row) => {
        let rowValue = row.values[id];

        if (rowValue === undefined || filterValue === undefined) return true;

        const { operator, value } = filterValue;
        const operation = operations[operator];

        if (!value || !operation) return true;

        if (valueTranslator) rowValue = valueTranslator(rowValue);

        return operation(rowValue, value);
    });
};

// eslint-disable-next-line react/display-name
export const withTableState = (tableId, Component) => (props) => {
    const [tableState, setTableState] = useState(null);

    const tableKey = `${tableId}-TableState`;

    useEffect(() => {
        const storedState = localStorage.getItem(tableKey);

        setTableState(storedState ? JSON.parse(storedState) : {});
    }, [tableKey]);

    if (tableState === null) return null;

    return (
        <Component
            {...props}
            tableId={tableId}
            savedState={tableState}
            onStateChange={(state) => {
                const serializedState = JSON.stringify(state);
                localStorage.setItem(tableKey, serializedState);
                setTableState(state);
            }}
        />
    );
};

const containerHeight = 300;

const useStyles = makeStyles(
    (theme) => ({
        rowActions: {
            display: 'flex',
            justifyContent: 'flex-end',
            flexWrap: 'nowrap',
            width: '100%',
            minHeight: 32,
            '& .MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root .MuiSvgIcon-root': {
                fontSize: '1.25rem',
            },
        },
        headerLabel: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(0.5)}px`,
            '& svg': {
                color: theme.palette.neutral.primary,
                fontSize: 18,
            },
        },
        container: {
            minHeight: containerHeight,
            maxHeight: containerHeight,
        },
        noWrap: {
            whiteSpace: 'nowrap',
        },
        preWrap: {
            whiteSpace: 'pre-wrap',
        },
    }),
    { name: 'ListView' },
);

const defaultTableOptions = {
    usePagination: true,
    fillEmptySpace: false,
    showTablaFiltrada: true,
};

export default function ListView(props) {
    const classes = useStyles();
    const [deleteElementId, setDeleteElementId] = useState(null);

    const {
        savedState,
        onStateChange,
        title,
        removeTitleTypography = false,
        titleActions,
        basePath,
        tableTitle,
        columns: originalColumns,
        data,
        onDelete,
        options,
        extraActions,
        beforeListComponent,
        onSelectedChange,
        className,
    } = props;

    const closeDeleteAlert = () => setDeleteElementId(null);
    const deleteElement = () => {
        onDelete(deleteElementId);
        closeDeleteAlert();
    };
    const confirmDelete = (id) => setDeleteElementId(id);

    const columns = useMemo(() => {
        function renderDelete(row) {
            if (!row.actions || !row.actions.delete) return null;

            return (
                <Tooltip title='Borrar'>
                    <IconButton aria-label='Borrar' onClick={() => confirmDelete(row.id)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            );
        }

        function renderEdit(row) {
            if (!row.actions || !row.actions.edit) return null;

            return (
                <Tooltip title='Ver ficha'>
                    <Link to={`${basePath}/${row.id}`}>
                        <IconButton aria-label='Ver ficha'>
                            <VisibilityIcon />
                        </IconButton>
                    </Link>
                </Tooltip>
            );
        }
        return options.useActions
            ? [
                  ...originalColumns.map((column, index) => ({
                      ...column,
                  })),
                  {
                      id: 'actions',
                      Header: 'Acciones',
                      className: 'actions-cell',
                      width: 120,
                      accessor: (row, idx, original) => {
                          return (
                              <div className={classes.rowActions}>
                                  {extraActions && extraActions(row, idx, original, { renderDelete, renderEdit })}
                                  {!options.hideEdit && row.actions && row.actions.edit && renderEdit(row)}
                                  {!options.hideDelete && row.actions && row.actions.delete && renderDelete(row)}
                              </div>
                          );
                      },
                      canHide: false,
                      disableSortBy: true,
                      disableFilters: true,
                  },
              ]
            : originalColumns;
    }, [originalColumns, options.useActions, options.hideEdit, options.hideDelete, extraActions, basePath]);

    // Data table related stuff
    const tableOptions = {
        ...defaultTableOptions,
        ...options.tableOptions,
    };

    const [expandedTable, setExpandedTable] = useState(tableOptions.expandedTable);

    function toggleExpandedTable() {
        setExpandedTable((v) => !v);
    }

    const { canExportCsv = true, canToggleColumns = true } = options;

    const tableArgs = [];

    if (tableOptions.useGlobalFilter) tableArgs.push(useGlobalFilter);

    tableArgs.push(useFilters);

    if (tableOptions.useSorting !== false) tableArgs.push(useSortBy);

    if (tableOptions.usePagination) tableArgs.push(usePagination);

    if (tableOptions.useSelect) {
        tableArgs.push(useRowSelect);

        if (!tableOptions.hideSelectColumn) {
            tableArgs.push((hooks) => {
                hooks.visibleColumns.push((columns) => [
                    {
                        id: 'selection',
                        // eslint-disable-next-line react/prop-types
                        Header: ({ getToggleAllPageRowsSelectedProps, getToggleAllRowsSelectedProps }) => (
                            <div>
                                <Checkbox
                                    {...(tableOptions.usePagination
                                        ? getToggleAllPageRowsSelectedProps
                                        : getToggleAllRowsSelectedProps)()}
                                    title='Seleccionar todos'
                                />
                            </div>
                        ),
                        // eslint-disable-next-line react/prop-types
                        Cell: ({ row: { getToggleRowSelectedProps } }) => (
                            <div>
                                <Checkbox {...getToggleRowSelectedProps()} title='Seleccionar fila' />
                            </div>
                        ),
                    },
                    ...columns,
                ]);
            });
        }
    }

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
            numberOperator: numberOperatorFn(),
            dateRange: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];

                    if (rowValue === undefined) return true;

                    const { day, month, year } = filterValue;

                    if (day > 0) return isSameDay(rowValue, new Date(year, month - 1, day));

                    if (isBefore(rowValue, new Date(year, month - 1, 1))) return false;

                    return !isAfter(rowValue, new Date(year, month, 0));
                });
            },
        }),
        [],
    );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        [],
    );

    const initialState = {
        ...(tableOptions.initialState || {}),
        ...savedState,
    };

    if (tableOptions.forceHiddenColumns) {
        if (!initialState.hiddenColumns) initialState.hiddenColumns = tableOptions.forceHiddenColumns;
        else initialState.hiddenColumns = [...tableOptions.forceHiddenColumns, ...initialState.hiddenColumns];
    }

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        prepareRow,
        rows,
        page,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter, selectedRowIds },
        setGlobalFilter,
        setFilter,
        setAllFilters,
        columns: tableColumns,
        allColumns,
        visibleColumns,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data: data || [],
            defaultColumn,
            filterTypes,
            initialState,
        },
        ...tableArgs,
    );

    const numRows = rows.length;

    useEffect(() => {
        if (!gotoPage) return;

        const pageStartIndex = pageIndex * pageSize + 1;
        if (numRows < pageStartIndex) {
            gotoPage(0);
        }
    }, [numRows, pageIndex, pageSize, gotoPage]);

    useEffect(() => {
        onSelectedChange && onSelectedChange(selectedRowIds);
    }, [selectedRowIds]);

    const emptyRows = tableOptions.usePagination ? pageSize - page.length : 0;

    const records = tableOptions.usePagination ? page : rows;

    let toggleableColumns = tableOptions.forceHiddenColumns
        ? allColumns.filter((col) => !tableOptions.forceHiddenColumns.includes(col.id))
        : allColumns;

    toggleableColumns = toggleableColumns.filter((col) => col.canHide !== false);

    useEffect(() => {
        if (!onStateChange) return;

        const sortBy = allColumns
            .filter((c) => c.isSorted)
            .sort((a, b) => a.sortedIndex - b.sortedIndex)
            .map((c) => ({
                id: c.id,
                desc: c.isSortedDesc,
            }));

        const filters = allColumns
            .filter((c) => c.filterValue !== undefined)
            .map((c) => ({
                id: c.id,
                value: c.filterValue,
            }));

        const hiddenColumns = allColumns.filter((c) => !c.isVisible).map((c) => c.id);

        const currentState = {
            hiddenColumns,
            sortBy,
            filters,
            pageIndex,
            pageSize,
        };

        if (globalFilter !== undefined) currentState.globalFilter = globalFilter;

        if (!deepEqual(currentState, savedState)) {
            onStateChange(currentState);
        }
    }, [page, allColumns, pageIndex, pageSize, globalFilter, visibleColumns, savedState]);

    // if (rows.length === 0 && props.emptyComponent)
    //     return props.emptyComponent;

    function exportCSV() {
        const columns = visibleColumns.filter((col) => col.id !== 'actions');
        const headers = columns.map((col) => col.Header);

        const separator = ';';

        const data = rows.map((row) => {
            prepareRow(row);
            return row.cells
                .filter((cell) => cell.column.id !== 'actions')
                .map((cell) => {
                    let value = cell.value;

                    if (cell.column.exportValue) {
                        value = cell.column.exportValue({ row, value });
                    } else if (cell.column.Cell) {
                        try {
                            const renderedValue = cell.column.Cell({ value, row });
                            if (typeof renderedValue !== 'object') value = renderedValue;
                        } catch {}
                    }

                    try {
                        if (value === null || value === undefined) {
                            value = '';
                        } else if (value.constructor === Array) {
                            value = value.join(', ');
                        } else if (value.toString().indexOf(separator) >= 0 || value.toString().indexOf('\n') >= 0)
                            value = `"${value}"`;
                    } catch (err) {
                        console.log('Error exporting to CSV', value, err);
                        throw err;
                    }

                    return value;
                });
        });

        const csv = headers.join(separator) + '\n' + data.map((row) => row.join(separator)).join('\n');
        const csvFile = new Blob(['\ufeff', csv], { type: 'text/csv' });
        const downloadLink = document.createElement('a');
        downloadLink.download = (tableTitle || 'exportar') + '.csv';
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }

    const numVisibleColumns = useMemo(
        () => headerGroups[0].headers.reduce((acc, col) => acc + col.getHeaderProps().colSpan, 0),
        [headerGroups],
    );

    const showFooter = useMemo(() => {
        const hasFooter = (columns) => {
            return columns.some((column) => {
                // Si la columna tiene subcolumnas (grupos de columnas)
                if (column.columns) {
                    return hasFooter(column.columns);
                }
                // Si la columna tiene la propiedad Footer y no está oculta
                return column.Footer && !column.isHidden;
            });
        };

        return hasFooter(columns);
    }, [columns]);

    return (
        <div style={props.style} className={className}>
            {title && (
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16, gap: '16px' }}>
                    <Typography variant='h1' component='h1' style={{ flex: 1 }}>
                        {title}
                    </Typography>
                    {titleActions}
                </div>
            )}
            {beforeListComponent && beforeListComponent(setFilter, tableColumns)}
            <Paper elevation={0}>
                <DeleteDialogBase
                    open={deleteElementId !== null}
                    onClose={closeDeleteAlert}
                    onConfirm={deleteElement}
                    text='¿Estás seguro de que deseas borrar este registro?'
                    confirmationText={options.deleteConfirmationText}
                />
                {(tableTitle || options.addRoute) && (
                    <div
                        style={{
                            display: 'flex',
                            position: 'relative',
                            alignItems: 'center',
                            minHeight: 50,
                            paddingLeft: expandedTable === undefined ? 16 : 0,
                            paddingRight: 16,
                            borderBottom: '1px solid #eeeeee',
                        }}
                    >
                        {expandedTable !== undefined && (
                            <IconButton onClick={toggleExpandedTable}>
                                {expandedTable ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        )}
                        {removeTitleTypography ? (
                            tableTitle
                        ) : (
                            <Typography variant={options.tableTitleTypography || 'h6'} style={{ flex: 1 }}>
                                {tableTitle}
                            </Typography>
                        )}
                        {(tableOptions.useGlobalFilter || tableOptions.extraFilters) && (
                            <div
                                style={{
                                    marginLeft: 32,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '24px',
                                }}
                            >
                                {tableOptions.useGlobalFilter && (
                                    <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                                )}
                                {tableOptions.extraFilters && tableOptions.extraFilters(setFilter, tableColumns)}
                            </div>
                        )}
                        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                            {options.addRoute && (
                                <Button startIcon={<AddIcon />} component={Link} to={options.addRoute}>
                                    Añadir
                                </Button>
                            )}
                            {typeof options.batchComponents === 'function'
                                ? options.batchComponents({
                                      rows,
                                      selectedFlatRows,
                                      selectedRowIds,
                                      setFilter,
                                      tableColumns,
                                  })
                                : options.batchComponents}
                            {canToggleColumns && <ColumnToggler columns={toggleableColumns} />}
                            {canExportCsv && (
                                <Tooltip title={'Exportar a CSV'}>
                                    <IconButton onClick={exportCSV}>
                                        <DownloadIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                )}
                {data ? (
                    <Collapse in={expandedTable === undefined || expandedTable}>
                        <TableContainer
                            style={{
                                maxHeight: tableOptions.tableFixedHeight,
                                minHeight: tableOptions.tableFixedHeight,
                            }}
                        >
                            <Table
                                {...getTableProps()}
                                stickyHeader={tableOptions.stickyHeader ?? false}
                                size='small'
                                className={tableOptions.className}
                            >
                                <TableHead>
                                    {headerGroups.map((headerGroup, r) => (
                                        <TableRow
                                            key={r}
                                            {...headerGroup.getHeaderGroupProps()}
                                            style={{ verticalAlign: 'top' }}
                                        >
                                            {headerGroup.headers.map((column, i) => {
                                                const style = {
                                                    fontWeight: 'bold',
                                                    ...column.style,
                                                };

                                                return (
                                                    <TableCell
                                                        key={i}
                                                        {...column.getHeaderProps()}
                                                        style={style}
                                                        align={column.align || 'left'}
                                                    >
                                                        {tableOptions.useSorting !== false && column.canSort ? (
                                                            <TableSortLabel
                                                                active={column.isSorted}
                                                                direction={column.isSortedDesc ? 'desc' : 'asc'}
                                                                {...column.getSortByToggleProps()}
                                                                title=''
                                                            >
                                                                <span className={classes.headerLabel}>
                                                                    {column.render('Header')}
                                                                    {column.helperText && (
                                                                        <Tooltip
                                                                            arrow
                                                                            title={column.helperText}
                                                                            placement='top'
                                                                        >
                                                                            <InfoIcon />
                                                                        </Tooltip>
                                                                    )}
                                                                </span>
                                                            </TableSortLabel>
                                                        ) : (
                                                            column.render('Header')
                                                        )}
                                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                                </TableHead>
                                <TableBody {...getTableBodyProps()}>
                                    {records.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <TableRow key={i} {...row.getRowProps()}>
                                                {row.cells.map((cell, c) => {
                                                    return (
                                                        <TableCell
                                                            key={c}
                                                            {...cell.getCellProps([
                                                                {
                                                                    className: clsx(
                                                                        cell.column.className,
                                                                        cell.column.noWrap ? classes.noWrap : null,
                                                                        cell.column.preWrap ? classes.preWrap : null,
                                                                    ),
                                                                    style: cell.column.style,
                                                                },
                                                                cell.column.getExtraCellProps
                                                                    ? cell.column.getExtraCellProps(cell)
                                                                    : {},
                                                            ])}
                                                            align={cell.column.align || 'left'}
                                                        >
                                                            {cell.render('Cell')}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                    {tableOptions.fillEmptySpace && emptyRows > 0 && (
                                        <TableRow style={{ height: 45 * emptyRows }}>
                                            <TableCell colSpan={columns.length} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {tableOptions.usePagination && (
                                    <TableFooter>
                                        {showFooter && (
                                            <TableRow
                                                {...footerGroups[0].getFooterGroupProps()}
                                                style={{ verticalAlign: 'top' }}
                                            >
                                                {footerGroups[0].headers.map((column, i) => {
                                                    const style = {
                                                        fontWeight: 'bold',
                                                        ...column.style,
                                                    };

                                                    return (
                                                        <TableCell
                                                            key={i}
                                                            {...column.getFooterProps()}
                                                            style={style}
                                                            align={column.align || 'left'}
                                                        >
                                                            {column.Footer && column.render('Footer')}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell
                                                colSpan={numVisibleColumns + (tableOptions.useSelect ? 1 : 0)}
                                                style={{ borderBottom: 'none' }}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {tableOptions.showTablaFiltrada && (
                                                        <div style={{ flex: 1 }}>
                                                            {rows.length !== data.length && (
                                                                <Chip
                                                                    size='small'
                                                                    icon={<InfoIcon />}
                                                                    label={
                                                                        <>
                                                                            <strong>Tabla filtrada:</strong> mostrando{' '}
                                                                            {rows.length} filas de un total de{' '}
                                                                            {data.length}
                                                                        </>
                                                                    }
                                                                    color='primary'
                                                                    onDelete={() => {
                                                                        setAllFilters([]);
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                    <TablePagination
                                                        component={'div'}
                                                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                        count={rows.length}
                                                        rowsPerPage={pageSize}
                                                        page={pageIndex}
                                                        SelectProps={{
                                                            inputProps: { 'aria-label': 'Filas por pagina' },
                                                            native: true,
                                                        }}
                                                        onPageChange={(ev, page) => gotoPage(page)}
                                                        onRowsPerPageChange={(ev) => {
                                                            setPageSize(parseInt(ev.target.value, 10));
                                                            gotoPage(0);
                                                        }}
                                                        ActionsComponent={TablePaginationActions}
                                                    />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                )}
                            </Table>
                        </TableContainer>
                    </Collapse>
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 540,
                        }}
                    >
                        <CircularProgress />
                    </div>
                )}
            </Paper>
        </div>
    );
}

ListView.propTypes = {
    className: PropTypes.any,
    basePath: PropTypes.any,
    beforeListComponent: PropTypes.any,
    data: PropTypes.any,
    columns: PropTypes.any,
    emptyComponent: PropTypes.any,
    extraActions: PropTypes.any,
    onDelete: PropTypes.any,
    onStateChange: PropTypes.any,
    options: PropTypes.any,
    savedState: PropTypes.any,
    style: PropTypes.any,
    tableId: PropTypes.any,
    tableTitle: PropTypes.any,
    title: PropTypes.any,
    removeTitleTypography: PropTypes.bool,
    titleActions: PropTypes.any,
    onSelectedChange: PropTypes.any,
};
