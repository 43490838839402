import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { estadoTareaOptions } from '../../../api/tareas';
import ServicioEstadoChip from '../../servicios/ServicioEstadoChip';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(1)}px`,
        },
        label: {
            color: theme.palette.neutral.primary,
        },
        value: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.primary.main,
        },
        chip: {
            opacity: 0.3,
            cursor: 'pointer',
            '&:hover': {
                opacity: 1,
            },
            '&.selected': {
                opacity: 1,
            },
        },
        todosChip: {
            backgroundColor: theme.palette.primary.main,
        },
    }),
    { name: 'EstadoFilter' },
);

export function EstadoFilter({ onFilterChange, filterValue }) {
    const classes = useStyles();

    function getUpdatedFilterValue(estado) {
        if (estado === null) return [];
        if (filterValue.includes(estado)) return filterValue.filter((e) => e !== estado);

        if (filterValue.length === estadoTareaOptions.length - 1) return [];

        return [...filterValue, estado];
    }

    return (
        <div className={classes.root}>
            <Typography variant='subtitle1' className={classes.label}>
                Estado
            </Typography>
            <ServicioEstadoChip
                value={null}
                label='Todos'
                className={clsx(classes.chip, classes.todosChip, filterValue.length === 0 ? 'selected' : null)}
                onClick={() => onFilterChange(getUpdatedFilterValue(null))}
            />
            {estadoTareaOptions.map(({ value }) => (
                <ServicioEstadoChip
                    key={value}
                    value={value}
                    className={clsx(classes.chip, filterValue.includes(value) ? 'selected' : null)}
                    onClick={() => onFilterChange(getUpdatedFilterValue(value))}
                />
            ))}
        </div>
    );
}

EstadoFilter.propTypes = {
    onFilterChange: PropTypes.any,
    filterValue: PropTypes.any,
};
