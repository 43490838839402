import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { equiposProvider } from '../../api';
import { useFetchData } from '../../hooks/useFetchData';
import BaseEquiposList from '../equipos/BaseEquiposList';

export default function EquiposList() {
    const { id } = useParams();
    const fetchDataFn = useCallback(() => equiposProvider.getByCliente(id), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    const options = useMemo(
        () => ({
            // batchComponents: (
            //     <NewEquipoDialog
            //         clienteId={id}
            //         button={
            //             <Button color='transparent' aria-label='Añadir equipo' startIcon={<AddIcon />}>
            //                 Añadir equipo
            //             </Button>
            //         }
            //         onSave={fetchData}
            //     />
            // ),
        }),
        [],
    );

    return (
        <BaseEquiposList
            hideCliente
            tableId='equipos-cliente'
            data={data}
            fetchData={fetchData}
            extraOptions={options}
        />
    );
}
