import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { GUTTERS, NO_GUTTERS, useMainAppContext } from '../../../../MainApp';
import { EditFormDataProvider } from './EditFormContext';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            overflow: 'hidden',
            flex: 1,
        },
        backButton: {
            position: 'absolute',
            top: 6,
            left: 4,
            padding: theme.spacing(1),
            background: 'white',
            color: theme.palette.neutral.primary,
        },
    }),
    { name: 'EditForm' },
);

export default function EditForm({ children, data, fetchData, className }) {
    const classes = useStyles();
    const history = useHistory();
    const { setContainerGutters } = useMainAppContext();

    useEffect(() => {
        setContainerGutters(NO_GUTTERS);

        return () => setContainerGutters(GUTTERS);
    }, []);

    return (
        <div className={clsx(classes.root, className)}>
            <IconButton className={classes.backButton} onClick={() => history.goBack()}>
                <ArrowBackIcon />
            </IconButton>
            <EditFormDataProvider data={data} fetchData={fetchData}>
                {children}
            </EditFormDataProvider>
        </div>
    );
}

EditForm.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.any,
    data: PropTypes.object.isRequired,
    fetchData: PropTypes.func.isRequired,
};
