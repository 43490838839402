import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';

export default function AjustesPreciosGastos({ classes }) {
    return (
        <Paper elevation={0}>
            <Typography className={classes.title}>Precios de gastos</Typography>
            <Grid container spacing={4} className={classes.content}>
                <Grid item xs={6}>
                    <TextField
                        name='precio_coste_dietas'
                        type='number'
                        step='any'
                        label='Precio coste dietas'
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>€/ud</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        name='precio_venta_dietas'
                        type='number'
                        step='any'
                        label='Precio venta dietas'
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>€/ud</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        name='precio_coste_desplazamiento'
                        type='number'
                        step='any'
                        label='Precio coste desplazamiento'
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>€/ud</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        name='precio_venta_desplazamiento'
                        type='number'
                        step='any'
                        label='Precio venta desplazamiento'
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>€/ud</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        name='precio_coste_parking'
                        type='number'
                        step='any'
                        label='Precio coste parking'
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>€/ud</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        name='precio_venta_parking'
                        type='number'
                        step='any'
                        label='Precio venta parking'
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>€/ud</InputAdornment>,
                        }}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
}

AjustesPreciosGastos.propTypes = {
    classes: PropTypes.any,
};
