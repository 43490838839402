import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import isBefore from 'date-fns/isBefore';
import { Form, Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { contratosMantenimientoProvider, equiposProvider, operariosProvider } from '../../../api';
import { formatISODate } from '../../../utils';
import Button from '../../common/Button';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import YearCalendar from '../YearCalendar/YearCalendar';
import ServicioPlanificador from './ServicioPlanificador';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: '16px',
        },
        calendario: {
            minWidth: 840,
        },
        servicios: {
            flex: 1,
            maxWidth: 400,
            '&>*:first-child': {
                marginTop: 0,
            },
        },
        paper: {
            padding: theme.spacing(2),
        },
        header: {
            fontWeight: theme.typography.fontWeightMedium,
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        hero: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: `${theme.spacing(1)}px`,
            marginTop: theme.spacing(2),
        },
        heroTitle: {
            textAlign: 'center',
            fontWeight: theme.typography.fontWeightMedium,
        },
        heroSubtitle: {
            textAlign: 'center',
            color: theme.palette.neutral.grey4,
        },
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
            gap: `${theme.spacing(1)}px`,
            marginTop: theme.spacing(2),
        },
    }),
    { name: 'PlanificadorAnual' },
);

const PlanificadorAnualSchema = Yup.object().shape({
    serviciosPlanificados: Yup.array().of(
        Yup.object().shape({
            tipos_equipo: Yup.array().min(1, 'Selecciona al menos un tipo de equipo'),
            cuestionarios: Yup.array().min(1, 'Selecciona al menos un cuestionario'),
        }),
    ),
    servicios: Yup.array().of(
        Yup.object().shape({
            tipos_equipo: Yup.array().min(1, 'Selecciona al menos un tipo de equipo'),
            cuestionarios: Yup.array().min(1, 'Selecciona al menos un cuestionario'),
        }),
    ),
});

export default function PlanificadorAnual() {
    const classes = useStyles();
    const { data, fetchData } = useEditFormData();
    const { id } = useParams();

    const [operariosOptions, setOperariosOptions] = useState([]);
    const [tiposOptions, setTiposOptions] = useState([]);

    useEffect(() => {
        const tiposEquipoContrato = [...new Set(data.cuestionarios.map((c) => c.tipos_equipo).flat())];

        equiposProvider
            .getTipos()
            .then((tipos) => setTiposOptions(tipos.filter((t) => tiposEquipoContrato.includes(t.id))));
        operariosProvider.getAll('as_options').then(setOperariosOptions);
    }, [data.cuestionarios]);

    const serviciosPlanificados = useMemo(
        () =>
            data.tareas_mantenimiento
                .map(({ planificaciones, ...tarea }) => ({
                    ...tarea,
                    fecha: new Date(planificaciones[0].fecha_inicio),
                    operarios: planificaciones.map((p) => p.operarios.map((o) => o.operario)).flat(),
                }))
                .sort(function (a, b) {
                    if (isBefore(a.fecha, b.fecha)) return -1;

                    return 1;
                }),
        [data.tareas_mantenimiento],
    );

    return (
        <Formik
            initialValues={{
                serviciosPlanificados,
                cuestionarios: data.cuestionarios,
                tipos_equipo: tiposOptions,
                servicios: [],
            }}
            validationSchema={PlanificadorAnualSchema}
            enableReinitialize
            onSubmit={({ servicios }, { setSubmitting, setFieldError }) => {
                contratosMantenimientoProvider
                    .actionOnId(id, 'tareas', servicios)
                    .then(fetchData)
                    .catch((err) => {
                        if (err.status === 400) {
                            for (const [field, errors] of Object.entries(err.message)) {
                                setFieldError(field, errors.join('\n'));
                            }
                        }
                    });

                setSubmitting(false);
            }}
        >
            {({ values, setFieldValue }) => {
                const numPendientesPlanificar = data.num_servicios - values.serviciosPlanificados.length;
                const selectedDates = values.servicios.map((s) => formatISODate(s.fecha));
                const plannedDates = values.serviciosPlanificados.map((s) => formatISODate(s.fecha));

                function onSelect(fecha) {
                    if (plannedDates.includes(fecha)) return;
                    const idx = values.servicios.findIndex((s) => formatISODate(s.fecha) === fecha);

                    if (idx >= 0) {
                        values.servicios.splice(idx, 1);
                        setFieldValue('servicios', [...values.servicios]);

                        return;
                    }

                    if (values.servicios.length >= numPendientesPlanificar) return;

                    values.servicios.push({
                        fecha: new Date(fecha),
                        tipos_equipo: [],
                        cuestionarios: [],
                        operarios: [],
                    });
                    values.servicios.sort((a, b) => (a.fecha < b.fecha ? -1 : 1));
                    setFieldValue('servicios', [...values.servicios]);
                }

                return (
                    <Form className={classes.root}>
                        <YearCalendar
                            startDate={new Date(data.fecha_inicio)}
                            endDate={new Date(data.fecha_fin)}
                            className={classes.calendario}
                            plannedDates={plannedDates}
                            selectedDates={selectedDates}
                            onSelect={onSelect}
                        />
                        <div className={classes.servicios}>
                            {values.serviciosPlanificados.length > 0 && (
                                <>
                                    <Typography className={classes.header}>
                                        Servicios de mantenimiento planificados
                                    </Typography>

                                    {values.serviciosPlanificados.map((_, i) => (
                                        <ServicioPlanificador
                                            key={i}
                                            servicioKey={`serviciosPlanificados.${i}`}
                                            operariosOptions={operariosOptions}
                                            onDelete={fetchData}
                                            onSave={(servicio) => {
                                                contratosMantenimientoProvider
                                                    .action(`tareas/${servicio.id}`, {
                                                        method: 'post',
                                                        body: JSON.stringify(servicio),
                                                    })
                                                    .then(fetchData);
                                            }}
                                        />
                                    ))}
                                </>
                            )}

                            {values.servicios.length === 0 && numPendientesPlanificar > 0 && (
                                <Paper elevation={0} className={clsx(classes.paper, classes.hero)}>
                                    <Typography variant='h1' className={classes.heroTitle}>
                                        Te faltan {numPendientesPlanificar} servicios de mantenimiento por planificar
                                    </Typography>
                                    <Typography variant='body2' className={classes.heroSubtitle}>
                                        Haz clic en el calendario para añadir esos dias a lista de mantenimientos que
                                        quieras planificar.
                                    </Typography>
                                </Paper>
                            )}

                            {values.servicios.length > 0 && (
                                <>
                                    <Typography className={classes.header}>
                                        Servicios de mantenimiento sin planificar ({values.servicios.length}/
                                        {numPendientesPlanificar})
                                    </Typography>
                                    {values.servicios.map((_, i) => (
                                        <ServicioPlanificador
                                            key={i}
                                            servicioKey={`servicios.${i}`}
                                            operariosOptions={operariosOptions}
                                            onDelete={() =>
                                                setFieldValue(
                                                    'servicios',
                                                    values.servicios.filter((_, j) => j !== i),
                                                )
                                            }
                                        />
                                    ))}
                                    {values.servicios.length > 0 && (
                                        <div className={classes.buttons}>
                                            <Button color='outlined' onClick={() => setFieldValue('servicios', [])}>
                                                Cancelar
                                            </Button>
                                            <Button type='submit' color='info'>
                                                Planificar servicios
                                            </Button>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}
