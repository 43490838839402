import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { dataProvider } from '../../api/proyectos';
import { useFetchData } from '../../hooks/useFetchData';
import NewProyectoDialog from '../clientes/proyectos/NewProyectoDialog';
import Button from '../common/Button';
import BaseListView, { withTableState } from '../common/list/ListView';

const ListView = withTableState('proyectos', BaseListView);

export default function ProyectosList() {
    const history = useHistory();
    const fetchDataFn = useCallback(() => dataProvider.getAll(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        dataProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Codigo',
                accessor: 'codigo',
            },
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
            {
                Header: 'Cliente',
                accessor: 'cliente',
            },
            {
                Header: 'Número de servicios',
                accessor: 'num_tareas',
            },
            {
                Header: 'Número de presupuestos',
                accessor: 'num_presupuestos',
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            deleteConfirmationText: 'borrar proyecto',
        }),
        [],
    );

    return (
        <ListView
            title='Proyectos'
            tableTitle='Lista de proyectos'
            titleActions={
                <NewProyectoDialog
                    button={
                        <Button color='info' aria-label='Nuevo proyecto' startIcon={<AddIcon />}>
                            Nuevo proyecto
                        </Button>
                    }
                    onSave={({ id }) => history.push(`proyectos/${id}`)}
                />
            }
            basePath='/proyectos'
            columns={columns}
            data={data}
            options={options}
            onDelete={onDelete}
        />
    );
}
