import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { companiesProvider } from '../../../../../api';
import { diasSemana, horarioDbValuesToFormValues } from '../../../../../api/companies';
import { createTiempo } from '../../../../../api/tareas-functions';
import { withButtonOpener } from '../../../../../hooks/withButtonOpener';
import { formatHora, formatTiempo } from '../../../../../utils';
import DialogForm from '../../../../common/forms/DialogForm';

const HorariosSchema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
});

const useStyles = makeStyles(
    (theme) => ({
        dialogPaper: {},
        colorEditor: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        descripcion: {
            whiteSpace: 'pre-line',
        },
    }),
    { name: 'EditHorariosDialog' },
);

function EditHorariosDialog({ id, onSave, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const [horario, setHorario] = useState(null);

    useEffect(() => {
        if (!props.open) {
            setHorario(null);
            return;
        }

        companiesProvider.getHorarioEmpresa(id).then(setHorario);
    }, [id, props.open]);

    if (!horario) return null;

    return (
        <DialogForm
            title='Editar horarios'
            maxWidth='sm'
            className={classes.dialogPaper}
            FormikProps={{
                initialValues: {
                    nombre: horario.nombre,
                    dias: horarioDbValuesToFormValues(horario.horarios),
                },
                enableReinitialize: true,
                validationSchema: HorariosSchema,
                onSubmit: ({ nombre, dias }, { setSubmitting, setFieldError }) => {
                    const payload = {
                        nombre,
                    };

                    companiesProvider
                        .updateHorarioEmpresa(id, payload)
                        .then((data) => {
                            onSave && onSave(data.id);
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {({ values }) => (
                <Grid container spacing={2} direction='column'>
                    <Grid item xs={12}>
                        <TextField name='nombre' label='Nombre' fullWidth />
                    </Grid>
                    <Grid item xs={12} container>
                        {values.dias.map((horarioDia, index) => (
                            <>
                                <Grid item xs={2}>
                                    {diasSemana[index]}
                                </Grid>
                                <Grid item xs={8}>
                                    {horarioDia.activo
                                        ? horarioDia.horario
                                              .map(
                                                  (horas) =>
                                                      `De ${formatHora(horas.hora_entrada)} a ${formatHora(
                                                          horas.hora_salida,
                                                      )}`,
                                              )
                                              .join(' | ')
                                        : 'Sin horario'}
                                </Grid>
                                <Grid item xs={2}>
                                    {formatTiempo(createTiempo(horarioDia.minutos_laborables))}
                                </Grid>
                            </>
                        ))}
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(EditHorariosDialog);

EditHorariosDialog.propTypes = {
    id: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};
