import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { companiesProvider } from '../../../../../../api';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            padding: 6,
            '& .MuiSvgIcon-root': {
                fontSize: 20,
            },
        },
    }),
    { name: 'PorDefectoButton' },
);

export default function PorDefectoButton({ value, tipoHoraCategoria, id, onSave }) {
    const snackbar = useSnackbar();
    const classes = useStyles();

    return !value ? (
        <Tooltip title='Marcar por defecto'>
            <IconButton
                className={classes.root}
                onClick={() => {
                    companiesProvider
                        .setTipoHoraPorDefecto(id, tipoHoraCategoria)
                        .then(onSave)
                        .catch((err) => snackbar.showMessage(err.message));
                }}
            >
                <BookmarkBorderIcon />
            </IconButton>
        </Tooltip>
    ) : (
        <Tooltip title='Por defecto'>
            <IconButton className={classes.root}>
                <BookmarkIcon />
            </IconButton>
        </Tooltip>
    );
}

PorDefectoButton.propTypes = {
    value: PropTypes.bool,
    id: PropTypes.string,
    onSave: PropTypes.func,
    tipoHoraCategoria: PropTypes.string,
};
