import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { usePermisos, usePreferencias } from '../../AuthState';
import { ListItem } from './AppBarMenu';
import ButtonMenu from './ButtonMenu';

export default function MasMenu({ disabled }) {
    const [usarPreparacionMateriales, usarAlbaranesCompra, usarDocumentos, usarRevisionMateriales] = usePreferencias(
        'usar_preparacion_materiales',
        'usar_albaranes_compra',
        'usar_documentos',
        'usar_revision_materiales',
    );

    const [gestionarPreparacionesMaterial, gestionarProveedores, gestionarDocumentacion] = usePermisos(
        'gestionar_preparaciones_material',
        'gestionar_proveedores',
        'gestionar_documentacion',
    );

    const items = useMemo(() => {
        const items = [
            {
                path: '/vehiculos',
                label: 'Vehículos',
            },
        ];

        if (usarPreparacionMateriales && gestionarPreparacionesMaterial)
            items.push({
                path: '/preparacion-material',
                label: 'Preparación de material',
            });

        if (usarAlbaranesCompra) {
            if (gestionarProveedores) {
                items.push({
                    path: '/proveedores',
                    label: 'Proveedores',
                });
            }
            items.push({
                path: '/albaranes-compra',
                label: 'Albaranes de compra',
            });

            if (usarRevisionMateriales) {
                items.push({
                    path: '/revision-material',
                    label: 'Revisión de material',
                });
            }
        }

        if (usarDocumentos && gestionarDocumentacion) {
            items.push({
                path: '/documentos',
                label: 'Documentos',
            });
        }

        return items;
    }, [
        usarPreparacionMateriales,
        gestionarPreparacionesMaterial,
        gestionarProveedores,
        gestionarDocumentacion,
        usarDocumentos,
        usarAlbaranesCompra,
    ]);

    return <ButtonMenu button={<ListItem label='Más' disabled={disabled} />} items={items} />;
}

MasMenu.propTypes = {
    disabled: PropTypes.any,
};
