import { useMemo } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            margin: theme.spacing(2, 0),
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        paper: {
            padding: theme.spacing(2),
            flex: 1,
        },
    }),
    { name: 'CompaniesKpis' },
);

export default function CompaniesKpis({ companies }) {
    const classes = useStyles();

    const { mrr, arr, numClientesPrueba, numClientesPagando } = useMemo(() => {
        const clientesPagando = companies.filter((c) => c.estado === 'PAGANDO');
        const clientesPrueba = companies.filter((c) => c.estado === 'PRUEBA' || c.estado === 'PRUEBA_CADUCADA');

        const mrr = clientesPagando.reduce((total, company) => total + company.tarifa.importe, 0);
        const arr = mrr * 12;

        return {
            mrr,
            arr,
            numClientesPrueba: {
                total: clientesPrueba.length,
                prueba: clientesPrueba.filter((c) => c.estado === 'PRUEBA').length,
                caducados: clientesPrueba.filter((c) => c.estado === 'PRUEBA_CADUCADA').length,
            },
            numClientesPagando: {
                total: clientesPagando.length,
                mensual: clientesPagando.filter((c) => c.tarifa.pago_mensual).length,
                anual: clientesPagando.filter((c) => !c.tarifa.pago_mensual).length,
            },
        };
    }, [companies]);

    return (
        <div className={classes.root}>
            <Paper elevation={0} className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Revenue</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        MRR: {mrr.toFixed(2)} EUR/mes
                    </Grid>
                    <Grid item xs={12}>
                        ARR: {arr.toFixed(2)} EUR/año
                    </Grid>
                </Grid>
            </Paper>
            <Paper elevation={0} className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Clientes en prueba</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        Total: {numClientesPrueba.total}
                    </Grid>
                    <Grid item xs={12}>
                        Probando: {numClientesPrueba.prueba}
                    </Grid>
                    <Grid item xs={12}>
                        Prueba caducada: {numClientesPrueba.caducados}
                    </Grid>
                </Grid>
            </Paper>
            <Paper elevation={0} className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Clientes pagando</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        Total: {numClientesPagando.total}
                    </Grid>
                    <Grid item xs={12}>
                        Mensual: {numClientesPagando.mensual}
                    </Grid>
                    <Grid item xs={12}>
                        Anual: {numClientesPagando.anual}
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

CompaniesKpis.propTypes = {
    companies: PropTypes.any,
};
