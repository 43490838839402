import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { companiesProvider } from '../../../../api';
import PrimerosPasosStep from './PrimerosPasosStep';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2),
            width: 700,
            maxWidth: 700,
            margin: '0 auto',
        },
        title: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        list: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(3),
        },
    }),
    { name: 'PrimerosPasos' },
);

export default function PrimerosPasos({ id, nombre, initialPrimerosPasos }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const [primerosPasos, setPrimerosPasosBase] = useState(null);

    function setPrimerosPasos(steps) {
        setPrimerosPasosBase(
            steps.map((step) => ({
                ...step,
                fecha_completado: step.fecha_completado ? new Date(step.fecha_completado) : null,
            })),
        );
    }

    useEffect(() => {
        setPrimerosPasos(initialPrimerosPasos);
    }, [initialPrimerosPasos]);

    function onToggleCompletado(step) {
        const isCompletado = step.fecha_completado !== null;
        companiesProvider
            .updatePrimerosPasos(id, step.id, !isCompletado)
            .then((res) => setPrimerosPasos(res.steps))
            .catch((err) => {
                if (err.status === 400) {
                    snackbar.showMessage(err.message);
                    setPrimerosPasosBase((steps) =>
                        steps.map((s) => (s.id !== step.id ? s : { ...s, error: err.message })),
                    );
                }
            });
    }

    return (
        <div className={classes.root}>
            <Typography variant='h1' className={classes.title} component='div'>
                {nombre}
                {/* <Button */}
                {/*    color='outlined' */}
                {/*    startIcon={<ReplayIcon />} */}
                {/*    onClick={() => companiesProvider.resetPrimerosPasos().then((res) => setPrimerosPasos(res.steps))} */}
                {/* > */}
                {/*    Reiniciar */}
                {/* </Button> */}
                {/* <Button */}
                {/*    color='outlined' */}
                {/*    startIcon={<ReplayIcon />} */}
                {/*    onClick={() => companiesProvider.checkPrimerosPasos().then((res) => setPrimerosPasos(res.steps))} */}
                {/* > */}
                {/*    Check */}
                {/* </Button> */}
            </Typography>
            {primerosPasos && (
                <div className={classes.list}>
                    {primerosPasos.map((step, i) => (
                        <PrimerosPasosStep
                            key={step.id}
                            idx={i + 1}
                            step={step}
                            onToggleCompletado={onToggleCompletado}
                            onRequestSuccess={(result) => setPrimerosPasos(result.steps)}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

PrimerosPasos.propTypes = {
    id: PropTypes.string,
    nombre: PropTypes.string,
    initialPrimerosPasos: PropTypes.array,
};
