import { Route, Switch } from 'react-router-dom';
import SolicitudesList from './SolicitudesList';

export default function SolicitudesModule() {
    const onError = (err) => console.error(err);

    return (
        <>
            <Switch>
                {/* <Route path='/solicitudes/calendario'> */}
                {/*    <SolicitudesCalendarioView /> */}
                {/* </Route> */}
                <Route path='/solicitudes'>
                    <SolicitudesList onError={onError} />
                </Route>
            </Switch>
        </>
    );
}
