import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { format } from '../../utils';
import { ButtonDialog } from '../common/dialogs/ButtonDialog';

const useStyles = makeStyles(
    (theme) => ({
        image: {
            '.image-gallery-content & .image-gallery-image': {
                maxHeight: 500,
            },
            '.image-gallery-content.fullscreen & .image-gallery-image': {
                minHeight: 'calc(100vh - 125px)',
            },
        },
        gallery: {
            '& .image-gallery-slide .image-gallery-description': {
                whiteSpace: 'pre-line',
                textAlign: 'left',
            },
            '& .image-gallery-icon': {
                color: 'rgba(0,0,0,.4)',
            },
            '& .fullscreen .image-gallery-icon': {
                color: 'white',
            },
        },
        thumbnailContainer: {
            position: 'relative',
        },
        thumbnailDelete: {
            position: 'absolute',
            bottom: 0,
            right: 60,
            padding: 20,
            color: 'white',
            '& svg': {
                height: 30,
                width: 30,
            },
        },
    }),
    { name: 'ButtonFotoSlideshow' },
);

export function ButtonFotoSlideshow({ button, tooltip, fotos, onDelete }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const slideshowRef = useRef(null);

    // eslint-disable-next-line quotes
    const formatStr = "dd/MM/yyyy' a las 'HH:mm";

    const images = fotos.map((foto) => {
        const descriptions = [`por ${foto.operario} el ${format(new Date(foto.fecha), formatStr)}`];

        if (foto.texto) descriptions.push(foto.texto);

        return {
            original: foto.image_url,
            thumbnail: foto.thumbnail_url,
            description: descriptions.join('\n'),
            originalClass: classes.image,
        };
    });

    let formButton = React.cloneElement(button, { onClick: () => setOpen(true) });

    if (tooltip) {
        formButton = (
            <Tooltip arrow title={tooltip}>
                {formButton}
            </Tooltip>
        );
    }

    // function renderThumbInner(item) {
    //     return (
    //         <span className={clsx(classes.thumbnailContainer, "image-gallery-thumbnail-inner")}>
    //             <img
    //                 className="image-gallery-thumbnail-image"
    //                 src={item.thumbnail}
    //                 height={item.thumbnailHeight}
    //                 width={item.thumbnailWidth}
    //                 alt={item.thumbnailAlt}
    //                 title={item.thumbnailTitle}
    //             />
    //             <IconButton className={classes.thumbnailDelete}>
    //                 <DeleteIcon />
    //             </IconButton>
    //             {
    //                 item.thumbnailLabel && (
    //                     <div className="image-gallery-thumbnail-label">
    //                         {item.thumbnailLabel}
    //                     </div>
    //                 )
    //             }
    //       </span>
    //     );
    // }

    function renderCustomControls() {
        return (
            <>
                {onDelete && (
                    <ButtonDialog
                        dialogTitle='¿Borrar la foto?'
                        dialogText='Esta opcion no se puede deshacer. ¿Deseas continuar?'
                        onConfirm={() => onDelete(slideshowRef.current.getCurrentIndex())}
                        button={
                            <IconButton className={clsx('image-gallery-icon', classes.thumbnailDelete)}>
                                <DeleteIcon className='image-gallery-svg' />
                            </IconButton>
                        }
                    />
                )}
            </>
        );
    }

    return (
        <>
            {formButton}
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'md'} style={{ padding: 0 }}>
                <DialogContent>
                    <ImageGallery
                        ref={slideshowRef}
                        additionalClass={classes.gallery}
                        showIndex
                        lazyLoad
                        items={images}
                        renderCustomControls={renderCustomControls}
                        // renderThumbInner={renderThumbInner}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}

export const FotoSlideshow = ({ fotos, ...props }) => (
    <ButtonFotoSlideshow
        button={
            <Button
                style={{ fontSize: 12, backgroundColor: '#2196F3', color: 'white', marginTop: 8, marginLeft: 'auto' }}
            >
                Ver fotos
            </Button>
        }
        fotos={fotos}
        {...props}
    />
);

ButtonFotoSlideshow.propTypes = {
    button: PropTypes.any,
    fotos: PropTypes.any,
    onDelete: PropTypes.any,
    tooltip: PropTypes.any,
};

FotoSlideshow.propTypes = {
    fotos: PropTypes.any,
};
