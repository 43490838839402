import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        label: {
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: 12,
        },
        value: {
            color: '#8F95AF',
            fontSize: 16,
            display: 'flex',
            alignItems: 'center',
            paddingTop: 6,
        },
    }),
    { name: 'InfoField' },
);

export default function InfoField({ label, value, className, style, classes, hideLabel = false }) {
    const defaultClasses = useStyles();

    return (
        <div className={classNames(defaultClasses.root, classes && classes.root, className)} style={style}>
            {!hideLabel && <div className={(classes && classes.label) || defaultClasses.label}>{label}</div>}
            <div className={(classes && classes.value) || defaultClasses.value}>{value}</div>
        </div>
    );
}

InfoField.propTypes = {
    className: PropTypes.any,
    classes: PropTypes.any,
    hideLabel: PropTypes.bool,
    label: PropTypes.any,
    style: PropTypes.any,
    value: PropTypes.any,
};
