export function createTiempo(minutos, allowNegative = false) {
    if (minutos <= 0 && !allowNegative)
        return {
            horas: 0,
            minutos: 0,
        };

    const absMinutos = Math.abs(minutos);

    const horas = Math.floor(absMinutos / 60);

    return {
        horas: (minutos < 0 ? -1 : 1) * horas,
        minutos: (minutos < 0 && horas === 0 ? -1 : 1) * (absMinutos % 60),
    };
}

export function getHoras(t) {
    if (!t) return 0;

    return t.horas + t.minutos / 60;
}

export function getMinutos(t) {
    if (!t) return 0;

    return t.horas * 60 + t.minutos;
}

export function isEqualTiempo(t1, t2) {
    return getMinutos(t1) === getMinutos(t2);
}

export function addTiempo(t1, t2) {
    if (!t1) return t2;

    if (!t2) return t1;

    return createTiempo(getMinutos(t1) + getMinutos(t2));
}

export const isTiempoZero = (tiempo) => !tiempo || (tiempo.horas === 0 && tiempo.minutos === 0);

export function getTiempoJornadas(tareas) {
    const horasTrabajadasJornada = {};

    for (const tarea of tareas) {
        for (const jornada of tarea.jornadasPropias) {
            horasTrabajadasJornada[jornada.fecha] = addTiempo(horasTrabajadasJornada[jornada.fecha], jornada.tiempo);
        }
    }

    return horasTrabajadasJornada;
}

export function getTiempoTotal(tarea) {
    let total = null;

    for (const jornada of tarea.jornadasPropias) {
        total = addTiempo(total, jornada.tiempo);
    }

    return total;
}
