import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { companiesProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import DialogForm from '../common/forms/DialogForm';

const CompanySchema = Yup.object().shape({
    name: Yup.string().required('Requerido'),
    contact_name: Yup.string().required('Requerido'),
    nif: Yup.string().required('Requerido'),
    telefono: Yup.string().required('Requerido'),
    direccion: Yup.string().required('Requerido'),
    codigo_postal: Yup.string().required('Requerido'),
    poblacion: Yup.string().required('Requerido'),
    provincia: Yup.string().required('Requerido'),
    username: Yup.string().required('Requerido'),
    email: Yup.string().email('No es un email válido'),
    password: Yup.string().required('Requerido'),
    confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
});

function NewCompanyDialog({ onSave, ...props }) {
    const snackbar = useSnackbar();

    return (
        <DialogForm
            title='Añadir company'
            maxWidth='md'
            FormikProps={{
                initialValues: {
                    name: '',
                    contact_name: '',
                    nif: '',
                    telefono: '',
                    direccion: '',
                    codigo_postal: '',
                    poblacion: '',
                    provincia: '',
                    username: '',
                    email: '',
                    password: '',
                    confirm_password: '',
                },
                validationSchema: CompanySchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    companiesProvider
                        .save(values)
                        .then((data) => {
                            onSave && onSave(data.id);
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField name='name' label='Denominación social' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='contact_name' label='Nombre comercial' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='nif' label='NIF' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='telefono' label='Telefono' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='direccion' label='Direccion' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='codigo_postal' label='Codigo postal' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='poblacion' label='Poblacion' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='provincia' label='Provincia' fullWidth />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField name='username' label='Usuario' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='email' label='Email' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='password' label='Contraseña' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='confirm_password' label='Confirmar contraseña' fullWidth />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(NewCompanyDialog);

NewCompanyDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
