import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: `${theme.spacing(3)}px`,
            '&>div': {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: `${theme.spacing(2)}px`,
            },
        },
        actions: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        empty: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            margin: theme.spacing(6, 'auto'),
            gap: `${theme.spacing(3)}px`,
            width: 430,
        },
        emptyText: {
            color: theme.palette.neutral.grey4,
        },
    }),
    { name: 'EmptyListHero' },
);

export default function EmptyListHero({ title, children, action }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper elevation={0}>
                <div className={classes.empty}>
                    <img src='/images/empty-partes-trabajo.png' />
                    <Typography variant='h1'>{title}</Typography>
                    <Typography variant='body2' className={classes.emptyText}>
                        {children}
                    </Typography>
                    {action}
                </div>
            </Paper>
        </div>
    );
}

EmptyListHero.propTypes = {
    action: PropTypes.any,
    children: PropTypes.any,
    title: PropTypes.any,
};
