import PropTypes from 'prop-types';
import { tareasProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core';
import ColorEditorDialog from '../common/dialogs/ColorEditor/ColorEditorDialog';

function PlanificacionColorEditor({ planificacion, ...props }) {
    const theme = useTheme();
    const defaultColor = {
        color_bg: theme.palette.primary.hover,
        color_fg: theme.palette.text.primary,
    };

    const [colors, setColors] = useState([]);

    useEffect(() => {
        if (!props.open) return;

        tareasProvider.action('colores').then((recentColors) => {
            const newColors = [defaultColor, ...recentColors];

            setColors(newColors);
        });
    }, [props.open]);

    return (
        <ColorEditorDialog
            title='Editar color del evento'
            backgroundColor={planificacion.color_bg}
            textColor={planificacion.color_fg}
            onSaveColor={(values) => {
                return tareasProvider.action(`${planificacion.id}/color`, {
                    method: 'post',
                    body: JSON.stringify(values),
                });
            }}
            colors={colors}
            {...props}
        />
    );
}

PlanificacionColorEditor.propTypes = {
    onClose: PropTypes.any,
    planificacion: PropTypes.any,
    open: PropTypes.bool,
};

export default withButtonOpener(PlanificacionColorEditor);
