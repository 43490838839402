import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import classNames from 'classnames';
import { isFuture } from 'date-fns';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { formatISODate } from '../../utils';

export const calendarColors = {
    day: '#163E88',
    noLaborable: '#4C60FE',
    sinRegistro: '#E94F4F',
    registroIncompleto: '#F3AC3D',
    registroCompleto: '#30BDA1',
};

export const calendarLegend = {
    day: 'Laborable, sin registro',
    noLaborable: 'No laborable',
    sinRegistro: 'Sin registro',
    registroIncompleto: 'Jornada parcialmente completada',
    registroCompleto: 'Jornada completada',
};

const useStyles = makeStyles(
    (theme) => ({
        day: {
            '& button': {
                color: 'white',
                backgroundColor: `${calendarColors.day} !important`,
            },
        },
    }),
    { name: 'MonthDaySelector' },
);

const useJornadaStyles = makeStyles(
    (theme) => ({
        noLaborable: {
            '& button': {
                backgroundColor: `${calendarColors.noLaborable} !important`,
            },
        },
        sinRegistro: {
            '& button': {
                backgroundColor: `${calendarColors.sinRegistro} !important`,
            },
        },
        registroIncompleto: {
            '& button': {
                backgroundColor: `${calendarColors.registroIncompleto} !important`,
            },
        },
        registroCompleto: {
            '& button': {
                backgroundColor: `${calendarColors.registroCompleto} !important`,
            },
        },
    }),
    { name: 'Jornada' },
);

export function getClassNameFromResumenJornada(horario, efectivas, fecha, jornada) {
    const noLaborable = horario === null || efectivas === 0;
    let className = noLaborable ? 'noLaborable' : null;

    if (!isFuture(new Date(fecha))) {
        if (jornada === 0 && !noLaborable) {
            className = 'sinRegistro';
        } else if (jornada > 0 && jornada >= efectivas) {
            className = 'registroCompleto';
        } else if (jornada < efectivas) {
            className = 'registroIncompleto';
        }
    }
    return className;
}

export function useClassesByFechaJornada() {
    const classes = useJornadaStyles();

    return useCallback(
        (resumen) =>
            Object.fromEntries(
                resumen.map(({ fecha, jornada, laborables: { horario, efectivas } }) => {
                    const className = getClassNameFromResumenJornada(horario, efectivas, fecha, jornada);

                    return [fecha, className ? classes[className] : null];
                }),
            ),
        [classes],
    );
}

export function useClassesByFechaCalendarioPlanificacion(operarios) {
    const classes = useJornadaStyles();

    return useCallback(
        (resumen) =>
            Object.fromEntries(
                resumen.map(({ fecha, no_laborable: noLaborable, has_tareas: hasTareas }) => {
                    let className = noLaborable ? 'noLaborable' : null;

                    const operarioHasTareas =
                        operarios.length === 0
                            ? hasTareas.length > 0
                            : hasTareas.some((operarioId) => operarios.includes(operarioId));
                    if (!operarioHasTareas && !noLaborable) {
                        className = 'registroCompleto';
                    } else if (operarioHasTareas) {
                        className = 'sinRegistro';
                    }
                    return [fecha, className ? classes[className] : null];
                }),
            ),
        [classes, operarios],
    );
}

export default function MonthDaySelector({
    fecha,
    classesByFecha,
    onDateChange,
    onMonthChange,
    showMonthSelector = false,
    showSelectedDay = true,
}) {
    const classes = useStyles();

    const materialTheme = useMemo(
        () =>
            createTheme({
                overrides: {
                    MuiTypography: {
                        body2: {
                            fontFamily: 'inherit',
                            fontWeight: 'inherit',
                        },
                        caption: {
                            fontSize: 14,
                        },
                    },
                    MuiIconButton: {
                        root: {
                            fontFamily: 'Inter, sans-serif',
                            fontSize: 14,
                            '&:hover': {
                                backgroundColor: 'inherit',
                                '@media (hover: none)': {
                                    backgroundColor: 'inherit',
                                },
                            },
                        },
                    },
                    MuiPickersBasePicker: {
                        container: {
                            alignItems: 'center',
                        },
                        pickerView: {
                            minHeight: 0,
                            width: '100%',
                            justifyContent: 'flex-start',
                            maxWidth: undefined,
                        },
                    },
                    MuiPickersStaticWrapper: {
                        staticWrapperRoot: {
                            flex: 1,
                            backgroundColor: 'transparent',
                            overflow: 'initial',
                            margin: 0,
                            width: '100%',
                        },
                    },
                    MuiPickersCalendarHeader: {
                        switchHeader: {
                            display: showMonthSelector ? 'flex' : 'none',
                            // color: 'white',
                        },
                        iconButton: {
                            backgroundColor: 'transparent',
                            padding: 8,
                            // color: 'white',
                        },
                        daysHeader: {
                            justifyContent: 'space-between',
                        },
                        dayLabel: {
                            // color: 'white',
                            textTransform: 'uppercase',
                        },
                        transitionContainer: {
                            '& > *': {
                                textTransform: 'capitalize',
                                fontWeight: 500,
                            },
                        },
                    },
                    MuiPickersCalendar: {
                        week: {
                            justifyContent: 'space-between',
                        },
                        transitionContainer: {
                            minHeight: 0,
                        },
                    },
                    MuiPickersSlideTransition: {
                        transitionContainer: {
                            '& > *': {
                                position: 'initial',
                            },
                        },
                    },
                    MuiPickersDay: {
                        day: {
                            color: 'inherit',
                            fontWeight: 'normal',
                            borderRadius: 8,
                            margin: '4px 0',
                            width: 32,
                            height: 32,
                        },
                        daySelected: {
                            backgroundColor: 'inherit',
                            color: 'inherit',
                            fontWeight: 'normal',
                            border: showSelectedDay ? '2px solid white' : 'none',
                            '&:hover': {
                                backgroundColor: 'inherit',
                            },
                        },
                        current: {
                            fontWeight: 700,
                            backgroundColor: 'inherit',
                            color: '#45486E',
                            '&>span:first-of-type': {
                                display: 'flex',
                                flexDirection: 'column',
                            },
                            '&>span:first-of-type::before': {
                                content: '"HOY"',
                                fontSize: 8,
                                marginTop: 3,
                                marginBottom: -3,
                            },
                        },
                    },
                },
            }),
        [showMonthSelector, showSelectedDay],
    );

    return (
        <ThemeProvider theme={materialTheme}>
            <DatePicker
                disableToolbar
                autoOk
                openTo='date'
                variant='static'
                value={fecha}
                onChange={onDateChange}
                onMonthChange={onMonthChange}
                renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
                    const isoDay = formatISODate(day);

                    const className = classesByFecha[isoDay];
                    return <span className={classNames(classes.day, className)}>{dayComponent}</span>;
                }}
            />
        </ThemeProvider>
    );
}

MonthDaySelector.propTypes = {
    fecha: PropTypes.any,
    onDateChange: PropTypes.any,
    onMonthChange: PropTypes.any,
    classesByFecha: PropTypes.any,
    showMonthSelector: PropTypes.bool,
    showSelectedDay: PropTypes.bool,
};
