import { TextField } from 'formik-material-ui';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import SelectField from '../common/fields/SelectField';
import { useEffect, useState } from 'react';
import { equiposProvider } from '../../api';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: `${theme.spacing(4)}px`,
            marginTop: theme.spacing(2),
        },
        column: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
            paddingRight: 16,
        },
    }),
    { name: 'FichaTecnicaForm' },
);

export function FichaTecnicaForm({ style, disabled }) {
    const classes = useStyles();
    const [tiposOptions, setTiposOptions] = useState([]);

    useEffect(() => {
        equiposProvider
            .getTipos()
            .then((tipos) => setTiposOptions(tipos.map((tipo) => ({ label: tipo.nombre, value: tipo.id }))));
    }, []);

    return (
        <div className={classes.root} style={style}>
            <div className={classes.column}>
                <TextField name='referencia' label='Referencia' fullWidth disabled={disabled} />
                <TextField name='marca' label='Marca' fullWidth disabled={disabled} />
                <KeyboardDatePicker
                    name='fecha_fin_garantia'
                    label='Fecha fin de garantía'
                    format='dd/MM/yyyy'
                    autoOk
                    ampm={false}
                    fullWidth
                />
                <TextField
                    name='tipo_refrigerante_carburante'
                    label='Tipo refrigerante/carburante'
                    fullWidth
                    disabled={disabled}
                />
                <Field
                    name='tipo_equipo_id'
                    id='tipo_equipo_id'
                    component={SelectField}
                    label='Tipo del equipo'
                    fullWidth
                    selectOptions={tiposOptions}
                    blankItem='No especificado'
                    disabled={disabled}
                />
            </div>
            <div className={classes.column}>
                <TextField name='nombre' label='Nombre' fullWidth disabled={disabled} />
                <TextField name='modelo' label='Modelo' fullWidth disabled={disabled} />
                <TextField name='numero_serie' label='Número de serie' fullWidth disabled={disabled} />
                <TextField
                    name='potencia'
                    type='number'
                    label='Potencia'
                    fullWidth
                    disabled={disabled}
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>Watios</InputAdornment>,
                    }}
                />
                <TextField name='ubicacion' label='Ubicación' fullWidth disabled={disabled} />
            </div>
        </div>
    );
}

FichaTecnicaForm.propTypes = {
    disabled: PropTypes.any,
    style: PropTypes.any,
};
