import * as Yup from 'yup';
import { useSnackbar } from 'material-ui-snackbar-provider';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { rendimientosProvider } from '../../../api';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import DialogForm from '../../common/forms/DialogForm';
import { withButtonOpener } from '../../../hooks/withButtonOpener';

const RendimientoSchema = Yup.object().shape({
    // nombre: Yup.string().required('Requerido'),
    // email: Yup.string().required('Requerido'),
});

function EditRendimientoDialog({ onSave, ...props }) {
    const snackbar = useSnackbar();

    const {
        data: {
            id,
            // eslint-disable-next-line camelcase
            total_facturado,
            // eslint-disable-next-line camelcase
            total_comercial,
        } = {},
    } = useEditFormData('rendimiento');

    return (
        <DialogForm
            title='Datos de rendimiento'
            maxWidth='xs'
            FormikProps={{
                initialValues: {
                    total_facturado,
                    total_comercial,
                },
                validationSchema: RendimientoSchema,
                onSubmit: (values, { setSubmitting }) => {
                    rendimientosProvider
                        .save(values, id)
                        .then(() => {
                            onSave();
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            console.error(err);
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name='total_facturado'
                            type='number'
                            step='any'
                            label='Total facturado'
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                            }}
                        />
                    </Grid>
                    {/* <Grid item xs={6}> */}
                    {/*    <TextField */}
                    {/*        name='total_comercial' */}
                    {/*        type='number' */}
                    {/*        step='any' */}
                    {/*        label='Total comercial' */}
                    {/*        fullWidth */}
                    {/*        InputProps={{ */}
                    {/*            endAdornment: <InputAdornment position='end'>€</InputAdornment>, */}
                    {/*        }} */}
                    {/*    /> */}
                    {/* </Grid> */}
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(EditRendimientoDialog);

EditRendimientoDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.func,
};
