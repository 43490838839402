import MuiBadge from '@material-ui/core/Badge';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React, { createElement } from 'react';
import { NavLink } from 'react-router-dom';

const MenuItem = withStyles(
    (theme) => ({
        root: {
            color: theme.palette.neutral.grey4,
            padding: theme.spacing(1.5, 2),
            '& svg': {
                fontSize: 20,
                marginRight: theme.spacing(1),
            },
            '&:not(:first-of-type)': {
                border: 'none',
            },
        },
    }),
    { name: 'MenuItem' },
)(MuiMenuItem);

const useStyles = makeStyles(
    (theme) => ({
        paper: {
            borderRadius: 2,
        },
        menu: {
            padding: 0,
        },
    }),
    { name: 'ButtonMenu' },
);

export const MenuBadge = withStyles(
    (theme) => ({
        root: {
            paddingRight: theme.spacing(2.5),
            marginRight: theme.spacing(1.5),
        },
    }),
    { name: 'MenuBadge' },
)(MuiBadge);

export default function ButtonMenu({ button, items, onOpen }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => {
            onOpen && onOpen(!prevOpen);
            return !prevOpen;
        });
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
        onOpen && onOpen(false);
    };

    const menuButton = React.cloneElement(button, {
        onClick: handleToggle,
        ref: anchorRef,
        'aria-controls': open ? 'menu-list-grow' : undefined,
        'aria-haspopup': 'true',
    });

    return (
        <>
            {menuButton}
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement='bottom-start'
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper className={classes.paper}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id='menu-list-grow' className={classes.menu}>
                                    {items.map(
                                        (
                                            {
                                                path,
                                                action,
                                                icon,
                                                label,
                                                ButtonComponent,
                                                ButtonComponentProps,
                                                divider = false,
                                            },
                                            i,
                                        ) => {
                                            if (divider) return <Divider key={i} />;

                                            const button = createElement(
                                                MenuItem,
                                                {
                                                    component: path ? NavLink : 'div',
                                                    to: path,
                                                    key: i,
                                                    onClick: (ev) => {
                                                        if (action) action(ev);

                                                        handleClose(ev);
                                                    },
                                                },
                                                [icon, label],
                                            );

                                            if (ButtonComponent) {
                                                return (
                                                    <ButtonComponent
                                                        key={i}
                                                        button={button}
                                                        {...ButtonComponentProps}
                                                    />
                                                );
                                            }

                                            return button;
                                        },
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}

ButtonMenu.propTypes = {
    button: PropTypes.any,
    items: PropTypes.any,
    onOpen: PropTypes.any,
};
