import { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import DialogEditor from '../../common/forms/DialogEditor';
import PropTypes from 'prop-types';
import { withButtonOpener } from '../../../hooks/withButtonOpener';

function AddLabelListItemDialog({ onSave, title, label, placeholder, ...props }) {
    const [nombre, setNombre] = useState('');

    useEffect(() => {
        setNombre('');
    }, [props.open]);

    return (
        <DialogEditor
            title={title}
            onSave={() => {
                onSave(nombre);
                props.onClose();
            }}
            {...props}
        >
            <TextField
                label={label}
                value={nombre}
                onChange={(ev) => setNombre(ev.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                placeholder={placeholder}
            />
        </DialogEditor>
    );
}

AddLabelListItemDialog.propTypes = {
    label: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
    placeholder: PropTypes.any,
    title: PropTypes.any,
};

export default withButtonOpener(AddLabelListItemDialog);
