import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import { IconButton } from '@material-ui/core';
import API_ROOT from '../../../api/api-config';
import DownloadIcon from '../../icons/Download';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiTableCell from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';

const TableCell = withStyles(
    (theme) => ({
        root: {
            borderBottomColor: theme.palette.neutral.grey1,
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

const useStyles = makeStyles(
    (theme) => ({
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '50px',
            paddingLeft: theme.spacing(2),
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
            '& .MuiIconButton-root': {
                marginLeft: 'auto',
                marginRight: theme.spacing(1),
            },
        },
        empty: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            margin: theme.spacing(6, 'auto'),
            gap: `${theme.spacing(3)}px`,
            // width: 430,
        },
        emptyText: {
            color: theme.palette.neutral.grey4,
        },
    }),
    { name: 'FacturasList' },
);

export default function FacturasList() {
    const classes = useStyles();
    const { data: pagos } = useEditFormData('pagos');

    return (
        <>
            {pagos.length > 0 ? (
                <Paper elevation={0}>
                    <Typography className={classes.title}>Histórico de facturas</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Número</TableCell>
                                        <TableCell>Fecha</TableCell>
                                        <TableCell>Periodo facturado</TableCell>
                                        <TableCell>Importe</TableCell>
                                        <TableCell>Acciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pagos.map((pago) => (
                                        <TableRow key={pago.id}>
                                            <TableCell>{pago.numero}</TableCell>
                                            <TableCell>{pago.fecha}</TableCell>
                                            <TableCell>
                                                Del {pago.fecha_inicio} al {pago.fecha_fin}
                                            </TableCell>
                                            <TableCell>{pago.importe} EUR</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    size='small'
                                                    onClick={() =>
                                                        window.open(`${API_ROOT}/companies/factura/${pago.id}/pdf`)
                                                    }
                                                >
                                                    <DownloadIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Paper>
            ) : (
                <Paper elevation={0}>
                    <div className={classes.empty}>
                        <img src='/images/empty-partes-trabajo.png' />
                        <Typography variant='h1'>Histórico de facturas</Typography>
                        <Typography variant='body2' className={classes.emptyText}>
                            Aquí podrás ver tu historial de facturas de Labory.
                        </Typography>
                    </div>
                </Paper>
            )}
        </>
    );
}
