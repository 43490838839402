import AddIcon from '@material-ui/icons/Add';
import differenceInDays from 'date-fns/differenceInDays';
import isPast from 'date-fns/isPast';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { instalacionesProvider } from '../../api';
import { dataProvider } from '../../api/contratos_mantenimiento';
import useAuthState from '../../AuthState';
import { useFetchData } from '../../hooks/useFetchData';
import { formatDate } from '../../utils';
import Button from '../common/Button';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import { DateColumnFilter, dateFilterFn } from '../common/list/DateColumnFilter';
import BaseListView, { withTableState } from '../common/list/ListView';
import { SelectColumnFilter } from '../common/list/SelectColumnFilter';
import EstadoChip, { A_PUNTO_VENCER, estadoOptions, VENCIDO, VIGENTE } from '../contratos_mantenimiento/EstadoChip';
import NewContratoMantenimientoDialog from '../contratos_mantenimiento/NewContratoMantenimientoDialog';

const ListView = withTableState('instalaciones', BaseListView);

export default function ContratosMantenimientoList() {
    const { id } = useParams();
    const { data: instalacion } = useEditFormData();

    const history = useHistory();
    const fetchDataFn = useCallback(
        () =>
            instalacionesProvider
                .getContratosMantenimiento(id)
                .then((data) => data.map((row) => ({ ...row, actions: { edit: true } }))),
        [id],
    );
    const { data, fetchData } = useFetchData(fetchDataFn);

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        dataProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const {
        userInfo: { preferencias: { dias_margen_renovacion_contrato_mantenimiento: diasMargen } = {} },
    } = useAuthState();

    const columns = useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
            {
                Header: 'Fecha inicio',
                accessor: 'fecha_inicio',
                Cell: ({ value }) => formatDate(new Date(value)),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Fecha fin',
                accessor: 'fecha_fin',
                Cell: ({ value }) => formatDate(new Date(value)),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Número de revisiones',
                accessor: 'num_servicios',
            },
            {
                Header: 'Número de equipos',
                accessor: 'num_equipos',
            },
            {
                Header: 'Estado',
                id: 'estado',
                accessor: (row) => {
                    const fechaFin = new Date(row.fecha_fin);

                    if (isPast(fechaFin)) return VENCIDO;
                    if (differenceInDays(fechaFin, new Date()) < diasMargen) return A_PUNTO_VENCER;
                    return VIGENTE;
                },
                Cell: EstadoChip,
                Filter: SelectColumnFilter,
                filter: 'equals',
                filterOptions: {
                    options: estadoOptions,
                    includeAllOption: {
                        value: 'todos',
                        label: 'Todos',
                    },
                },
                exportValue: ({ value }) => estadoOptions[value],
            },
        ],
        [diasMargen],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            deleteConfirmationText: 'borrar contrato',
            batchComponents: (
                <NewContratoMantenimientoDialog
                    clienteId={instalacion.cliente_id}
                    direccionId={instalacion.direccion_id}
                    instalacionId={instalacion.id}
                    button={
                        <Button color='info' aria-label='Nuevo contrato de mantenimiento' startIcon={<AddIcon />}>
                            Nuevo contrato de mantenimiento
                        </Button>
                    }
                    onSave={({ id }) => history.push(`/contratos-mantenimiento/${id}`)}
                />
            ),
        }),
        [],
    );

    return (
        <ListView
            tableTitle='Lista de contratos de mantenimiento'
            basePath='/contratos-mantenimiento'
            columns={columns}
            data={data}
            options={options}
            onDelete={onDelete}
        />
    );
}
