import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import EditUsuarioDialog from '../EditUsuarioDialog';
import Button from '../../../common/Button';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PropTypes from 'prop-types';

export default function AjustesDatosAcceso({ classes, onSave, usuario }) {
    return (
        <Paper elevation={0}>
            <Typography className={classes.title}>Datos de acceso</Typography>
            <Grid container spacing={4} className={classes.content}>
                <Grid item xs={12}>
                    <EditUsuarioDialog
                        usuario={usuario}
                        onSave={onSave}
                        button={
                            <Button color='info' startIcon={<VpnKeyIcon />}>
                                Editar datos de acceso
                            </Button>
                        }
                    />
                </Grid>
            </Grid>
        </Paper>
    );
}

AjustesDatosAcceso.propTypes = {
    classes: PropTypes.any,
    onSave: PropTypes.any,
    usuario: PropTypes.any,
};
