import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import EditFormHeader from '../common/forms/EditForm/EditFormHeader';
import EditFormHeaderItem from '../common/forms/EditForm/EditFormHeaderItem';
import { EditProveedorMenuOptions } from './EditProveedorMenuOptions';
import EditProveedorDialog from './EditProveedorDialog';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import EditFormHeaderItemList from '../common/forms/EditForm/EditFormHeaderItemList';

export function EditProveedorFormContent() {
    const { data, fetchData } = useEditFormData();

    return (
        <>
            <EditFormHeader
                label='Proveedor'
                name={data.nombre}
                actions={
                    <>
                        <EditProveedorDialog
                            button={
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            }
                            onSave={fetchData}
                            proveedor={data}
                        />
                        <EditProveedorMenuOptions />
                    </>
                }
            >
                <EditFormHeaderItemList>
                    <EditFormHeaderItem label='Codigo'>{data.codigo}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Activo'>{data.activo ? 'Si' : 'No'}</EditFormHeaderItem>
                </EditFormHeaderItemList>
            </EditFormHeader>
        </>
    );
}
