import { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonBase, makeStyles, Typography } from '@material-ui/core';

const useCircleStyles = makeStyles(
    (theme) => ({
        root: {
            width: 32,
            height: 32,
            borderRadius: '50%',
            background: 'rgba(0, 0, 0, 0)',
            // marginLeft: theme.spacing(1.5)
        },
        rootText: {
            color: theme.palette.text.primary,
        },
        rootTextDisabled: {
            color: theme.palette.text.disabled,
        },
        todayRoot: {
            background: theme.palette.background.default,
        },
        plannedRoot: {
            background: theme.palette.primary.main,
        },
        selectedRoot: {
            border: `2px solid ${theme.palette.primary.main}`,
        },
        noLaborableRoot: {
            background: `${theme.palette.error.main}33`,
        },
        checkedRootDisabled: {
            background: theme.palette.action.disabled,
        },
        plannedText: {
            color: theme.palette.common.white,
        },
        text: {
            textAlign: 'center',
            fontSize: 13,
        },
    }),
    { name: 'Day' },
);

const Day = ({ label, dayProps, disabled, checked, onCheck, className, isToday }) => {
    const classes = useCircleStyles();

    const handleClick = useCallback(() => {
        if (!disabled) {
            onCheck(!checked);
        }
    }, [onCheck, disabled, checked]);

    return (
        <ButtonBase
            className={classNames(
                classes.root,
                {
                    [classes.rootText]: !checked && !disabled,
                    [classes.rootTextDisabled]: disabled,
                    [classes.todayRoot]: isToday,
                    [classes.plannedRoot]: dayProps.planned && !disabled,
                    [classes.selectedRoot]: dayProps.selected && !disabled,
                    [classes.noLaborableRoot]: dayProps.noLaborable && !dayProps.planned,
                    [classes.checkedRootDisabled]: checked && disabled,
                },
                className,
            )}
            disabled={disabled}
            onClick={handleClick}
        >
            <Typography
                color='inherit'
                variant='body1'
                className={classNames(classes.text, {
                    [classes.plannedText]: dayProps.planned,
                })}
            >
                {label}
            </Typography>
        </ButtonBase>
    );
};

Day.propTypes = {
    checked: PropTypes.bool.isRequired,
    className: PropTypes.string,
    classes: PropTypes.object,
    dayProps: PropTypes.object,
    disabled: PropTypes.bool,
    isToday: PropTypes.any,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onCheck: PropTypes.func.isRequired,
};

export default Day;
