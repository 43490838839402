import withStyles from '@material-ui/core/styles/withStyles';
import MuiTab from '@material-ui/core/Tab';
import MuiTabs from '@material-ui/core/Tabs';

export const Tabs = withStyles(
    (theme) => ({
        root: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            minHeight: 24,
        },
        flexContainer: {
            gap: (props) => (props.disableGutters ? '0px' : '32px'),
        },
    }),
    { name: 'Tabs' },
)(({ disableGutters, ...props }) => <MuiTabs indicatorColor='primary' {...props} />);

export const Tab = withStyles(
    (theme) => ({
        root: {
            fontSize: '1rem',
            textTransform: 'none',
            minWidth: 80,
            fontWeight: theme.typography.fontWeightMedium,
            // marginRight: theme.spacing(4),
            color: theme.palette.neutral.primary,
            padding: 0,
            minHeight: 24,
            '&:hover': {},
            '&$selected': {
                color: theme.palette.text.primary,
            },
            '&:focus': {},
        },
        wrapper: {
            lineHeight: '24px',
        },
        selected: {},
    }),
    { name: 'Tab' },
)((props) => <MuiTab disableRipple {...props} />);
