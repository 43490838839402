import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { presupuestosProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import { formatDate } from '../../../utils';
import DialogEditor from '../../common/forms/DialogEditor';
import { TableCell, TableRow } from '../../presupuestos/PresupuestoView/Table';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 800,
        },
        body: {
            padding: 0,
        },
        noDownward: {
            marginLeft: 32,
        },
        observacionesCell: {
            width: '100%',
            whiteSpace: 'pre-wrap',
        },
    }),
    { name: 'PresupuestoLineaAvancesDialog' },
);

function PresupuestoLineaAvancesDialog({ avanceId, concepto, open, onSave, ...props }) {
    const classes = useStyles();
    const [avances, setAvances] = useState([]);
    const snackbar = useSnackbar();

    useEffect(() => {
        if (!open) return;

        setAvances([]);
        presupuestosProvider
            .getHistoricoObservacionesAvance(avanceId)
            .then(setAvances)
            .catch((err) => {
                snackbar.showMessage(err.message);
            });
    }, [open]);

    return (
        <DialogEditor
            title={`Observaciones de la linea: ${concepto}`}
            maxWidth='md'
            classes={{
                root: classes.root,
                body: classes.body,
            }}
            open={open}
            {...props}
        >
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Observaciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {avances.map((avance, index) => (
                        <TableRow key={index}>
                            <TableCell>{formatDate(avance.fecha_creacion)}</TableCell>
                            <TableCell className={classes.observacionesCell}>{avance.observaciones}</TableCell>
                        </TableRow>
                    ))}
                    {avances.length === 0 && (
                        <TableRow>
                            <TableCell colSpan={2}>No hay observaciones</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </DialogEditor>
    );
}

PresupuestoLineaAvancesDialog.propTypes = {
    avanceId: PropTypes.any,
    concepto: PropTypes.any,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.any,
    open: PropTypes.any,
};

export default withButtonOpener(PresupuestoLineaAvancesDialog);
