import { useCallback, useEffect, useMemo } from 'react';
import BaseListView, { withTableState } from '../common/list/ListView';
import { dataProvider } from '../../api/rendimientos';
import { useFetchData } from '../../hooks/useFetchData';
import { tipoLabels } from '../../api/tareas';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';

const ListView = withTableState('rendimientos', BaseListView);

export default function RendimientoServiciosList() {
    const fetchDataFn = useCallback(() => dataProvider.getAll(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);
    const currency = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' });
    const history = useHistory();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const columns = useMemo(
        () => [
            {
                Header: 'Referencia',
                id: 'numero',
                accessor: (row) => (row.numero ? row.numero.toString().padStart(4, '0') : 'N/D'),
            },
            {
                Header: 'Descripcion',
                accessor: 'descripcion',
            },
            {
                Header: 'Cliente',
                accessor: 'cliente',
            },
            {
                Header: 'Tipo',
                id: 'tipo',
                accessor: (row) => tipoLabels[row.tipo],
            },
            {
                Header: 'Origen',
                accessor: 'origen',
            },
            {
                Header: 'Total coste',
                accessor: 'total_coste',
                Cell: ({ value }) => currency.format(value),
            },
            {
                Header: 'Total venta',
                accessor: 'total_venta',
                Cell: ({ value }) => currency.format(value),
            },
            {
                Header: 'Total facturado',
                accessor: 'total_facturado',
                Cell: ({ value }) => currency.format(value),
            },
            {
                Header: 'Beneficio estimado',
                id: 'beneficio_estimado',
                accessor: (row) => row.total_venta - row.total_coste,
                Cell: ({ value }) => currency.format(value),
                getExtraCellProps: ({ value }) => ({
                    style: {
                        color: value < 0 ? 'red' : value > 0 ? 'green' : 'initial',
                    },
                }),
            },
            {
                Header: 'Beneficio facturado',
                id: 'beneficio_facturado',
                accessor: (row) => row.total_facturado - row.total_coste,
                Cell: ({ value }) => currency.format(value),
                getExtraCellProps: ({ value }) => ({
                    style: {
                        color: value < 0 ? 'red' : value > 0 ? 'green' : 'initial',
                    },
                }),
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            hideDelete: true,
        }),
        [],
    );

    return (
        <>
            <Alert severity='info' variant='filled' style={{ marginBottom: 24 }}>
                Esta funcionalidad esta en fase beta. Puede ser que haya cambios importantes. ¿Dudas o sugerencias?
                Envianos un email a hola@labory.es
            </Alert>
            <ListView
                title='Rendimiento de servicios'
                tableTitle='Lista de servicios'
                basePath='/rendimientos'
                columns={columns}
                data={data}
                options={options}
                extraActions={(row) => (
                    <Tooltip title='Ver'>
                        <IconButton onClick={() => history.push(`/servicios/${row.tarea_id}/rendimiento`)}>
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                )}
            />
        </>
    );
}
