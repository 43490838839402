import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { proveedoresProvider } from '../../api';
import DialogForm from '../common/forms/DialogForm';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import PropTypes from 'prop-types';

const Proveedoreschema = Yup.object().shape({
    codigo: Yup.string().required('Requerido'),
    nombre: Yup.string().required('Requerido'),
});

function NewProveedorDialog({ onSave, ...props }) {
    return (
        <DialogForm
            title='Añadir proveedor'
            FormikProps={{
                initialValues: {
                    codigo: '',
                    nombre: '',
                },
                validationSchema: Proveedoreschema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    proveedoresProvider
                        .save(values)
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField name='codigo' label='Código' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='nombre' label='Nombre' fullWidth />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(NewProveedorDialog);

NewProveedorDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
