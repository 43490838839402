import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import ItemLabel from '../../common/forms/MasterDetailsViewOld/ItemLabel';

const useStyles = makeStyles(
    (theme) => ({
        title: {
            height: 50,
            fontWeight: theme.typography.fontWeightMedium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            paddingLeft: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(2)}px`,
            '& .MuiTypography-root': {
                flex: 1,
            },
            '& .MuiIconButton-root': {
                marginRight: theme.spacing(2),
            },
        },
        infoBlock: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2),
            borderBottom: `1px solid ${theme.palette.divider}`,
            '& strong': {
                fontWeight: theme.typography.fontWeightMedium,
            },
            '& span': {
                color: theme.palette.neutral.primary,
            },
        },
    }),
    { name: 'InfoBlockPaper' },
);

export default function InfoBlockPaper({ title, editComponent, hasAllData, children }) {
    const classes = useStyles();

    return (
        <Paper elevation={0}>
            <div className={classes.title}>
                <Typography>{title}</Typography>

                <ItemLabel
                    color={hasAllData ? 'success' : 'error'}
                    label={hasAllData ? 'Completado' : 'Faltan datos'}
                    icon={hasAllData ? <CheckCircleIcon /> : <InfoIcon />}
                />
                {editComponent}
            </div>
            <div className={classes.infoBlock}>{children}</div>
        </Paper>
    );
}

InfoBlockPaper.propTypes = {
    children: PropTypes.any,
    editComponent: PropTypes.any,
    hasAllData: PropTypes.any,
    title: PropTypes.any,
};
