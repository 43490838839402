import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { formatFullDateTime } from '../../../../utils';
import { openSearchInMaps } from '../../../clientes/direcciones/NewDireccionDialog';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            padding: theme.spacing(1.5),
            gap: `${theme.spacing(1.5)}px`,
            '&>svg': {
                fontSize: 22,
            },
            border: '1px solid transparent',
        },
        active: {
            borderColor: theme.palette.primary.main,
        },
        header: {
            color: theme.palette.neutral.primary,
            lineHeight: '35px',
        },
        title: {
            display: 'flex',
            alignItems: 'flex-start',
            gap: `${theme.spacing(1.5)}px`,
            '&>.MuiTypography-root': {
                flex: 1,
                color: theme.palette.text.primary,
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        subtitle: {
            display: 'flex',
            alignItems: 'center',
        },
        details: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            color: theme.palette.neutral.primary,
        },
        actions: {
            display: 'flex',
            alignItems: 'center',
            marginTop: -theme.spacing(0.5),
            marginRight: -theme.spacing(0.5),
            marginBottom: 'auto',
            gap: `${theme.spacing(0.5)}px`,
            '& .MuiIconButton-root': {
                padding: theme.spacing(0.5),
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        menuPaper: {
            borderRadius: 2,
        },
    }),
    { name: 'MasterItem' },
);

export default function MasterItem({
    icon: Icon,
    title,
    subtitle,
    lastUpdate,
    lastUpdateLocation,
    header,
    isSelected,
    navigate = () => {},
    actions,
    labels,
    error,
}) {
    const classes = useStyles();

    // get first 2 numbers from the comma separated value in lastupdatelocation
    const updateGpsLocation = lastUpdateLocation?.split(',').slice(0, 2).join(',');

    return (
        <div>
            {header && (
                <Typography variant='subtitle1' className={classes.header}>
                    {header}
                </Typography>
            )}
            <Paper elevation={0} className={clsx(classes.root, { [classes.active]: isSelected })} onClick={navigate}>
                {Icon && <Icon color='primary' />}
                <div className={classes.details}>
                    <div className={classes.title}>
                        <Typography variant='body2'>{title}</Typography>
                        {labels}
                    </div>
                    {subtitle && (
                        <Typography variant='subtitle1' component='p' className={classes.subtitle}>
                            {subtitle}
                        </Typography>
                    )}
                    {lastUpdate && (
                        <Typography variant='subtitle1' component='p'>
                            Última actualización {formatFullDateTime(lastUpdate)}{' '}
                            {updateGpsLocation ? (
                                <Tooltip title='Abrir en Google Maps'>
                                    <IconButton size='small' onClick={() => openSearchInMaps(updateGpsLocation)}>
                                        <LocationOnIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                ''
                            )}
                        </Typography>
                    )}
                    {error && (
                        <Typography variant='subtitle1' component='p' color='error'>
                            {error}
                        </Typography>
                    )}
                </div>
                <div className={classes.actions}>{actions}</div>
            </Paper>
        </div>
    );
}

MasterItem.propTypes = {
    icon: PropTypes.any,
    actions: PropTypes.any,
    error: PropTypes.any,
    header: PropTypes.any,
    isSelected: PropTypes.any,
    labels: PropTypes.any,
    lastUpdate: PropTypes.any,
    lastUpdateLocation: PropTypes.any,
    navigate: PropTypes.any,
    subtitle: PropTypes.any,
    title: PropTypes.any,
};
