import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import addDays from 'date-fns/addDays';
import Button from '../../common/Button';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterDetailsView from '../../common/forms/MasterDetailsView/MasterDetailsView';
import ChecklistDetails from './ChecklistDetails/ChecklistDetails';
import MultiChecklistDetails from './ChecklistDetails/MultiChecklistDetails';
import ChecklistItem from './ChecklistItem';
import EditChecklistDialog from './EditChecklistDialog';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: `${theme.spacing(3)}px`,
            '&>div': {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: `${theme.spacing(2)}px`,
            },
        },
        actions: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        empty: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            margin: theme.spacing(6, 'auto'),
            gap: `${theme.spacing(3)}px`,
            width: 430,
        },
        emptyText: {
            color: theme.palette.neutral.grey4,
        },
    }),
    { name: 'ChecklistsList' },
);

export const CHECKLIST_RESUMEN_KEY = 'resumen';

export default function ChecklistsList() {
    const { data: checklists, fetchData } = useEditFormData('checklists_tarea');
    const classes = useStyles();

    const items = [];

    if (checklists.length > 1)
        items.push({
            id: CHECKLIST_RESUMEN_KEY,
        });

    items.push(...checklists);

    const fechaInicio =
        checklists.length > 0 ? addDays(new Date(checklists[checklists.length - 1].fecha_inicio), 1) : new Date();

    return (
        <MasterDetailsView
            title='Tareas'
            masterComponent={ChecklistItem}
            detailsComponent={checklists.length > 1 ? MultiChecklistDetails : ChecklistDetails}
            items={items}
            isEmpty={checklists.length === 0}
            emptyComponent={
                <Paper elevation={0}>
                    <div className={classes.empty}>
                        <img src='/images/empty-partes-trabajo.png' />
                        <Typography variant='h1'>Listas de tareas</Typography>
                        <Typography variant='body2' className={classes.emptyText}>
                            Si configuras listas de tareas en el servicio, esta sección te mostrará el tiempo que tus
                            operarios registren en cada tarea. También podrás ver qué tareas están completadas y cuándo.
                        </Typography>

                        <EditChecklistDialog
                            button={<Button color='info'>Añadir lista de tareas</Button>}
                            onSave={fetchData}
                        />
                    </div>
                </Paper>
            }
            actions={
                <EditChecklistDialog
                    button={
                        <IconButton>
                            <AddIcon />
                        </IconButton>
                    }
                    onSave={fetchData}
                    defaultFechaInicio={fechaInicio}
                />
            }
        />
    );
}
