import MuiListSubheader from '@material-ui/core/ListSubheader';
import Menu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DoneIcon from '@material-ui/icons/Done';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import React from 'react';
import { COLOR_SOURCE_CATEGORIA, COLOR_SOURCE_TAREA } from '../agenda/useTransformEvents';
import Button from '../common/Button';
import { ToggleButton, ToggleButtonGroup } from '../common/ToggleButtonGroup';
import CalendarioMonthIcon from '../icons/CalendarioMonth';
import CalendarioWeekIcon from '../icons/CalendarioWeek';
import NuevaTareaDialog from '../tareas/NuevaTareaDialog';
import { MONTH_VIEW, WEEK_VIEW } from './CalendarioPlanificacion';

const ListSubheader = withStyles(
    (theme) => ({
        root: {
            borderTop: '1px solid #eeeeee',
            lineHeight: '32px',
        },
    }),
    { name: 'ListSubheader' },
)(MuiListSubheader);

const MenuItem = withStyles(
    (theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: 200,
            gap: `${theme.spacing(1)}px`,
            '& .MuiSvgIcon-root': {
                color: theme.palette.success.main,
            },
        },
    }),
    { name: 'MenuItem' },
)(MuiMenuItem);

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(3)}px`,
            marginBottom: theme.spacing(3),
            '& .MuiTypography-root': {
                whiteSpace: 'nowrap',
            },
        },
        navigation: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            '& .MuiButton-root': {
                width: 32,
                minWidth: 0,
            },
        },
        navigationButton: {
            height: 32,
            padding: 0,
            backgroundColor: 'white',
        },
        fecha: {
            fontWeight: theme.typography.fontWeightMedium,
            textTransform: 'capitalize',
        },
        buttons: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            marginLeft: 'auto',
            '& .MuiButton-root$navigationButton': {
                width: 32,
                minWidth: 0,
            },
        },
    }),
    { name: 'Toolbar' },
);

export const navigate = {
    PREVIOUS: 'previous',
    NEXT: 'next',
    TODAY: 'today',
};

function Toolbar({
    onNavigate,
    label,
    onCreate,
    onView,
    views,
    view,
    showPendientesPlanificar,
    onShowPendientesPlanificar,
    colorSource,
    onColorSource,
    dateView,
    onDateView,
}) {
    const classes = useStyles();
    function onChangeView(event, newView) {
        if (newView === null) return;
        onView(newView);
    }
    function onChangeDateView(event, newView) {
        if (newView === null) return;
        onDateView(newView);
    }

    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

    const openMenu = (event) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
    };

    const closeMenu = (event) => {
        event && event.stopPropagation();
        setMenuAnchorEl(null);
    };

    return (
        <div className={classes.root}>
            {/* <Typography variant='h1'>Calendario de planificación</Typography> */}

            <div className={classes.navigation}>
                <Button
                    color='outlined'
                    onClick={() => onNavigate(navigate.PREVIOUS)}
                    className={classes.navigationButton}
                >
                    <ChevronLeftIcon />
                </Button>
                <Button color='outlined' onClick={() => onNavigate(navigate.NEXT)} className={classes.navigationButton}>
                    <ChevronRightIcon />
                </Button>
            </div>

            <Typography variant='h2' className={classes.fecha}>
                {label}
            </Typography>

            <Button color='outlined' onClick={() => onNavigate(navigate.TODAY)} className={classes.navigationButton}>
                Hoy
            </Button>

            <ToggleButtonGroup value={view} exclusive onChange={onChangeView}>
                {views.map(({ id, label, tooltip }) => (
                    <ToggleButton key={id} value={id} TooltipProps={{ title: tooltip ?? '', arrow: true }}>
                        {label}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>

            <ToggleButtonGroup value={dateView} exclusive onChange={onChangeDateView}>
                <ToggleButton value={WEEK_VIEW} TooltipProps={{ title: 'Vista semanal', arrow: true }}>
                    <CalendarioWeekIcon />
                </ToggleButton>
                <ToggleButton value={MONTH_VIEW} TooltipProps={{ title: 'Vista mensual', arrow: true }}>
                    <CalendarioMonthIcon />
                </ToggleButton>
            </ToggleButtonGroup>

            <div className={classes.buttons}>
                <Button color='outlined' onClick={openMenu} className={classes.navigationButton}>
                    <MoreVertIcon />
                </Button>
                <Button
                    color='outlined'
                    aria-label={
                        showPendientesPlanificar
                            ? 'Ocultar pendientes de planificar'
                            : 'Mostrar pendientes de planificar'
                    }
                    className={classes.addServicioButton}
                    onClick={onShowPendientesPlanificar}
                >
                    {showPendientesPlanificar ? 'Ocultar pendientes de planificar' : 'Mostrar pendientes de planificar'}
                </Button>
                <NuevaTareaDialog
                    button={
                        <Button color='info' aria-label='Añadir servicio' startIcon={<AddIcon />}>
                            Añadir servicio
                        </Button>
                    }
                    onSave={onCreate}
                />
                <Menu
                    id='simple-menu'
                    anchorEl={menuAnchorEl}
                    keepMounted
                    open={Boolean(menuAnchorEl)}
                    onClose={closeMenu}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <ListSubheader>Origen del color</ListSubheader>
                    <MenuItem
                        onClick={(ev) => {
                            closeMenu(ev);
                            onColorSource(COLOR_SOURCE_TAREA);
                        }}
                    >
                        Del servicio {colorSource === COLOR_SOURCE_TAREA && <DoneIcon fontSize='small' />}
                    </MenuItem>
                    <MenuItem
                        onClick={(ev) => {
                            closeMenu(ev);
                            onColorSource(COLOR_SOURCE_CATEGORIA);
                        }}
                    >
                        De la categoría {colorSource === COLOR_SOURCE_CATEGORIA && <DoneIcon fontSize='small' />}
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
}

Toolbar.propTypes = {
    label: PropTypes.any,
    onCreate: PropTypes.any,
    onNavigate: PropTypes.func.isRequired,
    onView: PropTypes.any,
    view: PropTypes.any,
    views: PropTypes.array,
    showPendientesPlanificar: PropTypes.any,
    onShowPendientesPlanificar: PropTypes.any,
    colorSource: PropTypes.any,
    onColorSource: PropTypes.any,
    dateView: PropTypes.any,
    onDateView: PropTypes.any,
};

export default Toolbar;
