import { useCallback, useMemo, useState } from 'react';
import BaseOperariosCell from '../common/OperariosCell';
import { operariosFilterFn as baseOperariosFilterFn } from '../../utils';
import OperariosColumnFilter from './ListaServicios/OperariosColumnFilter';

export default function useOperariosTableColumn() {
    const [hideOperariosExtra, setHideOperariosExtra] = useState(false);

    const OperariosCell = useCallback(
        (props) => <BaseOperariosCell hideOperariosExtra={hideOperariosExtra} {...props} />,
        [hideOperariosExtra],
    );

    const operariosFilterFn = useCallback(
        (rows, id, filterValue) => baseOperariosFilterFn(rows, id, filterValue, hideOperariosExtra),
        [hideOperariosExtra],
    );

    return useMemo(
        () => ({
            Header: 'Operarios',
            filter: operariosFilterFn,
            accessor: 'operarios',
            Cell: OperariosCell,
            Filter: OperariosColumnFilter,
            disableSortBy: true,
            filterOptions: {
                hideOperariosExtra,
                setHideOperariosExtra,
            },
            exportValue: ({ value }) => value.map((op) => op.nombre).join(', '),
        }),
        [operariosFilterFn, OperariosCell, hideOperariosExtra],
    );
}
