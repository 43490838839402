import { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import Popover from '@material-ui/core/Popover';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1, 0),
    },
    item: {
        padding: theme.spacing(0.5, 1),
    },
}));

export function ColumnToggler({ columns }) {
    const [toggleColumnsEl, setToggleColumnsEl] = useState(null);
    const classes = useStyles();

    return (
        <>
            <Tooltip title={'Mostrar columnas'}>
                <IconButton aria-label='Mostrar columnas' onClick={(ev) => setToggleColumnsEl(ev.currentTarget)}>
                    <ViewColumnIcon />
                </IconButton>
            </Tooltip>
            <Popover
                id='simple-popper'
                open={Boolean(toggleColumnsEl)}
                anchorEl={toggleColumnsEl}
                onClose={() => setToggleColumnsEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                style={{
                    padding: '16px 24px',
                }}
            >
                <div className={classes.root}>
                    {columns.map((column) => (
                        <div key={column.id} className={classes.item}>
                            <label>
                                <input type='checkbox' {...column.getToggleHiddenProps()} /> {column.Header}
                            </label>
                        </div>
                    ))}
                </div>
            </Popover>
        </>
    );
}

ColumnToggler.propTypes = {
    columns: PropTypes.any,
};
