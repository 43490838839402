import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { formatDate, formatDateTime, isAllDay } from '../../utils';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
        },
        label: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(0.5)}px`,
            '& svg': {
                fontSize: 16,
            },
        },
        infoLabel: {
            backgroundColor: ({ hasPlanificaciones }) =>
                hasPlanificaciones ? theme.palette.neutral.grey4 : theme.palette.success.main,
            color: 'white',
            padding: theme.spacing(0, 1, 0, 0.5),
            borderRadius: 12,
        },
        tooltip: {
            backgroundColor: theme.palette.neutral.hudBackground,
            opacity: 1,
            minWidth: 420,
            padding: 0,
            maxHeight: 600,
            overflowY: 'auto',
        },
        tooltipArrow: {
            color: theme.palette.neutral.hudBackground,
        },
        header: {
            fontWeight: theme.typography.fontWeightBold,
            padding: theme.spacing(0.5, 1),
        },
        button: {
            color: 'white',
        },
        info: {
            padding: theme.spacing(0.5, 1),
            display: 'flex',
            flexDirection: 'column',
            borderTop: `1px solid ${theme.palette.neutral.hudBackgroundDivider}`,
        },
    }),
    { name: 'PlanificacionFormItemSelectOption' },
);

export default function PlanificacionFormItemSelectOption({ label, planificaciones }) {
    const classes = useStyles({ hasPlanificaciones: planificaciones.length > 0 });

    return (
        <div className={classes.root}>
            <Typography className={classes.label}>{label}</Typography>

            {planificaciones.length > 0 ? (
                <Tooltip
                    arrow
                    interactive
                    classes={{
                        tooltip: classes.tooltip,
                        arrow: classes.tooltipArrow,
                    }}
                    title={
                        <>
                            <Typography variant='subtitle1' className={classes.header}>
                                Eventos planificados en las mismas fechas
                            </Typography>
                            {planificaciones.map((planificacion, i) => {
                                const formatFn = isAllDay(planificacion.fecha_inicio, planificacion.fecha_fin)
                                    ? formatDate
                                    : formatDateTime;

                                let operarios = null;
                                if (planificacion.operarios !== undefined) {
                                    operarios =
                                        planificacion.operarios !== null
                                            ? planificacion.operarios.split(';').join(', ')
                                            : 'Sin operarios';
                                }

                                return (
                                    <div key={i} className={classes.info}>
                                        {planificacion.cliente && (
                                            <Typography variant='subtitle2'>{planificacion.cliente}</Typography>
                                        )}
                                        <Typography variant='subtitle2'>{planificacion.descripcion}</Typography>
                                        <Typography variant='subtitle2'>{`Del ${formatFn(
                                            planificacion.fecha_inicio,
                                        )} al ${formatFn(planificacion.fecha_fin)}`}</Typography>
                                        {operarios && <Typography variant='subtitle2'>{operarios}</Typography>}
                                        {planificacion.vehiculo && (
                                            <Typography variant='subtitle2'>{planificacion.vehiculo.nombre}</Typography>
                                        )}
                                    </div>
                                );
                            })}
                        </>
                    }
                >
                    <Typography variant='subtitle1' className={clsx(classes.label, classes.infoLabel)}>
                        <InfoIcon /> {planificaciones.length} eventos
                    </Typography>
                </Tooltip>
            ) : (
                <Typography variant='subtitle1' className={clsx(classes.label, classes.infoLabel)}>
                    <CheckCircleIcon /> Libre
                </Typography>
            )}
        </div>
    );
}

PlanificacionFormItemSelectOption.propTypes = {
    label: PropTypes.any,
    planificaciones: PropTypes.any,
};
