import { Paper } from '@material-ui/core';
import MuiCheckbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { albaranesCompraProvider, preparacionesMaterialProvider } from '../../../../api';
import Button from '../../../common/Button';
import DeleteDialog from '../../../common/dialogs/DeleteDialog';
import { useEditFormData } from '../../../common/forms/EditForm/EditFormContext';
import AlbaranesCompraInfoTooltip from './AlbaranesCompraInfoTooltip';
import LineaEditor from './LineaEditor';
import ToAlbaranCompraDialog from './ToAlbaranCompraDialog';

const Table = withStyles(
    (theme) => ({
        root: {
            borderCollapse: 'separate',
            borderSpacing: '16px',
        },
    }),
    { name: 'Table' },
)(MuiTable);

const TableCell = withStyles(
    (theme) => ({
        root: {
            padding: 0,
            borderBottom: '1px solid #ccc',
            '&:not(:first-of-type)': {
                whiteSpace: 'nowrap',
            },
            '.MuiTableHead-root &': {
                border: 'none',
            },
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        sizeSmall: {
            '&:last-child': {
                padding: 0,
            },
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

const Checkbox = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0.5),
    },
}))(MuiCheckbox);

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            '&:first-of-type': {
                marginTop: 35,
            },
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '50px',
            padding: theme.spacing(0, 2),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        dividerRow: {
            '& td': {
                padding: 0,
                border: 'none',
            },
        },
        emptyRow: {
            '& td': {
                color: theme.palette.neutral.primary,
            },
            '& td:not(:first-of-type)': {
                border: 'none',
            },
        },
        actions: {
            whiteSpace: 'nowrap',
            textAlign: 'right',
            border: 'none',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        batchActions: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(1)}px`,
        },
        descripcion: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        preparada: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
                color: theme.palette.success.main,
                fontSize: 20,
            },
        },
    }),
    { name: 'PreparacionMaterialListaMateriales' },
);

export const COSTE = 'coste';
export const VENTA = 'venta';

export const precioViews = [
    {
        id: COSTE,
        label: 'Coste',
    },
    {
        id: VENTA,
        label: 'Venta',
    },
];

export default function PreparacionMaterialListaMateriales({ index }) {
    const classes = useStyles();
    const { data: preparacionMaterial, updateLocalData } = useEditFormData(`preparaciones_material.${index}`);
    const [albaranesCompraMap, setAlbaranesCompraMap] = useState({
        map: {},
        albaranes: {},
    });
    const history = useHistory();
    const [selectedRowIds, setSelectedRowIds] = React.useState([]);

    useEffect(() => {
        const missingAlbaranCompraLineaIds = preparacionMaterial.lineas
            .map((m) => m.albaran_compra_linea_id)
            .filter(Boolean)
            .filter((id) => !albaranesCompraMap.map[id]);

        if (missingAlbaranCompraLineaIds.length > 0) {
            albaranesCompraProvider
                .getAlbaranesCompraByLineas(missingAlbaranCompraLineaIds)
                .then(setAlbaranesCompraMap);
        }
    }, []);

    const [editMaterialInfo, setEditMaterialInfo] = React.useState(null);
    const openMaterialEditor =
        (preparacionMaterialId, { id, ...linea } = { id: null, descripcion: '', unidades: 0 }) =>
        (event) => {
            setEditMaterialInfo({
                preparacionMaterialId,
                lineaId: id,
                linea,
            });
        };
    const closeMaterialEditor = () => {
        setEditMaterialInfo(null);
    };

    function onChangeSelectAll(ev) {
        if (ev.target.checked) {
            setSelectedRowIds(
                preparacionMaterial.lineas
                    .filter(
                        (linea) =>
                            !linea.preparada &&
                            linea.unidades_preparadas + linea.unidades_pedidas_pendientes < linea.unidades,
                    )
                    .map((row) => row.id),
            );
        } else {
            setSelectedRowIds([]);
        }
    }

    function onSelectRow(id) {
        setSelectedRowIds((selectedRows) => {
            if (selectedRows.includes(id)) {
                return selectedRows.filter((rowId) => rowId !== id);
            }
            return [...selectedRows, id];
        });
    }

    const lineasNoPreparadas = preparacionMaterial.lineas.filter((linea) => !linea.preparada);
    const allChecked = lineasNoPreparadas.length > 0 && selectedRowIds.length === lineasNoPreparadas.length;
    const someChecked = selectedRowIds.length > 0 && selectedRowIds.length < lineasNoPreparadas.length;
    const allPreparadas = preparacionMaterial.lineas.every((linea) => linea.preparada);

    return (
        <>
            <LineaEditor
                open={Boolean(editMaterialInfo)}
                {...editMaterialInfo}
                onClose={closeMaterialEditor}
                onSave={(material) => {
                    const { preparacionMaterialId, lineaId } = editMaterialInfo;

                    let idx = preparacionMaterial.lineas.findIndex((material) => material.id === lineaId);
                    if (idx < 0) idx = preparacionMaterial.lineas.length;

                    return preparacionesMaterialProvider
                        .updateLinea(preparacionMaterialId, lineaId, material)
                        .then((updatedPreparacion) => {
                            updateLocalData(null, updatedPreparacion);
                            closeMaterialEditor();
                        });
                }}
            />
            <Paper elevation={0} className={classes.root}>
                <Typography variant='body1' className={classes.title}>
                    Lista de material
                </Typography>

                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {!allPreparadas && (
                                    <Checkbox
                                        checked={allChecked}
                                        indeterminate={someChecked}
                                        onChange={onChangeSelectAll}
                                    />
                                )}
                            </TableCell>
                            <TableCell style={{ width: '100%' }}>Descripción</TableCell>
                            <TableCell>Uds. a preparar</TableCell>
                            <TableCell>Uds. preparadas</TableCell>
                            <TableCell>Uds. restantes</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {preparacionMaterial.lineas.length === 0 && (
                            <TableRow className={classes.emptyRow}>
                                <TableCell colSpan={5}>No hay materiales</TableCell>
                            </TableRow>
                        )}
                        {preparacionMaterial.lineas.map((linea, i) => {
                            return (
                                <TableRow key={i}>
                                    <TableCell className={classes.actions}>
                                        {linea.preparada ? (
                                            <div className={classes.preparada}>
                                                <CheckCircleIcon />
                                            </div>
                                        ) : (
                                            <Checkbox
                                                checked={selectedRowIds.includes(linea.id)}
                                                onChange={() => onSelectRow(linea.id)}
                                                disabled={
                                                    linea.unidades_pendientes - linea.unidades_pedidas_pendientes === 0
                                                }
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <div className={classes.descripcion}>
                                            {linea.descripcion}{' '}
                                            {linea.albaran_compra_lineas.length > 0 ? (
                                                <AlbaranesCompraInfoTooltip albaranes={linea.albaran_compra_lineas} />
                                            ) : null}
                                        </div>
                                    </TableCell>
                                    <TableCell align='right'>{linea.unidades}</TableCell>
                                    <TableCell align='right'>
                                        {linea.unidades_preparadas}{' '}
                                        {linea.unidades_pedidas_pendientes > 0
                                            ? ` (+${linea.unidades_pedidas_pendientes})`
                                            : ''}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {linea.unidades_pendientes - linea.unidades_pedidas_pendientes}
                                    </TableCell>
                                    <TableCell className={classes.actions}>
                                        <IconButton onClick={openMaterialEditor(preparacionMaterial.id, linea)}>
                                            <EditIcon />
                                        </IconButton>
                                        <DeleteDialog
                                            text='¿Estás seguro que quieres eliminar este material?'
                                            onConfirm={() =>
                                                preparacionesMaterialProvider
                                                    .deleteLinea(preparacionMaterial.id, linea.id)
                                                    .then((preparacionMaterial) =>
                                                        updateLocalData(null, preparacionMaterial),
                                                    )
                                            }
                                            button={
                                                <IconButton>
                                                    <DeleteIcon />
                                                </IconButton>
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Paper>
            <div className={classes.batchActions}>
                <Button
                    color='primary'
                    startIcon={<AddIcon />}
                    size='small'
                    style={{ paddingLeft: 0 }}
                    onClick={openMaterialEditor(preparacionMaterial.id)}
                >
                    Añadir material
                </Button>
                <Button
                    color='info'
                    startIcon={<DoneIcon />}
                    size='small'
                    style={{ marginLeft: 'auto' }}
                    onClick={() => {
                        preparacionesMaterialProvider
                            .setLineasPreparadas(preparacionMaterial.id, selectedRowIds)
                            .then((preparacionMaterial) => {
                                setSelectedRowIds([]);
                                updateLocalData(null, preparacionMaterial);
                            });
                    }}
                    disabled={selectedRowIds.length === 0}
                >
                    Marcar como preparadas
                </Button>
                <ToAlbaranCompraDialog
                    preparacionMaterialId={preparacionMaterial.id}
                    selectedLineaIds={selectedRowIds}
                    onSave={(res) => history.push(`/albaranes-compra/${res.id}`)}
                    button={
                        <Button color='info' size='small' disabled={selectedRowIds.length === 0}>
                            Añadir a albarán de compra
                        </Button>
                    }
                />
            </div>
        </>
    );
}

PreparacionMaterialListaMateriales.propTypes = {
    index: PropTypes.any.isRequired,
};
