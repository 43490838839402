import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

export const VIGENTE = 'VIGENTE';
export const A_PUNTO_VENCER = 'A_PUNTO_VENCER';
export const VENCIDO = 'VENCIDO';

export const estadoLabels = {
    [VIGENTE]: 'Vigente',
    [A_PUNTO_VENCER]: 'A punto de vencer',
    [VENCIDO]: 'Vencido',
};

export const estadoOptions = Object.entries(estadoLabels).map(([value, label]) => ({ value, label }));

const useStyles = makeStyles(
    (theme) => ({
        root: {
            borderRadius: 4,
            color: 'white',
            padding: theme.spacing(0, 1),
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            height: 24,
            width: 'fit-content',
            margin: 'auto',
            '&.VIGENTE': {
                backgroundColor: theme.palette.success.main,
            },
            '&.A_PUNTO_VENCER': {
                backgroundColor: theme.palette.warning.main,
            },
            '&.VENCIDO': {
                backgroundColor: theme.palette.error.main,
            },
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
    }),
    { name: 'EstadoChip' },
);

export default function EstadoChip({ value, style }) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, value)} style={style}>
            <Typography variant='subtitle2'>{estadoLabels[value]}</Typography>
        </div>
    );
}

EstadoChip.propTypes = {
    value: PropTypes.string.isRequired,
    style: PropTypes.object,
};
