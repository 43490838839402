import Button from '../common/Button';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function TareaDialogActions({ disabled, onCancelar, activeStep, setActiveStep }) {
    const {
        values: { tipo },
        isSubmitting,
    } = useFormikContext();

    const isMantenimiento = tipo === 'MANTENIMIENTO';
    const lastStep = isMantenimiento ? 2 : 1;

    return (
        <>
            <Button color='outlined' disabled={disabled} onClick={onCancelar} width={200}>
                Cancelar
            </Button>
            {activeStep > 0 && (
                <Button
                    color='dark'
                    disabled={disabled}
                    width={200}
                    onClick={() => setActiveStep((activeStep) => activeStep - 1)}
                >
                    Atrás
                </Button>
            )}
            {activeStep < lastStep && (
                <Button
                    color='info'
                    disabled={disabled}
                    width={200}
                    onClick={() => setActiveStep((activeStep) => activeStep + 1)}
                >
                    Siguiente
                </Button>
            )}
            {activeStep === lastStep && (
                <Button
                    type='submit'
                    color='info'
                    disabled={disabled}
                    width={200}
                    startIcon={
                        isSubmitting ? <CircularProgress size={16} style={{ color: 'rgba(0, 0, 0, 0.26)' }} /> : null
                    }
                >
                    Guardar
                </Button>
            )}
        </>
    );
}

TareaDialogActions.propTypes = {
    activeStep: PropTypes.any,
    disabled: PropTypes.any,
    onCancelar: PropTypes.any,
    setActiveStep: PropTypes.any,
};
