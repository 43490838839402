import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import { BaseParteTrabajoList } from '../servicios/ParteTrabajoList/ParteTrabajoList';

export default function PartesTrabajoProyecto() {
    const { data: jornadas, fetchData, updateData } = useEditFormData('jornadas');

    if (!jornadas) return null;

    return (
        <BaseParteTrabajoList
            jornadas={jornadas}
            updateData={updateData}
            fetchData={fetchData}
            itemTitleFn={(item) => {
                const title = item.operario ? `${item.operario}` : 'Imputación de material';

                return `${item.tarea.descripcion} | ${title}`;
            }}
        />
    );
}
