import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { operariosProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import ColorEditorDialog from '../common/dialogs/ColorEditor/ColorEditorDialog';

function OperarioColorEditor({ operario, ...props }) {
    const theme = useTheme();
    const defaultColor = {
        color_bg: theme.palette.primary.hover,
        color_fg: theme.palette.text.primary,
    };

    const [colors, setColors] = useState([]);

    useEffect(() => {
        if (!props.open) return;

        operariosProvider.action('colores').then((recentColors) => {
            const newColors = [defaultColor, ...recentColors];

            setColors(newColors);
        });
    }, [props.open]);

    return (
        <ColorEditorDialog
            title='Editar color del operario'
            backgroundColor={operario.color?.color_bg ?? null}
            textColor={operario.color?.color_fg ?? null}
            onSaveColor={(values) => {
                return operariosProvider.action(`${operario.id}/color`, {
                    method: 'post',
                    body: JSON.stringify(values),
                });
            }}
            colors={colors}
            {...props}
        />
    );
}

OperarioColorEditor.propTypes = {
    onClose: PropTypes.any,
    operario: PropTypes.any,
    open: PropTypes.bool,
};

export default withButtonOpener(OperarioColorEditor);
