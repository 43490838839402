import { FormControl } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { presupuestosProvider } from '../../api';
import { estadoPresupuestoOptions, isEstadoFinal } from '../../api/presupuestos';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { ButtonDialog } from '../common/dialogs/ButtonDialog';
import ClienteField from '../common/fields/ClienteField';
import DireccionField from '../common/fields/DireccionField';
import FileUploader from '../common/fields/FileUploader';
import { KeyboardDatePicker } from '../common/fields/KeyboardDatePicker';
import ProyectoField from '../common/fields/ProyectoField';
import SelectField from '../common/fields/SelectField';
import DialogForm from '../common/forms/DialogForm';
import PrecioFields from './PresupuestoView/PrecioFields';

const PresupuestoSchema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
    cliente: Yup.mixed().required('Selecciona un cliente'),
    direccion: Yup.mixed().required('Selecciona una direccion'),
    importe_coste: Yup.number().required('Requerido'),
    importe_venta: Yup.number().required('Requerido'),
});

function EditPresupuestoDialog({ onSave, id, ...props }) {
    const [presupuesto, setPresupuesto] = useState(null);
    useEffect(() => {
        if (!props.open) {
            setPresupuesto(null);
            return;
        }

        presupuestosProvider.getAll(id).then(setPresupuesto);
    }, [id, props.open]);

    if (!presupuesto) return null;

    return (
        <DialogForm
            title='Editar presupuesto'
            FormikProps={{
                initialValues: {
                    cliente: { id: presupuesto.cliente.id },
                    direccion: { id: presupuesto.direccion.id },
                    proyecto: presupuesto.proyecto ? { id: presupuesto.proyecto.id } : null,
                    referencia_interna: presupuesto.referencia_interna,
                    nombre: presupuesto.nombre,
                    fecha_creacion: new Date(presupuesto.fecha_creacion),
                    fecha_envio: presupuesto.fecha_envio ? new Date(presupuesto.fecha_envio) : null,
                    fecha_final: presupuesto.fecha_final ? new Date(presupuesto.fecha_final) : null,
                    estado: presupuesto.estado,
                    notas: presupuesto.notas,
                    importe_coste: presupuesto.importe_coste,
                    importe_venta: presupuesto.importe_venta,
                    margen: (presupuesto.margen * 100).toFixed(2),
                    files: [],
                },
                validationSchema: PresupuestoSchema,
                onSubmit: ({ cliente, direccion, proyecto, files, ...values }, { setSubmitting, setFieldError }) => {
                    const formData = new FormData();
                    const formValues = {};

                    Object.entries(values).forEach(([key, value]) => (formValues[key] = value));
                    formValues.cliente_id = cliente.id;
                    formValues.direccion_id = direccion.id;
                    formValues.proyecto_id = proyecto ? proyecto.id : null;
                    formData.append('params', JSON.stringify(formValues));

                    new Array(...files).forEach((file) => formData.append('files', file));

                    presupuestosProvider
                        .save(formData, id)
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ touched, errors, values, setFieldValue }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ClienteField
                            error={touched.cliente && !!errors.cliente}
                            helperText={touched.cliente && errors.cliente}
                            disabled={presupuesto.proyecto}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DireccionField
                            error={touched.direccion && !!errors.direccion}
                            helperText={touched.direccion && errors.direccion}
                            disabled={presupuesto.proyecto}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ProyectoField
                            error={touched.presupuesto && !!errors.presupuesto}
                            helperText={touched.presupuesto && errors.presupuesto}
                            disabled={presupuesto.proyecto}
                            filterByDireccion
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='referencia_interna' label='Ref. interna' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <Field
                            name='estado'
                            id='estado'
                            component={SelectField}
                            label='Estado'
                            fullWidth
                            selectOptions={estadoPresupuestoOptions}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name='nombre' label='Nombre' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name='notas' label='Notas' fullWidth multiline maxRows={5} />
                    </Grid>
                    <Grid item xs={4}>
                        <KeyboardDatePicker
                            name='fecha_creacion'
                            label='Fecha de creación'
                            format='dd/MM/yyyy'
                            autoOk
                            ampm={false}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <KeyboardDatePicker
                            name='fecha_envio'
                            label='Fecha de envio'
                            format='dd/MM/yyyy'
                            autoOk
                            ampm={false}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <KeyboardDatePicker
                            name='fecha_final'
                            label='Fecha final'
                            format='dd/MM/yyyy'
                            autoOk
                            ampm={false}
                            fullWidth
                        />
                    </Grid>
                    <PrecioFields
                        setFieldValue={setFieldValue}
                        values={values}
                        precioCosteLabel='Importe coste'
                        precioCosteKey='importe_coste'
                        precioVentaLabel='Importe venta'
                        precioVentaKey='importe_venta'
                        gridWidth={4}
                        precioCosteDisabled={presupuesto.lineas.length > 0}
                        disabled={isEstadoFinal(presupuesto.estado)}
                    />
                    <Grid item xs={12}>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel shrink>Ficheros adjuntos</InputLabel>
                            <List dense style={{ marginTop: 16 }}>
                                {presupuesto.adjuntos.length === 0 ? (
                                    <ListItem>
                                        <ListItemText>No hay ficheros adjuntos</ListItemText>
                                    </ListItem>
                                ) : (
                                    presupuesto.adjuntos.map((adjunto) => {
                                        const isImage = adjunto.content_type.startsWith('image');

                                        return (
                                            <ListItem
                                                key={adjunto.id}
                                                dense
                                                button
                                                onClick={() =>
                                                    window.open(adjunto.image_url || adjunto.original_url, '_blank')
                                                }
                                            >
                                                <ListItemAvatar>
                                                    {isImage ? (
                                                        <Avatar variant='square' src={adjunto.thumbnail_url} />
                                                    ) : (
                                                        <Avatar variant='square'>
                                                            <PictureAsPdfIcon />
                                                        </Avatar>
                                                    )}
                                                </ListItemAvatar>
                                                <ListItemText primary={adjunto.original_url.split('/').pop()} />
                                                <ListItemSecondaryAction>
                                                    <ButtonDialog
                                                        button={
                                                            <IconButton edge='end' aria-label='delete'>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        }
                                                        dialogTitle='¿Borrar adjunto?'
                                                        dialogText='Esta opcion no se puede deshacer. ¿Deseas continuar?'
                                                        onConfirm={() => {
                                                            presupuestosProvider
                                                                .action(`${id}/adjuntos/${adjunto.id}`, {
                                                                    method: 'delete',
                                                                })
                                                                .then(() => {
                                                                    setPresupuesto((presupuesto) => ({
                                                                        ...presupuesto,
                                                                        adjuntos: presupuesto.adjuntos.filter(
                                                                            (adj) => adj.id !== adjunto.id,
                                                                        ),
                                                                    }));
                                                                });
                                                        }}
                                                    />
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        );
                                    })
                                )}
                            </List>
                        </FormControl>
                    </Grid>
                    {presupuesto.adjuntos.length === 0 && (
                        <Grid item xs={12}>
                            <FileUploader
                                id='upload-presupuesto-adjuntos-file'
                                label='Añadir adjuntos'
                                file={values.files}
                                setFile={(file) => setFieldValue('files', [file])}
                                isImage={false}
                                accept='image/jpeg,image/gif,image/png,application/pdf'
                            />
                        </Grid>
                    )}
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(EditPresupuestoDialog);

EditPresupuestoDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
    id: PropTypes.any,
};
