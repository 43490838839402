import { useEffect, useState } from 'react';
import { Field, useField } from 'formik';
import { proveedoresProvider } from '../../../api';
import { Autocomplete } from 'formik-material-ui-lab';
import MuiTextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

export default function ProveedorField({ error, helperText, disabled = false }) {
    const [proveedoresOptions, setProveedoresOptions] = useState([]);
    const [{ value }, , { setValue }] = useField('proveedor');

    useEffect(() => {
        proveedoresProvider.getAsOptions().then(setProveedoresOptions);
    }, []);

    useEffect(() => {
        if (proveedoresOptions.length === 0) return;

        setValue(value ? proveedoresOptions.find((d) => d.id === value.id) : null);
    }, [value, proveedoresOptions]);

    return (
        <Field
            name='proveedor'
            fullWidth
            component={Autocomplete}
            options={proveedoresOptions}
            getOptionSelected={(option, value) => option.id === value?.id}
            getOptionLabel={(option) => option.nombre ?? ''}
            disabled={disabled}
            renderInput={(params) => (
                <MuiTextField {...params} error={error} helperText={helperText} label='Proveedor' />
            )}
        />
    );
}

ProveedorField.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.any,
    helperText: PropTypes.any,
};
