import { Paper } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.divider}`,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        titleTypography: {
            flex: 1,
            fontWeight: theme.typography.fontWeightMedium,
            lineHeight: '50px',
        },
    }),
    { name: 'PaperTable' },
);

const TableCell = withStyles(
    (theme) => ({
        root: {
            height: 36,
            whiteSpace: 'nowrap',
            '&.number': {
                textAlign: 'right',
            },
            '&:first-of-type': {
                width: '100%',
                textTransform: 'capitalize',
            },
            'tr:not(:last-of-type) &': {
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            '.MuiTableHead-root &': {
                fontWeight: theme.typography.fontWeightMedium,
                backgroundColor: theme.palette.neutral.grey2,
                borderBottom: 'none',
            },
            '.MuiTableFooter-root &': {
                fontWeight: theme.typography.fontWeightMedium,
                backgroundColor: theme.palette.primary.hover,
                borderBottom: 'none',
                color: theme.palette.text.primary,
            },
            '.MuiTableFooter-root &:first-of-type': {
                borderBottomLeftRadius: 8,
            },
            '.MuiTableFooter-root &:last-of-type': {
                borderBottomRightRadius: 8,
            },
        },
        sizeSmall: {
            padding: '6px 8px 2px 8px',
            '&:first-of-type': {
                paddingLeft: 16,
            },
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

export default function PaperTable({ title, titleActions, columns, rows, footerRow }) {
    const classes = useStyles();

    return (
        <Paper elevation={0} className={classes.root}>
            <div className={classes.title}>
                <Typography variant='body2' className={classes.titleTypography}>
                    {title}
                </Typography>
                {titleActions}
            </div>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        {columns.map(({ label, isNumber }, i) => (
                            <TableCell key={i} className={isNumber ? 'number' : null}>
                                {label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, r) => (
                        <TableRow key={r}>
                            {columns.map(({ renderCell, isNumber }, i) => (
                                <TableCell key={i} className={isNumber ? 'number' : null}>
                                    {renderCell(row)}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
                {footerRow && (
                    <TableFooter>
                        <TableRow>
                            {columns.map(({ renderCell, footer, isNumber }, i) => (
                                <TableCell key={i} className={isNumber ? 'number' : null}>
                                    {footer === undefined
                                        ? renderCell(footerRow)
                                        : footer instanceof Function
                                        ? footer(footerRow)
                                        : footer}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </Paper>
    );
}

PaperTable.propTypes = {
    columns: PropTypes.array.isRequired,
    footerRow: PropTypes.object.isRequired,
    rows: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    titleActions: PropTypes.any,
};
