import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { preparacionesMaterialProvider, tareasProvider } from '../../api';
import PreparacionMaterialDrawerContent from './PreparacionMaterialDrawerContent';
import DetailsDrawer from '../common/DetailsDrawer';
import { useHistory, useRouteMatch } from 'react-router-dom';

export default function PreparacionMaterialDrawer({ row, solicitudId, onClose }) {
    const history = useHistory();
    const match = useRouteMatch();
    const fetchDataFn = useCallback(
        () =>
            Promise.all([
                tareasProvider.getAll(row.tarea_id),
                preparacionesMaterialProvider.getByTarea(row.tarea_id),
            ]).then(([tarea, preparacionesMaterial]) => {
                tarea.preparaciones_material = preparacionesMaterial;
                return tarea;
            }),
        [row.tarea_id],
    );

    useEffect(() => {
        if (solicitudId) {
            history.replace(`${match.url}#solicitud-${solicitudId}`);
        }
    }, [solicitudId]);

    const preparacionMaterialId = solicitudId && row.preparacion_material_id ? row.preparacion_material_id : row.id;
    return (
        <DetailsDrawer
            open={Boolean(row)}
            onClose={(ev) => {
                history.replace(match.url);
                onClose(ev);
            }}
            fetchDataFn={fetchDataFn}
            openUrl={`/servicios/${row.tarea_id}/preparaciones-material/${preparacionMaterialId}`}
        >
            <PreparacionMaterialDrawerContent preparacionMaterialId={preparacionMaterialId} />
        </DetailsDrawer>
    );
}

PreparacionMaterialDrawer.propTypes = {
    onClose: PropTypes.any,
    row: PropTypes.any,
    solicitudId: PropTypes.any,
};
