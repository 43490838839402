import { Route, Switch } from 'react-router-dom';
import ProveedoresList from './ProveedoresList';
import EditProveedorForm from './EditProveedorForm';

export default function ProveedoresModule() {
    return (
        <>
            <Switch>
                <Route
                    path='/proveedores/:id'
                    render={({ match }) => <EditProveedorForm basePath='/proveedores' match={match} />}
                />
                <Route path='/proveedores'>
                    <ProveedoresList />
                </Route>
            </Switch>
        </>
    );
}
