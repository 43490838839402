import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { format, formatFullDate } from '../../../../utils';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
            margin: theme.spacing(3),
        },
        hora: {
            fontSize: 48,
            lineHeight: '58px',
        },
    }),
    { name: 'Reloj' },
);

export default function Reloj() {
    const [fecha, setFecha] = useState(new Date());
    const classes = useStyles();

    useEffect(() => {
        const timerId = setInterval(() => setFecha(new Date()), 1000);

        return () => clearInterval(timerId);
    }, []);

    return (
        <div className={classes.root}>
            <Typography variant='h3'>{formatFullDate(fecha)}</Typography>
            <Typography className={classes.hora}>{format(fecha, 'HH:mm:ss')}</Typography>
        </div>
    );
}
