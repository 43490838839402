import MenuItem from '@material-ui/core/MenuItem';
import DescriptionIcon from '@material-ui/icons/Description';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import DeleteDialog from '../../common/dialogs/DeleteDialog';
import MasterItem from '../../common/forms/MasterDetailsView/MasterItem';
import MasterItemOptions from '../../common/forms/MasterDetailsView/MasterItemOptions';

export default function SolicitudMaterialItem({ item, index, href, navigate, onDelete }) {
    const location = useLocation();
    const isSelected = location.pathname === href;

    return (
        <MasterItem
            icon={DescriptionIcon}
            title={`Solicitud de material ${index + 1}`}
            subtitle={`Por ${item.operario.nombre}`}
            navigate={navigate}
            lastUpdate={item.fecha_actualizacion}
            isSelected={isSelected}
            actions={
                <MasterItemOptions>
                    {({ closeMenu }) => (
                        <DeleteDialog
                            text='¿Estás seguro que quieres eliminar esta solicitud de material?'
                            onConfirm={(ev) => {
                                closeMenu(ev);
                                onDelete();
                            }}
                            button={<MenuItem onClick={closeMenu}>Eliminar</MenuItem>}
                            confirmationText='borrar solicitud'
                        />
                    )}
                </MasterItemOptions>
            }
        />
    );
}

SolicitudMaterialItem.propTypes = {
    href: PropTypes.string.isRequired,
    index: PropTypes.any,
    item: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    showHeader: PropTypes.bool,
};
