import { FormControl } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { albaranesCompraProvider } from '../../api';
import { estadoCompraOptions } from '../../api/preparaciones_material';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { ButtonDialog } from '../common/dialogs/ButtonDialog';
import FileUploader from '../common/fields/FileUploader';
import ProveedorField from '../common/fields/ProveedorField';
import SelectField from '../common/fields/SelectField';
import DialogForm from '../common/forms/DialogForm';

const AlbaranCompraSchema = Yup.object().shape({
    referencia: Yup.string().required('Requerido'),
    proveedor: Yup.mixed().required('Selecciona un proveedor'),
    fecha_solicitud: Yup.date().typeError('Fecha inválida').required('Requerido'),
    fecha_llegada_prevista: Yup.date().typeError('Fecha inválida').nullable(),
    fecha_llegada: Yup.date().typeError('Fecha inválida').nullable(),
});

function EditAlbaranCompraDialog({ onSave, albaranCompra: originalAlbaranCompra, ...props }) {
    const [albaranCompra, setAlbaranCompra] = useState(originalAlbaranCompra);
    useEffect(() => setAlbaranCompra(originalAlbaranCompra), [originalAlbaranCompra]);

    return (
        <DialogForm
            title='Editar albarán de compra'
            FormikProps={{
                initialValues: {
                    referencia: albaranCompra.referencia,
                    proveedor: albaranCompra.proveedor,
                    factura_compra: albaranCompra.factura_compra ?? '',
                    fecha_factura: albaranCompra.fecha_factura ? new Date(albaranCompra.fecha_factura) : null,
                    fecha_solicitud: new Date(albaranCompra.fecha_solicitud),
                    fecha_llegada_prevista: albaranCompra.fecha_llegada_prevista
                        ? new Date(albaranCompra.fecha_llegada_prevista)
                        : null,
                    fecha_llegada: albaranCompra.fecha_llegada ? new Date(albaranCompra.fecha_llegada) : null,
                    notas: albaranCompra.notas ?? '',
                    estado: albaranCompra.estado,
                    files: [],
                },
                validationSchema: AlbaranCompraSchema,
                onSubmit: ({ files, proveedor, ...values }, { setSubmitting, setFieldError }) => {
                    const formData = new FormData();
                    values.proveedor_id = proveedor.id;
                    formData.append('values', JSON.stringify(values));

                    new Array(...files).forEach((file) => formData.append('files', file));

                    albaranesCompraProvider
                        .save(formData, albaranCompra.id)
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ values, touched, errors, setFieldValue }) => (
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField name='referencia' label='Referencia' fullWidth />
                    </Grid>
                    <Grid item xs={8}>
                        <ProveedorField
                            error={touched.proveedor && !!errors.proveedor}
                            helperText={touched.proveedor && errors.proveedor}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <KeyboardDatePicker
                            name='fecha_solicitud'
                            label='Fecha de solicitud'
                            format={'dd/MM/yyyy'}
                            fullWidth
                            autoOk
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <KeyboardDatePicker
                            name='fecha_llegada_prevista'
                            label='Fecha prev. llegada'
                            format={'dd/MM/yyyy'}
                            fullWidth
                            autoOk
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <KeyboardDatePicker
                            name='fecha_llegada'
                            label='Fecha de llegada'
                            format={'dd/MM/yyyy'}
                            fullWidth
                            autoOk
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField name='factura_compra' label='Factura' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <KeyboardDatePicker
                            name='fecha_factura'
                            label='Fecha de factura'
                            format={'dd/MM/yyyy'}
                            fullWidth
                            autoOk
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Field
                            name='estado'
                            id='estado'
                            component={SelectField}
                            label='Estado'
                            fullWidth
                            selectOptions={estadoCompraOptions}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel shrink>Ficheros adjuntos</InputLabel>
                            <List dense style={{ marginTop: 16 }}>
                                {albaranCompra.adjuntos.length === 0 ? (
                                    <ListItem>
                                        <ListItemText>No hay ficheros adjuntos</ListItemText>
                                    </ListItem>
                                ) : (
                                    albaranCompra.adjuntos.map((adjunto) => {
                                        const isImage = adjunto.content_type.startsWith('image');

                                        return (
                                            <ListItem
                                                key={adjunto.id}
                                                dense
                                                button
                                                onClick={() =>
                                                    window.open(adjunto.image_url || adjunto.original_url, '_blank')
                                                }
                                            >
                                                <ListItemAvatar>
                                                    {isImage ? (
                                                        <Avatar variant='square' src={adjunto.thumbnail_url} />
                                                    ) : (
                                                        <Avatar variant='square'>
                                                            <PictureAsPdfIcon />
                                                        </Avatar>
                                                    )}
                                                </ListItemAvatar>
                                                <ListItemText primary={adjunto.original_url.split('/').pop()} />
                                                <ListItemSecondaryAction>
                                                    <ButtonDialog
                                                        button={
                                                            <IconButton edge='end' aria-label='delete'>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        }
                                                        dialogTitle='¿Borrar adjunto?'
                                                        dialogText='Esta opcion no se puede deshacer. ¿Deseas continuar?'
                                                        onConfirm={() => {
                                                            albaranesCompraProvider
                                                                .action(`${albaranCompra.id}/adjuntos/${adjunto.id}`, {
                                                                    method: 'delete',
                                                                })
                                                                .then(() => {
                                                                    setAlbaranCompra((albaranCompra) => ({
                                                                        ...albaranCompra,
                                                                        adjuntos: albaranCompra.adjuntos.filter(
                                                                            (adj) => adj.id !== adjunto.id,
                                                                        ),
                                                                    }));
                                                                });
                                                        }}
                                                    />
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        );
                                    })
                                )}
                            </List>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FileUploader
                            id='upload-proyecto-adjuntos-file'
                            label='Añadir adjuntos'
                            file={values.files}
                            setFile={(files) => setFieldValue('files', files)}
                            multiple={true}
                            isImage={false}
                            accept='image/jpeg,image/gif,image/png,application/pdf'
                        />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(EditAlbaranCompraDialog);

EditAlbaranCompraDialog.propTypes = {
    albaranCompra: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
