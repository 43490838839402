import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
import MasterItemOptions from '../../common/forms/MasterDetailsView/MasterItemOptions';

export function LineasOptionsButton({ onSetAllExpanded, showDescripciones, setShowDescripciones, children }) {
    return (
        <MasterItemOptions>
            {({ renderButtonComponentMenuItem, renderMenuItem }) => {
                const options = [];
                if (children) {
                    const newOptions = children({
                        renderMenuItem,
                        renderButtonComponentMenuItem,
                    });

                    if (Array.isArray(newOptions)) {
                        options.push(...newOptions);
                    } else {
                        options.push(newOptions);
                    }
                }

                options.push(
                    renderMenuItem(<>Mostrar descripciones {showDescripciones ? <DoneIcon /> : null}</>, () =>
                        setShowDescripciones(!showDescripciones),
                    ),
                    renderMenuItem('Colapsar todo', () => onSetAllExpanded(false)),
                    renderMenuItem('Expandir todo', () => onSetAllExpanded(true)),
                );
                return options;
            }}
        </MasterItemOptions>
    );
}

LineasOptionsButton.propTypes = {
    onSetAllExpanded: PropTypes.func.isRequired,
    showDescripciones: PropTypes.bool.isRequired,
    setShowDescripciones: PropTypes.func.isRequired,
    children: PropTypes.func,
};
