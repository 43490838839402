import NotasDialog from './NotasDialog';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SobreIcon from '../../icons/Sobre';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiBadge from '@material-ui/core/Badge';
import PropTypes from 'prop-types';

const Badge = withStyles((theme) => ({
    badge: {
        width: 6,
        height: 6,
        minWidth: 6,
        borderRadius: 5,
        backgroundColor: (props) => (props.color === 'default' ? '#757575' : theme.palette.error.main),
    },
}))(MuiBadge);

export default function NotasDialogAction({ id, item, onUpdate }) {
    return (
        <NotasDialog
            id={id}
            button={
                <Tooltip title='Ver notas internas, observaciones y fotos'>
                    <IconButton style={{ padding: 6 }} aria-label='Notas internas'>
                        <Badge overlap='circular' variant='dot' color={item.sin_leer ? 'error' : 'default'}>
                            <SobreIcon fontSize='small' />
                        </Badge>
                    </IconButton>
                </Tooltip>
            }
            onUpdateNotasInternas={(notasInternas) => {
                onUpdate(item.id, {
                    ...item,
                    notas_internas: notasInternas,
                });
            }}
            onUpdateObservaciones={(updatedJornadas) => {
                const hasObservacionesNoVistas = updatedJornadas.some(
                    (jornada) => jornada.notas.length > 0 && !jornada.notas[0].vista,
                );

                const hasIncidenciasNoResueltas = updatedJornadas.some(
                    (jornada) =>
                        jornada.notas.length > 0 &&
                        jornada.notas[0].fecha_resolucion === null &&
                        jornada.notas[0].incidencia,
                );

                onUpdate(item.id, {
                    ...item,
                    sin_leer: hasObservacionesNoVistas,
                    sin_resolver: hasIncidenciasNoResueltas,
                });
            }}
        />
    );
}

NotasDialogAction.propTypes = {
    id: PropTypes.any,
    item: PropTypes.any,
    onUpdate: PropTypes.any,
};
