import React from 'react';
import PropTypes from 'prop-types';

export default function Aviso({ icon, text, button, color, className, style }) {
    const styledIcon = React.cloneElement(icon, {
        style: {
            fontSize: 16,
            color: color,
            marginRight: 8,
        },
    });

    const styledButton =
        button &&
        React.cloneElement(button, {
            style: {
                fontSize: 13,
                padding: 0,
                marginLeft: 4,
                color: color,
                marginRight: 8,
                fontWeight: 'bold',
                textDecoration: 'underline',
                textTransform: 'none',
            },
        });

    return (
        <div
            className={className}
            style={{
                display: 'flex',
                alignItems: 'center',
                color: color,
                fontSize: 13,
                ...style,
            }}
        >
            {styledIcon}
            {text}
            {styledButton}
        </div>
    );
}

Aviso.propTypes = {
    button: PropTypes.any,
    className: PropTypes.any,
    color: PropTypes.any,
    icon: PropTypes.any,
    style: PropTypes.any,
    text: PropTypes.any,
};
