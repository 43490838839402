import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { FieldArray, Form, Formik } from 'formik';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { presupuestosProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import DialogEditor from '../common/forms/DialogEditor';
import { TableCell, TableRow } from './PresupuestoView/Table';
const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 800,
        },
        body: {
            padding: 0,
        },
        noDownward: {
            marginLeft: 32,
        },
    }),
    { name: 'ReordenarLineasDialog' },
);

function ReordenarLineasDialog({
    presupuestoId,
    parentLineaId,
    concepto: fullConcepto,
    lineas,
    onSave,
    open,
    ...props
}) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    let concepto = fullConcepto;
    if (concepto?.length > 50) {
        concepto = concepto.substring(0, 50) + '...';
    }

    return (
        <Formik
            initialValues={{
                lineas: lineas.map((linea) => ({
                    id: linea.id,
                    referencia: linea.referencia,
                    concepto: linea.concepto,
                })),
            }}
            enableReinitialize
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                const payload = {
                    parent_id: parentLineaId ?? null,
                    lineas: values.lineas.map((linea, index) => ({
                        id: linea.id,
                        orden: index + 1,
                    })),
                };

                presupuestosProvider
                    .reordenarLineas(presupuestoId, payload)
                    .then(() => {
                        props.onClose();
                        setSubmitting(false);
                        onSave();
                    })
                    .catch((err) => {
                        if (err.status === 400) {
                            for (const [key, value] of Object.entries(err.message)) {
                                setFieldError(key, value[0]);
                            }
                        } else {
                            snackbar.showMessage('Ha ocurrido un error');
                        }

                        setSubmitting(false);
                    });
            }}
        >
            {({ isSubmitting, submitForm, values }) => (
                <Form>
                    <DialogEditor
                        title={concepto ? `Reordenar capítulo: ${concepto}` : 'Reordenar capìtulos del presupuesto'}
                        maxWidth='md'
                        onSave={submitForm}
                        classes={{
                            root: classes.root,
                            body: classes.body,
                        }}
                        open={open}
                        canSave={!isSubmitting}
                        saveButtonText='Guardar'
                        {...props}
                    >
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Referencia</TableCell>
                                    <TableCell style={{ width: '100%' }}>Concepto</TableCell>
                                </TableRow>
                            </TableHead>
                            <FieldArray name='lineas'>
                                {({ move }) => (
                                    <TableBody>
                                        {values.lineas.map((linea, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {index < values.lineas.length - 1 && (
                                                        <IconButton size='small' onClick={() => move(index, index + 1)}>
                                                            <ArrowDownwardIcon />
                                                        </IconButton>
                                                    )}
                                                    {index > 0 && (
                                                        <IconButton
                                                            size='small'
                                                            onClick={() => move(index, index - 1)}
                                                            className={
                                                                index >= values.lineas.length - 1
                                                                    ? classes.noDownward
                                                                    : null
                                                            }
                                                        >
                                                            <ArrowUpwardIcon />
                                                        </IconButton>
                                                    )}
                                                </TableCell>
                                                <TableCell>{linea.referencia}</TableCell>
                                                <TableCell>{linea.concepto}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                )}
                            </FieldArray>
                        </Table>
                    </DialogEditor>
                </Form>
            )}
        </Formik>
    );
}

ReordenarLineasDialog.propTypes = {
    presupuestoId: PropTypes.any,
    parentLineaId: PropTypes.any,
    concepto: PropTypes.string,
    lineas: PropTypes.array,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.any,
    open: PropTypes.any,
};

export default withButtonOpener(ReordenarLineasDialog);
