import { useEffect, useMemo, useState } from 'react';
import { companiesProvider } from '../../api';
import { multipleSelectFilterFn, SelectColumnFilter } from '../common/list/SelectColumnFilter';

export default function useCategoriasTableColumn() {
    const [categoriaOptions, setCategoriaOptions] = useState([]);

    useEffect(() => {
        companiesProvider
            .getCategoriasTareaAsOptions()
            .then((categorias) => setCategoriaOptions(categorias.map((c) => ({ value: c.nombre, label: c.nombre }))));
    }, []);

    return useMemo(
        () => ({
            Header: 'Categoría',
            accessor: 'categoria',
            Filter: SelectColumnFilter,
            filter: multipleSelectFilterFn,
            filterOptions: {
                options: categoriaOptions,
                multiple: true,
            },
        }),
        [categoriaOptions],
    );
}
