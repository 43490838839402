import DataProvider from './DataProvider';
import { formatISODateTime } from '../utils';

export const empty = {
    codigo: '',
    marca: '',
    modelo: '',
    matricula: '',
    num_plazas: 0,
};

class VehiculosProvider extends DataProvider {
    constructor() {
        super('vehiculos', empty);
    }

    getTareas = async (id) => this.action(`${id}/planificaciones`);

    getAsOptions = async () => {
        return this.getAll('as_options');
    };

    getAsOptionsForPlanificacion = async (fechaInicio, fechaFin, operarios, planificacionId) => {
        return this.getAll(
            `planificacion_options?planificacion_id=${planificacionId ?? ''}&fecha_inicio=${encodeURIComponent(
                formatISODateTime(fechaInicio),
            )}&fecha_fin=${encodeURIComponent(formatISODateTime(fechaFin))}&operario_ids=${operarios.join(',')}`,
        );
    };
}

export const dataProvider = new VehiculosProvider();
