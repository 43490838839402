import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterDetailsView from '../../common/forms/MasterDetailsViewOld/MasterDetailsView';
import DiasVacacionesDetails from './DiasVacacionesDetails';
import DiasVacacionesItem from './DiasVacacionesItem';

export default function DiasVacacionesList() {
    const { data: operario } = useEditFormData();

    return (
        <MasterDetailsView
            masterComponent={DiasVacacionesItem}
            detailsComponent={DiasVacacionesDetails}
            items={operario.dias_vacaciones}
            idKey='year'
        />
    );
}

DiasVacacionesList.propTypes = {};
