import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { presupuestosProvider } from '../../../api';
import DeleteDialog from '../../common/dialogs/DeleteDialog';
import MasterItemOptions from '../../common/forms/MasterDetailsView/MasterItemOptions';
import EditarPiePresupuestoDialog from '../../presupuestos/EditarPiePresupuestoDialog';
import EditPresupuestoDialog from '../../presupuestos/EditPresupuestoDialog';
import GenerarPdfDialog from '../../presupuestos/GenerarPdfDialog';

export default function PresupuestoItemOptions({ item, onUpdate, onDelete }) {
    return (
        <MasterItemOptions>
            {({ closeMenu }) => {
                const options = [
                    <EditPresupuestoDialog
                        key={0}
                        button={<MenuItem onClick={closeMenu}>Editar</MenuItem>}
                        id={item.id}
                        onSave={onUpdate}
                    />,
                    <MenuItem
                        key={1}
                        onClick={(ev) => {
                            closeMenu(ev);
                            presupuestosProvider.duplicar(item.id).then(onUpdate);
                        }}
                    >
                        Duplicar
                    </MenuItem>,
                    <EditarPiePresupuestoDialog
                        key={3}
                        button={<MenuItem onClick={closeMenu}>Editar pie de presupuesto</MenuItem>}
                        presupuesto={item}
                        onSave={onUpdate}
                    />,
                    <GenerarPdfDialog
                        key={4}
                        button={<MenuItem onClick={closeMenu}>Generar PDF</MenuItem>}
                        presupuesto={item}
                    />,
                ];

                if (onDelete)
                    options.push(
                        <DeleteDialog
                            key={2}
                            text='¿Estás seguro que quieres eliminar este presupuesto?'
                            onConfirm={(ev) => {
                                closeMenu(ev);
                                onDelete();
                            }}
                            button={<MenuItem onClick={closeMenu}>Eliminar</MenuItem>}
                            confirmationText='borrar presupuesto'
                        />,
                    );

                return options;
            }}
        </MasterItemOptions>
    );
}

PresupuestoItemOptions.propTypes = {
    item: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};
