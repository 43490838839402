import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { clientesProvider } from '../../api';
import { useFetchData } from '../../hooks/useFetchData';
import Button from '../common/Button';
import { ListaServicios } from '../servicios/ListaServicios/ListaServicios';
import useDefaultServiciosColumns from '../servicios/useDefaultServiciosColumns';
import NuevaTareaDialog from '../tareas/NuevaTareaDialog';

export default function TrabajosCliente({ disabled }) {
    const { id } = useParams();

    const fetchDataFn = useCallback(() => clientesProvider.getTareas(id), [id]);
    const { data, fetchData, setData } = useFetchData(fetchDataFn);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const columns = useDefaultServiciosColumns(['cliente', 'operarios-visible']);

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            hideDelete: true,
            batchComponents: !disabled && (
                <NuevaTareaDialog
                    clienteId={id}
                    button={
                        <Button color='transparent' aria-label='Añadir servicio' startIcon={<AddIcon />}>
                            Añadir servicio
                        </Button>
                    }
                    onSave={fetchData}
                />
            ),
        }),
        [],
    );

    return (
        <ListaServicios
            tableId='trabajosCliente'
            tareas={data}
            setTareas={setData}
            columns={columns}
            options={options}
            basePath='/clientes'
            tableTitle='Lista de servicios'
        />
    );
}

TrabajosCliente.propTypes = {
    disabled: PropTypes.bool,
};
