import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { Form, Formik, useFormikContext } from 'formik';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { equiposProvider, tareasProvider } from '../../api';
import { DatosGeneralesForm } from './DatosGeneralesForm';
import { EquiposForm } from './EquiposForm';
import { PlanificacionForm } from './PlanificacionForm';
import Stepper from './Stepper';
import TareaDialogActions from './TareaDialogActions';

const NuevaTareaSchema = Yup.object().shape({
    cliente: Yup.mixed().required('Selecciona un cliente'),
    direccion: Yup.object()
        .nullable()
        .shape({
            // google_place_id: Yup.string().required('La dirección no esta validada en Google Maps'),
        })
        .required('Selecciona una direccion'),
    descripcion: Yup.string().required('Tienes que proporcionar una descripcion'),
    planificaciones: Yup.array().of(
        Yup.object().shape({
            fecha_inicio: Yup.date().typeError('La fecha de inicio debe ser una fecha').required('Requerido'),
            fecha_fin: Yup.date()
                .typeError('La fecha de fin debe ser una fecha')
                .required('Requerido')
                .min(Yup.ref('fecha_inicio'), 'La fecha de fin debe ser posterior a la de inicio'),
        }),
    ),
    checklists_tarea: Yup.array().of(
        Yup.object().shape({
            items: Yup.array()
                .of(
                    Yup.object().shape({
                        texto: Yup.string().required('Requerido'),
                    }),
                )
                .test(
                    'solo-subtareas',
                    'Si se tiene que imputar mano de obra, solo se pueden añadir tareas predefinidas',
                    function (value) {
                        if (!this.parent.contar_tiempo) return true;

                        return value.every((item) => item.subtarea_id !== null);
                    },
                )
                .min(1, 'Debe añadir al menos una tarea'),
            fecha_inicio: Yup.date().required('Requerido'),
            contar_tiempo: Yup.boolean().test('this-or-marcar-completado', 'Debes seleccionar una opción', function () {
                return this.parent.contar_tiempo || this.parent.marcar_completado;
            }),
            marcar_completado: Yup.boolean(),
        }),
    ),
});

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 1050,
            height: 840,
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
    }),
    { name: 'NuevaTareaDialog' },
);

export default function NuevaTareaDialog({
    button,
    clienteId,
    direccionId,
    proyectoId,
    equipoIds = [],
    onSave,
    isMantenimiento = false,
}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const snackbar = useSnackbar();
    const history = useHistory();

    const formButton = React.cloneElement(button, { onClick: () => setOpen(true) });

    useEffect(() => {
        setActiveStep(0);
    }, [open]);

    const { initialValues, initialErrors, initialTouched } = useMemo(() => {
        const initialValues = {
            referencia_interna: '',
            cliente: clienteId ? { id: clienteId } : null,
            direccion: direccionId ? { id: direccionId } : null,
            proyecto: proyectoId ? { id: proyectoId } : null,
            categoria: null,
            tipo: isMantenimiento ? 'MANTENIMIENTO' : 'OTROS',
            descripcion: '',
            detalles: '',
            operarios: [],
            partes_mantenimiento: [],
            planificaciones: [],
            subtareas: [],
            files: [],
            jornadas: [],
            equipoIds,
            notas_internas: '',
            mantenimientoPreventivo: null,
            urgente: false,
            libre: false,
            tiempoEstimadoHoras: 0,
            tiempoEstimadoMinutos: 0,
            checklists_tarea: [],
            documentosSeleccionados: [],
        };

        const params = {
            initialValues,
            initialErrors: {},
            initialTouched: {},
        };

        if (open) {
            try {
                NuevaTareaSchema.validateSync(initialValues, { abortEarly: false });
            } catch (err) {
                err.inner.forEach((fieldError) => {
                    params.initialErrors[fieldError.path] = fieldError.errors.join('. ');
                    params.initialTouched[fieldError.path] = true;
                });
            }
        }

        return params;
    }, [open, clienteId, direccionId, proyectoId, isMantenimiento, equipoIds]);

    return (
        <>
            {formButton}
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby='nueva-tarea-title'
                open={open}
                classes={{
                    paper: classes.root,
                }}
                fullWidth={false}
                maxWidth='lg'
            >
                <Formik
                    initialValues={initialValues}
                    initialErrors={initialErrors}
                    initialTouched={initialTouched}
                    enableReinitialize
                    validationSchema={NuevaTareaSchema}
                    onSubmit={(
                        // eslint-disable-next-line camelcase
                        {
                            cliente,
                            checklists_tarea: checklists,
                            direccion,
                            proyecto,
                            categoria,
                            files,
                            adjuntos,
                            planificaciones,
                            documentosSeleccionados,
                            tiempoEstimadoHoras,
                            tiempoEstimadoMinutos,
                            ...otherValues
                        },
                        { setSubmitting },
                    ) => {
                        const values = {
                            ...otherValues,
                            cliente_id: cliente.id,
                            direccion_id: direccion.id,
                            proyecto_id: proyecto ? proyecto.id : null,
                            categoria_id: categoria ? categoria.id : null,
                            checklists_tarea: checklists.map(({ plantilla, ...checklists }) => ({
                                ...checklists,
                                checklist_plantilla_id: plantilla ? plantilla.id : null,
                            })),
                            documentosSeleccionados: documentosSeleccionados.map((doc) => ({ id: doc.id })),
                            planificaciones: planificaciones.map(
                                // eslint-disable-next-line camelcase
                                ({ fecha_inicio, fecha_fin, allDay, vehiculo, ...rest }) => {
                                    if (allDay) {
                                        fecha_inicio.setHours(0, 0, 0, 0);
                                        fecha_fin.setHours(23, 59, 59, 0);
                                    }
                                    return {
                                        ...rest,
                                        fecha_inicio,
                                        fecha_fin,
                                        vehiculo_id: vehiculo ? vehiculo.id : null,
                                        extender_automaticamente: allDay ? rest.extender_automaticamente : false,
                                    };
                                },
                            ),
                            minutos_estimados: tiempoEstimadoHoras * 60 + tiempoEstimadoMinutos,
                        };

                        const formData = new FormData();
                        // formData.append('values', JSON.stringify(values, replacer));
                        formData.append('values', JSON.stringify(values));
                        new Array(...files).forEach((file) => formData.append('files', file));

                        tareasProvider
                            .save(formData)
                            .then((data) => {
                                onSave && onSave(data.tarea);
                                setSubmitting(false);
                                setOpen(false);
                                snackbar.showMessage('El servicio se ha creado con éxito', 'Abrir', () =>
                                    history.push(`/servicios/${data.id}`),
                                );
                            })
                            .catch((err) => {
                                console.error(err);
                                setSubmitting(false);
                                snackbar.showMessage('Ha ocurrido un error');
                            });
                    }}
                >
                    {({ isSubmitting, values: { direccion = {} } }) => {
                        return (
                            <Form className={classes.form}>
                                <DialogTitle id='nueva-tarea-title' disableTypography>
                                    <Typography variant='h6' style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                                        Añadir servicio
                                        {direccion && direccion.google_place_id === null && (
                                            <Alert
                                                variant='filled'
                                                severity='warning'
                                                style={{ fontSize: 12, padding: '4px 12px', marginLeft: 'auto' }}
                                            >
                                                Valida la dirección en Google Maps para que el GPS de tus operarios vaya
                                                al destino correcto
                                            </Alert>
                                        )}
                                    </Typography>
                                    <IconButton onClick={() => setOpen(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent style={{ marginBottom: 16 }}>
                                    <NuevaTareaForm
                                        activeStep={activeStep}
                                        setActiveStep={setActiveStep}
                                        hasProyectoSet={Boolean(proyectoId)}
                                        hasDireccionSet={Boolean(direccionId)}
                                        hasClienteSet={Boolean(clienteId)}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <TareaDialogActions
                                        disabled={isSubmitting}
                                        onCancelar={() => setOpen(false)}
                                        activeStep={activeStep}
                                        setActiveStep={setActiveStep}
                                    />
                                </DialogActions>
                            </Form>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
}

function NuevaTareaForm({ activeStep, setActiveStep, hasClienteSet, hasDireccionSet, hasProyectoSet }) {
    const { values, setFieldValue, touched, errors } = useFormikContext();
    const hiddenStyle = { display: 'none' };

    useEffect(() => {
        if (!hasDireccionSet) setFieldValue('direccion', null);
        if (!hasProyectoSet) setFieldValue('proyecto', null);
    }, [values.cliente, setFieldValue]);

    const proyectoId = values.proyecto?.id;
    const equipoIds = values.equipoIds;

    useEffect(() => {
        if (!proyectoId || !equipoIds || equipoIds.length === 0) {
            return;
        }

        equiposProvider.getByProyectoClienteAsOptions(proyectoId).then((equipos) => {
            const selectedEquipos = equipos.filter((equipo) => equipoIds.includes(equipo.id));
            const partesMantenimiento = selectedEquipos.map((equipo) => ({
                equipo_id: equipo.id,
                equipo,
                cuestionarios: [],
            }));

            setFieldValue('partes_mantenimiento', partesMantenimiento);
        });
    }, [proyectoId, equipoIds]);

    const isMantenimiento = values.tipo === 'MANTENIMIENTO';
    const lastStep = isMantenimiento ? 2 : 1;

    const steps = [
        { label: 'Datos generales', error: DatosGeneralesForm.fieldNames.some((key) => touched[key] && !!errors[key]) },
    ];
    if (isMantenimiento) steps.push({ label: 'Mantenimiento' });
    steps.push({ label: 'Planificación' });

    return (
        <>
            <Stepper steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
            <DatosGeneralesForm
                style={activeStep === 0 ? null : hiddenStyle}
                clienteDisabled={hasClienteSet}
                direccionDisabled={hasDireccionSet}
                proyectoDisabled={hasProyectoSet}
            />
            {isMantenimiento && <EquiposForm style={activeStep === 1 ? null : hiddenStyle} />}
            <PlanificacionForm style={activeStep === lastStep ? null : hiddenStyle} />
        </>
    );
}

NuevaTareaDialog.propTypes = {
    button: PropTypes.any,
    clienteId: PropTypes.any,
    direccionId: PropTypes.any,
    equipoIds: PropTypes.array,
    isMantenimiento: PropTypes.bool,
    onSave: PropTypes.any,
    proyectoId: PropTypes.any,
};

NuevaTareaForm.propTypes = {
    activeStep: PropTypes.any,
    hasClienteSet: PropTypes.any,
    hasDireccionSet: PropTypes.any,
    hasProyectoSet: PropTypes.any,
    setActiveStep: PropTypes.any,
};
