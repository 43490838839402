import Tooltip from '@material-ui/core/Tooltip';
import { formatDate } from '../../utils';
import InfoIcon from '@material-ui/icons/Info';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                fontSize: 16,
                marginLeft: theme.spacing(0.5),
            },
        },
    }),
    { name: 'EstadoLabel' },
);

export default function EstadoLabel({ estado, fechaBaja, motivoBaja }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {estado}
            {estado === 'BAJA' && (
                <Tooltip title={`El ${formatDate(fechaBaja)} por motivo "${motivoBaja}"`}>
                    <InfoIcon />
                </Tooltip>
            )}
        </div>
    );
}

EstadoLabel.propTypes = {
    estado: PropTypes.any,
    fechaBaja: PropTypes.any,
    motivoBaja: PropTypes.any,
};
