import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';
import { EditFormContext } from './EditForm';
import PropTypes from 'prop-types';

const getTabFullPath = function (tab, index, baseUrl) {
    return '' + baseUrl + (tab.props.path ? '/' + tab.props.path : index > 0 ? '/' + index : '');
};

export function TabbedForm(props) {
    const { children, match, isSubmitting } = props;
    const validTabPaths = React.Children.toArray(children).map((tab, index) => getTabFullPath(tab, index, match.url));

    // eslint-disable-next-line no-restricted-globals
    const tabsValue = validTabPaths.indexOf(location.pathname);

    return (
        <EditFormContext.Consumer>
            {(context) => (
                <React.Fragment>
                    <Tabs value={tabsValue >= 0 ? tabsValue : 0} variant='scrollable' scrollButtons='on'>
                        {React.Children.map(children, (tab, index) => {
                            const tabPath = getTabFullPath(tab, index, match.url);

                            return <Tab label={tab.props.label} component={Link} to={tabPath} />;
                        })}
                    </Tabs>
                    {isSubmitting && <LinearProgress />}
                    <div className={context.classes.root}>
                        <Switch>
                            {React.Children.map(children, (tab, index) => {
                                const tabPath = getTabFullPath(tab, index, match.path);

                                return (
                                    <Route path={tabPath} exact>
                                        {tab}
                                    </Route>
                                );
                            })}
                        </Switch>
                    </div>
                </React.Fragment>
            )}
        </EditFormContext.Consumer>
    );
}

export const FormTab = (props) => {
    return (
        <EditFormContext.Consumer>
            {(context) =>
                React.Children.map(props.children, (child) => {
                    return React.cloneElement(child, { ...context });
                })
            }
        </EditFormContext.Consumer>
    );
};

TabbedForm.propTypes = {
    children: PropTypes.any,
    isSubmitting: PropTypes.any,
    label: PropTypes.any,
    match: PropTypes.any,
};

FormTab.propTypes = {
    children: PropTypes.any,
};
