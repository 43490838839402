import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import BusinessIcon from '@material-ui/icons/Business';
import InfoIcon from '@material-ui/icons/Info';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { clientesProvider } from '../../api';
import { usePermisos } from '../../AuthState';

const useStyles = makeStyles(
    (theme) => ({
        tooltip: {
            backgroundColor: theme.palette.neutral.hudBackground,
            opacity: 1,
            padding: theme.spacing(1),
        },
        tooltipArrow: {
            color: theme.palette.neutral.hudBackground,
        },
        label: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                color: theme.palette.neutral.primary,
                paddingLeft: theme.spacing(0.5),
            },
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                fontSize: 20,
            },
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightBold,
                flex: 1,
                marginLeft: theme.spacing(1),
            },
        },
        button: {
            color: 'white',
        },
        info: {
            paddingLeft: theme.spacing(3.5),
            marginTop: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
            '& .MuiTypography-root:first-of-type': {
                fontWeight: theme.typography.fontWeightBold,
            },
        },
    }),
    { name: 'ClienteInfoTooltip' },
);

export default function ClienteInfoTooltip({ id, label }) {
    const classes = useStyles();
    const [cliente, setCliente] = useState(null);
    const history = useHistory();
    const gestionarClientes = usePermisos('gestionar_clientes');

    useEffect(() => {
        clientesProvider.getAll(id).then(setCliente);
    }, [id]);

    return (
        <Tooltip
            arrow
            interactive
            classes={{
                tooltip: classes.tooltip,
                arrow: classes.tooltipArrow,
            }}
            title={
                <>
                    <div className={classes.header}>
                        <BusinessIcon />
                        <Typography variant='subtitle2'>Cliente</Typography>
                        {gestionarClientes && (
                            <IconButton
                                onClick={() => history.push(`/clientes/${id}`)}
                                size='small'
                                className={classes.button}
                            >
                                <VisibilityIcon />
                            </IconButton>
                        )}
                    </div>
                    {cliente && (
                        <div className={classes.info}>
                            <Typography variant='subtitle2'>{cliente.nombre}</Typography>
                            <Typography variant='subtitle2'>{cliente.direccion_completa}</Typography>
                            {cliente.telefono && <Typography variant='subtitle2'>{cliente.telefono}</Typography>}
                            {cliente.email && <Typography variant='subtitle2'>{cliente.email}</Typography>}
                            {cliente.persona_contacto && (
                                <Typography variant='subtitle2'>Contacto: {cliente.persona_contacto}</Typography>
                            )}
                        </div>
                    )}
                </>
            }
        >
            <Typography variant='subtitle1' className={classes.label}>
                {label} <InfoIcon />
            </Typography>
        </Tooltip>
    );
}

ClienteInfoTooltip.propTypes = {
    id: PropTypes.any,
    label: PropTypes.any,
};
