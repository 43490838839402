import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { companiesProvider } from '../../../../../../api';
import { withButtonOpener } from '../../../../../../hooks/withButtonOpener';
import DialogForm from '../../../../../common/forms/DialogForm';

const TipoHoraSchema = Yup.object().shape({
    codigo: Yup.string().required('Requerido'),
    nombre: Yup.string().required('Requerido'),
});

const useStyles = makeStyles(
    (theme) => ({
        dialogPaper: {},
    }),
    { name: 'NewTipoHoraDialog' },
);

function NewTipoHoraDialog({ onSave, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    return (
        <DialogForm
            title='Nuevo tipo de hora'
            maxWidth='sm'
            className={classes.dialogPaper}
            FormikProps={{
                initialValues: {
                    codigo: '',
                    nombre: '',
                },
                enableReinitialize: true,
                validationSchema: TipoHoraSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    companiesProvider
                        .createTipoHora(values)
                        .then((data) => {
                            onSave && onSave(data.id);
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField name='codigo' label='Codigo' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='nombre' label='Nombre' fullWidth />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(NewTipoHoraDialog);

NewTipoHoraDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};
