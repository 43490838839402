import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from '../../../common/Button';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            backgroundColor: 'white',
            padding: theme.spacing(3, 2, 3, 3),
            gap: `${theme.spacing(1)}px`,
            alignItems: 'center',
            '&:not(:first-of-type)': {
                borderTop: `1px solid ${theme.palette.divider}`,
            },
        },
        actions: {
            display: 'flex',
            marginLeft: 'auto',
            gap: `${theme.spacing(1)}px`,
        },
    }),
    { name: 'Categoria' },
);

export default function Categoria({ categoria }) {
    const classes = useStyles();
    const history = useHistory();

    function onClickLink(url) {
        if (url.startsWith('http')) {
            window.open(url, '_blank');
        } else {
            history.push(url);
        }
    }

    return (
        <div className={classes.root}>
            <Typography variant='body2'>{categoria.titulo}</Typography>
            <div className={classes.actions}>
                <Button color='dark' onClick={() => onClickLink(categoria.url)}>
                    Abrir
                </Button>
            </div>
        </div>
    );
}

Categoria.propTypes = {
    categoria: PropTypes.shape({
        titulo: PropTypes.string,
        url: PropTypes.string,
    }),
};
