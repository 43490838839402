import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';

export const FilledIconButton = withStyles(
    (theme) => ({
        root: {
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            borderRadius: 4,
            width: 32,
            height: 32,
            '& svg': {
                width: 20,
                height: 20,
            },
            '&:active, &:focus': {
                backgroundColor: theme.palette.primary.main,
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.hoverBackground,
            },
        },
    }),
    { name: 'FilledIconButton' },
)(IconButton);