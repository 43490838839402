import { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Field, Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import * as Yup from 'yup';
import { formatDateTime, setBeginOfDay, setEndOfDay } from '../../utils';
import InfoField from '../common/fields/InfoField';
import { solicitudesProvider } from '../../api';
import { TextField } from 'formik-material-ui';
import { DateRangePicker } from '../common/fields/DateRangePicker';
import { solicitudEstadoSelectOptions } from '../../api/solicitudes';
import SelectField from '../common/fields/SelectField';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../common/Button';
import PropTypes from 'prop-types';

const VacacionesSchema = Yup.object().shape({
    motivo: Yup.string().required('Requerido'),
    fecha_inicio: Yup.date().typeError('La fecha de inicio debe ser una fecha').required('Requerido'),
    fecha_fin: Yup.date()
        .typeError('La fecha de fin debe ser una fecha')
        .required('Requerido')
        .min(Yup.ref('fecha_inicio'), 'La fecha de fin debe ser posterior a la de inicio'),
});

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 800,
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
    }),
    { name: 'EditarVacacionesDialog' },
);

export function EditarVacacionesDialog({ open, onClose, onSave, vacaciones: originalVacaciones, styles }) {
    const classes = useStyles();
    const [vacaciones, setVacaciones] = useState(originalVacaciones);

    useEffect(() => {
        setVacaciones(originalVacaciones);
    }, [originalVacaciones]);

    if (!open) return null;

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby='nueva-vacaciones-title'
            open={open}
            classes={{
                paper: classes.root,
            }}
            fullWidth={false}
            maxWidth='lg'
        >
            <Formik
                initialValues={{
                    estado: vacaciones.estado,
                    motivo: vacaciones.motivo,
                    fecha_inicio: new Date(vacaciones.fecha_inicio),
                    fecha_fin: new Date(vacaciones.fecha_fin),
                    observaciones: vacaciones.observaciones || '',
                    // allDay: isAllDay(vacaciones.fecha_inicio, vacaciones.fecha_fin),
                }}
                enableReinitialize={true}
                validationSchema={VacacionesSchema}
                onSubmit={(
                    // eslint-disable-next-line camelcase
                    { operario, allDay, fecha_inicio, fecha_fin, ...values },
                    { setSubmitting, setFieldError },
                ) => {
                    if (allDay) {
                        setBeginOfDay(fecha_inicio);
                        setEndOfDay(fecha_fin);
                    }
                    solicitudesProvider
                        .save(
                            {
                                ...values,
                                tipo: vacaciones.tipo,
                                fecha_inicio,
                                fecha_fin,
                            },
                            vacaciones.id,
                        )
                        .then((updatedVacaciones) => {
                            setSubmitting(false);
                            onSave(updatedVacaciones);
                            onClose();
                        });
                }}
            >
                {({ isSubmitting, values, errors, setFieldValue }) => {
                    return (
                        <Form className={classes.form}>
                            <DialogTitle id='parte-trabajo-title'>Editar vacaciones</DialogTitle>
                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <InfoField label='Operario' value={vacaciones.operario} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InfoField
                                            label='Fecha de la solicitud'
                                            value={formatDateTime(vacaciones.fecha)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {vacaciones.estado === 'PENDIENTE' ? (
                                            <TextField name='motivo' label='Motivo' fullWidth />
                                        ) : (
                                            <InfoField label='Motivo' value={values.motivo} />
                                        )}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DateRangePicker
                                            label='Fechas'
                                            allDay={true}
                                            fechaInicioKey='fecha_inicio'
                                            fechaFinKey='fecha_fin'
                                            helperText={
                                                vacaciones.estado !== 'PENDIENTE'
                                                    ? `No deberias editar las fechas de una solicitud ${vacaciones.estado.toLowerCase()}`
                                                    : null
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name='estado'
                                            id='estado'
                                            component={SelectField}
                                            label='Estado'
                                            fullWidth
                                            selectOptions={solicitudEstadoSelectOptions}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            name='observaciones'
                                            label='Observaciones para el operario'
                                            fullWidth
                                            multiline
                                            maxRows={5}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button color='outlined' disabled={isSubmitting} onClick={onClose}>
                                    Cancelar
                                </Button>
                                <Button type='submit' color='info' disabled={isSubmitting}>
                                    Guardar
                                </Button>
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}

export const EditarVacacionesDialogButton = withButtonOpener(EditarVacacionesDialog);

EditarVacacionesDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
    styles: PropTypes.any,
    vacaciones: PropTypes.any,
};
