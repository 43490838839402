import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { usePermisos } from '../../AuthState';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            color: theme.palette.text.primary,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    }),
    { name: 'ClienteLink' },
);

export default function ClienteLink({ id, nombre }) {
    const classes = useStyles();
    const gestionarClientes = usePermisos('gestionar_clientes');

    if (!gestionarClientes) return nombre;

    return (
        <NavLink className={classes.root} to={`/clientes/${id}`} target='_blank'>
            {nombre}
        </NavLink>
    );
}

export const ClienteLinkCell = ({
    value,
    row: {
        original: { cliente_id: clienteId },
    },
}) => <ClienteLink id={clienteId} nombre={value} />;

ClienteLinkCell.propTypes = {
    value: PropTypes.any,
    row: PropTypes.any,
};

ClienteLink.propTypes = {
    id: PropTypes.any,
    nombre: PropTypes.any,
};
