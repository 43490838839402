import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import DeleteEquipoDialog from './DeleteEquipoDialog';
import MenuItem from '@material-ui/core/MenuItem';

export function EditEquipoMenuOptions() {
    const {
        data: { id },
    } = useEditFormData();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

    const openMenu = (event) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
    };

    const closeMenu = (event) => {
        event && event.stopPropagation();
        setMenuAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={openMenu}>
                <MoreHorizIcon />
            </IconButton>
            <Menu id='simple-menu' anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={closeMenu}>
                <DeleteEquipoDialog
                    id={id}
                    closeMenu={closeMenu}
                    button={<MenuItem onClick={closeMenu}>Eliminar</MenuItem>}
                />
            </Menu>
        </>
    );
}
