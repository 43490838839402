import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
    KeyboardDatePicker as MuiKeyboardDatePicker,
    KeyboardDateTimePicker as MuiKeyboardDateTimePicker,
    KeyboardTimePicker as MuiKeyboardTimePicker,
} from '@material-ui/pickers';
import addMinutes from 'date-fns/addMinutes';
import isValid from 'date-fns/isValid';
import { useField } from 'formik';
import PropTypes from 'prop-types';

export function KeyboardDatePicker({ label, name, ...other }) {
    const [field, m, h] = useField(name);
    const currentError = m.error;
    return (
        <MuiKeyboardDatePicker
            label={label}
            clearable
            autoOk={true}
            name={field.name}
            value={field.value}
            format='dd/MM/yyyy'
            helperText={currentError}
            error={Boolean(currentError)}
            onChange={(date) => h.setValue(date, true)}
            {...other}
        />
    );
}

export function KeyboardDateTimePicker({ label, name, ...other }) {
    const [field, m, h] = useField(name);
    const currentError = m.error;
    return (
        <MuiKeyboardDateTimePicker
            label={label}
            clearable
            autoOk={true}
            ampm={false}
            name={field.name}
            value={field.value}
            format='dd/MM/yyyy HH:mm'
            helperText={currentError}
            error={Boolean(currentError)}
            onChange={(date) => h.setValue(date, true)}
            {...other}
        />
    );
}

export function KeyboardTimePicker({ label, name, ...other }) {
    const [field, m, h] = useField(name);
    const currentError = m.error;
    return (
        <MuiKeyboardTimePicker
            label={label}
            clearable
            autoOk={true}
            ampm={false}
            name={field.name}
            value={field.value}
            format='HH:mm'
            helperText={currentError}
            error={Boolean(currentError)}
            onChange={(date) => h.setValue(date, true)}
            keyboardIcon={<AccessTimeIcon />}
            {...other}
        />
    );
}

export function MarcajeTimePicker({ label, name, value, onChange, inputProps, forceDate, hasError, ...other }) {
    return (
        <MuiKeyboardTimePicker
            label={label}
            clearable
            autoOk={true}
            ampm={false}
            name={name}
            value={value}
            format='HH:mm'
            error={hasError}
            helperText={null}
            onChange={function (date) {
                if (!onChange) return;

                if (date === null || !isValid(date)) return onChange(date);

                const newDate = new Date(date);
                if (forceDate) {
                    newDate.setFullYear(forceDate.getFullYear(), forceDate.getMonth(), forceDate.getDate());
                }

                return onChange(newDate);
            }}
            keyboardIcon={<AccessTimeIcon />}
            inputProps={{
                onKeyUp: (ev) => {
                    if (ev.key === 'ArrowUp' || ev.key === 'ArrowDown') {
                        if (isValid(value)) {
                            onChange(addMinutes(value, ev.key === 'ArrowUp' ? 1 : -1));
                        }
                    }
                },
                ...inputProps,
            }}
            {...other}
        />
    );
}

KeyboardDatePicker.propTypes = {
    label: PropTypes.any,
    name: PropTypes.any,
};

KeyboardDateTimePicker.propTypes = {
    label: PropTypes.any,
    name: PropTypes.any,
};

KeyboardTimePicker.propTypes = {
    label: PropTypes.any,
    name: PropTypes.any,
};

MarcajeTimePicker.propTypes = {
    label: PropTypes.any,
    name: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.any,
    inputProps: PropTypes.any,
    forceDate: PropTypes.any,
    hasError: PropTypes.any,
};
