import { Route, Switch } from 'react-router-dom';
import ContratosMantenimientoList from './ContratosMantenimientoList';
import EditContratoMantenimientoForm from './EditContratoMantenimientoForm';
import CalendarioServicios from './CalendarioServicios/CalendarioServicios';

export default function ContratosMantenimientoModule() {
    return (
        <>
            <Switch>
                <Route path='/contratos-mantenimiento/calendario'>
                    <CalendarioServicios />
                </Route>
                <Route
                    path='/contratos-mantenimiento/:id'
                    render={({ match }) => (
                        <EditContratoMantenimientoForm basePath='/contratos-mantenimiento' match={match} />
                    )}
                />
                <Route path='/contratos-mantenimiento'>
                    <ContratosMantenimientoList />
                </Route>
            </Switch>
        </>
    );
}
