import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { estadoIncidenciaLabels } from '../../../api/tareas';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            borderRadius: 4,
            color: 'white',
            padding: theme.spacing(0, 1),
            whiteSpace: 'nowrap',
            textTransform: 'uppercase',
            width: 'fit-content',
            '&.NO_RESUELTA': {
                backgroundColor: theme.palette.error.main,
            },
            '&.RESUELTA': {
                backgroundColor: theme.palette.success.main,
            },
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
    }),
    { name: 'CompraMaterialEstadoChip' },
);

export default function IncidenciaEstadoChip({ value, style }) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, value)} style={style}>
            <Typography variant='subtitle2'>{estadoIncidenciaLabels[value]}</Typography>
        </div>
    );
}

IncidenciaEstadoChip.propTypes = {
    value: PropTypes.string.isRequired,
    style: PropTypes.object,
};
