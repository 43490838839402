import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ReplayIcon from '@material-ui/icons/Replay';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { clientesProvider } from '../../../api';
import { useFetchData } from '../../../hooks/useFetchData';
import Button from '../../common/Button';
import { SwitchWithLabel } from '../../common/fields/Switch';
import ListView from '../../common/list/ListView';
import DireccionValidadaChip from './DireccionValidadaChip';
import { EditDireccionDialog } from './EditDireccionDialog';
import { NewDireccionDialog } from './NewDireccionDialog';

export function DireccionesList({ disabled, style }) {
    const { id: clienteId } = useParams();
    const fetchDataFn = useCallback(() => clientesProvider.getDirecciones(clienteId), [clienteId]);
    const { data, fetchData } = useFetchData(fetchDataFn);

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        clientesProvider
            .deleteDireccion(clienteId, idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Identificador',
                id: 'nombre',
                accessor: (row) => `${row.nombre}${!row.activo ? ' (inactiva)' : ''}`,
            },
            {
                Header: 'Telefono',
                accessor: 'telefono',
            },
            {
                Header: 'Direccion',
                accessor: 'direccion_completa',
                Cell: DireccionValidadaChip,
            },
            {
                Header: 'Activa',
                accessor: 'activo',
                // eslint-disable-next-line react/prop-types
                Cell: ({ value }) => (value ? <DoneIcon /> : null),
                filter: 'equals',
                disableGlobalFilter: true,
                disableFilters: true,
                disableSortBy: true,
                exportValue: ({ value }) => (value ? 'Si' : 'No'),
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            addRoute: null,
            batchComponents: !disabled && <AddDireccionButton clienteId={clienteId} onSave={fetchData} />,
            useActions: true,
            tableOptions: {
                usePagination: false,
                useSorting: false,
                extraFilters: (setFilter, tableColumns) => {
                    const column = tableColumns.find((c) => c.id === 'activo');
                    const showingAll = column.filterValue === undefined;

                    return (
                        <SwitchWithLabel
                            label='Mostrar solo las direcciones activas'
                            field={{
                                name: 'solo-direcciones-activas',
                                onChange: () => column.setFilter(showingAll ? true : undefined),
                                value: !showingAll,
                            }}
                        />
                    );
                },
                initialState: {
                    filters: [{ id: 'activo', value: true }],
                },
            },
        }),
        [clienteId, fetchData],
    );

    function openMaps(direccion, placeId) {
        const params = {
            destination: direccion,
        };

        if (placeId) params.destination_place_id = placeId;

        const url = `https://www.google.com/maps/dir/?api=1&${new URLSearchParams(params).toString()}`;
        window.open(url, '_blank');
    }

    return (
        <ListView
            tableTitle='Direcciones'
            basePath={`/clientes/${clienteId}/direcciones`}
            columns={columns}
            data={data}
            options={options}
            onDelete={onDelete}
            style={style}
            extraActions={(row) => (
                <>
                    <EditDireccionButton clienteId={clienteId} onSave={fetchData} direccion={row} />
                    {!row.activo && (
                        <Tooltip title='Reactivar'>
                            <IconButton
                                size='small'
                                onClick={() =>
                                    clientesProvider
                                        .actionOnId(clienteId, `direcciones/${row.id}/reactivar`)
                                        .then(fetchData)
                                }
                            >
                                <ReplayIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title='Abrir en Google Maps'>
                        <IconButton size='small' onClick={() => openMaps(row.direccion_completa, row.google_place_id)}>
                            <LocationOnIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </>
            )}
        />
    );
}

function AddDireccionButton({ clienteId, onSave }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                color='transparent'
                aria-label='Añadir dirección'
                startIcon={<AddIcon />}
            >
                Añadir dirección
            </Button>
            <NewDireccionDialog
                open={open}
                onSave={() => {
                    onSave();
                    setOpen(false);
                }}
                onClose={() => setOpen(false)}
                clienteId={clienteId}
            />
        </>
    );
}

function EditDireccionButton({ clienteId, direccion, onSave }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <IconButton onClick={() => setOpen(true)} style={{ padding: 6 }}>
                <EditIcon fontSize='small' />
            </IconButton>
            <EditDireccionDialog
                open={open}
                onSave={() => {
                    onSave();
                    setOpen(false);
                }}
                onClose={() => setOpen(false)}
                clienteId={clienteId}
                direccion={direccion}
            />
        </>
    );
}

DireccionesList.propTypes = {
    disabled: PropTypes.bool,
    style: PropTypes.any,
};

AddDireccionButton.propTypes = {
    clienteId: PropTypes.any,
    onSave: PropTypes.any,
};

EditDireccionButton.propTypes = {
    clienteId: PropTypes.any,
    direccion: PropTypes.any,
    onSave: PropTypes.any,
};
