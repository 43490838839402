import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import StartBackupForm from './StartBackupForm';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { formatDate, formatISODate } from '../../../../utils';
import { IconButton } from '@material-ui/core';
import DownloadIcon from '../../../icons/Download';
import { companiesProvider } from '../../../../api';
import DeleteIcon from '@material-ui/icons/Delete';
import EstadoBackupChip from './EstadoBackupChip';
import { useFetchData } from '../../../../hooks/useFetchData';
import Alert from '@material-ui/lab/Alert';

export default function AjustesBackups({ classes }) {
    const fetchDataFn = useCallback(() => companiesProvider.getBackups(), []);
    const { data: backups, fetchData, setData: setBackups } = useFetchData(fetchDataFn);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Paper elevation={0}>
            <Typography className={classes.title}>Exportación de datos</Typography>
            <Grid container spacing={4} className={classes.content}>
                <Grid item xs={12}>
                    <Alert variant='filled' severity='info'>
                        1. La exportación de datos se lleva a cabo automáticamente en horario nocturno.
                        <br />
                        2. Selecciona el rango de fechas de los datos que necesitas y haz clic en Iniciar Exportación.
                        Esto pondrá en marcha el proceso.
                        <br />
                        3. Si el rango de fechas solapa mas de un mes, se creara una exportación por cada mes.
                        <br />
                        4. Se generará un fichero comprimido (formato ZIP) con los datos de clientes, operarios y
                        servicios (formato XLSX) y todas las fotos entre las fechas seleccionadas.
                    </Alert>
                </Grid>
                <Grid item xs={12}>
                    <StartBackupForm onStart={fetchData} />
                </Grid>
                <Grid item xs={12}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Fecha de creación</TableCell>
                                <TableCell>Rango de fechas</TableCell>
                                <TableCell>Estado</TableCell>
                                <TableCell>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {backups.length > 0 ? (
                                backups.map((backup) => (
                                    <TableRow key={backup.id}>
                                        <TableCell>{formatDate(backup.fecha)}</TableCell>
                                        <TableCell>
                                            Del {formatDate(backup.fecha_inicio)} al {formatDate(backup.fecha_fin)}
                                        </TableCell>
                                        <TableCell>
                                            <EstadoBackupChip value={backup.estado} />
                                        </TableCell>
                                        <TableCell>
                                            {backup.estado === 'FINALIZADO' && (
                                                <>
                                                    <IconButton
                                                        size='small'
                                                        onClick={() => {
                                                            const link = document.createElement('a');
                                                            link.download = `${formatISODate(backup.fecha)}.zip`;
                                                            link.href = backup.url;
                                                            link.click();
                                                        }}
                                                    >
                                                        <DownloadIcon />
                                                    </IconButton>

                                                    <IconButton
                                                        size='small'
                                                        onClick={() =>
                                                            companiesProvider
                                                                .action(`backups/${backup.id}`, { method: 'delete' })
                                                                .then(() =>
                                                                    setBackups((backups) =>
                                                                        backups.filter((b) => b.id !== backup.id),
                                                                    ),
                                                                )
                                                        }
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </>
                                            )}
                                            {backup.estado === 'PENDIENTE' && (
                                                <IconButton
                                                    size='small'
                                                    onClick={() =>
                                                        companiesProvider
                                                            .action(`backups/${backup.id}/cancelar`, {
                                                                method: 'post',
                                                            })
                                                            .then(fetchData)
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}
                                            {backup.estado === 'EN_PROGRESO' && (
                                                <IconButton
                                                    size='small'
                                                    onClick={() =>
                                                        companiesProvider
                                                            .action(`backups/${backup.id}/cancelar`, {
                                                                method: 'post',
                                                            })
                                                            .then(fetchData)
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}
                                            {backup.estado === 'ERROR' && (
                                                <IconButton
                                                    size='small'
                                                    onClick={() =>
                                                        companiesProvider
                                                            .action(`backups/${backup.id}`, { method: 'delete' })
                                                            .then(fetchData)
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={3}>No hay copias de seguridad</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Paper>
    );
}

AjustesBackups.propTypes = {
    classes: PropTypes.any,
};
