import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { formatTime } from '../../../../utils';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            padding: theme.spacing(2, 3),
            gap: `${theme.spacing(2)}px`,
        },
        row: {
            display: 'flex',
            alignItems: 'center',
            flex: 1,
        },
        label: {
            fontSize: 14,
            fontWeight: 500,
            marginRight: theme.spacing(1),
        },
        time: {
            fontSize: 18,
            fontWeight: 500,
        },
    }),
    { name: 'Marcaje' },
);

export default function Marcaje({ marcaje }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.row}>
                <Typography className={classes.label}>Entrada:</Typography>
                <Typography className={classes.time}>{formatTime(marcaje.hora_entrada)}</Typography>
            </div>
            <div className={classes.row}>
                <Typography className={classes.label}>Salida:</Typography>
                <Typography className={classes.time}>
                    {marcaje.hora_salida ? formatTime(marcaje.hora_salida) : '--:--'}
                </Typography>
            </div>
        </div>
    );
}

Marcaje.propTypes = {
    marcaje: PropTypes.object.isRequired,
};
