import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { tipoUnidadesShortLabels } from '../../../api/presupuestos';
import { formatCurrency, formatNumber } from '../../../utils';
import PresupuestoTreeViewLinea from '../../presupuestos/PresupuestoTreeViewTable/PresupuestoTreeViewLinea';
import { TableCell } from '../../presupuestos/PresupuestoView/Table';
import { COSTE } from '../../servicios/ParteTrabajoList/ParteTrabajoDetails';
import EditAvanceDialog from './EditAvanceDialog';

export default function AvancePresupuestoLinea(props) {
    function renderCells({ classes, linea }) {
        const porcentaje = linea.avance ? linea.avance.porcentaje : 0;
        let unidadesClass;
        if (porcentaje >= 100) {
            unidadesClass = 'done';
        } else if (porcentaje > 0) {
            unidadesClass = 'progress';
        }

        const unidadesAvance = linea.avance ? linea.avance.unidades : 0;

        let tooltipEditar = 'Editar avance';
        if (props.certificado) {
            tooltipEditar = 'No se puede editar un avance certificado';
        } else if (props.bloqueado) {
            tooltipEditar = 'No se puede modificar un avance cuando ya existe otro avance posterior';
        }

        const precioCoste = linea.avance ? linea.avance.precio_coste : linea.precio_coste;
        const precioVenta = linea.avance ? linea.avance.precio_venta : linea.precio_venta;
        const totalCoste = linea.avance ? linea.avance.total_coste_presupuesto : linea.total_coste;
        const totalVenta = linea.avance ? linea.avance.total_venta_presupuesto : linea.total_venta;

        const canEdit = linea.tipo_linea === 'PARTIDA' || linea.tipo_linea === 'CAPITULO';
        const cells = [];

        cells.push(
            <TableCell className={clsx(classes.number, classes.unidades, unidadesClass)}>
                {linea.tipo_linea === 'CAPITULO' || linea.tipo_linea === 'PARTIDA' ? (
                    <>
                        {formatNumber(unidadesAvance)} / {formatNumber(linea.unidades)}
                    </>
                ) : (
                    formatNumber(linea.unidades)
                )}
            </TableCell>,
            <TableCell className={classes.tipoUnidades}>{tipoUnidadesShortLabels[linea.tipo_unidades]}</TableCell>,
        );

        if (linea.avance) {
            cells.push(
                <TableCell className={classes.number}>{formatNumber(linea.avance.porcentaje)} %</TableCell>,
                <TableCell className={classes.number}>
                    {formatCurrency(props.currentView === COSTE ? linea.avance.total_coste : linea.avance.total_venta)}
                </TableCell>,
            );
        } else {
            cells.push(<TableCell className={classes.number} colSpan={2} />);
        }

        cells.push(
            <TableCell className={classes.number}>
                {formatCurrency(props.currentView === COSTE ? precioCoste : precioVenta)}
            </TableCell>,
            <TableCell className={classes.number}>
                {formatCurrency(props.currentView === COSTE ? totalCoste : totalVenta)}
            </TableCell>,
            <TableCell className={classes.actions}>
                {canEdit && (
                    <EditAvanceDialog
                        avanceId={props.avanceId}
                        linea={linea}
                        onSave={props.onSave}
                        button={
                            <IconButton disabled={props.certificado || props.bloqueado}>
                                <EditIcon />
                            </IconButton>
                        }
                        tooltip={tooltipEditar}
                    />
                )}
            </TableCell>,
        );

        return cells;
    }

    return (
        <PresupuestoTreeViewLinea
            renderCells={renderCells}
            isExpandable={(linea) => linea.tipo_linea === 'CAPITULO' || linea.tipo_linea === 'PARTIDA'}
            {...props}
        />
    );
}

AvancePresupuestoLinea.propTypes = {
    avanceId: PropTypes.string,
    onSave: PropTypes.func,
    currentView: PropTypes.string,
    certificado: PropTypes.bool,
    bloqueado: PropTypes.bool,
};
