import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { estadoDevolucionLabels } from '../../api/albaranes_compra';
import EstadoChip from './EstadoChip';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            '&.SIN_DEVOLUCION': {
                backgroundColor: '#A3ACBA',
            },
            '&.PTE_TRAMITAR': {
                backgroundColor: '#ECBB02',
            },
            '&.TRAMITADO': {
                backgroundColor: '#FDA729',
            },
            '&.PTE_ABONO': {
                backgroundColor: '#1BC47D',
            },
            '&.ABONADO': {
                backgroundColor: '#1BC47D',
            },
        },
    }),
    { name: 'EstadoDevolucionChip' },
);

export default function EstadoDevolucionChip({ value, style, label, className, ...props }) {
    const classes = useStyles();

    const notas = props.row.original.notas_devolucion;

    return (
        <EstadoChip
            value={value}
            label={estadoDevolucionLabels[value]}
            className={classes.root}
            notas={notas}
            {...props}
        />
    );
}

EstadoDevolucionChip.propTypes = {
    value: PropTypes.string.isRequired,
    style: PropTypes.object,
    label: PropTypes.string,
    className: PropTypes.string,
    row: PropTypes.object,
};
