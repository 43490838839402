import * as Yup from 'yup';
import { useSnackbar } from 'material-ui-snackbar-provider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { companiesProvider } from '../../../../../api';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import DialogForm from '../../../../common/forms/DialogForm';
import { withButtonOpener } from '../../../../../hooks/withButtonOpener';
import FileUploader from '../../../../common/fields/FileUploader';
import { Field } from 'formik';
import { SwitchWithLabel } from '../../../../common/fields/Switch';

const DelegacionSchema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
    nif: Yup.string().required('Requerido'),
    direccion: Yup.string().required('Requerido'),
    telefono: Yup.string().required('Requerido'),
    codigo_postal: Yup.string().required('Requerido'),
    poblacion: Yup.string().required('Requerido'),
    provincia: Yup.string().required('Requerido'),
});

const useStyles = makeStyles(
    (theme) => ({
        dialogPaper: {},
    }),
    { name: 'EditDelegacionDialog' },
);

function EditDelegacionDialog({ id, onSave, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const [delegacion, setDelegacion] = useState(null);

    useEffect(() => {
        if (!props.open) {
            setDelegacion(null);
            return;
        }

        companiesProvider.getDelegacion(id).then(setDelegacion);
    }, [id, props.open]);

    if (!delegacion) return null;

    const {
        nombre,
        nif,
        direccion,
        telefono,
        // eslint-disable-next-line camelcase
        codigo_postal,
        poblacion,
        provincia,
    } = delegacion;

    return (
        <DialogForm
            title='Editar delegación'
            maxWidth='md'
            className={classes.dialogPaper}
            FormikProps={{
                initialValues: {
                    nombre,
                    nif,
                    direccion,
                    telefono,
                    codigo_postal,
                    poblacion,
                    provincia,
                    logo_url: delegacion.logo_url,
                    logo_file: null,
                    borrar_logo: false,
                },
                enableReinitialize: true,
                validationSchema: DelegacionSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    const formData = new FormData();
                    Object.entries(values).forEach(([key, value]) => formData.append(key, value !== null ? value : ''));
                    companiesProvider
                        .updateDelegacion(id, formData)
                        .then((data) => {
                            onSave && onSave(data.id);
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {({ values, setFieldValue }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField name='nombre' label='Denominación social' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='nif' label='NIF' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='telefono' label='Telefono' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='direccion' label='Direccion' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='codigo_postal' label='Codigo postal' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='poblacion' label='Poblacion' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='provincia' label='Provincia' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <FileUploader
                            id='upload-logo-file'
                            label='Logo de la empresa'
                            value={values.logo_url}
                            file={values.logo_file}
                            setFile={(file) => setFieldValue('logo_file', file)}
                            accept='image/*'
                        />
                        {values.logo_url && (
                            <Field name='borrar_logo' component={SwitchWithLabel} label='Eliminar logo existente' />
                        )}
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(EditDelegacionDialog);

EditDelegacionDialog.propTypes = {
    id: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};
