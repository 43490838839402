import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { dataProvider } from '../../api/documentos';
import { useFetchData } from '../../hooks/useFetchData';
import Button from '../common/Button';
import BaseListView, { withTableState } from '../common/list/ListView';
import { Breadcrumbs } from './Breadcrumbs';
import DocumentoIcon from './DocumentoIcon';
import EditCarpetaDialog from './EditCarpetaDialog';
import EditFicheroDialog from './EditFicheroDialog';
import NewCarpetaDialog from './NewCarpetaDialog';
import NewFicheroDialog from './NewFicheroDialog';

const ListView = withTableState('documentos', BaseListView);

const useStyles = makeStyles(
    (theme) => ({
        root: {
            '& .MuiTable-root .MuiTableRow-root .MuiTableCell-root:first-of-type': {
                width: 40,
                paddingRight: 0,
            },
        },
    }),
    { name: 'DocumentosList' },
);

export default function DocumentosList() {
    const classes = useStyles();
    const [parents, setParents] = useState([]);
    const fetchDataFn = useCallback(
        () => dataProvider.getAll(parents.length > 0 ? parents[parents.length - 1].id : null),
        [parents],
    );
    const { data, fetchData } = useFetchData(fetchDataFn);

    function addParent(parent) {
        setParents([...parents, parent]);
    }

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        dataProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                accessor: 'content_type',
                // eslint-disable-next-line react/prop-types
                Cell: ({ row }) => <DocumentoIcon documento={row.original} />,
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
            {
                Header: 'Descripcion',
                accessor: 'descripcion',
            },
            {
                Header: 'Operarios',
                id: 'operarios',
                accessor: (row) =>
                    row.operarios.length > 0 ? row.operarios.map((op) => op.nombre).join(', ') : 'Todos',
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            hideDelete: true,
            deleteConfirmationText: 'borrar documento',
        }),
        [],
    );

    return (
        <ListView
            className={classes.root}
            title='Documentos'
            removeTitleTypography
            tableTitle={
                <Breadcrumbs
                    parents={parents}
                    onClick={(idx) => {
                        setParents(parents.slice(0, idx));
                    }}
                />
            }
            titleActions={
                <>
                    <NewCarpetaDialog
                        button={
                            <Button color='info' aria-label='Nueva carpeta' startIcon={<AddIcon />}>
                                Nueva carpeta
                            </Button>
                        }
                        onSave={fetchData}
                        parentId={parents.length > 0 ? parents[parents.length - 1].id : null}
                    />
                    <NewFicheroDialog
                        button={
                            <Button color='info' aria-label='Nuevo fichero' startIcon={<AddIcon />}>
                                Nuevo fichero
                            </Button>
                        }
                        onSave={fetchData}
                        parentId={parents.length > 0 ? parents[parents.length - 1].id : null}
                    />
                </>
            }
            basePath='/documentos'
            columns={columns}
            data={data}
            options={options}
            onDelete={onDelete}
            extraActions={(row, idx, original, { renderDelete }) =>
                row.es_carpeta ? (
                    <>
                        <EditCarpetaDialog
                            id={row.id}
                            button={
                                <Tooltip title='Editar carpeta'>
                                    <IconButton>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            onSave={fetchData}
                        />
                        {renderDelete(row)}
                        <Tooltip title='Ver carpeta'>
                            <IconButton onClick={() => addParent(row)}>
                                <ChevronRightIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                ) : (
                    <>
                        <EditFicheroDialog
                            id={row.id}
                            button={
                                <Tooltip title='Editar fichero'>
                                    <IconButton>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            onSave={fetchData}
                        />
                        {renderDelete(row)}
                        <Tooltip title='Ver fichero'>
                            <IconButton onClick={() => window.open(row.original_url, '_blank')}>
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                )
            }
        />
    );
}
