import { withButtonOpener } from '../../hooks/withButtonOpener';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../common/Button';
import PropTypes from 'prop-types';

function ExportarDialog({ open, onClose, numAlbaranes, onExportar, onExportarFacturar }) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title'>Exportar todos los albaranes pendientes de facturar</DialogTitle>
            <DialogContent>
                <DialogContentText>Se generará un único fichero Excel con {numAlbaranes} albaranes.</DialogContentText>
                <DialogContentText>Opcionalmente, puedes marcarlos también como facturados.</DialogContentText>
                <DialogContentText>
                    Recuerda que si no los marcas como facturados ahora, tendrás que hacerlo más tarde de uno en uno.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color='outlined'>
                    Cancelar
                </Button>
                <Button onClick={() => onExportar().then(onClose)} color='dark'>
                    Sólo exportar
                </Button>
                <Button onClick={() => onExportarFacturar().then(onClose)} color='info'>
                    Exportar y marcar como facturado
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withButtonOpener(ExportarDialog);

ExportarDialog.propTypes = {
    numAlbaranes: PropTypes.any,
    onClose: PropTypes.any,
    onExportar: PropTypes.any,
    onExportarFacturar: PropTypes.any,
    open: PropTypes.any,
};
