import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { TableCell, TableRow } from '../PresupuestoView/Table';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            backgroundColor: 'white',
        },
        title: {
            padding: theme.spacing(0, 2),
            justifyContent: 'space-between',
            fontWeight: theme.typography.fontWeightMedium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '50px',
            display: 'flex',
            alignItems: 'center',
            '& .MuiTypography-root': {
                flex: 1,
                fontWeight: theme.typography.fontWeightMedium,
                lineHeight: '50px',
            },
            '& .MuiIconButton-root': {
                padding: 8,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        number: {
            textAlign: 'right',
        },
        actions: {
            whiteSpace: 'nowrap',
            textAlign: 'right',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
    }),
    { name: 'PresupuestoTreeViewTable' },
);

export const COLUMN_TYPE_NUMBER = 'number';
function PresupuestoTreeViewTable(
    {
        title,
        presupuesto,
        columns,
        extraActions,
        onSave,
        LineaComponent,
        LineaComponentProps,
        LineasOptionsComponent,
        lineasFilterFn,
    },
    ref,
) {
    const classes = useStyles();

    const [expandedLineas, setExpandedLineas] = useState([]);
    const [showDescripciones, setShowDescripciones] = useState(false);

    const lineas = presupuesto.lineas;

    const allLineaIds = useMemo(() => {
        if (lineas === undefined) return [];

        function flattenLineaIds(lineas) {
            return lineas.reduce((acc, linea) => {
                if (linea.lineas) {
                    return [...acc, linea.id, ...flattenLineaIds(linea.lineas)];
                }
                return [...acc, linea.id];
            }, []);
        }
        return flattenLineaIds(lineas);
    }, [lineas]);

    useEffect(() => {
        if (ref === null) return;

        ref.current = {
            allLineaIds,
            setExpandedLineas,
            expandedLineas,
        };
    }, [ref, allLineaIds]);

    // useEffect(() => {
    //     setExpandedLineas(allLineaIds);
    // }, [allLineaIds]);

    function onToggle(lineaId) {
        setExpandedLineas((lineas) =>
            lineas.includes(lineaId) ? lineas.filter((id) => id !== lineaId) : [...lineas, lineaId],
        );
    }

    const filterFn = lineasFilterFn ?? (() => true);

    const filteredLineas = lineas ? lineas.filter(filterFn) : [];

    return (
        <Paper elevation={0} className={classes.root}>
            <Typography variant='body1' component='div' className={classes.title}>
                {title}
                <div className={classes.titleActions}>
                    {extraActions}
                    <LineasOptionsComponent
                        showDescripciones={showDescripciones}
                        setShowDescripciones={setShowDescripciones}
                        onSetAllExpanded={(expanded) => {
                            if (expanded) setExpandedLineas(allLineaIds);
                            else setExpandedLineas([]);
                        }}
                    />
                </div>
            </Typography>

            <TableContainer className={classes.container}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Orden</TableCell>
                            <TableCell>Referencia</TableCell>
                            <TableCell style={{ width: '100%' }}>Concepto</TableCell>

                            {columns.map((column, i) => (
                                <TableCell
                                    key={i}
                                    className={clsx(
                                        column.type === COLUMN_TYPE_NUMBER ? classes.number : null,
                                        column.className,
                                    )}
                                    {...column.extraProps}
                                >
                                    {column.header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredLineas.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={3 + columns.length}>
                                    {!lineas ? 'Cargando lineas...' : 'No hay lineas que mostrar'}
                                </TableCell>
                            </TableRow>
                        )}
                        {filteredLineas.map((linea, index) => (
                            <LineaComponent
                                key={index}
                                linea={linea}
                                expandedLineas={expandedLineas}
                                onToggle={onToggle}
                                onSave={onSave}
                                hideDescripcion={!showDescripciones}
                                {...LineaComponentProps}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
export default forwardRef(PresupuestoTreeViewTable);

PresupuestoTreeViewTable.propTypes = {
    title: PropTypes.string,
    presupuesto: PropTypes.any,
    columns: PropTypes.array.isRequired,
    extraActions: PropTypes.any,
    LineaComponent: PropTypes.any,
    LineaComponentProps: PropTypes.any,
    onSave: PropTypes.func,
    LineasOptionsComponent: PropTypes.any,
    lineasFilterFn: PropTypes.func,
};
