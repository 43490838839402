import { useEffect, useState } from 'react';
import { Field, useField } from 'formik';
import { tareasProvider } from '../../../api';
import { Autocomplete } from 'formik-material-ui-lab';
import MuiTextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

export default function TareaField({ error, helperText, disabled = false }) {
    const [tareasOptions, setTareasOptions] = useState([]);
    const [{ value }, , { setValue }] = useField('tarea');

    useEffect(() => {
        tareasProvider.getAsOptions().then(setTareasOptions);
    }, []);

    useEffect(() => {
        if (tareasOptions.length === 0) return;

        setValue(value ? tareasOptions.find((d) => d.id === value.id) : null);
    }, [value, tareasOptions]);

    return (
        <Field
            name='tarea'
            fullWidth
            component={Autocomplete}
            options={tareasOptions}
            getOptionSelected={(option, value) => option.id === value?.id}
            getOptionLabel={(option) =>
                `${
                    option.referencia_interna && option.referencia_interna !== ''
                        ? option.referencia_interna
                        : option.numero
                } - ${option.descripcion} (${option.cliente})`
            }
            disabled={disabled}
            renderInput={(params) => (
                <MuiTextField {...params} error={error} helperText={helperText} label='Servicio' />
            )}
        />
    );
}

TareaField.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.any,
    helperText: PropTypes.any,
};
