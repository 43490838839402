import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { isEstadoFinal } from '../../api/presupuestos';
import { formatDate } from '../../utils';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import EditFormHeader from '../common/forms/EditForm/EditFormHeader';
import EditFormHeaderItem from '../common/forms/EditForm/EditFormHeaderItem';
import EditFormHeaderItemList from '../common/forms/EditForm/EditFormHeaderItemList';
import FormTabWrapper from '../common/forms/EditForm/FormTabWrapper';
import ProyectoLink from '../dashboard/ProyectoLink';
import ClienteInfoTooltip from '../servicios/ClienteInfoTooltip';
import DireccionInfoTooltip from '../servicios/DireccionInfoTooltip';
import EditPresupuestoDialog from './EditPresupuestoDialog';
import { EditPresupuestoMenuOptions } from './EditPresupuestoMenuOptions';
import PresupuestoEstadoChip from './PresupuestoEstadoChip';
import PresupuestoTable from './PresupuestoView/PresupuestoTable';

export function EditPresupuestoFormContent() {
    const { data, fetchData } = useEditFormData();

    const fechaFinalLabel = data.estado.toLowerCase();

    return (
        <>
            <EditFormHeader
                label='Presupuesto'
                name={
                    <>
                        {data.nombre} <PresupuestoEstadoChip value={data.estado} style={{ marginLeft: 8 }} />
                    </>
                }
                actions={
                    <>
                        <EditPresupuestoDialog
                            button={
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            }
                            id={data.id}
                            onSave={fetchData}
                        />
                        <EditPresupuestoMenuOptions />
                    </>
                }
            >
                <EditFormHeaderItemList>
                    <EditFormHeaderItem label='Referencia'>{data.referencia_interna}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Cliente'>
                        <ClienteInfoTooltip id={data.cliente.id} label={data.cliente.nombre} />
                    </EditFormHeaderItem>
                    <EditFormHeaderItem label='Direccion'>
                        {data.direccion ? (
                            <DireccionInfoTooltip
                                direccion={data.direccion.direccion_completa}
                                label={data.direccion.nombre}
                            />
                        ) : (
                            'No asignada'
                        )}
                    </EditFormHeaderItem>
                    {data.proyecto && (
                        <EditFormHeaderItem label='Proyecto'>
                            <ProyectoLink
                                id={data.proyecto.id}
                                nombre={data.proyecto.nombre}
                                targetBlank={false}
                                variant='subtitle1'
                            />
                        </EditFormHeaderItem>
                    )}
                    <EditFormHeaderItem label='Fecha creación'>{formatDate(data.fecha_creacion)}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Fecha envío'>
                        {data.fecha_envio ? formatDate(data.fecha_envio) : null}
                    </EditFormHeaderItem>
                    {isEstadoFinal(data.estado) && (
                        <EditFormHeaderItem label={`Fecha ${fechaFinalLabel}`}>
                            {data.fecha_final ? formatDate(data.fecha_final) : null}
                        </EditFormHeaderItem>
                    )}
                </EditFormHeaderItemList>
            </EditFormHeader>
            <FormTabWrapper>
                <PresupuestoTable />
            </FormTabWrapper>
        </>
    );
}
