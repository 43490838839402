import { NavLink } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            color: theme.palette.text.primary,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    }),
    { name: 'ProyectoLink' },
);

export default function ProyectoLink({ id, nombre, variant = 'body', targetBlank = true }) {
    const classes = useStyles();

    return (
        <NavLink className={classes.root} to={`/proyectos/${id}`} target={targetBlank ? '_blank' : ''}>
            <Typography variant={variant}>{nombre}</Typography>
        </NavLink>
    );
}

export const ProyectoLinkCell = ({
    value,
    row: {
        original: { proyecto_id: proyectoId },
    },
}) => <ProyectoLink id={proyectoId} nombre={value} />;

ProyectoLinkCell.propTypes = {
    value: PropTypes.any,
    row: PropTypes.any,
};

ProyectoLink.propTypes = {
    id: PropTypes.any,
    nombre: PropTypes.any,
    targetBlank: PropTypes.bool,
    variant: PropTypes.string,
};
