import DataProvider from './DataProvider';

export const empty = {
    referencia: '',
    nombre: '',
    marca: '',
    modelo: '',
    numero_serie: '',
    fecha_fin_garantia: null,
    tipo_refrigerante_carburante: '',
    potencia: 0,
    tipo_maquina: '',
    ubicacion: '',
    cliente: null,
    instalacion: null,
    direccion: '',
    direccion_id: null,
    proyecto_id: null,
    instalacion_id: null,
    cliente_id: null,
    revisiones: [],
    adjuntos: [],
    activo: true,
    tipo_equipo_id: null,
};

export const periodicidadLabels = {
    SEMANAL: 'Semanal',
    QUINCENAL: 'Quincenal',
    MENSUAL: 'Mensual',
    TRIMESTRAL: 'Trimestral',
    CUATRIMESTRAL: 'Cuatrimestral',
    SEMESTRAL: 'Semestral',
    ANUAL: 'Anual',
    BIANUAL: 'Bianual',
};

export const periodicidadOptions = Object.entries(periodicidadLabels).map(([value, label]) => ({ value, label }));

class EquiposProvider extends DataProvider {
    constructor() {
        super('equipos', empty);
    }

    getTareas = async (id) => this.actionOnId(id, 'tareas', null, { method: 'get' });

    getByCliente = async (clienteId) => this.getAll(`clientes/${clienteId}`);

    getByProyecto = async (proyectoId) => this.getAll(`proyectos/${proyectoId}`);

    getByInstalacion = async (instalacionId) => this.getAll(`instalaciones/${instalacionId}`);

    getOnlyActiveAsOptions = async () => this.getAll('as_options');

    getByProyectoClienteAsOptions = async (proyectoId) => this.getAll(`proyectos/${proyectoId}/as_options`);

    getByDireccionClienteAsOptions = async (direccionId) => this.getAll(`direcciones/${direccionId}/as_options`);

    duplicar = async (id) => this.actionOnId(id, 'duplicar');

    getTipos = async () => this.action('tipos');
    addTipo = async (nombre) => this.action('tipos', { method: 'post', body: JSON.stringify({ nombre }) });
    deleteTipo = async (id) => this.action(`tipos/${id}`, { method: 'delete' });
}

export const dataProvider = new EquiposProvider();
