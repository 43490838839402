import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { tareasProvider } from '../../../api';
import Button from '../../common/Button';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterDetailsView from '../../common/forms/MasterDetailsView/MasterDetailsView';
import GenerarAlbaranForTareaDialog from '../ParteTrabajoList/GenerarAlbaranForTareaDialog';
import AlbaranDetails from './AlbaranDetails';
import AlbaranItem from './AlbaranItem';
import AlbaranListOptions from './AlbaranListOptions';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: `${theme.spacing(3)}px`,
            '&>div': {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: `${theme.spacing(2)}px`,
            },
        },
        actions: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        empty: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            margin: theme.spacing(6, 'auto'),
            gap: `${theme.spacing(3)}px`,
            width: 430,
        },
        emptyText: {
            color: theme.palette.neutral.grey4,
        },
    }),
    { name: 'ParteTrabajoList' },
);

export default function AlbaranList() {
    const { data: tarea } = useEditFormData();

    return (
        <BaseAlbaranList
            actions={
                <>
                    <GenerarAlbaranForTareaDialog
                        button={
                            <IconButton disabled={tarea.jornadas.every((jornada) => jornada.albaran_id !== null)}>
                                <AddIcon />
                            </IconButton>
                        }
                    />
                    <AlbaranListOptions tareaId={tarea.id} />
                </>
            }
        />
    );
}

export function BaseAlbaranList({ actions }) {
    const classes = useStyles();

    const { data: albaranes, fetchData } = useEditFormData('albaranes');
    const { data: jornadas } = useEditFormData('jornadas');

    return (
        <MasterDetailsView
            title='Albaranes de servicio'
            masterComponent={AlbaranItem}
            masterExtraProps={(albaran, index) => ({
                onDelete: () => {
                    tareasProvider
                        .actionOnId(albaran.tarea_id, 'albaranes', { ids: [albaran.id] }, { method: 'delete' })
                        .then(fetchData);
                },
            })}
            detailsComponent={AlbaranDetails}
            items={albaranes}
            emptyComponent={
                <Paper elevation={0}>
                    <div className={classes.empty}>
                        <img src='/images/empty-partes-trabajo.png' />
                        <Typography variant='h1'>Albaranes de servicio</Typography>
                        <Typography variant='body2' className={classes.emptyText}>
                            Desde aquí podràs ver los albaranes de servicio que tus operarios hayan realizado. También
                            puedes generar un albarán de servicio manualmente agrupando los partes de trabajo.
                        </Typography>
                        {jornadas.length > 0 ? (
                            <GenerarAlbaranForTareaDialog
                                button={<Button color='info'>Generar albarán de servicio</Button>}
                            />
                        ) : (
                            <Typography variant='body2' className={classes.emptyText}>
                                No se pueden crear albaranes de servicio si no hay ningún parte de trabajo.
                            </Typography>
                        )}
                    </div>
                </Paper>
            }
            actions={actions}
        />
    );
}

BaseAlbaranList.propTypes = {
    actions: PropTypes.any,
};
