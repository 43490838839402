import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Field, FieldArray, useField } from 'formik';
import PropTypes from 'prop-types';
import SelectField from '../../../common/fields/SelectField';
import { telematelTipoLineaGastoOptions, telematelTipoLineaOperarioOptions } from '../../../../api/companies';
import { operariosProvider } from '../../../../api';
import { useEffect, useMemo, useState } from 'react';
import Button from '../../../common/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

// export const PreferenciasTelematelSchema = Yup.object().shape({
//     gastos: Yup.object().shape({
//         tipo_linea: Yup.string().required('Requerido'),
//     }),
//     tipo_linea_operarios: Yup.string().required('Requerido'),
// });

function EditPreferenciasTelematel({ classes }) {
    const [{ value }, ,] = useField('preferencias_telematel');

    const [operarios, setOperarios] = useState([]);

    useEffect(() => {
        operariosProvider
            .getAllAsOptions()
            .then((operarios) => setOperarios(Object.fromEntries(operarios.map(({ id, nombre }) => [id, nombre]))));
    }, []);

    const missingOperarioIds = useMemo(() => {
        if (value === null) return [];

        const currentIds = value.operarios.map((o) => o.id);
        return Object.keys(operarios).filter((id) => !currentIds.includes(id));
    }, [value, operarios]);

    const hasMissingOperarios = missingOperarioIds.length > 0;

    if (value === null) return null;

    return (
        <>
            <Paper elevation={0}>
                <Typography className={classes.title}>Configuración general</Typography>
                <Grid container spacing={4} className={classes.content}>
                    <Grid item xs={12}>
                        <Field
                            name='preferencias_telematel.gastos.tipo_linea'
                            id='telematel_gastos_tipo_linea'
                            component={SelectField}
                            label='Tipo de linea para gastos'
                            fullWidth
                            selectOptions={telematelTipoLineaGastoOptions}
                            blankItem='Selecciona un tipo'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            name='preferencias_telematel.gastos.codigo_desplazamiento'
                            label='Código desplazamiento'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField name='preferencias_telematel.gastos.codigo_dietas' label='Código dietas' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            name='preferencias_telematel.gastos.codigo_parking'
                            label='Código parking'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name='preferencias_telematel.tipo_linea_operarios'
                            id='telematel_tipo_linea_operarios'
                            component={SelectField}
                            label='Tipo de linea para mano de obra'
                            blankItem='Selecciona un tipo'
                            fullWidth
                            selectOptions={telematelTipoLineaOperarioOptions}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Paper elevation={0}>
                <Typography className={classes.title}>Operarios</Typography>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Codigo</TableCell>
                            <TableCell>Categoría</TableCell>
                            <TableCell>Tipo hora</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <FieldArray name='preferencias_telematel.operarios'>
                            {({ remove, push }) => (
                                <>
                                    {value.operarios.map((operario, i) => (
                                        <TableRow key={i}>
                                            <TableCell style={{ width: '50%' }}>{operarios[operario.id]}</TableCell>
                                            <TableCell>
                                                <TextField
                                                    name={`preferencias_telematel.operarios[${i}].codigo`}
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    name={`preferencias_telematel.operarios[${i}].categoria`}
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    name={`preferencias_telematel.operarios[${i}].tipo_hora`}
                                                    fullWidth
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {hasMissingOperarios && (
                                        <TableRow>
                                            <TableCell colSpan={4}>
                                                <Button
                                                    color='info'
                                                    onClick={() => {
                                                        missingOperarioIds.forEach((id) =>
                                                            push({
                                                                id,
                                                                codigo: '',
                                                                categoria: '',
                                                                tipo_hora: '',
                                                            }),
                                                        );
                                                    }}
                                                >
                                                    Añadir operarios que faltan
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </>
                            )}
                        </FieldArray>
                    </TableBody>
                </Table>
            </Paper>
        </>
    );
}

export default EditPreferenciasTelematel;

EditPreferenciasTelematel.propTypes = {
    classes: PropTypes.any,
};
