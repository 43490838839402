import ReplayIcon from '@material-ui/icons/Replay';
import PropTypes from 'prop-types';
import { formatDateTime } from '../../../utils';
import ItemLabel from '../../common/forms/MasterDetailsViewOld/ItemLabel';

export default function SincronizadoLabel({ value }) {
    return (
        <ItemLabel
            icon={<ReplayIcon />}
            label={value ? 'Sincronizado' : 'No sincronizado'}
            tooltip={value ? `Sincronizado el ${formatDateTime(value)}` : ''}
            disabled={!value}
        />
    );
}

SincronizadoLabel.propTypes = {
    value: PropTypes.any,
};
