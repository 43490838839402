import { Route, Switch } from 'react-router-dom';
import ProyectosList from './ProyectosList';
import EditProyectoForm from './EditProyectoForm';

export default function ProyectosModule() {
    return (
        <>
            <Switch>
                <Route
                    path='/proyectos/:id'
                    render={({ match }) => <EditProyectoForm basePath='/proyectos' match={match} />}
                />
                <Route path='/proyectos'>
                    <ProyectosList />
                </Route>
            </Switch>
        </>
    );
}
