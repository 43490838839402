import DataProvider from './DataProvider';

export const empty = {
    referencia: '',
    descripcion: '',
    precio_unitario: 0,
    precio_unitario_coste: 0,
    descuento: 0,
    descuento_coste: 0,
    marca: null,
};

class MaterialesProvider extends DataProvider {
    constructor() {
        super('materiales_internos', empty);
    }

    getAsOptions = async () => {
        return this.getAll('as_options');
    };
}

export const dataProvider = new MaterialesProvider();
