import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useState } from 'react';

const CopyPasteContext = createContext();

export function CopyPasteProvider({ children }) {
    const [items, setItems] = useState([]);

    const copy = useCallback((data) => {
        setItems(data);
    }, []);

    return (
        <CopyPasteContext.Provider
            value={{
                copy,
                items,
                hasItems: items.length > 0,
                clear: () => setItems([]),
            }}
        >
            {children}
        </CopyPasteContext.Provider>
    );
}

export function useCopyPaste() {
    return useContext(CopyPasteContext);
}

CopyPasteProvider.propTypes = {
    children: PropTypes.node,
};
