import SendIcon from '@material-ui/icons/Send';
import PropTypes from 'prop-types';
import { formatDateTime } from '../../../utils';
import ItemLabel from '../../common/forms/MasterDetailsView/ItemLabel';

export default function EnviadoLabel({ value }) {
    return (
        <ItemLabel
            icon={<SendIcon />}
            label={value ? 'Enviado' : 'No enviado'}
            tooltip={`Enviado el ${formatDateTime(value)}`}
            color={value ? null : 'error'}
        />
    );
}

EnviadoLabel.propTypes = {
    value: PropTypes.any,
};
