import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import ColorSwatch from './ColorSwatch';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            flexWrap: 'wrap',
        },
    }),
    { name: 'ColorEditor' },
);

export default function ColorBgTextEditor({ onSelect, colors, selectedColor }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {colors.map((color, i) => (
                <ColorSwatch
                    key={i}
                    color={color}
                    selected={selectedColor === i}
                    onClick={() => onSelect(i === 0 ? { color_bg: null, color_fg: null } : color)}
                />
            ))}
        </div>
    );
}

ColorBgTextEditor.propTypes = {
    colors: PropTypes.any,
    onSelect: PropTypes.any,
    selectedColor: PropTypes.any,
};
