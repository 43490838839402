import PropTypes from 'prop-types';
import { forwardRef, useCallback, useEffect, useMemo } from 'react';
import { tareasProvider } from '../../api';
import { useFetchData } from '../../hooks/useFetchData';
import { ListaServicios } from '../servicios/ListaServicios/ListaServicios';
import useDefaultServiciosColumns from '../servicios/useDefaultServiciosColumns';

function TareasSinPlanificar({ onTareaChanged, style, hideTitle = false, options: otherOptions, ...props }, ref) {
    const fetchDataFn = useCallback(() => tareasProvider.getSinPlanificar(), []);
    const { data, fetchData, setData } = useFetchData(fetchDataFn);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (ref === null) return;

        ref.current = {
            refresh: fetchData,
        };
    }, [ref, fetchData]);

    const columns = useDefaultServiciosColumns(['revisado', 'facturado', 'operarios-visible']);

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            hideDelete: true,
            ...otherOptions,
            tableOptions: {
                fillEmptySpace: false,
                ...otherOptions?.tableOptions,
            },
        }),
        [otherOptions],
    );

    return (
        <ListaServicios
            tableId='tareasSinPlanificar'
            tareas={data}
            setTareas={setData}
            columns={columns}
            options={options}
            style={{
                marginBottom: 24,
                ...style,
            }}
            basePath='/clientes'
            tableTitle={hideTitle ? null : 'Servicios pendientes de planificar'}
            onTareaChanged={() => {
                fetchData();
                onTareaChanged && onTareaChanged();
            }}
            {...props}
        />
    );
}

export default forwardRef(TareasSinPlanificar);

TareasSinPlanificar.propTypes = {
    hideTitle: PropTypes.bool,
    onTareaChanged: PropTypes.any,
    style: PropTypes.any,
    options: PropTypes.any,
};
