import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';

const useStyle = makeStyles(
    (theme) => ({
        root: {
            display: 'inline-flex',
            alignItems: 'center',
            gap: `${theme.spacing(0.5)}px`,
        },
    }),
    { name: 'InfoTooltip' },
);

export default function InfoTooltip({ label, content }) {
    const classes = useStyle();

    return (
        <Tooltip title={content} arrow>
            <span className={classes.root}>
                {label}
                <InfoIcon fontSize='small' />
            </span>
        </Tooltip>
    );
}

InfoTooltip.propTypes = {
    label: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
};
