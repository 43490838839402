import { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogEditor from '../../common/forms/DialogEditor';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        fields: {
            gap: `${theme.spacing(2)}px`,
        },
        field: {},
    }),
    { name: 'TimeEditor' },
);

export default function PreguntaEditor({ preguntaId, texto: initialTexto, onSave, ...props }) {
    const classes = useStyles();
    const [texto, setTexto] = useState(initialTexto);

    useEffect(() => {
        if (!initialTexto) {
            setTexto('');
            return;
        }

        setTexto(initialTexto);
    }, [initialTexto]);

    return (
        <DialogEditor
            title={preguntaId ? 'Editar pregunta' : 'Añadir pregunta'}
            onSave={() => onSave(texto)}
            {...props}
        >
            <div className={classes.fields}>
                <TextField
                    label='Texto'
                    value={texto}
                    onChange={(ev) => setTexto(ev.target.value)}
                    className={classes.field}
                    fullWidth
                />
            </div>
        </DialogEditor>
    );
}

PreguntaEditor.propTypes = {
    texto: PropTypes.any,
    onSave: PropTypes.any,
    preguntaId: PropTypes.any,
};
