import SvgIcon from '@material-ui/core/SvgIcon';

export default function CertificadoFichajesIcon(props) {
    return (
        <SvgIcon viewBox='0 0 24 24' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.82 4H19C20.1 4 21 4.9 21 6V20C21 21.1 20.1 22 19 22H5C3.9 22 3 21.1 3 20V6C3 4.9 3.9 4 5 4H9.18C9.6 2.84 10.7 2 12 2C13.3 2 14.4 2.84 14.82 4ZM11 5C11 5.55 11.45 6 12 6C12.55 6 13 5.55 13 5C13 4.45 12.55 4 12 4C11.45 4 11 4.45 11 5ZM7 9.33333L12.25 7L17.5 9.33333V12.8333C17.5 16.0708 15.26 19.0983 12.25 19.8333C9.24 19.0983 7 16.0708 7 12.8333V9.33333ZM8.75 14L11.0833 16.3333L15.75 11.6667L14.9275 10.8383L11.0833 14.6825L9.5725 13.1775L8.75 14Z'
            />
        </SvgIcon>
    );
}
