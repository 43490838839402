import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import addMinutes from 'date-fns/addMinutes';
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes';
import { FieldArray, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import useAuthState from '../../AuthState';
import Button from '../common/Button';
import { SwitchWithLabelField } from '../common/fields/Switch';
import PlanificacionFormItem from './PlanificacionFormItem';
import PlanificacionFormItemReadOnly from './PlanificacionFormItemReadOnly';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
            overflowY: 'auto',
        },
        planificacion: {
            display: 'flex',
            gap: `${theme.spacing(4)}px`,
            padding: theme.spacing(2),
            borderRadius: 8,
            border: `1px solid ${theme.palette.neutral.grey1}`,
        },
        column: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            '&:last-of-type': {
                paddingRight: theme.spacing(4),
            },
        },
        operariosAsignados: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
            marginTop: theme.spacing(2),
            '& .MuiTypography-root:first-of-type': {
                fontWeight: 'normal',
                color: 'rgba(0, 0, 0, 0.54)',
                marginBottom: theme.spacing(-1),
            },
        },
        operarioAsignadoError: {
            color: theme.palette.error.main,
            marginLeft: theme.spacing(2),
        },
        deleteButton: {
            position: 'absolute',
            top: 0,
            right: 0,
        },
        addButton: {
            marginLeft: 'auto',
        },
        emptyWrapper: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        empty: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            margin: 'auto',
            gap: `${theme.spacing(3)}px`,
            width: 430,
        },
        emptyText: {
            color: theme.palette.neutral.grey4,
        },
    }),
    { name: 'PlanificacionForm' },
);

export function createEmptyPlanificacion() {
    const now = roundToNearestMinutes(addMinutes(new Date(), 8), { nearestTo: 15 });

    return {
        fecha_inicio: now,
        fecha_fin: addMinutes(now, 60),
        fecha_visita: null,
        fecha_inicio_jornada: null,
        usarFechaInicioJornada: false,
        allDay: false,
        operarios: [],
        vehiculo: null,
        confirmada: false,
        extender_automaticamente: false,
        editable: true,
    };
}

export function PlanificacionForm({ style, disabled }) {
    const classes = useStyles();
    const {
        values: { jornadas, planificaciones, mantenimientoPreventivo, libre },
    } = useFormikContext();

    const {
        userInfo: {
            preferencias: { usar_servicios_libres: usarServiciosLibres },
        },
    } = useAuthState();

    const planificacionesFixedOperarioIds = useMemo(() => {
        const operarioIds = [];

        jornadas.forEach(({ operario_id: operarioId }) => {
            if (operarioIds.includes(operarioId)) return;
            operarioIds.push(operarioId);
        });

        const planificacionesFixedOperarioIds = [];
        planificaciones
            .slice()
            .reverse()
            .forEach((planificacion) => {
                const planificacionFixedOperarioIds = [];
                planificacion.operarios.forEach((operarioPlanificacion) => {
                    const idx = operarioIds.indexOf(operarioPlanificacion.operario.id);
                    if (idx < 0) return;

                    planificacionFixedOperarioIds.push(operarioIds[idx]);
                    operarioIds.splice(idx, 1);
                });
                planificacionesFixedOperarioIds.push(planificacionFixedOperarioIds);
            });

        return planificacionesFixedOperarioIds.reverse();
    }, [jornadas, planificaciones]);

    return (
        <div className={classes.root} style={style}>
            <FieldArray name='planificaciones'>
                {({ remove, insert }) => {
                    function addPlanificacion() {
                        insert(0, createEmptyPlanificacion());
                    }

                    return planificaciones.length === 0 ? (
                        <div className={classes.emptyWrapper}>
                            <div className={classes.empty}>
                                <img src='/images/empty-partes-trabajo.png' />
                                <Typography variant='h1'>Servicio planificado</Typography>
                                <Typography variant='body2' className={classes.emptyText}>
                                    Puedes asignar este servicio en varios rangos de fechas a distintos grupos de
                                    operarios. Además, también puedes controlar qué operarios ven en su móvil el
                                    servicio.
                                </Typography>
                                <Button
                                    color='info'
                                    startIcon={<AddIcon />}
                                    onClick={addPlanificacion}
                                    disabled={libre}
                                >
                                    Añadir planificación
                                </Button>
                            </div>
                            {usarServiciosLibres && (
                                <div className={classes.empty}>
                                    <img src='/images/empty-partes-trabajo.png' />
                                    <Typography variant='h1'>Servicio libre</Typography>
                                    <Typography variant='body2' className={classes.emptyText}>
                                        Puedes dejar que los operarios se planifiquen este servicio desde su aplicación
                                        móvil. Ellos quedarán directamente con el cliente y se asignarán a ellos mismos
                                        y opcionalmente a algún compañero.
                                    </Typography>
                                    <SwitchWithLabelField
                                        name='libre'
                                        label='Dejar que los operarios se asignen este servicio'
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            <Button
                                color='primary'
                                startIcon={<AddIcon />}
                                className={classes.addButton}
                                onClick={addPlanificacion}
                            >
                                Añadir planificación
                            </Button>
                            {planificaciones.map((planificacion, i) => {
                                const deleteDisabled =
                                    planificacionesFixedOperarioIds[i].length > 0 || Boolean(mantenimientoPreventivo);

                                if (planificacion.editable) {
                                    return (
                                        <PlanificacionFormItem
                                            key={planificacion.id ?? `planificacion-${i}`}
                                            planificacion={planificacion}
                                            fixedOperarioIds={planificacionesFixedOperarioIds[i]}
                                            disabled={disabled}
                                            onDelete={() => remove(i)}
                                            fieldKey={`planificaciones.${i}`}
                                            deleteDisabled={deleteDisabled}
                                        />
                                    );
                                }

                                return (
                                    <PlanificacionFormItemReadOnly
                                        key={planificacion.id ?? `planificacion-${i}`}
                                        planificacion={planificacion}
                                        disabled={disabled}
                                        onDelete={() => remove(i)}
                                        fieldKey={`planificaciones.${i}`}
                                        deleteDisabled={deleteDisabled}
                                    />
                                );
                            })}
                        </>
                    );
                }}
            </FieldArray>
        </div>
    );
}

PlanificacionForm.propTypes = {
    disabled: PropTypes.any,
    style: PropTypes.any,
};
