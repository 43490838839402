import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { useMemo } from 'react';
import { operariosProvider } from '../../../api';
import { formatPeriodo } from '../../../utils';
import DeleteDialog from '../../common/dialogs/DeleteDialog';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import ListView from '../../common/list/ListView';

export default function CalendariosTable() {
    const { id: operarioId, data: calendarios, fetchData } = useEditFormData('calendarios');

    const calendariosData = useMemo(() => {
        return calendarios.map((calendario, i) => ({
            ...calendario,
            fin: calendarios[i - 1]?.fecha,
            inicio: calendario.fecha,
        }));
    }, [calendarios]);

    const columns = useMemo(
        () => [
            {
                Header: 'Periodo',
                id: 'periodo',
                accessor: (row) => formatPeriodo(row.inicio, row.fin),
            },
            {
                Header: 'Calendario',
                accessor: 'calendario.nombre',
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            addRoute: null,
            useActions: true,
            tableOptions: {
                usePagination: false,
                useSorting: false,
            },
        }),
        [],
    );

    return (
        <ListView
            tableTitle='Historial de cambios de calendario'
            columns={columns}
            data={calendariosData}
            options={options}
            extraActions={(row, idx) => (
                <DeleteDialog
                    text='¿Estás seguro que quieres eliminar este cambio de calendario?'
                    onConfirm={() => {
                        operariosProvider.deleteCambioCalendario(operarioId, idx).then(fetchData);
                    }}
                    button={
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    }
                />
            )}
        />
    );
}
