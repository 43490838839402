import { Drawer } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { forwardRef, useMemo } from 'react';
import useAuthState from '../../AuthState';
import TareasSinPlanificar from '../dashboard/TareasSinPlanificar';
import DraggableTableRowHandle from './DraggableTableRowHandle';

export const SIN_PLANIFICAR_HEIGHT = 400;

const useStyles = makeStyles(
    (theme) => ({
        drawer: {
            flexShrink: 0,
        },
        drawerPaper: (props) => {
            const style = {
                height: SIN_PLANIFICAR_HEIGHT,
                filter: 'drop-shadow(0px 4px 10px #c4c4c4)',
                borderTop: 'none',
            };

            if (props.enableDnd) {
                style['& .MuiTableCell-root.MuiTableCell-sizeSmall:first-of-type'] = {
                    width: 0,
                    padding: theme.spacing(0, 0, 0, 1),
                };
            }

            return style;
        },
    }),
    { name: 'TareasSinPlanificarDrawer' },
);

function TareasSinPlanificarDrawer({ showPendientesPlanificar, onClosePendientesPlanificar }, ref) {
    const {
        userInfo: { preferencias: { usar_dnd_calendario_planificacion: usarDndCalendarioPlanificacion } = {} },
    } = useAuthState();

    const classes = useStyles({ enableDnd: usarDndCalendarioPlanificacion });

    const tareasSinPlanificarOptions = useMemo(
        () => ({
            canExportCsv: false,
            canToggleColumns: false,
            batchComponents: (
                <IconButton onClick={onClosePendientesPlanificar} style={{ marginRight: -16 }}>
                    <CloseIcon />
                </IconButton>
            ),
            tableOptions: {
                // stickyHeader: true,
                tableFixedHeight: SIN_PLANIFICAR_HEIGHT - 50,
            },
        }),
        [],
    );

    return (
        <Drawer
            open={showPendientesPlanificar}
            className={classes.drawer}
            variant='persistent'
            anchor='bottom'
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <TareasSinPlanificar
                ref={ref}
                hideTitle
                style={{ marginBottom: 0 }}
                tableTitle='Servicios pendientes de planificar'
                options={tareasSinPlanificarOptions}
                DraggableComponent={usarDndCalendarioPlanificacion ? DraggableTableRowHandle : null}
            />
        </Drawer>
    );
}

export default forwardRef(TareasSinPlanificarDrawer);

TareasSinPlanificarDrawer.propTypes = {
    showPendientesPlanificar: PropTypes.any,
    onClosePendientesPlanificar: PropTypes.any,
};
