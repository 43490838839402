import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import Button from '../../common/Button';
import EditChecklistDialog from './EditChecklistDialog/EditChecklistDialog';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            gap: `${theme.spacing(1)}px`,
        },
        icon: {
            fontSize: 20,
        },
        tooltip: {
            backgroundColor: theme.palette.neutral.hudBackground,
            opacity: 1,
            minWidth: 420,
            padding: 0,
        },
        tooltipArrow: {
            color: theme.palette.neutral.hudBackground,
        },
        info: {
            padding: theme.spacing(0.5, 1),
            display: 'flex',
            flexDirection: 'column',
            borderTop: `1px solid ${theme.palette.neutral.hudBackgroundDivider}`,
            '&:first-of-type': {
                border: 'none',
            },
        },
        error: {
            color: theme.palette.error.main,
        },
    }),
    { name: 'ChecklistsFormControl' },
);

export function getDefaultChecklist(fechaInicio) {
    return {
        fecha_inicio: fechaInicio ?? new Date(),
        plantilla: null,
        checklist_plantilla_id: null,
        items: [],
        contar_tiempo: false,
        marcar_completado: false,
    };
}

export default function ChecklistsFormControl({ name }) {
    const classes = useStyles();
    const [, { value, error }, { setValue }] = useField(name);

    const hasError = Boolean(error);
    return (
        <>
            <FormControl fullWidth error={hasError}>
                <InputLabel shrink>Listas de tareas</InputLabel>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 16,
                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                    }}
                >
                    <Typography className={clsx(classes.root, hasError ? classes.error : null)}>
                        {value.length} listas añadidas {hasError ? 'con errores' : ''}
                    </Typography>

                    <EditChecklistDialog
                        button={
                            <Button
                                onClick={() => {
                                    if (value.length > 0) return;

                                    setValue([getDefaultChecklist()]);
                                }}
                                color='primary'
                            >
                                Configurar
                            </Button>
                        }
                        onDelete={() => {
                            setValue([]);
                        }}
                        name={name}
                    />
                </div>
                {/* {error && <FormHelperText id='component-error-text'>{error}</FormHelperText>} */}
            </FormControl>
        </>
    );
}

ChecklistsFormControl.propTypes = {
    name: PropTypes.any,
};
