import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import * as Yup from 'yup';
import { tareasProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import { KeyboardDateTimePicker } from '../../common/fields/KeyboardDatePicker';
import DialogForm from '../../common/forms/DialogForm';

const useStyles = makeStyles(
    (theme) => ({
        container: {
            marginTop: theme.spacing(1),
        },
    }),
    { name: 'FacturadoEditor' },
);

const FechaEditorSchema = Yup.object().shape({
    fecha_facturacion: Yup.date().typeError('Fecha inválida').required('Requerido'),
});

function FacturadoEditor({ albaran, onSave, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const initialValues = useMemo(
        () => ({
            fecha_facturacion: albaran.fecha_facturacion ? new Date(albaran.fecha_facturacion) : new Date(),
            numero_factura: albaran.numero_factura ?? '',
        }),
        [albaran],
    );
    return (
        <DialogForm
            title='Editar datos de facturación del albarán de servicio'
            FormikProps={{
                initialValues,
                enableReinitialize: true,
                validationSchema: FechaEditorSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    tareasProvider
                        .action(`albaranes/${albaran.id}`, {
                            method: 'post',
                            body: JSON.stringify(values),
                        })
                        .then(() => {
                            props.onClose();
                            setSubmitting(false);
                            onSave();
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [key, value] of Object.entries(err.message)) {
                                    setFieldError(key, value[0]);
                                }
                            } else {
                                snackbar.showMessage('Ha ocurrido un error');
                            }

                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2} className={classes.container}>
                    <Grid item xs={6}>
                        <KeyboardDateTimePicker label='Fecha facturación' name='fecha_facturacion' autoOk />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name='numero_factura'
                            label='Número factura'
                            InputLabelProps={{ shrink: true }}
                            placeholder='Aún no tiene'
                        />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

FacturadoEditor.propTypes = {
    albaran: PropTypes.object,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};

export default withButtonOpener(FacturadoEditor);
