import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { tareasProvider } from '../../../api';
import useAuthState from '../../../AuthState';
import { TareaActions } from '../../clientes/TareaActions';
import BaseListView, { withTableState } from '../../common/list/ListView';

export function ListaServicios({
    tareas,
    setTareas,
    columns: defaultColumns,
    options: defaultOptions,
    tableId,
    onTareaChanged,
    extraActions,
    TareaActionsProps,
    DraggableComponent = null,
    ...props
}) {
    const {
        userInfo: {
            preferencias: {
                usar_referencias_tareas: usarReferencias,
                usar_preparacion_materiales: usarPreparacionMateriales,
            } = {},
        },
    } = useAuthState();

    const ListView = useMemo(() => withTableState(tableId, BaseListView), [tableId]);

    const columns = useMemo(() => {
        const columns = [];

        if (DraggableComponent) {
            columns.push({
                id: 'draggable',
                Cell: DraggableComponent,
            });
        }
        //
        // if (usarReferencias)
        //     columns.push({
        //         Header: 'Ref. interna',
        //         accessor: 'referencia_interna',
        //     });

        columns.push(...defaultColumns);
        //
        // if (usarPreparacionMateriales)
        //     columns.push({
        //         Header: 'Estado preparacion',
        //         accessor: 'estado_preparacion',
        //         Cell: PreparacionMaterialEstadoChip,
        //         Filter: SelectColumnFilter,
        //         filter: 'equals',
        //         filterOptions: {
        //             options: estadoPreparacionOptions,
        //             includeAllOption: {
        //                 value: 'todos',
        //                 label: 'Todos',
        //             },
        //         },
        //         exportValue: ({ value }) => estadoPreparacionLabels[value],
        //     });

        return columns;
    }, [DraggableComponent, defaultColumns, usarReferencias, usarPreparacionMateriales]);

    function updateTarea(tareaId, newTarea, replace = true) {
        setTareas((tareas) => {
            if (!newTarea) return tareas.filter((tarea) => tarea.id !== tareaId);

            const tareaIdx = tareas.findIndex((t) => t.id === tareaId);

            const updatedTarea = replace ? { ...newTarea } : { ...tareas[tareaIdx], ...newTarea };

            return [...tareas.slice(0, tareaIdx), updatedTarea, ...tareas.slice(tareaIdx + 1)];
        });
    }

    const options = useMemo(() => ({ ...defaultOptions, deleteConfirmationText: 'borrar servicio' }), [defaultOptions]);

    return (
        <ListView
            columns={columns}
            data={tareas}
            options={options}
            extraActions={(row) => (
                <>
                    {extraActions && extraActions(row)}
                    <TareaActions
                        {...TareaActionsProps}
                        tarea={row}
                        onTareaChanged={(id) => {
                            tareasProvider.getByIdForList(id).then((tarea) => {
                                updateTarea(tarea.id, tarea);
                                onTareaChanged && onTareaChanged(id);
                            });
                        }}
                        updateTarea={updateTarea}
                    />
                </>
            )}
            {...props}
        />
    );
}

ListaServicios.propTypes = {
    DraggableComponent: PropTypes.any,
    columns: PropTypes.any,
    options: PropTypes.any,
    setTareas: PropTypes.any,
    tableId: PropTypes.any,
    tareas: PropTypes.any,
    onTareaChanged: PropTypes.func,
    extraActions: PropTypes.any,
    TareaActionsProps: PropTypes.any,
};
