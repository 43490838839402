import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import GoogleMapsAutocomplete from '../../common/fields/GoogleMapsAutocomplete';
import { openDirectionsInMaps } from './NewDireccionDialog';

export default function ValidarDireccion() {
    const { values, setFieldValue, errors } = useFormikContext();

    let direccionCompleta = values.direccion;
    if (values.direccion_secundaria) direccionCompleta += `, ${values.direccion_secundaria}`;
    if (values.codigo_postal) direccionCompleta += `, ${values.codigo_postal}`;
    if (values.poblacion) direccionCompleta += `, ${values.poblacion}`;
    if (values.provincia) direccionCompleta += `, ${values.provincia}`;

    const isNotValidada = !!errors.google_place_id;

    return (
        <>
            <Grid item xs={6}>
                <GoogleMapsAutocomplete
                    placeholder='P.ej. Calle Balmes, 35, Barcelona'
                    label='Buscar dirección en Google Maps'
                    onChange={(result) => {
                        setFieldValue('google_place_id', result.placeId);
                        setFieldValue('direccion', result.address.direccion);
                        setFieldValue('codigo_postal', result.address.codigo_postal);
                        setFieldValue('poblacion', result.address.poblacion);
                        setFieldValue('provincia', result.address.provincia);
                        setFieldValue('coords', result.address.coords);
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    name='direccion_secundaria'
                    label='Direccion secundaria'
                    fullWidth
                    helperText='P.ej: bloque, escalera, piso, puerta, ...'
                />
            </Grid>
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <FormControl style={{ width: '100%' }} error={isNotValidada}>
                        <InputLabel shrink>Dirección completa</InputLabel>
                        <div style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
                            {direccionCompleta ? (
                                <>
                                    {direccionCompleta}

                                    <Tooltip title='Abrir en Google Maps'>
                                        <IconButton
                                            size='small'
                                            onClick={() =>
                                                openDirectionsInMaps(direccionCompleta, values.google_place_id)
                                            }
                                        >
                                            <LocationOnIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            ) : (
                                <em>Sin dirección</em>
                            )}
                        </div>
                        {isNotValidada && (
                            <FormHelperText>
                                Esta dirección no está validada en Google Maps. Usa el buscador para validarla.
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
}
