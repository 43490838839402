import PropTypes from 'prop-types';
import { SelectControl } from './SelectControl';

export function multipleSelectFilterFn(rows, id, filterValue) {
    if (filterValue === undefined) return rows;

    return rows.filter((row) => {
        const value = row.values[id];

        if (!Array.isArray(filterValue)) {
            if (!Array.isArray(value)) return filterValue === value || filterValue === '';

            return value.includes(filterValue) || filterValue === '';
        }

        if (!(filterValue.length > 0 && !filterValue.some((f) => f === ''))) {
            return true;
        }

        return Array.isArray(value) ? value.some((v) => filterValue.includes(v)) : filterValue.includes(value);
    });
}

export function SelectColumnFilter({ column }) {
    const {
        filterValue,
        setFilter,
        filterOptions: { options, includeAllOption, multiple = false },
    } = column;

    let value = filterValue;

    if (value === undefined) {
        if (multiple) value = [];
        else value = includeAllOption ? includeAllOption.value : '';
    } else if (multiple && !Array.isArray(value)) {
        value = [value];
    }

    return (
        <SelectControl
            multiple={multiple}
            displayEmpty={multiple}
            renderValue={
                multiple
                    ? (selected) => {
                          if (selected.length === 0) return 'Todos';
                          if (selected.length === 1) return options.find((op) => op.value === selected[0])?.label;
                          if (selected.length === options.length) return includeAllOption.label;
                          return `${selected.length} seleccionados`;
                      }
                    : undefined
            }
            fullWidth
            options={options}
            value={value}
            onChange={(e) => {
                const value = e.target.value;
                if (multiple) {
                    if (
                        value.length === 0 ||
                        value[value.length - 1] === null ||
                        value.filter((v) => v !== null).length === options.length
                    )
                        setFilter([]);
                    else setFilter(value);
                } else {
                    setFilter(value === includeAllOption.value ? undefined : value);
                }
            }}
            includeAllOption={multiple ? { label: 'Todos', value: null } : includeAllOption}
        />
    );
}

SelectColumnFilter.propTypes = {
    column: PropTypes.any,
};
