import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { Children } from 'react';
import { tipoLabels } from '../../api/operarios';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import EditFormHeader from '../common/forms/EditForm/EditFormHeader';
import EditFormHeaderItem from '../common/forms/EditForm/EditFormHeaderItem';
import EditFormHeaderItemList from '../common/forms/EditForm/EditFormHeaderItemList';
import { FormTab, FormTabs } from '../common/forms/EditForm/FormTabs';
import CalendariosTab from './Calendarios/CalendariosTab';
import DiasVacacionesList from './DiasVacaciones/DiasVacacionesList';
import EditOperarioDialog from './EditOperarioDialog';
import { CALENDARIOS_ROUTE, HORARIOS_ROUTE, RESUMEN_ROUTE, VACACIONES_ROUTE } from './EditOperarioForm';
import { EditOperarioMenuOptions } from './EditOperarioMenuOptions';
import HorariosTab from './Horarios/HorariosTab';
import ResumenTab from './Resumen/ResumenTab';

export function EditOperarioFormContent() {
    const { data } = useEditFormData();

    const usarResumen = true;

    const tabs = [];

    if (usarResumen) {
        tabs.push(
            <FormTab addWrapper path={RESUMEN_ROUTE} label='Resumen'>
                <ResumenTab />
            </FormTab>,
        );
    }

    tabs.push(
        <FormTab addWrapper path={HORARIOS_ROUTE} label='Horarios'>
            <HorariosTab />
        </FormTab>,
        <FormTab addWrapper path={CALENDARIOS_ROUTE} label='Calendarios'>
            <CalendariosTab />
        </FormTab>,
        <FormTab addWrapper path={VACACIONES_ROUTE} label='Días de vacaciones y ausencias'>
            <DiasVacacionesList />
        </FormTab>,
    );

    return (
        <>
            <EditFormHeader
                label={tipoLabels[data.tipo]}
                name={data.nombre}
                actions={
                    <>
                        <EditOperarioDialog
                            button={
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            }
                        />
                        <EditOperarioMenuOptions />
                    </>
                }
            >
                <EditFormHeaderItemList>
                    <EditFormHeaderItem label='Código'>{data.codigo}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Teléfono'>{data.telefono}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Email'>{data.email}</EditFormHeaderItem>
                    <EditFormHeaderItem label='NIF'>{data.nif}</EditFormHeaderItem>
                    <EditFormHeaderItem label='NAF'>{data.naf}</EditFormHeaderItem>
                    <EditFormHeaderItem label='¿Puede editar servicios?'>
                        {data.is_admin ? 'Si' : 'No'}
                    </EditFormHeaderItem>
                    <EditFormHeaderItem label='Activo'>{data.activo ? 'Si' : 'No'}</EditFormHeaderItem>
                </EditFormHeaderItemList>
            </EditFormHeader>

            <FormTabs>{Children.map(tabs, (tab) => tab)}</FormTabs>
        </>
    );
}
