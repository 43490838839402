import { useCallback, useEffect, useMemo } from 'react';
import BaseListView, { withTableState } from '../common/list/ListView';
import { dataProvider } from '../../api/vehiculos';
import { useHistory } from 'react-router-dom';
import { useFetchData } from '../../hooks/useFetchData';
import { useSnackbar } from 'material-ui-snackbar-provider';
import NewVehiculoDialog from './NewVehiculoDialog';
import Button from '../common/Button';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import { SwitchWithLabel } from '../common/fields/Switch';

const ListView = withTableState('vehiculos', BaseListView);

export default function VehiculosList() {
    const history = useHistory();
    const fetchDataFn = useCallback(() => dataProvider.getAll(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        dataProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Codigo',
                accessor: 'codigo',
            },
            {
                Header: 'Marca',
                accessor: 'marca',
            },
            {
                Header: 'Modelo',
                accessor: 'modelo',
            },
            {
                Header: 'Matrícula',
                accessor: 'matricula',
            },
            {
                Header: 'Num. plazas',
                accessor: 'num_plazas',
            },
            {
                Header: 'Activo',
                accessor: 'activo',
                // eslint-disable-next-line react/prop-types
                Cell: ({ value }) => (value ? <DoneIcon /> : null),
                filter: 'equals',
                disableGlobalFilter: true,
                disableFilters: true,
                disableSortBy: true,
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            deleteConfirmationText: 'borrar vehiculo',
            tableOptions: {
                useGlobalFilter: true,
                extraFilters: (setFilter, tableColumns) => {
                    const column = tableColumns.find((c) => c.id === 'activo');
                    const showingAll = column.filterValue === undefined;

                    return (
                        <SwitchWithLabel
                            label='Mostrar solo los vehículos activos'
                            field={{
                                name: 'solo-clientes-activos',
                                onChange: () => column.setFilter(showingAll ? true : undefined),
                                value: !showingAll,
                            }}
                        />
                    );
                },
                initialState: {
                    filters: [{ id: 'activo', value: true }],
                },
            },
        }),
        [],
    );

    return (
        <ListView
            title='Vehículos'
            tableTitle='Lista de vehículos'
            titleActions={
                <NewVehiculoDialog
                    button={
                        <Button color='info' aria-label='Nuevo vehículo' startIcon={<AddIcon />}>
                            Nuevo vehiculo
                        </Button>
                    }
                    onSave={({ id }) => history.push(`vehiculos/${id}`)}
                />
            }
            basePath='/vehiculos'
            columns={columns}
            data={data}
            options={options}
            onDelete={onDelete}
        />
    );
}
