import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { clientesProvider } from '../../api';
import { Form, Formik } from 'formik';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'material-ui-snackbar-provider';
import FileUploader from '../common/fields/FileUploader';
import { CheckboxWithLabel } from 'formik-material-ui';
import PropTypes from 'prop-types';

const UploadSchema = Yup.object().shape({
    file: Yup.mixed().required('Requerido'),
});

export function UploadClientesFileForm({ companyId }) {
    const snackbar = useSnackbar();

    return (
        <Formik
            initialValues={{
                file: null,
                sobreescribir_direccion_principal: false,
                borrar_inexistentes: false,
                company_id: companyId,
            }}
            enableReinitialize={true}
            validationSchema={UploadSchema}
            onSubmit={({ file, ...values }, { setSubmitting, setFieldError }) => {
                const formData = new FormData();
                formData.append('params', JSON.stringify(values));
                formData.append('file', file);

                clientesProvider
                    .getAll('importar', {
                        method: 'post',
                        body: formData,
                    })
                    .then((result) => {
                        snackbar.showMessage('Se han importado los clientes con éxito');
                    })
                    .catch((err) => {
                        if (err.status === 400) {
                            for (const [field, errors] of Object.entries(err.message)) {
                                setFieldError(field, errors.join('\n'));
                            }
                        }
                    })
                    .finally(() => setSubmitting(false));
            }}
        >
            {({ isSubmitting, values, errors, setFieldValue }) => {
                return (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FileUploader
                                    id='upload-clientes-file'
                                    label='Añadir adjuntos'
                                    file={values.setFile}
                                    setFile={(file) => setFieldValue('file', file)}
                                    multiple={false}
                                    isImage={false}
                                    accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CheckboxWithLabel
                                    type='checkbox'
                                    name='sobreescribir_direccion_principal'
                                    Label={{ label: 'Sobreescribir dirección principal' }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CheckboxWithLabel
                                    type='checkbox'
                                    name='borrar_inexistentes'
                                    Label={{ label: 'Borrar o inactivar empresas inexistentes' }}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                <Button type='submit' variant='contained' color='primary' disabled={isSubmitting}>
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
}

UploadClientesFileForm.propTypes = {
    companyId: PropTypes.any,
};
