import DescriptionIcon from '@material-ui/icons/Description';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
    estadoPreparacionColors,
    estadoPreparacionIcons,
    estadoPreparacionLabels,
} from '../../../api/preparaciones_material';
import { formatFullDate } from '../../../utils';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import ItemLabel from '../../common/forms/MasterDetailsView/ItemLabel';
import MasterItem from '../../common/forms/MasterDetailsView/MasterItem';
import PreparacionMaterialItemOptions from './PreparacionMaterialItemOptions';

export default function PreparacionMaterialItem({ item, index, href, navigate, onDelete, showHeader = false }) {
    const location = useLocation();
    const isSelected = location.pathname === href;
    const { fetchData } = useEditFormData();

    const Icon = estadoPreparacionIcons[item.estado];

    return (
        <MasterItem
            icon={DescriptionIcon}
            title={`Preparacion de material ${index + 1}`}
            navigate={navigate}
            header={showHeader && formatFullDate(item.fecha_creacion)}
            isSelected={isSelected}
            labels={
                <ItemLabel
                    icon={<Icon />}
                    label={estadoPreparacionLabels[item.estado]}
                    color={estadoPreparacionColors[item.estado]}
                    uppercase
                />
            }
            actions={<PreparacionMaterialItemOptions item={item} fetchData={fetchData} onDelete={onDelete} />}
        />
    );
}

PreparacionMaterialItem.propTypes = {
    href: PropTypes.string.isRequired,
    index: PropTypes.any,
    item: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    showHeader: PropTypes.bool,
};
