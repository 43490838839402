import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import IncidenciasList from './IncidenciasList';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'RevisionIncidencias' },
);

export default function RevisionIncidencias() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant='h1' component='h1'>
                Revisión de incidencias
            </Typography>
            <IncidenciasList />
        </div>
    );
}
