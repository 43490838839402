import { equiposProvider } from '../../../api';
import LabelListField from './LabelListField';

const TiposEquipoField = () => (
    <LabelListField
        label={'Tipos de equipo'}
        getItems={equiposProvider.getTipos}
        onDelete={equiposProvider.deleteTipo}
        onAdd={equiposProvider.addTipo}
        DialogProps={{
            title: 'Añadir tipo de equipo',
            label: 'Nombre',
            placeholder: 'P.ej: Split, Caldera, ...',
        }}
    />
);
export default TiposEquipoField;
