import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { eventIcons } from './constants';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            borderRadius: 50,
            padding: '0px 8px',
            color: (props) => props.color.color_fg,
            backgroundColor: (props) => props.color.color_bg,
            border: (props) => `2px solid ${props.color.color_bg}`,
            opacity: (props) => (props.closed ? 0.6 : 1),
            lineHeight: '20px',
            '& svg': {
                fontSize: 16,
                marginRight: 4,
            },
        },
    }),
    { name: 'EventListadoLabel' },
);

export function EventListadoLabel({ title, icon, color, closed }) {
    const classes = useStyles({ color, closed });
    const Icon = eventIcons[icon];

    return (
        <div className={clsx(classes.root)}>
            <Icon /> {title}
        </div>
    );
}

EventListadoLabel.propTypes = {
    closed: PropTypes.any,
    color: PropTypes.any,
    icon: PropTypes.any,
    title: PropTypes.any,
};
