import DialogForm from '../common/forms/DialogForm';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { companiesProvider } from '../../api';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import AuthService from '../auth/AuthService';
import API_ROOT from '../../api/api-config';
import useAuthState from '../../AuthState';

const ConfigurarRevisionSchema = Yup.object().shape({
    minutos_diferencia_fichajes_min: Yup.number().required('Requerido').min(0, 'Tiene que ser un número positivo'),
    minutos_diferencia_fichajes_max: Yup.number().required('Requerido').min(0, 'Tiene que ser un número positivo'),
});

const useStyles = makeStyles(
    (theme) => ({
        row: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(2)}px`,
            '& .MuiTypography-root': {
                fontWeight: 400,
            },
            '& .MuiTypography-root:first-of-type': {
                width: 340,
            },
            '& .MuiTextField-root': {
                width: 80,
            },
        },
    }),
    { name: 'ConfigurarRevisionDialog' },
);

function ConfigurarRevisionDialog(props) {
    const [preferencias, setPreferencias] = useState({
        minutos_diferencia_fichajes_min: 0,
        minutos_diferencia_fichajes_max: 0,
    });
    const classes = useStyles();
    const snackbar = useSnackbar();
    const { updateLoginInfo } = useAuthState();

    useEffect(() => {
        if (!props.open) return;

        companiesProvider.getPreferencias().then(setPreferencias);
    }, [props.open]);

    return (
        <DialogForm
            title='Configurar revisión de fichajes'
            maxWidth='md'
            FormikProps={{
                initialValues: {
                    minutos_diferencia_fichajes_min: preferencias.minutos_diferencia_fichajes_min,
                    minutos_diferencia_fichajes_max: preferencias.minutos_diferencia_fichajes_max,
                },
                enableReinitialize: true,
                validationSchema: ConfigurarRevisionSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    companiesProvider
                        .savePreferencias({
                            ...preferencias,
                            ...values,
                        })
                        .then(() => {
                            const authService = new AuthService(API_ROOT);
                            authService.updateUserInfo().then(updateLoginInfo);

                            snackbar.showMessage('Ajustes guardados con éxito');
                            props.onClose();
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ values, setFieldValue }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.row}>
                        <Typography variant='subtitle2'>
                            Marcar en rojo los fichajes con una diferencia de MÁS de
                        </Typography>
                        <TextField name='minutos_diferencia_fichajes_max' type='number' />
                        <Typography variant='subtitle2'>minutos respecto a las horas efectivas</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                        <Typography variant='subtitle2'>
                            Marcar en rojo los fichajes con una diferencia de MENOS de
                        </Typography>
                        <TextField name='minutos_diferencia_fichajes_min' type='number' />
                        <Typography variant='subtitle2'>minutos respecto a las horas efectivas</Typography>
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(ConfigurarRevisionDialog);

ConfigurarRevisionDialog.propTypes = {
    onClose: PropTypes.any,
    open: PropTypes.any,
};
