import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { tareasProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { SwitchWithLabel } from '../common/fields/Switch';
import TipoHoraField from '../common/fields/TipoHoraField';
import DialogForm from '../common/forms/DialogForm';

function TiposHoraDefectoDialog({ tareaId, ...props }) {
    const [tiposHora, setTiposHora] = useState(null);

    useEffect(() => {
        if (!props.open) return;

        tareasProvider.getTiposHora(tareaId).then(setTiposHora);
    }, [props.open, tareaId]);

    return (
        <DialogForm
            title='Editar tipos de hora por defecto'
            FormikProps={{
                initialValues: {
                    usar_preferencias: tiposHora === null,
                    ordinaria: tiposHora && tiposHora.ordinaria ? { id: tiposHora.ordinaria } : null,
                    nocturno: tiposHora && tiposHora.nocturno ? { id: tiposHora.nocturno } : null,
                    sabado: tiposHora && tiposHora.sabado ? { id: tiposHora.sabado } : null,
                    festivo: tiposHora && tiposHora.festivo ? { id: tiposHora.festivo } : null,
                },
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    const payload = {};
                    if (!values.usar_preferencias) {
                        payload.ordinaria = values.ordinaria?.id;
                        payload.nocturno = values.nocturno?.id;
                        payload.sabado = values.sabado?.id;
                        payload.festivo = values.festivo?.id;
                    }
                    tareasProvider
                        .setTiposHora(tareaId, payload)
                        .then((res) => {
                            props.onClose();
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ values }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Field
                            name='usar_preferencias'
                            component={SwitchWithLabel}
                            label='Utilizar los tipos de hora por dfecto configurados en Ajustes'
                        />
                    </Grid>
                    {!values.usar_preferencias && (
                        <>
                            <Grid item xs={12}>
                                <TipoHoraField name='ordinaria' label='Ordinaria' />
                            </Grid>
                            <Grid item xs={12}>
                                <TipoHoraField name='nocturno' label='Nocturno' />
                            </Grid>
                            <Grid item xs={12}>
                                <TipoHoraField name='sabado' label='Sábado' />
                            </Grid>
                            <Grid item xs={12}>
                                <TipoHoraField name='festivo' label='Festivo' />
                            </Grid>
                        </>
                    )}
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(TiposHoraDefectoDialog);

TiposHoraDefectoDialog.propTypes = {
    onClose: PropTypes.any,
    open: PropTypes.any,
    tareaId: PropTypes.any,
};
