import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getDaysInYear } from 'date-fns';
import { es } from 'date-fns/locale';
import { useEffect, useMemo, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { operariosProvider, solicitudesProvider } from '../../../api';
import Button from '../../common/Button';
import DialogPaper from '../../common/dialogs/DialogPaper/DialogPaper';
import DialogPaperLine from '../../common/dialogs/DialogPaper/DialogPaperLine';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: `${theme.spacing(7)}px`,
        },
        resumenColumn: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
        },
        navigation: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(4)}px`,
            '& .MuiButton-root': {
                width: 32,
                minWidth: 0,
            },
        },
        navigationButton: {
            height: 32,
            padding: 0,
            backgroundColor: 'white',
        },
        fecha: {
            fontWeight: theme.typography.fontWeightMedium,
            textTransform: 'capitalize',
        },
        calendarPaper: {
            maxWidth: 1080,
            flexWrap: 'nowrap',
        },
        leyenda: {
            display: 'flex',
            gap: `${theme.spacing(4)}px`,
            marginLeft: 'auto',
            marginRight: theme.spacing(1),
            '& .MuiTypography-root': {
                display: 'flex',
                alignItems: 'center',
                gap: `${theme.spacing(0.5)}px`,
            },
            '& .MuiTypography-root span': {
                width: 14,
                height: 14,
                display: 'inline-block',
                borderRadius: '50%',
            },
        },
        vacaciones: {
            background: '#2CBD96',
        },
        ausencia: {
            background: '#E94F4F',
        },
        laborable: {
            background: 'white',
        },
        noLaborable: {
            background: `${theme.palette.error.main}33`,
        },
        calendar: {
            '&.rdp': {
                margin: 0,
                fontSize: 12,
                '--rdp-cell-size': '32px',
                '--rdp-caption-font-size': '14px',
            },
            '& .rdp-months': {
                flexWrap: 'wrap',
                gap: `${theme.spacing(3)}px`,
            },
            '& .rdp-month': {
                margin: 0,
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            },
        },
    }),
    { name: 'Resumen' },
);

export default function ResumenTab() {
    const classes = useStyles();
    const [year, setYear] = useState(new Date().getFullYear());
    const [noLaborables, setNoLaborables] = useState([]);
    const [resumenSolicitudes, setResumenSolicitudes] = useState(null);

    const { data: operario } = useEditFormData();

    const numDays = getDaysInYear(year);

    const numDiasVacaciones = useMemo(() => {
        const yearRecord = operario.dias_vacaciones.find((d) => d.year === year);
        if (!yearRecord) return 0;

        return yearRecord.dias;
    }, [operario, year]);

    useEffect(() => {
        operariosProvider
            .getNoLaborables(year, operario.id)
            .then((fechas) => setNoLaborables(fechas.map((fecha) => new Date(fecha))));

        solicitudesProvider.getResumen(year, operario.id).then(setResumenSolicitudes);
    }, [year]);

    const numNoLaborables = noLaborables.length;

    const numDiasLaborables = numDays - numNoLaborables - numDiasVacaciones;

    const { fechasVacaciones, fechasAusencias } = useMemo(() => {
        const fechasVacaciones = [];
        const fechasAusencias = [];

        if (!resumenSolicitudes) return { fechasVacaciones, fechasAusencias };

        resumenSolicitudes.vacaciones.solicitudes.lista.forEach((solicitud) => {
            fechasVacaciones.push(...solicitud.dias.map((fecha) => new Date(fecha)));
        });

        resumenSolicitudes.ausencias.solicitudes.lista.forEach((solicitud) => {
            fechasAusencias.push(...solicitud.dias.map((fecha) => new Date(fecha)));
        });

        return { fechasVacaciones, fechasAusencias };
    });

    return (
        <div className={classes.root}>
            <div className={classes.resumenColumn}>
                <DialogPaper title={`Días laborables ${year}`} typographyVariant='body1'>
                    <DialogPaperLine title='Naturales' value={`${numDays} días`} />
                    <DialogPaperLine title='No laborables' value={`${numNoLaborables} días`} />
                    <DialogPaperLine title='Vacaciones' value={`${numDiasVacaciones} días`} />
                    <DialogPaperLine title='Laborables' value={`${numDiasLaborables} días`} />
                </DialogPaper>
            </div>
            <Grid container spacing={2} direction='column' className={classes.calendarPaper}>
                <Grid item xs={12} className={classes.navigation}>
                    <Button
                        color='outlined'
                        onClick={() => setYear((year) => year - 1)}
                        className={classes.navigationButton}
                    >
                        <ChevronLeftIcon />
                    </Button>
                    <Typography variant='h2' className={classes.fecha}>
                        {year}
                    </Typography>
                    <Button
                        color='outlined'
                        onClick={() => setYear((year) => year + 1)}
                        className={classes.navigationButton}
                    >
                        <ChevronRightIcon />
                    </Button>
                    <div className={classes.leyenda}>
                        <Typography variant='subtitle1'>
                            <span className={classes.noLaborable}></span> No laborable
                        </Typography>
                        <Typography variant='subtitle1'>
                            <span className={classes.vacaciones}></span> Vacaciones
                        </Typography>
                        <Typography variant='subtitle1'>
                            <span className={classes.ausencia}></span> Ausencia
                        </Typography>
                        <Typography variant='subtitle1'>
                            <span className={classes.laborable}></span> Laborable
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <DayPicker
                            className={classes.calendar}
                            disableNavigation
                            locale={es}
                            month={new Date(year, 0)}
                            mode='multiple'
                            numberOfMonths={12}
                            modifiers={{
                                noLaborables: noLaborables,
                                vacaciones: fechasVacaciones,
                                ausencia: fechasAusencias,
                            }}
                            modifiersClassNames={{
                                noLaborables: classes.noLaborable,
                                vacaciones: classes.vacaciones,
                                ausencia: classes.ausencia,
                            }}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
