import { Route, Switch } from 'react-router-dom';
import EditServicioForm from './EditServicioForm';

export default function ServiciosModule() {
    const onError = (err) => console.error(err);

    return (
        <>
            <Switch>
                <Route
                    path='/servicios/:id'
                    render={({ match }) => <EditServicioForm basePath='/empleados' match={match} onError={onError} />}
                />
            </Switch>
        </>
    );
}
