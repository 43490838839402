import React from 'react';
import { EventDefault } from './EventDefault';
import { EventFichaje } from './EventFichaje';
import PropTypes from 'prop-types';

const eventTypeMap = {
    fichaje: EventFichaje,
    jornada: EventDefault,
    agenda: EventDefault,
    solicitud: EventDefault,
};

export function Event(props) {
    const Element = eventTypeMap[props.event.type];
    return React.createElement(Element, props);
}

Event.propTypes = {
    event: PropTypes.object,
};
