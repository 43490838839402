import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import { BaseAlbaranList } from '../servicios/AlbaranList/AlbaranList';
import { GenerarAlbaranForProyectoDialog } from '../servicios/ParteTrabajoList/GenerarAlbaranForTareaDialog';

export default function AlbaranesProyecto() {
    const { data: jornadas } = useEditFormData('jornadas');

    if (!jornadas) return null;

    return (
        <BaseAlbaranList
            actions={
                <GenerarAlbaranForProyectoDialog
                    button={
                        <IconButton disabled={jornadas.filter((jornada) => jornada.albaran_id === null).length === 0}>
                            <AddIcon />{' '}
                        </IconButton>
                    }
                />
            }
        />
    );
}
