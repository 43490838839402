import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import EditFormHeader from '../common/forms/EditForm/EditFormHeader';
import EditFormHeaderItem from '../common/forms/EditForm/EditFormHeaderItem';
import EditFormHeaderItemList from '../common/forms/EditForm/EditFormHeaderItemList';
import FormTabWrapper from '../common/forms/EditForm/FormTabWrapper';
import EditVehiculoDialog from './EditVehiculoDialog';
import { EditVehiculoMenuOptions } from './EditVehiculoMenuOptions';
import ServiciosVehiculo from './ServiciosVehiculo';

export function EditVehiculoFormContent() {
    const { data, fetchData } = useEditFormData();

    return (
        <>
            <EditFormHeader
                label='Vehiculo'
                name={data.nombre}
                actions={
                    <>
                        <EditVehiculoDialog
                            button={
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            }
                            onSave={fetchData}
                            vehiculo={data}
                        />
                        <EditVehiculoMenuOptions />
                    </>
                }
            >
                <EditFormHeaderItemList>
                    <EditFormHeaderItem label='Marca'>{data.marca}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Modelo'>{data.modelo}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Matrícula'>{data.matricula}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Número de plazas'>{data.num_plazas}</EditFormHeaderItem>
                </EditFormHeaderItemList>
            </EditFormHeader>

            <FormTabWrapper>
                <ServiciosVehiculo />
            </FormTabWrapper>
        </>
    );
}
