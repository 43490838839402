import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            borderBottom: `1px solid ${theme.palette.divider}`,
            '&:last-child': {
                borderBottom: 'none',
            },
            padding: theme.spacing(1, 1.5),
        },
        title: {
            flex: 1,
        },
    }),
    { name: 'DialogPaperLine' },
);

export default function DialogPaperLine({ title, value }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant='body2' className={classes.title}>
                {title}
            </Typography>
            {typeof value === 'string' ? (
                <Typography variant='body2' className={classes.value}>
                    {value}
                </Typography>
            ) : (
                <div>{value}</div>
            )}
        </div>
    );
}

DialogPaperLine.propTypes = {
    title: PropTypes.any,
    value: PropTypes.any,
};
