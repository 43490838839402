import { useEffect, useState } from 'react';
import { clientesProvider } from '../../../api';
import { Field, useField } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import MuiTextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '../Button';
import NewProyectoDialog from '../../clientes/proyectos/NewProyectoDialog';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(
    (theme) => ({
        proyectoHelper: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 10,
            fontWeight: 500,
            color: theme.palette.warning.main,
            '& .MuiButton-root .MuiSvgIcon-root': {
                fontSize: 15,
            },
            '& .MuiButton-root': {
                marginLeft: 'auto',
                padding: theme.spacing(0.5),
                fontSize: 13,
                height: 24,
            },
        },
    }),
    { name: 'ProyectoField' },
);

export default function ProyectoField({ error, helperText, disabled, filterByDireccion = false }) {
    const classes = useStyles();
    const [proyectosOptions, setProyectosOptions] = useState([]);

    const [{ value: proyecto }, , { setValue }] = useField('proyecto');
    const [{ value: cliente }, ,] = useField('cliente');
    const [{ value: direccion }, ,] = useField('direccion');

    const clienteId = cliente?.id;
    const proyectoId = proyecto?.id;
    const direccionId = filterByDireccion ? direccion?.id : null;

    function queryProyectosOptions() {
        if (!clienteId || (filterByDireccion && !direccionId)) {
            setProyectosOptions([]);
            return;
        }

        let actionUrl = direccionId ? `direcciones/${direccionId}/` : '';
        actionUrl += 'proyectos/as_options';
        clientesProvider.actionOnId(clienteId, actionUrl, null, { method: 'get' }).then((proyectos) => {
            setProyectosOptions(proyectos);
            setValue(proyectoId ? proyectos.find((d) => d.id === proyectoId) : null);
        });
    }

    useEffect(queryProyectosOptions, [clienteId, direccionId, proyectoId]);

    return (
        <div>
            <Field
                name='proyecto'
                fullWidth
                disabled={disabled}
                component={Autocomplete}
                options={proyectosOptions}
                getOptionSelected={(option, value) => option.id === value?.id}
                getOptionLabel={(option) => option.nombre ?? ''}
                renderInput={(params) => (
                    <MuiTextField {...params} error={error} helperText={helperText} label='Proyecto' />
                )}
            />
            {!disabled && (
                <div className={classes.proyectoHelper}>
                    <NewProyectoDialog
                        clienteId={clienteId}
                        direccionId={direccionId}
                        button={
                            <Button
                                color='primary'
                                startIcon={<AddIcon />}
                                disabled={!clienteId || (filterByDireccion && !direccionId)}
                            >
                                Añadir proyecto
                            </Button>
                        }
                        onSave={queryProyectosOptions}
                    />
                </div>
            )}
        </div>
    );
}

ProyectoField.propTypes = {
    disabled: PropTypes.any,
    error: PropTypes.any,
    helperText: PropTypes.any,
    filterByDireccion: PropTypes.bool,
};
