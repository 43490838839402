import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { companiesProvider } from '../../../../../../api';
import { withButtonOpener } from '../../../../../../hooks/withButtonOpener';
import DialogForm from '../../../../../common/forms/DialogForm';

const CategoriaOperarioSchema = Yup.object().shape({
    codigo: Yup.string().required('Requerido'),
    nombre: Yup.string().required('Requerido'),
    precio_coste: Yup.string().required('Requerido'),
    precio_venta: Yup.string().required('Requerido'),
});

const useStyles = makeStyles(
    (theme) => ({
        dialogPaper: {},
    }),
    { name: 'NewCategoriaOperarioDialog' },
);

function NewCategoriaOperarioDialog({ onSave, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    return (
        <DialogForm
            title='Nueva categoría de operario'
            maxWidth='sm'
            className={classes.dialogPaper}
            FormikProps={{
                initialValues: {
                    codigo: '',
                    nombre: '',
                    precio_coste: 0,
                    precio_venta: 0,
                },
                enableReinitialize: true,
                validationSchema: CategoriaOperarioSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    companiesProvider
                        .createCategoriaOperario(values)
                        .then((data) => {
                            onSave && onSave(data.id);
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {({ values, setFieldValue }) => (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField name='codigo' label='Codigo' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='nombre' label='Nombre' fullWidth />
                    </Grid>
                    {/* <Grid item xs={6}> */}
                    {/*    <TextField */}
                    {/*        name='precio_coste' */}
                    {/*        label='Precio coste' */}
                    {/*        type='number' */}
                    {/*        step='.01' */}
                    {/*        fullWidth */}
                    {/*        InputProps={{ */}
                    {/*            endAdornment: <InputAdornment position='end'>€</InputAdornment>, */}
                    {/*        }} */}
                    {/*    /> */}
                    {/* </Grid> */}
                    {/* <Grid item xs={6}> */}
                    {/*    <TextField */}
                    {/*        name='precio_venta' */}
                    {/*        label='Precio venta' */}
                    {/*        type='number' */}
                    {/*        step='.01' */}
                    {/*        fullWidth */}
                    {/*        InputProps={{ */}
                    {/*            endAdornment: <InputAdornment position='end'>€</InputAdornment>, */}
                    {/*        }} */}
                    {/*    /> */}
                    {/* </Grid> */}
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(NewCategoriaOperarioDialog);

NewCategoriaOperarioDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};
