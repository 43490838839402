import { useEffect, useState } from 'react';
import { Field, useField } from 'formik';
import { companiesProvider } from '../../../api';
import { Autocomplete } from 'formik-material-ui-lab';
import MuiTextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

export default function DelegacionField() {
    const [delegacionesOptions, setDelegacionesOptions] = useState([]);
    const [{ value }, , { setValue }] = useField('delegacion');

    useEffect(() => {
        companiesProvider.getDelegacionesAsOptions().then(setDelegacionesOptions);
    }, []);

    useEffect(() => {
        if (delegacionesOptions.length === 0) return;

        setValue(value ? delegacionesOptions.find((d) => d.id === value.id) : null);
    }, [value, delegacionesOptions]);

    return (
        <Field
            name='delegacion'
            fullWidth
            component={Autocomplete}
            options={delegacionesOptions}
            getOptionSelected={(option, value) => option.id === value?.id}
            getOptionLabel={(option) => option.nombre ?? ''}
            renderInput={(params) => (
                <MuiTextField
                    {...params}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label='Delegación'
                    placeholder={!value ? 'Por defecto' : ''}
                />
            )}
        />
    );
}

DelegacionField.propTypes = {
    disabled: PropTypes.any,
    error: PropTypes.any,
    helperText: PropTypes.any,
};
