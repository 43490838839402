import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { albaranesCompraProvider } from '../../api';
import { useFetchData } from '../../hooks/useFetchData';
import EditForm from '../common/forms/EditForm/EditForm';
import { EditAlbaranCompraFormContent } from './EditAlbaranCompraFormContent';

export const SERVICIOS_ROUTE = 'servicios';

export default function EditAlbaranCompraForm() {
    const { id } = useParams();

    const fetchDataFn = useCallback(() => albaranesCompraProvider.getAll(id), [id]);
    const { data, fetchData } = useFetchData(fetchDataFn, null);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        data && (
            <>
                <EditForm data={data} fetchData={fetchData}>
                    <EditAlbaranCompraFormContent />
                </EditForm>
            </>
        )
    );
}
