import { DialogActions, ListItem } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { diasSemana } from '../../../../../api/companies';
import { withButtonOpener } from '../../../../../hooks/withButtonOpener';
import Button from '../../../../common/Button';

const useStyles = makeStyles(
    (theme) => ({
        list: {
            '& .MuiListItemIcon-root': {
                minWidth: 32,
            },
            '& .MuiCheckbox-root': {
                padding: 4,
            },
        },
    }),
    { name: 'CopiarHorariosDiaDialog' },
);

function CopiarHorariosDiaDialog({ selectedDia, onAccept, open, onClose }) {
    const classes = useStyles();
    const [checked, setChecked] = useState([selectedDia]);

    useEffect(() => {
        setChecked([selectedDia]);
    }, [selectedDia]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    function handleToggleAll() {
        if (checked.length === 7) {
            setChecked([selectedDia]);
        } else {
            setChecked([0, 1, 2, 3, 4, 5, 6]);
        }
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth='sm'>
            <DialogTitle id='alert-dialog-title'>Copiar horarios a</DialogTitle>
            <List dense className={classes.list}>
                <ListItem dense button onClick={handleToggleAll}>
                    <ListItemIcon>
                        <Checkbox edge='start' checked={checked.length === 7} tabIndex={-1} disableRipple />
                    </ListItemIcon>
                    <ListItemText primary='Seleccionar todos' />
                </ListItem>
                {diasSemana.map((dia, index) => {
                    if (dia === selectedDia) return null;
                    return (
                        <ListItem
                            key={index}
                            dense
                            button={index === selectedDia}
                            disabled={index === selectedDia}
                            onClick={handleToggle(index)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge='start'
                                    disabled={index === selectedDia}
                                    checked={checked.includes(index)}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primary={dia} />
                        </ListItem>
                    );
                })}
            </List>
            <DialogActions>
                <Button color='outlined' onClick={onClose}>
                    Cerrar
                </Button>
                <Button
                    color='info'
                    onClick={() => {
                        onAccept(checked.filter((idx) => idx !== selectedDia));
                        onClose();
                    }}
                >
                    Copiar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

CopiarHorariosDiaDialog.propTypes = {
    selectedDia: PropTypes.number,
    onAccept: PropTypes.func,
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

export default withButtonOpener(CopiarHorariosDiaDialog);
