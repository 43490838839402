import { revisadoLabels } from '../../api/tareas';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            borderRadius: 4,
            color: 'white',
            padding: theme.spacing(0, 1),
            whiteSpace: 'nowrap',
            textTransform: 'uppercase',
            width: 'fit-content',
            '&.NO_REVISADO': {
                backgroundColor: theme.palette.neutral.grey4,
            },
            '&.REVISADO_PARCIALMENTE': {
                backgroundColor: theme.palette.neutral.grey4,
            },
            '&.REVISADO': {
                backgroundColor: theme.palette.primary.main,
            },
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
    }),
    { name: 'ServicioRevisadoChip' },
);

export default function ServicioRevisadoChip({ value, style }) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, value)} style={style}>
            <Typography variant='subtitle2'>{revisadoLabels[value]}</Typography>
        </div>
    );
}

ServicioRevisadoChip.propTypes = {
    value: PropTypes.string.isRequired,
    style: PropTypes.object,
};
