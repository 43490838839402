import { FilterInput } from './FilterInput';
import PropTypes from 'prop-types';

export function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
        <FilterInput
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Buscar en ${count} filas...`}
        />
    );
}

DefaultColumnFilter.propTypes = {
    column: PropTypes.any,
};
