import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import MasterItemOptions from '../../common/forms/MasterDetailsView/MasterItemOptions';
import GenerarAlbaranForTareaDialog from './GenerarAlbaranForTareaDialog';
import GenerarExcelDialog from './GenerarExcelDialog';
import GenerarPdfDialog from './GenerarPdfDialog';

export default function ParteTrabajoListOptions({ tareaId, canGenerarAlbaran, onUpdate }) {
    if (!tareaId) return null;

    return (
        <MasterItemOptions>
            {({ closeMenu }) => [
                <GenerarExcelDialog
                    key={22}
                    tareaId={tareaId}
                    button={<MenuItem onClick={closeMenu}>Descargar todo en Excel</MenuItem>}
                />,
                <GenerarPdfDialog
                    key={2}
                    tareaId={tareaId}
                    button={<MenuItem onClick={closeMenu}>Descargar todo en PDF</MenuItem>}
                />,
                <GenerarAlbaranForTareaDialog
                    key={3}
                    button={
                        <MenuItem onClick={closeMenu} disabled={!canGenerarAlbaran}>
                            Generar albarán de servicio
                        </MenuItem>
                    }
                />,
            ]}
        </MasterItemOptions>
    );
}

ParteTrabajoListOptions.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    tareaId: PropTypes.string,
    canGenerarAlbaran: PropTypes.bool,
};
