import DataProvider from './DataProvider';

export const empty = {
    nombre: '',
    instalacion: null,
    fecha_inicio: null,
    fecha_fin: null,
    observaciones: '',
    num_servicios: '',
    adjuntos: [],
    equipos: [],
    tareas: [],
};

class ContratosMantenimientoProvider extends DataProvider {
    constructor() {
        super('contratos_mantenimiento', empty);
    }

    getEquipos = async (id) => this.actionOnId(id, 'equipos', null, { method: 'get' });
    getEquiposToAdd = async (id) => this.actionOnId(id, 'equipos/to-add', null, { method: 'get' });
    addEquipos = async (id, equipoIds) => this.actionOnId(id, 'equipos', { ids: equipoIds }, { method: 'post' });
    quitarEquipo = async (id, equipoId) => this.actionOnId(id, `equipos/${equipoId}`, null, { method: 'delete' });

    deleteTarea = async (tareaId) => this.action(`tareas/${tareaId}`, { method: 'delete' });
    getTareas = async (id) => this.actionOnId(id, 'tareas', null, { method: 'get' });

    getTareasAnuales = async (year) => this.getAll(`tareas/${year}`);
}

export const dataProvider = new ContratosMantenimientoProvider();
