import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 2),
            height: 32,
            '&:not(:last-child)': {
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            '&.info': {
                backgroundColor: theme.palette.primary.backgroundTint,
            },
            '&.success': {
                backgroundColor: theme.palette.success.backgroundTint,
            },
            '&.error': {
                backgroundColor: theme.palette.error.backgroundTint,
            },
        },
        label: {
            flex: 1,
            fontWeight: theme.typography.fontWeightMedium,
        },
        value: {
            textAlign: 'right',
        },
    }),
    { name: 'PaperList' },
);

export default function PaperListItem({ label, value, color }) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, color)}>
            <Typography variant='subtitle1' className={classes.label}>
                {label}
            </Typography>
            <Typography variant='subtitle1' className={classes.value}>
                {value}
            </Typography>
        </div>
    );
}

PaperListItem.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    color: PropTypes.string,
};
