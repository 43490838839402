import { useEffect, useMemo, useState } from 'react';
import BaseListView, { withTableState } from '../common/list/ListView';
import { dataProvider } from '../../api/equipos';
import DoneIcon from '@material-ui/icons/Done';
import { useSnackbar } from 'material-ui-snackbar-provider';
import Button from '../common/Button';
import AddIcon from '@material-ui/icons/Add';
import { NewEquipoDialogBase } from './NewEquipoDialog';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CopyIcon from '@material-ui/icons/FileCopy';
import EditEquipoDialog from './EditEquipoDialog';
import EditIcon from '@material-ui/icons/Edit';
import useAuthState from '../../AuthState';
import PropTypes from 'prop-types';
import { SwitchWithLabel } from '../common/fields/Switch';
import EstadoLabel from './EstadoLabel';

export default function BaseEquiposList({
    tableId,
    data,
    fetchData,
    ListViewProps,
    hideCliente,
    hideInstalacion,
    extraOptions,
    tableTitle = 'Lista de equipos',
}) {
    const ListView = useMemo(() => withTableState(tableId, BaseListView), [tableId]);

    const [duplicarEquipoId, setDuplicarEquipoId] = useState(null);

    const {
        userInfo: { preferencias: { dias_margen_revision: diasMargenRevision } = {} },
    } = useAuthState();

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        dataProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(() => {
        const columns = [
            {
                Header: 'Referencia',
                accessor: 'referencia',
            },
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
            {
                Header: 'Marca',
                accessor: 'marca',
            },
            {
                Header: 'Modelo',
                accessor: 'modelo',
            },
            {
                Header: 'Tipo',
                accessor: 'tipo_equipo',
            },
            {
                Header: 'Numero de serie',
                accessor: 'numero_serie',
            },
            {
                Header: 'Ubicación',
                accessor: 'ubicacion',
            },
        ];

        if (!hideCliente)
            columns.push({
                Header: 'Cliente',
                accessor: 'cliente',
            });

        if (!hideInstalacion)
            columns.push({
                Header: 'Instalación',
                accessor: 'instalacion.nombre',
            });

        columns.push(
            ...[
                {
                    Header: 'Direccion',
                    accessor: 'direccion.nombre',
                },
                {
                    Header: 'Estado',
                    id: 'estado',
                    accessor: (row) => (row.activo ? 'ACTIVO' : row.fecha_baja ? 'BAJA' : 'INACTIVO'),
                    // eslint-disable-next-line react/prop-types
                    Cell: ({ value, row: { original } }) => (
                        // eslint-disable-next-line react/prop-types
                        <EstadoLabel estado={value} fechaBaja={original.fecha_baja} motivoBaja={original.motivo_baja} />
                    ),
                },
                {
                    Header: 'Activo',
                    accessor: 'activo',
                    // eslint-disable-next-line react/prop-types
                    Cell: ({ value }) => (value ? <DoneIcon /> : null),
                    filter: 'equals',
                    disableGlobalFilter: true,
                    disableFilters: true,
                    disableSortBy: true,
                },
            ],
        );

        return columns;
    }, [hideCliente, hideInstalacion, diasMargenRevision]);

    const options = useMemo(
        () => ({
            useActions: true,
            deleteConfirmationText: 'borrar equipo',
            tableOptions: {
                extraFilters: (setFilter, tableColumns) => {
                    const column = tableColumns.find((c) => c.id === 'activo');
                    const showingAll = column.filterValue === undefined;

                    return (
                        <SwitchWithLabel
                            label='Mostrar solo los equipos activos'
                            field={{
                                name: 'solo-operarios-activos',
                                onChange: () => column.setFilter(showingAll ? true : undefined),
                                value: !showingAll,
                            }}
                        />
                    );
                },
                forceHiddenColumns: ['activo'],
                initialState: {
                    filters: [{ id: 'activo', value: true }],
                },
            },
            ...extraOptions,
        }),
        [extraOptions],
    );

    return (
        <>
            <NewEquipoDialogBase
                open={Boolean(duplicarEquipoId)}
                equipoId={duplicarEquipoId}
                button={
                    <Button color='info' aria-label='Nuevo equipo' startIcon={<AddIcon />}>
                        Nuevo equipo
                    </Button>
                }
                onClose={() => setDuplicarEquipoId(null)}
                onSave={() => {
                    setDuplicarEquipoId(null);
                    fetchData();
                }}
            />
            <ListView
                tableTitle={tableTitle}
                basePath='/equipos'
                columns={columns}
                data={data}
                options={options}
                onDelete={onDelete}
                extraActions={(row) => (
                    <>
                        <Tooltip title='Duplicar equipo'>
                            <IconButton onClick={() => setDuplicarEquipoId(row.id)}>
                                <CopyIcon />
                            </IconButton>
                        </Tooltip>
                        <EditEquipoDialog
                            id={row.id}
                            button={
                                <Tooltip title='Editar equipo'>
                                    <IconButton>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            onSave={fetchData}
                        />
                    </>
                )}
                {...ListViewProps}
            />
        </>
    );
}

BaseEquiposList.propTypes = {
    ListViewProps: PropTypes.any,
    data: PropTypes.any,
    extraOptions: PropTypes.any,
    fetchData: PropTypes.any,
    hideCliente: PropTypes.any,
    hideInstalacion: PropTypes.any,
    tableId: PropTypes.any,
    tableTitle: PropTypes.string,
};
