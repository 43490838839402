import { useHistory } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import EditFormBase, { useFormState, withEditForm } from '../common/forms/EditForm';
import { dataProvider } from '../../api/companies';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

const CompaniesSchema = Yup.object().shape({
    name: Yup.string().required('Requerido'),
    email: Yup.string().email().required('Requerido'),
    password: Yup.string().required('Requerido'),
    confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Las contraseñas tienen que coincidir'),
});

const TabGeneral = () => {
    const { classes } = useFormState();

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <TextField name='name' label='Nombre' fullWidth />
            </Grid>
            <Grid item xs={12}>
                <TextField name='email' label='Email' fullWidth />
            </Grid>
            <Grid item xs={12}>
                <TextField name='username' label='Usuario' fullWidth />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name='password'
                    label='Contraseña'
                    InputProps={{
                        type: 'password',
                    }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name='confirm_password'
                    label='Confirmar contraseña'
                    InputProps={{
                        type: 'password',
                    }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField name='nif' label='NIF' fullWidth />
            </Grid>
            <Grid item xs={6}>
                <TextField name='telefono' label='Telefono' fullWidth />
            </Grid>
            <Grid item xs={6}>
                <TextField name='direccion' label='Direccion' fullWidth />
            </Grid>
            <Grid item xs={6}>
                <TextField name='codigo_postal' label='Codigo postal' fullWidth />
            </Grid>
            <Grid item xs={6}>
                <TextField name='poblacion' label='Poblacion' fullWidth />
            </Grid>
            <Grid item xs={6}>
                <TextField name='provincia' label='Provincia' fullWidth />
            </Grid>
        </Grid>
    );
};

const EditForm = withEditForm('companies', CompaniesSchema)(EditFormBase);

export default function CreateCompanyForm({ basePath, onUnauthorized }) {
    const history = useHistory();

    const data = {
        name: '',
        email: '',
        password: '',
        confirm_password: '',
        nif: '',
        telefono: '',
        direccion: '',
        codigo_postal: '',
        poblacion: '',
        provincia: '',
    };

    return (
        <EditForm
            title='Nueva compañia'
            data={data}
            validationSchema={CompaniesSchema}
            basePath={basePath}
            dataProvider={dataProvider}
            onUnauthorized={onUnauthorized}
            onSave={(data) => {
                history.push(`${basePath}/${data.id}`);
            }}
        >
            <TabGeneral />
        </EditForm>
    );
}

CreateCompanyForm.propTypes = {
    basePath: PropTypes.any,
    onUnauthorized: PropTypes.any,
};
