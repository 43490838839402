import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import { proyectosProvider, tareasProvider } from '../../../api';
import SelectJornadasSinAlbaranDialog from './SelectJornadasSinAlbaranDialog';
import { formatDate } from '../../../utils';

export default function GenerarAlbaranForTareaDialog(props) {
    const { data: tarea, fetchData } = useEditFormData();

    return (
        <BaseGenerarAlbaranDialog
            onSave={(selectedJornadas) => tareasProvider.generarAlbaran(tarea.id, selectedJornadas).then(fetchData)}
            {...props}
        />
    );
}

export function GenerarAlbaranForProyectoDialog(props) {
    const { data: proyecto, fetchData } = useEditFormData();

    return (
        <BaseGenerarAlbaranDialog
            onSave={(selectedJornadas) =>
                proyectosProvider.generarAlbaran(proyecto.id, selectedJornadas).then(fetchData)
            }
            itemTitleFn={(jornada) => {
                let baseTitle = `${jornada.tarea.descripcion} | `;
                baseTitle += jornada.operario ? 'Parte de trabajo del ' : 'Imputación de albarán de compra del ';
                return `${baseTitle}${formatDate(jornada.fecha)}`;
            }}
            {...props}
        />
    );
}

export function BaseGenerarAlbaranDialog(props) {
    return <SelectJornadasSinAlbaranDialog title='Generar albarán de servicio' saveButtonText='Generar' {...props} />;
}
