import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { vehiculosProvider } from '../../api';
import DialogForm from '../common/forms/DialogForm';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import PropTypes from 'prop-types';

const VehiculoSchema = Yup.object().shape({
    codigo: Yup.string().required('Requerido'),
    marca: Yup.string().required('Requerido'),
    modelo: Yup.string().required('Requerido'),
    matricula: Yup.string(),
    num_plazas: Yup.number(),
});

function NewVehiculoDialog({ onSave, ...props }) {
    return (
        <DialogForm
            title='Añadir vehiculo'
            FormikProps={{
                initialValues: {
                    codigo: '',
                    marca: '',
                    modelo: '',
                    matricula: '',
                    num_plazas: 0,
                },
                validationSchema: VehiculoSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    vehiculosProvider
                        .save(values)
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField name='codigo' label='Código' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='marca' label='Marca' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='modelo' label='Modelo' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='matricula' label='Matricula' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='num_plazas' label='Número de plazas' fullWidth />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(NewVehiculoDialog);

NewVehiculoDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
