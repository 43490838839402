import { List, ListItem as MuiListItem } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import EventIcon from '@material-ui/icons/Event';
import InfoIcon from '@material-ui/icons/Info';
import addHours from 'date-fns/addHours';
import isValid from 'date-fns/isValid';
import { es } from 'date-fns/locale';
import { useField } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { createTiempo } from '../../../api/tareas-functions';
import { format, formatDate, formatISODate, formatTiempo, setBeginOfDay, setEndOfDay } from '../../../utils';
import DateRangePickerDialog from './DateRangePickerDialog';

const ListItem = withStyles(
    (theme) => ({
        root: {
            '& span': {
                marginLeft: theme.spacing(2),
                color: theme.palette.neutral.grey4,
            },
        },
    }),
    { name: 'ListItem' },
)(MuiListItem);

export const useHasChanged = (val) => {
    const prevVal = usePrevious(val);
    return prevVal !== val;
};

const usePrevious = (value) => {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
};

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'flex-end',
            gap: '8px',
        },
        picker: {
            '& .MuiInputAdornment-positionEnd': {
                margin: 0,
            },
            '& .MuiIconButton-root': {
                padding: 6,
            },
        },
        formControlRoot: {
            flex: 1,
        },
        datePicker: {
            marginRight: theme.spacing(2),
        },
        timePicker: {
            maxWidth: 80,
        },
        arrow: {
            margin: '0 8px',
        },
        datePickerPaper: {
            display: 'flex',
            flexDirection: 'column',
        },
        datePickerPaperFooter: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            alignItems: 'center',
            padding: theme.spacing(0, 2, 1.5, 2),
            '& .MuiTypography-root': {
                flex: 1,
            },
        },
        checkRow: {
            display: 'flex',
            justifyContent: 'space-between',
            '& .MuiFormControlLabel-label': {
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                fontSize: 14,
            },
            '& .MuiFormControlLabel-label .MuiSvgIcon-root': {
                fontSize: 18,
            },
            '& .MuiCheckbox-root': {
                paddingRight: 4,
            },
        },
    }),
    { name: 'DateRangePicker' },
);

const MIN_HOUR = 6;
const MAX_HOUR = 22;

export function DateRangePickerNew({
    fieldKey,
    label,
    allDayKey,
    disabled,
    fechaInicioKey,
    fechaFinKey,
    autoAdjustTime = false,
    helperText,
}) {
    const classes = useStyles();
    const [, { value: fechaInicioValue, error: fechaInicioError }, { setValue: setFechaInicioValue }] =
        useField(fechaInicioKey);
    const [, { value: fechaFinValue, error: fechaFinError }, { setValue: setFechaFinValue }] = useField(fechaFinKey);
    const [, { value: allDay }, { setValue: setAllDay }] = useField(allDayKey);

    const [notAllDayDate, setNotAllDayDate] = useState(formatDate(fechaInicioValue));
    const [dateRange, setDateRange] = useState(
        allDay ? { from: fechaInicioValue, to: fechaFinValue } : fechaInicioValue,
    );

    const keyHasChanged = useHasChanged(fechaInicioKey);

    const [timeRange, setTimeRange] = useState({
        from: isValid(fechaInicioValue) ? format(fechaInicioValue, 'HH:mm') : '',
        to: isValid(fechaFinValue) ? format(fechaFinValue, 'HH:mm') : '',
        fromChanged: false,
        toChanged: false,
    });

    useEffect(() => {
        if (!keyHasChanged) return;

        setNotAllDayDate(formatDate(fechaInicioValue));
        setTimeRange({
            from: isValid(fechaInicioValue) ? format(fechaInicioValue, 'HH:mm') : '',
            to: isValid(fechaFinValue) ? format(fechaFinValue, 'HH:mm') : '',
            fromChanged: false,
            toChanged: false,
        });
    }, [fechaInicioValue, fechaFinValue, keyHasChanged]);

    const [datePickerAnchorEl, setDatePickerAnchorEl] = useState(null);

    const toggleDatePicker = (ev) => {
        const element = ev?.currentTarget;
        setDatePickerAnchorEl((current) => {
            if (current) {
                setDateRange(current.dateRange);
                return null;
            }
            return { element, dateRange };
        });
    };

    const datePickerOpen = Boolean(datePickerAnchorEl);
    const datePickerId = datePickerOpen ? 'date-picker-popper' : undefined;

    function parseTime(value, key, validateOnly = false) {
        if (!value) return;

        // if there is no :, add it two places to the left of the end
        if (value.indexOf(':') < 0) {
            value = value.slice(0, -2) + ':' + value.slice(-2);

            if (!validateOnly) setTimeRange((range) => ({ ...range, [key]: value }));
        }

        // if there is anything that is not a number or :, clear the field
        if (!/^\d{1,2}:\d{1,2}$/.test(value)) {
            if (!validateOnly) setTimeRange((range) => ({ ...range, [key]: '' }));
            return;
        }

        const [hours, minutes] = value.split(':');
        if (hours > 23 || minutes > 59) {
            if (!validateOnly) setTimeRange((range) => ({ ...range, [key]: '' }));
            return;
        }

        return [hours, minutes];
    }

    function onUpdateSingleDay(date, fromTime, toTime) {
        const parsedFromTime = parseTime(fromTime, 'from');
        if (parsedFromTime) {
            const [hours, minutes] = parsedFromTime;
            const fromFecha = new Date(date);
            fromFecha.setHours(hours);
            fromFecha.setMinutes(minutes);
            setFechaInicioValue(fromFecha);
        } else {
            setFechaInicioValue(null);
        }

        const parsedToTime = parseTime(toTime, 'to');
        if (parsedToTime) {
            const [hours, minutes] = parsedToTime;
            const toFecha = new Date(date);
            toFecha.setHours(hours);
            toFecha.setMinutes(minutes);
            setFechaFinValue(toFecha);
        } else {
            setFechaFinValue(null);
        }
    }

    function onFromTimeUpdated(time) {
        const value = parseTime(time, 'from');
        if (!value) return;

        const [hours, minutes] = value;

        const fromFecha = new Date(dateRange);
        fromFecha.setHours(hours);
        fromFecha.setMinutes(minutes);

        const toFecha = addHours(fromFecha, 1);
        setFechaInicioValue(fromFecha);
        setFechaFinValue(toFecha);
        setTimeRange({ from: format(fromFecha, 'HH:mm'), to: format(toFecha, 'HH:mm') });
    }

    function onToTimeUpdated(time) {
        const value = parseTime(time, 'to');
        if (!value) return;

        const [hours, minutes] = value;
        const toFecha = new Date(dateRange);
        toFecha.setHours(hours);
        toFecha.setMinutes(minutes);
        setFechaFinValue(toFecha);
        setTimeRange((range) => ({
            ...range,
            to: format(toFecha, 'HH:mm'),
            toChanged: false,
        }));
    }

    const [fromTimeAnchorEl, setFromTimeAnchorEl] = useState(null);
    const fromTimeOpen = Boolean(fromTimeAnchorEl);

    const handleFromTimeClose = (type) => {
        setFromTimeAnchorEl((current) => (current && current.type === type ? null : current));
    };

    const fromTimeOptions = useMemo(() => {
        const options = [];
        for (let hour = MIN_HOUR; hour <= MAX_HOUR; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                options.push(`${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`);
            }
        }
        return options;
    }, []);

    const toTimeOptions = useMemo(() => {
        if (!timeRange.from) return [];

        const parsedFromTime = parseTime(timeRange.from, 'from', true);
        if (!parsedFromTime) return [];
        const [fromHours, fromMinutes] = parsedFromTime;

        const options = [];
        for (let hour = fromHours; hour <= MAX_HOUR; hour++) {
            const startMinute = hour === fromHours ? Math.ceil(fromMinutes / 15) * 15 : 0;

            for (let minute = startMinute; minute < 60; minute += 15) {
                const value = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

                const elapsedMinutes = (hour - fromHours) * 60 + (minute - fromMinutes);

                const label = (
                    <>
                        {value} <span>{formatTiempo(createTiempo(elapsedMinutes), false, false)}</span>
                    </>
                );
                options.push({ value, label });
            }
        }
        return options;
    }, [timeRange.from]);

    const ITEM_HEIGHT = 32;

    const selectedElementRef = useRef(null);
    const scrollableContainerRef = useRef(null);
    const scrollToElement = () => {
        const selectedEl = selectedElementRef.current;
        const scrollContainer = scrollableContainerRef.current;

        if (selectedEl && scrollContainer) {
            const selectedElementTop = selectedEl.offsetTop;
            const selectedElementHeight = selectedEl.offsetHeight;
            const containerHeight = scrollContainer.offsetHeight;

            // Calculate the scroll position to center the element if possible
            const scrollTo = selectedElementTop + selectedElementHeight / 2 - containerHeight / 2;

            scrollContainer.scrollTop = scrollTo;
        }
    };

    useEffect(() => {
        if (fromTimeOpen && selectedElementRef.current) {
            scrollToElement();
        }
    }, [fromTimeOpen]);

    return (
        <>
            <div className={classes.root}>
                <FormControl
                    error={Boolean(fechaInicioError || fechaFinError)}
                    fullWidth={allDay}
                    classes={{ root: classes.formControlRoot }}
                >
                    {label && (
                        <InputLabel shrink htmlFor='fecha-inicio'>
                            {label}
                        </InputLabel>
                    )}
                    {!allDay ? (
                        <>
                            <Input
                                style={{ flex: 1 }}
                                value={notAllDayDate}
                                onChange={(ev) => {
                                    setNotAllDayDate(ev.target.value);
                                }}
                                onBlur={(ev) => {
                                    const value = ev.target.value;
                                    const date = value.match(/^(\d{1,2})[/-](\d{1,2})[/-](\d{4})$/);
                                    if (!date) return;

                                    const [, day, month, year] = date;
                                    const newDate = new Date(`${year}-${month}-${day}`);
                                    if (!isValid(newDate)) {
                                        setNotAllDayDate('');
                                        return;
                                    }

                                    if (formatISODate(newDate) === formatISODate(dateRange)) return;

                                    setDateRange(newDate);
                                    onUpdateSingleDay(newDate, timeRange.from, timeRange.to);
                                }}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton onClick={toggleDatePicker}>
                                            <EventIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <Popper
                                id={datePickerId}
                                open={datePickerOpen}
                                anchorEl={datePickerAnchorEl?.element}
                                style={{ zIndex: 9999 }}
                            >
                                <Paper className={classes.datePickerPaper}>
                                    <DayPicker
                                        captionLayout='dropdown-buttons'
                                        fromYear={2020}
                                        toYear={2040}
                                        locale={es}
                                        mode={'single'}
                                        selected={dateRange}
                                        onSelect={(date) => {
                                            setDateRange(date);
                                            setNotAllDayDate(date ? formatDate(date) : '');
                                            if (date) {
                                                onUpdateSingleDay(date, timeRange.from, timeRange.to);

                                                setDatePickerAnchorEl(null);
                                            } else {
                                                setFechaInicioValue(null);
                                            }
                                        }}
                                    />
                                </Paper>
                            </Popper>
                        </>
                    ) : (
                        <>
                            <Input
                                fullWidth
                                value={`${formatDate(fechaInicioValue)} - ${formatDate(fechaFinValue)}`}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton onClick={toggleDatePicker}>
                                            <EventIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <DateRangePickerDialog
                                open={datePickerOpen}
                                onClose={() => setDatePickerAnchorEl(null)}
                                onAccept={(dateRange) => {
                                    setFechaInicioValue(dateRange.from);
                                    setFechaFinValue(dateRange.to);
                                }}
                                defaultDateRange={{
                                    from: fechaInicioValue,
                                    to: fechaFinValue,
                                }}
                            />
                        </>
                    )}

                    {fechaInicioError && <FormHelperText id='component-error-text'>{fechaInicioError}</FormHelperText>}
                    {!fechaInicioError && fechaFinError && (
                        <FormHelperText id='component-error-text'>{fechaFinError}</FormHelperText>
                    )}
                    {helperText && <FormHelperText id='component-error-text'>{helperText}</FormHelperText>}
                </FormControl>
                {!allDay && (
                    <>
                        <FormControl error={Boolean(fechaInicioError || fechaFinError)}>
                            <Input
                                style={{ width: 60 }}
                                placeholder={'HH:MM'}
                                value={timeRange.from}
                                onChange={(ev) => {
                                    const value = ev.target.value;
                                    setTimeRange((range) => ({ ...range, from: value, fromChanged: true }));
                                }}
                                onBlur={(ev) => {
                                    if (timeRange.fromChanged) onFromTimeUpdated(ev.target.value);

                                    setTimeout(() => {
                                        handleFromTimeClose('from');
                                    }, 300);
                                }}
                                onClick={(ev) => {
                                    const element = ev.currentTarget;
                                    setFromTimeAnchorEl((target) =>
                                        target && target.type === 'from' ? null : { element, type: 'from' },
                                    );
                                }}
                            />
                        </FormControl>
                        <FormControl error={Boolean(fechaInicioError || fechaFinError)}>
                            <Input
                                style={{ width: 60 }}
                                placeholder={'HH:MM'}
                                value={timeRange.to}
                                onChange={(ev) => {
                                    const value = ev.target.value;
                                    setTimeRange((range) => ({ ...range, to: value, toChanged: true }));
                                }}
                                onBlur={(ev) => {
                                    if (timeRange.toChanged) onToTimeUpdated(ev.target.value);

                                    setTimeout(() => {
                                        handleFromTimeClose('to');
                                    }, 300);
                                }}
                                onClick={(ev) => {
                                    const element = ev.currentTarget;
                                    setFromTimeAnchorEl((target) =>
                                        target && target.type === 'to' ? null : { element, type: 'to' },
                                    );
                                }}
                            />
                        </FormControl>
                        <Popper
                            id='from-time-menu'
                            open={fromTimeOpen}
                            anchorEl={fromTimeAnchorEl?.element}
                            style={{ zIndex: 9999 }}
                            keepMounted
                        >
                            <Paper
                                ref={scrollableContainerRef}
                                style={{ maxHeight: ITEM_HEIGHT * 4.5, overflow: 'auto' }}
                            >
                                <List dense={true}>
                                    {fromTimeAnchorEl?.type === 'from' &&
                                        fromTimeOptions.map((option) => (
                                            <ListItem
                                                button
                                                key={option}
                                                selected={option === timeRange.from}
                                                ref={option === timeRange.from ? selectedElementRef : null}
                                                onClick={() => {
                                                    if (option !== timeRange.from) onFromTimeUpdated(option);
                                                    handleFromTimeClose();
                                                }}
                                            >
                                                {option}
                                            </ListItem>
                                        ))}
                                    {fromTimeAnchorEl?.type === 'to' &&
                                        toTimeOptions.map(({ label, value }) => (
                                            <ListItem
                                                button
                                                key={value}
                                                selected={value === timeRange.to}
                                                ref={value === timeRange.to ? selectedElementRef : null}
                                                onClick={() => {
                                                    if (value !== timeRange.to) onToTimeUpdated(value);
                                                    handleFromTimeClose();
                                                }}
                                            >
                                                {label}
                                            </ListItem>
                                        ))}
                                </List>
                            </Paper>
                        </Popper>
                    </>
                )}
            </div>
            <div className={classes.checkRow}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={allDay}
                            onChange={(ev) => {
                                const allDay = ev.target.checked;
                                setAllDay(allDay);
                                const newFechaInicio = allDay ? setBeginOfDay(fechaInicioValue) : fechaInicioValue;
                                const newFechaFin = allDay ? setEndOfDay(fechaFinValue) : fechaFinValue;
                                setDateRange(allDay ? { from: fechaInicioValue, to: fechaFinValue } : fechaInicioValue);
                                setTimeRange({
                                    from: isValid(newFechaInicio) ? format(fechaInicioValue, 'HH:mm') : '',
                                    to: isValid(newFechaFin) ? format(fechaFinValue, 'HH:mm') : '',
                                });
                                if (allDay) {
                                    setFechaInicioValue(newFechaInicio);
                                    setFechaFinValue(newFechaFin);
                                }
                            }}
                            color='primary'
                        />
                    }
                    label='Todo el dia'
                    disabled={disabled}
                />

                {allDay && (
                    <CheckboxWithLabel
                        type='checkbox'
                        name={`${fieldKey}.extender_automaticamente`}
                        Label={{
                            label: (
                                <>
                                    Auto-extender{' '}
                                    <Tooltip
                                        title='Activa esta opción para que la fecha de fin del servicio se ajuste automáticamente al día actual, manteniéndola vigente hasta que se marque como finalizado.'
                                        arrow
                                    >
                                        <InfoIcon />
                                    </Tooltip>
                                </>
                            ),
                        }}
                        color='primary'
                        disabled={disabled}
                    />
                )}

                <CheckboxWithLabel
                    type='checkbox'
                    name={`${fieldKey}.confirmada`}
                    Label={{
                        label: (
                            <>
                                Confirmada{' '}
                                <Tooltip
                                    title='Opcionalmente, puedes marcar esta casilla si el cliente ha confirmado la visita a la hora planificada. Lo verás reflejado en el calendario.'
                                    arrow
                                >
                                    <InfoIcon />
                                </Tooltip>
                            </>
                        ),
                    }}
                    color='primary'
                    disabled={disabled}
                />
            </div>
        </>
    );
}

DateRangePickerNew.propTypes = {
    fieldKey: PropTypes.any,
    allDayKey: PropTypes.any,
    autoAdjustTime: PropTypes.bool,
    disabled: PropTypes.any,
    fechaFinKey: PropTypes.any,
    fechaInicioKey: PropTypes.any,
    helperText: PropTypes.any,
    label: PropTypes.any,
};
