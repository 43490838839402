import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { formatCurrency, formatNumber } from '../../utils';
import { getTotalesPresupuesto } from '../presupuestos/TotalesPresupuesto';
import PaperList from '../proyectos/PaperList/PaperList';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 400,
            alignSelf: 'flex-end',
        },
    }),
    { name: 'TotalesProyecto' },
);

export default function TotalesProyecto({ proyecto }) {
    const classes = useStyles();

    const totales = useMemo(() => {
        if (proyecto.presupuestos.length === 0) return [];

        let totales = getTotalesPresupuesto(proyecto.presupuestos[0]);
        for (let i = 1; i < proyecto.presupuestos.length; i++) {
            const presupuesto = proyecto.presupuestos[i];
            const presupuestoTotales = getTotalesPresupuesto(presupuesto);
            totales = {
                totalCoste: totales.totalCoste + presupuestoTotales.totalCoste,
                totalVentaSinDescuento: totales.totalVentaSinDescuento + presupuestoTotales.totalVentaSinDescuento,
                totalVenta: totales.totalVenta + presupuestoTotales.totalVenta,
                margen: totales.margen + presupuestoTotales.margen,
            };
        }

        totales.margenPorcentaje = totales.totalVenta !== 0 ? (totales.margen / totales.totalVenta) * 100 : 0;
        totales.descuento =
            totales.totalVentaSinDescuento !== 0 ? (1 - totales.totalVenta / totales.totalVentaSinDescuento) * 100 : 0;

        return [
            {
                label: 'Total coste',
                value: formatCurrency(totales.totalCoste),
            },
            {
                label: 'Total venta (sin descuento)',
                value: formatCurrency(totales.totalVentaSinDescuento),
            },
            {
                label: 'Descuento',
                value: `${formatNumber(totales.descuento)} %`,
            },
            {
                label: 'Total venta',
                value: formatCurrency(totales.totalVenta),
                color: 'info',
            },
            {
                label: 'Margen',
                value: `(${formatNumber(totales.margenPorcentaje)}%) ${formatCurrency(totales.margen)}`,
                color: 'success',
            },
        ];
    }, [proyecto]);

    return <PaperList lineas={totales} className={classes.root} />;
}

TotalesProyecto.propTypes = {
    proyecto: PropTypes.object.isRequired,
};
