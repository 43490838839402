import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { contratosMantenimientoProvider } from '../../api';
import { useFetchData } from '../../hooks/useFetchData';
import { ListaServicios } from '../servicios/ListaServicios/ListaServicios';
import useDefaultServiciosColumns from '../servicios/useDefaultServiciosColumns';

export default function ServiciosList() {
    const { id } = useParams();

    const fetchDataFn = useCallback(() => contratosMantenimientoProvider.getTareas(id), [id]);
    const { data, fetchData, setData } = useFetchData(fetchDataFn);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const columns = useDefaultServiciosColumns(['cliente', 'operarios-visible']);

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: false,
            hideDelete: true,
        }),
        [],
    );

    return (
        <ListaServicios
            tableId='trabajos-contrato'
            tareas={data}
            setTareas={setData}
            columns={columns}
            options={options}
            basePath='/servicios'
            tableTitle='Lista de servicios'
        />
    );
}
