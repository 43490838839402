import { FormLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { useField } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import PropTypes from 'prop-types';

export default function RadioGroupField({ name, label, options }) {
    const [{ value }, , { setValue }] = useField(name);

    return (
        <FormControl>
            <FormLabel>{label}</FormLabel>
            <RadioGroup row name={name} value={value} onChange={(ev) => setValue(ev.target.value)}>
                {options.map(({ value, label }) => (
                    <FormControlLabel key={value} value={value} control={<Radio color='primary' />} label={label} />
                ))}
            </RadioGroup>
        </FormControl>
    );
}

RadioGroupField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ),
};
