import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { checklistsProvider } from '../../../../../api';
import { dataProvider } from '../../../../../api/equipos';
import { useFetchData } from '../../../../../hooks/useFetchData';
import Button from '../../../../common/Button';
import BaseListView, { withTableState } from '../../../../common/list/ListView';
import EditChecklistDialog from './EditChecklistDialog';
import NewChecklistDialog from './NewChecklistDialog';

const ListView = withTableState('delegaciones', BaseListView);

export default function AjustesChecklists({ classes }) {
    const fetchDataFn = useCallback(() => checklistsProvider.getAll(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        dataProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
            {
                Header: 'Activo',
                accessor: 'activo',
                // eslint-disable-next-line react/prop-types
                Cell: ({ value }) => (value ? <DoneIcon /> : null),
                filter: 'equals',
                disableGlobalFilter: true,
                disableFilters: true,
                disableSortBy: true,
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            deleteConfirmationText: 'borrar checklist',
            batchComponents: (
                <NewChecklistDialog
                    button={
                        <Button color='transparent' aria-label='Nueva plantilla' startIcon={<AddIcon />}>
                            Nueva plantilla
                        </Button>
                    }
                    onSave={() => {
                        fetchData();
                    }}
                />
            ),
        }),
        [],
    );

    return (
        <Paper elevation={0}>
            <ListView
                tableTitle={'Plantillas de listas de tareas'}
                basePath='/checklists'
                columns={columns}
                data={data}
                options={options}
                onDelete={onDelete}
                extraActions={(row) => (
                    <>
                        <EditChecklistDialog
                            id={row.id}
                            button={
                                <Tooltip title='Editar plantilla'>
                                    <IconButton>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            onSave={fetchData}
                        />
                    </>
                )}
            />
        </Paper>
    );
}

AjustesChecklists.propTypes = {
    classes: PropTypes.any,
};
