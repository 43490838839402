import MuiAppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HelpIcon from '@material-ui/icons/Help';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import useAuthState, { usePermisos, usePreferencias } from '../../AuthState';
import LaboryLogo from '../icons/LaboryLogo';
import SupportIcon from '../icons/SupportIcon';
import AccountMenu from './AccountMenu';
import { NavListItem } from './AppBarMenu';
import CalendarioMenu from './CalendarioMenu';
import ClientesMenu from './ClientesMenu';
import MantenimientoMenu from './MantenimientoMenu';
import MasMenu from './MasMenu';
import OperariosMenu from './OperariosMenu';
import ProyectosMenu from './ProyectosMenu';
import SearchBar from './SearchBar/SearchBar';
import ServiciosMenu from './ServiciosMenu';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#1E2835',
            boxShadow: 'none',
            '& .MuiIconButton-root': {
                color: 'white',
            },
        },
        menu: {
            display: 'flex',
            flex: 1,
            padding: theme.spacing(2, 0),
            '& .MuiListItemText-primary': {
                whiteSpace: 'nowrap',
            },
        },
        menuLarge: {
            display: 'flex',
            [theme.breakpoints.down(1580)]: {
                display: 'none',
            },
        },
        menuShort: {
            display: 'none',
            [theme.breakpoints.down(1580)]: {
                display: 'flex',
            },
        },
        homeButton: {
            minWidth: 64,
            height: 64,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        actions: {
            display: 'flex',
            alignItems: 'center',
            '&>.MuiIconButton-root': {
                padding: 6,
            },
        },
    }),
    { name: 'AppBar' },
);

export default function AppBar() {
    const {
        isAdmin,
        isCompany,
        isPaymentOk,
        userInfo: { preferencias: { usar_presupuestos: usarPresupuestos } = {} },
    } = useAuthState();

    const [usarSoloControlHorario, usarBolsaHoras, usarDocumentos] = usePreferencias(
        'usar_solo_control_horario',
        'usar_bolsa_horas',
        'usar_documentos',
    );
    const [
        gestionarClientes,
        gestionarMantenimientos,
        gestionarFichajes,
        gestionarVacacionesAusencias,
        gestionarDocumentacion,
    ] = usePermisos(
        'gestionar_clientes',
        'gestionar_mantenimientos',
        'gestionar_fichajes',
        'gestionar_vacaciones_ausencias',
        'gestionar_documentacion',
    );

    const classes = useStyles();

    return (
        <MuiAppBar className={classes.root}>
            <Link to='/' disabled={!isPaymentOk} className={classes.homeButton}>
                <LaboryLogo />
            </Link>
            {isCompany && usarSoloControlHorario ? (
                <List component='div' className={classes.menu}>
                    <NavListItem to='/fichajes' label='Control horario' disabled={!isPaymentOk} />
                    <NavListItem to='/solicitudes' label='Vacaciones y ausencias' disabled={!isPaymentOk} />
                    {usarBolsaHoras && <NavListItem to='/bolsa-horas' label='Bolsa de horas' disabled={!isPaymentOk} />}
                    <NavListItem to='/empleados' label='Empleados' disabled={!isPaymentOk} />
                    {usarDocumentos && gestionarDocumentacion && (
                        <NavListItem to='/documentos' label='Documentos' disabled={!isPaymentOk} />
                    )}
                </List>
            ) : (
                <>
                    <List component='div' className={clsx(classes.menu, classes.menuLarge)}>
                        {isAdmin && <NavListItem to='/companies' label='Compañias' />}
                        {isCompany && (
                            <>
                                {/* <NavListItem to='/' exact label='Servicios' disabled={!isPaymentOk} /> */}
                                <ServiciosMenu disabled={!isPaymentOk} />
                                <CalendarioMenu disabled={!isPaymentOk} />
                                {usarPresupuestos ? (
                                    <ProyectosMenu disabled={!isPaymentOk} />
                                ) : (
                                    <NavListItem to='/proyectos' label='Proyectos' disabled={!isPaymentOk} />
                                )}
                                {gestionarMantenimientos && <MantenimientoMenu disabled={!isPaymentOk} />}
                                <NavListItem to='/materiales' label='Materiales' disabled={!isPaymentOk} />
                                {gestionarFichajes && (
                                    <NavListItem to='/fichajes' label='Fichajes' disabled={!isPaymentOk} />
                                )}
                                {gestionarVacacionesAusencias && (
                                    <NavListItem
                                        to='/solicitudes'
                                        label='Vacaciones y ausencias'
                                        disabled={!isPaymentOk}
                                    />
                                )}
                                <NavListItem to='/facturacion' label='Facturación' disabled={!isPaymentOk} />
                                {gestionarClientes && (
                                    <NavListItem to='/clientes' label='Clientes' disabled={!isPaymentOk} />
                                )}
                                <OperariosMenu disabled={!isPaymentOk} />
                                {/* <AnaliticasMenu disabled={!isPaymentOk} /> */}
                                <MasMenu disabled={!isPaymentOk} />
                            </>
                        )}
                    </List>
                    <List component='div' className={clsx(classes.menu, classes.menuShort)}>
                        {isAdmin && <NavListItem to='/companies' label='Compañias' />}
                        {isCompany && (
                            <>
                                {/* <NavListItem to='/' exact label='Servicios' disabled={!isPaymentOk} /> */}
                                <ServiciosMenu disabled={!isPaymentOk} />
                                <CalendarioMenu disabled={!isPaymentOk} />
                                {gestionarMantenimientos && <MantenimientoMenu disabled={!isPaymentOk} />}
                                <NavListItem to='/materiales' label='Materiales' disabled={!isPaymentOk} />
                                <NavListItem to='/facturacion' label='Facturación' disabled={!isPaymentOk} />

                                <ClientesMenu disabled={!isPaymentOk} />

                                <OperariosMenu disabled={!isPaymentOk} />
                                {/* <AnaliticasMenu disabled={!isPaymentOk} /> */}
                                <MasMenu disabled={!isPaymentOk} />
                            </>
                        )}
                    </List>
                </>
            )}
            <div className={classes.actions}>
                <SearchBar />
                <IconButton
                    onClick={() => {
                        window.Beacon('toggle');
                        window.Beacon('navigate', '/ask/');
                    }}
                >
                    <SupportIcon />
                </IconButton>
                <IconButton
                    onClick={() => {
                        window.open('https://labory.helpscoutdocs.com', '_blank');
                    }}
                >
                    <HelpIcon />
                </IconButton>
            </div>
            <AccountMenu />
        </MuiAppBar>
    );
}
