import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { companiesProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { KeyboardDatePicker } from '../common/fields/KeyboardDatePicker';
import { SwitchWithLabelField } from '../common/fields/Switch';
import DialogForm from '../common/forms/DialogForm';

const TarifaSchema = Yup.object().shape({
    numero_licencias: Yup.number().required('Requerido').min(1, 'Debe ser mayor o igual a 1'),
    codigo_promocional: Yup.string().nullable(),
    pago_mensual: Yup.boolean().required('Requerido'),
    fecha_proximo_pago: Yup.date().typeError('Fecha inválida').nullable(),
    codigo_tarifa: Yup.string(),
});

function EditTarifaDialog({ companyId, tarifa, onSave, ...props }) {
    const snackbar = useSnackbar();

    return (
        <DialogForm
            title='Editar tarifa'
            FormikProps={{
                initialValues: {
                    numero_licencias: tarifa ? tarifa.numero_licencias : 0,
                    codigo_promocional: tarifa ? tarifa.codigo_promocional : '',
                    pago_mensual: tarifa ? tarifa.pago_mensual : false,
                    fecha_proximo_pago: tarifa ? new Date(tarifa.fecha_proximo_pago) : null,
                    codigo_tarifa: tarifa ? tarifa.codigo_tarifa : '',
                },
                validationSchema: TarifaSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    setSubmitting(false);

                    companiesProvider
                        .updateTarifa(companyId, values)
                        .then((res) => {
                            props.onClose();
                            onSave && onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                if (err.message instanceof Object) {
                                    for (const [field, errors] of Object.entries(err.message)) {
                                        setFieldError(field, errors.join('\n'));
                                    }
                                } else {
                                    snackbar.showMessage(err.message);
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField name='numero_licencias' label='Número de licencias' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name='codigo_promocional' label='Código promocional' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name='codigo_tarifa'
                            label='Código de tarifa'
                            fullWidth
                            helperText='Dejalo en blanco para aplicar la tarifa por defecto'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SwitchWithLabelField name='pago_mensual' label='Pago mensual' />
                    </Grid>
                    <Grid item xs={12}>
                        <KeyboardDatePicker name='fecha_proximo_pago' label='Fecha de proximo pago' fullWidth />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(EditTarifaDialog);

EditTarifaDialog.propTypes = {
    companyId: PropTypes.any,
    tarifa: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
