import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import PaperTextEditor from '../../common/forms/PaperTextEditor';
import { preparacionesMaterialProvider } from '../../../api';
import MuiTable from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';
import TableBody from '@material-ui/core/TableBody';
import { formatDate, formatDateTime } from '../../../utils';
import CompraMaterialEstadoChip from '../CompraMaterialEstadoChip';
import EditarCompraMaterialDialog from './EditarCompraMaterialDialog';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteDialog from '../../common/dialogs/DeleteDialog';

const useStyles = makeStyles(
    (theme) => ({
        title: {
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.divider}`,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            '& .MuiTypography-root': {
                flex: 1,
                fontWeight: theme.typography.fontWeightMedium,
                lineHeight: '50px',
            },
            '& .MuiIconButton-root': {
                padding: 8,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        body: {
            padding: theme.spacing(2),
        },
        textarea: {
            '& .MuiInput-root': {
                fontSize: 14,
            },
            '& .Mui-disabled': {
                color: theme.palette.text.primary,
            },
            '& .MuiInput-underline.Mui-disabled::before': {
                borderWidth: 0,
            },
        },
        actions: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
    { name: 'CompraMaterialPaper' },
);

const Table = withStyles(
    (theme) => ({
        root: {
            margin: theme.spacing(0, 0, 1, 0),
        },
    }),
    { name: 'DenseTable' },
)(MuiTable);

const TableCell = withStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(0.5, 2),
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
    }),
    { name: 'DenseTableCell' },
)(MuiTableCell);

export default function CompraMaterialPaper({ compraMaterial, onSave, className }) {
    const classes = useStyles();
    return (
        <PaperTextEditor
            title={`Compra de material ${
                compraMaterial.referencia !== '' ? compraMaterial.referencia : 'sin referencia'
            }`}
            value={compraMaterial.notas}
            className={classes.root}
            canEdit={compraMaterial.estado !== 'VERIFICADO'}
            placeholder='Escribe aquí notas referentes a la compra de material'
            onSave={(value) => {
                preparacionesMaterialProvider.updateNotasCompra(compraMaterial.id, value).then(onSave);
            }}
            forceEdit
            extraActions={
                <>
                    <EditarCompraMaterialDialog
                        compraMaterial={compraMaterial}
                        button={
                            <Tooltip title='Editar compra de material'>
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        onSave={onSave}
                    />
                    <DeleteDialog
                        onConfirm={() => preparacionesMaterialProvider.deleteCompra(compraMaterial.id).then(onSave)}
                        text='¿Estás seguro que quieres borrar esta compra de material?'
                        confirmationText='borrar compra'
                        button={
                            <Tooltip title='Borrar compra de material'>
                                <IconButton>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    />
                </>
            }
        >
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>Referencia</TableCell>
                        <TableCell>Fecha solicitud</TableCell>
                        <TableCell>Llegada prevista</TableCell>
                        <TableCell>Fecha llegada</TableCell>
                        <TableCell>Estado</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            {compraMaterial.referencia !== '' ? compraMaterial.referencia : <em>Sin referencia</em>}
                        </TableCell>
                        <TableCell>{formatDateTime(compraMaterial.fecha_solicitud)}</TableCell>
                        <TableCell>
                            {compraMaterial.fecha_llegada_prevista ? (
                                formatDate(compraMaterial.fecha_llegada_prevista)
                            ) : (
                                <em>Sin fecha</em>
                            )}
                        </TableCell>
                        <TableCell>
                            {compraMaterial.fecha_llegada ? (
                                formatDate(compraMaterial.fecha_llegada)
                            ) : (
                                <em>Sin fecha</em>
                            )}
                        </TableCell>
                        <TableCell>
                            <CompraMaterialEstadoChip value={compraMaterial.estado} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </PaperTextEditor>
    );
}

CompraMaterialPaper.propTypes = {
    className: PropTypes.string,
    compraMaterial: PropTypes.any,
    onSave: PropTypes.func.isRequired,
};
