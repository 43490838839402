import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { presupuestosProvider } from '../../../api';
import SearchLineaDialog from './SearchLineaDialog';

export default function ReferenciaField({ proyectoId, presupuestoId, tipoLinea, onSelect, clearSelection }) {
    return (
        <TextField
            name='referencia'
            label='Referencia'
            fullWidth
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <SearchLineaDialog
                            presupuestoId={presupuestoId}
                            proyectoId={proyectoId}
                            tipoLinea={tipoLinea}
                            button={
                                <IconButton size='small'>
                                    <SearchIcon />
                                </IconButton>
                            }
                            onSelect={onSelect}
                        />
                    </InputAdornment>
                ),
                onFocus: clearSelection,
                onBlur: (ev) => {
                    const referencia = ev.target.value.trim();

                    if (referencia === '' || referencia === null) return;

                    const params = {
                        referencia,
                        tipo_linea: tipoLinea,
                    };
                    if (proyectoId) {
                        params.proyecto_id = proyectoId;
                    } else {
                        params.presupuesto_id = presupuestoId;
                    }
                    presupuestosProvider.searchLinea(params).then((lineas) => {
                        const linea = lineas[0];

                        if (!linea) return;

                        onSelect(linea);
                    });
                },
            }}
        />
    );
}

ReferenciaField.propTypes = {
    proyectoId: PropTypes.number,
    presupuestoId: PropTypes.number,
    tipoLinea: PropTypes.string,
    onSelect: PropTypes.func,
    clearSelection: PropTypes.func,
};
