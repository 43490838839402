import { OperarioChip } from './OperarioChip';
import { defaultColor } from './constants';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ColorizeIcon from '@material-ui/icons/Colorize';
import OperarioColorEditor from './OperarioColorEditor';
import clsx from 'clsx';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
        },
        item: {
            display: 'flex',
            alignItems: 'center',
            paddingRight: 26,
            '&.hovered': {
                paddingRight: 0,
            },
        },
        changeColorButton: {
            marginLeft: 'auto',
        },
    }),
    { name: 'OperariosSelector' },
);
export function OperariosSelector({
    enabledSinAsignar,
    setEnabledSinAsignar,
    selectedOperarioIds,
    setSelectedOperarioIds,
    operarios,
    onColorChanged,
    soloActivos,
}) {
    const [hoveredItem, setHoveredItem] = useState(null);

    const classes = useStyles();
    function toggleOperario(operarioId) {
        setSelectedOperarioIds((selectedOperarioIds) => {
            const idx = selectedOperarioIds.findIndex((op) => op === operarioId);
            if (idx < 0) return [...selectedOperarioIds, operarioId];

            return selectedOperarioIds.filter((op) => op !== operarioId);
        });
    }

    function isEnabled(operarioId) {
        return selectedOperarioIds.includes(operarioId);
    }

    const filteredOperarios = useMemo(() => {
        if (!soloActivos) return operarios;
        return operarios.filter((op) => op.activo);
    }, [operarios, soloActivos]);

    return (
        <div className={classes.root}>
            <div className={classes.item}>
                <OperarioChip
                    onClick={() => setEnabledSinAsignar((enabled) => !enabled)}
                    label={'Sin asignar'}
                    disabled={!enabledSinAsignar}
                    backgroundColor={defaultColor}
                />
            </div>
            {filteredOperarios.map((operario) => (
                <div
                    className={clsx(classes.item, hoveredItem === operario.id && 'hovered')}
                    key={operario.id}
                    onMouseEnter={() => setHoveredItem(operario.id)}
                    onMouseLeave={() => setHoveredItem(null)}
                >
                    <OperarioChip
                        onClick={() => toggleOperario(operario.id)}
                        label={operario.nombre}
                        disabled={!isEnabled(operario.id)}
                        backgroundColor={operario.color.color_bg}
                        textColor={operario.color.color_fg}
                    />
                    {hoveredItem === operario.id && (
                        <OperarioColorEditor
                            operario={operario}
                            onSave={(values) => onColorChanged(operario.id, values)}
                            button={
                                <Tooltip title='Cambiar color' arrow>
                                    <IconButton
                                        aria-label='Cambiar color'
                                        size='small'
                                        className={classes.changeColorButton}
                                    >
                                        <ColorizeIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            }
                        />
                    )}
                </div>
            ))}
        </div>
    );
}

OperariosSelector.propTypes = {
    enabledSinAsignar: PropTypes.any,
    onColorChanged: PropTypes.any,
    operarios: PropTypes.any,
    selectedOperarioIds: PropTypes.any,
    setEnabledSinAsignar: PropTypes.any,
    setSelectedOperarioIds: PropTypes.any,
    soloActivos: PropTypes.any,
};
