import { Route, Switch } from 'react-router-dom';
import MaterialesList from './MaterialesList';

export default function MaterialesModule() {
    const onError = (err) => console.error(err);

    return (
        <>
            <Switch>
                <Route path='/materiales'>
                    <MaterialesList onError={onError} />
                </Route>
            </Switch>
        </>
    );
}
