import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withButtonOpener } from '../../../../../hooks/withButtonOpener';
import ColorEditorDialog from '../../../../common/dialogs/ColorEditor/ColorEditorDialog';
import ColorSwatch from '../../../../common/dialogs/ColorEditor/ColorSwatch';

function CategoriaColorEditor({ color, onChange, ...props }) {
    const theme = useTheme();
    const defaultColor = {
        color_bg: theme.palette.primary.hover,
        color_fg: theme.palette.text.primary,
    };

    return (
        <>
            <ColorSwatch color={color.color_bg === null ? defaultColor : color} />
            <ColorEditorDialog
                title='Editar color de la categoría'
                backgroundColor={color.color_bg}
                textColor={color.color_fg}
                onSaveColor={() => Promise.resolve()}
                onSave={onChange}
                colors={[defaultColor]}
                {...props}
            />
        </>
    );
}

CategoriaColorEditor.propTypes = {
    color: PropTypes.any,
    onChange: PropTypes.any,
    open: PropTypes.bool,
    onClose: PropTypes.any,
};

export default withButtonOpener(CategoriaColorEditor);
