import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { companiesProvider } from '../../../../../../api';
import { withButtonOpener } from '../../../../../../hooks/withButtonOpener';
import DialogForm from '../../../../../common/forms/DialogForm';

const TipoHoraSchema = Yup.object().shape({
    codigo: Yup.string().required('Requerido'),
    nombre: Yup.string().required('Requerido'),
});

const useStyles = makeStyles(
    (theme) => ({
        dialogPaper: {},
    }),
    { name: 'EditTipoHoraDialog' },
);

function EditTipoHoraDialog({ id, onSave, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const [tipoHora, setTipoHora] = useState(null);

    useEffect(() => {
        if (!props.open) {
            setTipoHora(null);
            return;
        }

        companiesProvider.getTipoHora(id).then(setTipoHora);
    }, [id, props.open]);

    if (!tipoHora) return null;

    const { codigo, nombre } = tipoHora;

    return (
        <DialogForm
            title='Editar tipo de hora'
            maxWidth='sm'
            className={classes.dialogPaper}
            FormikProps={{
                initialValues: {
                    codigo,
                    nombre,
                },
                enableReinitialize: true,
                validationSchema: TipoHoraSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    companiesProvider
                        .updateTipoHora(id, values)
                        .then((data) => {
                            onSave && onSave(data.id);
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField name='codigo' label='Codigo' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='nombre' label='Nombre' fullWidth />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(EditTipoHoraDialog);

EditTipoHoraDialog.propTypes = {
    id: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};
