import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { useEffect, useMemo, useState } from 'react';
import { companiesProvider, operariosProvider } from '../../../api';
import { formatPeriodo } from '../../../utils';
import Button from '../../common/Button';
import DeleteDialog from '../../common/dialogs/DeleteDialog';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import InfoTooltip from '../../common/InfoTooltip';
import ListView from '../../common/list/ListView';
import NuevoHorarioDialog from './NuevoHorarioDialog';

export default function HorariosTable() {
    const { id: operarioId, data: horarios, fetchData } = useEditFormData('horarios');
    const [horarioNamesById, setHorarioNamesById] = useState([]);

    useEffect(() => {
        companiesProvider.getHorariosEmpresaAsOptions().then((res) => {
            setHorarioNamesById(Object.fromEntries(res.map((option) => [option.id, option.nombre])));
        });
    }, []);

    const horariosData = useMemo(() => {
        return horarios.map((horario, i) => ({
            ...horario,
            nombre: horarioNamesById[horario.horario_id] ?? 'Personalizado',
            fin: horarios[i - 1]?.fecha,
            inicio: horario.fecha,
        }));
    }, [horarios, horarioNamesById]);

    const columns = useMemo(
        () => [
            {
                Header: 'Periodo',
                id: 'periodo',
                accessor: (row) => formatPeriodo(row.inicio, row.fin),
            },
            {
                Header: 'Horario',
                accessor: 'nombre',
                // eslint-disable-next-line react/prop-types
                Cell: ({ value, row }) => <InfoTooltip label={value} content={row.original.descripcion} />,
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            addRoute: null,
            useActions: true,
            tableOptions: {
                usePagination: false,
                useSorting: false,
            },
            batchComponents: (
                <NuevoHorarioDialog button={<Button color='info'>Registrar un cambio de horario</Button>} />
            ),
        }),
        [],
    );

    return (
        <ListView
            tableTitle='Historial de cambios de horario'
            columns={columns}
            data={horariosData}
            options={options}
            extraActions={(row, idx) => (
                <DeleteDialog
                    text='¿Estás seguro que quieres eliminar este cambio de horario?'
                    onConfirm={() => {
                        operariosProvider.deleteHorarios(operarioId, idx).then(fetchData);
                    }}
                    button={
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    }
                />
            )}
        />
    );
}
