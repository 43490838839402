import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ChatIcon from '@material-ui/icons/Chat';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import { Field, useField, useFormikContext } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import useAuthState from '../../AuthState';
import { formatDateTime } from '../../utils';
import DeleteDialog from '../common/dialogs/DeleteDialog';
import { DateRangePickerNew, useHasChanged } from '../common/fields/DateRangePickerNew';
import { KeyboardTimePicker } from '../common/fields/KeyboardDatePicker';
import { SwitchWithoutLabel } from '../common/fields/Switch';
import VehiculoField from '../common/fields/VehiculosField';
import OperariosPlanificacionField from './OperariosPlanificacionField';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            padding: theme.spacing(2),
            borderRadius: 8,
            border: `1px solid ${theme.palette.neutral.grey1}`,
        },
        planificacion: {
            flex: 1,
            display: 'flex',
            flexWrap: 'wrap',
            gap: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        },
        column: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            minWidth: 430,
            '$planificacion>&': {
                gap: `${theme.spacing(2)}px`,
            },
        },
        row: {
            display: 'flex',
        },
        checkRow: {
            display: 'flex',
            justifyContent: 'space-between',
            '& .MuiFormControlLabel-label': {
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                fontSize: 14,
            },
            '& .MuiFormControlLabel-label .MuiSvgIcon-root': {
                fontSize: 18,
            },
            '& .MuiCheckbox-root': {
                paddingRight: 4,
            },
        },
        operariosAsignados: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
            '$small &': {
                display: 'none',
            },
            '&>.MuiTypography-root:first-of-type': {
                fontWeight: 'normal',
                color: 'rgba(0, 0, 0, 0.54)',
            },
        },
        operarioAsignadoRow: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(1)}px`,
            '& .MuiTypography-root': {
                fontWeight: 'normal',
            },
            '&>.MuiTypography-root:first-of-type': {
                color: 'rgba(0, 0, 0, 0.54)',
                flex: 1,
            },
            '&>div': {
                width: 95,
                display: 'flex',
                alignItems: 'center',
                gap: `${theme.spacing(0.5)}px`,
            },
        },
        operarioAsignadoError: {
            color: theme.palette.error.main,
            fontWeight: 'normal',
        },
        addButton: {
            marginLeft: 'auto',
        },
        empty: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            margin: 'auto',
            gap: `${theme.spacing(3)}px`,
            width: 430,
        },
        emptyText: {
            color: theme.palette.neutral.grey4,
        },
        picker: {
            '& .MuiInputAdornment-positionEnd': {
                margin: 0,
            },
            '& .MuiIconButton-root': {
                padding: 6,
            },
        },
        actions: {
            display: 'flex',
            alignItems: 'flex-start',
        },
    }),
    { name: 'PlanificacionFormItem' },
);

function PlanificacionFormItemActions({ fieldKey, disabled, onDelete, onShowMessage }) {
    const classes = useStyles();

    const [, { value: mensaje }] = useField(`${fieldKey}.mensaje`);

    return (
        <div className={classes.actions}>
            <Tooltip
                arrow
                title={mensaje ? 'Esta planificación tiene un mensaje' : 'Añadir mensaje para los operarios'}
            >
                <IconButton size='small' onClick={onShowMessage}>
                    <ChatIcon fontSize='small' color={mensaje ? 'primary' : 'default'} />
                </IconButton>
            </Tooltip>
            <DeleteDialog
                button={
                    <IconButton size='small' disabled={disabled}>
                        <DeleteIcon fontSize='small' />
                    </IconButton>
                }
                text='¿Estás seguro que deseas borrar esta planificación?'
                onConfirm={onDelete}
            />
        </div>
    );
}

PlanificacionFormItemActions.propTypes = {
    fieldKey: PropTypes.string,
    disabled: PropTypes.bool,
    onDelete: PropTypes.func,
    onShowMessage: PropTypes.func,
};

export default function PlanificacionFormItem({ onDelete, deleteDisabled, ...props }) {
    const classes = useStyles();
    const [showMensaje, setShowMensaje] = useState(false);

    return (
        <div className={classes.root}>
            <BasePlanificacionFormItem {...props} showMensaje={showMensaje} />
            <PlanificacionFormItemActions
                fieldKey={props.fieldKey}
                disabled={props.disabled || deleteDisabled}
                onDelete={onDelete}
                onShowMessage={() => setShowMensaje((v) => !v)}
            />
        </div>
    );
}

PlanificacionFormItem.propTypes = {
    deleteDisabled: PropTypes.any,
    onDelete: PropTypes.any,
    disabled: PropTypes.bool,
    fieldKey: PropTypes.string,
};

export function BasePlanificacionFormItem({ fieldKey, planificacion, fixedOperarioIds, disabled, showMensaje = true }) {
    const classes = useStyles();
    const { touched, errors } = useFormikContext();

    const {
        userInfo: {
            preferencias: { usar_nueva_ui_operarios: usarNuevaUiOperarios, usar_responsables: usarResponsables } = {},
        },
    } = useAuthState();

    const fechaInicioKey = `${fieldKey}.fecha_inicio`;
    const fechaFinKey = `${fieldKey}.fecha_fin`;
    const fechaInicioJornadaKey = `${fieldKey}.fecha_inicio_jornada`;
    const allDayKey = `${fieldKey}.allDay`;
    const [, , { setValue: setFechaInicioJornada }] = useField(fechaInicioJornadaKey);
    const fechaInicioHasChanged = useHasChanged(planificacion.fecha_inicio);
    const allDayChanged = useHasChanged(planificacion.allDay);
    const usarFechaInicioJornadaChanged = useHasChanged(planificacion.usarFechaInicioJornada);
    const [, { error: operariosAsignadosError }] = useField(`${fieldKey}.operarios`);

    const {
        userInfo: {
            preferencias: { usar_hora_inicio_jornada: usarHoraInicioJornada },
        },
    } = useAuthState();

    useEffect(() => {
        if (allDayChanged && planificacion.allDay) {
            setFechaInicioJornada(null);
        }
    }, [allDayChanged, setFechaInicioJornada]);

    useEffect(() => {
        if (planificacion.allDay || !fechaInicioHasChanged || planificacion.fecha_visita === null) return;

        setFechaInicioJornada(planificacion.fecha_inicio);
    }, [fechaInicioHasChanged, planificacion.fecha_inicio, planificacion.allDay]);

    useEffect(() => {
        if (!planificacion.usarFechaInicioJornada && usarFechaInicioJornadaChanged) {
            setFechaInicioJornada(null);
        }
    }, [usarFechaInicioJornadaChanged, planificacion.usarFechaInicioJornada]);

    return (
        <div className={classes.planificacion}>
            <div className={classes.column}>
                <div className={classes.row}>
                    <div className={classes.column}>
                        <DateRangePickerNew
                            fieldKey={fieldKey}
                            label='Fecha prevista de ejecución'
                            allDayKey={allDayKey}
                            fechaInicioKey={fechaInicioKey}
                            fechaFinKey={fechaFinKey}
                            autoAdjustTime
                            disabled={disabled && planificacion.id}
                        />
                        {usarHoraInicioJornada && (
                            <div className={classes.checkRow}>
                                <CheckboxWithLabel
                                    type='checkbox'
                                    name={`${fieldKey}.usarFechaInicioJornada`}
                                    Label={{
                                        label: 'Inicio de jornada diferente al habitual',
                                    }}
                                    disabled={disabled && planificacion.id}
                                />
                                {planificacion.usarFechaInicioJornada && (
                                    <KeyboardTimePicker
                                        name={fechaInicioJornadaKey}
                                        disabled={disabled && planificacion.id}
                                        InputLabelProps={{ shrink: true }}
                                        className={classes.picker}
                                        style={{ maxWidth: 90 }}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <VehiculoField
                    vehiculoKey={`${fieldKey}.vehiculo`}
                    disabled={disabled && planificacion.id}
                    fechaInicio={planificacion.fecha_inicio}
                    fechaFin={planificacion.fecha_fin}
                    planificacionId={planificacion.id}
                    operarios={planificacion.operarios}
                    className={classes.vehiculoField}
                />
            </div>
            <div className={clsx(classes.column, classes.operariosField)}>
                <OperariosPlanificacionField
                    fechaInicio={planificacion.fecha_inicio}
                    fechaFin={planificacion.fecha_fin}
                    planificacionId={planificacion.id}
                    fixedOperarioIds={fixedOperarioIds}
                    operariosKey={`${fieldKey}.operarios`}
                    error={touched.operarios && !!errors.operarios}
                    helperText={touched.operarios && errors.operarios}
                />
                {showMensaje && (
                    <TextField
                        name={`${fieldKey}.mensaje`}
                        label='Nota para los operarios'
                        fullWidth
                        multiline
                        minRows={4}
                        InputLabelProps={{ shrink: true }}
                    />
                )}
                {planificacion.operarios.length > 0 && (
                    <div className={classes.operariosAsignados}>
                        <div className={classes.operarioAsignadoRow}>
                            <Typography variant='subtitle2'></Typography>
                            <div>
                                <Typography variant='subtitle2'>Mostrar</Typography>
                                <Tooltip
                                    title='Puedes especificar si el operario verá o no el servicio en su móvil'
                                    arrow
                                >
                                    <InfoIcon fontSize='small' />
                                </Tooltip>
                            </div>
                            {usarNuevaUiOperarios && usarResponsables && (
                                <div>
                                    <Typography variant='subtitle2'>Responsable</Typography>
                                    <Tooltip
                                        title='Puedes especificar quién es el responsable en esta planificación, por ejemplo para que los operarios sepan quien es el referente'
                                        arrow
                                    >
                                        <InfoIcon fontSize='small' />
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                        <div>
                            {planificacion.operarios.map((operarioAsignado, j) => (
                                <div key={j} className={classes.operarioAsignadoRow}>
                                    <Typography variant='subtitle1'>{operarioAsignado.operario.nombre}</Typography>
                                    <div>
                                        <Field
                                            name={`${fieldKey}.operarios.${j}.visible`}
                                            component={SwitchWithoutLabel}
                                        />
                                        {operarioAsignado.fecha_salida && (
                                            <Tooltip
                                                arrow
                                                title={`Salió el ${formatDateTime(operarioAsignado.fecha_salida)}`}
                                            >
                                                <WarningIcon
                                                    fontSize='small'
                                                    className={classes.operarioAsignadoError}
                                                />
                                            </Tooltip>
                                        )}
                                    </div>
                                    {usarNuevaUiOperarios && usarResponsables && (
                                        <div>
                                            <Field
                                                name={`${fieldKey}.operarios.${j}.es_responsable`}
                                                component={SwitchWithoutLabel}
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                            {operariosAsignadosError && (
                                <Typography variant='subtitle2' className={classes.operarioAsignadoError}>
                                    {operariosAsignadosError}
                                </Typography>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

BasePlanificacionFormItem.propTypes = {
    deleteDisabled: PropTypes.any,
    disabled: PropTypes.any,
    fieldKey: PropTypes.any,
    fixedOperarioIds: PropTypes.any,
    onDelete: PropTypes.any,
    planificacion: PropTypes.any,
    small: PropTypes.bool,
    BasePlanificacionFormItem: PropTypes.bool,
    showMensaje: PropTypes.bool,
};
