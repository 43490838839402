import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { presupuestosProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import DialogForm from '../../common/forms/DialogForm';

const AplicarMargenSchema = Yup.object().shape({
    margen: Yup.number().required('Requerido').min(0, 'Debe ser mayor o igual a 0'),
});

function AplicarMargenDialog({ presupuestoId, lineaId, onSave, ...props }) {
    return (
        <DialogForm
            title='Aplicar margen a las partidas'
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    margen: 0,
                },
                validationSchema: AplicarMargenSchema,
                onSubmit: ({ margen }, { setSubmitting, setFieldError }) => {
                    presupuestosProvider
                        .aplicarMargen(presupuestoId, lineaId, margen)
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name='margen'
                            label='Margen'
                            fullWidth
                            type='number'
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

AplicarMargenDialog.propTypes = {
    presupuestoId: PropTypes.string,
    lineaId: PropTypes.string,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
};

export default withButtonOpener(AplicarMargenDialog);
