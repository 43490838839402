import PropTypes from 'prop-types';
import { presupuestosProvider } from '../../../api';
import { estadoPresupuestoLabels } from '../../../api/presupuestos';
import { formatFullDateTime } from '../../../utils';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterDetailsHeader from '../../common/forms/MasterDetailsView/MasterDetailsHeader';
import EmptyPresupuestoHero from '../../presupuestos/EmptyPresupuestoHero';
import { BasePresupuestoTable } from '../../presupuestos/PresupuestoView/PresupuestoTable';
import TotalesPresupuesto from '../../presupuestos/TotalesPresupuesto';

export default function PresupuestoDetails({ index }) {
    const { data: presupuesto, updateData, fetchData } = useEditFormData(`presupuestos.${index}`);

    const headerItems = [{ label: 'Creado el', value: formatFullDateTime(presupuesto.fecha_creacion) }];

    if (presupuesto.fecha_envio)
        headerItems.push({ label: 'Enviado el', value: formatFullDateTime(presupuesto.fecha_envio) });

    if (presupuesto.fecha_final)
        headerItems.push({
            label: `${estadoPresupuestoLabels[presupuesto.estado]} el`,
            value: formatFullDateTime(presupuesto.fecha_final),
        });

    if (presupuesto.lineas && presupuesto.lineas.length === 0) {
        return <EmptyPresupuestoHero onSave={fetchData} presupuestoId={presupuesto.id} />;
    }

    return (
        <>
            <MasterDetailsHeader title='Presupuesto:' subtitle={presupuesto.nombre} items={headerItems} />
            <BasePresupuestoTable
                presupuesto={presupuesto}
                fetchData={() => {
                    presupuestosProvider.getById(presupuesto.id).then((data) => {
                        updateData((proyecto) => ({
                            ...proyecto,
                            presupuestos: proyecto.presupuestos.map((presupuestoOld) =>
                                presupuestoOld.id === presupuesto.id ? { ...presupuestoOld, ...data } : presupuestoOld,
                            ),
                            avances_obra: null,
                            certificaciones_obra: null,
                        }));
                    });
                }}
            />
            <TotalesPresupuesto presupuesto={presupuesto} />
        </>
    );
}

PresupuestoDetails.propTypes = {
    index: PropTypes.any.isRequired,
};
