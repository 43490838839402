import { operariosProvider } from '../../../api';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import DialogEditor from '../../common/forms/DialogEditor';
import * as Yup from 'yup';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {},
        searchIcon: {
            fontSize: 20,
            color: theme.palette.neutral.primary,
        },
        fields: {
            gap: `${theme.spacing(2)}px`,
        },
        field: {
            flex: 1,
        },
    }),
    { name: 'DiasVacacionesDialog' },
);

const DiasVacacionesSchema = Yup.object().shape({
    dias_vacaciones: Yup.array().of(
        Yup.object().shape({
            year: Yup.number(),
            dias: Yup.number()
                .typeError('Debe ser un numero')
                .integer('Debe ser un numero')
                .moreThan(-1, 'No puede ser negativo'),
        }),
    ),
});

function DiasVacacionesDialog({ index, onSave, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const {
        data: { id: operarioId, dias_vacaciones: diasVacaciones },
        fetchData,
    } = useEditFormData();

    return (
        <Formik
            initialValues={{
                dias_vacaciones: diasVacaciones,
            }}
            enableReinitialize
            validationSchema={DiasVacacionesSchema}
            onSubmit={({ dias_vacaciones: diasVacaciones }, actions) => {
                operariosProvider
                    .actionOnId(operarioId, 'dias_vacaciones', diasVacaciones)
                    .then((result) => {
                        fetchData();
                        props.onClose();
                    })
                    .catch((err) => {
                        if (err.status === 400) {
                            if (err.message instanceof Object) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    actions.setFieldError(field, errors.join('\n'));
                                }
                            } else {
                                snackbar.showMessage(err.message);
                            }
                        }
                        actions.setSubmitting(false);
                    });
            }}
        >
            {({ dirty, values, submitForm, setFieldValue, isSubmitting }) => (
                <Form style={{ position: 'absolute' }}>
                    <DialogEditor title='Días de vacaciones' onSave={submitForm} className={classes.root} {...props}>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <TextField name={`dias_vacaciones[${index}].dias`} label='Número de dias' fullWidth />
                            </Grid>
                        </Grid>
                    </DialogEditor>
                </Form>
            )}
        </Formik>
    );
}

export default withButtonOpener(DiasVacacionesDialog);

DiasVacacionesDialog.propTypes = {
    index: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
