import Chip from '@material-ui/core/Chip';
import MuiTextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import debounce from '@material-ui/core/utils/debounce';
import StarIcon from '@material-ui/icons/Star';
import isValid from 'date-fns/isValid';
import { Field, useField } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { operariosProvider } from '../../api';
import PlanificacionFormItemSelectOption from './PlanificacionFormItemSelectOption';

export default function OperariosPlanificacionField({
    fixedOperarioIds,
    error,
    helperText,
    disabled,
    operariosKey = 'operarios',
    fechaInicio,
    fechaFin,
    planificacionId,
}) {
    const [{ value }, , { setValue }] = useField(operariosKey);
    const [operariosOptions, setOperariosOptions] = useState([]);

    const updateOperariosOptions = useCallback(
        debounce((fechaInicio, fechaFin) => {
            operariosProvider.getPlanificacionOptions(fechaInicio, fechaFin, planificacionId).then((options) =>
                setOperariosOptions(
                    options.map((option) => ({
                        fecha_salida: null,
                        visible: true,
                        operario: option,
                        es_responsable: option.es_responsable_operarios,
                    })),
                ),
            );
        }, 1000),
        [],
    );

    useEffect(() => {
        if (!isValid(fechaInicio) || !isValid(fechaFin)) {
            setOperariosOptions((options) => (options.length > 0 ? [] : options));
            return;
        }

        updateOperariosOptions(fechaInicio, fechaFin);
        // operariosProvider.getPlanificacionOptions(fechaInicio, fechaFin, planificacionId).then((options) =>
        //     setOperariosOptions(
        //         options.map((option) => ({
        //             fecha_salida: null,
        //             visible: true,
        //             operario: option,
        //         })),
        //     ),
        // );
    }, [fechaInicio, fechaFin]);

    const fixedOptions = useMemo(() => {
        return operariosOptions
            .filter((e) => fixedOperarioIds.includes(e.operario.id))
            .map((o) => ({
                ...o,
                ...value.find((v) => v.operario.id === o.operario.id),
            }));
    }, [fixedOperarioIds, operariosOptions, value]);

    return (
        <Field
            name={operariosKey}
            fullWidth
            multiple
            disabled={disabled}
            component={Autocomplete}
            onChange={(event, newValue) => {
                const value = [
                    ...fixedOptions,
                    ...newValue.filter((option) => !fixedOperarioIds.includes(option.operario.id)),
                ];
                setValue(value);
            }}
            options={operariosOptions}
            getOptionLabel={(option) => option.operario.nombre}
            getOptionSelected={(option, value) => option.operario.id === value.operario.id}
            renderInput={(params) => (
                <MuiTextField
                    {...params}
                    error={error}
                    helperText={helperText}
                    label='Operarios'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={disabled}
                    placeholder={value?.length === 0 ? 'Puedes dejarlo vacío para asignarlo más tarde' : ''}
                />
            )}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                    const isFixed = fixedOperarioIds.includes(option.operario.id);

                    return (
                        <Tooltip
                            key={index}
                            title={
                                isFixed
                                    ? 'No se puede quitar este operario porque ya tiene partes de trabajo en este servicio'
                                    : ''
                            }
                            arrow
                        >
                            <span>
                                <Chip label={option.operario.nombre} {...getTagProps({ index })} disabled={isFixed} />
                            </span>
                        </Tooltip>
                    );
                })
            }
            renderOption={(option) => (
                <PlanificacionFormItemSelectOption
                    label={
                        option.operario.es_responsable_operarios ? (
                            <>
                                <StarIcon fontSize='small' />
                                {option.operario.nombre}
                            </>
                        ) : (
                            option.operario.nombre
                        )
                    }
                    planificaciones={option.operario.planificaciones}
                />
            )}
        />
    );
}

OperariosPlanificacionField.propTypes = {
    disabled: PropTypes.any,
    error: PropTypes.any,
    fechaFin: PropTypes.any,
    fechaInicio: PropTypes.any,
    fixedOperarioIds: PropTypes.any,
    helperText: PropTypes.any,
    operariosKey: PropTypes.string,
    planificacionId: PropTypes.any,
};
