import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { clientesProvider } from '../../../api';
import { usePreferencias } from '../../../AuthState';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import ClienteField from '../../common/fields/ClienteField';
import DireccionField from '../../common/fields/DireccionField';
import FileUploader from '../../common/fields/FileUploader';
import { SwitchWithLabelField } from '../../common/fields/Switch';
import DialogForm from '../../common/forms/DialogForm';

const ProyectoSchema = Yup.object().shape({
    codigo: Yup.string().nullable().required('Requerido'),
    nombre: Yup.string().required('Requerido'),
    cliente: Yup.mixed().required('Requerido'),
    descuento: Yup.number().min(0, 'Debe ser mayor o igual a 0'),
});

function NewProyectoDialog({ onSave, clienteId, direccionId, ...props }) {
    const usarPresupuestos = usePreferencias('usar_presupuestos');
    return (
        <DialogForm
            title='Añadir proyecto'
            FormikProps={{
                initialValues: {
                    cliente: clienteId ? { id: clienteId } : null,
                    direccion: direccionId ? { id: direccionId } : null,
                    codigo: '',
                    nombre: '',
                    fecha_inicio: null,
                    fecha_fin: null,
                    descuento: 0,
                    files: [],
                    usar_presupuestos: false,
                    usar_avances: false,
                },
                validationSchema: ProyectoSchema,
                onSubmit: ({ cliente, direccion, files, ...values }, { setSubmitting, setFieldError }) => {
                    const formData = new FormData();

                    const formValues = {};

                    Object.entries(values).forEach(([key, value]) => (formValues[key] = value));
                    formValues.direccion_id = direccion ? direccion.id : null;

                    formData.append('params', JSON.stringify(formValues));
                    new Array(...files).forEach((file) => formData.append('files', file));

                    clientesProvider
                        .getAll(`${cliente.id}/proyectos`, {
                            method: 'post',
                            body: formData,
                        })
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ touched, errors, values, setFieldValue }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ClienteField
                            error={touched.cliente && !!errors.cliente}
                            helperText={touched.cliente && errors.cliente}
                            disabled={Boolean(clienteId)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DireccionField
                            error={touched.direccion && !!errors.direccion}
                            helperText={touched.direccion && errors.direccion}
                            disabled={Boolean(direccionId)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='codigo' label='Codigo' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='nombre' label='Nombre' fullWidth />
                    </Grid>
                    {usarPresupuestos && (
                        <>
                            <Grid item xs={12}>
                                <SwitchWithLabelField
                                    name='usar_presupuestos'
                                    label='Usar presupuestos'
                                    helpTooltip='Poder añadir presupuestos al proyecto'
                                />
                            </Grid>
                            {values.usar_presupuestos && (
                                <>
                                    <Grid item xs={12}>
                                        <SwitchWithLabelField
                                            name='usar_avances'
                                            label='Usar avances de obra y certificaciones'
                                            helpTooltip='Gestión de obra mediante avances y certificaciones de obra'
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <KeyboardDatePicker
                                            name='fecha_inicio'
                                            label='Fecha inicio'
                                            format={'dd/MM/yyyy'}
                                            fullWidth
                                            autoOk
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <KeyboardDatePicker
                                            name='fecha_fin'
                                            label='Fecha fin'
                                            format={'dd/MM/yyyy'}
                                            fullWidth
                                            autoOk
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            name='descuento'
                                            type='number'
                                            step='any'
                                            label='Descuento'
                                            fullWidth
                                            InputProps={{
                                                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                    <Grid item xs={12}>
                        <FileUploader
                            id='upload-proyecto-adjuntos-file'
                            label='Añadir adjuntos'
                            file={values.files}
                            setFile={(files) => setFieldValue('files', files)}
                            multiple={true}
                            isImage={false}
                            accept='image/jpeg,image/gif,image/png,application/pdf'
                        />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(NewProyectoDialog);

NewProyectoDialog.propTypes = {
    clienteId: PropTypes.any,
    direccionId: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
