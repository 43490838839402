import { useCallback, useEffect, useMemo, useState } from 'react';
import { companiesProvider } from '../../../api';
import { useFetchData } from '../../../hooks/useFetchData';
import { format, formatDate, formatISODate } from '../../../utils';
import Typography from '@material-ui/core/Typography';
import Button from '../../common/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { navigate } from '../../calendario_planificacion/Toolbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import { ToggleButton, ToggleButtonGroup } from '../../common/ToggleButtonGroup';
import addMonths from 'date-fns/addMonths';
import subMonths from 'date-fns/subMonths';
import startOfMonth from 'date-fns/startOfMonth';
import TableByDay from './TableByDay';
import SelectCompany from './SelectCompany';
import TableByCompany from './TableByCompany';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(3)}px`,
            marginBottom: theme.spacing(3),
            '& .MuiTypography-root': {
                whiteSpace: 'nowrap',
            },
        },
        navigation: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            '& .MuiButton-root': {
                width: 32,
                minWidth: 0,
            },
        },
        navigationButton: {
            height: 32,
            padding: 0,
            backgroundColor: 'white',
        },
        fecha: {
            fontWeight: theme.typography.fontWeightMedium,
            textTransform: 'capitalize',
        },
        buttons: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            marginLeft: 'auto',
        },
    }),
    { name: 'DashboardAdmin' },
);

const BY_DAY = 'stats-by-day';
const BY_MONTH = 'stats-by-month';

const STORAGE_KEY = 'DashboardAdmin-prefs';
const defaultPrefs = {
    company: null,
    fecha: new Date(),
    view: BY_MONTH,
};

export default function DashboardAdmin() {
    const classes = useStyles();
    const [fecha, setFecha] = useState(new Date());
    // const fetchDataFn = useCallback(() => companiesProvider.getAll(`stats/${formatISODate(fecha)}`), [fecha]);

    const [view, setView] = useState(BY_MONTH);
    const [selectedCompany, setSelectedCompany] = useState(null);

    const [currentMonth, currentYear] = useMemo(() => [fecha.getMonth() + 1, fecha.getFullYear()], [fecha]);

    const fetchDataFn = useCallback(
        () => companiesProvider.getAll(`stats-by-month/${currentYear}/${currentMonth}`),
        [currentMonth, currentYear],
    );
    const { data, fetchData, isLoading } = useFetchData(fetchDataFn, [], true);

    function loadPrefs() {
        const prefs = JSON.parse(localStorage.getItem(STORAGE_KEY)) || defaultPrefs;
        setSelectedCompany(prefs.company);
        setView(prefs.view);
        setFecha(new Date(prefs.fecha));
    }

    function updatePrefs() {
        localStorage.setItem(
            STORAGE_KEY,
            JSON.stringify({
                company: selectedCompany,
                view,
                fecha: formatISODate(fecha),
            }),
        );
    }
    useEffect(() => {
        loadPrefs();
    }, []);

    useEffect(() => {
        updatePrefs();
    }, [selectedCompany, view, fecha]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    function onNavigate(mode) {
        let newStart = null;
        if (mode === navigate.NEXT) {
            newStart = view === BY_DAY ? addDays(fecha, 1) : addMonths(fecha, 1);
        } else if (mode === navigate.PREVIOUS) {
            newStart = view === BY_DAY ? subDays(fecha, 1) : subMonths(fecha, 1);
        } else if (mode === navigate.TODAY) {
            newStart = BY_DAY ? new Date() : startOfMonth(new Date());
        }

        if (newStart) setFecha(newStart);
    }

    const dataByDay = useMemo(
        () =>
            data.map(({ stats, ...company }) => ({
                ...company,
                stats: stats[formatISODate(fecha)],
            })),
        [data, fecha],
    );

    const dataByCompany = useMemo(() => {
        if (!selectedCompany) return [];

        const companyData = data.find((company) => company.id === selectedCompany.id);
        if (!companyData) return [];

        const stats = companyData.stats;

        // transform the isodate: object, object (stats) to an array for the current month filling the gaps for the missing days
        const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();
        const statsArray = [];
        for (let i = 1; i <= daysInMonth; i++) {
            const isoDate = formatISODate(new Date(currentYear, currentMonth - 1, i));
            statsArray.push({
                fecha: new Date(isoDate),
                stats: stats[isoDate] || {},
            });
        }
        return statsArray;
    }, [data, selectedCompany]);

    const companyOptions = useMemo(() => data.map(({ id, name }) => ({ id, name })), [data]);

    return (
        <>
            <div className={classes.root}>
                <Typography variant='h1'>Dashboard admin</Typography>

                <div className={classes.navigation}>
                    <Button
                        color='outlined'
                        onClick={() => onNavigate(navigate.PREVIOUS)}
                        className={classes.navigationButton}
                    >
                        <ChevronLeftIcon />
                    </Button>
                    <Button
                        color='outlined'
                        onClick={() => onNavigate(navigate.NEXT)}
                        className={classes.navigationButton}
                    >
                        <ChevronRightIcon />
                    </Button>
                </div>

                <Typography variant='h2' className={classes.fecha}>
                    {view === BY_DAY ? formatDate(fecha) : format(fecha, 'MMMM yyyy')}
                </Typography>

                <Button
                    color='outlined'
                    onClick={() => onNavigate(navigate.TODAY)}
                    className={classes.navigationButton}
                >
                    Hoy
                </Button>

                <ToggleButtonGroup value={view} exclusive onChange={(ev, view) => setView(view)}>
                    <ToggleButton value={BY_DAY}>Por dia</ToggleButton>
                    <ToggleButton value={BY_MONTH}>Por empresa y mes</ToggleButton>
                </ToggleButtonGroup>

                {view === BY_MONTH && (
                    <SelectCompany
                        defaultCompanyId={selectedCompany?.id}
                        companiesOptions={companyOptions}
                        onChange={(company) => setSelectedCompany(company)}
                    />
                )}
            </div>
            {view === BY_DAY ? (
                <TableByDay data={dataByDay} />
            ) : (
                <TableByCompany company={selectedCompany} resumen={dataByCompany} loading={isLoading} />
            )}
        </>
    );
}
