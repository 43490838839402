import React from 'react';
import DialogEditor from '../../common/forms/DialogEditor';
import PropTypes from 'prop-types';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import { tareasProvider } from '../../../api';
import { formatDate } from '../../../utils';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useAuthState from '../../../AuthState';
import TableHead from '@material-ui/core/TableHead';
import MaterialEditor from '../ParteTrabajoList/MaterialEditor';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import MuiTableCell from '@material-ui/core/TableCell';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import AlbaranesCompraInfoTooltip from '../AlbaranesCompraInfoTooltip';

const TableCell = withStyles(
    (theme) => ({
        root: {
            height: 36,
            borderBottom: `1px solid ${theme.palette.divider}`,
            '&:not(:first-of-type)': {
                whiteSpace: 'nowrap',
            },
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        sizeSmall: {
            padding: '6px 8px 2px 8px',
            '&:first-of-type': {
                paddingLeft: 16,
            },
            '&:last-child': {
                padding: 0,
                paddingRight: 16,
            },
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

const useStyles = makeStyles(
    (theme) => ({
        table: {
            '& .MuiTableCell-root:first-of-type': {
                width: '100%',
            },
        },
        fotos: {
            display: 'block',
        },
        fotosList: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            marginTop: theme.spacing(1),
        },
        foto: {
            width: 60,
            height: 60,
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: 8,
            },
        },
        actions: {
            textAlign: 'right',
        },
        descripcion: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    }),
    { name: 'EditarCosteMaterialesDialog' },
);

const EditarCosteMaterialesDialog = withButtonOpener(function ({ fecha, materiales, albaranesCompraMap, ...props }) {
    const classes = useStyles();

    const {
        userInfo: { preferencias: { usar_precios: usarPrecios } = {} },
    } = useAuthState();

    const { fetchData } = useEditFormData();

    const [editMaterialInfo, setEditMaterialInfo] = React.useState(null);
    const openMaterialEditor =
        (jornadaId, { id, ...material } = { id: null, descripcion: '', unidades: '' }) =>
        (event) => {
            setEditMaterialInfo({
                jornadaId,
                materialId: id,
                material,
            });
        };
    const closeMaterialEditor = () => {
        setEditMaterialInfo(null);
    };

    const totalMaterial = materiales
        .filter((m) => m.importe !== null && m.importe_coste !== null)
        .reduce(
            (total, material) => ({
                importeVenta: total.importeVenta + material.importe,
                importeCoste: total.importeCoste + material.importe_coste,
            }),
            { importeVenta: 0, importeCoste: 0 },
        );
    return (
        <>
            <MaterialEditor
                open={Boolean(editMaterialInfo)}
                {...editMaterialInfo}
                onClose={closeMaterialEditor}
                onSave={(material) => {
                    const { jornadaId, materialId } = editMaterialInfo;

                    let idx = materiales.findIndex((material) => material.id === materialId);
                    if (idx < 0) idx = materiales.length;

                    return tareasProvider
                        .updateMaterialJornada(jornadaId, materialId, material)
                        .then((updatedMaterial) => {
                            fetchData();
                            closeMaterialEditor();
                        });
                }}
            />
            <DialogEditor
                title={`Materiales del ${fecha && formatDate(fecha)}`}
                cancelButtonText='Cerrar'
                maxWidth='md'
                disableBodyGutters
                {...props}
            >
                <Table size='small' className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '100%' }}>Descripción</TableCell>
                            <TableCell>Unidades</TableCell>
                            {usarPrecios && (
                                <>
                                    <TableCell>Precio coste</TableCell>
                                    <TableCell>Precio venta</TableCell>
                                    <TableCell>Dto venta</TableCell>
                                    <TableCell>Importe coste</TableCell>
                                    <TableCell>Importe venta</TableCell>
                                </>
                            )}
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {materiales.map((material, i) => {
                            const albaranCompraId = albaranesCompraMap.map[material.albaran_compra_linea_id];

                            return (
                                <TableRow key={i}>
                                    <TableCell>
                                        <div className={classes.descripcion}>
                                            {material.descripcion}{' '}
                                            {albaranCompraId ? (
                                                <AlbaranesCompraInfoTooltip
                                                    albaranes={[albaranesCompraMap.albaranes[albaranCompraId]]}
                                                />
                                            ) : null}
                                        </div>
                                    </TableCell>
                                    <TableCell>{material.unidades}</TableCell>
                                    {usarPrecios && (
                                        <>
                                            <TableCell align='right'>
                                                {material.precio_unitario_coste.toFixed(2)}€
                                            </TableCell>
                                            <TableCell align='right'>{material.precio_unitario.toFixed(2)}€</TableCell>
                                            <TableCell align='right'>{material.descuento.toFixed(0)}%</TableCell>
                                            <TableCell align='right'>
                                                {material.importe_coste !== null
                                                    ? `${material.importe_coste.toFixed(2)}€`
                                                    : 'NaN'}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {material.importe !== null ? `${material.importe.toFixed(2)}€` : 'NaN'}
                                            </TableCell>
                                        </>
                                    )}
                                    <TableCell className={classes.actions}>
                                        <IconButton onClick={openMaterialEditor(material.jornada_id, material)}>
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {usarPrecios && (
                            <TableRow>
                                <TableCell colSpan={5}>Total material</TableCell>
                                <TableCell align='right'>{totalMaterial.importeCoste.toFixed(2)}€</TableCell>
                                <TableCell align='right'>{totalMaterial.importeVenta.toFixed(2)}€</TableCell>
                                <TableCell />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {/* {fotos.length > 0 && ( */}
                {/*    <span className={classes.fotos}> */}
                {/*        <InputLabel shrink>Fotos de los operarios</InputLabel> */}
                {/*        <div className={classes.fotosList}> */}
                {/*            {fotos.map((foto) => ( */}
                {/*                <div */}
                {/*                    key={foto.id} */}
                {/*                    onClick={() => window.open(foto.image_url, '_blank')} */}
                {/*                    className={classes.foto} */}
                {/*                > */}
                {/*                    <img src={foto.thumbnail_url} /> */}
                {/*                </div> */}
                {/*            ))} */}
                {/*        </div> */}
                {/*    </span> */}
                {/*    // <ButtonFotoSlideshow */}
                {/*    //     button={ */}
                {/*    //         <Button color='info'> */}
                {/*    //             <ImageIcon /> Ver fotos de operarios */}
                {/*    //         </Button> */}
                {/*    //     } */}
                {/*    //     fotos={fotos} */}
                {/*    // /> */}
                {/* )} */}
            </DialogEditor>
        </>
    );
});

EditarCosteMaterialesDialog.propTypes = {
    onSave: PropTypes.any,
    albaranesCompraMap: PropTypes.any,
};

export default EditarCosteMaterialesDialog;
