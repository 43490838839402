import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiTextField from '@material-ui/core/TextField';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Autocomplete } from 'formik-material-ui-lab';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { operariosProvider, solicitudesProvider } from '../../api';
import { INCLUDE_ADMINISTRATIVOS } from '../../api/operarios';
import { solicitudEstadoSelectOptions } from '../../api/solicitudes';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { setBeginOfDay, setEndOfDay } from '../../utils';
import Button from '../common/Button';
import { DateRangePicker } from '../common/fields/DateRangePicker';
import SelectField from '../common/fields/SelectField';

const VacacionesSchema = Yup.object().shape({
    operario: Yup.mixed().required('Requerido'),
    motivo: Yup.string().required('Requerido'),
    fecha_inicio: Yup.date().typeError('La fecha de inicio debe ser una fecha').required('Requerido'),
    fecha_fin: Yup.date()
        .typeError('La fecha de fin debe ser una fecha')
        .required('Requerido')
        .min(Yup.ref('fecha_inicio'), 'La fecha de fin debe ser posterior a la de inicio'),
});

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 600,
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
    }),
    { name: 'CrearVacacionesDialog' },
);

function CrearVacacionesDialog({ open, onClose, onSave, styles }) {
    const classes = useStyles();
    const [operariosOptions, setOperariosOptions] = useState([]);

    useEffect(() => {
        if (!open) return;

        operariosProvider.getOnlyActiveAsOptions(INCLUDE_ADMINISTRATIVOS).then(setOperariosOptions);
    }, [open]);

    const today = setBeginOfDay(new Date());
    const snackbar = useSnackbar();

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby='nueva-vacaciones-title'
            open={open}
            classes={{
                paper: classes.root,
            }}
            fullWidth={false}
            maxWidth='lg'
        >
            <Formik
                initialValues={{
                    operario: null,
                    motivo: '',
                    fecha_inicio: new Date(today),
                    fecha_fin: new Date(today),
                    estado: 'PENDIENTE',
                    observaciones: '',
                }}
                enableReinitialize={true}
                validationSchema={VacacionesSchema}
                onSubmit={(
                    // eslint-disable-next-line camelcase
                    { operario, allDay, fecha_inicio, fecha_fin, ...values },
                    { setSubmitting, setFieldError },
                ) => {
                    if (allDay) {
                        setBeginOfDay(fecha_inicio);
                        setEndOfDay(fecha_fin);
                    }

                    solicitudesProvider
                        .create({
                            tipo: 'VACACIONES',
                            operario_id: operario.id,
                            fecha_inicio,
                            fecha_fin,
                            ...values,
                        })
                        .then((updatedVacaciones) => {
                            onClose();
                            setSubmitting(false);
                            onSave(updatedVacaciones);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                }}
            >
                {({ isSubmitting, values, errors, touched, setFieldValue }) => {
                    return (
                        <Form className={classes.form}>
                            <DialogTitle id='parte-trabajo-title'>Añadir vacaciones</DialogTitle>
                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field
                                            name='operario'
                                            fullWidth
                                            component={Autocomplete}
                                            options={operariosOptions}
                                            getOptionLabel={(option) => option.nombre}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            renderInput={(params) => (
                                                <MuiTextField
                                                    {...params}
                                                    error={touched.operario && !!errors.operario}
                                                    helperText={touched.operario && errors.operario}
                                                    label='Operario'
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField name='motivo' label='Motivo' fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DateRangePicker
                                            label='Fechas'
                                            allDay={true}
                                            fechaInicioKey='fecha_inicio'
                                            fechaFinKey='fecha_fin'
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name='estado'
                                            id='estado'
                                            component={SelectField}
                                            label='Estado'
                                            fullWidth
                                            selectOptions={solicitudEstadoSelectOptions}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            name='observaciones'
                                            label='Observaciones para el operario'
                                            fullWidth
                                            multiline
                                            maxRows={5}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button color='outlined' disabled={isSubmitting} onClick={onClose}>
                                    Cancelar
                                </Button>
                                <Button type='submit' color='info' disabled={isSubmitting}>
                                    Guardar
                                </Button>
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}

export default withButtonOpener(CrearVacacionesDialog);

CrearVacacionesDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
    styles: PropTypes.any,
};
