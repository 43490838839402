import DataProvider from './DataProvider';

export const empty = {};

export const solicitudEstadoLabels = {
    PENDIENTE: 'Pendiente',
    APROBADA: 'Aprobada',
    RECHAZADA: 'Rechazada',
};

export const solicitudEstadoSelectOptions = Object.entries(solicitudEstadoLabels).map(([value, label]) => ({
    value,
    label,
}));

export const solicitudTipoLabels = {
    VACACIONES: 'Vacaciones',
    AUSENCIA: 'Ausencia',
};

class SolicitudesProvider extends DataProvider {
    constructor() {
        super('solicitudes', empty);
    }

    create = async (values) => {
        return this.save(values);
    };

    getByMonth = async (year, month) => {
        return this.getAll(`${year}/${month}`);
    };

    getAllVacaciones = async () => {
        return this.getAll('vacaciones');
    };

    getAllAusencias = async () => {
        return this.getAll('ausencias');
    };

    getAllVacacionesCalendario = async () => {
        return this.getAll('vacaciones/calendario');
    };

    getAllAusenciasCalendario = async () => {
        return this.getAll('ausencias/calendario');
    };

    getOperariosSinVacaciones = async () => this.getAll('operarios_sin_vacaciones');

    getResumen = async (year, operarioId) => this.action(`resumen/${year}/${operarioId}`);
}

export function convertDates(solicitud) {
    if (Array.isArray(solicitud)) return solicitud.map(convertDates);

    solicitud.fecha = new Date(solicitud.fecha);
    solicitud.fecha_inicio = new Date(solicitud.fecha_inicio);
    solicitud.fecha_fin = new Date(solicitud.fecha_fin);

    return solicitud;
}

// export const dataProvider = new DataProvider('fichajes', empty);
export const dataProvider = new SolicitudesProvider();
