import LockIcon from '@material-ui/icons/Lock';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { formatDate } from '../../../utils';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import ItemLabel from '../../common/forms/MasterDetailsView/ItemLabel';
import MasterItem from '../../common/forms/MasterDetailsView/MasterItem';
import AvanceObraItemOptions from './AvanceObraItemOptions';

export default function AvanceObraItem({ item, href, navigate, onDelete }) {
    const location = useLocation();
    const { fetchData } = useEditFormData();

    const isSelected = location.pathname === href;

    return (
        <MasterItem
            title={`Avance del ${formatDate(item.fecha_creacion)}`}
            navigate={navigate}
            isSelected={isSelected}
            labels={
                item.certificado ? (
                    <ItemLabel
                        icon={<LockIcon />}
                        label='Certificado'
                        tooltip={`Certificado el ${formatDate(item.certificacion_obra.fecha_creacion)}`}
                    />
                ) : null
            }
            actions={<AvanceObraItemOptions item={item} onUpdate={fetchData} onDelete={onDelete} />}
        />
    );
}

AvanceObraItem.propTypes = {
    item: PropTypes.object.isRequired,
    href: PropTypes.string.isRequired,
    navigate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};
