import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import PropTypes from 'prop-types';
import { isAllDay } from '../../../utils';
import EditPlanificacionDialog from '../EditPlanificacionDialog';
import { EVENTO_DRAGGABLE_TYPE } from '../Evento';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            borderRadius: 4,
            padding: theme.spacing(0.5, 0.5, 0.5, 1),
            overflow: 'hidden',
            cursor: 'pointer',
            position: 'relative',
            width: 240,
            zIndex: 999999,
            background: (props) =>
                props.urgente
                    ? theme.palette.error.main
                    : props.background
                    ? props.background
                    : theme.palette.primary.hover,
            color: (props) => (props.urgente ? 'white' : props.text ? props.text : theme.palette.text.primary),
            '& .MuiTypography-root': {
                lineHeight: `${theme.spacing(2)}px`,
                whiteSpace: 'nowrap',
            },
            '& .MuiIconButton-root': {
                padding: theme.spacing(0.5),
            },
            '& .MuiSvgIcon-root': {
                fontSize: 20,
            },
        },
        duration: {
            borderRadius: '50%',
            width: 32,
            height: 32,
            marginRight: theme.spacing(0.5),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: (props) =>
                props.urgente ? 'white' : props.text ? props.text : theme.palette.primary.main,
            color: (props) =>
                props.urgente ? theme.palette.error.main : props.background ? props.background : 'white',
        },
        content: {
            flex: 1,
            overflow: 'hidden',
        },
        title: {
            fontWeight: 600,
        },
        subtitle: {
            fontWeight: theme.typography.fontWeightMedium,
        },
        overflowText: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        icons: {
            display: 'flex',
        },
    }),
    { name: 'MoverServicioItem' },
);

export default function MoverServicioItem({ event, isDragging }) {
    const classes = useStyles({ ...event.customColor, urgente: event.urgente });

    const operarios = event.operarios
        ? event.operarios.length > 0
            ? event.operarios.map((op) => op.nombre).join(', ')
            : 'Sin operarios'
        : null;

    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: event.uniqueId,
        data: {
            type: EVENTO_DRAGGABLE_TYPE,
            entity: event,
        },
    });

    const style = {
        transform: CSS.Translate.toString(transform),
    };

    const allDay = isAllDay(event.start, event.end);
    const duration = allDay ? differenceInDays(event.end, event.start) + 1 : differenceInHours(event.end, event.start);

    return (
        <div className={clsx(classes.root)} style={style} {...attributes} {...listeners} ref={setNodeRef}>
            <div className={classes.duration}>
                <Typography variant='subtitle2'>
                    {duration}
                    {allDay ? 'd' : 'h'}
                </Typography>
            </div>
            <div className={classes.content}>
                <Typography
                    variant='subtitle2'
                    title={event.cliente}
                    className={clsx(classes.title, classes.overflowText)}
                >
                    {event.cliente}
                </Typography>
                <Typography
                    variant='subtitle2'
                    className={clsx(classes.subtitle, classes.overflowText)}
                    title={event.descripcion}
                >
                    {event.descripcion}
                </Typography>
                {operarios && (
                    <Typography
                        variant='subtitle2'
                        title={operarios}
                        className={clsx(classes.subtitle, classes.overflowText)}
                    >
                        {operarios}
                    </Typography>
                )}
            </div>
            {!isDragging && (
                <div className={classes.icons}>
                    <EditPlanificacionDialog
                        tarea={{
                            id: event.id,
                            cliente: event.cliente,
                            descripcion: event.descripcion,
                        }}
                        planificacionId={event.planificacionId}
                        onSave={event.onUpdate}
                        button={
                            <IconButton>
                                <EditIcon />
                            </IconButton>
                        }
                    />
                    <IconButton onClick={event.onRestore}>
                        <CloseIcon />
                    </IconButton>
                </div>
            )}
        </div>
    );
}

MoverServicioItem.propTypes = {
    event: PropTypes.any,
    isDragging: PropTypes.bool,
};
