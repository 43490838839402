import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import isPast from 'date-fns/isPast';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { preparacionesMaterialProvider } from '../../api';
import { estadoPreparacionLabels, estadoPreparacionOptions } from '../../api/preparaciones_material';
import { tipoLabels } from '../../api/tareas';
import useAuthState from '../../AuthState';
import { useFetchData } from '../../hooks/useFetchData';
import { estadoPreparacionFilterFn, formatDate, formatDateTime, isAllDay } from '../../utils';
import { SwitchWithLabel } from '../common/fields/Switch';
import { DateColumnFilter, dateFilterFn } from '../common/list/DateColumnFilter';
import BaseListView, { withTableState } from '../common/list/ListView';
import { SelectColumnFilter } from '../common/list/SelectColumnFilter';
import { ClienteLinkCell } from '../dashboard/ClienteLink';
import PreparacionMaterialEstadoChip from '../servicios/PreparacionMaterialEstadoChip';
import PreparacionMaterialDrawer from './PreparacionMaterialDrawer';

const ListView = withTableState('preparaciones-material', BaseListView);

export default function PreparacionesMaterialList() {
    const {
        userInfo: { preferencias: { usar_referencias_tareas: usarReferencias } = {} },
    } = useAuthState();
    const fetchDataFn = useCallback(() => preparacionesMaterialProvider.getAll(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    const [drawerPreparacion, setDrawerPreparacion] = useState(false);

    const toggleDrawerPreparacion = (jornada) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawerPreparacion(jornada);
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const columns = useMemo(() => {
        const columns = [];

        if (usarReferencias)
            columns.push({
                Header: 'Ref. interna',
                accessor: 'referencia_interna',
            });

        const estadoPreparacionOptionsExtra = [
            ...estadoPreparacionOptions,
            {
                value: 'NO_PREPARADO',
                label: 'No preparado',
            },
        ];

        columns.push(
            {
                Header: 'Referencia',
                id: 'numero',
                accessor: (row) => (row.numero ? row.numero.toString().padStart(4, '0') : 'N/D'),
            },
            {
                Header: 'Título',
                accessor: 'descripcion',
            },
            {
                Header: 'Cliente',
                accessor: 'cliente',
                Cell: ClienteLinkCell,
            },
            {
                Header: 'Direccion',
                accessor: 'direccion',
            },
            {
                Header: 'Tipo',
                id: 'tipo',
                accessor: (row) => tipoLabels[row.tipo],
            },
            {
                Header: 'Categoría',
                accessor: 'categoria',
            },
            {
                Header: 'Fecha creacion',
                accessor: 'fecha_creacion',
                Cell: ({ value }) => (value ? formatDateTime(new Date(value)) : 'Sin fecha'),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Inicio previsto',
                accessor: 'fecha',
                Cell: ({ value, row: { values } }) =>
                    value
                        ? isAllDay(values.fecha, values.fecha_fin)
                            ? formatDate(value)
                            : formatDateTime(value)
                        : 'Sin fecha',
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Fin previsto',
                accessor: 'fecha_fin',
                Cell: ({ value, row: { values } }) =>
                    value
                        ? isAllDay(values.fecha, values.fecha_fin)
                            ? formatDate(value)
                            : formatDateTime(value)
                        : 'Sin fecha',
                getExtraCellProps: ({ value }) => ({ style: { color: isPast(new Date(value)) ? 'red' : 'initial' } }),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Fecha límite',
                accessor: 'fecha_limite',
                Cell: ({ value }) => (value ? formatDateTime(value) : 'Sin fecha'),
                getExtraCellProps: ({ value }) => ({
                    style: { color: value && isPast(new Date(value)) ? 'red' : 'initial' },
                }),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Estado preparacion',
                accessor: 'estado_preparacion',
                Cell: PreparacionMaterialEstadoChip,
                Filter: SelectColumnFilter,
                filter: estadoPreparacionFilterFn,
                filterOptions: {
                    options: estadoPreparacionOptionsExtra,
                    includeAllOption: {
                        value: 'todos',
                        label: 'Todos',
                    },
                },
                exportValue: ({ value }) => estadoPreparacionLabels[value],
            },
            {
                Header: 'Visible',
                id: 'no-caducada',
                accessor: (row) => !row.fecha_limite || !isPast(new Date(row.fecha_limite)),
                Cell: ({ value }) => (value ? 'Si' : 'No'),
            },
        );

        return columns;
    }, [usarReferencias]);

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            hideDelete: true,
            tableOptions: {
                fillEmptySpace: false,
                forceHiddenColumns: ['no-caducada'],
                extraFilters: (setFilter, tableColumns) => {
                    const column = tableColumns.find((c) => c.id === 'no-caducada');
                    const showingAll = column.filterValue === undefined;

                    return (
                        <SwitchWithLabel
                            label='Mostrar solo las preparaciones vigentes'
                            field={{
                                name: 'solo-servicios-vigentes',
                                onChange: () => column.setFilter(showingAll ? true : undefined),
                                value: !showingAll,
                            }}
                        />
                    );
                },
                initialState: {
                    filters: [{ id: 'no-caducada', value: true }],
                },
            },
        }),
        [],
    );

    return (
        <>
            <PreparacionMaterialDrawer row={drawerPreparacion} onClose={toggleDrawerPreparacion(false)} />
            <ListView
                tableTitle='Lista de preparaciones de material'
                basePath='/preparacion-material'
                columns={columns}
                data={data}
                options={options}
                extraActions={(row) => (
                    <>
                        <IconButton style={{ padding: 6 }} onClick={toggleDrawerPreparacion(row)}>
                            <VisibilityIcon fontSize='small' />
                        </IconButton>
                    </>
                )}
            />
        </>
    );
}
