import { useCallback, useEffect, useState } from 'react';
import { useFetchData } from '../../hooks/useFetchData';
import { Breadcrumbs } from './Breadcrumbs';
import DocumentoIcon from './DocumentoIcon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Dialog, DialogActions, List, ListItem, ListItemText } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { documentosProvider } from '../../api';
import Button from '../common/Button';
import PropTypes from 'prop-types';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(
    (theme) => ({
        dialogPaper: {
            height: 600,
        },
        breadcrumbs: {
            background: theme.palette.neutral.grey1,
            padding: theme.spacing(2),
        },
        documentosList: {
            overflowY: 'auto',
            flex: 1,
        },
        selectedDocumento: {
            background: theme.palette.neutral.grey1,
        },
    }),
    { name: 'SeleccionarDocumentosDialog' },
);

function SeleccionarDocumentosDialog({ onSelect, ...props }) {
    const classes = useStyles();
    const [parents, setParents] = useState([]);
    const [selectedDocumentos, setSelectedDocumentos] = useState([]);
    const fetchDataFn = useCallback(
        () => documentosProvider.getAll(parents.length > 0 ? parents[parents.length - 1].id : null),
        [parents],
    );
    const { data: documentos, fetchData } = useFetchData(fetchDataFn);

    function addParent(parent) {
        setParents([...parents, parent]);
        setSelectedDocumentos([]);
    }

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Dialog maxWidth='md' fullWidth classes={{ paper: classes.dialogPaper }} {...props}>
            <DialogTitle>Buscar y selecciona los ficheros a adjuntar</DialogTitle>
            <Breadcrumbs
                parents={parents}
                onClick={(idx) => {
                    setParents(parents.slice(0, idx));
                }}
                className={classes.breadcrumbs}
            />
            <List className={classes.documentosList}>
                {documentos.map((documento) => {
                    function onClick() {
                        if (documento.es_carpeta) {
                            addParent(documento);
                        } else {
                            setSelectedDocumentos((prev) => {
                                if (prev.includes(documento)) {
                                    return prev.filter((d) => d.id !== documento.id);
                                }
                                return [...prev, documento];
                            });
                        }
                    }
                    return (
                        <ListItem key={documento.id} button divider onClick={onClick}>
                            <ListItemIcon>
                                <DocumentoIcon documento={documento} />
                            </ListItemIcon>
                            <ListItemText primary={documento.nombre} />
                            {!documento.es_carpeta && (
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge='end'
                                        checked={selectedDocumentos.includes(documento)}
                                        disableRipple
                                        onChange={onClick}
                                    />
                                </ListItemSecondaryAction>
                            )}
                        </ListItem>
                    );
                })}
            </List>
            <DialogActions>
                <Button color='outlined' onClick={props.onClose}>
                    Cancelar
                </Button>
                <Button
                    disabled={selectedDocumentos.length === 0}
                    color='info'
                    onClick={() => {
                        onSelect && onSelect(selectedDocumentos);
                        props.onClose();
                    }}
                >
                    Adjuntar seleccionados
                </Button>
            </DialogActions>
        </Dialog>
    );
}

SeleccionarDocumentosDialog.propTypes = {
    onClose: PropTypes.any,
    onSelect: PropTypes.func,
};

export default withButtonOpener(SeleccionarDocumentosDialog);
