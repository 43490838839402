import { Route, Switch } from 'react-router-dom';
import DocumentosList from './DocumentosList';

export default function DocumentosModule() {
    return (
        <>
            <Switch>
                <Route path='/documentos'>
                    <DocumentosList />
                </Route>
            </Switch>
        </>
    );
}
