import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import EditProyectoDialog from '../clientes/proyectos/EditProyectoDialog';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import EditFormHeader from '../common/forms/EditForm/EditFormHeader';
import EditFormHeaderItem from '../common/forms/EditForm/EditFormHeaderItem';
import EditFormHeaderItemList from '../common/forms/EditForm/EditFormHeaderItemList';
import { FormTab, FormTabs } from '../common/forms/EditForm/FormTabs';
import ClienteInfoTooltip from '../servicios/ClienteInfoTooltip';
import DireccionInfoTooltip from '../servicios/DireccionInfoTooltip';
import AlbaranesProyecto from './AlbaranesProyecto';
import AvancesObraList from './AvancesObraTab/AvancesObraList';
import {
    ALBARANES_ROUTE,
    AVANCES_OBRA_ROUTE,
    PARTES_TRABAJO_ROUTE,
    PRESUPUESTOS_ROUTE,
    RENDIMIENTO_ROUTE,
    SERVICIOS_ROUTE,
} from './EditProyectoForm';
import { EditProyectoMenuOptions } from './EditProyectoMenuOptions';
import PartesTrabajoProyecto from './PartesTrabajoProyecto';
import PresupuestosList from './PresupuestosTab/PresupuestosList';
import Rendimiento from './Rendimiento';
import TrabajosProyecto from './TrabajosProyecto';

export function EditProyectoFormContent() {
    const { data, fetchData } = useEditFormData();

    const tabs = [];

    if (data.usar_presupuestos) {
        tabs.push(
            <FormTab key={PRESUPUESTOS_ROUTE} path={PRESUPUESTOS_ROUTE} label='Presupuestos'>
                <PresupuestosList />
            </FormTab>,
        );
    }

    tabs.push(
        <FormTab addWrapper key={SERVICIOS_ROUTE} path={SERVICIOS_ROUTE} label='Servicios'>
            <TrabajosProyecto clienteId={data.cliente_id} direccionId={data.direccion_id} />
        </FormTab>,
    );

    if (data.usar_presupuestos) {
        tabs.push(
            <FormTab key={PARTES_TRABAJO_ROUTE} path={PARTES_TRABAJO_ROUTE} label='Partes de trabajo'>
                <PartesTrabajoProyecto />
            </FormTab>,
            <FormTab key={ALBARANES_ROUTE} path={ALBARANES_ROUTE} label='Albaranes de servicio'>
                <AlbaranesProyecto />
            </FormTab>,
        );

        if (data.usar_avances) {
            tabs.push(
                <FormTab key={AVANCES_OBRA_ROUTE} path={AVANCES_OBRA_ROUTE} label='Avances de obra'>
                    <AvancesObraList />
                </FormTab>,
            );
        }
        tabs.push(
            <FormTab addWrapper key={RENDIMIENTO_ROUTE} path={RENDIMIENTO_ROUTE} label='Rendimiento'>
                <Rendimiento title='Rendimiento del proyecto' />
            </FormTab>,
        );
    }

    return (
        <>
            <EditFormHeader
                label='Proyecto'
                name={data.nombre}
                actions={
                    <>
                        <EditProyectoDialog
                            button={
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            }
                            clienteId={data.cliente_id}
                            proyectoId={data.id}
                            onSave={fetchData}
                            proyecto={data}
                        />
                        <EditProyectoMenuOptions />
                    </>
                }
            >
                <EditFormHeaderItemList>
                    <EditFormHeaderItem label='Código'>{data.codigo}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Cliente'>
                        <ClienteInfoTooltip id={data.cliente_id} label={data.cliente} />
                    </EditFormHeaderItem>
                    <EditFormHeaderItem label='Direccion'>
                        {data.direccion ? (
                            <DireccionInfoTooltip
                                direccion={data.direccion.direccion_completa}
                                label={data.direccion.nombre}
                            />
                        ) : (
                            'No asignada'
                        )}
                    </EditFormHeaderItem>
                    <EditFormHeaderItem label='Operarios responsables'>
                        {data.operarios.length > 0
                            ? data.operarios.map((o) => o.operario.nombre).join(', ')
                            : 'No asignado'}
                    </EditFormHeaderItem>
                </EditFormHeaderItemList>
            </EditFormHeader>

            {tabs.length > 1 ? <FormTabs>{tabs}</FormTabs> : tabs[0]}
        </>
    );
}
