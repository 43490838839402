import PaperTable from './PaperTable';
import PropTypes from 'prop-types';
import useAuthState from '../../../AuthState';
import { useMemo } from 'react';

export default function GastosPaper({ rows, totales }) {
    const {
        userInfo: {
            preferencias: { usar_precios: usarPrecios },
        },
    } = useAuthState();

    const columns = useMemo(
        () => [
            {
                label: 'Concepto',
                renderCell: (row) => row.concepto,
                footer: 'Total',
            },
            {
                label: 'Unidades',
                renderCell: (row) => row.unidades,
                isNumber: true,
                footer: null,
            },
            ...(usarPrecios
                ? [
                      {
                          label: 'Precio coste',
                          renderCell: (row) => `${row.precioCoste.toFixed(2)} €/h`,
                          isNumber: true,
                          footer: null,
                      },
                      {
                          label: 'Precio venta',
                          renderCell: (row) => `${row.precioVenta.toFixed(2)} €/h`,
                          isNumber: true,
                          footer: null,
                      },
                      {
                          label: 'Total coste',
                          renderCell: (row) => `${row.importeCoste.toFixed(2)} €`,
                          isNumber: true,
                      },
                      {
                          label: 'Total venta',
                          renderCell: (row) => `${row.importeVenta.toFixed(2)} €`,
                          isNumber: true,
                      },
                  ]
                : []),
        ],
        [usarPrecios],
    );

    return <PaperTable columns={columns} title='Gastos' rows={rows} footerRow={usarPrecios ? totales : null} />;
}

GastosPaper.propTypes = {
    rows: PropTypes.any,
    totales: PropTypes.any,
};
