import MuiTextField from '@material-ui/core/TextField';
import { Field, useField } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { companiesProvider } from '../../../api';

export default function CategoriaTareaField({ error, helperText, disabled }) {
    const [categoriaOptions, setCategoriaOptions] = useState([]);
    const [{ value }, , { setValue }] = useField('categoria');

    useEffect(() => {
        companiesProvider.getCategoriasTareaAsOptions().then(setCategoriaOptions);
    }, []);

    useEffect(() => {
        if (categoriaOptions.length === 0) return;

        setValue(value ? categoriaOptions.find((d) => d.id === value.id) : null);
    }, [value, categoriaOptions]);

    return (
        <Field
            name='categoria'
            fullWidth
            component={Autocomplete}
            options={categoriaOptions}
            getOptionSelected={(option, value) => option.id === value?.id}
            getOptionLabel={(option) => option.nombre ?? ''}
            disabled={disabled}
            renderInput={(params) => (
                <MuiTextField {...params} error={error} helperText={helperText} label='Categoría' />
            )}
        />
    );
}

CategoriaTareaField.propTypes = {
    disabled: PropTypes.any,
    error: PropTypes.any,
    helperText: PropTypes.any,
};
