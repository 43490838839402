import MovilNoVisibleIcon from '../icons/MovilNoVisible';
import { formatDateTime } from '../../utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
        },
        error: {
            color: theme.palette.error.main,
            '&.MuiSvgIcon-root': {
                fontSize: 18,
            },
        },
    }),
    { name: 'OperarioInfo' },
);
export default function OperarioInfo({ operario }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {!operario.visible && !operario.fechaSalida && <MovilNoVisibleIcon className={classes.error} />}
            {!operario.operarioId ? 'Sin asignar' : operario.operario}
            {!operario.visible && !operario.fechaSalida && (
                <span className={classes.error}>No se muestra el servicio al operario</span>
            )}
            {!operario.visible && operario.fechaSalida && (
                <span className={classes.error}>Salió el {formatDateTime(operario.fechaSalida)}</span>
            )}
        </div>
    );
}

OperarioInfo.propTypes = {
    operario: PropTypes.any,
};
