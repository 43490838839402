import { Route, Switch } from 'react-router-dom';
import RendimientoServiciosList from './RendimientoServiciosList';

export default function RendimientoServiciosModule() {
    return (
        <>
            <Switch>
                <Route path='/rendimiento-servicios'>
                    <RendimientoServiciosList />
                </Route>
            </Switch>
        </>
    );
}
