import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import BusinessIcon from '@material-ui/icons/Business';
import VisibilityIcon from '@material-ui/icons/Visibility';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formatDate } from '../../utils';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(
    (theme) => ({
        tooltip: {
            backgroundColor: theme.palette.neutral.hudBackground,
            opacity: 1,
            padding: theme.spacing(1),
        },
        tooltipArrow: {
            color: theme.palette.neutral.hudBackground,
        },
        label: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                color: theme.palette.neutral.primary,
                paddingLeft: theme.spacing(0.5),
            },
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                fontSize: 20,
            },
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightBold,
                flex: 1,
                marginLeft: theme.spacing(1),
            },
        },
        button: {
            color: 'white',
        },
        info: {
            paddingLeft: theme.spacing(3.5),
            marginTop: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
        },
    }),
    { name: 'ImputacionInfoTooltip' },
);

export default function ImputacionInfoTooltip({ imputacion }) {
    const classes = useStyles();
    const history = useHistory();

    const numero = imputacion
        ? imputacion.referencia_interna && imputacion.referencia_interna !== ''
            ? imputacion.referencia_interna
            : imputacion.numero
        : null;

    return (
        <Tooltip
            arrow
            interactive
            classes={{
                tooltip: classes.tooltip,
                arrow: classes.tooltipArrow,
            }}
            title={
                imputacion ? (
                    <>
                        <div className={classes.header}>
                            <BusinessIcon />
                            <Typography variant='subtitle2'>Servicio {imputacion.numero}</Typography>
                            <IconButton
                                onClick={() =>
                                    history.push(`/servicios/${imputacion.id}/partes-trabajo/${imputacion.jornada_id}`)
                                }
                                size='small'
                                className={classes.button}
                            >
                                <VisibilityIcon />
                            </IconButton>
                        </div>
                        <div className={classes.info}>
                            <Typography variant='subtitle2'>
                                <strong>Descripción: </strong> {imputacion.descripcion}
                            </Typography>
                            <Typography variant='subtitle2'>
                                <strong>Cliente: </strong> {imputacion.cliente}
                            </Typography>
                            <Typography variant='subtitle2'>{imputacion.direccion}</Typography>
                            <Typography variant='subtitle2'>
                                <strong>Fecha de imputación: </strong> {formatDate(imputacion.fecha)}
                            </Typography>
                            {imputacion.referencia_interna && (
                                <Typography variant='subtitle2'>
                                    <strong>Ref.interna: </strong> {imputacion.referencia_interna}
                                </Typography>
                            )}
                        </div>
                    </>
                ) : (
                    ''
                )
            }
        >
            <Chip size='small' icon={<InfoIcon />} label={imputacion ? `Ref. ${numero}` : 'No imputado'} />
        </Tooltip>
    );
}

ImputacionInfoTooltip.propTypes = {
    imputacion: PropTypes.any,
};
