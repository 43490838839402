import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import MuiTable from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import withStyles from '@material-ui/core/styles/withStyles';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import { cuestionariosProvider } from '../../../api';
import DeleteDialog from '../../common/dialogs/DeleteDialog';
import PreguntaEditor from './PreguntaEditor';
import MuiRadio from '@material-ui/core/Radio';
import { useParams } from 'react-router-dom';
import MuiOutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '../../common/Button';
import AddIcon from '@material-ui/icons/Add';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Table = withStyles(
    (theme) => ({
        root: {
            borderCollapse: 'separate',
            borderSpacing: '16px',
        },
    }),
    { name: 'Table' },
)(MuiTable);

const TableCell = withStyles(
    (theme) => ({
        root: {
            padding: 0,
            borderBottom: '1px solid #ccc',
            '.MuiTableHead-root &': {
                border: 'none',
            },
        },
        sizeSmall: {
            '&:not(:first-of-type)': {
                border: 'none',
                textAlign: 'center',
            },
            '&:last-child': {
                padding: 0,
            },
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

const Radio = withStyles(
    (theme) => ({
        root: {
            padding: 0,
        },
    }),
    { name: 'Radio' },
)(MuiRadio);

const Input = withStyles(
    (theme) => ({
        input: {
            padding: 6,
            minWidth: 32,
        },
    }),
    { name: 'Input' },
)(MuiOutlinedInput);

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '50px',
            paddingLeft: theme.spacing(2),
        },
        dividerRow: {
            '& td': {
                padding: 0,
                border: 'none',
            },
        },
        emptyRow: {
            '& td': {
                color: theme.palette.neutral.primary,
            },
            '& td:not(:first-of-type)': {
                border: 'none',
            },
        },
        actions: {
            whiteSpace: 'nowrap',
            textAlign: 'right',
            border: 'none',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
    }),
    { name: 'ParteTrabajoDetails' },
);

export default function SeccionDetails({ index }) {
    const classes = useStyles();
    const { id: cuestionarioId } = useParams();
    const {
        data: { has_partes_mantenimiento: disabled },
    } = useEditFormData();
    const { data: seccion, updateLocalData } = useEditFormData(`secciones.${index}`);

    const [editPreguntaInfo, setEditPreguntaInfo] = React.useState(null);
    const openPreguntaEditor =
        (seccionId, { id, ...pregunta } = { id: null, texto: '' }) =>
        (event) => {
            setEditPreguntaInfo({
                seccionId,
                preguntaId: id,
                ...pregunta,
            });
        };
    const closePreguntaEditor = () => {
        setEditPreguntaInfo(null);
    };

    return (
        <>
            <PreguntaEditor
                open={Boolean(editPreguntaInfo)}
                {...editPreguntaInfo}
                onClose={closePreguntaEditor}
                onSave={(texto) => {
                    const { seccionId, preguntaId } = editPreguntaInfo;

                    let idx = seccion.preguntas.findIndex((pregunta) => pregunta.id === preguntaId);
                    if (idx < 0) idx = seccion.preguntas.length;

                    closePreguntaEditor();

                    cuestionariosProvider
                        .updatePreguntaSeccion(cuestionarioId, seccionId, preguntaId, texto)
                        .then((updatedPregunta) => {
                            updateLocalData(`preguntas.${idx}`, updatedPregunta);
                        });
                }}
            />
            <Paper elevation={0} className={classes.root}>
                <Typography variant='body2' className={classes.title}>
                    Lista de preguntas
                </Typography>

                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '100%' }}>Pregunta</TableCell>
                            {seccion.columnas.map((label, i) => (
                                <TableCell key={i}>{label}</TableCell>
                            ))}
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {seccion.preguntas.length === 0 && (
                            <TableRow className={classes.emptyRow}>
                                <TableCell>Aún no has añadido preguntas</TableCell>
                                <TableCell colSpan={seccion.columnas.length + 1} />
                            </TableRow>
                        )}
                        {seccion.preguntas.map((pregunta, i) => (
                            <TableRow key={pregunta.id}>
                                <TableCell>{pregunta.texto}</TableCell>
                                {seccion.columnas.map((label, i) => (
                                    <TableCell key={i}>
                                        {seccion.tipo === 'CHOICE' ? (
                                            <Radio checked={i === 0} value={label} name={pregunta.id} />
                                        ) : (
                                            <Input value={99} disabled />
                                        )}
                                    </TableCell>
                                ))}
                                <TableCell className={classes.actions}>
                                    <IconButton
                                        disabled={i === 0}
                                        onClick={() =>
                                            cuestionariosProvider
                                                .movePreguntaUp(cuestionarioId, seccion.id, pregunta.id)
                                                .then((seccion) => updateLocalData(null, seccion))
                                        }
                                    >
                                        <ExpandLessIcon />
                                    </IconButton>
                                    <IconButton
                                        disabled={i === seccion.preguntas.length - 1}
                                        onClick={() =>
                                            cuestionariosProvider
                                                .movePreguntaDown(cuestionarioId, seccion.id, pregunta.id)
                                                .then((seccion) => updateLocalData(null, seccion))
                                        }
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                    <IconButton onClick={openPreguntaEditor(seccion.id, pregunta)} disabled={disabled}>
                                        <EditIcon />
                                    </IconButton>
                                    <DeleteDialog
                                        text='¿Estás seguro que quieres eliminar esta pregunta?'
                                        onConfirm={() =>
                                            cuestionariosProvider
                                                .deletePreguntaSeccion(cuestionarioId, seccion.id, pregunta.id)
                                                .then((seccion) => updateLocalData(null, seccion))
                                        }
                                        button={
                                            <IconButton disabled={disabled}>
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow className={classes.dividerRow}>
                            <TableCell colSpan={2 + seccion.columnas.length}>
                                <Button
                                    color='primary'
                                    startIcon={<AddIcon />}
                                    size='small'
                                    style={{ paddingLeft: 0 }}
                                    onClick={openPreguntaEditor(seccion.id)}
                                    disabled={disabled}
                                >
                                    Añadir pregunta
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        </>
    );
}

SeccionDetails.propTypes = {
    index: PropTypes.any.isRequired,
};
