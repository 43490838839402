import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '50px',
            padding: theme.spacing(0, 2),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        body: {
            padding: theme.spacing(2),
        },
        noGutters: {
            padding: 0,
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            padding: theme.spacing(2),
        },
    }),
    { name: 'InlineDialog' },
);

export default function InlineDialog({ title, titleActions, children, actions, className, removeGutters = false }) {
    const classes = useStyles();

    return (
        <Paper elevation={0} className={clsx(classes.root, className)}>
            {title && (
                <Typography variant='body1' component='div' className={classes.title}>
                    {title}
                    {titleActions}
                </Typography>
            )}
            <div className={clsx(classes.body, removeGutters ? classes.noGutters : null)}>{children}</div>
            {actions && <div className={classes.actions}>{actions}</div>}
        </Paper>
    );
}

InlineDialog.propTypes = {
    title: PropTypes.string,
    titleActions: PropTypes.node,
    children: PropTypes.any,
    actions: PropTypes.node,
    className: PropTypes.string,
    removeGutters: PropTypes.bool,
};
