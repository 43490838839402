import makeStyles from '@material-ui/core/styles/makeStyles';
import { useEffect, useMemo, useState } from 'react';
import { calendariosProvider, operariosProvider, solicitudesProvider } from '../../api';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import { format, getInitials } from '../../utils';
import AusenciaIcon from '@material-ui/icons/Feedback';
import VacacionesIcon from '../icons/Vacaciones';
import { convertDates } from '../../api/solicitudes';
import clsx from 'clsx';
import {
    isAfterDate,
    isBeforeDate,
} from '@randex/material-ui-multiple-dates-picker/app/src/lib/components/Datepicker/dateUtils';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const DAY_CELL_SIZE = 48;
const DAYS_ROW_HEIGHT = 55;

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
        },
        operariosColumn: {
            display: 'flex',
            gap: '4px',
            flexDirection: 'column',
            minWidth: 320,
            paddingTop: DAYS_ROW_HEIGHT + 4,
            '& $row': {
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
                marginRight: theme.spacing(0.5),
                paddingLeft: theme.spacing(2),
            },
        },
        daysColumnWrappper: {
            overflowX: 'scroll',
        },
        daysColumn: {
            width: 'fit-content',
            display: 'flex',
            gap: '4px',
            flexDirection: 'column',
            '& $diasRow': {
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8,
            },
        },
        row: {
            display: 'flex',
            height: DAY_CELL_SIZE,
            alignItems: 'center',
            backgroundColor: 'white',
        },
        operario: {
            display: 'flex',
            alignItems: 'center',
        },
        diasRow: {
            flex: 1,
            display: 'flex',
            position: 'relative',
            minHeight: DAYS_ROW_HEIGHT,
            '&:not(:first-of-type)': {
                minHeight: DAY_CELL_SIZE,
                backgroundColor: 'white',
            },
        },
        dia: {
            minWidth: DAY_CELL_SIZE,
            display: 'flex',
            flexDirection: 'column',
            color: '#8F95AF',
            fontSize: 16,
            fontWeight: 500,
            alignItems: 'center',
            '& span': {
                fontSize: 10,
                color: '#45486E',
            },
        },
        diaItem: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            color: 'white',
            fontWeight: 500,
            padding: theme.spacing(0, 1.5),
        },
        solicitud: {
            cursor: 'pointer',
            opacity: 0.85,
            '&:hover': {
                opacity: 1,
            },
            '& svg': {
                marginRight: theme.spacing(1.5),
            },
            '&.PENDIENTE': {
                backgroundColor: '#E5E8F1',
                color: '#8F95AF',
            },
            '&.VACACIONES.APROBADA': {
                backgroundColor: '#2196F3',
            },
            '&.VACACIONES.RECHAZADA': {
                backgroundColor: '#E94F4F',
            },
            '&.AUSENCIA': {
                backgroundColor: '#064DB7',
            },
            '&.startsBefore': {
                clipPath: 'polygon(8px 0, 100% 0, 100% 100%, 8px 100%, 0 50%)',
            },
            '&.endsAfter': {
                clipPath: 'polygon(calc(100% - 8px) 0, 0 0, 0 100%, calc(100% - 8px) 100%, 100% 50%)',
            },
        },
        noLaborable: {
            color: '#E94F4F',
            '& span': {
                color: '#E94F4F',
            },
        },
    }),
    { name: 'Calendario' },
);

const colors = [
    '#CDCDCD',
    '#F9597B',
    '#FD7528',
    '#FDA729',
    '#AEE432',
    '#2DDA81',
    '#23D4C8',
    '#0B9F95',
    '#51DBFB',
    '#1269F7',
    '#655EF5',
    '#9E4EE1',
    '#FD7DFD',
    '#FD50A6',
    '#FD94B0',
];

export function Calendario({ currentMonth, onSelect }) {
    const { firstDayOfMonth, lastDayOfMonth } = useMemo(() => {
        const firstDayOfMonth = new Date(currentMonth);
        firstDayOfMonth.setDate(1);
        firstDayOfMonth.setHours(0, 0, 0, 0);
        const lastDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
        lastDayOfMonth.setHours(23, 59, 59, 0);

        return { firstDayOfMonth, lastDayOfMonth };
    }, [currentMonth]);

    const classes = useStyles();
    const [operarios, setOperarios] = useState([]);
    const [solicitudesOperarios, setSolicitudesOperarios] = useState({});
    const [noLaborables, setNoLaborables] = useState([]);

    useEffect(() => {
        calendariosProvider
            .getNoLaborables(currentMonth.getFullYear())
            .then((dates) =>
                setNoLaborables(
                    dates
                        .map((d) => new Date(d))
                        .filter((d) => !isBeforeDate(d, firstDayOfMonth) && !isAfterDate(d, lastDayOfMonth)),
                ),
            );
    }, [currentMonth]);

    useEffect(() => {
        operariosProvider.getOnlyActiveAsOptions().then((operarios) => {
            setOperarios(
                operarios.map((operario, i) => ({
                    id: operario.id,
                    nombre: operario.nombre,
                    iniciales: getInitials(operario.nombre),
                    color: colors[i],
                })),
            );
        });
    }, []);

    useEffect(() => {
        setSolicitudesOperarios({});
        solicitudesProvider.getByMonth(currentMonth.getFullYear(), currentMonth.getMonth() + 1).then((solicitudes) => {
            const solicitudesMap = {};

            solicitudes.forEach((solicitud) => {
                if (solicitudesMap[solicitud.operario_id] === undefined) solicitudesMap[solicitud.operario_id] = [];

                solicitudesMap[solicitud.operario_id].push(convertDates(solicitud));
            });

            setSolicitudesOperarios(solicitudesMap);
        });
    }, [currentMonth]);

    const days = useMemo(() => {
        const numDays = getDaysInMonth(currentMonth);
        const inicio = new Date(currentMonth);
        inicio.setDate(1);
        const fin = new Date(inicio);
        fin.setDate(numDays);

        const days = [];
        // eslint-disable-next-line no-unmodified-loop-condition
        for (let day = new Date(inicio); day <= fin; day.setDate(day.getDate() + 1)) {
            days.push(new Date(day));
        }
        return days;
    }, [currentMonth]);

    const noLaborablesDay = useMemo(() => noLaborables.map((d) => d.getDate()), [noLaborables]);

    return (
        <div className={classes.root}>
            <div className={classes.operariosColumn}>
                {operarios.map((operario, i) => {
                    return (
                        <div className={classes.row} key={i}>
                            <div className={classes.operario}>
                                <Typography>{operario.nombre}</Typography>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className={classes.daysColumnWrappper}>
                <div className={classes.daysColumn}>
                    <div className={classes.diasRow}>
                        {days.map((day, i) => {
                            const isNoLaborable = noLaborablesDay.includes(day.getDate());
                            return (
                                <div className={clsx(classes.dia, isNoLaborable ? classes.noLaborable : null)} key={i}>
                                    <div>{format(day, 'd')}</div>
                                    <span>{format(day, 'EEE')}</span>
                                </div>
                            );
                        })}
                    </div>
                    {operarios.map((operario, i) => {
                        const solicitudes = solicitudesOperarios[operario.id] || [];
                        return (
                            <div className={classes.diasRow} key={i}>
                                {solicitudes.map((solicitud, i) => {
                                    let fechaInicio = solicitud.fecha_inicio;
                                    let startsBefore = false;

                                    if (fechaInicio < firstDayOfMonth) {
                                        startsBefore = true;
                                        fechaInicio = firstDayOfMonth;
                                    }
                                    let fechaFin = solicitud.fecha_fin;
                                    let endsAfter = false;

                                    if (fechaFin > lastDayOfMonth) {
                                        endsAfter = true;
                                        fechaFin = lastDayOfMonth;
                                    }

                                    const start = fechaInicio.getDate() - 1;
                                    const length = fechaFin.getDate() - fechaInicio.getDate() + 1;

                                    return (
                                        <div
                                            key={i}
                                            className={clsx(
                                                classes.diaItem,
                                                classes.solicitud,
                                                solicitud.estado,
                                                solicitud.tipo,
                                                { startsBefore, endsAfter },
                                            )}
                                            style={{
                                                position: 'absolute',
                                                left: start * DAY_CELL_SIZE,
                                                width: length * DAY_CELL_SIZE,
                                            }}
                                            title={solicitud.motivo}
                                            onClick={() =>
                                                onSelect(solicitud, (updatedSolicitud) => {
                                                    setSolicitudesOperarios((solicitudesOperarios) => ({
                                                        ...solicitudesOperarios,
                                                        [updatedSolicitud.operario_id]: solicitudesOperarios[
                                                            updatedSolicitud.operario_id
                                                        ].map((s) =>
                                                            s.id === updatedSolicitud.id
                                                                ? { ...s, ...convertDates(updatedSolicitud) }
                                                                : s,
                                                        ),
                                                    }));
                                                })
                                            }
                                        >
                                            {solicitud.tipo === 'VACACIONES' ? <VacacionesIcon /> : <AusenciaIcon />}
                                            {solicitud.motivo}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

Calendario.propTypes = {
    currentMonth: PropTypes.any,
    onSelect: PropTypes.any,
};
