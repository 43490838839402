import AssignmentIcon from '@material-ui/icons/Assignment';
import BuildIcon from '@material-ui/icons/Build';
import FolderIcon from '@material-ui/icons/Folder';
import ScheduleIcon from '@material-ui/icons/Schedule';

export const CapituloIcon = FolderIcon;
export const PartidaIcon = AssignmentIcon;
export const ManoObraIcon = ScheduleIcon;
export const MaterialIcon = BuildIcon;

export const tipoLineaIcons = {
    CAPITULO: CapituloIcon,
    PARTIDA: PartidaIcon,
    MANO_OBRA: ManoObraIcon,
    MATERIAL: MaterialIcon,
};
