import { useEffect, useState } from 'react';
import { Field, useField } from 'formik';
import { clientesProvider } from '../../../api';
import { Autocomplete } from 'formik-material-ui-lab';
import MuiTextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

export default function ClienteField({ error, helperText, disabled }) {
    const [clientesOptions, setClientesOptions] = useState([]);
    const [{ value }, , { setValue }] = useField('cliente');

    useEffect(() => {
        clientesProvider.getAll('as_options?with_address=true').then(setClientesOptions);
    }, []);

    useEffect(() => {
        if (clientesOptions.length === 0) return;

        setValue(value ? clientesOptions.find((d) => d.id === value.id) : null);
    }, [value, clientesOptions]);

    return (
        <Field
            name='cliente'
            fullWidth
            component={Autocomplete}
            options={clientesOptions}
            getOptionSelected={(option, value) => option.id === value?.id}
            getOptionLabel={(option) => option.nombre ?? ''}
            disabled={disabled}
            renderInput={(params) => <MuiTextField {...params} error={error} helperText={helperText} label='Cliente' />}
        />
    );
}

ClienteField.propTypes = {
    disabled: PropTypes.any,
    error: PropTypes.any,
    helperText: PropTypes.any,
};
