import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Field, Form, Formik } from 'formik';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { presupuestosProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { SwitchWithLabel } from '../common/fields/Switch';
import DialogEditor from '../common/forms/DialogEditor';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 600,
        },
        body: {
            gap: `${theme.spacing(2)}px`,
        },
        title: {
            color: theme.palette.neutral.grey4,
        },
        fields: {
            gap: `${theme.spacing(2)}px`,
        },
        field: {
            flex: 1,
        },
    }),
    { name: 'GenerarPdfDialog' },
);

const GenerarPdfDialogSchema = Yup.object().shape({
    incluirPrecios: Yup.boolean().required('Requerido'),
    incluirDescripciones: Yup.boolean().required('Requerido'),
});

export function afterGeneratingPdf({ onFinish, alternativeUrl, timeout = 5000, snackbar }) {
    return (res) => {
        const now = new Date();
        function checkStatus() {
            fetch(res.status_url)
                .then((res) => res.json())
                .then((res) => {
                    if (res.state === 'SUCCESS') {
                        window.open(res.result, '_blank');
                        onFinish && onFinish();
                    } else if (res.state === 'FAILURE') {
                        const message = res.status;

                        if (typeof message === 'string') {
                            snackbar && snackbar.showMessage(message);
                        } else if (typeof message === 'object' && message._schema) {
                            const messages = message._schema.map((m, i) => (
                                <Typography key={i} style={{ whiteSpace: 'nowrap' }}>
                                    {m}
                                </Typography>
                            ));
                            snackbar && snackbar.showMessage(messages);
                        } else {
                            snackbar && snackbar.showMessage('Error desconocido');
                        }
                        onFinish && onFinish();
                    } else {
                        const elapsed = new Date() - now;
                        if (elapsed > timeout) {
                            if (typeof alternativeUrl === 'function') {
                                alternativeUrl();
                            } else {
                                window.open(alternativeUrl, '_blank');
                            }
                            onFinish && onFinish();
                        } else {
                            setTimeout(checkStatus, 1000);
                        }
                    }
                })
                .catch((err) => {
                    console.error(err);
                    snackbar && snackbar.showMessage(err.body.message);
                    onFinish && onFinish();
                });
        }
        setTimeout(checkStatus, 1000);
    };
}

function GenerarPdfDialog({ presupuesto, open, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    return (
        <Formik
            initialValues={{
                incluirPrecios: true,
                incluirDescripciones: true,
            }}
            validationSchema={GenerarPdfDialogSchema}
            enableReinitialize
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                presupuestosProvider
                    .generarPdf(presupuesto.id, values)
                    .then(
                        afterGeneratingPdf({
                            onFinish: () => {
                                setSubmitting(false);
                                props.onClose();
                            },
                            alternativeUrl: presupuestosProvider.getPdfURL(presupuesto.id, values),
                            snackbar,
                        }),
                    )
                    .catch((err) => {
                        setSubmitting(false);
                        props.onClose();
                        console.error(err);
                        snackbar.showMessage(err.body.message);
                    });
            }}
        >
            {({ isSubmitting, submitForm }) => (
                <Form>
                    <DialogEditor
                        title='Generar PDF del presupuesto'
                        onSave={submitForm}
                        classes={{
                            root: classes.root,
                            body: classes.body,
                        }}
                        open={open}
                        canSave={!isSubmitting}
                        saveButtonText='Generar'
                        {...props}
                    >
                        <div className={classes.fields}>
                            <Field name='incluirPrecios' component={SwitchWithLabel} label='Incluir precios' />
                        </div>
                        <div className={classes.fields}>
                            <Field
                                name='incluirDescripciones'
                                component={SwitchWithLabel}
                                label='Incluir descripciones'
                            />
                        </div>
                    </DialogEditor>
                </Form>
            )}
        </Formik>
    );
}

GenerarPdfDialog.propTypes = {
    presupuesto: PropTypes.any,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.any,
};

export default withButtonOpener(GenerarPdfDialog);
