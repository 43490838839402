import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import Chip from '@material-ui/core/Chip';

export const VisibleLayerChip = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.hover,
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        height: 28,
        width: 'fit-content',
        '& svg': {
            color: theme.palette.primary.main,
        },
        '&.Mui-disabled': {
            cursor: 'pointer',
            pointerEvents: 'all',
            opacity: 1,
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.neutral.primary}`,
            color: theme.palette.neutral.primary,
        },
        '&.Mui-disabled svg': {
            color: theme.palette.neutral.primary,
        },
    },
    label: {
        flex: 1,
    },
    clickable: {
        '&:focus': {
            backgroundColor: theme.palette.primary.hover,
        },
        '&:hover': {
            backgroundColor: (props) => props.backgroundColor,
        },
        '&.Mui-disabled:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-disabled:focus': {
            backgroundColor: 'transparent',
        },
    },
}))(({ backgroundColor, ...props }) => React.createElement(Chip, props));
