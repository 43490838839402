import MenuItem from '@material-ui/core/MenuItem';
import isPast from 'date-fns/isPast';
import PropTypes from 'prop-types';
import { checklistsProvider } from '../../../api';
import DeleteDialog from '../../common/dialogs/DeleteDialog';
import MasterItemOptions from '../../common/forms/MasterDetailsView/MasterItemOptions';
import EditChecklistDialog from './EditChecklistDialog';

export default function ChecklistItemOptions({ item, onUpdate, onDelete }) {
    return (
        <MasterItemOptions>
            {({ closeMenu }) => [
                <EditChecklistDialog
                    key={0}
                    checklist={item}
                    onSave={onUpdate}
                    button={
                        <MenuItem disabled={item.fecha_fin && isPast(new Date(item.fecha_fin))} onClick={closeMenu}>
                            Editar
                        </MenuItem>
                    }
                />,
                <EditChecklistDialog
                    key={1}
                    checklist={item}
                    onSave={onUpdate}
                    duplicate
                    button={<MenuItem onClick={closeMenu}>Duplicar</MenuItem>}
                />,
                <DeleteDialog
                    key={2}
                    text='¿Estás seguro que quieres eliminar esta lista de tareas?'
                    onConfirm={(ev) => {
                        closeMenu(ev);
                        checklistsProvider.deleteChecklistTarea(item.id).then(onUpdate);
                    }}
                    button={
                        <MenuItem disabled={item.fecha_fin && isPast(new Date(item.fecha_fin))} onClick={closeMenu}>
                            Eliminar
                        </MenuItem>
                    }
                    confirmationText='borrar lista de tareas'
                />,
            ]}
        </MasterItemOptions>
    );
}

ChecklistItemOptions.propTypes = {
    item: PropTypes.any,
    onDelete: PropTypes.any,
    onUpdate: PropTypes.any,
};
