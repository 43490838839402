import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { albaranesCompraProvider } from '../../api';
import { estadoDevolucionOptions, estadoRevisionOptions } from '../../api/albaranes_compra';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import SelectField from '../common/fields/SelectField';
import DialogForm from '../common/forms/DialogForm';
import TagsField from './TagsField';

const PartidaSchema = Yup.object().shape({
    precio_unitario: Yup.number().required('Requerido'),
});

function EditRevisionLineaDialog({ linea, onSave, ...props }) {
    return (
        <DialogForm
            title='Editar revisión de material'
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    precio_unitario: linea.precio_unitario,
                    estado_revision: linea.estado_revision,
                    notas_revision: linea.notas_revision ?? '',
                    estado_devolucion: linea.estado_devolucion,
                    notas_devolucion: linea.notas_devolucion ?? '',
                    tags: linea.tags ?? [],
                },
                validationSchema: PartidaSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    // console.log(values);
                    // setSubmitting(false);
                    // return;

                    albaranesCompraProvider
                        .getAll(`revision-material/${linea.id}`, {
                            method: 'post',
                            body: JSON.stringify(values),
                        })
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {() => {
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                name='precio_unitario'
                                label='Precio unitario'
                                type='number'
                                fullWidth
                                InputProps={{
                                    endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name='estado_revision'
                                id='estado_revision'
                                component={SelectField}
                                label='Estado revisión de precios'
                                fullWidth
                                selectOptions={estadoRevisionOptions}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name='notas_revision'
                                label='Notas de la revisión'
                                fullWidth
                                multiline
                                minRows={4}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name='estado_devolucion'
                                id='estado_devolucion'
                                component={SelectField}
                                label='Estado devolución'
                                fullWidth
                                selectOptions={estadoDevolucionOptions}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name='notas_devolucion'
                                label='Notas de la devolución'
                                fullWidth
                                multiline
                                minRows={4}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TagsField />
                        </Grid>
                    </Grid>
                );
            }}
        </DialogForm>
    );
}

EditRevisionLineaDialog.propTypes = {
    presupuestoId: PropTypes.string,
    linea: PropTypes.object,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    editable: PropTypes.bool,
};

export default withButtonOpener(EditRevisionLineaDialog);
