import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CommentIcon from '@material-ui/icons/Comment';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            borderRadius: 4,
            color: 'white',
            padding: theme.spacing(0, 1),
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: `${theme.spacing(0.5)}px`,
            '& svg': {
                fontSize: 18,
            },
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        tooltip: {
            whiteSpace: 'pre-line',
            minWidth: 400,
        },
    }),
    { name: 'EstadoChip' },
);

export default function EstadoChip({ value, style, label, className, notas }) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, value, className)} style={style}>
            <Typography variant='subtitle2'>{label}</Typography>
            {notas && (
                <Tooltip arrow interactive title={notas} classes={{ tooltip: classes.tooltip }}>
                    <CommentIcon />
                </Tooltip>
            )}
        </div>
    );
}

EstadoChip.propTypes = {
    value: PropTypes.string.isRequired,
    style: PropTypes.object,
    label: PropTypes.string,
    className: PropTypes.string,
    notas: PropTypes.string,
};
