import withStyles from '@material-ui/core/styles/withStyles';
import OutlinedInput from '@material-ui/core/OutlinedInput';

export const FilterInput = withStyles(
    (theme) => ({
        input: {
            padding: 8,
            fontSize: 12,
        },
        adornedEnd: {
            paddingRight: 4,
        },
    }),
    { name: 'FilterInput' },
)(OutlinedInput);
