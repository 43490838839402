import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Form, withFormik } from 'formik';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { Link, useParams } from 'react-router-dom';
import AlertDialog from '../dialogs/AlertDialog';
import { useSnackbar } from 'material-ui-snackbar-provider';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

export const EditFormContext = React.createContext({});

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        // paddingTop: theme.spacing(2),
        // paddingBottom: theme.spacing(2),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    checkboxField: {
        marginLeft: theme.spacing(1),
    },
    textField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
    },
    shortField: {
        width: 100,
    },
    mediumField: {
        width: 200,
    },
    longField: {
        width: 450,
    },
    veryLongField: {
        width: 600,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    actions: {
        padding: theme.spacing(2),
        display: 'flex',
    },
    action: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    rowError: {
        backgroundColor: theme.palette.error.light,
    },
    dialogWide: {
        width: 550,
    },
}));

export const useFormStyles = useStyles;

export default function EditForm({
    title,
    basePath,
    onDelete,
    submitForm,
    resetForm,
    errors,
    isSubmitting,
    values,
    deleteTitle,
    deleteText,
    setFieldValue,
    extraActions,
    isExternalSubmitting,
    status,
    data,
    children,
    paperElevation,
}) {
    const [deleteElementId, setDeleteElementId] = useState(null);
    const { id } = useParams();
    const snackbar = useSnackbar();
    const classes = useStyles();

    const closeDeleteAlert = () => setDeleteElementId(null);
    const deleteElement = () => {
        onDelete(deleteElementId);
        closeDeleteAlert();
    };
    const confirmDelete = () => setDeleteElementId(id);

    useEffect(() => {
        if (status === 'saved') {
            snackbar.showMessage('Se han guardado los cambios');
        }
    }, [status, snackbar]);

    useEffect(() => {
        resetForm(data);
    }, [data, resetForm]);

    const WrappingComponent = paperElevation === null ? <React.Fragment /> : <Paper elevation={paperElevation} />;

    return (
        <EditFormContext.Provider value={{ classes, isSubmitting, values, errors, setFieldValue }}>
            {onDelete && (
                <AlertDialog
                    open={deleteElementId !== null}
                    onClose={closeDeleteAlert}
                    onConfirm={deleteElement}
                    dialogTitle={deleteTitle || 'Borrar registro'}
                    dialogText={
                        deleteText ||
                        '¿Estás seguro de que deseas borrar este registro permanentemente? Esta acción no se puede deshacer.'
                    }
                />
            )}

            {title && (
                <Typography variant='h4' gutterBottom component='h2'>
                    {title}
                </Typography>
            )}
            {React.cloneElement(
                WrappingComponent,
                {},
                <Form>
                    {children}
                    <div className={classes.actions}>
                        <Button
                            variant='contained'
                            disabled={isExternalSubmitting || isSubmitting}
                            className={classes.action}
                            component={Link}
                            to={basePath}
                            startIcon={<KeyboardArrowLeftIcon />}
                        >
                            Volver
                        </Button>
                        <div style={{ flexGrow: 1 }} />
                        {extraActions}
                        <Button
                            variant='contained'
                            color='primary'
                            disabled={isExternalSubmitting || isSubmitting}
                            onClick={submitForm}
                            className={classes.action}
                            startIcon={<SaveIcon />}
                        >
                            Guardar
                        </Button>
                        {onDelete && (
                            <Button
                                variant='contained'
                                color='secondary'
                                disabled={isExternalSubmitting || isSubmitting}
                                onClick={confirmDelete}
                                className={classes.action}
                                startIcon={<DeleteIcon />}
                            >
                                Borrar
                            </Button>
                        )}
                    </div>
                </Form>,
            )}
        </EditFormContext.Provider>
    );
}

export const withEditForm = (resourceName, validationSchema, cleanValues) => {
    return withFormik({
        mapPropsToValues: (props) => ({
            ...props.data,
        }),
        validationSchema: validationSchema,
        handleSubmit: (values, { props, setStatus, setSubmitting, setFieldError }) => {
            if (cleanValues) values = cleanValues(values);

            props.dataProvider
                .save(values, props.data_id)
                .then((data) => {
                    setStatus('saved');
                    setStatus('');
                    setSubmitting(false);

                    props.onSave && props.onSave(data);
                })
                .catch((error) => {
                    if (error.status === 401) {
                        // props.onUnauthorized();
                    } else if (error.status === 400) {
                        for (const [key, value] of Object.entries(error.message)) {
                            setFieldError(key, value);
                        }
                    }

                    setSubmitting(false);

                    props.onError && props.onError(error);
                });
        },
    });
};

export function useFormState() {
    const values = useContext(EditFormContext);

    return values;
}

EditForm.propTypes = {
    basePath: PropTypes.any,
    children: PropTypes.any,
    data: PropTypes.any,
    deleteText: PropTypes.any,
    deleteTitle: PropTypes.any,
    errors: PropTypes.any,
    extraActions: PropTypes.any,
    isExternalSubmitting: PropTypes.any,
    isSubmitting: PropTypes.any,
    onDelete: PropTypes.any,
    paperElevation: PropTypes.any,
    resetForm: PropTypes.any,
    setFieldValue: PropTypes.any,
    status: PropTypes.any,
    submitForm: PropTypes.any,
    title: PropTypes.any,
    values: PropTypes.any,
};
