import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import { operariosProvider } from '../../api';
import { Autocomplete } from 'formik-material-ui-lab';
import MuiTextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Button from '../common/Button';
import { SwitchWithLabelField } from '../common/fields/Switch';
import useAuthState from '../../AuthState';

const ExportarJornadaSchema = Yup.object().shape({
    from_date: Yup.mixed().required('Escoge una fecha'),
    to_date: Yup.mixed().required('Escoge una fecha'),
    operarios: Yup.array().min(1, 'Selecciona al menos un operario'),
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: 500,
    },
}));

export default function ExportarJornadaDialog({ button, operarioId }) {
    const styles = useStyles();
    const [open, setOpen] = useState(false);
    const [operariosOptions, setOperariosOptions] = useState(null);

    const {
        userInfo: { preferencias: { usar_marcajes_jornadas: usarMarcajesDefault } = {} },
    } = useAuthState();

    useEffect(() => {
        if (!open) return;

        if (!operarioId) {
            operariosProvider.getAllAsOptions().then(setOperariosOptions);
        } else {
            setOperariosOptions(null);
        }
    }, [operarioId, open]);

    const formButton = React.cloneElement(button, { onClick: () => setOpen(true) });

    const today = new Date();
    const fromDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const toDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    return (
        <>
            {formButton}
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby='nueva-tarea-title'
                open={open}
                classes={{
                    paper: styles.root,
                }}
                fullWidth={false}
                maxWidth='lg'
            >
                <DialogTitle id='nueva-tarea-title'>Exportar jornada laboral</DialogTitle>
                <DialogContent style={{ marginBottom: 16 }}>
                    <Formik
                        initialValues={{
                            from_date: fromDate,
                            to_date: toDate,
                            incluir_marcajes: usarMarcajesDefault,
                            operarios: operarioId ? [{ id: operarioId }] : [],
                        }}
                        validationSchema={ExportarJornadaSchema}
                        onSubmit={({ operarios, ...values }, { setSubmitting, setFieldError }) => {
                            operariosProvider
                                .fetchRaw('exportar_jornada', {
                                    method: 'post',
                                    body: JSON.stringify({
                                        ...values,
                                        operarios: operarios.map((o) => o.id),
                                    }),
                                })
                                .then(async (data) => {
                                    const uri = window.URL.createObjectURL(await data.blob());

                                    const a = document.createElement('a');
                                    a.style = { display: 'none' };
                                    a.href = uri;
                                    a.download = data.headers.get('Content-Disposition').split('filename=')[1];
                                    document.body.appendChild(a);
                                    a.click();
                                    window.URL.revokeObjectURL(uri);
                                    document.body.removeChild(a);

                                    setSubmitting(false);
                                    setOpen(false);
                                })
                                .catch((err) => {
                                    if (err.status === 400) {
                                        for (const [field, errors] of Object.entries(err.message)) {
                                            setFieldError(field, errors.join('\n'));
                                        }
                                    }
                                    // snackbar.showMessage('Ha ocurrido un error durante la descarga');
                                    console.error(err);
                                    setSubmitting(false);
                                });
                        }}
                    >
                        {({ isSubmitting, touched, errors, values, setFieldValue }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <KeyboardDatePicker
                                            name='from_date'
                                            label='Desde'
                                            format={'dd/MM/yyyy'}
                                            fullWidth
                                            autoOk
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <KeyboardDatePicker
                                            name='to_date'
                                            label='Hasta'
                                            format={'dd/MM/yyyy'}
                                            fullWidth
                                            autoOk
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SwitchWithLabelField
                                            name='incluir_marcajes'
                                            label='Incluir marcajes de los partes de trabajo'
                                            helpTooltip='Si marcas esta opción, se incluirá una linea extra por cada parte de trabajo que tenga marcajes (hora de inicio y de fin del servicio)'
                                        />
                                    </Grid>
                                    {operariosOptions && (
                                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                            <Field
                                                name='operarios'
                                                fullWidth
                                                multiple
                                                component={Autocomplete}
                                                options={operariosOptions}
                                                getOptionLabel={(option) => option.nombre}
                                                getOptionSelected={(option, value) => option.id === value.id}
                                                renderInput={(params) => (
                                                    <MuiTextField
                                                        {...params}
                                                        error={touched.operarios && !!errors.operarios}
                                                        helperText={touched.operarios && errors.operarios}
                                                        label='Operarios'
                                                        placeholder='Selecciona los operarios a exportar'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                            <Button
                                                color='primary'
                                                style={{ marginLeft: 8 }}
                                                onClick={() => setFieldValue('operarios', [...operariosOptions])}
                                            >
                                                Todos
                                            </Button>
                                        </Grid>
                                    )}
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                                    >
                                        <Button color='outlined' disabled={isSubmitting} onClick={() => setOpen(false)}>
                                            Cancelar
                                        </Button>
                                        <Button type='submit' color='info' disabled={isSubmitting}>
                                            Descargar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
}

ExportarJornadaDialog.propTypes = {
    button: PropTypes.any,
    operarioId: PropTypes.any,
};
