import SettingsIcon from '@material-ui/icons/Settings';
import differenceInDays from 'date-fns/differenceInDays';
import isPast from 'date-fns/isPast';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useCallback, useEffect, useMemo } from 'react';
import { dataProvider } from '../../api/contratos_mantenimiento';
import useAuthState from '../../AuthState';
import { useFetchData } from '../../hooks/useFetchData';
import { formatDate } from '../../utils';
import { DateColumnFilter, dateFilterFn } from '../common/list/DateColumnFilter';
import BaseListView, { withTableState } from '../common/list/ListView';
import { SelectColumnFilter } from '../common/list/SelectColumnFilter';
import { FilledIconButton } from '../fichajes/FilledIconButton';
import ConfigurarHorquillaDialog from './ConfigurarHorquillaDialog';
import EstadoChip, { A_PUNTO_VENCER, estadoOptions, VENCIDO, VIGENTE } from './EstadoChip';

const ListView = withTableState('instalaciones', BaseListView);

export default function ContratosMantenimientoList() {
    const fetchDataFn = useCallback(() => dataProvider.getAll(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        dataProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const {
        userInfo: { preferencias: { dias_margen_renovacion_contrato_mantenimiento: diasMargen } = {} },
    } = useAuthState();

    const columns = useMemo(
        () => [
            {
                Header: 'Cliente',
                accessor: 'cliente',
            },
            {
                Header: 'Instalacion',
                accessor: 'instalacion',
            },
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
            {
                Header: 'Fecha inicio',
                accessor: 'fecha_inicio',
                Cell: ({ value }) => formatDate(new Date(value)),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Fecha fin',
                accessor: 'fecha_fin',
                Cell: ({ value }) => formatDate(new Date(value)),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Número de servicios',
                accessor: 'num_servicios',
            },
            {
                Header: 'Número de equipos',
                accessor: 'num_equipos',
            },
            {
                Header: 'Estado',
                id: 'estado',
                accessor: (row) => {
                    const fechaFin = new Date(row.fecha_fin);

                    if (isPast(fechaFin)) return VENCIDO;
                    if (differenceInDays(fechaFin, new Date()) < diasMargen) return A_PUNTO_VENCER;
                    return VIGENTE;
                },
                Cell: EstadoChip,
                Filter: SelectColumnFilter,
                filter: 'equals',
                filterOptions: {
                    options: estadoOptions,
                    includeAllOption: {
                        value: 'todos',
                        label: 'Todos',
                    },
                },
                exportValue: ({ value }) => estadoOptions[value],
            },
        ],
        [diasMargen],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            deleteConfirmationText: 'borrar contrato',
        }),
        [],
    );

    return (
        <>
            {/* <Alert severity='info' variant='filled' style={{ marginBottom: 24 }}> */}
            {/*    Esta funcionalidad esta en fase beta. Puede ser que haya cambios importantes. ¿Dudas o sugerencias? */}
            {/*    Envianos un email a hola@labory.es */}
            {/* </Alert> */}
            <ListView
                title='Contratos de mantenimiento'
                tableTitle='Lista de contratos'
                titleActions={
                    <>
                        <ConfigurarHorquillaDialog
                            button={
                                <FilledIconButton>
                                    <SettingsIcon />
                                </FilledIconButton>
                            }
                            // onSave={setDiasMargenRevision}
                        />
                    </>
                }
                basePath='/contratos-mantenimiento'
                columns={columns}
                data={data}
                options={options}
                onDelete={onDelete}
            />
        </>
    );
}
