import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { equiposProvider } from '../../api';
import { useFetchData } from '../../hooks/useFetchData';
import EditForm from '../common/forms/EditForm/EditForm';
import { EditEquipoFormContent } from './EditEquipoFormContent';

export default function EditEquipoForm() {
    const { id } = useParams();

    const fetchDataFn = useCallback(() => equiposProvider.getAll(id), [id]);
    const { data, fetchData } = useFetchData(fetchDataFn, null);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        data && (
            <>
                <EditForm data={data} fetchData={fetchData}>
                    <EditEquipoFormContent />
                </EditForm>
            </>
        )
    );
}
