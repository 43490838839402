import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PaperListItem from './PaperListItem';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            backgroundColor: 'white',
        },
    }),
    { name: 'PaperList' },
);

export default function PaperList({ className, lineas }) {
    const classes = useStyles();

    return (
        <Paper elevation={0} className={clsx(classes.root, className)}>
            {lineas.map((linea, i) => (
                <PaperListItem label={linea.label} value={linea.value} key={i} color={linea.color} />
            ))}
        </Paper>
    );
}

PaperList.propTypes = {
    className: PropTypes.string,
    lineas: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            color: PropTypes.string,
        }),
    ).isRequired,
};
