import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'moment/locale/es';
import 'fontsource-roboto/400-normal.css';
import 'fontsource-roboto/500-normal.css';
import 'fontsource-roboto/700-normal.css';
import * as Sentry from '@sentry/react';
import { ENVIRONMENT, IS_LOCALHOST } from './api/api-config';

// eslint-disable-next-line jest/require-hook
if (!IS_LOCALHOST) {
    Sentry.init({
        dsn: 'https://deca749c1714fdd1ca5169bc9e308dbb@o4505630789074944.ingest.sentry.io/4505631198281728',
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ['localhost', 'https:yourserver.io/api/'],
            }),
            new Sentry.Replay({
                maskAllText: false,
                mutationBreadcrumbLimit: 750,
                mutationLimit: 1500,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: ENVIRONMENT === 'development' ? 1.0 : 0.1, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        environment: ENVIRONMENT,
        release: process.env.REACT_APP_VERSION,
    });
}

// eslint-disable-next-line jest/require-hook
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// eslint-disable-next-line jest/require-hook
// serviceWorkerRegistration.register();
