import React from 'react';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import EntradaIcon from '../icons/Entrada';
import { format, isSameTime } from '../../utils';
import SalidaIcon from '../icons/Salida';
import withStyles from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        fontSize: 18,
        color: '#8F95AF',
        marginRight: theme.spacing(0.5),
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        // padding: theme.spacing(1),
    },
}));

const List = withStyles((theme) => ({
    root: {
        '& .MuiListItem-divider:last-of-type': {
            borderBottom: 0,
        },
    },
    padding: {
        padding: 0,
    },
}))(MuiList);

const ListItem = withStyles((theme) => ({
    root: {
        '&>div': {
            display: 'flex',
            alignItems: 'center',
            flex: 1,
        },
        '&>div:first-of-type': {
            marginRight: theme.spacing(5),
        },
        '& .entrada svg': {
            color: '#2CBD96',
        },
        '& .salida svg': {
            color: '#E94F4F',
        },
        '& .timeDiff': {
            fontSize: 10,
            color: '#4C60FE',
            backgroundColor: '#e6eeef',
            padding: theme.spacing(0.5, 1),
            borderRadius: 20,
            marginLeft: 8,
            textAlign: 'center',
            width: 60,
            lineHeight: '16px',
        },
        '& .timeDiff.hidden': {
            visibility: 'hidden',
        },
    },
}))(MuiListItem);

export default function MarcajesPopover({ marcajes }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const label = `${marcajes
        .map((marcaje) => (marcaje.hora_salida === null ? 1 : 2))
        .reduce((a, b) => a + b, 0)} fichajes`;

    return (
        <div className={classes.root}>
            <InfoIcon
                className={classes.icon}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup='true'
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            />
            {label}
            <Popover
                id='mouse-over-popover'
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <List>
                    {marcajes.map((marcaje, i) => {
                        return (
                            <ListItem key={i} divider>
                                <div className={'entrada'}>
                                    <EntradaIcon /> {format(marcaje.hora_entrada, 'HH:mm')}
                                    <span
                                        className={clsx('timeDiff', {
                                            hidden: isSameTime(
                                                new Date(marcaje.hora_entrada),
                                                new Date(marcaje.hora_entrada_real),
                                            ),
                                        })}
                                    >
                                        {format(marcaje.hora_entrada_real, 'HH:mm')}
                                    </span>
                                </div>
                                <div className={'salida'}>
                                    <SalidaIcon /> {marcaje.hora_salida ? format(marcaje.hora_salida, 'HH:mm') : 'N/D'}
                                    <span
                                        className={clsx('timeDiff', {
                                            hidden: isSameTime(
                                                new Date(marcaje.hora_salida),
                                                new Date(marcaje.hora_salida_real),
                                            ),
                                        })}
                                    >
                                        {format(marcaje.hora_salida_real, 'HH:mm')}
                                    </span>
                                </div>
                            </ListItem>
                        );
                    })}
                </List>
            </Popover>
        </div>
    );
}

MarcajesPopover.propTypes = {
    marcajes: PropTypes.any,
};
