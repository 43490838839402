import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Categoria from './Categoria';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2),
            width: 700,
            maxWidth: 700,
            margin: '0 auto',
        },
        title: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        list: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(3),
        },
    }),
    { name: 'CentroAyuda' },
);

export default function CentroAyuda() {
    const classes = useStyles();

    const categorias = [
        {
            titulo: 'Empleados',
            url: 'https://labory.helpscoutdocs.com/category/14-operarios',
        },
        {
            titulo: 'Clientes',
            url: 'https://labory.helpscoutdocs.com/category/15-clientes',
        },
        {
            titulo: 'Servicios',
            url: 'https://labory.helpscoutdocs.com/category/22-servicios',
        },
        {
            titulo: 'App móvil para operarios',
            url: 'https://labory.helpscoutdocs.com/category/24-registro-de-partes-y-albaranes-app-mobil',
        },
        {
            titulo: 'Seguimiento de servicios',
            url: 'https://labory.helpscoutdocs.com/category/26-seguimiento-de-servicios',
        },
        {
            titulo: 'Control horario',
            url: 'https://labory.helpscoutdocs.com/category/34-registro-laboral',
        },
        {
            titulo: 'Vacaciones y ausencias',
            url: 'https://labory.helpscoutdocs.com/category/36-vacaciones-y-ausencias',
        },
        {
            titulo: 'Ajustes',
            url: 'https://labory.helpscoutdocs.com/category/38-ajustes',
        },
    ];

    return (
        <div className={classes.root}>
            <Typography variant='h1' className={classes.title} component='div'>
                Centro de ayuda
            </Typography>
            <div className={classes.list}>
                {categorias.map((categoria, i) => (
                    <Categoria key={i} categoria={categoria} />
                ))}
            </div>
        </div>
    );
}
