import DataProvider from './DataProvider';

export const empty = {};

class RendimientosProvider extends DataProvider {
    constructor() {
        super('rendimientos', empty);
    }

    getOrigenes = async () => this.action('origenes');
    addOrigen = async (nombre) => this.action('origenes', { method: 'post', body: JSON.stringify({ nombre }) });
    deleteOrigen = async (id) => this.action(`origenes/${id}`, { method: 'delete' });

    getByTareaId = async (tareaId) => this.action(`by-tarea/${tareaId}`);
    recalcular = async (id) => this.action(`${id}/recalcular`, { method: 'post' });

    getTareaMaterialesByFecha = async (id, fecha) => this.action(`${id}/materiales/${fecha}`);

    updateTareaMaterialesByFecha = async (id, fecha, coste) =>
        this.action(`${id}/materiales/${fecha}`, { method: 'post', body: JSON.stringify({ total_coste: coste }) });
}

export const dataProvider = new RendimientosProvider();
