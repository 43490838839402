import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { tareasProvider } from '../../../api';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterDetailsView from '../../common/forms/MasterDetailsView/MasterDetailsView';
import MasterItemOptions from '../../common/forms/MasterDetailsView/MasterItemOptions';
import ParteMantenimientoDetails from './ParteMantenimientoDetails';
import ParteMantenimientoItem from './ParteMantenimientoItem';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: `${theme.spacing(3)}px`,
            '&>div': {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: `${theme.spacing(2)}px`,
            },
        },
        actions: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        empty: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            margin: theme.spacing(6, 'auto'),
            gap: `${theme.spacing(3)}px`,
            width: 430,
        },
        emptyText: {
            color: theme.palette.neutral.grey4,
        },
        button: {
            marginLeft: 'auto',
        },
        actionInfo: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(0.5)}px`,
            color: theme.palette.neutral.grey4,
            '& svg': {
                fontSize: 16,
            },
        },
    }),
    { name: 'ParteTrabajoList' },
);

export default function ParteMantenimientoList() {
    const classes = useStyles();

    const { data: tarea, updateData } = useEditFormData();
    const partesMantenimiento = tarea.partes_mantenimiento;

    const finalizada = tarea.estado === 'FINALIZADA';

    return (
        <MasterDetailsView
            title='Revisiones de equipos'
            masterComponent={ParteMantenimientoItem}
            masterExtraProps={(parteMantenimiento) => ({
                onDelete: () => {
                    tareasProvider
                        .action(`partes-mantenimiento/${parteMantenimiento.id}`, {
                            method: 'delete',
                        })
                        .then(updateData);
                },
            })}
            detailsComponent={ParteMantenimientoDetails}
            items={partesMantenimiento}
            emptyComponent={
                <Paper elevation={0}>
                    <div className={classes.empty}>
                        <img src='/images/empty-partes-trabajo.png' />
                        <Typography variant='h1'>Equipos revisados</Typography>
                        <Typography variant='body2' className={classes.emptyText}>
                            Aquí recibirás las revisiones de equipos que los operarios generen con su App móvil.
                        </Typography>
                    </div>
                </Paper>
            }
            //
            actions={
                <MasterItemOptions>
                    {({ closeMenu }) => (
                        <MenuItem
                            onClick={() => {
                                closeMenu();
                                if (finalizada) {
                                    window.open(tareasProvider.getMantenimientoPdfUrl(tarea.id));
                                }
                            }}
                        >
                            <Tooltip
                                arrow
                                title={!finalizada ? 'Hay que cerrar el servicio para poder descargar el PDF' : ''}
                            >
                                <span>Descargar en PDF</span>
                            </Tooltip>
                        </MenuItem>
                    )}
                </MasterItemOptions>
            }
        />
    );
}

ParteMantenimientoList.propTypes = {};
