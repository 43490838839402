import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { formatDate } from '../../../../utils';
import { useEditFormData } from '../../../common/forms/EditForm/EditFormContext';
import MasterDetailsHeader from '../../../common/forms/MasterDetailsView/MasterDetailsHeader';
import PartesTrabajoChecklist from './PartesTrabajoChecklist';
import TiempoTareaChecklist from './TiempoTareaChecklist';

export function useJornadasBetweenDates(fechaInicio, fechaFin) {
    const { data: jornadas } = useEditFormData('jornadas');

    return useMemo(() => {
        return jornadas
            .filter(
                (j) =>
                    !isBefore(new Date(j.fecha), new Date(fechaInicio)) &&
                    (!fechaFin || !isAfter(new Date(j.fecha), new Date(fechaFin))) &&
                    j.marcajes.some((m) => m.subtarea),
            )
            .map((j) => ({
                ...j,
                subtareas: j.marcajes.filter((m) => m.subtarea).map((m) => m.subtarea.titulo),
            }))
            .sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
    }, [jornadas, fechaInicio, fechaFin]);
}

export default function ChecklistDetails({ index }) {
    const { data: checklist } = useEditFormData(`checklists_tarea.${index}`);

    const match = useRouteMatch();
    const location = useLocation();
    const selectedDate = match.isExact ? null : location.pathname.split('/').pop();

    const subtitle = selectedDate
        ? `del ${formatDate(selectedDate)}`
        : `del ${formatDate(checklist.fecha_inicio)} al ${
              checklist.fecha_fin ? formatDate(checklist.fecha_fin) : 'próximo cambio'
          }`;

    const jornadas = useJornadasBetweenDates(
        selectedDate ?? checklist.fecha_inicio,
        selectedDate ?? checklist.fecha_fin,
    );

    const subtareas = useMemo(() => checklist.items.map((i) => i.subtarea), [checklist]);

    return (
        <>
            <MasterDetailsHeader title={selectedDate ? 'Resumen' : 'Tareas'} subtitle={subtitle} />
            <TiempoTareaChecklist
                jornadas={jornadas}
                contarTiempo={checklist.contar_tiempo}
                marcarCompletado={checklist.marcar_completado}
                items={checklist.items}
            />
            {checklist.contar_tiempo && <PartesTrabajoChecklist jornadas={jornadas} subtareas={subtareas} />}
        </>
    );
}

ChecklistDetails.propTypes = {
    index: PropTypes.number.isRequired,
    preciosGastos: PropTypes.array,
    onUpdate: PropTypes.func,
};
