import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useState } from 'react';

const useStyles = makeStyles(
    (theme) => ({
        fotoContainer: {
            display: 'flex',
            justifyContent: 'center',
            '& img': {
                width: 400,
                height: 400,
                objectFit: 'cover',
            },
        },
        fotosList: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: `${theme.spacing(2)}px`,
        },
        fotosListItem: {
            border: '4px solid transparent',
            position: 'relative',
            cursor: 'pointer',
            '& img': {
                width: 100,
                height: 100,
                objectFit: 'cover',
            },
            '& .MuiCheckbox-root': {
                display: 'none',
                position: 'absolute',
                top: 0,
                right: 0,
                zIndex: 999,
            },
            '&:hover img': {
                opacity: 0.5,
            },
            '&:hover .MuiCheckbox-root': {
                display: 'block',
            },
        },
        fotoCurrent: {
            borderColor: theme.palette.primary.main,
        },
        fotoSelected: {
            '& img': {
                opacity: 0.5,
            },
            '& .MuiCheckbox-root': {
                display: 'block',
            },
        },
    }),
    { name: 'FromFotosTab' },
);

export default function FromFotosTab({ fotos, onToggleSelectedFoto }) {
    const classes = useStyles();
    const [currentFotoIdx, setCurrentFotoIdx] = useState(0);

    if (fotos.length === 0) {
        return (
            <Grid item xs={12}>
                <Typography>No hay fotos disponibles</Typography>
            </Grid>
        );
    }

    return (
        <>
            <Grid item xs={12}>
                <div className={classes.fotoContainer}>
                    <img src={fotos[currentFotoIdx].image_url} />
                </div>
            </Grid>
            <Grid item xs={12} className={classes.fotosList}>
                {fotos.map((foto, idx) => (
                    <div
                        key={foto.id}
                        className={clsx(
                            classes.fotosListItem,
                            foto.selected ? classes.fotoSelected : null,
                            idx === currentFotoIdx ? classes.fotoCurrent : null,
                        )}
                    >
                        <Checkbox color='primary' checked={foto.selected} onChange={() => onToggleSelectedFoto(foto)} />
                        <img src={foto.image_url} onClick={() => setCurrentFotoIdx(idx)} />
                    </div>
                ))}
            </Grid>
        </>
    );
}

FromFotosTab.propTypes = {
    fotos: PropTypes.array,
    onToggleSelectedFoto: PropTypes.func,
};
