import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { vehiculosProvider } from '../../api';
import { estadoLabels, tipoLabels } from '../../api/tareas';
import { useFetchData } from '../../hooks/useFetchData';
import { formatDate, formatDateTime, isAllDay } from '../../utils';
import { DateColumnFilter, dateFilterFn } from '../common/list/DateColumnFilter';
import ListView from '../common/list/ListView';
import { ClienteLinkCell } from '../dashboard/ClienteLink';
import ServicioEstadoChip from '../servicios/ServicioEstadoChip';

const useStyles = makeStyles((theme) => ({
    title: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flex: 1,
    },
    button: {
        marginLeft: theme.spacing(1),
        '& svg': {
            marginRight: theme.spacing(1),
        },
    },
    estadoLabel: {
        backgroundColor: '#ccc',
        borderRadius: 4,
        fontSize: 11,
        padding: 6,
        textTransform: 'uppercase',
        '&.pendiente': {
            color: '#F03738',
            backgroundColor: '#FDEBEB',
        },
        '&.en_progreso': {
            color: '#F3AC3D',
            backgroundColor: '#f8ead3',
        },
        '&.finalizada': {
            color: '#2CBD96',
            backgroundColor: '#CFF3E9',
        },
    },
}));

function EstadoCell({ value }) {
    const styles = useStyles();

    return <span className={clsx(styles.estadoLabel, value.toLowerCase())}>{estadoLabels[value]}</span>;
}

export default function ServiciosVehiculo() {
    const history = useHistory();
    const { id } = useParams();

    const styles = useStyles();
    const fetchDataFn = useCallback(() => vehiculosProvider.getTareas(id), [id]);
    const { data, fetchData, setData } = useFetchData(fetchDataFn);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const columns = useMemo(
        () => [
            {
                Header: 'Referencia',
                accessor: 'numero',
                Cell: ({ value }) => (value ? value.toString().padStart(4, '0') : 'N/D'),
            },
            {
                Header: 'Título',
                accessor: 'descripcion',
            },
            {
                Header: 'Cliente',
                accessor: 'cliente',
                Cell: ClienteLinkCell,
            },
            {
                Header: 'Direccion',
                accessor: 'direccion',
            },
            {
                Header: 'Tipo',
                id: 'tipo',
                accessor: (row) => tipoLabels[row.tipo],
            },
            {
                Header: 'Categoría',
                accessor: 'categoria',
            },
            {
                Header: 'Fecha inicio',
                accessor: 'fecha_inicio',
                Cell: ({ value, row: { values } }) =>
                    isAllDay(values.fecha_inicio, values.fecha_fin) ? formatDate(value) : formatDateTime(value),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Fecha fin',
                accessor: 'fecha_fin',
                Cell: ({ value, row: { values } }) =>
                    isAllDay(values.fecha_inicio, values.fecha_fin) ? formatDate(value) : formatDateTime(value),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Operarios',
                id: 'operarios',
                accessor: (row) => row.operarios.join(', '),
            },
            {
                Header: 'Estado',
                accessor: 'estado',
                Cell: ServicioEstadoChip,
            },
        ],
        [styles.estadoLabel],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            hideDelete: true,
        }),
        [],
    );

    return (
        <ListView
            columns={columns}
            data={data}
            options={options}
            extraActions={(row) => (
                <IconButton onClick={() => history.push(`/servicios/${row.tarea_id}`)}>
                    <VisibilityIcon />
                </IconButton>
            )}
            tableId='trabajos-vehiculo'
            tareas={data}
            setTareas={setData}
            basePath='/vehiculos'
            tableTitle='Lista de servicios'
        />
    );
}

EstadoCell.propTypes = {
    value: PropTypes.any,
};

ServiciosVehiculo.propTypes = {
    clienteId: PropTypes.any,
    direccionId: PropTypes.any,
    proyectoId: PropTypes.any,
};
