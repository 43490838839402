import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { useHistory, useParams } from 'react-router-dom';
import { preparacionesMaterialProvider } from '../../../api';
import useAuthState from '../../../AuthState';
import Button from '../../common/Button';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterDetailsView from '../../common/forms/MasterDetailsView/MasterDetailsView';
import NuevaPreparacionMaterialDialog from './NuevaPreparacionMaterialDialog';
import PreparacionSolicitudDetails from './PreparacionSolicitudDetails';
import PreparacionSolicitudItem from './PreparacionSolicitudItem';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: `${theme.spacing(3)}px`,
            '&>div': {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: `${theme.spacing(2)}px`,
            },
        },
        actions: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        empty: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            margin: theme.spacing(6, 'auto'),
            gap: `${theme.spacing(3)}px`,
            width: 430,
        },
        emptyText: {
            color: theme.palette.neutral.grey4,
        },
    }),
    { name: 'ParteTrabajoList' },
);

export default function PreparacionMaterialList() {
    const classes = useStyles();
    const { id: tareaId } = useParams();
    const history = useHistory();

    const {
        userInfo: { preferencias: { usar_preparacion_materiales_lista: usarPreparacionMaterialesLista } = {} },
    } = useAuthState();

    const { data: preparacionesMaterial, fetchData } = useEditFormData('preparaciones_material');

    const hayOtrasEnProgreso =
        preparacionesMaterial.filter((p) => p.type === 'PREPARACION' && p.estado !== 'PREPARADO').length > 0;

    return (
        <MasterDetailsView
            title='Preparaciones de material'
            masterComponent={PreparacionSolicitudItem}
            masterExtraProps={(item) => ({
                showHeader: false,
                onDelete: () => {
                    if (item.type === 'PREPARACION') preparacionesMaterialProvider.delete(item.id).then(fetchData);
                    else if (item.type === 'SOLICITUD')
                        preparacionesMaterialProvider.borrarSolicitud(item.id).then(fetchData);
                },
            })}
            detailsComponent={PreparacionSolicitudDetails}
            items={preparacionesMaterial}
            emptyComponent={
                <Paper elevation={0}>
                    <div className={classes.empty}>
                        <img src='/images/empty-partes-trabajo.png' />
                        <Typography variant='h1'>Preparaciones de material</Typography>
                        <Typography variant='body2' className={classes.emptyText}>
                            Hay servicios que necesitan material. En esta página podrás gestionar la preparación de
                            dicho material, así como las compras de material a proveedor.
                        </Typography>
                        {usarPreparacionMaterialesLista ? (
                            <Button
                                color='info'
                                onClick={() => {
                                    preparacionesMaterialProvider
                                        .addToTarea(tareaId, { lista_material: '' })
                                        .then(() => {
                                            fetchData();
                                        });
                                }}
                            >
                                Añadir preparación de material
                            </Button>
                        ) : (
                            <NuevaPreparacionMaterialDialog
                                tareaId={tareaId}
                                button={<Button color='info'>Añadir preparación de material</Button>}
                                onSave={fetchData}
                            />
                        )}
                    </div>
                </Paper>
            }
            actions={
                hayOtrasEnProgreso ? null : (
                    <NuevaPreparacionMaterialDialog
                        tareaId={tareaId}
                        button={
                            <IconButton>
                                <AddIcon />
                            </IconButton>
                        }
                        onSave={({ id: newId }) =>
                            fetchData().then(() =>
                                history.push(`/servicios/${tareaId}/preparaciones-material/${newId}`),
                            )
                        }
                    />
                )
            }
        />
    );
}

PreparacionMaterialList.propTypes = {};
