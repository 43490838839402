import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import EditFormHeader from '../common/forms/EditForm/EditFormHeader';
import EditFormHeaderItem from '../common/forms/EditForm/EditFormHeaderItem';
import EditFormHeaderItemList from '../common/forms/EditForm/EditFormHeaderItemList';
import { FormTab, FormTabs } from '../common/forms/EditForm/FormTabs';
import { DireccionesList } from './direcciones/DireccionesList';
import EditClienteDialog from './EditClienteDialog';
import {
    DIRECCIONES_ROUTE,
    EQUIPOS_ROUTE,
    OBSERVACIONES_ROUTE,
    PROYECTOS_ROUTE,
    SERVICIOS_ROUTE,
} from './EditClienteForm';
import { EditClienteMenuOptions } from './EditClienteMenuOptions';
import EquiposList from './EquiposList';
import { ObservacionesList } from './ObservacionesList';
import { ProyectosList } from './proyectos/ProyectosList';
import TrabajosCliente from './TrabajosCliente';

export function EditClienteFormContent() {
    const { data, fetchData } = useEditFormData();

    return (
        <>
            <EditFormHeader
                label='Cliente'
                name={data.nombre}
                actions={
                    <>
                        <EditClienteDialog
                            button={
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            }
                            onSave={fetchData}
                        />
                        <EditClienteMenuOptions />
                    </>
                }
            >
                <EditFormHeaderItemList>
                    <EditFormHeaderItem label='Código'>{data.codigo}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Dirección'>{data.direccion}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Población'>{data.poblacion}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Teléfono'>{data.telefono}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Email'>{data.email}</EditFormHeaderItem>
                    <EditFormHeaderItem label='NIF'>{data.nif}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Persona contacto'>{data.persona_contacto}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Activo'>{data.activo ? 'Si' : 'No'}</EditFormHeaderItem>
                </EditFormHeaderItemList>
            </EditFormHeader>

            <FormTabs>
                <FormTab addWrapper path={SERVICIOS_ROUTE} label='Historial de servicios'>
                    <TrabajosCliente disabled={!data.activo} />
                </FormTab>
                <FormTab addWrapper path={PROYECTOS_ROUTE} label='Proyectos'>
                    <ProyectosList disabled={!data.activo} />
                </FormTab>
                <FormTab addWrapper path={EQUIPOS_ROUTE} label='Equipos'>
                    <EquiposList disabled={!data.activo} />
                </FormTab>
                <FormTab addWrapper path={DIRECCIONES_ROUTE} label='Direcciones'>
                    <DireccionesList disabled={!data.activo} />
                </FormTab>
                <FormTab addWrapper path={OBSERVACIONES_ROUTE} label='Observaciones'>
                    <ObservacionesList />
                </FormTab>
            </FormTabs>
        </>
    );
}
