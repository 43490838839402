import FormHelperText from '@material-ui/core/FormHelperText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Button from '../../Button';
import DialogForm from '../../forms/DialogForm';
import ColorBgTextEditor from './ColorBgTextEditor';
import ColorEditor from './ColorEditor';

const useStyles = makeStyles(
    (theme) => ({
        fields: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
        },
        field: {
            display: 'flex',
        },
        label: {
            width: 200,
        },
    }),
    { name: 'ColorEditorDialog' },
);

const ColorEditorSchema = Yup.object().shape({
    color_bg: Yup.string().nullable(),
    color_fg: Yup.string().nullable(),
});

function ColorEditorDialog({ backgroundColor, textColor, onSaveColor, colors, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    return (
        <DialogForm
            title='Editar color del evento'
            maxWidth='lg'
            FormikProps={{
                initialValues: {
                    color_bg: backgroundColor,
                    color_fg: textColor,
                    personalizar: false,
                },
                validationSchema: ColorEditorSchema,
                enableReinitialize: true,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    if (values.color_bg === null && values.color_fg !== null) {
                        setFieldError('color_bg', 'Requerido');
                        setSubmitting(false);
                        return;
                    }

                    if (values.color_fg === null && values.color_bg !== null) {
                        setFieldError('color_fg', 'Requerido');
                        setSubmitting(false);
                        return;
                    }

                    onSaveColor(values)
                        .then(() => {
                            props.onClose();
                            props.onSave(values);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [key, value] of Object.entries(err.message)) {
                                    setFieldError(key, value[0]);
                                }
                            } else {
                                snackbar.showMessage('Ha ocurrido un error');
                            }
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ setFieldValue, values, errors }) => {
                let selectedIndex = 0;
                if (values.color_bg !== null && values.color_fg !== null) {
                    selectedIndex = colors.findIndex(
                        (c) => c.color_bg === values.color_bg && c.color_fg === values.color_fg,
                    );
                }

                return (
                    <div className={classes.fields}>
                        <div className={classes.field}>
                            <Typography variant='body2' component='div' className={classes.label}>
                                Libreria de colores
                            </Typography>
                            <ColorBgTextEditor
                                colors={colors}
                                onSelect={(color) => {
                                    setFieldValue('color_bg', color.color_bg);
                                    setFieldValue('color_fg', color.color_fg);
                                }}
                                selectedColor={selectedIndex}
                            />
                        </div>
                        {values.personalizar || selectedIndex < 0 ? (
                            <>
                                <div className={classes.field}>
                                    <Typography variant='body2' component='div' className={classes.label}>
                                        Color de fondo
                                        <FormHelperText error>{errors.color_bg}</FormHelperText>
                                    </Typography>
                                    <ColorEditor
                                        onSelect={(color) => setFieldValue('color_bg', color)}
                                        selectedColor={values.color_bg}
                                    />
                                </div>
                                <div className={classes.field}>
                                    <Typography variant='body2' component='div' className={classes.label}>
                                        Color del texto
                                        <FormHelperText error>{errors.color_fg}</FormHelperText>
                                    </Typography>
                                    <ColorEditor
                                        onSelect={(color) => setFieldValue('color_fg', color)}
                                        selectedColor={values.color_fg}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className={classes.field}>
                                <Typography variant='body2' className={classes.label}>
                                    Color personalizado
                                </Typography>
                                <Button color='outlined' onClick={() => setFieldValue('personalizar', true)}>
                                    Personalizar
                                </Button>
                            </div>
                        )}
                    </div>
                );
            }}
        </DialogForm>
    );
}

ColorEditorDialog.propTypes = {
    backgroundColor: PropTypes.any,
    colors: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    onSaveColor: PropTypes.any,
    textColor: PropTypes.any,
};

export default ColorEditorDialog;
