import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { tareasProvider } from '../../api';
import useAuthState from '../../AuthState';
import DeleteDialog from '../common/dialogs/DeleteDialog';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import EventosDialog from '../companies/profile/EventosDialog';
import DelayedDownloadComponent from './AlbaranList/DelayedDownloadComponent';
import PreciosGastosDialog from './PreciosGastosDialog';
import TiposHoraDefectoDialog from './TiposHoraDefectoDialog';

export function EditServicioMenuOptions() {
    const history = useHistory();
    const {
        data: { id, estado, facturable, jornadas, albaranes },
        fetchData,
    } = useEditFormData();

    const {
        userInfo: {
            preferencias: { usar_precios: usarPrecios },
        },
    } = useAuthState();

    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

    const openMenu = (event) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
    };

    const closeMenu = (event) => {
        event && event.stopPropagation();
        setMenuAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={openMenu}>
                <MoreHorizIcon />
            </IconButton>
            <Menu id='simple-menu' anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={closeMenu}>
                <MenuItem
                    onClick={(ev) => {
                        closeMenu(ev);
                        tareasProvider
                            .marcarFacturable(id, !facturable)
                            .then(fetchData)
                            .catch((err) => {
                                console.error(err);
                            });
                    }}
                >
                    Marcar como {facturable ? 'no facturable' : 'facturable'}
                </MenuItem>
                {usarPrecios && (
                    <PreciosGastosDialog
                        tareaId={id}
                        button={<MenuItem onClick={closeMenu}>Precios de gastos</MenuItem>}
                    />
                )}
                <TiposHoraDefectoDialog tareaId={id} button={<MenuItem onClick={closeMenu}>Tipos de hora</MenuItem>} />
                {estado === 'FINALIZADA' ? (
                    <MenuItem
                        onClick={(ev) => {
                            closeMenu(ev);
                            tareasProvider
                                .abrir(id)
                                .then(fetchData)
                                .catch((err) => {
                                    console.error(err);
                                });
                        }}
                    >
                        Abrir servicio
                    </MenuItem>
                ) : (
                    <MenuItem
                        onClick={(ev) => {
                            closeMenu(ev);
                            tareasProvider
                                .cerrar(id)
                                .then(fetchData)
                                .catch((err) => {
                                    console.error(err);
                                });
                        }}
                    >
                        Cerrar servicio
                    </MenuItem>
                )}
                <DelayedDownloadComponent
                    key={0}
                    Component={MenuItem}
                    onClick={() => tareasProvider.generarFichaPdf(id)}
                    closeMenu={closeMenu}
                    altDownloadAction={() => window.open(tareasProvider.getFichaPdfURL(id))}
                >
                    Descargar ficha en PDF
                </DelayedDownloadComponent>
                <EventosDialog entityId={id} button={<MenuItem onClick={closeMenu}>Registro de actividad</MenuItem>} />
                <DeleteDialog
                    text='¿Estás seguro que quieres eliminar este servicio?'
                    onConfirm={(ev) => {
                        closeMenu(ev);
                        tareasProvider.delete(id).then(() => history.goBack());
                    }}
                    button={<MenuItem onClick={closeMenu}>Eliminar</MenuItem>}
                    confirmationText='borrar servicio'
                >
                    {jornadas.length > 0 || albaranes.length > 0 ? (
                        <Typography variant='subtitle1'>
                            Este servicio contiene {jornadas.length} partes de trabajo
                            {albaranes.length > 0 ? ` y ${albaranes.length} albaranes de servicio` : null}. Si lo
                            eliminas, se eliminará también la información contenida.
                        </Typography>
                    ) : null}
                </DeleteDialog>
            </Menu>
        </>
    );
}
