import { createElement, useEffect, useMemo, useState } from 'react';
import { companiesProvider } from '../../api';
import { formatFullDateTime } from '../../utils';

export default function useSynchronizationJob(SyncButtonComponent, getCurrentSyncJobFn, buttonParams, onSyncFinished) {
    const [syncJob, setSyncJob] = useState(null);

    useEffect(() => {
        if (!getCurrentSyncJobFn) {
            setSyncJob(null);
            return;
        }
        getCurrentSyncJobFn().then(setSyncJob);
    }, [getCurrentSyncJobFn]);

    useEffect(() => {
        if (!syncJob || ['FINALIZADO', 'ERROR'].includes(syncJob.estado)) return;

        const intervalId = setInterval(updateTaskStatus, 2000);

        function updateTaskStatus() {
            companiesProvider.checkSynchronizationJobStatus(syncJob.celery_task_id).then((status) => {
                if (status.state === 'SUCCESS') {
                    setSyncJob(status.result);
                    clearInterval(intervalId);
                    if (onSyncFinished) onSyncFinished();
                }
            });
        }

        return () => clearInterval(intervalId);
    }, [syncJob]);

    const syncJobStatus = useMemo(() => {
        const status = {
            running: false,
            message: '',
        };

        if (!syncJob) return status;

        if (syncJob.estado === 'PENDIENTE' || syncJob.estado === 'EN_PROGRESO') {
            status.running = true;
        } else {
            status.message = `Última sincronización: ${formatFullDateTime(syncJob.fecha_creacion)}`;
        }

        return status;
    }, [syncJob]);

    const syncButton = useMemo(
        () =>
            createElement(SyncButtonComponent, {
                message: syncJobStatus.message,
                running: syncJobStatus.running,
                onJobStarted: setSyncJob,
                ...buttonParams,
            }),
        [syncJobStatus, buttonParams],
    );

    return {
        syncJob,
        syncJobStatus,
        syncButton,
    };
}
