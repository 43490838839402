import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { presupuestosProvider } from '../../../api';
import { tipoPresupuestoLineaLabels, tipoUnidadesShortLabels } from '../../../api/presupuestos';
import { formatCurrency, formatNumber } from '../../../utils';
import DeleteDialog from '../../common/dialogs/DeleteDialog';
import MasterItemOptions from '../../common/forms/MasterDetailsView/MasterItemOptions';
import EditBatchLineasDialog from '../EditBatchLineasDialog';
import PresupuestoTreeViewLinea from '../PresupuestoTreeViewTable/PresupuestoTreeViewLinea';
import ReordenarLineasDialog from '../ReordenarLineasDialog';
import { AddLineaButton } from './AddLineaButton';
import AplicarMargenDialog from './AplicarMargenDialog';
import { useCopyPaste } from './CopyPasteProvider';
import EditCapituloDialog from './EditCapituloDialog';
import EditDescomposicionDialog from './EditDescomposicionDialog';
import EditPartidaDialog from './EditPartidaDialog';
import { TableCell } from './Table';

/* eslint-disable react/jsx-key */

export default function PresupuestoLinea({ ...props }) {
    const { hasItems, items } = useCopyPaste();
    const snackbar = useSnackbar();

    function renderCells({ classes, linea }) {
        return [
            <TableCell className={clsx(classes.number, classes.unidades)}>{formatNumber(linea.unidades)}</TableCell>,
            <TableCell className={classes.tipoUnidades}>{tipoUnidadesShortLabels[linea.tipo_unidades]}</TableCell>,
            <TableCell className={classes.number}>{formatCurrency(linea.precio_coste)}</TableCell>,
            <TableCell className={classes.number}>{formatCurrency(linea.precio_venta)}</TableCell>,
            <TableCell className={classes.number}>{formatNumber(linea.margen * 100)} %</TableCell>,
            <TableCell className={classes.number}>{formatCurrency(linea.total_coste)}</TableCell>,
            <TableCell className={classes.number}>{formatCurrency(linea.total_venta)}</TableCell>,
            <TableCell className={classes.actions}>
                {props.editable && (linea.tipo_linea !== 'PARTIDA' || linea.num_avances === 0) && (
                    <AddLineaButton
                        presupuestoId={props.presupuestoId}
                        lineaId={linea.id}
                        tipoLinea={linea.tipo_linea}
                        onSave={props.onSave}
                    />
                )}
                {linea.tipo_linea === 'CAPITULO' && props.editable && (
                    <EditCapituloDialog
                        tooltip='Editar capítulo'
                        presupuestoId={props.presupuestoId}
                        capitulo={linea}
                        button={
                            <IconButton>
                                <EditIcon />
                            </IconButton>
                        }
                        onSave={props.onSave}
                        editable={props.editable && linea.num_avances === 0}
                    />
                )}
                {linea.tipo_linea === 'PARTIDA' && (
                    <EditPartidaDialog
                        tooltip='Editar partida'
                        presupuestoId={props.presupuestoId}
                        linea={linea}
                        button={
                            <IconButton>
                                <EditIcon />
                            </IconButton>
                        }
                        onSave={props.onSave}
                        editable={props.editable && linea.num_avances === 0}
                    />
                )}
                {(linea.tipo_linea === 'MANO_OBRA' || linea.tipo_linea === 'MATERIAL') && (
                    <EditDescomposicionDialog
                        tooltip={`Editar ${tipoPresupuestoLineaLabels[linea.tipo_linea].toLowerCase()}`}
                        presupuestoId={props.presupuestoId}
                        linea={linea}
                        button={
                            <IconButton>
                                <EditIcon />
                            </IconButton>
                        }
                        onSave={props.onSave}
                        editable={props.editable}
                    />
                )}
                <MasterItemOptions>
                    {({ renderButtonComponentMenuItem, renderMenuItem }) => {
                        const options = [
                            renderButtonComponentMenuItem('Aplicar margen a partidas', AplicarMargenDialog, {
                                presupuestoId: props.presupuestoId,
                                lineaId: linea.id,
                                onSave: props.onSave,
                            }),
                        ];

                        if (linea.lineas.length > 0 && linea.tipo_linea === 'CAPITULO') {
                            options.push(
                                renderButtonComponentMenuItem('Ordenar lineas del capítulo', ReordenarLineasDialog, {
                                    presupuestoId: props.presupuestoId,
                                    parentLineaId: linea.id,
                                    concepto: linea.concepto,
                                    lineas: linea.lineas,
                                    onSave: props.onSave,
                                }),
                            );
                        }

                        if (linea.lineas.length > 0 && linea.tipo_linea === 'PARTIDA') {
                            options.push(
                                renderButtonComponentMenuItem('Editar lineas', EditBatchLineasDialog, {
                                    presupuestoId: props.presupuestoId,
                                    parentLineaId: linea.id,
                                    concepto: linea.concepto,
                                    lineas: linea.lineas,
                                    onSave: props.onSave,
                                }),
                            );
                        }

                        if (hasItems && linea.tipo_linea === 'PARTIDA') {
                            options.push(
                                renderMenuItem('Pegar lineas seleccionadas', () => {
                                    const lineaIds = items.map((item) => item.id);

                                    presupuestosProvider
                                        .pegarLineas(props.presupuestoId, linea.id, lineaIds)
                                        .then(props.onSave);
                                }),
                            );
                        }

                        if (props.editable && (linea.num_avances === 0 || linea.num_avances === null)) {
                            options.push(
                                renderButtonComponentMenuItem('Eliminar', DeleteDialog, {
                                    text: '¿Estás seguro de borrar esta línea del presupuesto?',
                                    onConfirm: () =>
                                        presupuestosProvider
                                            .deleteLinea(props.presupuestoId, linea.id)
                                            .then(props.onSave)
                                            .catch((err) => snackbar.showMessage(err.message)),
                                }),
                            );
                        }

                        return options;
                    }}
                </MasterItemOptions>
            </TableCell>,
        ];
    }

    return (
        <PresupuestoTreeViewLinea
            renderCells={renderCells}
            isExpandable={(linea) => linea.tipo_linea === 'CAPITULO' || linea.tipo_linea === 'PARTIDA'}
            {...props}
        />
    );
}

PresupuestoLinea.propTypes = {
    presupuestoId: PropTypes.string,
    onSave: PropTypes.func,
    editable: PropTypes.bool,
};
