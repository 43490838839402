import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Field, Form, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Button from '../../common/Button';
import { Switch } from '../../common/fields/Switch';
import CalculadoraButton from './CalculadoraButton';

const useStyles = makeStyles(
    (theme) => ({
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '50px',
            paddingLeft: theme.spacing(3),
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
            '& .MuiIconButton-root': {
                marginLeft: 'auto',
                marginRight: theme.spacing(1),
            },
        },
        content: {
            padding: theme.spacing(2, 3),
            '& div': {
                display: 'flex',
                alignItems: 'flex-start',
            },
            '& strong': {
                fontWeight: theme.typography.fontWeightMedium,
            },
            '& .MuiGrid-item>.MuiTypography-root': {
                lineHeight: '32px',
            },
            '& .Mui-error.MuiFormHelperText-root': {
                whiteSpace: 'nowrap',
                textAlign: 'right',
                alignSelf: 'flex-end',
                marginRight: 0,
            },
        },
        dualSwitch: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(1)}px`,
            width: 'fit-content',
        },
        calculadora: {
            '&>div': {
                display: 'flex',
                alignItems: 'center',
            },
            '& div .MuiTypography-root:first-of-type': {
                flex: 1,
            },
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightMedium,
            },
            '& .MuiOutlinedInput-input': {
                textAlign: 'center',
            },
            '& .MuiTypography-h3': {
                fontWeight: theme.typography.fontWeightBold,
            },
            '& .MuiTypography-subtitle1': {
                fontWeight: theme.typography.fontWeightMedium,
                color: theme.palette.neutral.primary,
            },
            '& $title': {
                justifyContent: 'center',
                fontWeight: 600,
            },
        },
        pagoMensual: {
            margin: 'auto',
        },
        numOperarios: {
            width: 90,
        },
        codigoPromocional: {
            width: 200,
        },
        divider: {
            background: theme.palette.divider,
            height: 1,
            padding: 0,
            margin: theme.spacing(2, 1),
            flex: 1,
        },
        button: {
            width: '100%',
        },
        codigoPromocionalButton: {
            fontSize: 11,
            marginTop: theme.spacing(2),
        },
    }),
    { name: 'SuscripcionFormContent' },
);

export default function SuscripcionFormContent({ cuotaMinima, hasTarifa }) {
    const classes = useStyles();
    const [showCodigoPromocional, setShowCodigoPromocional] = useState(false);

    const { values, errors, setFieldValue } = useFormikContext();

    return (
        <>
            <Form className={classes.form}>
                <Paper elevation={0} className={classes.calculadora}>
                    <Typography className={classes.title}>
                        {hasTarifa ? 'Actualiza' : 'Activa'} tu suscripción
                    </Typography>
                    <Grid container spacing={2} className={classes.content}>
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.pago_mensual)} className={classes.pagoMensual}>
                                <label className={classes.dualSwitch}>
                                    Mensual
                                    <Field
                                        name='pago_mensual'
                                        component={Switch}
                                        checked={!values.pago_mensual}
                                        value={false}
                                        onChange={(ev) => setFieldValue('pago_mensual', !ev.target.checked)}
                                    />
                                    Anual (ahorra un 10%)
                                </label>
                                {errors.pago_mensual && <FormHelperText>{errors.pago_mensual}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        {cuotaMinima > 0 && (
                            <Grid item xs={12}>
                                <Typography>Cuota mínima</Typography>
                                <Typography>{cuotaMinima} €/mes</Typography>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Typography>Número de empleados</Typography>
                            <TextField
                                name='numero_licencias'
                                type='number'
                                variant='outlined'
                                className={classes.numOperarios}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {(showCodigoPromocional || values.codigo_promocional) && (
                                <>
                                    <Typography>Código promocional</Typography>
                                    <TextField
                                        name='codigo_promocional'
                                        variant='outlined'
                                        className={classes.codigoPromocional}
                                    />
                                </>
                            )}
                        </Grid>
                        <CalculadoraButton hasTarifa={hasTarifa} />
                    </Grid>
                </Paper>
            </Form>
            {!showCodigoPromocional && !values.codigo_promocional && (
                <Button
                    color='secondary'
                    onClick={() => setShowCodigoPromocional(true)}
                    className={classes.codigoPromocionalButton}
                >
                    ¿Tienes un código promocional?
                </Button>
            )}
        </>
    );
}

SuscripcionFormContent.propTypes = {
    cuotaMinima: PropTypes.any,
    hasTarifa: PropTypes.any,
};
