import { FormControl } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { clientesProvider, proyectosProvider } from '../../../api';
import { usePreferencias } from '../../../AuthState';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import { ButtonDialog } from '../../common/dialogs/ButtonDialog';
import ClienteField from '../../common/fields/ClienteField';
import DireccionField from '../../common/fields/DireccionField';
import FileUploader from '../../common/fields/FileUploader';
import { SwitchWithLabelField } from '../../common/fields/Switch';
import DialogForm from '../../common/forms/DialogForm';
import OperariosResponsablesField from '../../proyectos/OperariosResponsablesField';

const ProyectoSchema = Yup.object().shape({
    codigo: Yup.string().nullable().required('Requerido'),
    nombre: Yup.string().required('Requerido'),
    direccion: Yup.object().nullable().required('Requerido'),
    descuento: Yup.number().min(0, 'Debe ser mayor o igual a 0'),
});

function EditProyectoDialog({ onSave, clienteId, proyectoId, proyecto: originalProyecto, ...props }) {
    const usarPresupuestos = usePreferencias('usar_presupuestos');
    const snackbar = useSnackbar();

    const [proyecto, setProyecto] = useState(null);
    useEffect(() => {
        if (!proyectoId) return;

        if (props.open) {
            proyectosProvider.getById(proyectoId).then(setProyecto);
        } else {
            setProyecto(null);
        }
    }, [props.open, proyectoId]);

    useEffect(() => {
        if (originalProyecto) setProyecto(originalProyecto);
    }, [originalProyecto]);

    if (!proyecto) return null;

    return (
        <DialogForm
            title='Editar proyecto'
            FormikProps={{
                initialValues: {
                    codigo: proyecto.codigo,
                    cliente: proyecto.cliente_id ? { id: proyecto.cliente_id } : null,
                    direccion: proyecto.direccion_id ? { id: proyecto.direccion_id } : null,
                    nombre: proyecto.nombre,
                    operarios: proyecto.operarios,
                    fecha_inicio: proyecto.fecha_inicio ? new Date(proyecto.fecha_inicio) : null,
                    fecha_fin: proyecto.fecha_fin ? new Date(proyecto.fecha_fin) : null,
                    descuento: proyecto.descuento,
                    files: [],
                    usar_presupuestos: proyecto.usar_presupuestos,
                    usar_avances: proyecto.usar_avances,
                },
                enableReinitialize: true,
                validationSchema: ProyectoSchema,
                onSubmit: ({ files, cliente, direccion, ...values }, { setSubmitting, setFieldError }) => {
                    const formData = new FormData();

                    const formValues = {};

                    Object.entries(values).forEach(([key, value]) => (formValues[key] = value));
                    formValues.direccion_id = direccion ? direccion.id : null;

                    formData.append('params', JSON.stringify(formValues));

                    new Array(...files).forEach((file) => formData.append('files', file));

                    clientesProvider
                        .getAll(`${clienteId}/proyectos/${proyectoId}`, {
                            method: 'post',
                            body: formData,
                        })
                        .then((result) => {
                            onSave(result);
                            props.onClose();
                            snackbar.showMessage('Se ha actualizado el proyecto con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    if (field === 'direccion_id') {
                                        setFieldError('direccion', errors.join('\n'));
                                    } else {
                                        setFieldError(field, errors.join('\n'));
                                    }
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ values, setFieldValue, touched, errors }) => {
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField name='codigo' label='Codigo' fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField name='nombre' label='Nombre' fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <ClienteField
                                error={touched.cliente && !!errors.cliente}
                                helperText={touched.cliente && errors.cliente}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DireccionField
                                error={touched.direccion && !!errors.direccion}
                                helperText={touched.direccion && errors.direccion}
                                disabled={Boolean(proyecto.direccion_id)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <OperariosResponsablesField />
                        </Grid>
                        {usarPresupuestos && !proyecto.usar_presupuestos && (
                            <>
                                <Grid item xs={12}>
                                    <SwitchWithLabelField
                                        name='usar_presupuestos'
                                        label='Usar presupuestos'
                                        helpTooltip='Poder añadir presupuestos al proyecto'
                                    />
                                </Grid>
                                {values.usar_presupuestos && (
                                    <>
                                        <Grid item xs={12}>
                                            <SwitchWithLabelField
                                                name='usar_avances'
                                                label='Usar avances de obra y certificaciones'
                                                helpTooltip='Gestión de obra mediante avances y certificaciones de obra'
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <KeyboardDatePicker
                                                name='fecha_inicio'
                                                label='Fecha inicio'
                                                format={'dd/MM/yyyy'}
                                                fullWidth
                                                autoOk
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <KeyboardDatePicker
                                                name='fecha_fin'
                                                label='Fecha fin'
                                                format={'dd/MM/yyyy'}
                                                fullWidth
                                                autoOk
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                name='descuento'
                                                type='number'
                                                step='any'
                                                label='Descuento'
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                        {proyecto.usar_presupuestos && (
                            <>
                                <Grid item xs={4}>
                                    <KeyboardDatePicker
                                        name='fecha_inicio'
                                        label='Fecha inicio'
                                        format={'dd/MM/yyyy'}
                                        fullWidth
                                        autoOk
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <KeyboardDatePicker
                                        name='fecha_fin'
                                        label='Fecha fin'
                                        format={'dd/MM/yyyy'}
                                        fullWidth
                                        autoOk
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        name='descuento'
                                        type='number'
                                        step='any'
                                        label='Descuento'
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel shrink>Ficheros adjuntos</InputLabel>
                                <List dense style={{ marginTop: 16 }}>
                                    {proyecto.adjuntos.length === 0 ? (
                                        <ListItem>
                                            <ListItemText>No hay ficheros adjuntos</ListItemText>
                                        </ListItem>
                                    ) : (
                                        proyecto.adjuntos.map((adjunto) => {
                                            const isImage = adjunto.content_type.startsWith('image');

                                            return (
                                                <ListItem
                                                    key={adjunto.id}
                                                    dense
                                                    button
                                                    onClick={() =>
                                                        window.open(adjunto.image_url || adjunto.original_url, '_blank')
                                                    }
                                                >
                                                    <ListItemAvatar>
                                                        {isImage ? (
                                                            <Avatar variant='square' src={adjunto.thumbnail_url} />
                                                        ) : (
                                                            <Avatar variant='square'>
                                                                <PictureAsPdfIcon />
                                                            </Avatar>
                                                        )}
                                                    </ListItemAvatar>
                                                    <ListItemText primary={adjunto.original_url.split('/').pop()} />
                                                    <ListItemSecondaryAction>
                                                        <ButtonDialog
                                                            button={
                                                                <IconButton edge='end' aria-label='delete'>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            }
                                                            dialogTitle='¿Borrar adjunto?'
                                                            dialogText='Esta opcion no se puede deshacer. ¿Deseas continuar?'
                                                            onConfirm={() => {
                                                                clientesProvider
                                                                    .action(
                                                                        `${clienteId}/proyectos/${proyecto.id}/adjuntos/${adjunto.id}`,
                                                                        {
                                                                            method: 'delete',
                                                                        },
                                                                    )
                                                                    .then(() => {
                                                                        setProyecto((proyecto) => ({
                                                                            ...proyecto,
                                                                            adjuntos: proyecto.adjuntos.filter(
                                                                                (adj) => adj.id !== adjunto.id,
                                                                            ),
                                                                        }));
                                                                    });
                                                            }}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            );
                                        })
                                    )}
                                </List>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FileUploader
                                id='upload-proyecto-adjuntos-file'
                                label='Añadir adjuntos'
                                file={values.files}
                                setFile={(files) => setFieldValue('files', files)}
                                multiple={true}
                                isImage={false}
                                accept='image/jpeg,image/gif,image/png,application/pdf'
                            />
                        </Grid>
                    </Grid>
                );
            }}
        </DialogForm>
    );
}

export default withButtonOpener(EditProyectoDialog);

EditProyectoDialog.propTypes = {
    clienteId: PropTypes.any,
    proyectoId: PropTypes.any,
    proyecto: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};
