import DialogForm from '../../common/forms/DialogForm';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { cuestionariosProvider } from '../../../api';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import { Field } from 'formik';
import SelectField from '../../common/fields/SelectField';
import { tipoSeccionOptions } from '../../../api/cuestionarios';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';

const SeccionSchema = Yup.object().shape({
    titulo: Yup.string().required('Requerido'),
    columnas: Yup.string().required('Requerido'),
});

function EditSeccionDialog({ index, ...props }) {
    const snackbar = useSnackbar();

    const { id } = useParams();
    const { data: seccion, updateData } = useEditFormData(`secciones.${index}`);

    return (
        <DialogForm
            title='Editar sección'
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    titulo: seccion.titulo,
                    tipo: seccion.tipo,
                    columnas: seccion.columnas.join(', '),
                },
                validationSchema: SeccionSchema,
                onSubmit: ({ columnas, ...values }, { setSubmitting, setFieldError }) => {
                    cuestionariosProvider
                        .updateSeccion(id, seccion.id, { ...values, columnas_list: columnas })
                        .then((result) => {
                            updateData(result);
                            props.onClose();
                            snackbar.showMessage('Se ha actualizado la sección con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ values }) => (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField name='titulo' label='Titulo' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <Field
                            name='tipo'
                            id='tipo'
                            component={SelectField}
                            label='Tipo de respuestas'
                            fullWidth
                            selectOptions={tipoSeccionOptions}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name='columnas'
                            label='Respuestas'
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            placeholder={values.tipo === 'CHOICE' ? 'Bien, Regular, Mal' : 'Entrada, salida'}
                            helperText={`Lista de respuestas separada por comas. P.ej: ${
                                values.tipo === 'CHOICE' ? 'Bien, Regular, Mal' : 'Entrada, salida'
                            }`}
                        />
                    </Grid>
                    {values.tipo === 'CHOICE' && values.columnas && (
                        <Grid item xs={12}>
                            <Alert severity='info'>
                                La respuesta por defecto para esta sección es &quot;
                                {values.columnas.split(',')[0].trim()}&quot;
                            </Alert>
                        </Grid>
                    )}
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(EditSeccionDialog);

EditSeccionDialog.propTypes = {
    index: PropTypes.any,
    onClose: PropTypes.any,
};
