import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { createTiempo } from '../../api/tareas-functions';
import { formatTiempo } from '../../utils';

const useStyles = makeStyles(
    (theme) => ({
        excedido: {
            color: theme.palette.error.main,
        },
    }),
    { name: 'MinutosRealesCell' },
);

export default function MinutosRealesCell({
    value,
    row: {
        values: { minutos_estimados: minutosEstimados },
    },
}) {
    const classes = useStyles();
    const tiempoReal = createTiempo(value);

    return (
        <span className={minutosEstimados > 0 && value > minutosEstimados ? classes.excedido : null}>
            {formatTiempo(tiempoReal)}
        </span>
    );
}

MinutosRealesCell.propTypes = {
    value: PropTypes.number,
    row: PropTypes.object,
};
