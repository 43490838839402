import { useHistory } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import EditFormBase, { useFormState, withEditForm } from '../common/forms/EditForm';
import { dataProvider } from '../../api/clientes';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

const ClientesSchema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
    // email: Yup.string().email().required('Requerido'),
});

const TabGeneral = () => {
    const { classes } = useFormState();

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h2'>Datos personales</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField name='nombre' label='Nombre' fullWidth />
            </Grid>
            <Grid item xs={6}>
                <TextField name='codigo' label='Codigo de cliente' fullWidth />
            </Grid>
            <Grid item xs={6}>
                <TextField name='nif' label='NIF' fullWidth />
            </Grid>
            <Grid item xs={6}>
                <TextField name='email' label='Email' fullWidth />
            </Grid>
            <Grid item xs={6}>
                <TextField name='telefono' label='Telefono' fullWidth />
            </Grid>
            <Grid item xs={6}>
                <TextField name='direccion' label='Direccion' fullWidth />
            </Grid>
            <Grid item xs={6}>
                <TextField name='codigo_postal' label='Codigo postal' fullWidth />
            </Grid>
            <Grid item xs={6}>
                <TextField name='poblacion' label='Poblacion' fullWidth />
            </Grid>
            <Grid item xs={6}>
                <TextField name='provincia' label='Provincia' fullWidth />
            </Grid>
        </Grid>
    );
};

const EditForm = withEditForm('clientes', ClientesSchema)(EditFormBase);

export default function CreateClienteForm({ match, basePath, onUnauthorized }) {
    const history = useHistory();

    const data = {
        nombre: '',
        email: '',
        telefono: '',
        direccion: '',
        codigo_postal: '',
        poblacion: '',
        provincia: '',
    };

    return (
        <EditForm
            title='Añadir cliente'
            data={data}
            validationSchema={ClientesSchema}
            basePath={basePath}
            dataProvider={dataProvider}
            onUnauthorized={onUnauthorized}
            onSave={(data) => {
                history.push(`${basePath}/${data.id}`);
            }}
        >
            <TabGeneral />
        </EditForm>
    );
}

CreateClienteForm.propTypes = {
    basePath: PropTypes.any,
    match: PropTypes.any,
    onUnauthorized: PropTypes.any,
};
