import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { companiesProvider } from '../../api';
import { dataProvider } from '../../api/clientes';
import { usePreferencias } from '../../AuthState';
import { useFetchData } from '../../hooks/useFetchData';
import Button from '../common/Button';
import { SwitchWithLabel } from '../common/fields/Switch';
import BaseListView, { withTableState } from '../common/list/ListView';
import DownloadIcon from '../icons/Download';
import ClientesSyncButton from './ClientesSyncButton';
import ExportarTareasDialog from './ExportarTareasDialog';
import NewClienteDialog from './NewClienteDialog';
import useSynchronizationJob from './useSynchronizationJob';

const ListView = withTableState('clientes', BaseListView);

export default function ClientesList() {
    const history = useHistory();
    const fetchDataFn = useCallback(() => dataProvider.getAll(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);
    const { syncButton } = useSynchronizationJob(
        ClientesSyncButton,
        companiesProvider.getCurrentClientesSynchronizationJob,
    );
    const usarDelsol = usePreferencias('usar_delsol');

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        dataProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Codigo',
                accessor: 'codigo',
                show: false,
            },
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
            {
                Header: 'NIF',
                accessor: 'nif',
                show: false,
            },
            {
                Header: 'Telefono',
                accessor: 'telefono',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Direccion',
                accessor: 'direccion',
            },
            {
                Header: 'Poblacion',
                accessor: 'poblacion',
            },
            {
                Header: 'Activo',
                accessor: 'activo',
                // eslint-disable-next-line react/prop-types
                Cell: ({ value }) => (value ? <DoneIcon /> : null),
                filter: 'equals',
                disableGlobalFilter: true,
                disableFilters: true,
                disableSortBy: true,
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            deleteConfirmationText: 'borrar cliente',
            tableOptions: {
                useGlobalFilter: true,
                extraFilters: (setFilter, tableColumns) => {
                    const column = tableColumns.find((c) => c.id === 'activo');
                    const showingAll = column.filterValue === undefined;

                    return (
                        <SwitchWithLabel
                            label='Mostrar solo los clientes activos'
                            field={{
                                name: 'solo-clientes-activos',
                                onChange: () => column.setFilter(showingAll ? true : undefined),
                                value: !showingAll,
                            }}
                        />
                    );
                },
                initialState: {
                    filters: [{ id: 'activo', value: true }],
                },
            },
        }),
        [],
    );

    return (
        <ListView
            title='Clientes'
            tableTitle='Lista de clientes'
            titleActions={
                <>
                    {usarDelsol ? syncButton : null}
                    <ExportarTareasDialog
                        button={
                            <Button color='info' aria-label='Informe de servicios' startIcon={<DownloadIcon />}>
                                Informe de servicios
                            </Button>
                        }
                    />
                    <NewClienteDialog
                        button={
                            <Button color='info' aria-label='Nuevo cliente' startIcon={<AddIcon />}>
                                Nuevo cliente
                            </Button>
                        }
                        onSave={(id) => history.push(`clientes/${id}`)}
                    />
                </>
            }
            basePath='/clientes'
            columns={columns}
            data={data}
            options={options}
            onDelete={onDelete}
        />
    );
}
