import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useCallback, useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { operariosProvider } from '../../api';
import { OPERARIO, tipoLabels, tipoOptions } from '../../api/operarios';
import useAuthState from '../../AuthState';
import { useFetchData } from '../../hooks/useFetchData';
import useInfoSuscripcion from '../../hooks/useInfoSuscripcion';
import Button from '../common/Button';
import { SwitchWithLabel } from '../common/fields/Switch';
import BaseListView, { withTableState } from '../common/list/ListView';
import { multipleSelectFilterFn, SelectColumnFilter } from '../common/list/SelectColumnFilter';
import DownloadIcon from '../icons/Download';
import ExportarJornadaDialog from './ExportarJornadaDialog';
import NuevoOperarioDialog from './NuevoOperarioDialog';

const ListView = withTableState('operarios', BaseListView);

export default function OperariosList() {
    const fetchDataFn = useCallback(() => operariosProvider.getAll(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    const history = useHistory();
    const snackbar = useSnackbar();

    const { agotadas } = useInfoSuscripcion([data]);
    const { isAdministrativo } = useAuthState();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        operariosProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Codigo',
                accessor: 'codigo',
            },
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
            {
                Header: 'Tipo',
                accessor: 'tipo',
                Cell: ({ value }) => tipoLabels[value],
                Filter: SelectColumnFilter,
                filter: multipleSelectFilterFn,
                filterOptions: {
                    options: tipoOptions,
                    multiple: true,
                },
                exportValue: ({ value }) => tipoLabels[value],
            },
            {
                Header: 'Telefono',
                accessor: 'telefono',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Activo',
                accessor: 'activo',
                // eslint-disable-next-line react/prop-types
                Cell: ({ value }) => (value ? <DoneIcon /> : null),
                filter: 'equals',
                disableGlobalFilter: true,
                disableFilters: true,
                disableSortBy: true,
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            deleteConfirmationText: 'borrar empleado',
            tableOptions: {
                useGlobalFilter: true,
                extraFilters: (setFilter, tableColumns) => {
                    const column = tableColumns.find((c) => c.id === 'activo');
                    const showingAll = column.filterValue === undefined;

                    return (
                        <SwitchWithLabel
                            label='Mostrar solo los empleados activos'
                            field={{
                                name: 'solo-operarios-activos',
                                onChange: () => column.setFilter(showingAll ? true : undefined),
                                value: !showingAll,
                            }}
                        />
                    );
                },
                initialState: {
                    filters: [{ id: 'activo', value: true }],
                },
            },
        }),
        [],
    );

    return (
        <ListView
            title='Empleados'
            tableTitle='Lista de empleados'
            titleActions={
                <>
                    <ExportarJornadaDialog
                        button={
                            <Button color='info' aria-label='Exportar jornada' startIcon={<DownloadIcon />}>
                                Exportar jornada
                            </Button>
                        }
                    />
                    {!isAdministrativo && (
                        <>
                            <NuevoOperarioDialog
                                button={
                                    <Button
                                        color='info'
                                        aria-label='Nuevo empleado'
                                        startIcon={<AddIcon />}
                                        disabled={agotadas}
                                    >
                                        Nuevo empleado
                                    </Button>
                                }
                                tipo={OPERARIO}
                                tooltip={
                                    agotadas ? (
                                        <>
                                            Has llegado al limite de empleados. Actualiza tu suscripción o desactiva
                                            algún empleado.
                                            <Button color='secondary' to='/mi-cuenta/suscripcion' component={Link}>
                                                Actualizar suscripción
                                            </Button>
                                        </>
                                    ) : (
                                        ''
                                    )
                                }
                                TooltipProps={{
                                    interactive: true,
                                }}
                                onSave={(id) => history.push(`empleados/${id}`)}
                            />
                        </>
                    )}
                </>
            }
            basePath='/empleados'
            columns={columns}
            data={data}
            options={options}
            onDelete={onDelete}
            // extraActions={row => (
            //     <Tooltip title='Calendario'>
            //         <Link to={`/operarios/${row.id}/calendario`}>
            //             <IconButton style={{padding: 6}} aria-label="Jornada laboral">
            //                 <CalendarTodayOutlinedIcon fontSize="small" />
            //             </IconButton>
            //         </Link>
            //     </Tooltip>
            // )}
        />
    );
}
