import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
        },
        label: {
            fontWeight: 500,
            marginRight: theme.spacing(0.5),
            whiteSpace: 'nowrap',
        },
    }),
    { name: 'EditFormHeaderItem' },
);

export default function EditFormHeaderItem({ label, children, emptyText = 'Sin especificar' }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant='subtitle1' className={classes.label}>
                {label}:
            </Typography>
            {typeof children !== 'object' || children === null ? (
                <Typography variant='subtitle1'>{children || emptyText}</Typography>
            ) : (
                children
            )}
        </div>
    );
}

EditFormHeaderItem.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.any,
    emptyText: PropTypes.string,
};
