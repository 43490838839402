import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { preparacionesMaterialProvider } from '../../../api';
import useAuthState from '../../../AuthState';
import { formatFullDateTime } from '../../../utils';
import Button from '../../common/Button';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterDetailsHeader from '../../common/forms/MasterDetailsView/MasterDetailsHeader';
import PaperTextEditor from '../../common/forms/PaperTextEditor';
import CompraMaterialPaper from './CompraMaterialPaper';
import PreparacionMaterialListaMateriales from './ListaMateriales/PreparacionMaterialListaMateriales';
import NuevaCompraMaterialDialog from './NuevaCompraMaterialDialog';
import PreparacionSolicitudesList from './PreparacionSolicitudesList';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '50px',
            paddingLeft: theme.spacing(2),
        },
        dividerRow: {
            '& td': {
                padding: 0,
                border: 'none',
            },
        },
        emptyRow: {
            '& td': {
                color: theme.palette.neutral.primary,
            },
            '& td:not(:first-of-type)': {
                border: 'none',
            },
        },
        actions: {
            whiteSpace: 'nowrap',
            textAlign: 'right',
            border: 'none',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        heroPendiente: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(4),
            '& .MuiTypography-root': {
                textAlign: 'center',
                fontWeight: theme.typography.fontWeightRegular,
            },
        },
    }),
    { name: 'PreparacionMaterialDetails' },
);

export default function PreparacionMaterialDetails({ index }) {
    const classes = useStyles();
    const {
        data: preparacionMaterial,
        updateLocalData,
        fetchData,
    } = useEditFormData(`preparaciones_material.${index}`);

    const {
        userInfo: {
            preferencias: {
                usar_solicitudes_material: usarSolicitudesMaterial,
                usar_preparacion_materiales_lista: usarPreparacionMaterialesLista,
            } = {},
        },
    } = useAuthState();

    const headerItems = [
        { label: 'Creado el', value: formatFullDateTime(preparacionMaterial.fecha_creacion) },
        { label: 'Última actualización', value: formatFullDateTime(preparacionMaterial.fecha_actualizacion) },
    ];

    if (preparacionMaterial.fecha_limite)
        headerItems.push({ label: 'Fecha límite', value: formatFullDateTime(preparacionMaterial.fecha_limite) });

    return (
        <>
            <MasterDetailsHeader title='Preparación de material:' subtitle={index + 1} items={headerItems} />
            {usarSolicitudesMaterial && (
                <PreparacionSolicitudesList preparacionMaterial={preparacionMaterial} onUpdate={fetchData} />
            )}
            {usarPreparacionMaterialesLista ? (
                <PreparacionMaterialListaMateriales index={index} />
            ) : (
                <PaperTextEditor
                    title='Lista de material a preparar'
                    value={preparacionMaterial.lista_material}
                    className={classes.root}
                    canEdit={preparacionMaterial.estado !== 'TERMINADO'}
                    onSave={(value) => {
                        preparacionesMaterialProvider
                            .updateListaMaterial(preparacionMaterial.id, value)
                            .then(() =>
                                preparacionesMaterialProvider
                                    .getById(preparacionMaterial.id)
                                    .then((res) => updateLocalData(null, { id: preparacionMaterial.id, ...res })),
                            );
                    }}
                />
            )}
            {preparacionMaterial.estado !== 'PENDIENTE' && (
                <PaperTextEditor
                    title='Notas'
                    value={preparacionMaterial.notas}
                    className={classes.root}
                    canEdit={preparacionMaterial.estado !== 'TERMINADO'}
                    placeholder='Escribe aquí notas referentes a la preparación de material'
                    onSave={(value) => {
                        preparacionesMaterialProvider
                            .updateNotas(preparacionMaterial.id, value)
                            .then(() =>
                                preparacionesMaterialProvider
                                    .getById(preparacionMaterial.id)
                                    .then((res) => updateLocalData(null, { id: preparacionMaterial.id, ...res })),
                            );
                    }}
                />
            )}
            {!usarPreparacionMaterialesLista && preparacionMaterial.estado !== 'PENDIENTE' && (
                <>
                    {preparacionMaterial.compras_material.map((compraMaterial) => (
                        <CompraMaterialPaper
                            key={compraMaterial.id}
                            compraMaterial={compraMaterial}
                            onSave={() =>
                                preparacionesMaterialProvider
                                    .getById(preparacionMaterial.id)
                                    .then((res) => updateLocalData(null, { id: preparacionMaterial.id, ...res }))
                            }
                        />
                    ))}
                    <NuevaCompraMaterialDialog
                        preparacionMaterialId={preparacionMaterial.id}
                        button={
                            <Button
                                color='primary'
                                startIcon={<AddIcon />}
                                style={{ paddingLeft: 0, marginRight: 'auto' }}
                            >
                                Añadir compra de material
                            </Button>
                        }
                        onSave={() =>
                            preparacionesMaterialProvider
                                .getById(preparacionMaterial.id)
                                .then((res) => updateLocalData(null, { id: preparacionMaterial.id, ...res }))
                        }
                    />
                </>
            )}
            {!usarPreparacionMaterialesLista && preparacionMaterial.estado === 'PENDIENTE' && (
                <Paper elevation={0} className={classes.heroPendiente}>
                    <Typography variant='h1'>Empezar a preparar material</Typography>

                    <Typography variant='body2'>
                        Al empezar a preparar el material de la lista, podrás añadir notas y solicitar la compra de
                        material en caso de que sea necesario.
                    </Typography>

                    <Button
                        color='info'
                        onClick={() =>
                            preparacionesMaterialProvider
                                .iniciar(preparacionMaterial.id)
                                .then(() =>
                                    preparacionesMaterialProvider.getById(preparacionMaterial.id).then(fetchData),
                                )
                        }
                    >
                        Empezar preparación
                    </Button>
                </Paper>
            )}
        </>
    );
}

PreparacionMaterialDetails.propTypes = {
    index: PropTypes.any.isRequired,
};
