import Grid from '@material-ui/core/Grid';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { documentosProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import OperariosField from '../common/fields/OperariosField';
import DialogForm from '../common/forms/DialogForm';

const DocumentosSchema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
    descripcion: Yup.string(),
});

function EditCarpetaDialog({ id, onSave, ...props }) {
    const snackbar = useSnackbar();
    const [carpeta, setCarpeta] = useState(null);

    useEffect(() => {
        if (!props.open) {
            setCarpeta(null);
            return;
        }

        documentosProvider.getAll(`carpetas/${id}`).then(setCarpeta);
    }, [id, props.open]);

    if (!carpeta) return null;

    return (
        <DialogForm
            title='Editar carpeta'
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    nombre: carpeta.nombre,
                    descripcion: carpeta.descripcion,
                    operarios: carpeta.operarios,
                    publica: carpeta.operarios.length === 0,
                },
                validationSchema: DocumentosSchema,
                onSubmit: ({ publica, ...values }, { setSubmitting, setFieldError }) => {
                    if (publica) values.operarios = [];

                    documentosProvider
                        .saveCarpeta(values, id)
                        .then(() => {
                            onSave && onSave();
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                if (err.message instanceof Object) {
                                    for (const [field, errors] of Object.entries(err.message)) {
                                        setFieldError(field, errors.join('\n'));
                                    }
                                } else {
                                    snackbar.showMessage(err.message);
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {({ values }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField name='nombre' label='Nombre' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name='descripcion' label='Descripcion' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <CheckboxWithLabel
                            type='checkbox'
                            name='publica'
                            Label={{
                                label: 'Visible para todos los operarios',
                            }}
                        />
                    </Grid>
                    {!values.publica && (
                        <Grid item xs={12}>
                            <OperariosField />
                        </Grid>
                    )}
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(EditCarpetaDialog);

EditCarpetaDialog.propTypes = {
    id: PropTypes.any,
    open: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
