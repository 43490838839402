import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { presupuestosProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import DialogEditor from '../common/forms/DialogEditor';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 600,
        },
        body: {
            gap: `${theme.spacing(2)}px`,
        },
        title: {
            color: theme.palette.neutral.grey4,
        },
        fields: {
            gap: `${theme.spacing(2)}px`,
        },
        field: {
            flex: 1,
        },
    }),
    { name: 'EditarPiePresupuestoDialog' },
);

const EditarPiePresupuestoDialogSchema = Yup.object().shape({
    secciones: Yup.array().of(
        Yup.object().shape({
            titulo: Yup.string(),
            contenido: Yup.string().required('Requerido'),
        }),
    ),
});

function EditarPiePresupuestoDialog({ presupuesto, onSave, open, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    return (
        <Formik
            initialValues={{
                secciones:
                    presupuesto.pie_pagina?.secciones?.length > 0
                        ? presupuesto.pie_pagina.secciones
                        : [{ titulo: '', contenido: '' }],
            }}
            validationSchema={EditarPiePresupuestoDialogSchema}
            enableReinitialize
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                presupuestosProvider
                    .editarPie(presupuesto.id, values)
                    .then(() => {
                        props.onClose();
                        setSubmitting(false);
                        onSave();
                    })
                    .catch((err) => {
                        if (err.status === 400) {
                            for (const [key, value] of Object.entries(err.message)) {
                                setFieldError(key, value[0]);
                            }
                        } else {
                            snackbar.showMessage('Ha ocurrido un error');
                        }

                        setSubmitting(false);
                    });
            }}
        >
            {({ isSubmitting, submitForm }) => (
                <Form>
                    <DialogEditor
                        title='Editar pie de presupuesto'
                        onSave={submitForm}
                        classes={{
                            root: classes.root,
                            body: classes.body,
                        }}
                        open={open}
                        canSave={!isSubmitting}
                        saveButtonText='Guardar'
                        {...props}
                    >
                        <div className={classes.fields}>
                            <TextField
                                name='secciones[0].contenido'
                                label='Contenido'
                                fullWidth
                                multiline
                                minRows={6}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                    </DialogEditor>
                </Form>
            )}
        </Formik>
    );
}

EditarPiePresupuestoDialog.propTypes = {
    presupuesto: PropTypes.any,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.any,
    open: PropTypes.any,
};

export default withButtonOpener(EditarPiePresupuestoDialog);
