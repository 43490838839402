import { Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { endOfDay } from 'date-fns';
import addDays from 'date-fns/addDays';
import isPast from 'date-fns/isPast';
import isValid from 'date-fns/isValid';
import subDays from 'date-fns/subDays';
import { Form, useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { withButtonOpener } from '../../../../hooks/withButtonOpener';
import { formatDate } from '../../../../utils';
import Button from '../../../common/Button';
import { SelectControl } from '../../../common/list/SelectControl';
import { getDefaultChecklist } from '../ChecklistsFormControl';
import EditChecklistForm from './EditChecklistForm';

const useStyles = makeStyles(
    (theme) => ({
        dialogPaper: {},
        content: {
            display: 'flex',
            flexDirection: 'column',
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            height: '100%',
        },
        title: {
            paddingRight: theme.spacing(2),
            gap: `${theme.spacing(2)}px`,
        },
        checklistSelectorControl: {
            margin: theme.spacing(1, 0, 4, 0),
            flex: 1,
            '& .MuiSelect-root': {
                fontSize: 16,
            },
        },
        checklistSelectorHelper: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 10,
            fontWeight: 500,
            color: theme.palette.warning.main,
            '& .MuiButton-root .MuiSvgIcon-root': {
                fontSize: 15,
            },
            '& .MuiButton-root': {
                marginLeft: 'auto',
                padding: theme.spacing(0.5),
                fontSize: 13,
                height: 24,
            },
        },
    }),
    { name: 'EditChecklistDialog' },
);

function EditChecklistDialog({ name, onDelete, ...props }) {
    const classes = useStyles();

    const [selectedChecklistIndex, setSelectedChecklistIndex] = useState(0);
    const [{ value: checklistsTarea }, { error }, { setValue: setChecklistsTareaValue }] = useField(name);

    const checklistOptions = useMemo(() => {
        return checklistsTarea.map((checklist, index) => {
            let label = 'del ';
            label += isValid(checklist.fecha_inicio) ? `${formatDate(checklist.fecha_inicio)}` : '...';
            if (isValid(checklist.fecha_fin)) {
                label += ` al ${formatDate(checklist.fecha_fin)}`;
            } else {
                label += ' hasta nuevo cambio';
            }
            return {
                value: index,
                label,
            };
        });
    }, [checklistsTarea]);

    const selectedChecklistFieldName = `${name}.${selectedChecklistIndex}`;

    const { isSubmitting } = useFormikContext();

    const [{ value: selectedChecklist }, { touched, initialValue }, { setValue: setChecklistValue }] =
        useField(selectedChecklistFieldName);

    const selectedChecklistItems = selectedChecklist ? selectedChecklist.items : [];

    const selectedChecklistSubtareaIds = useMemo(
        () => [...new Set(selectedChecklistItems.map((i) => i.subtarea_id).filter(Boolean))],
        [selectedChecklistItems],
    );

    return (
        <Dialog
            maxWidth='sm'
            classes={{
                paper: classes.dialogPaper,
            }}
            fullWidth
            {...props}
        >
            <Form className={classes.form}>
                <DialogTitle className={classes.title} disableTypography>
                    <Typography variant='h6'>Editar listas de tareas</Typography>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <SelectControl
                        label='Seleccionar lista de tareas'
                        classes={{
                            formControl: classes.checklistSelectorControl,
                        }}
                        variant='standard'
                        options={checklistOptions}
                        value={selectedChecklistIndex}
                        onChange={(e) => {
                            setSelectedChecklistIndex(e.target.value);
                        }}
                    >
                        <div className={classes.checklistSelectorHelper}>
                            <Button
                                color='primary'
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    const newChecklistsTarea = [...checklistsTarea];
                                    let newChecklistDate = new Date();
                                    if (newChecklistsTarea.length > 0) {
                                        const fechaInicio = new Date(
                                            newChecklistsTarea[newChecklistsTarea.length - 1].fecha_inicio,
                                        );
                                        const fechaFin = isPast(endOfDay(fechaInicio))
                                            ? subDays(new Date(), 1)
                                            : fechaInicio;
                                        newChecklistsTarea[newChecklistsTarea.length - 1].fecha_fin = fechaFin;

                                        newChecklistDate = addDays(fechaFin, 1);
                                    }
                                    newChecklistsTarea.push(getDefaultChecklist(newChecklistDate));
                                    setChecklistsTareaValue(newChecklistsTarea);
                                    setSelectedChecklistIndex(newChecklistsTarea.length - 1);
                                }}
                            >
                                Añadir lista de tareas
                            </Button>
                        </div>
                    </SelectControl>
                    <EditChecklistForm
                        fieldName={selectedChecklistFieldName}
                        currentSubtareaIds={selectedChecklistSubtareaIds}
                    />
                </DialogContent>
                <DialogActions>
                    {initialValue && (
                        <Button
                            disabled={!touched}
                            color='outlined'
                            onClick={() => {
                                props.onClose();
                                setChecklistValue(initialValue);
                            }}
                        >
                            Cancelar cambios
                        </Button>
                    )}

                    <Button
                        color='error'
                        onClick={() => {
                            setChecklistsTareaValue(checklistsTarea.filter((_, i) => i !== selectedChecklistIndex));
                            setSelectedChecklistIndex(0);
                            if (checklistsTarea.length <= 1) {
                                props.onClose();
                            }
                        }}
                    >
                        Borrar lista
                    </Button>

                    <Button
                        color='info'
                        onClick={(ev) => {
                            ev.persist();

                            if (!props.onSave) {
                                return props.onClose(ev);
                            }

                            return props.onSave().then(() => props.onClose(ev));
                        }}
                        disabled={isSubmitting || error}
                    >
                        Aceptar
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

export default withButtonOpener(EditChecklistDialog);

EditChecklistDialog.propTypes = {
    id: PropTypes.any,
    onSave: PropTypes.any,
    onClose: PropTypes.any,
    onDelete: PropTypes.any,
    open: PropTypes.any,
    name: PropTypes.any,
};
