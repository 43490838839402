import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiDrawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFetchData } from '../../hooks/useFetchData';
import CloseIcon from '../icons/Close';
import OpenIcon from '../icons/Open';
import { EditFormDataProvider } from './forms/EditForm/EditFormContext';

const useStyles = makeStyles(
    (theme) => ({
        list: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
        },
        toolbar: {
            padding: theme.spacing(0.5),
            display: 'flex',
            alignItems: 'center',
            paddingRight: theme.spacing(6),
            '& svg': {
                fontSize: 20,
            },
        },
        loading: {
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'JornadaDrawer' },
);

const Drawer = withStyles(
    (theme) => ({
        paper: {
            width: 850,
            backgroundColor: theme.palette.neutral.grey3,
        },
    }),
    { name: 'Drawer' },
)(MuiDrawer);

export default function DetailsDrawer({ open, fetchDataFn, onClose, children, openUrl, extraActions }) {
    const classes = useStyles();
    const history = useHistory();

    const { data, isLoading, fetchData, setData } = useFetchData(fetchDataFn, null);

    useEffect(() => {
        if (!open) {
            setData(null);
            return;
        }

        fetchData();
    }, [open, fetchData]);

    return (
        <Drawer anchor='right' open={open} onClose={onClose}>
            <div className={classes.toolbar}>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <Tooltip arrow title='Abrir en la ficha'>
                    <IconButton onClick={() => history.push(openUrl)}>
                        <OpenIcon />
                    </IconButton>
                </Tooltip>{' '}
                {typeof extraActions === 'function' ? extraActions({ fetchData, tarea: data }) : extraActions}
            </div>
            <div className={clsx(classes.list, isLoading ? classes.loading : null)} role='presentation'>
                {isLoading && (
                    <>
                        <CircularProgress size={40} />
                        <Typography>Cargando datos</Typography>
                    </>
                )}
                {data && (
                    <EditFormDataProvider data={data} fetchData={fetchData}>
                        {children}
                    </EditFormDataProvider>
                )}
            </div>
        </Drawer>
    );
}

DetailsDrawer.propTypes = {
    children: PropTypes.any,
    extraActions: PropTypes.any,
    fetchDataFn: PropTypes.any,
    onClose: PropTypes.any,
    open: PropTypes.any,
    openUrl: PropTypes.any,
};
