import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { fichajesProvider } from '../../../../api';
import { getTiempoTotalMarcajes } from '../../../../api/fichajes';
import { getMinutos } from '../../../../api/tareas-functions';
import { formatFullDateTime, formatTiempo } from '../../../../utils';
import Button from '../../../common/Button';
import { ButtonDialog } from '../../../common/dialogs/ButtonDialog';
import Marcaje from './Marcaje';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(2),
            flex: 1,
        },
        loading: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: `${theme.spacing(2)}px`,
            marginBottom: theme.spacing(3),
        },
        totalHoy: {
            color: 'white',
            fontSize: 20,
            padding: theme.spacing(1.5, 2),
            borderRadius: 24,
            lineHeight: '20px',
            marginBottom: theme.spacing(3),
            alignSelf: 'center',
            '&.success': {
                backgroundColor: theme.palette.success.main,
            },
            '&.warning': {
                backgroundColor: theme.palette.warning.main,
            },
            '&.error': {
                backgroundColor: theme.palette.error.main,
            },
        },
        lista: {
            marginBottom: theme.spacing(2),
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        buttons: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
            margin: theme.spacing(0, 3, 3, 3),
        },
    }),
    { name: 'ListaFichajesHoy' },
);

export default function ListaFichajesHoy({ onShowHistorico }) {
    const classes = useStyles();
    const [marcajes, setMarcajes] = useState(null);
    const [marcajePausa, setMarcajePausa] = useState(null);
    const [minutosHorario, setMinutosHorario] = useState(0);

    const fetchMarcajes = useCallback(
        () =>
            fichajesProvider.getMarcajesHoy().then((res) => {
                setMarcajes(res.marcajes);
                setMinutosHorario(res.horario);
                setMarcajePausa(res.marcaje_pausa);
            }),
        [],
    );

    useEffect(() => {
        fetchMarcajes();
    }, []);

    const tiempoHoy = useMemo(() => (marcajes ? getTiempoTotalMarcajes(marcajes) : 0), [marcajes]);
    const minutosHoy = getMinutos(tiempoHoy);
    const tiempoHoyClass = minutosHoy === 0 ? 'error' : minutosHoy >= minutosHorario ? 'success' : 'warning';

    const puedeEntrar = marcajes && (marcajes.length === 0 || marcajes[marcajes.length - 1].hora_salida !== null);

    const ficharEntrada = () => fichajesProvider.ficharEntrada().then(fetchMarcajes);
    const ficharSalida = () => fichajesProvider.ficharSalida().then(fetchMarcajes);

    const canRegistrarDescanso =
        marcajes &&
        marcajes.length > 0 &&
        marcajes[marcajes.length - 1].hora_salida === null &&
        (!marcajePausa || !marcajePausa.hora_salida);

    return (
        <div className={classnames(classes.root)}>
            {marcajes === null ? (
                <div className={classes.loading}>
                    <CircularProgress />
                    <Typography>Cargando fichajes</Typography>
                </div>
            ) : (
                <>
                    <div className={classnames(classes.totalHoy, tiempoHoyClass)}>{formatTiempo(tiempoHoy)}</div>

                    {marcajes.length > 0 && (
                        <div className={classes.lista}>
                            {marcajes.map((marcaje, i) => (
                                <Marcaje key={i} marcaje={marcaje} marcajePausa={marcajePausa} />
                            ))}
                        </div>
                    )}
                    <div className={classes.buttons}>
                        {puedeEntrar ? (
                            <ButtonDialog
                                button={
                                    <Button color='info' fullWidth size='big'>
                                        Fichar entrada
                                    </Button>
                                }
                                onConfirm={ficharEntrada}
                                dialogTitle='Fichar entrada'
                                dialogText={`¿Quieres fichar la entrada a las ${formatFullDateTime(new Date())}?`}
                                confirmButtonText='Fichar entrada'
                            />
                        ) : (
                            <ButtonDialog
                                button={
                                    <Button color='info' fullWidth size='big'>
                                        Fichar salida
                                    </Button>
                                }
                                onConfirm={ficharSalida}
                                dialogTitle='Fichar salida'
                                dialogText={`¿Quieres fichar la salida a las ${formatFullDateTime(new Date())}?`}
                                confirmButtonText='Fichar salida'
                            />
                        )}
                        {canRegistrarDescanso && (
                            <Button
                                color='outlined'
                                fullWidth
                                onClick={() => {
                                    const fecha = new Date();

                                    const newMarcajePausa =
                                        marcajePausa && marcajePausa.hora_entrada
                                            ? {
                                                  hora_salida: fecha,
                                              }
                                            : {
                                                  hora_entrada: fecha,
                                              };

                                    return fichajesProvider.ficharPausa(newMarcajePausa).then(fetchMarcajes);
                                }}
                                size='big'
                            >
                                Registrar descanso
                            </Button>
                        )}

                        <Button color='outlined' fullWidth onClick={onShowHistorico} size='big'>
                            Ver historial de fichajes
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
}

ListaFichajesHoy.propTypes = {
    className: PropTypes.any,
    style: PropTypes.any,
    onShowHistorico: PropTypes.func,
};
