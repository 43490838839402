import AddIcon from '@material-ui/icons/Add';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { instalacionesProvider } from '../../api';
import { useFetchData } from '../../hooks/useFetchData';
import Button from '../common/Button';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import { ListaServicios } from '../servicios/ListaServicios/ListaServicios';
import useDefaultServiciosColumns from '../servicios/useDefaultServiciosColumns';
import NuevaTareaDialog from '../tareas/NuevaTareaDialog';

export default function TrabajosInstalacion() {
    const history = useHistory();
    const { id } = useParams();
    const { data: instalacion } = useEditFormData();

    const fetchDataFn = useCallback(() => instalacionesProvider.getTareas(id), [id]);
    const { data, fetchData, setData } = useFetchData(fetchDataFn);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const columns = useDefaultServiciosColumns(['cliente', 'direccion', 'operarios-visible']);

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            hideDelete: true,
            batchComponents: (
                <NuevaTareaDialog
                    clienteId={instalacion.cliente_id}
                    direccionId={instalacion.direccion_id}
                    isMantenimiento
                    button={
                        <Button color='info' aria-label='Nuevo servicio' startIcon={<AddIcon />}>
                            Añadir servicio
                        </Button>
                    }
                    onSave={(result) => history.push(`/servicios/${result.id}`)}
                />
            ),
        }),
        [],
    );

    return (
        <ListaServicios
            tableId='trabajosInstalacion'
            tareas={data}
            setTareas={setData}
            columns={columns}
            options={options}
            basePath='/proyectos'
            tableTitle='Lista de servicios'
        />
    );
}
