import DeleteDialog from '../common/dialogs/DeleteDialog';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { formatDate, formatDateTime, formatHora } from '../../utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import EditIcon from '@material-ui/icons/Edit';
import Chip from '@material-ui/core/Chip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EventIcon from '@material-ui/icons/Event';
import OperariosIcon from '../icons/Operarios';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Tooltip from '@material-ui/core/Tooltip';
import VisitaConfirmadaIcon from '@material-ui/icons/EventAvailable';
import Typography from '@material-ui/core/Typography';
import isSameDay from 'date-fns/isSameDay';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: `${theme.spacing(4)}px`,
            padding: theme.spacing(2),
            borderRadius: 8,
            border: `1px solid ${theme.palette.neutral.grey1}`,
        },
        column: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
        },
        row: {
            display: 'flex',
            alignItems: 'flex-start',
            gap: `${theme.spacing(2)}px`,
            '&>.MuiSvgIcon-root': {
                color: theme.palette.neutral.primary,
                fontSize: 20,
                marginTop: 2,
            },
            '& .MuiTypography-subtitle2': {
                fontWeight: 400,
            },
        },
        operariosList: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: `${theme.spacing(1)}px`,
        },
        noVisibleChip: {
            color: theme.palette.error.main,
            '& .MuiSvgIcon-root': {
                color: 'inherit',
            },
        },
        actions: {
            display: 'flex',
            alignItems: 'flex-start',
        },
    }),
    { name: 'PlanificacionFormItemReadOnly' },
);

export default function PlanificacionFormItemReadOnly({ fieldKey, planificacion, disabled, onDelete, deleteDisabled }) {
    const classes = useStyles();

    const [, , { setValue: setEditable }] = useField(`${fieldKey}.editable`);

    let fecha = '';
    if (planificacion.allDay) {
        if (isSameDay(planificacion.fecha_inicio, planificacion.fecha_fin)) {
            fecha = formatDate(planificacion.fecha_inicio);
        } else {
            fecha = `${formatDate(planificacion.fecha_inicio)} a ${formatDate(planificacion.fecha_fin)}`;
        }
    } else {
        fecha = `${formatDate(planificacion.fecha_inicio)} de ${formatHora(planificacion.fecha_inicio)} a ${formatHora(
            planificacion.fecha_fin,
        )}`;
    }
    return (
        <div className={classes.root}>
            <div className={classes.column}>
                <div className={classes.row}>
                    <EventIcon />
                    <Typography variant='subtitle2'>{fecha}</Typography>
                    {planificacion.confirmada && (
                        <Chip size='small' label='Confirmada' icon={<VisitaConfirmadaIcon />} />
                    )}
                </div>
                <div className={classes.row}>
                    <OperariosIcon />
                    {planificacion.operarios.length === 0 ? (
                        <Typography variant='subtitle2'>Sin operarios asignados</Typography>
                    ) : (
                        <div className={classes.operariosList}>
                            {planificacion.operarios.map((op, i) => (
                                <Tooltip
                                    key={i}
                                    arrow
                                    title={
                                        op.visible
                                            ? ''
                                            : op.fecha_salida
                                            ? `El operario salió del sevicio el ${formatDateTime(op.fecha_salida)}`
                                            : 'El operario no ve el servicio'
                                    }
                                >
                                    <Chip
                                        label={op.operario.nombre}
                                        icon={op.visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        size='small'
                                        className={op.visible ? null : classes.noVisibleChip}
                                    />
                                </Tooltip>
                            ))}
                        </div>
                    )}
                </div>
                <div className={classes.row}>
                    <DriveEtaIcon />
                    <Typography variant='subtitle2'>
                        {planificacion.vehiculo ? planificacion.vehiculo.nombre : 'Sin vehiculo asignado'}
                    </Typography>
                </div>
            </div>
            <div className={classes.actions}>
                <IconButton size='small' onClick={() => setEditable(true)}>
                    <EditIcon fontSize='small' />
                </IconButton>
                <DeleteDialog
                    button={
                        <IconButton size='small' disabled={disabled || deleteDisabled}>
                            <DeleteIcon fontSize='small' />
                        </IconButton>
                    }
                    text='¿Estás seguro que deseas borrar esta planificación?'
                    onConfirm={onDelete}
                />
            </div>
        </div>
    );
}

PlanificacionFormItemReadOnly.propTypes = {
    deleteDisabled: PropTypes.any,
    disabled: PropTypes.any,
    fieldKey: PropTypes.any,
    onDelete: PropTypes.any,
    planificacion: PropTypes.any,
};
