import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(
    (theme) => ({
        contentWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        progress: {
            marginLeft: theme.spacing(1),
        },
    }),
    { name: 'DelayedDownloadItem' },
);

const DelayedDownloadComponent = React.forwardRef(function (
    { Component, onClick, children, closeMenu, altDownloadAction, ...props },
    ref,
) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const snackbar = useSnackbar();

    const handleClick = (ev) => {
        ev.stopPropagation();
        setLoading(true);
        onClick()
            .then((res) => {
                const now = new Date();
                function checkStatus() {
                    fetch(res.status_url)
                        .then((res) => res.json())
                        .then((res) => {
                            if (res.state === 'SUCCESS') {
                                window.open(res.result, '_blank');
                                setLoading(false);
                                closeMenu && closeMenu();
                            } else if (res.state === 'FAILURE') {
                                const message = res.status;

                                if (typeof message === 'string') {
                                    snackbar.showMessage(message);
                                } else if (typeof message === 'object' && message._schema) {
                                    const messages = message._schema.map((m, i) => (
                                        <Typography key={i} style={{ whiteSpace: 'nowrap' }}>
                                            {m}
                                        </Typography>
                                    ));
                                    snackbar.showMessage(messages);
                                } else {
                                    snackbar.showMessage('Error desconocido');
                                }
                                setLoading(false);
                                closeMenu && closeMenu();
                            } else {
                                const elapsed = new Date() - now;
                                if (elapsed > 5000) {
                                    altDownloadAction && altDownloadAction();
                                    setLoading(false);
                                    closeMenu && closeMenu();
                                } else {
                                    setTimeout(checkStatus, 1000);
                                }
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                            snackbar.showMessage(err.body.message);
                            setLoading(false);
                            closeMenu && closeMenu();
                        });
                }
                setTimeout(checkStatus, 1000);
            })
            .catch((err) => {
                setLoading(false);
                closeMenu && closeMenu();
                console.error(err);
                snackbar.showMessage(err.body.message);
            });
    };

    return (
        <Component onClick={handleClick} disabled={loading} {...props}>
            <span className={classes.contentWrapper}>
                {children}
                {loading && <CircularProgress size={16} className={classes.progress} />}
            </span>
        </Component>
    );
});

DelayedDownloadComponent.propTypes = {
    Component: PropTypes.any,
    altDownloadAction: PropTypes.any,
    children: PropTypes.any,
    closeMenu: PropTypes.any,
    onClick: PropTypes.any,
};

DelayedDownloadComponent.displayName = 'DelayedDownloadComponent';

export default DelayedDownloadComponent;
