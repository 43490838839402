import Grid from '@material-ui/core/Grid';
import { Form } from 'formik';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { presupuestosProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import FileUploader from '../common/fields/FileUploader';
import DialogForm from '../common/forms/DialogForm';

const UploadSchema = Yup.object().shape({
    file: Yup.mixed().required('Requerido'),
});

function UploadFileDialog({ presupuestoId, onSave, ...props }) {
    const snackbar = useSnackbar();

    return (
        <DialogForm
            title='Subir fichero de presupuesto'
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    file: null,
                },
                validationSchema: UploadSchema,
                onSubmit: ({ file, ...values }, { setSubmitting, setFieldError }) => {
                    const formData = new FormData();
                    formData.append('params', JSON.stringify(values));
                    formData.append('file', file);

                    presupuestosProvider
                        .getAll(`${presupuestoId}/importar`, {
                            method: 'post',
                            body: formData,
                        })
                        .then((result) => {
                            props.onClose();
                            onSave(result);
                            snackbar.showMessage('Se ha importado el presupuesto con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                if (typeof err.message === 'string') {
                                    snackbar.showMessage(err.message);
                                } else {
                                    for (const [field, errors] of Object.entries(err.message)) {
                                        setFieldError(field, errors.join('\n'));
                                    }
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ isSubmitting, values, errors, setFieldValue }) => {
                return (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FileUploader
                                    id='upload-presupuesto-file'
                                    label='Fichero'
                                    file={values.file}
                                    setFile={(file) => setFieldValue('file', file)}
                                    multiple={false}
                                    isImage={false}
                                    accept='.bc3,.xls,.xlsx,.xlsm'
                                />
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </DialogForm>
    );
}

export default withButtonOpener(UploadFileDialog);

UploadFileDialog.propTypes = {
    onSave: PropTypes.any,
    onClose: PropTypes.any,
    presupuestoId: PropTypes.any,
};
