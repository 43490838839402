import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useEffect, useState } from 'react';
import { DialogContent } from '@material-ui/core';
import clsx from 'clsx';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import DialogEditablePaper from './DialogEditablePaper';
import { tareasProvider } from '../../../api';
import DialogObservacionesPaper from './DialogObservacionesPaper';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../../common/Button';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 820,
            maxWidth: 820,
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            lineHeight: '50px',
            paddingLeft: theme.spacing(2),
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        body: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(3)}px`,
            padding: theme.spacing(2),
        },
    }),
    { name: 'NotasDialog' },
);

function NotasDialog({ id, open, onClose, className, onUpdateNotasInternas, onUpdateObservaciones }) {
    const classes = useStyles();

    const [notasInternas, setNotasInternas] = useState(null);

    useEffect(() => {
        if (!open) return;

        tareasProvider.getNotasInternas(id).then((res) => setNotasInternas(res.notas_internas));
    }, [id, open]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            id='mouse-over-popover'
            classes={{
                paper: clsx(classes.root, className),
            }}
        >
            <DialogTitle id='alert-dialog-title'>Notas</DialogTitle>
            <DialogContent className={classes.body}>
                <DialogEditablePaper
                    title='Notas internas'
                    value={notasInternas}
                    onSave={(value) => {
                        tareasProvider.saveNotasInternas(id, value).then(() => {
                            setNotasInternas(value);
                            onUpdateNotasInternas(value);
                        });
                    }}
                    placeholder='Las notas que escribas aqui solo las verá administración'
                />
                <DialogObservacionesPaper id={id} onUpdate={onUpdateObservaciones} />
            </DialogContent>

            <DialogActions>
                <Button color='outlined' onClick={onClose}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withButtonOpener(NotasDialog);

NotasDialog.propTypes = {
    className: PropTypes.any,
    id: PropTypes.any,
    onClose: PropTypes.any,
    onUpdateNotasInternas: PropTypes.any,
    onUpdateObservaciones: PropTypes.any,
    open: PropTypes.any,
};
