import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { subtareasProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import DialogForm from '../../common/forms/DialogForm';

const SubtareaSchema = Yup.object().shape({
    codigo: Yup.string().max(40, 'La referencia no puede tener más de 40 caracteres').required('Requerido'),
    titulo: Yup.string().required('Requerido'),
});

function EditSubtareaDialog({ subtarea, onSave, ...props }) {
    const snackbar = useSnackbar();

    return (
        <DialogForm
            title='Editar subtarea'
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    codigo: subtarea.codigo,
                    titulo: subtarea.titulo,
                    categoria: subtarea.categoria,
                },
                validationSchema: SubtareaSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    subtareasProvider
                        .save(values, subtarea.id)
                        .then(() => {
                            onSave && onSave();
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                if (err.message instanceof Object) {
                                    for (const [field, errors] of Object.entries(err.message)) {
                                        setFieldError(field, errors.join('\n'));
                                    }
                                } else {
                                    snackbar.showMessage(err.message);
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField name='codigo' label='Codigo' fullWidth />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField name='categoria' label='Categoría' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name='titulo' label='Titulo' fullWidth />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(EditSubtareaDialog);

EditSubtareaDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    subtarea: PropTypes.any,
};
