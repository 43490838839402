import PropTypes from 'prop-types';
import useAuthState, { usePermisos } from '../../AuthState';
import { ListItem } from './AppBarMenu';
import ButtonMenu from './ButtonMenu';

export default function ClientesMenu({ disabled }) {
    const {
        userInfo: { preferencias: { usar_presupuestos: usarPresupuestos } = {} },
    } = useAuthState();

    const gestionarClientes = usePermisos('gestionar_clientes');

    const items = [];

    if (gestionarClientes) {
        items.push({
            path: '/clientes',
            label: 'Clientes',
        });
    }
    items.push({
        path: '/proyectos',
        label: 'Proyectos',
    });

    if (usarPresupuestos) {
        items.push({
            path: '/presupuestos',
            label: 'Presupuestos',
        });
    }

    return <ButtonMenu button={<ListItem label='Clientes' disabled={disabled} />} items={items} />;
}

ClientesMenu.propTypes = {
    disabled: PropTypes.any,
};
