import PropTypes from 'prop-types';
import { CustomFilter } from '../common/CustomFilter';

export function DiaSemanaFilter({ onFilterChange }) {
    const options = [
        {
            label: 'Todos',
            onClick: () => onFilterChange(null),
        },
        {
            label: 'Lunes',
            onClick: () => {
                onFilterChange(0);
            },
        },
        {
            label: 'Martes',
            onClick: () => {
                onFilterChange(1);
            },
        },
        {
            label: 'Miércoles',
            onClick: () => {
                onFilterChange(2);
            },
        },
        {
            label: 'Jueves',
            onClick: () => {
                onFilterChange(3);
            },
        },
        {
            label: 'Viernes',
            onClick: () => {
                onFilterChange(4);
            },
        },
        {
            label: 'Sábado',
            onClick: () => {
                onFilterChange(5);
            },
        },
        {
            label: 'Domingo',
            onClick: () => {
                onFilterChange(6);
            },
        },
    ];

    return <CustomFilter label='Dia' options={options} />;
}

DiaSemanaFilter.propTypes = {
    onFilterChange: PropTypes.any,
};
