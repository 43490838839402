import { eventIcons } from './constants';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import clsx from 'clsx';

const useStyles = makeStyles(
    (theme) => ({
        event: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                fontSize: 15,
                marginRight: 2,
            },
        },
        title: {
            fontWeight: 500,
            lineHeight: '16px',
        },
        subtitle: {
            lineHeight: '16px',
            display: 'flex',
            alignItems: 'center',
            marginLeft: 17,
            '& svg': {
                fontSize: 15,
                marginRight: 2,
            },
            '&.no-visible': {
                marginLeft: 0,
            },
        },
    }),
    { name: 'EventDefault' },
);

export function EventDefault({ event }) {
    const classes = useStyles();
    const Icon = eventIcons[event.icon];

    const isNoVisible = event.type === 'agenda' && event.visible === false;

    return (
        <div>
            <div className={classes.event}>
                <Icon />
                <div className={classes.title}>{event.cliente}</div>
            </div>
            <div className={clsx(classes.subtitle, isNoVisible ? 'no-visible' : null)}>
                {isNoVisible ? <VisibilityOffIcon /> : null}
                {event.descripcion}
            </div>
        </div>
    );
}

EventDefault.propTypes = {
    event: PropTypes.any,
};
