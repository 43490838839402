import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { instalacionesProvider } from '../../api';
import { useSnackbar } from 'material-ui-snackbar-provider';
import FileUploader from '../common/fields/FileUploader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { FormControl } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { ButtonDialog } from '../common/dialogs/ButtonDialog';
import DialogForm from '../common/forms/DialogForm';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import PropTypes from 'prop-types';
import ClienteField from '../common/fields/ClienteField';
import DireccionField from '../common/fields/DireccionField';

const Instalacioneschema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
    cliente: Yup.mixed().required('Selecciona un cliente'),
    direccion: Yup.mixed().required('Selecciona una direccion'),
});

function EditInstalacionDialog({ onSave, id, instalacion: originalInstalacion, ...props }) {
    const snackbar = useSnackbar();

    const [instalacion, setInstalacion] = useState(originalInstalacion);
    useEffect(() => setInstalacion(originalInstalacion), [originalInstalacion]);

    return (
        <DialogForm
            title='Editar instalación'
            FormikProps={{
                initialValues: {
                    nombre: instalacion.nombre,
                    cliente: { id: instalacion.cliente_id },
                    direccion: { id: instalacion.direccion_id },
                    files: [],
                },
                validationSchema: Instalacioneschema,
                onSubmit: ({ cliente, direccion, files, ...values }, { setSubmitting, setFieldError }) => {
                    const formData = new FormData();
                    Object.entries(values).forEach(([key, value]) => formData.append(key, value));
                    formData.append('cliente_id', cliente.id);
                    formData.append('direccion_id', direccion.id);
                    new Array(...files).forEach((file) => formData.append('files', file));

                    instalacionesProvider
                        .save(formData, id)
                        .then((result) => {
                            onSave(result);
                            props.onClose();
                            snackbar.showMessage('Se ha actualizado la instalación con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ touched, values, errors, setFieldValue }) => {
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ClienteField
                                error={touched.cliente && !!errors.cliente}
                                helperText={touched.cliente && errors.cliente}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DireccionField
                                error={touched.direccion && !!errors.direccion}
                                helperText={touched.direccion && errors.direccion}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name='nombre' label='Nombre' fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel shrink>Ficheros adjuntos</InputLabel>
                                <List dense style={{ marginTop: 16 }}>
                                    {instalacion.adjuntos.length === 0 ? (
                                        <ListItem>
                                            <ListItemText>No hay ficheros adjuntos</ListItemText>
                                        </ListItem>
                                    ) : (
                                        instalacion.adjuntos.map((adjunto) => {
                                            const isImage = adjunto.content_type.startsWith('image');

                                            return (
                                                <ListItem
                                                    key={adjunto.id}
                                                    dense
                                                    button
                                                    onClick={() =>
                                                        window.open(adjunto.image_url || adjunto.original_url, '_blank')
                                                    }
                                                >
                                                    <ListItemAvatar>
                                                        {isImage ? (
                                                            <Avatar variant='square' src={adjunto.thumbnail_url} />
                                                        ) : (
                                                            <Avatar variant='square'>
                                                                <PictureAsPdfIcon />
                                                            </Avatar>
                                                        )}
                                                    </ListItemAvatar>
                                                    <ListItemText primary={adjunto.original_url.split('/').pop()} />
                                                    <ListItemSecondaryAction>
                                                        <ButtonDialog
                                                            button={
                                                                <IconButton edge='end' aria-label='delete'>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            }
                                                            dialogTitle='¿Borrar adjunto?'
                                                            dialogText='Esta opcion no se puede deshacer. ¿Deseas continuar?'
                                                            onConfirm={() => {
                                                                instalacionesProvider
                                                                    .action(
                                                                        `${instalacion.id}/adjuntos/${adjunto.id}`,
                                                                        {
                                                                            method: 'delete',
                                                                        },
                                                                    )
                                                                    .then(() => {
                                                                        setInstalacion((instalacion) => ({
                                                                            ...instalacion,
                                                                            adjuntos: instalacion.adjuntos.filter(
                                                                                (adj) => adj.id !== adjunto.id,
                                                                            ),
                                                                        }));
                                                                    });
                                                            }}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            );
                                        })
                                    )}
                                </List>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FileUploader
                                id='upload-instalacion-adjuntos-file'
                                label='Añadir adjuntos'
                                file={values.files}
                                setFile={(files) => setFieldValue('files', files)}
                                multiple={true}
                                isImage={false}
                                accept='image/jpeg,image/gif,image/png,application/pdf'
                            />
                        </Grid>
                    </Grid>
                );
            }}
        </DialogForm>
    );
}

export default withButtonOpener(EditInstalacionDialog);

EditInstalacionDialog.propTypes = {
    clienteId: PropTypes.any,
    id: PropTypes.any,
    instalacion: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
