import makeStyles from '@material-ui/core/styles/makeStyles';
import BusinessIcon from '@material-ui/icons/Business';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import addDays from 'date-fns/addDays';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import esES from 'date-fns/locale/es';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import subDays from 'date-fns/subDays';
import { useEffect, useState } from 'react';
import { calendariosProvider } from '../../api';
import { BACKGROUND_COLOR } from '../../App';
import { formatDate, formatISODate } from '../../utils';
import {
    COLOR_SOURCE_TAREA,
    GROUP_BY_OPERARIOS,
    GROUP_BY_SERVICIOS,
    GROUP_BY_VEHICULOS,
} from '../agenda/useTransformEvents';
import OperariosIcon from '../icons/Operarios';
import CalendarioContent from './CalendarioContent';
import Toolbar, { navigate } from './Toolbar';

const useStyles = makeStyles(
    (theme) => ({
        fixedHeader: {
            background: BACKGROUND_COLOR,
            position: 'fixed',
            top: 0,
            left: theme.spacing(3),
            right: theme.spacing(3),
            paddingTop: theme.spacing(11),
            paddingBottom: theme.spacing(1),
            zIndex: 1,
        },
    }),
    { name: 'CalendarioPlanificacion' },
);

export const WEEK_VIEW = 'week';
export const MONTH_VIEW = 'month';

function getWeekRange(date) {
    const start = startOfWeek(date, { locale: esES });
    const end = endOfWeek(date, { locale: esES });

    const days = [];
    for (let d = 0; d < 7; d++) {
        days.push(addDays(start, d));
    }

    return { start, end, days };
}

function getMonthRange(date) {
    const start = startOfMonth(date, { locale: esES });
    const end = endOfMonth(date, { locale: esES });
    const numberOfDaysOfMonth = new Date(start.getFullYear(), start.getMonth() + 1, 0).getDate();

    const days = [];
    for (let d = 0; d < numberOfDaysOfMonth; d++) {
        days.push(addDays(start, d));
    }

    return { start, end, days };
}

const views = [
    {
        id: GROUP_BY_OPERARIOS,
        label: <OperariosIcon />,
        tooltip: 'Agrupar por operarios',
    },
    {
        id: GROUP_BY_SERVICIOS,
        label: <BusinessIcon />,
        tooltip: 'Agrupar por cliente',
    },
    {
        id: GROUP_BY_VEHICULOS,
        label: <DriveEtaIcon />,
        tooltip: 'Agrupar por vehículos',
    },
];

const CALENDARIO_PLANIFICACION_CONFIG_KEY = 'labory-calendario-planificacion';

export default function CalendarioPlanificacion() {
    const classes = useStyles();

    const [dateView, setDateView] = useState(WEEK_VIEW);
    const [dateRange, setDateRange] = useState(
        dateView === WEEK_VIEW ? getWeekRange(new Date()) : getMonthRange(new Date()),
    );
    const [noLaborables, setNoLaborables] = useState([]);
    const [hidePastPlanificaciones, setHidePastPlanificaciones] = useState(false);
    const [currentView, setCurrentView] = useState(GROUP_BY_OPERARIOS);
    const [currentColorSource, setCurrentColorSource] = useState(COLOR_SOURCE_TAREA);
    const [showOnlyColumn, setShowOnlyColumn] = useState(null);
    const [showOnlyOperarioActivos, setShowOnlyOperarioActivos] = useState(true);
    const [showPendientesPlanificar, setShowPendientesPlanificar] = useState(false);

    const dateRangeStartYear = dateRange?.start.getFullYear();
    const dateRangeEndYear = dateRange?.end.getFullYear();

    useEffect(() => {
        let config = localStorage.getItem(CALENDARIO_PLANIFICACION_CONFIG_KEY);
        if (config) {
            config = JSON.parse(config);

            setDateView(config.dateView);
            setCurrentView(config.currentView);
            setCurrentColorSource(config.currentColorSource);
        }
    }, []);

    function saveCurrentConfig() {
        localStorage.setItem(
            CALENDARIO_PLANIFICACION_CONFIG_KEY,
            JSON.stringify({
                dateView,
                currentView,
                currentColorSource,
            }),
        );
    }

    useEffect(() => {
        saveCurrentConfig();
    }, [dateView, currentView, currentColorSource]);

    useEffect(() => {
        setDateRange((dateRange) => {
            if (dateView === WEEK_VIEW) {
                return getWeekRange(dateRange.start);
            } else if (dateView === MONTH_VIEW) {
                return getMonthRange(dateRange.start);
            }
        });
    }, [dateView]);

    useEffect(() => {
        if (dateRangeStartYear === null) return;

        const years = [dateRangeStartYear];
        if (dateRangeEndYear !== dateRangeStartYear) years.push(dateRangeEndYear);

        const promises = years.map((year) => calendariosProvider.getNoLaborables(year));

        Promise.all(promises).then((dates) => {
            setNoLaborables(dates.reduce((accumDates, dates) => accumDates.concat(dates), []).map(formatISODate));
        });
    }, [dateRangeStartYear, dateRangeEndYear]);

    function onNavigate(mode) {
        let newStart = null;
        if (mode === navigate.NEXT) {
            newStart = addDays(dateRange.start, dateRange.days.length);
        } else if (mode === navigate.PREVIOUS) {
            newStart = subDays(dateRange.start, dateRange.days.length);
        } else if (mode === navigate.TODAY) {
            newStart = new Date();
        }

        if (newStart) setDateRange((dateView === WEEK_VIEW ? getWeekRange : getMonthRange)(newStart));
    }

    function refresh() {
        // ugly hack
        setDateRange((range) => ({ ...range, start: new Date(range.start), end: new Date(range.end) }));
    }

    return (
        <>
            <div className={classes.fixedHeader}>
                <Toolbar
                    onNavigate={onNavigate}
                    onCreate={refresh}
                    label={`${formatDate(dateRange.start)} - ${formatDate(dateRange.end)}`}
                    hidePast={hidePastPlanificaciones}
                    onHidePastChanged={setHidePastPlanificaciones}
                    views={views}
                    onView={setCurrentView}
                    view={currentView}
                    showPendientesPlanificar={showPendientesPlanificar}
                    onShowPendientesPlanificar={() => setShowPendientesPlanificar((show) => !show)}
                    colorSource={currentColorSource}
                    onColorSource={setCurrentColorSource}
                    dateView={dateView}
                    onDateView={setDateView}
                />
                {/* <CalendarioHeader */}
                {/*    showOnlyColumn={showOnlyColumn} */}
                {/*    days={dateRange.days} */}
                {/*    noLaborables={noLaborables} */}
                {/*    onFilter={(key, value) => { */}
                {/*        if (key === 'dia') { */}
                {/*            setShowOnlyColumn(value); */}
                {/*        } else if (key === 'soloOperariosActivos') { */}
                {/*            setShowOnlyOperarioActivos(value); */}
                {/*        } */}
                {/*    }} */}
                {/* /> */}
            </div>
            <CalendarioContent
                showOnlyOperarioActivos={showOnlyOperarioActivos}
                showOnlyColumn={showOnlyColumn}
                view={currentView}
                start={dateRange.start}
                end={dateRange.end}
                days={dateRange.days}
                hidePastPlanificaciones={hidePastPlanificaciones}
                onNavigate={onNavigate}
                showPendientesPlanificar={showPendientesPlanificar}
                onClosePendientesPlanificar={() => setShowPendientesPlanificar(false)}
                colorSource={currentColorSource}
                noLaborables={noLaborables}
                onFilter={(key, value) => {
                    if (key === 'dia') {
                        setShowOnlyColumn(value);
                    } else if (key === 'soloOperariosActivos') {
                        setShowOnlyOperarioActivos(value);
                    }
                }}
            />
        </>
    );
}
