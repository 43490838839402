import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';

function AboutDialog({ open, onClose }) {
    return (
        <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
            <DialogTitle>Acerca de Labory</DialogTitle>
            <DialogContent>Version v{process.env.REACT_APP_VERSION}</DialogContent>
        </Dialog>
    );
}

export default AboutDialog;

AboutDialog.propTypes = {
    onClose: PropTypes.any,
    open: PropTypes.any,
};
