import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import MuiStepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import MuiStepper from '@material-ui/core/Stepper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Check from '@material-ui/icons/Check';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const StepConnector = withStyles((theme) => ({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: theme.palette.primary.main,
        },
    },
    completed: {
        '& $line': {
            borderColor: theme.palette.primary.main,
        },
    },
    line: {
        borderColor: '#E3E6ED',
        borderTopWidth: 4,
        borderRadius: 0,
    },
}))(MuiStepConnector);

const useStepIconStyles = makeStyles(
    (theme) => ({
        root: {
            color: '#E3E6ED',
            display: 'flex',
            height: 22,
            alignItems: 'center',
        },
        active: {
            color: theme.palette.primary.main,
        },
        error: {
            color: theme.palette.error.main,
        },
        circle: {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
        completed: {
            color: theme.palette.primary.main,
            zIndex: 1,
            fontSize: 18,
        },
    }),
    { name: 'StepIcon' },
);

function StepIcon(props) {
    const classes = useStepIconStyles();
    const { active, completed, error } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.error]: error,
            })}
        >
            {completed && !error ? <Check className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}

StepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    error: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
};

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 650,
            margin: '0 auto',
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
    { name: 'Stepper' },
);

export default function Stepper({ steps, activeStep, setActiveStep }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <MuiStepper nonLinear alternativeLabel activeStep={activeStep} connector={<StepConnector />}>
                {steps.map(({ label, error }, i) => (
                    <Step key={i} completed={i < activeStep}>
                        <StepButton onClick={() => setActiveStep(i)}>
                            <StepLabel StepIconComponent={StepIcon} error={error}>
                                {label}
                            </StepLabel>
                        </StepButton>
                    </Step>
                ))}
            </MuiStepper>
        </div>
    );
}

Stepper.propTypes = {
    activeStep: PropTypes.any,
    setActiveStep: PropTypes.any,
    steps: PropTypes.any,
};
