import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { presupuestosProvider } from '../../api';
import { useFetchData } from '../../hooks/useFetchData';
import EditForm from '../common/forms/EditForm/EditForm';
import { EditPresupuestoFormContent } from './EditPresupuestoFormContent';

export default function EditPresupuestoForm() {
    const { id } = useParams();

    const fetchDataFn = useCallback(() => presupuestosProvider.getAll(id), [id]);
    const { data, fetchData } = useFetchData(fetchDataFn, null);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        data && (
            <>
                <EditForm data={data} fetchData={fetchData}>
                    <EditPresupuestoFormContent />
                </EditForm>
            </>
        )
    );
}
