import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

const SelectField = ({
    field,
    id,
    selectOptions,
    blankItem,
    form,
    label,
    className,
    classes,
    placeholder,
    fullWidth,
    disabled,
    multiple = false,
}) => {
    const currentError = field.name.split('.').reduce((obj, prop) => obj && obj[prop], form.errors);
    const currentValue = field.value || '';

    return (
        <FormControl
            error={Boolean(currentError)}
            className={className}
            disabled={form.isSubmitting}
            fullWidth={fullWidth}
        >
            <InputLabel shrink htmlFor={id}>
                {label}
            </InputLabel>
            <Select
                id={id}
                value={currentValue}
                onChange={(event) => {
                    form.setFieldValue(field.name, event.target.value, true);
                }}
                input={<Input name={field.name} id={id} />}
                displayEmpty
                name={field.name}
                className={classes.selectEmpty}
                disabled={disabled}
                MenuProps={{
                    disablePortal: true,
                }}
                multiple={multiple}
            >
                {blankItem && <MenuItem value=''>{blankItem}</MenuItem>}
                {placeholder && (
                    <MenuItem value='' disabled>
                        {placeholder}
                    </MenuItem>
                )}

                {selectOptions.map((opt, i) => (
                    <MenuItem key={i} value={opt.value}>
                        {opt.label}
                    </MenuItem>
                ))}
            </Select>
            {currentError && <FormHelperText error={true}>{currentError}</FormHelperText>}
        </FormControl>
    );
};

export default withStyles(styles)(SelectField);

SelectField.propTypes = {
    blankItem: PropTypes.any,
    className: PropTypes.any,
    classes: PropTypes.any,
    disabled: PropTypes.any,
    field: PropTypes.any,
    form: PropTypes.any,
    fullWidth: PropTypes.any,
    id: PropTypes.any,
    label: PropTypes.any,
    multiple: PropTypes.any,
    placeholder: PropTypes.any,
    selectOptions: PropTypes.any,
};
