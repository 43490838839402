import isPast from 'date-fns/isPast';
import isSameDay from 'date-fns/isSameDay';
import { useMemo } from 'react';
import { uuid } from 'uuidv4';
import { tareasProvider } from '../../api';
import { estadosFichajeLabels, getTiempoFichaje } from '../../api/fichajes';
import { createTiempo } from '../../api/tareas-functions';
import {
    formatDate,
    formatDateTime,
    formatHora,
    formatTiempo,
    isAllDay,
    setBeginOfDay,
    setEndOfDay,
} from '../../utils';
import { TareaActions } from '../clientes/TareaActions';
import { defaultColor } from './constants';

export const COLOR_SOURCE_TAREA = 'color-source-tarea';
export const COLOR_SOURCE_CATEGORIA = 'color-source-categoria';

export const GROUP_BY_OPERARIOS = 'operarios';
export const GROUP_BY_VEHICULOS = 'vehiculos';
export const GROUP_BY_SERVICIOS = 'servicios';
export const groupByToKey = {
    [GROUP_BY_OPERARIOS]: 'operarioId',
    [GROUP_BY_VEHICULOS]: 'vehiculoId',
    [GROUP_BY_SERVICIOS]: 'clienteId',
};

export function useTransformEvents(
    colors,
    setAgendaEvents,
    setSelectedEvent,
    hidePastPlanificaciones = true,
    groupBy = GROUP_BY_OPERARIOS,
    colorSource = COLOR_SOURCE_TAREA,
) {
    return useMemo(() => {
        function updateTarea(tareaId, newTarea, transformFn) {
            setAgendaEvents((tareas) => {
                const oldTareas = tareas.filter((tarea) => tarea.id !== tareaId);

                return [...oldTareas, ...transformFn(newTarea)];
            });
        }

        function transformTarea(tarea) {
            if (!tarea) return [];

            let title = `${tarea.cliente} - ${tarea.descripcion}`;

            if (tarea.direccion_helper) title = `${title} (${tarea.direccion_helper})`;

            const newTarea = {
                id: tarea.id,
                title,
                clienteId: tarea.cliente,
                type: 'agenda',
                icon: 'agenda',
                cliente: groupBy === GROUP_BY_SERVICIOS ? null : tarea.cliente,
                descripcion: tarea.descripcion,
                direccion: tarea.direccion_helper,
                sin_leer: tarea.sin_leer,
                original: tarea,
                estado: tarea.estado,
                has_notas_internas: tarea.has_notas_internas,
                estado_preparacion: tarea.estado_preparacion,
                urgente: tarea.urgente,
                actions: (
                    <TareaActions
                        tarea={tarea}
                        onTareaChanged={(id) =>
                            tareasProvider.getByIdForCalendar(id).then((tarea) => {
                                setSelectedEvent(null);
                                updateTarea(tarea.id, tarea, transformTarea);
                            })
                        }
                        updateTarea={(...args) => updateTarea(...args, transformTarea)}
                    />
                ),
            };

            const items = [];

            tarea.planificaciones.forEach((planificacion) => {
                const fechaInicio = new Date(planificacion.fecha_inicio);
                const fechaFin = new Date(planificacion.fecha_fin);
                const allDay = isAllDay(fechaInicio, fechaFin);

                const planificacionIsPast = isPast(fechaFin);

                const planificacionTarea = {
                    ...newTarea,
                    uniqueId: uuid(),
                    start: fechaInicio,
                    end: fechaFin,
                    allDay,
                    planificacionId: planificacion.id,
                    vehiculo: planificacion.vehiculo,
                    vehiculoId: planificacion.vehiculo_id,
                    confirmada: planificacion.confirmada,
                    extender_automaticamente: planificacion.extender_automaticamente,
                    visible: true,
                    groupBy,
                };

                if (colorSource === COLOR_SOURCE_TAREA && tarea.color_bg && tarea.color_fg) {
                    planificacionTarea.customColor = {
                        background: tarea.color_bg,
                        text: tarea.color_fg,
                    };
                } else if (
                    colorSource === COLOR_SOURCE_CATEGORIA &&
                    tarea.categoria &&
                    tarea.categoria.color_bg &&
                    tarea.categoria.color_fg
                ) {
                    planificacionTarea.customColor = {
                        background: tarea.categoria.color_bg,
                        text: tarea.categoria.color_fg,
                    };
                }

                if (
                    planificacion.operarios.length === 0 ||
                    groupBy === GROUP_BY_VEHICULOS ||
                    groupBy === GROUP_BY_SERVICIOS
                ) {
                    const item = {
                        ...planificacionTarea,
                        color: defaultColor,
                    };

                    if (groupBy === GROUP_BY_OPERARIOS) item.operarioId = null;
                    else if (groupBy === GROUP_BY_VEHICULOS || groupBy === GROUP_BY_SERVICIOS)
                        item.operarios = planificacion.operarios.map((op) => ({
                            ...op.operario,
                            operarioId: op.operario.id,
                            operario: op.operario.nombre,
                            visible: op.visible,
                            es_responsable: op.es_responsable,
                            fechaSalida: op.fecha_salida ? new Date(op.fecha_salida) : null,
                        }));

                    items.push(item);
                } else if (groupBy === GROUP_BY_OPERARIOS) {
                    planificacion.operarios.forEach((op) => {
                        if (!op.visible && planificacionIsPast && hidePastPlanificaciones) return;

                        items.push({
                            ...planificacionTarea,
                            uniqueId: uuid(),
                            operarioId: op.operario.id,
                            operario: op.operario.nombre,
                            color: colors[op.operario.id],
                            visible: op.visible,
                            es_responsable: op.es_responsable,
                            fechaSalida: op.fecha_salida ? new Date(op.fecha_salida) : null,
                        });
                    });
                }
            });

            tarea.jornadas?.forEach((jornada) => {
                const start = setBeginOfDay(new Date(jornada.fecha));
                const end = setEndOfDay(new Date(jornada.fecha));

                if (jornada.operario_id === null) return;

                items.push({
                    ...newTarea,
                    type: 'jornada',
                    icon: 'jornada',
                    operarioId: jornada.operario_id,
                    jornadaId: jornada.id,
                    tiempo: jornada.tiempo,
                    color: colors[jornada.operario_id],
                    allDay: true,
                    start,
                    end,
                });
            });

            return items;
        }

        const solicitudParams = {
            VACACIONES: {
                label: 'Vacaciones',
                type: 'solicitud',
                icon: 'vacaciones',
            },
            AUSENCIA: {
                label: 'Ausencia',
                type: 'solicitud',
                icon: 'ausencia',
            },
        };

        function transformSolicitud(solicitud) {
            const { label, ...params } = solicitudParams[solicitud.tipo];
            const title = `${label} - ${solicitud.motivo}`;

            const fechaInicio = new Date(solicitud.fecha_inicio);
            const fechaFin = new Date(solicitud.fecha_fin);
            const formatFn = isAllDay(fechaInicio, fechaFin) ? formatDate : formatDateTime;
            const allDay = isAllDay(fechaInicio, fechaFin);
            const sameDay = isSameDay(fechaInicio, fechaFin);

            let descripcion = 'Todo el día';
            if (sameDay) {
                if (!allDay) descripcion = `${formatHora(fechaInicio)} - ${formatHora(fechaFin)}`;
            } else {
                descripcion = `${formatFn(fechaInicio)} - ${formatFn(fechaFin)}`;
            }

            return [
                {
                    id: solicitud.id,
                    title,
                    ...params,
                    cliente: solicitud.motivo,
                    descripcion,
                    tooltip: title,
                    start: fechaInicio,
                    end: new Date(solicitud.fecha_fin),
                    allDay: isAllDay(solicitud.fecha_inicio, solicitud.fecha_fin),
                    operarioId: solicitud.operario_id,
                    color: colors[solicitud.operario_id],
                    customColor: {
                        background: solicitud.tipo === 'VACACIONES' ? '#2196F3' : '#064DB7',
                        text: 'white',
                    },
                },
            ];
        }

        function transformFichaje(fichaje) {
            const tiempo = createTiempo(getTiempoFichaje(fichaje));
            const entrada = fichaje.marcajes[0].hora_entrada;
            const salida = fichaje.marcajes[fichaje.marcajes.length - 1].hora_salida;
            const title = `De ${formatHora(entrada)} a ${salida ? formatHora(salida) : 'N/D'} (${formatTiempo(
                tiempo,
            )}) | ${estadosFichajeLabels[fichaje.estado]}`;

            return [
                {
                    id: fichaje.id,
                    title,
                    type: 'fichaje',
                    icon: 'fichaje',
                    tooltip: title,
                    start: setBeginOfDay(new Date(fichaje.fecha)),
                    end: setEndOfDay(new Date(fichaje.fecha)),
                    entrada,
                    salida,
                    tiempo,
                    estado: fichaje.estado,
                    allDay: true,
                    operarioId: fichaje.operario_id,
                    color: colors[fichaje.operario_id],
                },
            ];
        }

        return {
            transformTarea,
            transformSolicitud,
            transformFichaje,
            updateTarea: (...args) => updateTarea(...args, transformTarea),
        };
    }, [colors, setAgendaEvents, setSelectedEvent, hidePastPlanificaciones, groupBy, colorSource]);
}
