import { useEffect, useMemo, useState } from 'react';
import useAuthState from '../../../AuthState';
import { tareasProvider } from '../../../api';

export default function usePreciosGastos(tareaId, open = true) {
    const [preciosGastos, setPreciosGastos] = useState(null);

    const {
        userInfo: {
            preferencias: { usar_precios: usarPrecios, ...preferencias },
        },
    } = useAuthState();

    useEffect(() => {
        if (!open || !usarPrecios || !tareaId) return;

        tareasProvider.getPreciosGastos(tareaId).then(setPreciosGastos);
    }, [open, tareaId, usarPrecios]);

    return useMemo(
        () => ({
            precio_coste_parking: Number(preciosGastos?.precio_coste_parking ?? preferencias.precio_coste_parking),
            precio_venta_parking: Number(preciosGastos?.precio_venta_parking ?? preferencias.precio_venta_parking),
            precio_coste_desplazamiento: Number(
                preciosGastos?.precio_coste_desplazamiento ?? preferencias.precio_coste_desplazamiento,
            ),
            precio_venta_desplazamiento: Number(
                preciosGastos?.precio_venta_desplazamiento ?? preferencias.precio_venta_desplazamiento,
            ),
            precio_coste_dietas: Number(preciosGastos?.precio_coste_dietas ?? preferencias.precio_coste_dietas),
            precio_venta_dietas: Number(preciosGastos?.precio_venta_dietas ?? preferencias.precio_venta_dietas),
        }),
        [preciosGastos, preferencias],
    );
}
