import { Formik } from 'formik';
import { useSnackbar } from 'material-ui-snackbar-provider';
import * as Yup from 'yup';
import { companiesProvider } from '../../../api';
import API_ROOT from '../../../api/api-config';
import useAuthState from '../../../AuthState';
import AuthService from '../../auth/AuthService';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import SuscripcionFormContent from './SuscripcionFormContent';

const SuscripcionSchema = Yup.object().shape({
    numero_licencias: Yup.number().required('Requerido'),
    pago_mensual: Yup.bool().required('Requerido'),
});

export default function SuscripcionForm() {
    const snackbar = useSnackbar();

    const { data, fetchData } = useEditFormData();
    const { updateLoginInfo } = useAuthState();

    const tarifa = data.tarifa || {
        pago_mensual: true,
        codigo_promocional: '',
        frecuencia_pago: '',
        numero_licencias: '',
        codigo_tarifa: '',
    };

    let cuotaMinima = 30;

    const tarifasSinCuotaMinima = ['default', 'default_202106', 'default_202306'];
    if (tarifasSinCuotaMinima.includes(tarifa.codigo_tarifa) || !data.tarifa) cuotaMinima = 0;

    return (
        <Formik
            initialValues={{
                id: tarifa.id,
                codigo_promocional: tarifa.codigo_promocional,
                pago_mensual: tarifa.pago_mensual,
                numero_licencias: tarifa.numero_licencias,
                importe: tarifa.importe,
            }}
            enableReinitialize
            validationSchema={SuscripcionSchema}
            onSubmit={(values, actions) => {
                companiesProvider
                    .getAll('profile/tarifa', {
                        method: 'post',
                        body: JSON.stringify(values),
                    })
                    .then((result) => {
                        const authService = new AuthService(API_ROOT);
                        authService.updateUserInfo().then(updateLoginInfo);

                        actions.setSubmitting(false);
                        fetchData();
                        snackbar.showMessage('Se han actualizado las preferencias con éxito');
                    })
                    .catch((err) => {
                        if (err.status === 400) {
                            for (const [field, errors] of Object.entries(err.message)) {
                                actions.setFieldError(field, errors.join('\n'));
                            }
                        }
                        actions.setSubmitting(false);
                    });
            }}
        >
            {() => <SuscripcionFormContent cuotaMinima={cuotaMinima} hasTarifa={Boolean(data.tarifa)} />}
        </Formik>
    );
}
