import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Alert from '@material-ui/lab/Alert';
import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isValid from 'date-fns/isValid';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { albaranesCompraProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { SwitchWithLabelField } from '../common/fields/Switch';
import TareaField from '../common/fields/TareaField';
import DialogForm from '../common/forms/DialogForm';

const ImputarMaterialesSchema = Yup.object().shape({
    tarea: Yup.mixed().required('Selecciona un servicio'),
    fecha: Yup.date().typeError('Fecha inválida').required('Requerido'),
    margen: Yup.number().typeError('Número inválido').required('Requerido').min(0, 'Debe ser mayor o igual a 0'),
});

const INVALID = 'invalido';
const CHECKING = 'checking';
const EXISTS = 'exists';
const NOT_EXISTS = 'not_exists';

function ImputarMaterialesDialog({ albaranCompraId, selectedRows, onSave, ...props }) {
    const snackbar = useSnackbar();
    return (
        <DialogForm
            title='Imputar materiales a un servicio'
            FormikProps={{
                initialValues: {
                    tarea: null,
                    fecha: new Date(),
                    margen: 0,
                    mergePartes: true,
                },
                validationSchema: ImputarMaterialesSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    setSubmitting(false);

                    albaranesCompraProvider
                        .imputar(
                            albaranCompraId,
                            values.tarea.id,
                            values.fecha,
                            selectedRows.map((row) => row.id),
                            values.margen,
                            values.mergePartes,
                        )
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                if (err.message instanceof Object) {
                                    for (const [field, errors] of Object.entries(err.message)) {
                                        setFieldError(field, errors.join('\n'));
                                    }
                                } else {
                                    snackbar.showMessage(err.message);
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ touched, errors, values, setFieldValue }) => {
                const [imputacionExists, setImputacionExists] = useState(INVALID);
                useEffect(() => {
                    if (
                        !values.tarea ||
                        !values.fecha ||
                        !isValid(values.fecha) ||
                        isBefore(values.fecha, new Date(2020, 1, 1)) ||
                        isAfter(values.fecha, addDays(new Date(), 365))
                    ) {
                        setImputacionExists(INVALID);
                        return;
                    }

                    albaranesCompraProvider.checkImputacion(values.tarea.id, values.fecha).then(({ existe }) => {
                        setImputacionExists(existe ? EXISTS : NOT_EXISTS);
                        setFieldValue('mergePartes', !existe);
                    });
                }, [values.tarea, values.fecha]);

                let imputacionInfo = null;

                if (imputacionExists === CHECKING) {
                    imputacionInfo = {
                        severity: 'info',
                        message: 'Comprobando si existe un parte de imputación de material en la fecha seleccionada...',
                    };
                } else if (imputacionExists === EXISTS) {
                    imputacionInfo = {
                        severity: 'warning',
                        message:
                            'Ya existe un parte de imputación de material en la fecha seleccionada. Puedes elegir fusionar los materiales en el mismo parte o crear uno nuevo',
                    };
                } else if (imputacionExists === NOT_EXISTS) {
                    imputacionInfo = {
                        severity: 'success',
                        message:
                            'No existe un parte de imputación de material en la fecha seleccionada. Se creará uno nuevo y se añadirá el material seleccionado.',
                    };
                }

                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TareaField
                                error={touched.tarea && !!errors.tarea}
                                helperText={touched.tarea && errors.tarea}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <KeyboardDatePicker
                                name='fecha'
                                label='Fecha de imputación'
                                format={'dd/MM/yyyy'}
                                fullWidth
                                autoOk
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name='margen'
                                label='Margen de venta'
                                fullWidth
                                type='number'
                                InputProps={{
                                    endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                                }}
                            />
                        </Grid>
                        {imputacionInfo && (
                            <Grid item xs={12}>
                                <Alert variant='filled' severity={imputacionInfo.severity}>
                                    {imputacionInfo.message}
                                </Alert>
                            </Grid>
                        )}
                        {imputacionExists === EXISTS && (
                            <Grid item xs={12}>
                                <SwitchWithLabelField
                                    name='mergePartes'
                                    label='Fusionar en el mismo parte de imputación'
                                    helpTooltip='Si en la fecha seleccionada ya existe un parte de imputación de material, se fusionarán los materiales en el mismo parte'
                                    disabled={imputacionExists === INVALID || imputacionExists === CHECKING}
                                />
                            </Grid>
                        )}
                    </Grid>
                );
            }}
        </DialogForm>
    );
}

export default withButtonOpener(ImputarMaterialesDialog);

ImputarMaterialesDialog.propTypes = {
    albaranCompraId: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    selectedRows: PropTypes.any,
};
