import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SettingsIcon from '@material-ui/icons/Settings';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            borderTop: '1px solid #eee',
            '& .MuiMenuItem-root': {
                borderTop: 'none',
            },
        },
        settingsItem: {
            borderLeft: '1px solid #eee',
            padding: theme.spacing(0, 1),
            marginLeft: 'auto',
            '& .MuiSvgIcon-root': {
                fontSize: 18,
            },
        },
    }),
    { name: 'MenuItemWithSettings' },
);

export default function MenuItemWithSettings({
    children,
    onClick,
    disabled,
    SettingsDialogComponent,
    SettingsDialogComponentProps,
}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <MenuItem disabled={disabled} onClick={onClick}>
                {children}
            </MenuItem>
            <SettingsDialogComponent
                {...SettingsDialogComponentProps}
                button={
                    <MenuItem disabled={disabled} className={classes.settingsItem}>
                        <SettingsIcon />
                    </MenuItem>
                }
            />
        </div>
    );
}

MenuItemWithSettings.propTypes = {
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    SettingsDialogComponent: PropTypes.any.isRequired,
    SettingsDialogComponentProps: PropTypes.object,
};
