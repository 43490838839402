import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { SwitchWithLabel } from '../../common/fields/Switch';

export default function PermisosAdministrativo({ hidden }) {
    const hiddenStyle = hidden ? { display: 'none' } : {};

    return (
        <Grid container spacing={2} style={hiddenStyle}>
            <Grid item xs={12}>
                <Field name='permisos.gestionar_clientes' component={SwitchWithLabel} label='Gestionar clientes' />
            </Grid>
            <Grid item xs={12}>
                <Field
                    name='permisos.gestionar_proveedores'
                    component={SwitchWithLabel}
                    label='Gestionar proveedores'
                />
            </Grid>
            <Grid item xs={12}>
                <Field name='permisos.gestionar_empleados' component={SwitchWithLabel} label='Gestionar empleados' />
            </Grid>
            <Grid item xs={12}>
                <Field
                    name='permisos.gestionar_mantenimientos'
                    component={SwitchWithLabel}
                    label='Gestionar mantenimientos'
                />
            </Grid>
            <Grid item xs={12}>
                <Field name='permisos.fichar' component={SwitchWithLabel} label='Fichar' />
            </Grid>
            <Grid item xs={12}>
                <Field name='permisos.gestionar_fichajes' component={SwitchWithLabel} label='Gestionar fichajes' />
            </Grid>
            <Grid item xs={12}>
                <Field
                    name='permisos.gestionar_preparaciones_material'
                    component={SwitchWithLabel}
                    label='Gestionar preparaciones de material'
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    name='permisos.gestionar_vacaciones_ausencias'
                    component={SwitchWithLabel}
                    label='Gestionar vacaciones y ausencias'
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    name='permisos.gestionar_documentacion'
                    component={SwitchWithLabel}
                    label='Gestionar documentación'
                />
            </Grid>
        </Grid>
    );
}

PermisosAdministrativo.propTypes = {
    hidden: PropTypes.bool,
};
