import ServicioEstadoChip from '../servicios/ServicioEstadoChip';
import EditFormHeaderItem from '../common/forms/EditForm/EditFormHeaderItem';
import ClienteInfoTooltip from '../servicios/ClienteInfoTooltip';
import DireccionInfoTooltip from '../servicios/DireccionInfoTooltip';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import useAuthState from '../../AuthState';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { formatFullDateTime } from '../../utils';
import SolicitudMaterialDetails from '../servicios/PreparacionesMaterialList/SolicitudMaterialDetails';
import PreparacionMaterialDetails from '../servicios/PreparacionesMaterialList/PreparacionMaterialDetails';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(3)}px`,
            padding: theme.spacing(2, 6, 6, 6),
        },
        title: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'normal',
            gap: `${theme.spacing(1)}px`,
            '& strong': {
                fontWeight: 500,
            },
        },
        header: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
        },
        headerInfo: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: `${theme.spacing(2)}px`,
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'PreparacionMaterialDrawerContent' },
);

export default function PreparacionMaterialDrawerContent({ preparacionMaterialId }) {
    const classes = useStyles();
    const { data } = useEditFormData();
    const selectedIndex = data ? data.preparaciones_material.findIndex((j) => j.id === preparacionMaterialId) : -1;
    const { data: preparacionMaterial } = useEditFormData(`preparaciones_material.${selectedIndex}`);

    const {
        userInfo: { preferencias: { usar_referencias_tareas: usarReferencias } = {} },
    } = useAuthState();

    if (!preparacionMaterial) return null;

    const Component = preparacionMaterial.type === 'SOLICITUD' ? SolicitudMaterialDetails : PreparacionMaterialDetails;

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography variant='h1' className={classes.title}>
                    <strong>Servicio:</strong> {data.descripcion}
                    <ServicioEstadoChip value={data.estado} style={{ marginLeft: 'auto' }} />
                </Typography>
                <div className={classes.headerInfo}>
                    <EditFormHeaderItem label='Cliente'>
                        <ClienteInfoTooltip id={data.cliente_id} label={data.cliente} />
                    </EditFormHeaderItem>
                    <EditFormHeaderItem label='Direccion'>
                        <DireccionInfoTooltip
                            direccion={data.direccion.direccion_completa}
                            label={data.direccion.nombre}
                        />
                    </EditFormHeaderItem>
                    <EditFormHeaderItem label='Referencia'>
                        {data.numero ? data.numero.toString() : ''}
                    </EditFormHeaderItem>
                    {usarReferencias && (
                        <EditFormHeaderItem label='Ref. interna'>{data.referencia_interna}</EditFormHeaderItem>
                    )}
                </div>
            </div>
            <div className={classes.header}>
                <Typography variant='h3' className={classes.title}>
                    <strong>
                        {preparacionMaterial.type === 'SOLICITUD'
                            ? 'Solicitud de material del operario'
                            : 'Preparación de materiales'}
                    </strong>
                </Typography>
                <div className={classes.headerInfo}>
                    <EditFormHeaderItem label='Última actualización'>
                        {formatFullDateTime(preparacionMaterial.fecha_actualizacion)}
                    </EditFormHeaderItem>
                </div>
            </div>
            <div className={classes.container}>{selectedIndex >= 0 && <Component index={selectedIndex} />}</div>
        </div>
    );
}

PreparacionMaterialDrawerContent.propTypes = {
    preparacionMaterialId: PropTypes.any,
};
