import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { tareasProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import { KeyboardDatePicker } from '../../common/fields/KeyboardDatePicker';
import DialogForm from '../../common/forms/DialogForm';

const useStyles = makeStyles(
    (theme) => ({
        fields: {
            gap: `${theme.spacing(2)}px`,
        },
        field: {
            width: 100,
            '& .MuiInput-input': {
                textAlign: 'right',
            },
        },
    }),
    { name: 'FechaEditor' },
);

const FechaEditorSchema = Yup.object().shape({
    fecha_creacion: Yup.date().typeError('Fecha inválida').required('Requerido'),
});

function FechaEditor({ albaran, onSave, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    return (
        <DialogForm
            title='Editar albarán de servicio'
            FormikProps={{
                initialValues: {
                    fecha_creacion: new Date(albaran.fecha_creacion),
                },
                enableReinitialize: true,
                validationSchema: FechaEditorSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    tareasProvider
                        .action(`albaranes/${albaran.id}`, {
                            method: 'post',
                            body: JSON.stringify(values),
                        })
                        .then(() => {
                            props.onClose();
                            setSubmitting(false);
                            onSave();
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [key, value] of Object.entries(err.message)) {
                                    setFieldError(key, value[0]);
                                }
                            } else {
                                snackbar.showMessage('Ha ocurrido un error');
                            }

                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {() => (
                <div className={classes.fields}>
                    <KeyboardDatePicker label='Fecha' name='fecha_creacion' autoOk />
                </div>
            )}
        </DialogForm>
    );
}

FechaEditor.propTypes = {
    albaran: PropTypes.object,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};

export default withButtonOpener(FechaEditor);
