import { useEffect } from 'react';
import { SelectControl } from './SelectControl';
import { FilterInput } from './FilterInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';

const options = [
    {
        label: '>=',
        value: 'gte',
    },
    {
        label: '<=',
        value: 'lte',
    },
    {
        label: '>',
        value: 'gt',
    },
    {
        label: '<',
        value: 'lt',
    },
    {
        label: '==',
        value: 'eq',
    },
];

export const operations = {
    gte: (a, b) => a >= b,
    gt: (a, b) => a > b,
    lte: (a, b) => a <= b,
    lt: (a, b) => a < b,
    eq: (a, b) => a === b,
};

export function NumberFilter({ column }) {
    const { filterValue: { operator, value } = {}, setFilter, filterOptions: { units, width = 80 } = {} } = column;

    useEffect(() => {
        if (operator !== undefined) return;

        setFilter({
            operator: options[0].value,
        });
    }, [operator]);

    return (
        <div style={{ display: 'flex' }}>
            <SelectControl
                options={options}
                value={operator || ''}
                onChange={(e) => {
                    setFilter({
                        value,
                        operator: e.target.value,
                    });
                }}
            />
            <FilterInput
                style={{ width: width, marginLeft: 4 }}
                endAdornment={units && <InputAdornment position='end'>{units}</InputAdornment>}
                value={value || ''}
                onChange={(e) => {
                    setFilter(
                        {
                            operator,
                            value: e.target.value,
                        } || undefined,
                    );
                }}
            />
        </div>
    );
}

NumberFilter.propTypes = {
    column: PropTypes.any,
};
