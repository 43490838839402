import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { instalacionesProvider } from '../../api';
import DeleteDialog from '../common/dialogs/DeleteDialog';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import EventosDialog from '../companies/profile/EventosDialog';

export function EditInstalacionMenuOptions() {
    const history = useHistory();
    const {
        data: { id },
    } = useEditFormData();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

    const openMenu = (event) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
    };

    const closeMenu = (event) => {
        event && event.stopPropagation();
        setMenuAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={openMenu}>
                <MoreHorizIcon />
            </IconButton>
            <Menu id='simple-menu' anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={closeMenu}>
                <MenuItem
                    onClick={(ev) => {
                        closeMenu(ev);
                        window.open(instalacionesProvider.getLibroMantenimientoPdfUrl(id));
                    }}
                >
                    Descargar libro de mantenimiento en PDF
                </MenuItem>
                <EventosDialog entityId={id} button={<MenuItem onClick={closeMenu}>Registro de actividad</MenuItem>} />
                <DeleteDialog
                    text='Al eliminar la instalación no podrás recuperar sus datos. ¿Deseas eliminarla de todos modos?'
                    onConfirm={(ev) => {
                        closeMenu(ev);

                        instalacionesProvider.delete(id).then(() => history.push('/instalaciones'));
                    }}
                    button={<MenuItem onClick={closeMenu}>Eliminar</MenuItem>}
                    confirmationText='borrar instalacion'
                />
            </Menu>
        </>
    );
}
