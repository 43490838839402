import { Typography } from '@material-ui/core';
import moment from 'moment';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        textAlign: 'center',
        marginBottom: theme.spacing(1),
        '& > *': {
            fontSize: 10,
            width: 32,
        },
    },
}));

const Week = (props) => <Typography variant='overline' color='textSecondary' {...props} />;

const WeekHeader = () => {
    const classes = useStyles();

    const weekdayNames = moment.weekdaysShort(true);

    return (
        <div className={classes.root}>
            {weekdayNames.map((name) => (
                <Week key={name}>{name}</Week>
            ))}
        </div>
    );
};

export default WeekHeader;
