import { useCallback, useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { contratosMantenimientoProvider } from '../../api';
import { useFetchData } from '../../hooks/useFetchData';
import EditForm from '../common/forms/EditForm/EditForm';
import { EditContratoMantenimientoFormContent } from './EditContratoMantenimientoFormContent';

export const PLANIFICACION_ROUTE = 'planificacion';
export const SERVICIOS_ROUTE = 'servicios';
export const EQUIPOS_ROUTE = 'equipos';

export default function EditContratoMantenimientoForm() {
    const { id } = useParams();
    const { isExact: isRouteExact, url } = useRouteMatch();
    const history = useHistory();

    const fetchDataFn = useCallback(() => contratosMantenimientoProvider.getAll(id), [id]);
    const { data, fetchData } = useFetchData(fetchDataFn, null);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (!isRouteExact || !data) return;

        history.replace(`${url}/${EQUIPOS_ROUTE}`);
    }, [data, url, isRouteExact]);

    return (
        data && (
            <>
                <EditForm data={data} fetchData={fetchData}>
                    <EditContratoMantenimientoFormContent />
                </EditForm>
            </>
        )
    );
}
