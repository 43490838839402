import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useState } from 'react';
import * as Yup from 'yup';
import { documentosProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import FileUploader from '../common/fields/FileUploader';
import OperariosField from '../common/fields/OperariosField';
import DialogForm from '../common/forms/DialogForm';
import { Tab, Tabs } from '../common/Tabs';

const useStyles = makeStyles(
    (theme) => ({
        tabs: {
            marginBottom: 0,
        },
    }),
    { name: 'NewFicheroDialog' },
);

const DocumentosSchema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
    descripcion: Yup.string(),
    files: Yup.lazy((value, { parent }) =>
        !parent.file_url || parent.file_url.length === 0 ? Yup.array().min(1, 'Requerido') : Yup.array(),
    ),
    file_url: Yup.lazy((value, { parent }) =>
        !parent.files || parent.files.length === 0
            ? Yup.string().url('Tiene que ser una URL valida').required('Requerido')
            : Yup.string().url(),
    ),
});

const UPLOAD = 'upload';
const URL = 'url';

function NewFicheroDialog({ parentId, onSave, ...props }) {
    const snackbar = useSnackbar();
    const classes = useStyles();
    const [tipoFichero, setTipoFichero] = useState(UPLOAD);

    return (
        <DialogForm
            title='Añadir fichero'
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    nombre: '',
                    descripcion: '',
                    parent_id: parentId,
                    files: [],
                    file_url: '',
                    publico: true,
                    operarios: [],
                },
                validationSchema: DocumentosSchema,
                onSubmit: ({ files, publico, ...values }, { setSubmitting, setFieldError }) => {
                    if (publico) values.operarios = [];

                    const formData = new FormData();
                    const formValues = {};

                    Object.entries(values).forEach(([key, value]) => (formValues[key] = value));
                    formData.append('params', JSON.stringify(formValues));

                    new Array(...files).forEach((file) => formData.append('files', file));

                    documentosProvider
                        .saveFichero(formData)
                        .then((data) => {
                            onSave && onSave(data.id);
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                if (err.message instanceof Object) {
                                    for (const [field, errors] of Object.entries(err.message)) {
                                        setFieldError(field, errors.join('\n'));
                                    }
                                } else {
                                    snackbar.showMessage(err.message);
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {({ setFieldValue, values, errors }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField name='nombre' label='Nombre' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name='descripcion' label='Descripcion' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <CheckboxWithLabel
                            type='checkbox'
                            name='publico'
                            Label={{
                                label: 'Visible para todos los operarios',
                            }}
                        />
                    </Grid>
                    {!values.publico && (
                        <Grid item xs={12}>
                            <OperariosField />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Tabs
                            value={tipoFichero}
                            onChange={(ev, newValue) => setTipoFichero(newValue)}
                            aria-label='Tipo de direccion'
                            classes={{
                                root: classes.tabs,
                            }}
                        >
                            <Tab label='Subir desde mi PC' value={UPLOAD} />
                            <Tab label='Vincular una URL' value={URL} />
                        </Tabs>
                    </Grid>
                    {tipoFichero === UPLOAD && (
                        <Grid item xs={12}>
                            <FileUploader
                                id='upload-fichero-adjuntos-file'
                                label='Fichero'
                                file={values.files}
                                setFile={(file) => setFieldValue('files', file ? [file] : [])}
                                isImage={false}
                                accept='image/jpeg,image/gif,image/png,application/pdf'
                                error={errors.files}
                            />
                        </Grid>
                    )}
                    {tipoFichero === URL && (
                        <Grid item xs={12}>
                            <TextField name='file_url' label='URL de fichero externo' fullWidth />
                        </Grid>
                    )}
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(NewFicheroDialog);

NewFicheroDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    parentId: PropTypes.any,
};
