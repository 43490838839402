import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { CustomFilter } from '../../../common/CustomFilter';

export function ParteTrabajoOperarioFilter({ operarios, onFilterChange }) {
    const [options, setOptions] = useState([
        {
            label: 'Todos',
            onClick: () => onFilterChange(null),
        },
    ]);

    useEffect(() => {
        setOptions([
            {
                label: 'Todos',
                onClick: () => onFilterChange(null),
            },
            ...operarios.map((operario) => ({
                label: operario.nombre,
                onClick: () => onFilterChange(operario.id),
            })),
        ]);
    }, [operarios]);

    return <CustomFilter label='Operario' options={options} />;
}

ParteTrabajoOperarioFilter.propTypes = {
    onFilterChange: PropTypes.any,
    operarios: PropTypes.any,
};
