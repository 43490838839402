import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { useParams } from 'react-router-dom';
import { formatDate } from '../../utils';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import EditFormHeader from '../common/forms/EditForm/EditFormHeader';
import EditFormHeaderItem from '../common/forms/EditForm/EditFormHeaderItem';
import EditFormHeaderItemList from '../common/forms/EditForm/EditFormHeaderItemList';
import FormTabWrapper from '../common/forms/EditForm/FormTabWrapper';
import CompraMaterialEstadoChip from '../servicios/CompraMaterialEstadoChip';
import AlbaranCompraLineasList from './AlbaranCompraLineasList';
import EditAlbaranCompraDialog from './EditAlbaranCompraDialog';
import { EditAlbaranCompraMenuOptions } from './EditAlbaranCompraMenuOptions';

export function EditAlbaranCompraFormContent() {
    const { data, fetchData } = useEditFormData();
    const { id } = useParams();

    return (
        <>
            <EditFormHeader
                label='Albarán de Compra'
                name={
                    <>
                        {data.proveedor.nombre} #{data.referencia}
                        <CompraMaterialEstadoChip value={data.estado} />
                    </>
                }
                actions={
                    <>
                        <EditAlbaranCompraDialog
                            button={
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            }
                            onSave={fetchData}
                            albaranCompra={data}
                        />
                        <EditAlbaranCompraMenuOptions />
                    </>
                }
            >
                <EditFormHeaderItemList>
                    <EditFormHeaderItem label='Fecha de solicitud'>
                        {formatDate(data.fecha_solicitud)}
                    </EditFormHeaderItem>
                    <EditFormHeaderItem label='Fecha prevista de llegada'>
                        {data.fecha_llegada_prevista ? formatDate(data.fecha_llegada_prevista) : 'Sin fecha'}
                    </EditFormHeaderItem>
                    <EditFormHeaderItem label='Fecha de llegada'>
                        {data.fecha_llegada ? formatDate(data.fecha_llegada) : 'Sin fecha'}
                    </EditFormHeaderItem>
                    <EditFormHeaderItem label='Factura de compra'>
                        {data.factura_compra !== null && data.factura_compra !== ''
                            ? data.factura_compra
                            : 'Sin factura'}
                    </EditFormHeaderItem>
                </EditFormHeaderItemList>
            </EditFormHeader>

            <FormTabWrapper>
                <AlbaranCompraLineasList albaranCompraId={id} data={data.lineas} />
            </FormTabWrapper>
        </>
    );
}
