import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { MarcajeTimePicker } from '../../../../common/fields/KeyboardDatePicker';

const useStyles = makeStyles(
    (theme) => ({
        timePicker: {
            '& .MuiInputBase-input': {
                width: 36,
                fontSize: theme.typography.subtitle2.fontSize,
            },
            '& .MuiInputAdornment-positionEnd': {
                marginLeft: 0,
                display: 'none',
            },
        },
        totalLabel: {
            marginLeft: 'auto',
            fontWeight: 'bold',
        },
    }),
    { name: 'MarcajeDiaField' },
);

export default function MarcajeDiaField({ name, index, showTotal, onUpdate }) {
    const classes = useStyles();

    const [{ value: horaEntradaValue }, { error: horaEntradaError }, { setValue: setHoraEntradaValue }] = useField(
        `${name}.horario[${index}].hora_entrada`,
    );
    const [{ value: horaSalidaValue }, { error: horaSalidaError }, { setValue: setHoraSalidaValue }] = useField(
        `${name}.horario[${index}].hora_salida`,
    );
    const [{ value: totalMinutosValue }, { error: totalMinutosError }, { setValue: setTotalMinutosValue }] = useField(
        `${name}.total_minutos`,
    );

    return (
        <>
            <Typography variant='subtitle2'>de</Typography>
            <MarcajeTimePicker
                inputVariant='outlined'
                className={classes.timePicker}
                onChange={(date) => {
                    setHoraEntradaValue(date);
                }}
                value={horaEntradaValue}
                onBlur={onUpdate}
                hasError={Boolean(horaEntradaError)}
                // helperText={horaEntradaError}
            />
            <Typography variant='subtitle2'>a</Typography>
            <MarcajeTimePicker
                inputVariant='outlined'
                className={classes.timePicker}
                onChange={(date) => {
                    setHoraSalidaValue(date);
                }}
                value={horaSalidaValue}
                onBlur={onUpdate}
                hasError={Boolean(horaSalidaError)}
                // helperText={horaSalidaError}
            />
            {showTotal && (
                <>
                    <Typography variant='subtitle2' className={classes.totalLabel}>
                        Total
                    </Typography>
                    <MarcajeTimePicker
                        inputVariant='outlined'
                        className={classes.timePicker}
                        onChange={(date) => {
                            setTotalMinutosValue(date);
                        }}
                        value={totalMinutosValue}
                        hasError={Boolean(totalMinutosError)}
                        // helperText={totalMinutosError}
                    />
                </>
            )}
        </>
    );
}

MarcajeDiaField.propTypes = {
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    showTotal: PropTypes.bool,
    onUpdate: PropTypes.func,
};
