import makeStyles from '@material-ui/core/styles/makeStyles';
import { EventListadoLabel } from './EventListadoLabel';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        actions: {
            display: 'flex',
            alignItems: 'center',
        },
    }),
    { name: 'EventListado' },
);

export function EventListado({ event }) {
    const classes = useStyles();
    const closed = ['agenda', 'jornada'].includes(event.type) && event.estado === 'FINALIZADA';

    return (
        <div className={classes.root}>
            <EventListadoLabel title={event.title} color={event.color} closed={closed} icon={event.icon} />
            {event.actions && <div className={classes.actions}>{event.actions}</div>}
        </div>
    );
}

EventListado.propTypes = {
    event: PropTypes.any,
};
