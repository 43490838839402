import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import { tareasProvider } from '../../api';
import { useSnackbar } from 'material-ui-snackbar-provider/lib';
import PropTypes from 'prop-types';
import Button from '../common/Button';

const ExportarJornadaSchema = Yup.object().shape({
    from_date: Yup.mixed().required('Escoge una fecha'),
    to_date: Yup.mixed().required('Escoge una fecha'),
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: 500,
    },
}));

export default function ExportarTareasDialog({ button, clienteId }) {
    const styles = useStyles();
    const [open, setOpen] = useState(false);
    const snackbar = useSnackbar();

    const formButton = React.cloneElement(button, { onClick: () => setOpen(true) });

    const today = new Date();
    const fromDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const toDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    return (
        <>
            {formButton}
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby='nueva-tarea-title'
                open={open}
                classes={{
                    paper: styles.root,
                }}
                fullWidth={false}
                maxWidth='lg'
            >
                <DialogTitle id='nueva-tarea-title'>Descargar informe de trabajos</DialogTitle>
                <DialogContent style={{ marginBottom: 16 }}>
                    <Formik
                        initialValues={{
                            from_date: fromDate,
                            to_date: toDate,
                            clientes: clienteId ? [{ id: clienteId }] : [],
                        }}
                        validationSchema={ExportarJornadaSchema}
                        onSubmit={({ clientes, ...values }, { setSubmitting }) => {
                            tareasProvider
                                .fetchRaw('descargar', {
                                    method: 'post',
                                    body: JSON.stringify({
                                        ...values,
                                        clientes: clientes.map((o) => o.id),
                                    }),
                                })
                                .then(async (data) => {
                                    const uri = window.URL.createObjectURL(await data.blob());

                                    const a = document.createElement('a');
                                    a.style = { display: 'none' };
                                    a.href = uri;
                                    a.download = data.headers.get('Content-Disposition').split('filename=')[1];
                                    document.body.appendChild(a);
                                    a.click();
                                    window.URL.revokeObjectURL(uri);
                                    document.body.removeChild(a);

                                    setSubmitting(false);
                                    setOpen(false);
                                })
                                .catch((err) => {
                                    // snackbar.showMessage('Ha ocurrido un error durante la descarga');
                                    snackbar.showMessage(err.body.message);
                                    setSubmitting(false);
                                });
                        }}
                    >
                        {({ isSubmitting, touched, errors, values, setFieldValue }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <KeyboardDatePicker
                                            name='from_date'
                                            label='Desde'
                                            format={'dd/MM/yyyy'}
                                            fullWidth
                                            autoOk
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <KeyboardDatePicker
                                            name='to_date'
                                            label='Hasta'
                                            format={'dd/MM/yyyy'}
                                            fullWidth
                                            autoOk
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                                    >
                                        <Button color='outlined' disabled={isSubmitting} onClick={() => setOpen(false)}>
                                            Cancelar
                                        </Button>
                                        <Button type='submit' color='info' disabled={isSubmitting}>
                                            Descargar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
}

ExportarTareasDialog.propTypes = {
    button: PropTypes.any,
    clienteId: PropTypes.any,
};
