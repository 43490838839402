import { Paper } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplayIcon from '@material-ui/icons/Replay';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { tareasProvider } from '../../../api';
import { gastosLabels } from '../../../api/tareas';
import useAuthState from '../../../AuthState';
import { formatFullDate, formatFullDateTime, formatTiempo } from '../../../utils';
import { ButtonDialog } from '../../common/dialogs/ButtonDialog';
import DeleteDialog from '../../common/dialogs/DeleteDialog';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterDetailsHeader from '../../common/forms/MasterDetailsView/MasterDetailsHeader';
import PaperTextEditor from '../../common/forms/PaperTextEditor';
import { ToggleButton, ToggleButtonGroup } from '../../common/ToggleButtonGroup';
import { JORNADAS_ROUTE } from '../EditServicioForm';
import { precioViews, VENTA } from '../ParteTrabajoList/ParteTrabajoDetails';
import SelectJornadasSinAlbaranDialog from '../ParteTrabajoList/SelectJornadasSinAlbaranDialog';
import useResumenJornadas from './useResumenJornadas';

const Table = withStyles(
    (theme) => ({
        root: {
            borderCollapse: 'separate',
            borderSpacing: '16px',
        },
    }),
    { name: 'Table' },
)(MuiTable);

const TableCell = withStyles(
    (theme) => ({
        root: {
            padding: 0,
            borderBottom: '1px solid #ccc',
            '&:not(:first-of-type)': {
                whiteSpace: 'nowrap',
            },
            '.MuiTableHead-root &': {
                border: 'none',
            },
        },
        sizeSmall: {
            '&:last-child': {
                padding: 0,
            },
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

const DenseTable = withStyles(
    (theme) => ({
        root: {
            margin: theme.spacing(1, 0),
        },
    }),
    { name: 'DenseTable' },
)(MuiTable);

const DenseTableCell = withStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(0, 2),
        },
    }),
    { name: 'DenseTableCell' },
)(MuiTableCell);

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.divider}`,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            '& .MuiTypography-root': {
                flex: 1,
                fontWeight: theme.typography.fontWeightMedium,
                lineHeight: '50px',
            },
            '& .MuiIconButton-root': {
                padding: 8,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        avisoVacio: {
            margin: theme.spacing(2),
        },
        dividerRow: {
            '& td': {
                padding: 0,
                border: 'none',
            },
        },
        emptyRow: {
            '& td': {
                color: theme.palette.neutral.primary,
            },
            '& td:not(:first-of-type)': {
                border: 'none',
            },
        },
        actions: {
            whiteSpace: 'nowrap',
            textAlign: 'right',
            border: 'none',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
    }),
    { name: 'AlbaranDetails' },
);

export default function AlbaranDetails({ index }) {
    const classes = useStyles();
    const { data: albaran, updateLocalData, fetchData } = useEditFormData(`albaranes.${index}`);
    const { data: allJornadas } = useEditFormData('jornadas');
    const [currentView, setCurrentView] = useState(VENTA);

    const match = useRouteMatch();

    function routeForJornada(tareaId, jornadaId) {
        const basePath = match.url.split('/')[1];
        return `/${basePath}/${tareaId}/${JORNADAS_ROUTE}/${jornadaId}`;
    }

    const { id } = useParams();
    const history = useHistory();

    const {
        userInfo: { preferencias: { usar_precios: usarPrecios } = {} },
    } = useAuthState();

    const jornadas = allJornadas.filter((jornada) => jornada.albaran_id === albaran.id);

    const { tiempoOperarios, materiales, gastos, totalMaterialVenta, totalMaterialCoste } =
        useResumenJornadas(jornadas);

    const allJornadasConAlbaran = allJornadas.every((j) => Boolean(j.albaran_id));

    const headerItems = [
        { label: 'Creado el', value: formatFullDateTime(albaran.fecha_creacion) },
        { label: 'Última actualización', value: formatFullDateTime(albaran.fecha_actualizacion) },
    ];
    if (albaran.fecha_envio) headerItems.push({ label: 'Enviado el', value: formatFullDateTime(albaran.fecha_envio) });

    if (albaran.fecha_facturacion)
        headerItems.push({ label: 'Facturado el', value: formatFullDateTime(albaran.fecha_facturacion) });
    if (albaran.numero_factura) headerItems.push({ label: 'Número de factura', value: albaran.numero_factura });

    return (
        <>
            <MasterDetailsHeader title='Albarán de servicio:' subtitle={albaran.numero} items={headerItems} />
            <Paper elevation={0} className={classes.root}>
                <div className={classes.title}>
                    <Typography variant='body1'>Partes de trabajo que contiene este albarán</Typography>

                    <SelectJornadasSinAlbaranDialog
                        title='Añadir partes de trabajo al albarán de servicio'
                        saveButtonText='Añadir'
                        onSave={(selectedJornadas) =>
                            tareasProvider.addJornadasToAlbaran(albaran.id, selectedJornadas).then(fetchData)
                        }
                        button={
                            <Tooltip title={!allJornadasConAlbaran ? 'Añadir partes de trabajo' : ''}>
                                <IconButton disabled={allJornadasConAlbaran}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    />
                </div>

                {jornadas.length === 0 ? (
                    <Typography variant='body2' className={classes.avisoVacio}>
                        Este albarán de servicio esta vacío. Añadele partes de trabajo o bórralo.
                    </Typography>
                ) : (
                    <DenseTable size='small' style={{ marginTop: 8 }}>
                        <TableHead>
                            <TableRow>
                                <DenseTableCell style={{ width: '50%' }}>Operario</DenseTableCell>
                                <DenseTableCell style={{ width: '50%' }}>Fecha</DenseTableCell>
                                <DenseTableCell>Revisado</DenseTableCell>
                                <DenseTableCell>Acciones</DenseTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {jornadas.map((jornada, index) => (
                                <TableRow key={index}>
                                    <DenseTableCell>
                                        {jornada.operario ?? 'Imputación de albarán de compra'}
                                    </DenseTableCell>
                                    <DenseTableCell>{formatFullDate(jornada.fecha)}</DenseTableCell>
                                    <DenseTableCell>{jornada.revisada ? 'Si' : 'No'}</DenseTableCell>
                                    <DenseTableCell className={classes.actions}>
                                        <Tooltip title='Ver parte de trabajo'>
                                            <IconButton
                                                onClick={() => history.replace(routeForJornada(id, jornada.id))}
                                            >
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <DeleteDialog
                                            text='¿Estás seguro de quitar este parte de trabajo del albarán?'
                                            onConfirm={() => {
                                                tareasProvider
                                                    .deleteJornadaFromAlbaran(albaran.id, jornada.id)
                                                    .then(fetchData);
                                            }}
                                            button={
                                                <Tooltip title='Quitar parte de trabajo del albarán'>
                                                    <IconButton>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        />
                                    </DenseTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </DenseTable>
                )}
            </Paper>
            <PaperTextEditor
                title='Descripción del trabajo realizado'
                value={albaran.explicacion}
                className={classes.root}
                placeholder='Escribe la descripción de lo que se ha hecho en los partes de este albarán de servicio'
                onSave={(value) => {
                    tareasProvider
                        .saveAlbaranExplicacion(albaran.id, value)
                        .then(() => updateLocalData('explicacion', value));
                }}
                extraActions={
                    <ButtonDialog
                        button={
                            <Tooltip title='Volver a generar la descripción'>
                                <IconButton>
                                    <ReplayIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        dialogTitle='Restablecer la descripción del trabajo'
                        dialogText='Se reemplazará la descripción actual juntando las explicaciones de los partes de trabajo del albarán. ¿Deseas continuar?'
                        onConfirm={() => {
                            const value = jornadas
                                .filter((jornada) => jornada.explicacion !== null && jornada.explicacion !== '')
                                .map((jornada) => jornada.explicacion)
                                .join('\n\n');

                            tareasProvider
                                .saveAlbaranExplicacion(albaran.id, value)
                                .then(() => updateLocalData('explicacion', value));
                        }}
                    />
                }
            />
            <Paper elevation={0} className={classes.root}>
                <div className={classes.title}>
                    <Typography variant='body1'>Detalles del albarán de servicio</Typography>
                    {usarPrecios && (
                        <ToggleButtonGroup value={currentView} exclusive onChange={(ev, view) => setCurrentView(view)}>
                            {precioViews.map(({ id, label }) => (
                                <ToggleButton key={id} value={id}>
                                    {label}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    )}
                </div>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '100%' }}>Descripción</TableCell>
                            <TableCell>Unidades</TableCell>
                            {usarPrecios && <TableCell>Precio</TableCell>}
                            {usarPrecios && currentView === VENTA && <TableCell>Dto</TableCell>}
                            {usarPrecios && <TableCell>Importe</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(tiempoOperarios).map(([operario, tiempoOperario]) => (
                            <TableRow key={operario}>
                                <TableCell>Mano de obra {operario}</TableCell>
                                <TableCell align='right'>{formatTiempo(tiempoOperario.tiempo)}</TableCell>
                                {usarPrecios && (
                                    <>
                                        <TableCell align='right'>
                                            {tiempoOperario[
                                                currentView === VENTA ? 'precioVenta' : 'precioCoste'
                                            ].toFixed(2)}
                                            €
                                        </TableCell>
                                        {currentView === VENTA && (
                                            <TableCell align='right'>{tiempoOperario.descuento.toFixed(0)}%</TableCell>
                                        )}
                                        <TableCell align='right'>
                                            {tiempoOperario[
                                                currentView === VENTA ? 'importeVenta' : 'importeCoste'
                                            ].toFixed(2)}
                                            €
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        ))}
                        <TableRow className={classes.dividerRow} />
                        {materiales.length === 0 && (
                            <TableRow className={classes.emptyRow}>
                                <TableCell>No hay materiales</TableCell>
                                <TableCell />
                            </TableRow>
                        )}
                        {materiales.map((material, i) => (
                            <TableRow key={i}>
                                <TableCell>{material.descripcion}</TableCell>
                                <TableCell align='right'>{material.unidades}</TableCell>
                                {usarPrecios && (
                                    <>
                                        <TableCell align='right'>
                                            {material[
                                                currentView === VENTA ? 'precio_unitario' : 'precio_unitario_coste'
                                            ].toFixed(2)}
                                            €
                                        </TableCell>
                                        {currentView === VENTA && (
                                            <TableCell align='right'>{material.descuento.toFixed(0)}%</TableCell>
                                        )}
                                        <TableCell align='right'>
                                            {material[currentView === VENTA ? 'importe' : 'importe_coste'] !== null
                                                ? `${material[
                                                      currentView === VENTA ? 'importe' : 'importe_coste'
                                                  ].toFixed(2)}€`
                                                : null}
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        ))}
                        {usarPrecios && (
                            <TableRow>
                                <TableCell colSpan={currentView === VENTA ? 4 : 3}>Total material</TableCell>
                                <TableCell align='right'>
                                    {(currentView === VENTA ? totalMaterialVenta : totalMaterialCoste).toFixed(2)}€
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow className={classes.dividerRow} />
                        {Object.entries(gastos).map(([key, gasto]) => {
                            return (
                                <TableRow key={key}>
                                    <TableCell>{gastosLabels[key]}</TableCell>
                                    <TableCell align='right'>{gasto.unidades.toFixed(2)}</TableCell>
                                    {usarPrecios && (
                                        <>
                                            <TableCell align='right'>
                                                {gasto[currentView === VENTA ? 'precioVenta' : 'precioCoste'].toFixed(
                                                    2,
                                                )}
                                                €
                                            </TableCell>
                                            {currentView === VENTA && (
                                                <TableCell align='right'>{gasto.descuento.toFixed(0)}%</TableCell>
                                            )}
                                            <TableCell align='right'>
                                                {gasto[currentView === VENTA ? 'importeVenta' : 'importeCoste'].toFixed(
                                                    2,
                                                )}
                                                €
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Paper>
        </>
    );
}

AlbaranDetails.propTypes = {
    index: PropTypes.any.isRequired,
};
