import DataProvider from './DataProvider';

export const empty = {
    codigo: '',
    nombre: '',
    activo: true,
};

class ProveedoresProvider extends DataProvider {
    constructor() {
        super('proveedores', empty);
    }

    getAsOptions = async () => {
        return this.getAll('as_options');
    };

    getHistoricoPrecios = async (proveedorId, materialInternoId) => {
        return this.getAll(`${proveedorId}/precios-material/${materialInternoId}`);
    };
}

export const dataProvider = new ProveedoresProvider();
