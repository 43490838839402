import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { clientesProvider } from '../../../api';
import { useFetchData } from '../../../hooks/useFetchData';
import Button from '../../common/Button';
import ListView from '../../common/list/ListView';
import EditProyectoDialog from './EditProyectoDialog';
import NewProyectoDialog from './NewProyectoDialog';

export function ProyectosList({ disabled, style }) {
    const { id: clienteId } = useParams();
    const fetchDataFn = useCallback(() => clientesProvider.getProyectos(clienteId), [clienteId]);
    const { data, fetchData } = useFetchData(fetchDataFn);

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        clientesProvider
            .deleteProyecto(clienteId, idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Codigo',
                accessor: 'codigo',
            },
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            addRoute: null,
            deleteConfirmationText: 'borrar proyecto',
            batchComponents: !disabled && (
                <NewProyectoDialog
                    clienteId={clienteId}
                    onSave={fetchData}
                    button={
                        <Button color='transparent' aria-label='Añadir proyecto' startIcon={<AddIcon />}>
                            Añadir proyecto
                        </Button>
                    }
                />
            ),
            useActions: true,
            tableOptions: {
                usePagination: false,
            },
        }),
        [],
    );

    return (
        <ListView
            tableTitle='Lista de proyectos'
            basePath={'/proyectos'}
            columns={columns}
            data={data}
            options={options}
            onDelete={onDelete}
            style={style}
            extraActions={(row) => (
                <EditProyectoDialog
                    button={
                        <Tooltip title='Editar proyecto'>
                            <IconButton>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    clienteId={clienteId}
                    onSave={fetchData}
                    proyectoId={row.id}
                />
            )}
        />
    );
}

ProyectosList.propTypes = {
    style: PropTypes.any,
    disabled: PropTypes.bool,
};
