import InputLabel from '@material-ui/core/InputLabel';
import { FormControl } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AdjuntosList from './AdjuntosList';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useField } from 'formik';
import { tareasProvider } from '../../../../api';
import PropTypes from 'prop-types';
import Button from '../../Button';
import { createRef } from 'react';
import SeleccionarDocumentosDialog from '../../../documentos/SeleccionarDocumentosDialog';
import useAuthState from '../../../../AuthState';

const useStyles = makeStyles(
    (theme) => ({
        adjuntarActions: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'AdjuntosField' },
);

export default function AdjuntosField({ disabled = false }) {
    const classes = useStyles();
    const id = 'AdjuntosField-1';

    const adjuntarRef = createRef();
    const {
        userInfo: { preferencias: { usar_documentos: usarDocumentos } = {} },
    } = useAuthState();

    const [{ value: adjuntos = [] }, , { setValue: setAdjuntosValue }] = useField('adjuntos');
    const [{ value: documentos = [] }, , { setValue: setDocumentosValue }] = useField('documentos');
    const [{ value: documentosSeleccionados = [] }, , { setValue: setDocumentosSeleccionadosValue }] =
        useField('documentosSeleccionados');
    const [{ value: files }, , { setValue: setFilesValues }] = useField('files');

    const filesArray = files ? [...files] : [];

    const mergedAdjuntos = [
        ...adjuntos.map((adjunto) => ({
            id: adjunto.id,
            path: adjunto.original_url,
            type: adjunto.content_type,
            thumbnail: adjunto.thumbnail_url,
            image: adjunto.image_url,
            existing: true,
            canDelete: !disabled,
            isDocumento: false,
        })),
        ...documentos.map((documento) => ({
            id: documento.id,
            name: documento.nombre,
            path: documento.original_url,
            type: documento.content_type,
            thumbnail: documento.thumbnail_url,
            image: documento.image_url,
            existing: true,
            canDelete: !disabled,
            isDocumento: true,
        })),
        ...filesArray.map((file, i) => ({
            id: i,
            path: file.name,
            type: file.type,
            existing: false,
            canDelete: !disabled,
            isDocumento: false,
        })),
        ...documentosSeleccionados.map((documento) => ({
            id: documento.id,
            name: documento.nombre,
            path: documento.original_url,
            type: documento.content_type,
            thumbnail: documento.thumbnail_url,
            image: documento.image_url,
            existing: false,
            canDelete: !disabled,
            isDocumento: true,
        })),
    ];

    return (
        <FormControl style={{ width: '100%' }}>
            <InputLabel shrink>Ficheros adjuntos</InputLabel>

            <AdjuntosList
                adjuntos={mergedAdjuntos}
                style={{ marginTop: 20 }}
                onDelete={(adjunto) => {
                    if (adjunto.existing) {
                        if (adjunto.isDocumento) {
                            setDocumentosValue(documentos.filter((doc) => doc.id !== adjunto.id));
                        } else {
                            tareasProvider
                                .action(`adjuntos/${adjunto.id}`, {
                                    method: 'delete',
                                })
                                .then(() => {
                                    setAdjuntosValue(adjuntos.filter((adj) => adj.id !== adjunto.id));
                                });
                        }
                    } else {
                        if (adjunto.isDocumento) {
                            setDocumentosSeleccionadosValue(
                                documentosSeleccionados.filter((doc) => doc.id !== adjunto.id),
                            );
                        } else {
                            filesArray.splice(adjunto.id, 1);
                            setFilesValues([...filesArray]);
                        }
                    }
                }}
            />
            {!disabled && (
                <div className={classes.adjuntarActions}>
                    <label htmlFor={id} className={classes.addFile}>
                        <input
                            ref={adjuntarRef}
                            id={id}
                            name={id}
                            style={{ display: 'none' }}
                            type='file'
                            accept='image/jpeg,image/gif,image/png,application/pdf'
                            onChange={(ev) => setFilesValues([...files, ...ev.target.files])}
                            multiple
                        />
                    </label>

                    <Button
                        color='primary'
                        startIcon={<AddIcon />}
                        size='small'
                        style={{ paddingLeft: 0 }}
                        onClick={() => adjuntarRef.current.click()}
                    >
                        Adjuntar desde PC
                    </Button>
                    {usarDocumentos && (
                        <SeleccionarDocumentosDialog
                            button={
                                <Button color='primary' startIcon={<AddIcon />} size='small' style={{ paddingLeft: 0 }}>
                                    Adjuntar desde documentación
                                </Button>
                            }
                            onSelect={(documentos) => {
                                setDocumentosSeleccionadosValue(documentos);
                            }}
                        />
                    )}
                </div>
            )}
        </FormControl>
    );
}

AdjuntosField.propTypes = {
    disabled: PropTypes.bool,
};
