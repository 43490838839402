import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditarTareaDialog from '../tareas/EditarTareaDialog';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ColorizeIcon from '@material-ui/icons/Colorize';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotasDialogAction from '../servicios/NotasDialog/NotasDialogAction';
import PlanificacionColorEditor from '../servicios/PlanificacionColorEditor';

export function TareaActions({
    tarea,
    onTareaChanged,
    updateTarea,
    canEdit = true,
    canColorize = false,
    jornadaId = null,
    planificacionId = null,
}) {
    if (!tarea) return null;

    let verUrl = `/servicios/${tarea.id}`;

    if (jornadaId) verUrl = `${verUrl}/partes-trabajo/${jornadaId}`;

    return (
        <>
            {canColorize && planificacionId && (
                <PlanificacionColorEditor
                    planificacion={tarea}
                    onSave={() => onTareaChanged(tarea.id)}
                    button={
                        <Tooltip title='Cambiar color'>
                            <IconButton style={{ padding: 6 }} aria-label='Editar'>
                                <ColorizeIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                    }
                />
            )}
            <NotasDialogAction id={tarea.id} item={tarea} onUpdate={updateTarea} />
            {canEdit && (
                <EditarTareaDialog
                    id={tarea.id}
                    button={
                        <Tooltip title='Editar servicio'>
                            <IconButton style={{ padding: 6 }} aria-label='Editar'>
                                <EditIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                    }
                    onSave={() => onTareaChanged(tarea.id)}
                />
            )}
            <Tooltip title={`Ver ${jornadaId ? 'parte de trabajo' : 'servicio'}`}>
                <IconButton style={{ padding: 6 }} aria-label='Ver' to={verUrl} component={NavLink}>
                    <VisibilityIcon fontSize='small' />
                </IconButton>
            </Tooltip>
        </>
    );
}

TareaActions.propTypes = {
    canClose: PropTypes.bool,
    canColorize: PropTypes.bool,
    canEdit: PropTypes.bool,
    jornadaId: PropTypes.any,
    onTareaChanged: PropTypes.func,
    planificacionId: PropTypes.any,
    tarea: PropTypes.any,
    updateTarea: PropTypes.any,
};
