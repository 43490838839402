import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import makeStyles from '@material-ui/core/styles/makeStyles';
import debounce from '@material-ui/core/utils/debounce';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import Alert from '@material-ui/lab/Alert';
import { Field, FieldArray, useField } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { uuid } from 'uuidv4';
import { checklistsProvider } from '../../../../api';
import { deepEqual } from '../../../../utils';
import { getItemStyle } from '../../../calendario_planificacion/CalendarioContent';
import Button from '../../../common/Button';
import { KeyboardDatePicker } from '../../../common/fields/KeyboardDatePicker';
import { SwitchWithLabel } from '../../../common/fields/Switch';
import SubtareasListDialog from '../../subtareas/SubtareasListDialog';
import SelectPlantillaField from '../SelectPlantillaField';

const useStyles = makeStyles(
    (theme) => ({
        itemsContainer: {
            maxHeight: 400,
            overflowY: 'scroll',
        },
        item: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'EditChecklistForm' },
);

export default function EditChecklistForm({ fieldName, currentSubtareaIds }) {
    const classes = useStyles();

    const fieldPrefix = fieldName ? `${fieldName}.` : '';

    const [{ value: checklist }, { error: checklistError }] = useField(fieldName);
    const [{ value: currentItems }, , { setValue: setChecklistItemsValue }] = useField(`${fieldPrefix}items`);
    const [{ value: plantilla }, ,] = useField(`${fieldPrefix}plantilla`);

    const [{ value: plantillaChanged }, , { setValue: setPlantillaChanged }] = useField(
        `${fieldPrefix}plantillaChanged`,
    );

    const [subtareasOpen, setSubtareasOpen] = useState(false);
    const [selectedPlantillaItems, setSelectedPlantillaItems] = useState(null);

    const schemaError = checklistError?._schema ?? checklistError?.contar_tiempo;
    const itemsError = typeof checklistError?.items === 'string' ? checklistError.items : null;

    useEffect(() => {
        if (!plantilla?.id) {
            setSelectedPlantillaItems(null);
            return;
        }

        checklistsProvider.getById(plantilla?.id).then((plantilla) => {
            setSelectedPlantillaItems(plantilla.items.map((item) => item.texto));
            setChecklistItemsValue(
                plantilla.items.map((item) => ({
                    id: uuid(),
                    texto: item.texto,
                    subtarea_id: item.subtarea_id,
                })),
            );
        });
    }, [plantilla?.id]);

    const plantillaHasChanged = useCallback(
        debounce((selectedPlantillaItems, currentItems) => {
            if (!selectedPlantillaItems) {
                setPlantillaChanged(false);
                return;
            }

            const currentItemsTextos = currentItems.map((item) => item.texto);

            setPlantillaChanged(!deepEqual(selectedPlantillaItems, currentItemsTextos));
        }, 1000),
        [],
    );

    useEffect(() => {
        if (!currentItems) return;
        plantillaHasChanged(selectedPlantillaItems, currentItems);
    }, [selectedPlantillaItems, currentItems]);

    if (!checklist) return null;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <KeyboardDatePicker name={`${fieldPrefix}fecha_inicio`} label='Fecha inicio' fullWidth />
            </Grid>
            <Grid item xs={12}>
                <Field
                    name={`${fieldPrefix}contar_tiempo`}
                    component={SwitchWithLabel}
                    label='Imputar mano de obra a las tareas predefinidas'
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    name={`${fieldPrefix}marcar_completado`}
                    component={SwitchWithLabel}
                    label='Marcar tareas como completadas'
                />
            </Grid>
            {schemaError && (
                <Grid item xs={12}>
                    <FormHelperText error>{schemaError}</FormHelperText>
                </Grid>
            )}
            <Grid item xs={12}>
                <SelectPlantillaField name={`${fieldPrefix}plantilla`} />
            </Grid>
            <FieldArray name={`${fieldPrefix}items`}>
                {({ remove, push, move }) => {
                    function onDragEnd(result) {
                        // dropped outside the list
                        if (!result.destination) {
                            return;
                        }

                        move(result.source.index, result.destination.index);
                    }

                    return (
                        <>
                            <SubtareasListDialog
                                open={subtareasOpen}
                                onClose={() => setSubtareasOpen(false)}
                                currentSubtareaIds={currentSubtareaIds}
                                onSave={(selectedItems) => {
                                    selectedItems
                                        .filter((item) => !currentSubtareaIds.includes(item.id))
                                        .forEach((item) => {
                                            push({
                                                id: uuid(),
                                                texto: item.titulo,
                                                subtarea_id: item.id,
                                                subtarea: item,
                                            });
                                        });
                                }}
                            />
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId='droppable'>
                                    {(provided, snapshot) => (
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            className={classes.itemsContainer}
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {currentItems &&
                                                currentItems.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                                        {(provided, snapshot) => (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                key={index}
                                                                className={classes.item}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style,
                                                                )}
                                                            >
                                                                <DragIndicatorIcon className={classes.dragHandle} />
                                                                {item.subtarea ? (
                                                                    <Input
                                                                        value={`${item.subtarea.codigo} | ${item.subtarea.titulo}`}
                                                                        disabled
                                                                        fullWidth
                                                                    />
                                                                ) : (
                                                                    <TextField
                                                                        name={`${fieldPrefix}items.${index}.texto`}
                                                                        fullWidth
                                                                        placeholder='Nueva tarea puntual'
                                                                    />
                                                                )}
                                                                <IconButton
                                                                    onClick={() => {
                                                                        remove(index);
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            {provided.placeholder}
                                        </Grid>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <Grid item xs={12}>
                                <Button
                                    color='primary'
                                    startIcon={<AddIcon />}
                                    size='small'
                                    style={{ paddingLeft: 0 }}
                                    onClick={() => push({ id: uuid(), nombre: '', subtarea_id: null })}
                                    disabled={checklist.contar_tiempo}
                                >
                                    Añadir tarea puntual
                                </Button>
                                <Button
                                    color='primary'
                                    startIcon={<AddIcon />}
                                    size='small'
                                    onClick={() => setSubtareasOpen(true)}
                                >
                                    Añadir tarea predefinida
                                </Button>
                            </Grid>
                            {itemsError && (
                                <Grid item xs={12}>
                                    <FormHelperText error>{itemsError}</FormHelperText>
                                </Grid>
                            )}
                        </>
                    );
                }}
            </FieldArray>
            {plantillaChanged && (
                <Grid item xs={12}>
                    <Alert severity='warning'>
                        El checklist ha cambiado respecto el original. ¿Quieres guardar los cambios en una nueva
                        plantilla?
                        <TextField
                            name={`${fieldPrefix}newPlantillaName`}
                            label='Nombre de la nueva plantilla'
                            InputLabelProps={{ shrink: true }}
                            placeholder='Déjalo vacío si no quieres crear una nueva plantilla con los cambios'
                            fullWidth
                        />
                    </Alert>
                </Grid>
            )}
        </Grid>
    );
}

EditChecklistForm.propTypes = {
    fieldName: PropTypes.string,
    currentSubtareaIds: PropTypes.array,
};
