import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 8,
            display: 'flex',
            flexDirection: 'column',
            background: 'white',
        },
        title: {
            width: '100%',
            borderBottom: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1, 0, 1.5),
            '& .MuiTypography-root': {
                lineHeight: '40px',
                flex: 1,
                fontWeight: theme.typography.fontWeightMedium,
            },
            '& .MuiIconButton-root': {
                padding: 4,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
    }),
    { name: 'DialogPaper' },
);

export default function DialogPaper({ title, actions, className, children, typographyVariant = 'subtitle1' }) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.title}>
                <Typography component='div' variant={typographyVariant}>
                    {title}
                </Typography>
                {actions}
            </div>
            {children}
        </div>
    );
}

DialogPaper.propTypes = {
    actions: PropTypes.any,
    children: PropTypes.any,
    className: PropTypes.any,
    title: PropTypes.any,
    typographyVariant: PropTypes.string,
};
