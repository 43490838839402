import PropTypes from 'prop-types';
import { createRef, useCallback, useMemo, useState } from 'react';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import { ToggleButton, ToggleButtonGroup } from '../../common/ToggleButtonGroup';
import PresupuestoTreeViewTable, {
    COLUMN_TYPE_NUMBER,
} from '../../presupuestos/PresupuestoTreeViewTable/PresupuestoTreeViewTable';
import { precioViews, VENTA } from '../../servicios/ParteTrabajoList/ParteTrabajoDetails';
import AvancePresupuestoLinea from './AvancePresupuestoLinea';
import { LineasOptionsButton } from './LineasOptionsButton';

export default function AvancePresupuestoTable({ baseKey, avanceId, index, onSave, certificado, bloqueado }) {
    const { data: presupuesto } = useEditFormData(`${baseKey}.presupuestos.${index}`);

    const tableRef = createRef();
    const [currentView, setCurrentView] = useState(VENTA);

    const lineas = presupuesto.lineas;
    const capituloLineaIds = useMemo(() => {
        if (lineas === undefined) return [];

        function flattenLineaIds(lineas) {
            return lineas.reduce((acc, linea) => {
                if (linea.tipo_linea !== 'CAPITULO') return acc;
                if (linea.lineas) {
                    return [...acc, linea.id, ...flattenLineaIds(linea.lineas)];
                }
                return [...acc, linea.id];
            }, []);
        }
        return flattenLineaIds(lineas);
    }, [lineas]);

    const columns = useMemo(() => {
        const columns = [
            {
                header: 'Unidades',
                type: COLUMN_TYPE_NUMBER,
                extraProps: {
                    colSpan: 2,
                },
            },
            {
                header: '% avance',
                type: COLUMN_TYPE_NUMBER,
            },
            {
                header: 'Total avance',
                type: COLUMN_TYPE_NUMBER,
            },
            {
                header: 'Precio',
                type: COLUMN_TYPE_NUMBER,
            },
            {
                header: 'Total',
                type: COLUMN_TYPE_NUMBER,
            },
        ];
        if (!certificado)
            columns.push({
                header: 'Acciones',
                type: COLUMN_TYPE_NUMBER,
            });
        return columns;
    }, [certificado]);

    const LineasOptionsComponent = useCallback(
        (props) => (
            <LineasOptionsButton
                {...props}
                onSetCapitulosExpanded={(expanded) => {
                    if (expanded) tableRef.current.setExpandedLineas(tableRef.current.allLineaIds);
                    else tableRef.current.setExpandedLineas(capituloLineaIds);
                }}
            />
        ),
        [capituloLineaIds, tableRef?.current?.allLineaIds],
    );

    // useEffect(() => {
    //     if (!tableRef.current) return;
    //
    //     tableRef.current.setExpandedLineas(capituloLineaIds);
    // }, [capituloLineaIds, tableRef.current]);

    return (
        <PresupuestoTreeViewTable
            title={`Presupuesto: ${presupuesto.nombre}`}
            ref={tableRef}
            presupuesto={presupuesto}
            columns={columns}
            onSave={onSave}
            LineaComponent={AvancePresupuestoLinea}
            LineaComponentProps={{
                avanceId,
                currentView,
                certificado,
                bloqueado,
            }}
            extraActions={
                <ToggleButtonGroup
                    value={currentView}
                    exclusive
                    onChange={(ev, view) => view !== null && setCurrentView(view)}
                >
                    {precioViews.map(({ id, label }) => (
                        <ToggleButton key={id} value={id}>
                            {label}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            }
            LineasOptionsComponent={LineasOptionsComponent}
        />
    );
}

AvancePresupuestoTable.propTypes = {
    baseKey: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    onSave: PropTypes.func.isRequired,
    avanceId: PropTypes.string.isRequired,
    certificado: PropTypes.bool.isRequired,
    bloqueado: PropTypes.bool.isRequired,
};
