import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import useAuthState from '../../AuthState';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import EditFormHeaderItem from '../common/forms/EditForm/EditFormHeaderItem';
import ClienteInfoTooltip from '../servicios/ClienteInfoTooltip';
import DireccionInfoTooltip from '../servicios/DireccionInfoTooltip';
import ParteTrabajoDetails from '../servicios/ParteTrabajoList/ParteTrabajoDetails';
import usePreciosGastos from '../servicios/ParteTrabajoList/usePreciosGastos';
import ServicioEstadoChip from '../servicios/ServicioEstadoChip';
import EditarTareaButtonDialog from '../tareas/EditarTareaDialog';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(3)}px`,
            padding: theme.spacing(2, 6, 6, 6),
        },
        title: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'normal',
            gap: `${theme.spacing(1)}px`,
            '& strong': {
                fontWeight: 500,
            },
        },
        header: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
        },
        headerInfo: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: `${theme.spacing(2)}px`,
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'JornadaDrawerContent' },
);

export default function JornadaDrawerContent({ jornadaId, onUpdate }) {
    const classes = useStyles();

    const { data, fetchData } = useEditFormData();
    const selectedJornadaIndex = data ? data.jornadas.findIndex((j) => j.id === jornadaId) : -1;
    const { data: jornada } = useEditFormData(`jornadas.${selectedJornadaIndex}`);

    const {
        userInfo: { preferencias: { usar_referencias_tareas: usarReferencias } = {} },
    } = useAuthState();

    const preciosGastos = usePreciosGastos(data.id, Boolean(jornada));

    if (!jornada) return null;

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography variant='h1' className={classes.title}>
                    <strong>Servicio:</strong> {data.descripcion}
                    <ServicioEstadoChip value={data.estado} style={{ marginLeft: 'auto' }} />
                    <EditarTareaButtonDialog
                        id={data.id}
                        button={
                            <Tooltip title='Editar servicio'>
                                <IconButton style={{ padding: 6 }} aria-label='Editar'>
                                    <EditIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        }
                        onSave={fetchData}
                    />
                </Typography>
                <div className={classes.headerInfo}>
                    <EditFormHeaderItem label='Cliente'>
                        <ClienteInfoTooltip id={data.cliente_id} label={data.cliente} />
                    </EditFormHeaderItem>
                    <EditFormHeaderItem label='Direccion'>
                        <DireccionInfoTooltip
                            direccion={data.direccion.direccion_completa}
                            label={data.direccion.nombre}
                        />
                    </EditFormHeaderItem>
                    <EditFormHeaderItem label='Referencia'>
                        {data.numero ? data.numero.toString() : ''}
                    </EditFormHeaderItem>
                    {usarReferencias && (
                        <EditFormHeaderItem label='Ref. interna'>{data.referencia_interna}</EditFormHeaderItem>
                    )}
                </div>
            </div>
            <div className={classes.container}>
                {preciosGastos && selectedJornadaIndex >= 0 && (
                    <ParteTrabajoDetails
                        index={selectedJornadaIndex}
                        preciosGastos={preciosGastos}
                        onUpdate={onUpdate}
                        size='small'
                    />
                )}
            </div>
        </div>
    );
}

JornadaDrawerContent.propTypes = {
    jornadaId: PropTypes.any,
    onUpdate: PropTypes.any,
};
