import DataProvider from './DataProvider';

export const empty = {
    titulo: '',
    mensaje: '',
    operarios: [],
};

class NotasDiaProvider extends DataProvider {
    constructor() {
        super('notas_dia', empty);
    }
}

export const dataProvider = new NotasDiaProvider();
