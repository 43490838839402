import React from 'react';
import { useAsyncDebounce } from 'react-table';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';

export function GlobalFilter({ globalFilter, setGlobalFilter }) {
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <OutlinedInput
            value={value || ''}
            onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
            }}
            placeholder={'Buscar...'}
            margin='dense'
            endAdornment={<SearchIcon />}
            style={{
                fontSize: 12,
            }}
        />
    );
}

GlobalFilter.propTypes = {
    globalFilter: PropTypes.any,
    setGlobalFilter: PropTypes.any,
};
