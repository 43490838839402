import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import PaperTextEditor from '../../common/forms/PaperTextEditor';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '../../common/Button';
import { preparacionesMaterialProvider } from '../../../api';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiTable from '@material-ui/core/Table';
import MuiTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';

const Table = withStyles(
    (theme) => ({
        root: {
            borderCollapse: 'separate',
            borderSpacing: '16px',
        },
    }),
    { name: 'Table' },
)(MuiTable);

const TableCell = withStyles(
    (theme) => ({
        root: {
            padding: 0,
            borderBottom: '1px solid #ccc',
            '&:not(:first-of-type)': {
                whiteSpace: 'nowrap',
            },
            '.MuiTableHead-root &': {
                border: 'none',
            },
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        sizeSmall: {
            '&:last-child': {
                padding: 0,
            },
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '50px',
            paddingLeft: theme.spacing(2),
        },
        dividerRow: {
            '& td': {
                padding: 0,
                border: 'none',
            },
        },
        emptyRow: {
            '& td': {
                color: theme.palette.neutral.primary,
            },
            '& td:not(:first-of-type)': {
                border: 'none',
            },
        },
        actions: {
            whiteSpace: 'nowrap',
            textAlign: 'right',
            border: 'none',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        heroPendiente: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(4),
            '& .MuiTypography-root': {
                textAlign: 'center',
                fontWeight: theme.typography.fontWeightRegular,
            },
        },
    }),
    { name: 'SolicitudMaterialDetails' },
);

export default function SolicitudMaterialDetails({ index }) {
    const classes = useStyles();
    const { data: solicitudMaterial, fetchData } = useEditFormData(`preparaciones_material.${index}`);

    return (
        <>
            {solicitudMaterial.notas && (
                <PaperTextEditor
                    title='Lista de material solicitado'
                    value={solicitudMaterial.notas}
                    className={classes.root}
                    canEdit={false}
                    onSave={() => {}}
                />
            )}
            {solicitudMaterial.lineas.length > 0 && (
                <Paper elevation={0} className={classes.root}>
                    <Typography variant='body1' className={classes.title}>
                        Lista de material solicitado
                    </Typography>

                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '100%' }}>Descripción</TableCell>
                                <TableCell>Uds. a preparar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {solicitudMaterial.lineas.map((linea, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <div className={classes.descripcion}>{linea.descripcion}</div>
                                        </TableCell>
                                        <TableCell align='right'>{linea.unidades}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Paper>
            )}

            {!solicitudMaterial.has_preparacion && (
                <Paper elevation={0} className={classes.heroPendiente}>
                    <Typography variant='h1'>Añadir a preparación de material</Typography>

                    <Typography variant='body2'>
                        Al empezar a preparar el material de la lista, podrás añadir notas y solicitar la compra de
                        material en caso de que sea necesario.
                    </Typography>

                    <Button
                        color='info'
                        onClick={() =>
                            preparacionesMaterialProvider
                                .addSolicitudToPreparacion(solicitudMaterial.id)
                                .then(fetchData)
                        }
                    >
                        Añadir a preparación de material
                    </Button>
                </Paper>
            )}
        </>
    );
}

SolicitudMaterialDetails.propTypes = {
    index: PropTypes.any.isRequired,
};
