import { useCallback, useMemo, useState } from 'react';
import TableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableFooter from '@material-ui/core/TableFooter';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import { formatDate } from '../../../utils';
import PropTypes from 'prop-types';

const TableCell = withStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(0.5, 2),
            borderBottom: '1px solid #eee',
            whiteSpace: 'nowrap',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
            '.MuiTableRow-head:nth-of-type(2) &.MuiTableCell-head.MuiTableCell-stickyHeader': {
                top: 33,
            },
        },
        footer: {
            color: theme.palette.text.primary,
            fontWeight: 500,
        },
        sizeSmall: {
            '&:last-child': {
                padding: 0,
            },
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

const TableRow = withStyles(
    (theme) => ({
        root: {
            '&:not(.empty):hover': {
                backgroundColor: theme.palette.neutral.grey3,
            },
        },
    }),
    { name: 'TableRow' },
)(MuiTableRow);

const useStyles = makeStyles(
    (theme) => ({
        header: {
            display: 'flex',
            gap: `${theme.spacing(4)}px`,
        },
        title: {
            marginBottom: theme.spacing(2),
        },
        noLaborable: {
            '& td:not($diferencia)': {
                color: theme.palette.neutral.primary,
            },
        },
        diferencia: {
            color: theme.palette.error.main,
        },
        paper: {
            marginBottom: theme.spacing(3),
            flex: 1,
        },
        tableTitle: {
            margin: theme.spacing(1, 2),
            height: 32,
            display: 'flex',
            alignItems: 'center',
        },
        container: {
            maxHeight: 400,
        },
        stickyFooter: {
            '& tbody': {
                marginBottom: 33,
            },
            '& .MuiTableCell-footer': {
                left: 0,
                bottom: 0,
                position: 'sticky',
                zIndex: 2,
                backgroundColor: '#fafafa',
            },
        },
    }),
    { name: 'TableByCompany' },
);

export default function TableByCompany({ resumen, company, loading }) {
    const classes = useStyles();
    const [containerHeight, setContainerHeight] = useState(500);

    const updateContainerHeight = useCallback((node) => {
        if (node !== null) {
            setContainerHeight(node.getBoundingClientRect().height - 32);
        }
    }, []);

    const totales = useMemo(() => {
        const totales = {
            num_tareas_creadas: 0,
            num_planificaciones_creadas: 0,
            num_jornadas_creadas: 0,
            num_jornadas_revisadas: 0,
            num_albaranes_creados: 0,
            num_albaranes_facturados: 0,
        };

        if (!resumen) return totales;

        resumen.forEach((resumenFecha) => {
            if (resumenFecha?.stats?.num_tareas_creadas)
                totales.num_tareas_creadas += resumenFecha.stats.num_tareas_creadas;
            if (resumenFecha?.stats?.num_planificaciones_creadas)
                totales.num_planificaciones_creadas += resumenFecha.stats.num_planificaciones_creadas;
            if (resumenFecha?.stats?.num_jornadas_creadas)
                totales.num_jornadas_creadas += resumenFecha.stats.num_jornadas_creadas;
            if (resumenFecha?.stats?.num_jornadas_revisadas)
                totales.num_jornadas_revisadas += resumenFecha.stats.num_jornadas_revisadas;
            if (resumenFecha?.stats?.num_albaranes_creados)
                totales.num_albaranes_creados += resumenFecha.stats.num_albaranes_creados;
            if (resumenFecha?.stats?.num_albaranes_facturados)
                totales.num_albaranes_facturados += resumenFecha.stats.num_albaranes_facturados;
        });

        return totales;
    }, [resumen]);

    return (
        <>
            <Paper elevation={0} className={classes.paper} ref={updateContainerHeight}>
                <Typography variant='h6' className={classes.tableTitle}>
                    {company ? `Empresa: ${company.name}` : 'Selecciona una empresa para ver sus datos'}
                </Typography>
                <TableContainer
                    className={classes.container}
                    style={{ minHeight: containerHeight, maxHeight: containerHeight }}
                >
                    <Table size='small' stickyHeader className={classes.stickyFooter}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Fecha</TableCell>
                                <TableCell>Servicios creados</TableCell>
                                <TableCell>Planif. creadas</TableCell>
                                <TableCell>Partes creados</TableCell>
                                <TableCell>Partes revisados</TableCell>
                                <TableCell>Albaranes creados</TableCell>
                                <TableCell>Albaranes facturados</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(!resumen || resumen.length === 0) && (
                                <TableRow className='empty'>
                                    <TableCell colSpan={10} align='center' style={{ height: containerHeight - 34 * 3 }}>
                                        {loading ? <CircularProgress /> : 'No hay datos'}
                                    </TableCell>
                                </TableRow>
                            )}
                            {resumen?.map((resumenFecha, i) => {
                                const isWeekend = [0, 6].includes(new Date(resumenFecha.fecha).getDay());
                                return (
                                    <TableRow key={i} className={isWeekend ? classes.noLaborable : null}>
                                        <TableCell>{formatDate(resumenFecha.fecha)}</TableCell>
                                        <TableCell>{resumenFecha.stats.num_tareas_creadas}</TableCell>
                                        <TableCell>{resumenFecha.stats.num_planificaciones_creadas}</TableCell>
                                        <TableCell>{resumenFecha.stats.num_jornadas_creadas}</TableCell>
                                        <TableCell>{resumenFecha.stats.num_jornadas_revisadas}</TableCell>
                                        <TableCell>{resumenFecha.stats.num_albaranes_creados}</TableCell>
                                        <TableCell>{resumenFecha.stats.num_albaranes_facturados}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell>Totales</TableCell>
                                <TableCell>{totales.num_tareas_creadas}</TableCell>
                                <TableCell>{totales.num_planificaciones_creadas}</TableCell>
                                <TableCell>{totales.num_jornadas_creadas}</TableCell>
                                <TableCell>{totales.num_jornadas_revisadas}</TableCell>
                                <TableCell>{totales.num_albaranes_creados}</TableCell>
                                <TableCell>{totales.num_albaranes_facturados}</TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}

TableByCompany.propTypes = {
    company: PropTypes.any,
    resumen: PropTypes.array,
    loading: PropTypes.bool,
};
