import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import isPast from 'date-fns/isPast';
import { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { preparacionesMaterialProvider } from '../../api';
import { estadoCompraLabels, estadoCompraOptions } from '../../api/preparaciones_material';
import useAuthState from '../../AuthState';
import { useFetchData } from '../../hooks/useFetchData';
import { formatDate, formatDateTime } from '../../utils';
import { DateColumnFilter, dateFilterFn } from '../common/list/DateColumnFilter';
import BaseListView, { withTableState } from '../common/list/ListView';
import { SelectColumnFilter } from '../common/list/SelectColumnFilter';
import { ClienteLinkCell } from '../dashboard/ClienteLink';
import CompraMaterialEstadoChip from '../servicios/CompraMaterialEstadoChip';
import EditarCompraMaterialDialog from '../servicios/PreparacionesMaterialList/EditarCompraMaterialDialog';

const ListView = withTableState('compras-material', BaseListView);

export default function ComprasMaterialList() {
    const {
        userInfo: { preferencias: { usar_referencias_tareas: usarReferencias } = {} },
    } = useAuthState();
    const fetchDataFn = useCallback(() => preparacionesMaterialProvider.getAllCompras(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const columns = useMemo(() => {
        const columns = [];

        if (usarReferencias)
            columns.push({
                Header: 'Ref. servicio (interna)',
                accessor: 'referencia_interna',
            });

        columns.push(
            {
                Header: 'Ref. servicio',
                id: 'numero',
                accessor: (row) => (row.numero ? row.numero.toString().padStart(4, '0') : 'N/D'),
            },
            {
                Header: 'Título',
                accessor: 'descripcion',
            },
            {
                Header: 'Cliente',
                accessor: 'cliente',
                Cell: ClienteLinkCell,
            },
            {
                Header: 'Direccion',
                accessor: 'direccion',
            },
            {
                Header: 'Ref. compra',
                accessor: 'referencia',
            },
            {
                Header: 'Fecha solicitud',
                accessor: 'fecha_solicitud',
                Cell: ({ value }) => formatDateTime(value),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Fecha prevista llegada',
                accessor: 'fecha_llegada_prevista',
                Cell: ({ value }) => (value ? formatDate(value) : 'Sin fecha'),
                getExtraCellProps: ({ value }) => ({
                    style: { color: value && isPast(new Date(value)) ? 'red' : 'initial' },
                }),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Fecha llegada',
                accessor: 'fecha_llegada',
                Cell: ({ value }) => (value ? formatDateTime(value) : 'Sin fecha'),
                getExtraCellProps: ({ value }) => ({
                    style: { color: value && isPast(new Date(value)) ? 'red' : 'initial' },
                }),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Estado',
                accessor: 'estado',
                Cell: CompraMaterialEstadoChip,
                Filter: SelectColumnFilter,
                filter: 'equals',
                filterOptions: {
                    options: estadoCompraOptions,
                    includeAllOption: {
                        value: 'todos',
                        label: 'Todos',
                    },
                },
                exportValue: ({ value }) => estadoCompraLabels[value],
            },
        );
        return columns;
    }, [usarReferencias]);

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            hideDelete: true,
            tableOptions: {
                fillEmptySpace: false,
            },
        }),
        [],
    );

    return (
        <ListView
            tableTitle='Lista de compras de material'
            basePath='/preparacion-material'
            columns={columns}
            data={data}
            options={options}
            extraActions={(row) => (
                <>
                    <Tooltip title='Ver ficha'>
                        <Link to={`/servicios/${row.tarea_id}/preparaciones-material/${row.preparacion_material_id}`}>
                            <IconButton aria-label='Ver ficha'>
                                <VisibilityIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>
                    <EditarCompraMaterialDialog
                        compraMaterialId={row.id}
                        button={
                            <Tooltip title='Editar compra de material'>
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        onSave={fetchData}
                    />
                </>
            )}
        />
    );
}
