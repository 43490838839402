import Grid from '@material-ui/core/Grid';
import MuiTextField from '@material-ui/core/TextField';
import addMinutes from 'date-fns/addMinutes';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { Field } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { tareasProvider } from '../../../../api';
import { withButtonOpener } from '../../../../hooks/withButtonOpener';
import { fromMinutosAsFecha, setBeginOfDay } from '../../../../utils';
import { KeyboardTimePicker } from '../../../common/fields/KeyboardDatePicker';
import DialogForm from '../../../common/forms/DialogForm';
import { MarcajeInfoField } from '../../../fichajes/MarcajesField';
import EntradaIcon from '../../../icons/Entrada';
import SalidaIcon from '../../../icons/Salida';

const MarcajeSchema = Yup.object().shape({
    hora_entrada: Yup.date()
        .typeError('Inválido')
        .nullable()
        .test('hora_entrada', 'Requerido', function (value) {
            return value || !this.parent.hora_salida;
        }),
    hora_salida: Yup.date().typeError('Inválido').nullable(),
    minutos_as_fecha: Yup.date().typeError('Inválido').required('Requerido'),
});

function EditarMarcajeDialog({ marcaje, fecha, tareaId, operarioId, subtareas, onSave, ...props }) {
    const snackbar = useSnackbar();

    const subtareasOptions = useMemo(
        () =>
            subtareas
                ? subtareas.map((subtarea) => {
                      const st = subtarea.subtarea ?? subtarea;
                      return { value: st.id, label: `${st.codigo} | ${st.titulo}` };
                  })
                : [],
        [subtareas],
    );

    return (
        <DialogForm
            title={marcaje === null ? 'Añadir marcaje' : 'Editar marcaje'}
            FormikProps={{
                initialValues: {
                    subtarea:
                        marcaje && marcaje.subtarea ? subtareas.find((op) => op.id === marcaje.subtarea.id) : null,
                    hora_entrada: marcaje && marcaje.hora_entrada ? new Date(marcaje.hora_entrada) : null,
                    hora_salida: marcaje && marcaje.hora_salida ? new Date(marcaje.hora_salida) : null,
                    minutos_as_fecha: addMinutes(
                        setBeginOfDay(marcaje ? new Date(marcaje.hora_entrada) : new Date()),
                        marcaje ? marcaje.minutos : 0,
                    ),
                },
                enableReinitialize: true,
                validationSchema: MarcajeSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    let url = `jornadas/${fecha}/marcajes`;
                    if (marcaje) url = `${url}/${marcaje.id}`;

                    const payload = {
                        minutos: fromMinutosAsFecha(values.minutos_as_fecha),
                        operario_id: operarioId,
                        subtarea_id: values.subtarea ? values.subtarea.id : null,
                    };

                    if (values.hora_entrada) payload.hora_entrada = values.hora_entrada;
                    if (values.hora_salida) payload.hora_salida = values.hora_salida;

                    tareasProvider
                        .actionOnId(tareaId, url, payload)
                        .then((updatedFichaje) => {
                            setSubmitting(false);
                            onSave(updatedFichaje);
                            props.onClose();
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                if (err.message instanceof Object) {
                                    for (const [field, errors] of Object.entries(err.message)) {
                                        setFieldError(field, errors.join('\n'));
                                    }
                                } else {
                                    snackbar.showMessage(err.message);
                                }
                            } else {
                                snackbar.showMessage(err.toString());
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ values, setFieldValue }) => {
                const { hora_entrada: horaEntrada, hora_salida: horaSalida } = values;

                useEffect(() => {
                    if (!horaEntrada || !horaSalida) return;

                    const minutos = differenceInMinutes(horaSalida, horaEntrada);
                    setFieldValue('minutos_as_fecha', addMinutes(setBeginOfDay(new Date()), minutos));
                }, [horaEntrada, horaSalida]);

                return (
                    <Grid container spacing={2}>
                        {subtareasOptions.length > 0 && (
                            <Grid item xs={12}>
                                <Field
                                    name='subtarea'
                                    id='subtarea'
                                    label='Tarea'
                                    fullWidth
                                    blankItem='No especificada'
                                    component={Autocomplete}
                                    options={subtareas}
                                    getOptionLabel={(option) => `${option.codigo} | ${option.titulo}`}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    renderInput={(params) => <MuiTextField {...params} label='Tarea' />}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} style={{ display: 'flex', gap: '16px' }}>
                            <MarcajeInfoField
                                variant='entrada'
                                label={
                                    <>
                                        <EntradaIcon /> Entrada
                                    </>
                                }
                                value={<KeyboardTimePicker name='hora_entrada' minutesStep={5} />}
                            />
                            <MarcajeInfoField
                                variant='salida'
                                label={
                                    <>
                                        <SalidaIcon /> Salida
                                    </>
                                }
                                value={<KeyboardTimePicker name='hora_salida' minutesStep={5} />}
                            />
                            <MarcajeInfoField
                                label='Tiempo'
                                value={
                                    <KeyboardTimePicker name='minutos_as_fecha' disabled={horaEntrada || horaSalida} />
                                }
                            />
                        </Grid>
                    </Grid>
                );
            }}
        </DialogForm>
    );
}

export default withButtonOpener(EditarMarcajeDialog);

EditarMarcajeDialog.propTypes = {
    fecha: PropTypes.any,
    marcaje: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    operarioId: PropTypes.any,
    subtareas: PropTypes.any,
    tareaId: PropTypes.any,
};
