import PreparacionMaterialItem from './PreparacionMaterialItem';
import SolicitudMaterialItem from './SolicitudMaterialItem';
import PropTypes from 'prop-types';

export default function PreparacionSolicitudItem(props) {
    const Component = props.item.type === 'PREPARACION' ? PreparacionMaterialItem : SolicitudMaterialItem;

    return <Component {...props} />;
}

PreparacionSolicitudItem.propTypes = {
    item: PropTypes.any,
};
