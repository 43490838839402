import withStyles from '@material-ui/core/styles/withStyles';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';

export const TableCell = withStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(0.5, 2),
            borderBottom: '1px solid #eee',
            whiteSpace: 'nowrap',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
            '.MuiTableRow-head:nth-of-type(2) &.MuiTableCell-head.MuiTableCell-stickyHeader': {
                top: 33,
            },
            '&.progress': {
                color: theme.palette.warning.main,
            },
            '&.done': {
                color: theme.palette.success.main,
            },
        },
        footer: {
            color: theme.palette.text.primary,
            fontWeight: 500,
        },
        sizeSmall: {
            '.MuiTableCell-body &:first-child': {
                paddingLeft: theme.spacing(0.5),
            },
            '.MuiTableCell-body &:last-child': {
                paddingRight: theme.spacing(0.5),
            },
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

export const TableRow = withStyles(
    (theme) => ({
        root: {
            '&:not(.MuiTableRow-head):hover': {
                backgroundColor: theme.palette.neutral.grey3,
            },
        },
    }),
    { name: 'TableRow' },
)(MuiTableRow);
