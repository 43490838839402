import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import NotasDialog from './NotasDialog';
import IconButton from '@material-ui/core/IconButton';
import NotasIcon from '../../icons/Notas';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '50px',
            paddingLeft: theme.spacing(2),
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
            '& .MuiIconButton-root': {
                marginLeft: 'auto',
                marginRight: theme.spacing(1),
            },
        },
        columns: {
            display: 'flex',
            gap: `${theme.spacing(3)}px`,
            padding: theme.spacing(1.5, 3),
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
            '& strong': {
                marginRight: theme.spacing(0.5),
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
    }),
    { name: 'FichaTecnicaEquipo' },
);

export default function FichaTecnicaEquipo({ equipo, notas }) {
    const classes = useStyles();

    return (
        <Paper elevation={0} className={classes.root}>
            <Typography variant='body2' className={classes.title}>
                <span>Ficha técnica del equipo</span>
                <NotasDialog
                    button={
                        <IconButton color={notas ? 'primary' : 'default'} disabled={!notas}>
                            <NotasIcon />
                        </IconButton>
                    }
                    notas={notas}
                />
            </Typography>
            <div className={classes.columns}>
                <div className={classes.column}>
                    <Typography variant='subtitle1'>
                        <strong>Nombre:</strong>
                        {equipo.nombre}
                    </Typography>
                    <Typography variant='subtitle1'>
                        <strong>Referencia:</strong>
                        {equipo.referencia}
                    </Typography>
                    <Typography variant='subtitle1'>
                        <strong>Ubicación:</strong>
                        {equipo.ubicacion}
                    </Typography>
                </div>
                <div className={classes.column}>
                    <Typography variant='subtitle1'>
                        <strong>Marca:</strong>
                        {equipo.marca}
                    </Typography>
                    <Typography variant='subtitle1'>
                        <strong>Num. de serie:</strong>
                        {equipo.numero_serie}
                    </Typography>
                    <Typography variant='subtitle1'>
                        <strong>Cliente:</strong>
                        {equipo.cliente}
                    </Typography>
                </div>
                <div className={classes.column}>
                    <Typography variant='subtitle1'>
                        <strong>Modelo:</strong>
                        {equipo.modelo}
                    </Typography>
                    <Typography variant='subtitle1'>
                        <strong>Proyecto:</strong>
                        {equipo.proyecto ? equipo.proyecto.nombre : 'Sin asignar'}
                    </Typography>
                </div>
            </div>
        </Paper>
    );
}

FichaTecnicaEquipo.propTypes = {
    equipo: PropTypes.any,
    notas: PropTypes.any,
};
