import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Alert from '@material-ui/lab/Alert';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import EditFormHeader from '../common/forms/EditForm/EditFormHeader';
import EditFormHeaderItem from '../common/forms/EditForm/EditFormHeaderItem';
import EditFormHeaderItemList from '../common/forms/EditForm/EditFormHeaderItemList';
import FormTabWrapper from '../common/forms/EditForm/FormTabWrapper';
import EditClienteDialog from './EditCuestionarioDialog';
import { EditCuestionarioMenuOptions } from './EditCuestionarioMenuOptions';
import SeccionList from './SeccionesList/SeccionList';

export function EditCuestionarioFormContent() {
    const { data, fetchData } = useEditFormData();

    return (
        <>
            <EditFormHeader
                label='Cuestionario'
                name={data.nombre}
                actions={
                    <>
                        <EditClienteDialog
                            button={
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            }
                            onSave={fetchData}
                        />
                        <EditCuestionarioMenuOptions />
                    </>
                }
            >
                <EditFormHeaderItemList>
                    <EditFormHeaderItem label='Referencia'>{data.referencia}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Descripción'>{data.descripcion}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Usado en servicios de mantenimiento'>
                        {data.has_partes_mantenimiento ? 'Si' : 'No'}
                    </EditFormHeaderItem>
                    <EditFormHeaderItem label='Activo'>{data.activo ? 'Si' : 'No'}</EditFormHeaderItem>
                </EditFormHeaderItemList>
            </EditFormHeader>

            <FormTabWrapper>
                {data.has_partes_mantenimiento ? (
                    <Alert variant='filled' severity='warning' style={{ marginTop: 24 }}>
                        No se pueden editar las preguntas de este cuestionario porque está en uso en algun parte de
                        mantenimiento. Si quieres editarlo, puedes duplicarlo y editar la copia.
                    </Alert>
                ) : null}
                <SeccionList />
            </FormTabWrapper>
        </>
    );
}
