import { useEffect, useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { tareasProvider } from '../../api';
import DialogForm from '../common/forms/DialogForm';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { Field } from 'formik';
import { SwitchWithLabel } from '../common/fields/Switch';
import useAuthState from '../../AuthState';

const PreciosGastosSchema = Yup.object().shape({
    precio_coste_parking: Yup.number().min(0).required('Requerido'),
    precio_venta_parking: Yup.number().min(0).required('Requerido'),
    precio_coste_desplazamiento: Yup.number().min(0).required('Requerido'),
    precio_venta_desplazamiento: Yup.number().min(0).required('Requerido'),
    precio_coste_dietas: Yup.number().min(0).required('Requerido'),
    precio_venta_dietas: Yup.number().min(0).required('Requerido'),
});

function PreciosGastosDialog({ tareaId, ...props }) {
    const [preciosGastos, setPreciosGastos] = useState(null);

    const {
        userInfo: { preferencias },
    } = useAuthState();

    useEffect(() => {
        if (!props.open) return;

        tareasProvider.getPreciosGastos(tareaId).then(setPreciosGastos);
    }, [props.open, tareaId]);

    return (
        <DialogForm
            title='Editar precios de los gastos'
            FormikProps={{
                initialValues: {
                    usar_preferencias: preciosGastos === null,
                    precio_coste_parking: preciosGastos?.precio_coste_parking ?? preferencias.precio_coste_parking,
                    precio_venta_parking: preciosGastos?.precio_venta_parking ?? preferencias.precio_venta_parking,
                    precio_coste_desplazamiento:
                        preciosGastos?.precio_coste_desplazamiento ?? preferencias.precio_coste_desplazamiento,
                    precio_venta_desplazamiento:
                        preciosGastos?.precio_venta_desplazamiento ?? preferencias.precio_venta_desplazamiento,
                    precio_coste_dietas: preciosGastos?.precio_coste_dietas ?? preferencias.precio_coste_dietas,
                    precio_venta_dietas: preciosGastos?.precio_venta_dietas ?? preferencias.precio_venta_dietas,
                },
                validationSchema: PreciosGastosSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    tareasProvider
                        .setPreciosGastos(tareaId, values)
                        .then((res) => {
                            props.onClose();
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ values }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Field
                            name='usar_preferencias'
                            component={SwitchWithLabel}
                            label='Utilizar los precios configurados en Ajustes'
                        />
                    </Grid>
                    {!values.usar_preferencias && (
                        <>
                            <Grid item xs={6}>
                                <TextField
                                    name='precio_coste_dietas'
                                    type='number'
                                    step='any'
                                    label='Precio coste dietas'
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'>€/ud</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name='precio_venta_dietas'
                                    type='number'
                                    step='any'
                                    label='Precio venta dietas'
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'>€/ud</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name='precio_coste_desplazamiento'
                                    type='number'
                                    step='any'
                                    label='Precio coste desplazamiento'
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'>€/ud</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name='precio_venta_desplazamiento'
                                    type='number'
                                    step='any'
                                    label='Precio venta desplazamiento'
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'>€/ud</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name='precio_coste_parking'
                                    type='number'
                                    step='any'
                                    label='Precio coste parking'
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'>€/ud</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name='precio_venta_parking'
                                    type='number'
                                    step='any'
                                    label='Precio venta parking'
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'>€/ud</InputAdornment>,
                                    }}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(PreciosGastosDialog);

PreciosGastosDialog.propTypes = {
    onClose: PropTypes.any,
    open: PropTypes.any,
    tareaId: PropTypes.any,
};
