import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { formatDate, formatDateTime, isAllDay } from '../../utils';
import clsx from 'clsx';
import isPast from 'date-fns/isPast';
import PropTypes from 'prop-types';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(
    (theme) => ({
        tooltip: {
            backgroundColor: theme.palette.neutral.hudBackground,
            opacity: 1,
            padding: theme.spacing(0),
            maxWidth: 'none',
        },
        tooltipArrow: {
            color: theme.palette.neutral.hudBackground,
        },
        label: {
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            '& svg': {
                fontSize: 18,
                marginRight: theme.spacing(0.25),
                color: theme.palette.neutral.primary,
            },
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                fontSize: 20,
            },
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightBold,
                flex: 1,
                marginLeft: theme.spacing(1),
            },
        },
        button: {
            color: 'white',
        },
        info: {
            display: 'flex',
            flexDirection: 'column',
            '& .MuiTypography-root strong': {
                fontWeight: theme.typography.fontWeightBold,
            },
        },
        planificacion: {
            padding: theme.spacing(1, 2),
            '&:not(:first-of-type)': {
                borderTop: `1px solid ${theme.palette.neutral.hudBackgroundDivider}`,
            },
            '& .caducado': {
                color: theme.palette.error.main,
            },
        },
        error: {
            color: theme.palette.error.main,
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                fontSize: 18,
                marginRight: theme.spacing(0.25),
                color: theme.palette.error.main,
            },
        },
    }),
    { name: 'OperarioInfoTooltip' },
);

export default function OperarioInfoTooltip({ operario, hideIfNoMostrado = false, ...props }) {
    const classes = useStyles();

    const hasError =
        operario.visible === false ||
        (operario.visible === undefined && operario.planificaciones.every((p) => !p.visible));

    if (hasError && hideIfNoMostrado) return null;

    return (
        <Tooltip
            arrow
            classes={{
                tooltip: classes.tooltip,
                arrow: classes.tooltipArrow,
            }}
            title={
                <div className={classes.info}>
                    {operario.planificaciones.map((planificacion, i) => {
                        const allDay = isAllDay(planificacion.fecha_inicio, planificacion.fecha_fin);
                        const formatFn = allDay ? formatDate : formatDateTime;

                        const caducado = isPast(new Date(planificacion.fecha_fin));

                        return (
                            <div key={i} className={classes.planificacion}>
                                <Typography variant='subtitle2'>
                                    <strong>Planificación:</strong> del {formatFn(planificacion.fecha_inicio)} al{' '}
                                    <span className={clsx({ caducado })}>{formatFn(planificacion.fecha_fin)}</span>
                                </Typography>
                                {!planificacion.visible && (
                                    <Typography variant='subtitle2' className={classes.error}>
                                        <VisibilityOffIcon />{' '}
                                        {planificacion.fecha_salida
                                            ? `Salió el ${formatDateTime(planificacion.fecha_salida)}`
                                            : 'No se muestra el servicio al operario'}
                                    </Typography>
                                )}
                            </div>
                        );
                    })}
                </div>
            }
            {...props}
        >
            <Typography variant='subtitle1' className={clsx(classes.label, hasError ? classes.error : null)}>
                <InfoIcon />
                {operario.nombre}
            </Typography>
        </Tooltip>
    );
}

OperarioInfoTooltip.propTypes = {
    hideIfNoMostrado: PropTypes.bool,
    operario: PropTypes.any,
};
