import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import addHours from 'date-fns/addHours';
import differenceInHours from 'date-fns/differenceInHours';
import isBefore from 'date-fns/isBefore';
import isValid from 'date-fns/isValid';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { isBeginOfDay, isEndOfDay, setBeginOfDay, setEndOfDay } from '../../../utils';
import { KeyboardDatePicker, KeyboardDateTimePicker } from './KeyboardDatePicker';

const useHasChanged = (val) => {
    const prevVal = usePrevious(val);
    return prevVal !== val;
};

const usePrevious = (value) => {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
};

export function DateRangePicker({
    label,
    allDay,
    disabled,
    fechaInicioKey,
    fechaFinKey,
    autoAdjustTime = false,
    helperText,
}) {
    const [, { value: fechaInicioValue, error: fechaInicioError }, { setValue: setFechaInicioValue }] =
        useField(fechaInicioKey);
    const [, { value: fechaFinValue, error: fechaFinError }, { setValue: setFechaFinValue }] = useField(fechaFinKey);

    const fechaInicioHasChanged = useHasChanged(fechaInicioValue);

    const PickerComponent = allDay ? KeyboardDatePicker : KeyboardDateTimePicker;

    const fromFormat = allDay ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm';
    const toFormat = allDay ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm';

    useEffect(() => {
        if (!fechaInicioValue || !fechaFinValue || !isValid(fechaInicioValue) || !isValid(fechaFinValue)) return;

        if (allDay) {
            if (!isBeginOfDay(fechaInicioValue)) {
                setFechaInicioValue(setBeginOfDay(fechaInicioValue));
            }

            if (!isEndOfDay(fechaFinValue)) {
                setFechaFinValue(setEndOfDay(fechaFinValue));
            }

            if (autoAdjustTime && isBefore(fechaFinValue, fechaInicioValue)) {
                // console.log(fechaInicioValue, fechaFinValue, setEndOfDay(new Date(fechaInicioValue)));
                setFechaFinValue(setEndOfDay(new Date(fechaInicioValue)));
            }
        } else {
            if (!fechaInicioHasChanged) {
                return;
            }

            if (autoAdjustTime && differenceInHours(fechaFinValue, fechaInicioValue) !== 1) {
                setFechaFinValue(addHours(fechaInicioValue, 1));
            }
        }
    }, [allDay, fechaInicioHasChanged, fechaInicioValue, fechaFinValue, setFechaInicioValue, setFechaFinValue]);

    return (
        <FormControl fullWidth error={Boolean(fechaInicioError || fechaFinError)}>
            {label && (
                <InputLabel shrink htmlFor='fecha-inicio'>
                    {label}
                </InputLabel>
            )}
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
                <PickerComponent
                    id='fecha-inicio'
                    name={fechaInicioKey}
                    format={fromFormat}
                    autoOk
                    ampm={false}
                    minutesStep={15}
                    disabled={disabled}
                    helperText={null}
                />
                <span style={{ margin: '0 16px' }}>a</span>
                <PickerComponent
                    name={fechaFinKey}
                    format={toFormat}
                    autoOk
                    ampm={false}
                    minutesStep={15}
                    disabled={disabled}
                    helperText={null}
                />
            </div>
            {fechaInicioError && <FormHelperText id='component-error-text'>{fechaInicioError}</FormHelperText>}
            {fechaFinError && <FormHelperText id='component-error-text'>{fechaFinError}</FormHelperText>}
            {helperText && <FormHelperText id='component-error-text'>{helperText}</FormHelperText>}
        </FormControl>
    );
}

DateRangePicker.propTypes = {
    allDay: PropTypes.any,
    autoAdjustTime: PropTypes.bool,
    disabled: PropTypes.any,
    fechaFinKey: PropTypes.any,
    fechaInicioKey: PropTypes.any,
    helperText: PropTypes.any,
    label: PropTypes.any,
};
