import { eventIcons, fichajeEstadoIcons } from './constants';
import { formatHora, formatTiempo } from '../../utils';
import { estadosFichajeLabels } from '../../api/fichajes';
import EntradaIcon from '../icons/Entrada';
import SalidaIcon from '../icons/Salida';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            '& svg': {
                fontSize: 12,
                marginRight: 2,
            },
        },
        resumen: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            '&>div': {
                display: 'flex',
                alignItems: 'center',
                lineHeight: '16px',
            },
        },
        title: {
            fontWeight: 500,
        },
        horas: {
            display: 'flex',
            flexDirection: 'column',
            '&>div': {
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                lineHeight: '16px',
            },
        },
    }),
    { name: 'EventFichaje' },
);

export function EventFichaje({ event }) {
    const classes = useStyles();
    const Icon = eventIcons[event.icon];

    const EstadoIcon = fichajeEstadoIcons[event.estado];

    return (
        <div className={classes.root}>
            <div className={classes.resumen}>
                <div>
                    <Icon />
                    <div className={classes.title}>{formatTiempo(event.tiempo)}</div>
                </div>
                <div>
                    <EstadoIcon />
                    <div>{estadosFichajeLabels[event.estado]}</div>
                </div>
            </div>
            <div className={classes.horas}>
                <div>
                    <EntradaIcon style={{}} />
                    <span>{formatHora(event.entrada)}</span>
                </div>
                <div>
                    <SalidaIcon style={{ fontSize: 12, marginRight: 2 }} />
                    <span>{event.salida ? formatHora(event.salida) : 'N/D'}</span>
                </div>
            </div>
        </div>
    );
}

EventFichaje.propTypes = {
    event: PropTypes.any,
};
