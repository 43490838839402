import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '../../common/Button';
import { companiesProvider } from '../../../api';
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { deepEqual } from '../../../utils';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '50px',
            paddingLeft: theme.spacing(3),
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
            '& .MuiIconButton-root': {
                marginLeft: 'auto',
                marginRight: theme.spacing(1),
            },
        },
        content: {
            padding: theme.spacing(2, 3),
            '& div': {
                display: 'flex',
                alignItems: 'center',
                gap: `${theme.spacing(1)}px`,
            },
            '& strong': {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        dualSwitch: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(1)}px`,
            margin: 'auto',
            width: 'fit-content',
        },
        calculadora: {
            '&>div': {
                display: 'flex',
                alignItems: 'center',
            },
            '& div .MuiTypography-root:first-of-type': {
                flex: 1,
            },
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightMedium,
            },
            '& .MuiOutlinedInput-input': {
                textAlign: 'center',
            },
            '& .MuiTypography-h3': {
                fontWeight: theme.typography.fontWeightBold,
            },
            '& .MuiTypography-subtitle1': {
                fontWeight: theme.typography.fontWeightMedium,
                color: theme.palette.neutral.primary,
            },
            '& $title': {
                justifyContent: 'center',
                fontWeight: 600,
            },
        },
        numOperarios: {
            width: 90,
        },
        codigoPromocional: {
            width: 200,
        },
        divider: {
            background: theme.palette.divider,
            height: 1,
            padding: 0,
            margin: theme.spacing(2, 1),
            flex: 1,
        },
        button: {
            width: '100%',
        },
    }),
    { name: 'CalculadoraButton' },
);
export default function CalculadoraButton({ hasTarifa }) {
    const classes = useStyles();

    const [simulacion, setSimulacion] = useState(false);
    const { values, setSubmitting, setFieldError, initialValues } = useFormikContext();

    const changed = !deepEqual(values, initialValues);

    const {
        numero_licencias: numeroLicencias,
        pago_mensual: pagoMensual,
        codigo_promocional: codigoPromocional,
        importe,
    } = values;

    useEffect(() => {
        setSimulacion(false);
    }, [numeroLicencias, pagoMensual, codigoPromocional, importe]);

    return simulacion ? (
        <>
            <span className={classes.divider} />
            <Grid item xs={12}>
                <Typography variant='h3'>Precio total</Typography>
                <Typography variant='h3'>{simulacion.tarifa.importe.toFixed(2)} €/mes</Typography>
            </Grid>
            {!pagoMensual && (
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>Facturado anualmente</Typography>
                    <Typography variant='subtitle1'>{(simulacion.tarifa.importe * 12).toFixed(2)} €/año</Typography>
                </Grid>
            )}
            {simulacion.change && (
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>
                        Al actualizar tu suscripción, te facturaremos {simulacion.change.importe.toFixed(2)} €
                        correspondientes al incremento de tarifa, prorrateado por lo que te queda hasta el próximo
                        periodo de facturación.
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12} style={{ flexDirection: 'column' }}>
                <Button type='submit' color='info' className={classes.button} style={{ height: 50 }}>
                    {hasTarifa ? 'Actualizar' : 'Activar'} suscripción
                </Button>
            </Grid>
        </>
    ) : (
        <Grid item xs={12} style={{ flexDirection: 'column' }}>
            <Button
                color='info'
                className={classes.button}
                style={{ height: 50 }}
                disabled={!changed}
                onClick={() => {
                    setSubmitting(true);
                    companiesProvider
                        .getAll('profile/tarifa/simulate', {
                            method: 'post',
                            body: JSON.stringify(values),
                        })
                        .then((tarifa) => {
                            setSubmitting(false);
                            setSimulacion(tarifa);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                }}
            >
                Calcular tarifa
            </Button>
        </Grid>
    );
}

CalculadoraButton.propTypes = {
    hasTarifa: PropTypes.any,
};
