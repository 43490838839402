import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

export const MASTER_ITEM = 'MasterItem';
export const MASTER_ITEM_SELECTED = 'MasterItem-selected';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            [`&.${MASTER_ITEM}`]: {
                display: 'flex',
                flexDirection: 'column',
                padding: theme.spacing(1.5),
                gap: `${theme.spacing(1)}px`,
                '&>svg': {
                    fontSize: 22,
                },
                boxSizing: 'border-box',
                borderLeft: '4px solid transparent',
                '&:hover': {
                    backgroundColor: theme.palette.neutral.backgroundPrimary,
                    borderColor: theme.palette.neutral.grey4,
                    cursor: 'pointer',
                },
                [`&.${MASTER_ITEM_SELECTED}`]: {
                    borderColor: theme.palette.primary.main,
                    backgroundColor: theme.palette.primary.backgroundTint,
                },
            },
        },
        header: {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightMedium,
            lineHeight: '35px',
            paddingLeft: theme.spacing(2),
            backgroundColor: theme.palette.neutral.backgroundPrimary,
        },
        title: {
            display: 'flex',
            alignItems: 'flex-start',
            gap: `${theme.spacing(1.5)}px`,
            '&>.MuiTypography-root': {
                flex: 1,
                color: theme.palette.text.primary,
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        labels: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
        },
        actions: {
            display: 'flex',
            alignItems: 'center',
            marginTop: -theme.spacing(0.5),
            marginRight: -theme.spacing(0.5),
            marginBottom: 'auto',
            gap: `${theme.spacing(0.5)}px`,
            '& .MuiIconButton-root': {
                padding: theme.spacing(0.5),
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        menuPaper: {
            borderRadius: 2,
        },
    }),
    { name: 'MasterItem' },
);

export default function MasterItem({
    title,
    header,
    isSelected,
    navigate = () => {},
    actions,
    labels,
    classes: overrideClasses = {},
}) {
    const classes = useStyles();
    const ref = useRef();

    useEffect(() => {
        if (!isSelected) return;

        ref.current.scrollIntoView();
    }, [ref.current]);

    return (
        <div ref={ref}>
            {header && (
                <Typography variant='body2' className={classes.header}>
                    {header}
                </Typography>
            )}
            <Paper
                elevation={0}
                square
                className={clsx(
                    classes.root,
                    MASTER_ITEM,
                    { [MASTER_ITEM_SELECTED]: isSelected },
                    overrideClasses.root,
                )}
                onClick={navigate}
            >
                <div className={clsx(classes.title, overrideClasses.titleWrapper)}>
                    <Typography variant='body2' className={overrideClasses.title}>
                        {title}
                    </Typography>
                    <div className={classes.actions}>{actions}</div>
                </div>
                {labels && <div className={classes.labels}>{labels}</div>}
            </Paper>
        </div>
    );
}

MasterItem.propTypes = {
    title: PropTypes.string.isRequired,
    header: PropTypes.string,
    isSelected: PropTypes.bool,
    navigate: PropTypes.func,
    actions: PropTypes.node,
    labels: PropTypes.node,
    classes: PropTypes.object,
};
