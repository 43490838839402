import { Paper } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { solicitudesProvider } from '../../../api';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';

const DenseTable = withStyles(
    (theme) => ({
        root: {
            margin: theme.spacing(1, 0),
        },
    }),
    { name: 'DenseTable' },
)(MuiTable);

const DenseTableCell = withStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(0, 2),
            '&:last-of-type': {
                width: 100,
            },
        },
    }),
    { name: 'DenseTableCell' },
)(MuiTableCell);

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '50px',
            paddingLeft: theme.spacing(2),
        },
        dividerRow: {
            '& td': {
                padding: 0,
                border: 'none',
            },
        },
        emptyRow: {
            '& td': {
                color: theme.palette.neutral.primary,
            },
            '& td:not(:first-of-type)': {
                border: 'none',
            },
        },
        actions: {
            whiteSpace: 'nowrap',
            textAlign: 'right',
            border: 'none',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
    }),
    { name: 'DiasVacacionesDetails' },
);

export default function DiasVacacionesDetails({ index }) {
    const classes = useStyles();
    const { id } = useParams();

    const { data: dias, updateLocalData } = useEditFormData(`dias_vacaciones.${index}`);

    useEffect(() => {
        if (dias.resumen) return;

        solicitudesProvider.getResumen(dias.year, id).then((resumen) => updateLocalData('resumen', resumen));
    }, [dias.resumen]);

    const resumen = dias.resumen;

    if (!resumen) return null;

    return (
        <>
            <Paper elevation={0} className={classes.root}>
                <Typography variant='body2' className={classes.title}>
                    Resúmen de vacaciones
                </Typography>
                <DenseTable size='small' style={{ marginTop: 8 }}>
                    <TableBody>
                        <TableRow>
                            <DenseTableCell>Días disponibles</DenseTableCell>
                            <DenseTableCell>{resumen.vacaciones.dias.disponibles}</DenseTableCell>
                        </TableRow>
                        <TableRow>
                            <DenseTableCell>Días totales</DenseTableCell>
                            <DenseTableCell>{resumen.vacaciones.dias.totales}</DenseTableCell>
                        </TableRow>
                    </TableBody>
                </DenseTable>
            </Paper>
            <Paper elevation={0} className={classes.root}>
                <Typography variant='body2' className={classes.title}>
                    Solicitudes de vacaciones
                </Typography>
                <DenseTable size='small' style={{ marginTop: 8 }}>
                    <TableBody>
                        <TableRow>
                            <DenseTableCell>Pendientes</DenseTableCell>
                            <DenseTableCell>{resumen.vacaciones.solicitudes.pendientes}</DenseTableCell>
                        </TableRow>
                        <TableRow>
                            <DenseTableCell>Aprobadas</DenseTableCell>
                            <DenseTableCell>{resumen.vacaciones.solicitudes.aprobadas}</DenseTableCell>
                        </TableRow>
                        <TableRow>
                            <DenseTableCell>Rechazadas</DenseTableCell>
                            <DenseTableCell>{resumen.vacaciones.solicitudes.rechazadas}</DenseTableCell>
                        </TableRow>
                        <TableRow>
                            <DenseTableCell>Totales</DenseTableCell>
                            <DenseTableCell>{resumen.vacaciones.solicitudes.totales}</DenseTableCell>
                        </TableRow>
                    </TableBody>
                </DenseTable>
            </Paper>
            <Paper elevation={0} className={classes.root}>
                <Typography variant='body2' className={classes.title}>
                    Ausencias
                </Typography>
                <DenseTable size='small' style={{ marginTop: 8 }}>
                    <TableBody>
                        <TableRow>
                            <DenseTableCell>Ausencias realizadas</DenseTableCell>
                            <DenseTableCell>{resumen.ausencias.realizadas}</DenseTableCell>
                        </TableRow>
                    </TableBody>
                </DenseTable>
            </Paper>
        </>
    );
}

DiasVacacionesDetails.propTypes = {
    index: PropTypes.any.isRequired,
};
