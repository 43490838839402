import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import React from 'react';

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };
const placesService = { current: null };

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

export default function GoogleMapsAutocomplete({ label, onChange, placeholder }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);
    const sessionToken = React.useRef(null);
    const snackbar = useSnackbar();

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                'https://maps.googleapis.com/maps/api/js?key=AIzaSyCfYUgBVjoDuc1tB7b9sbCJhGw6wsdpQ6A&libraries=places',
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }

        if (!placesService.current && window.google) {
            placesService.current = new window.google.maps.places.PlacesService(document.createElement('div'));
        }

        if (!autocompleteService.current || !placesService.current) {
            console.warn('Autocomplete and Places services not loaded yet');
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        if (!sessionToken.current) {
            sessionToken.current = new window.google.maps.places.AutocompleteSessionToken();
            // console.log('New session token', sessionToken.current);
        }

        fetch(
            {
                input: inputValue,
                componentRestrictions: { country: 'es' },
                sessionToken: sessionToken.current,
            },
            (results) => {
                if (active) {
                    let newOptions = [];

                    if (value) {
                        newOptions = [value];
                    }

                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }

                    setOptions(newOptions);
                }
            },
        );

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <Autocomplete
            id='google-map-demo'
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            fullWidth
            value={value}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);

                if (!placesService.current || !placesService.current.getDetails) return;

                if (newValue == null) {
                    return;
                }

                // console.log(newValue);

                placesService.current.getDetails(
                    {
                        placeId: newValue.place_id,
                        fields: ['address_components', 'geometry.location'],
                        sessionToken: sessionToken.current,
                    },
                    ({ address_components: addressComponents, geometry: { location } = {} }, status) => {
                        const coords = location ? `${location.lat()}, ${location.lng()}` : null;

                        sessionToken.current = null;

                        if (status !== 'OK') {
                            snackbar.showMessage('Ha ocurrido un error al consultar datos de Google Maps');
                            return;
                        }

                        const componentTypes = [
                            'town_square',
                            'route',
                            'street_number',
                            'locality',
                            'postal_code',
                            'administrative_area_level_2',
                        ];

                        const address = Object.fromEntries(
                            componentTypes.map((componentType) => {
                                const component = addressComponents.find((c) => c.types.includes(componentType));

                                return [componentType, component ? component.long_name : null];
                            }),
                        );

                        if (address.route === null && address.town_square === null) {
                            address.route = newValue.structured_formatting.main_text;

                            // snackbar.showMessage('No se ha podido usar esta dirección');
                            // onChange(null);
                            // return;
                        }

                        let direccion = address.route ?? address.town_square;

                        if (address.street_number) {
                            direccion = `${direccion}, ${address.street_number}`;
                        }

                        onChange({
                            placeId: newValue.place_id,
                            address: {
                                direccion,
                                codigo_postal: address.postal_code ?? '',
                                poblacion: address.locality ?? '',
                                provincia: address.administrative_area_level_2 ?? '',
                                direccion_secundaria: '',
                                coords,
                            },
                        });
                    },
                );
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    placeholder={placeholder}
                    {...params}
                    label={label}
                    fullWidth
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                        form: {
                            autocomplete: 'off',
                        },
                    }}
                    InputLabelProps={{
                        shrink: Boolean(placeholder),
                    }}
                />
            )}
            renderOption={(option) => {
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts =
                    matches && matches.length > 0
                        ? parse(
                              option.structured_formatting.main_text,
                              matches.map((match) => [match.offset, match.offset + match.length]),
                          )
                        : null;

                return (
                    <Grid container alignItems='center'>
                        <Grid item>
                            <LocationOnIcon className={classes.icon} />
                        </Grid>
                        <Grid item xs>
                            {parts
                                ? parts.map((part, index) => (
                                      <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                          {part.text}
                                      </span>
                                  ))
                                : option.structured_formatting.main_text}

                            <Typography variant='body2' color='textSecondary'>
                                {option.structured_formatting.secondary_text}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
}

GoogleMapsAutocomplete.propTypes = {
    label: PropTypes.any,
    onChange: PropTypes.any,
    placeholder: PropTypes.any,
};
