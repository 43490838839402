import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { companiesProvider } from '../../../../../api';
import { useFetchData } from '../../../../../hooks/useFetchData';
import Button from '../../../../common/Button';
import { ToggleButton, ToggleButtonGroup } from '../../../../common/ToggleButtonGroup';
import { precioViews, VENTA } from '../../../../servicios/ParteTrabajoList/ParteTrabajoDetails';
import AjustesCategoriasOperario from './CategoriasOperario/AjustesCategoriasOperario';
import AjustesTiposHora from './TiposHora/AjustesTiposHora';

const TableCell = withStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(0.5, 2),
            borderBottom: '1px solid #eee',
            whiteSpace: 'nowrap',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
            '.MuiTableRow-head:nth-of-type(2) &.MuiTableCell-head.MuiTableCell-stickyHeader': {
                top: 33,
            },
        },
        footer: {
            color: theme.palette.text.primary,
            fontWeight: 500,
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

const TableRow = withStyles(
    (theme) => ({
        root: {
            '&:not(.empty):hover': {
                backgroundColor: theme.palette.neutral.grey3,
            },
        },
    }),
    { name: 'TableRow' },
)(MuiTableRow);

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '50px',
            padding: theme.spacing(0, 2),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            padding: theme.spacing(2),
        },
    }),
    { name: 'AjustesCategoriasPrecios' },
);

export default function AjustesCategoriasPrecios() {
    const classes = useStyles();
    const [currentView, setCurrentView] = useState(VENTA);

    const categoriasFetchDataFn = useCallback(() => companiesProvider.getCategoriasOperario(), []);
    const { data: categorias, fetchData: fetchCategorias } = useFetchData(categoriasFetchDataFn);

    const tiposHoraFetchDataFn = useCallback(() => companiesProvider.getTiposHora(), []);
    const { data: tiposHora, fetchData: fetchTiposHora } = useFetchData(tiposHoraFetchDataFn);

    const preciosFetchDataFn = useCallback(() => companiesProvider.getOperarioPrecios(), []);
    const { data: precios, fetchData: fetchPrecios } = useFetchData(preciosFetchDataFn);

    useEffect(() => {
        fetchCategorias();
        fetchTiposHora();
        fetchPrecios();
    }, [fetchCategorias, fetchTiposHora, fetchPrecios]);

    const groupedPreciosByCategoriaAndTipoHora = useMemo(
        () =>
            Object.fromEntries(
                categorias.map((categoria) => {
                    const categoriaPrecios = Object.fromEntries(
                        tiposHora.map((tipoHora) => {
                            const precio = precios.find(
                                (precio) =>
                                    precio.categoria_operario_id === categoria.id &&
                                    precio.tipo_hora_id === tipoHora.id,
                            );

                            return [
                                tipoHora.id,
                                {
                                    precio_coste: precio ? precio.precio_coste : 0,
                                    precio_venta: precio ? precio.precio_venta : 0,
                                },
                            ];
                        }),
                    );
                    return [categoria.id, categoriaPrecios];
                }),
            ),
        [precios, categorias, tiposHora],
    );

    const fieldName = currentView === VENTA ? 'precio_venta' : 'precio_coste';
    return (
        <>
            <AjustesCategoriasOperario data={categorias} fetchData={fetchCategorias} />
            <AjustesTiposHora data={tiposHora} fetchData={fetchTiposHora} />

            <Formik
                initialValues={{
                    precios: groupedPreciosByCategoriaAndTipoHora,
                }}
                enableReinitialize
                onSubmit={(values, { setFieldError, setSubmitting }) => {
                    companiesProvider
                        .updateOperarioPrecios(values.precios)
                        .then(() => {})
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({ values: { precios }, isSubmitting, setFieldValue, submitForm }) => (
                    <Paper elevation={0} className={classes.root}>
                        <Typography variant='body1' component='div' className={classes.title}>
                            Precios de operarios
                            <ToggleButtonGroup
                                value={currentView}
                                exclusive
                                onChange={(ev, view) => setCurrentView(view)}
                            >
                                {precioViews.map(({ id, label }) => (
                                    <ToggleButton key={id} value={id}>
                                        {label}
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        </Typography>

                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    {categorias.map((categoria) => (
                                        <TableCell key={categoria.id}>{categoria.nombre}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tiposHora.map((tipoHora) => (
                                    <TableRow key={tipoHora.id}>
                                        <TableCell>{tipoHora.nombre}</TableCell>
                                        {categorias.map((categoria) => {
                                            return (
                                                <TableCell key={categoria.id}>
                                                    <TextField
                                                        name={`precios.${categoria.id}.${tipoHora.id}.${fieldName}`}
                                                        type='number'
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position='end'>€</InputAdornment>
                                                            ),
                                                        }}
                                                        variant='outlined'
                                                    />
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <div className={classes.actions}>
                            <Button onClick={submitForm} color='info'>
                                Guardar
                            </Button>
                        </div>
                    </Paper>
                )}
            </Formik>
        </>
    );
}
