import PropTypes from 'prop-types';
import { useMemo } from 'react';
import useAuthState from '../../../AuthState';
import { formatTiempo } from '../../../utils';
import ViewToggler from '../../solicitudes/ViewToggler';
import PaperTable from './PaperTable';

export default function ManoObraPaper({ rows, desglosado, setDesglosado, totales }) {
    const {
        userInfo: {
            preferencias: { usar_precios: usarPrecios },
        },
    } = useAuthState();

    const columns = useMemo(
        () => [
            {
                label: 'Operario',
                renderCell: (row) => row.operario,
                footer: 'Total',
            },
            {
                label: 'Tiempo',
                renderCell: (row) => formatTiempo(row.tiempo),
                isNumber: true,
            },
            ...(usarPrecios
                ? [
                      {
                          label: 'Precio coste',
                          renderCell: (row) => `${row.precioCoste.toFixed(2)} €/h`,
                          isNumber: true,
                          footer: null,
                      },
                      {
                          label: 'Precio venta',
                          renderCell: (row) => `${row.precioVenta.toFixed(2)} €/h`,
                          isNumber: true,
                          footer: null,
                      },
                      {
                          label: 'Total coste',
                          renderCell: (row) => `${row.importeCoste.toFixed(2)} €`,
                          isNumber: true,
                      },
                      {
                          label: 'Total venta',
                          renderCell: (row) => `${row.importeVenta.toFixed(2)} €`,
                          isNumber: true,
                      },
                  ]
                : []),
        ],
        [usarPrecios],
    );

    return (
        <PaperTable
            columns={columns}
            title='Mano de obra'
            rows={rows}
            footerRow={totales}
            titleActions={
                <ViewToggler
                    views={[
                        {
                            key: 'agrupado',
                            label: 'Agrupado',
                        },
                        {
                            key: 'desglosado',
                            label: 'Desglosado',
                        },
                    ]}
                    initialView='agrupado'
                    onViewChange={(view) => setDesglosado(view === 'desglosado')}
                />
            }
        />
    );
}

ManoObraPaper.propTypes = {
    rows: PropTypes.any,
    totales: PropTypes.any,
    desglosado: PropTypes.any,
    setDesglosado: PropTypes.any,
};
