import PropTypes from 'prop-types';
import { FilterInput } from '../../common/list/FilterInput';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';

export default function OperariosColumnFilter({
    column: {
        filterValue,
        preFilteredRows,
        setFilter,
        filterOptions: { hideOperariosExtra, setHideOperariosExtra },
    },
}) {
    const count = preFilteredRows.length;

    return (
        <>
            <FilterInput
                value={filterValue || ''}
                onChange={(e) => {
                    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
                placeholder={`Buscar en ${count} filas...`}
                endAdornment={
                    <InputAdornment position='end'>
                        <Tooltip
                            title={
                                hideOperariosExtra
                                    ? 'Mostrar todos los operarios'
                                    : 'Ocultar los operarios que no ven el servicio'
                            }
                        >
                            <IconButton size='small' onClick={() => setHideOperariosExtra((h) => !h)}>
                                {hideOperariosExtra ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                }
            />
        </>
    );
}

OperariosColumnFilter.propTypes = {
    column: PropTypes.any,
};
