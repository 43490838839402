import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { preparacionesMaterialProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import DialogForm from '../../common/forms/DialogForm';

const PreparacionMaterialSchema = Yup.object().shape({
    lista_material: Yup.string(),
});

function NuevaPreparacionMaterialDialog({ onSave, tareaId, ...props }) {
    return (
        <DialogForm
            title='Añadir preparación de material'
            FormikProps={{
                initialValues: {
                    lista_material: '',
                },
                validationSchema: PreparacionMaterialSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    preparacionesMaterialProvider
                        .addToTarea(tareaId, values)
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name='lista_material'
                            label='Lista de material'
                            fullWidth
                            multiline
                            minRows={6}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(NuevaPreparacionMaterialDialog);

NuevaPreparacionMaterialDialog.propTypes = {
    tareaId: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
