import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { presupuestosProvider } from '../../../api';
import Button from '../../common/Button';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterDetailsView from '../../common/forms/MasterDetailsView/MasterDetailsView';
import NewPresupuestoDialog from '../../presupuestos/NewPresupuestoDialog';
import PresupuestoDetails from './PresupuestoDetails';
import PresupuestoItem from './PresupuestoItem';
import ProyectoPresupuestoDetails from './ProyectoPresupuestoDetails';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: `${theme.spacing(3)}px`,
            '&>div': {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: `${theme.spacing(2)}px`,
            },
        },
        actions: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        empty: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            margin: theme.spacing(6, 'auto'),
            gap: `${theme.spacing(3)}px`,
            width: 430,
        },
        emptyText: {
            color: theme.palette.neutral.grey4,
        },
    }),
    { name: 'PresupuestosList' },
);

export default function PresupuestosList() {
    const classes = useStyles();
    const { data: proyecto, fetchData } = useEditFormData();

    const items = [];

    if (proyecto.presupuestos.length > 1)
        items.push({
            id: 'resumen',
            nombre: 'Resúmen',
        });

    items.push(...proyecto.presupuestos);

    return (
        <MasterDetailsView
            title='Presupuestos'
            masterComponent={PresupuestoItem}
            masterExtraProps={(presupuesto, index) => ({
                onDelete: () => {
                    presupuestosProvider.delete(presupuesto.id).then(fetchData);
                },
            })}
            detailsComponent={proyecto.presupuestos.length > 1 ? ProyectoPresupuestoDetails : PresupuestoDetails}
            items={items}
            isEmpty={proyecto.presupuestos.length === 0}
            emptyComponent={
                <Paper elevation={0}>
                    <div className={classes.empty}>
                        <img src='/images/empty-partes-trabajo.png' />
                        <Typography variant='h1'>Presupuestos</Typography>
                        <Typography variant='body2' className={classes.emptyText}>
                            Aquí puedes añadir presupuestos al proyecto.
                        </Typography>
                        <NewPresupuestoDialog
                            clienteId={proyecto.cliente_id}
                            direccionId={proyecto.direccion_id}
                            proyectoId={proyecto.id}
                            button={<Button color='info'>Añadir presupuesto</Button>}
                            onSave={fetchData}
                        />
                    </div>
                </Paper>
            }
            actions={
                <NewPresupuestoDialog
                    clienteId={proyecto.cliente_id}
                    direccionId={proyecto.direccion_id}
                    proyectoId={proyecto.id}
                    button={
                        <IconButton>
                            <AddIcon />
                        </IconButton>
                    }
                    onSave={fetchData}
                />
            }
        />
    );
}

PresupuestosList.propTypes = {};
