import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useFormikContext } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { ADMINISTRATIVO, tipoOptions } from '../../../api/operarios';
import CategoriaOperarioField from '../../common/fields/CategoriaOperarioField';
import { KeyboardDatePicker } from '../../common/fields/KeyboardDatePicker';
import RadioGroupField from './RadioGroupField';

export default function NuevoDatosGeneralesForm({ tipo, hidden }) {
    const hiddenStyle = hidden ? { display: 'none' } : {};
    const { values } = useFormikContext();

    return (
        <>
            <Grid container spacing={2} style={hiddenStyle}>
                <Grid item xs={12}>
                    <RadioGroupField name='tipo' label='Tipo de empleado' options={tipoOptions} />
                </Grid>
                <Grid item xs={6}>
                    <TextField name='codigo' label='Codigo' fullWidth type='number' />
                </Grid>
                <Grid item xs={6}>
                    <TextField name='nombre' label='Nombre' fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextField name='telefono' label='Telefono' fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextField name='user.email' label='Email' fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextField name='nif' label='NIF' fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextField name='naf' label='NAF' fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <KeyboardDatePicker
                        name='fecha_alta'
                        label='Fecha de alta'
                        format={'dd/MM/yyyy'}
                        fullWidth
                        autoOk
                    />
                </Grid>
                <Grid item xs={6}>
                    <KeyboardDatePicker
                        name='fecha_baja'
                        label='Fecha de baja'
                        format={'dd/MM/yyyy'}
                        fullWidth
                        autoOk
                    />
                </Grid>
                {tipo !== ADMINISTRATIVO && (
                    <Grid item xs={6}>
                        <CategoriaOperarioField />
                    </Grid>
                )}
                {tipo !== ADMINISTRATIVO && !values.categoria_operario && (
                    <>
                        <Grid item xs={3}>
                            <TextField
                                name='precio_coste'
                                type='number'
                                step='any'
                                label='Precio coste'
                                fullWidth
                                InputProps={{
                                    endAdornment: <InputAdornment position='end'>€/h</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                name='precio_venta'
                                type='number'
                                step='any'
                                label='Precio venta'
                                fullWidth
                                InputProps={{
                                    endAdornment: <InputAdornment position='end'>€/h</InputAdornment>,
                                }}
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <CheckboxWithLabel type='checkbox' name='activo' Label={{ label: 'Activo' }} />
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 24, ...hiddenStyle }}>
                <Grid item xs={12}>
                    <TextField name='user.username' label='Nombre de usuario' fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextField name='user.password' label='Contraseña' type='password' fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextField name='user.confirm_password' label='Confirmar contraseña' type='password' fullWidth />
                </Grid>
            </Grid>
        </>
    );
}

NuevoDatosGeneralesForm.fieldNames = [
    'codigo',
    'nombre',
    'telefono',
    'tipo',
    'nif',
    'naf',
    'activo',
    'user.email',
    'user.username',
    'user.password',
    'user.confirm_password',
    'categoria_operario',
    'fecha_alta',
    'fecha_baja',
    'precio_coste',
    'precio_venta',
];

NuevoDatosGeneralesForm.propTypes = {
    tipo: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
};
