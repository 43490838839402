import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { tareasProvider } from '../../../api';
import NewJornadaDialog from '../../clientes/jornadas/NewJornadaDialog';
import Button from '../../common/Button';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterDetailsView from '../../common/forms/MasterDetailsView/MasterDetailsView';
import ParteTrabajoDetails from './ParteTrabajoDetails';
import ParteTrabajoItem from './ParteTrabajoItem';
import ParteTrabajoListFilters from './ParteTrabajoListFilters';
import ParteTrabajoListOptions from './ParteTrabajoListOptions';
import usePreciosGastos from './usePreciosGastos';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: `${theme.spacing(3)}px`,
            '&>div': {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: `${theme.spacing(2)}px`,
            },
        },
        actions: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        empty: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            margin: theme.spacing(6, 'auto'),
            gap: `${theme.spacing(3)}px`,
            width: 430,
        },
        emptyText: {
            color: theme.palette.neutral.grey4,
        },
    }),
    { name: 'ParteTrabajoList' },
);

export default function ParteTrabajoList() {
    const { data: tarea, updateData, fetchData } = useEditFormData();

    return (
        <BaseParteTrabajoList
            jornadas={tarea.jornadas}
            updateData={updateData}
            fetchData={fetchData}
            tareaId={tarea.id}
        />
    );
}

export function BaseParteTrabajoList({ jornadas, updateData, fetchData, tareaId, itemTitleFn = null }) {
    const classes = useStyles();

    const preciosGastos = usePreciosGastos(tareaId);

    return (
        <MasterDetailsView
            title='Partes de trabajo'
            masterComponent={ParteTrabajoItem}
            masterExtraProps={(jornada, index) => ({
                showHeader: index === 0 || jornada.fecha !== jornadas[index - 1].fecha,
                onDelete: () => {
                    tareasProvider
                        .actionOnId(tareaId, 'jornadas', { ids: [jornada.id] }, { method: 'delete' })
                        .then(updateData);
                },
                titleFn: itemTitleFn,
            })}
            detailsComponent={ParteTrabajoDetails}
            detailsExtraProps={{ preciosGastos }}
            items={jornadas}
            isEmpty={jornadas.length === 0}
            emptyComponent={
                <Paper elevation={0}>
                    <div className={classes.empty}>
                        <img src='/images/empty-partes-trabajo.png' />
                        <Typography variant='h1'>Partes de trabajo</Typography>
                        <Typography variant='body2' className={classes.emptyText}>
                            Aquí recibirás los partes de trabajo que los operarios generen con su App móvil. También
                            podrás editarlos o añadir manualmente de forma rápida y sencilla. Para agilizar el proceso,
                            podrás agrupar los partes para generar albaranes de servicio.
                        </Typography>
                        {tareaId && (
                            <NewJornadaDialog
                                tareaId={tareaId}
                                button={<Button color='info'>Añadir parte de trabajo</Button>}
                                onSave={updateData}
                            />
                        )}
                    </div>
                </Paper>
            }
            actions={
                <>
                    <NewJornadaDialog
                        tareaId={tareaId}
                        button={
                            <IconButton>
                                <AddIcon />
                            </IconButton>
                        }
                        tooltip='Añadir parte de trabajo'
                        onSave={fetchData}
                    />
                    <ParteTrabajoListOptions
                        tareaId={tareaId}
                        canGenerarAlbaran={jornadas.some((jornada) => jornada.albaran_id === null)}
                        onUpdate={fetchData}
                    />
                </>
            }
            FilterComponent={ParteTrabajoListFilters}
        />
    );
}

BaseParteTrabajoList.propTypes = {
    jornadas: PropTypes.array.isRequired,
    updateData: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    tareaId: PropTypes.any,
    itemTitleFn: PropTypes.func,
};
