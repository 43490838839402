import DescriptionIcon from '@material-ui/icons/Description';
import isBefore from 'date-fns/isBefore';
import max from 'date-fns/max';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { usePreferencias } from '../../../AuthState';
import { formatDateTime, formatFullDate } from '../../../utils';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import ItemLabel from '../../common/forms/MasterDetailsView/ItemLabel';
import MasterItem from '../../common/forms/MasterDetailsView/MasterItem';
import AlbaranItemOptions from './AlbaranItemOptions';
import EnviadoLabel from './EnviadoLabel';
import FirmadoLabel from './FirmadoLabel';
import SincronizadoLabel from './SincronizadoLabel';

const FacturadoIcon = DescriptionIcon;

export default function AlbaranItem({ item, index, href, navigate, onDelete, showHeader = false }) {
    const location = useLocation();
    const usarDelsol = usePreferencias('usar_delsol');

    const { data: allJornadas, fetchData } = useEditFormData('jornadas');
    const jornadas = allJornadas.filter((jornada) => jornada.albaran_id === item.id);

    const isSelected = location.pathname === href;

    const numJornadasFacturadas = jornadas.filter((j) => j.facturada).length;
    const numJornadas = jornadas.length;

    const fechas = jornadas.map((jornada) => jornada.fecha_actualizacion).filter(Boolean);
    fechas.push(item.fecha_actualizacion);

    const lastUpdate = fechas.length > 0 ? max(fechas.map((f) => new Date(f))) : null;

    let error = null;
    if (!item.fecha_envio) error = 'Albarán no enviado a cliente';
    else if (isBefore(new Date(item.fecha_envio), lastUpdate))
        error = 'Albarán editado despues de enviarlo al cliente. Recuerda volverlo a enviar';

    return (
        <MasterItem
            icon={DescriptionIcon}
            title={`Albarán de servicio ${item.numero}`}
            subtitle={`Creado por ${item.firma ? item.firma.operario : 'administración'} el ${formatDateTime(
                item.fecha_creacion,
            )}`}
            navigate={navigate}
            header={showHeader && formatFullDate(item.fecha)}
            lastUpdate={lastUpdate}
            isSelected={isSelected}
            error={error}
            labels={
                <>
                    <FirmadoLabel value={item.firma} />
                    <EnviadoLabel value={item.fecha_envio} />
                    {numJornadasFacturadas > 0 && (
                        <ItemLabel
                            icon={<FacturadoIcon />}
                            label={
                                numJornadasFacturadas === numJornadas
                                    ? item.numero_factura
                                        ? `Fra. ${item.numero_factura}`
                                        : 'Facturado'
                                    : 'Facturado parcialmente'
                            }
                            tooltip={`Facturado el ${formatDateTime(item.fecha_facturacion)}`}
                        />
                    )}
                    {usarDelsol && <SincronizadoLabel value={item.fecha_ultima_sincronizacion} />}
                </>
            }
            actions={
                <AlbaranItemOptions
                    item={item}
                    numJornadas={numJornadas}
                    numJornadasFacturadas={numJornadasFacturadas}
                    onUpdate={fetchData}
                    onDelete={onDelete}
                />
            }
        />
    );
}

AlbaranItem.propTypes = {
    href: PropTypes.string.isRequired,
    index: PropTypes.any,
    item: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    showHeader: PropTypes.bool,
};
