import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { estadoLabels, estadoOptions } from '../../../api/companies';
import { formatDateTime } from '../../../utils';
import BaseListView, { withTableState } from '../../common/list/ListView';
import { SelectColumnFilter } from '../../common/list/SelectColumnFilter';

const ListView = withTableState('companiesStats', BaseListView);

export default function TableByDay({ data }) {
    const columns = useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'name',
            },
            {
                Header: 'Estado',
                accessor: 'estado',
                Cell: ({ value }) => estadoLabels[value],
                Filter: SelectColumnFilter,
                filter: 'equals',
                filterOptions: {
                    options: estadoOptions,
                    includeAllOption: {
                        value: 'todos',
                        label: 'Todos',
                    },
                },
                exportValue: ({ value }) => estadoLabels[value],
            },
            {
                Header: 'Ultimo login admin',
                accessor: 'stats.ultimo_login_administrador',
                Cell: ({ value }) => (value ? formatDateTime(value) : 'Sin fecha'),
            },
            {
                Header: 'Ultimo login operario',
                accessor: 'stats.ultimo_login_operario',
                Cell: ({ value }) => (value ? formatDateTime(value) : 'Sin fecha'),
            },
            {
                Header: 'Servicios creados',
                accessor: 'stats.num_tareas_creadas',
            },
            {
                Header: 'Planif. creadas',
                accessor: 'stats.num_planificaciones_creadas',
            },
            {
                Header: 'Partes creados',
                accessor: 'stats.num_jornadas_creadas',
            },
            {
                Header: 'Partes revisados',
                accessor: 'stats.num_jornadas_revisadas',
            },
            {
                Header: 'Albaranes creados',
                accessor: 'stats.num_albaranes_creados',
            },
            {
                Header: 'Albaranes facturados',
                accessor: 'stats.num_albaranes_facturados',
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: false,
        }),
        [],
    );

    return (
        <ListView
            tableTitle='Lista de compañias'
            basePath='/companies'
            columns={columns}
            data={data}
            options={options}
        />
    );
}

TableByDay.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
};
