import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { createTiempo, getHoras } from '../../../../api/tareas-functions';
import { formatDateTime, formatTiempo } from '../../../../utils';
import { TableCell, TableRow } from '../../../presupuestos/PresupuestoView/Table';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '50px',
            paddingLeft: theme.spacing(2),
            display: 'flex',
            '& .MuiIconButton-root': {
                marginLeft: 'auto',
                marginRight: theme.spacing(2),
            },
        },
        dividerRow: {
            '& td': {
                padding: 0,
                border: 'none',
            },
        },
        emptyRow: {
            '& td': {
                color: theme.palette.neutral.primary,
            },
            '& td:not(:first-of-type)': {
                border: 'none',
            },
        },
        actions: {
            whiteSpace: 'nowrap',
            textAlign: 'right',
            border: 'none',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        completadoCell: {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                marginLeft: 4,
                marginBottom: -4,
            },
        },
    }),
    { name: 'ResumenChecklist' },
);

export default function TiempoTareaChecklist({ jornadas, items, contarTiempo = true, marcarCompletado = true }) {
    const classes = useStyles();

    const { tiempoPorSubtarea, tiempoTotal } = useMemo(() => {
        const tiempoPorSubtarea = {};
        let tiempoTotal = 0;
        jornadas.forEach((jornada) => {
            jornada.marcajes
                .filter((m) => m.subtarea)
                .forEach((marcaje) => {
                    const subtareaId = marcaje.subtarea.id;
                    if (!tiempoPorSubtarea[subtareaId]) {
                        tiempoPorSubtarea[subtareaId] = 0;
                    }

                    tiempoTotal += marcaje.minutos;
                    tiempoPorSubtarea[subtareaId] += marcaje.minutos;
                });
        });

        Object.keys(tiempoPorSubtarea).forEach((subtareaId) => {
            tiempoPorSubtarea[subtareaId] = createTiempo(tiempoPorSubtarea[subtareaId]);
        });

        tiempoTotal = createTiempo(tiempoTotal);

        return { tiempoTotal, tiempoPorSubtarea };
    }, [jornadas]);

    const showCodigo = useMemo(() => items.some((item) => item.subtarea), [items]);

    function exportCSV() {
        const headers = [];

        if (showCodigo) headers.push('Código');
        headers.push('Tarea');
        if (contarTiempo) headers.push('Horas');
        if (marcarCompletado) headers.push('Completado');

        const separator = ';';

        const data = items.map((item) => {
            const row = [];

            if (showCodigo) row.push(item.subtarea ? item.subtarea.codigo : '');
            row.push(item.subtarea ? item.subtarea.titulo : item.texto);
            if (contarTiempo) row.push(item.subtarea ? getHoras(tiempoPorSubtarea[item.subtarea.id]) : '');
            if (marcarCompletado) row.push(item.completado ? 'Sí' : 'No');

            return row;
        });

        const csv = headers.join(separator) + '\n' + data.map((row) => row.join(separator)).join('\n');
        const csvFile = new Blob(['\ufeff', csv], { type: 'text/csv' });
        const downloadLink = document.createElement('a');
        downloadLink.download = 'resumen-tareas.csv';
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }

    return (
        <Paper elevation={0} className={classes.root}>
            <Typography variant='body1' className={classes.title}>
                Resumen de tareas
                <Tooltip title={'Exportar a CSV'}>
                    <IconButton onClick={exportCSV}>
                        <DownloadIcon />
                    </IconButton>
                </Tooltip>
            </Typography>

            <Table size='small'>
                <TableHead>
                    <TableRow>
                        {showCodigo && <TableCell>Código</TableCell>}
                        <TableCell>Tarea</TableCell>
                        {contarTiempo && <TableCell>Horas</TableCell>}
                        {marcarCompletado && <TableCell>Completado</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item, i) => {
                        return (
                            <TableRow key={i}>
                                {showCodigo && <TableCell>{item.subtarea ? item.subtarea.codigo : null}</TableCell>}
                                <TableCell>{item.subtarea ? item.subtarea.titulo : item.texto}</TableCell>
                                {contarTiempo && (
                                    <TableCell>
                                        {item.subtarea ? formatTiempo(tiempoPorSubtarea[item.subtarea.id]) : null}
                                    </TableCell>
                                )}
                                {marcarCompletado && (
                                    <TableCell className={classes.completadoCell}>
                                        {item.completado ? (
                                            <>
                                                Sí{' '}
                                                <Tooltip
                                                    title={`Por ${item.operario} el ${formatDateTime(
                                                        item.fecha_completado,
                                                    )}`}
                                                    arrow
                                                >
                                                    <InfoIcon />
                                                </Tooltip>
                                            </>
                                        ) : (
                                            'No'
                                        )}
                                    </TableCell>
                                )}
                            </TableRow>
                        );
                    })}
                </TableBody>
                {contarTiempo && (
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={2}>Total</TableCell>
                            <TableCell>{formatTiempo(tiempoTotal)}</TableCell>
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </Paper>
    );
}

TiempoTareaChecklist.propTypes = {
    jornadas: PropTypes.array,
    items: PropTypes.array,
    contarTiempo: PropTypes.bool,
    marcarCompletado: PropTypes.bool,
};
