import { VisibleLayerChip } from './VisibleLayerChip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import EventIcon from '@material-ui/icons/Event';
import PartesTrabajoIcon from '@material-ui/icons/FileCopy';
import FichajesIcon from '@material-ui/icons/Timer';
import VacacionesIcon from '../icons/Vacaciones';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
        },
    }),
    { name: 'VisibleLayersSelector' },
);

const layers = [
    {
        key: 'agenda',
        label: 'Planificación de servicios',
        icon: <EventIcon />,
    },
    {
        key: 'jornada',
        label: 'Partes de trabajo',
        icon: <PartesTrabajoIcon />,
    },
    {
        key: 'fichaje',
        label: 'Fichajes',
        icon: <FichajesIcon />,
    },
    {
        key: 'solicitud',
        label: 'Vacaciones y ausencias',
        icon: <VacacionesIcon />,
    },
];

export function VisibleLayersSelector({ visibleLayers, setVisibleLayers }) {
    const classes = useStyles();

    function toggleLayer(key) {
        setVisibleLayers((visibleLayers) => {
            if (visibleLayers.includes(key)) return visibleLayers.filter((k) => k !== key);

            return [...visibleLayers, key];
        });
    }

    return (
        <div className={classes.root}>
            {layers.map(({ key, label, icon }) => (
                <VisibleLayerChip
                    key={key}
                    label={label}
                    icon={icon}
                    size='small'
                    onClick={() => toggleLayer(key)}
                    disabled={!visibleLayers.includes(key)}
                />
            ))}
        </div>
    );
}

VisibleLayersSelector.propTypes = {
    visibleLayers: PropTypes.array.isRequired,
    setVisibleLayers: PropTypes.func.isRequired,
};
