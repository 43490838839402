import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { createTiempo } from '../../../../api/tareas-functions';
import { formatTiempo } from '../../../../utils';

const useStyles = makeStyles(
    (theme) => ({
        item: {
            display: 'flex',
            flexDirection: 'column',
            borderTop: '1px solid #23395F',
            padding: theme.spacing(2, 3),
            gap: `${theme.spacing(2)}px`,
            '&:first-of-type': {
                marginTop: theme.spacing(2),
            },
        },
        row: {
            display: 'flex',
            alignItems: 'center',
        },
        label: {
            fontSize: 14,
            fontWeight: 500,
            flex: 1,
        },
        time: {
            fontSize: 18,
            fontWeight: 500,
        },
        subItems: {
            gap: `${theme.spacing(4)}px`,
        },
        subItem: {
            display: 'flex',
            flexDirection: 'column',
        },
        label2: {
            fontSize: 12,
            fontWeight: 400,
        },
        time2: {
            fontSize: 16,
            fontWeight: 500,
        },
    }),
    { name: 'ResumenMes' },
);

export default function ResumenMes({ fichajes }) {
    const classes = useStyles();

    const { horasEfectivas, horasFichadas, horasExtras, horasLaborables, horasVacaciones, horasAusencias } =
        useMemo(() => {
            const agregado = fichajes.reduce(
                (acc, item) => {
                    acc.horasEfectivas = acc.horasEfectivas + item.laborables.efectivas;
                    acc.horasFichadas = acc.horasFichadas + item.laborables.fichadas;
                    acc.horasLaborables = acc.horasLaborables + item.laborables.horario;
                    acc.horasVacaciones = acc.horasVacaciones + item.laborables.vacaciones;
                    acc.horasAusencias = acc.horasAusencias + item.laborables.ausencias;
                    return acc;
                },
                {
                    horasEfectivas: 0,
                    horasFichadas: 0,
                    horasLaborables: 0,
                    horasVacaciones: 0,
                    horasAusencias: 0,
                },
            );

            agregado.horasExtras = Math.max(0, agregado.horasFichadas - agregado.horasEfectivas);

            agregado.horasEfectivas = createTiempo(agregado.horasEfectivas);
            agregado.horasFichadas = createTiempo(agregado.horasFichadas);
            agregado.horasExtras = createTiempo(agregado.horasExtras);
            agregado.horasLaborables = createTiempo(agregado.horasLaborables);
            agregado.horasVacaciones = createTiempo(agregado.horasVacaciones);
            agregado.horasAusencias = createTiempo(agregado.horasAusencias);

            return agregado;
        }, [fichajes]);

    return (
        <>
            <div className={classes.item}>
                <div className={classes.row}>
                    <Typography className={classes.label}>Horas de trabajo efectivas:</Typography>
                    <Typography className={classes.time}>{formatTiempo(horasEfectivas)}</Typography>
                </div>
                <div className={clsx(classes.row, classes.subItems)}>
                    <div className={classes.subItem}>
                        <Typography className={classes.label2}>Laborables</Typography>
                        <Typography className={classes.time2}>{formatTiempo(horasLaborables)}</Typography>
                    </div>
                    <div className={classes.subItem}>
                        <Typography className={classes.label2}>Vacaciones</Typography>
                        <Typography className={classes.time2}>{formatTiempo(horasVacaciones)}</Typography>
                    </div>
                    <div className={classes.subItem}>
                        <Typography className={classes.label2}>Ausencias</Typography>
                        <Typography className={classes.time2}>{formatTiempo(horasAusencias)}</Typography>
                    </div>
                </div>
            </div>
            <div className={classes.item}>
                <div className={classes.row}>
                    <Typography className={classes.label}>Horas fichadas:</Typography>
                    <Typography className={classes.time}>{formatTiempo(horasFichadas)}</Typography>
                </div>
            </div>
            <div className={classes.item}>
                <div className={classes.row}>
                    <Typography className={classes.label}>Horas extras:</Typography>
                    <Typography className={classes.time}>{formatTiempo(horasExtras)}</Typography>
                </div>
            </div>
        </>
    );
}

ResumenMes.propTypes = {
    fichajes: PropTypes.object.isRequired,
};
