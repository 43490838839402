import PropTypes from 'prop-types';
import { presupuestosProvider } from '../../../api';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterDetailsHeader from '../../common/forms/MasterDetailsView/MasterDetailsHeader';
import { BasePresupuestoTable } from '../../presupuestos/PresupuestoView/PresupuestoTable';
import TotalesProyecto from '../TotalesProyecto';
import PresupuestoDetails from './PresupuestoDetails';

function ResumenPresupuestos() {
    const { data: proyecto, updateData } = useEditFormData();

    return (
        <>
            <MasterDetailsHeader title='Resúmen de presupuestos aceptados' />
            {proyecto.presupuestos
                .filter((presupuesto) => presupuesto.estado === 'ACEPTADO')
                .map((presupuesto) => (
                    <BasePresupuestoTable
                        key={presupuesto.id}
                        presupuesto={presupuesto}
                        title={`Presupuesto: ${presupuesto.nombre}`}
                        fetchData={() => {
                            presupuestosProvider.getById(presupuesto.id).then((data) => {
                                updateData((proyecto) => ({
                                    ...proyecto,
                                    presupuestos: proyecto.presupuestos.map((presupuestoOld) =>
                                        presupuestoOld.id === presupuesto.id
                                            ? { ...presupuestoOld, ...data }
                                            : presupuestoOld,
                                    ),
                                    avances_obra: null,
                                    certificaciones_obra: null,
                                }));
                            });
                        }}
                    />
                ))}
            <TotalesProyecto proyecto={proyecto} />
        </>
    );
}

export default function ProyectoPresupuestoDetails({ index }) {
    if (index === 0) {
        return <ResumenPresupuestos />;
    }

    return <PresupuestoDetails index={index - 1} />;
}

ProyectoPresupuestoDetails.propTypes = {
    index: PropTypes.number.isRequired,
};
