import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            color: theme.palette.text.primary,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    }),
    { name: 'LinkCell' },
);

export default function Link({ id, value, baseUrl }) {
    const classes = useStyles();

    return (
        <NavLink className={classes.root} to={`/${baseUrl}/${id}`} target='_blank'>
            {value}
        </NavLink>
    );
}

export function createLinkCell(propName, baseUrl) {
    // eslint-disable-next-line react/display-name
    return (props) => <LinkCell propName={propName} baseUrl={baseUrl} {...props} />;
}

export const LinkCell = ({ value, row, propName, baseUrl }) => (
    <Link id={row.original[propName]} value={value} baseUrl={baseUrl} />
);

LinkCell.propTypes = {
    value: PropTypes.any,
    row: PropTypes.any,
    propName: PropTypes.any,
    baseUrl: PropTypes.any,
};

Link.propTypes = {
    id: PropTypes.any,
    value: PropTypes.any,
    baseUrl: PropTypes.any,
};
