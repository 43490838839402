import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import OperarioInfoTooltip from './OperarioInfoTooltip';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import MuiBadge from '@material-ui/core/Badge';
import withStyles from '@material-ui/core/styles/withStyles';
import PeopleIcon from '@material-ui/icons/People';

const Badge = withStyles(
    (theme) => ({
        badge: {
            right: -3,
            top: 4,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
        },
    }),
    { name: 'Badge' },
)(MuiBadge);

export function OperariosServicioList({ operarios }) {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

    const openMenu = (event) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
    };

    const closeMenu = (event) => {
        event && event.stopPropagation();
        setMenuAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={openMenu} size='small'>
                <Badge color='primary' badgeContent={operarios.length}>
                    <PeopleIcon />
                </Badge>
            </IconButton>
            <Menu
                id='simple-menu'
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={closeMenu}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {operarios.map((operario, i) => (
                    <MenuItem key={i} component={OperarioInfoTooltip} operario={operario} placement='right' />
                ))}
            </Menu>
        </>
    );
}

OperariosServicioList.propTypes = {
    operarios: PropTypes.any,
};
