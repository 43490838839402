import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import addMonths from 'date-fns/addMonths';
import { calendariosProvider } from '../../../api';
import { MonthCalendar } from './MonthCalendar';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            flex: 1,
            display: 'flex',
            gap: '16px',
            flexWrap: 'wrap',
            background: 'white',
            borderRadius: 8,
        },
    }),
    { name: 'YearCalendar' },
);

export const CalendarContext = React.createContext({});

export default function YearCalendar({ startDate, endDate, plannedDates, selectedDates, onSelect, className }) {
    const classes = useStyles();
    const [noLaborables, setNoLaborables] = useState([]);

    const dateRangeStartYear = startDate.getFullYear();
    const dateRangeEndYear = endDate.getFullYear();

    useEffect(() => {
        if (dateRangeStartYear === null) return;

        const years = [dateRangeStartYear];
        if (dateRangeEndYear !== dateRangeStartYear) years.push(dateRangeEndYear);

        const promises = years.map((year) => calendariosProvider.getNoLaborables(year));

        Promise.all(promises).then((dates) => {
            setNoLaborables(dates.reduce((accumDates, dates) => accumDates.concat(dates), []));
        });
    }, [dateRangeStartYear, dateRangeEndYear]);

    const months = useMemo(() => {
        function stillInRange(date) {
            return (
                date.getFullYear() < endDate.getFullYear() ||
                (date.getFullYear() === endDate.getFullYear() && date.getMonth() <= endDate.getMonth())
            );
        }

        const months = [];
        for (
            let currentDate = new Date(startDate);
            stillInRange(currentDate);
            currentDate = addMonths(currentDate, 1)
        ) {
            months.push(<MonthCalendar year={currentDate.getFullYear()} month={currentDate.getMonth() + 1} />);
        }
        return months;
    }, [startDate, endDate]);

    function getDayProps(day) {
        const props = {
            noLaborable: false,
            selected: false,
            planned: false,
        };

        if (noLaborables.includes(day)) props.noLaborable = true;

        if (selectedDates.includes(day)) props.selected = true;

        if (plannedDates.includes(day)) props.planned = true;

        return props;
    }

    return (
        <CalendarContext.Provider
            value={{
                onSelect,
                getDayProps,
                readOnly: false,
                disabledDates: [],
                selectedDates,
                minDate: null,
                maxDate: null,
            }}
        >
            <div className={clsx(classes.root, className)}>{React.Children.toArray(months)}</div>
        </CalendarContext.Provider>
    );
}

YearCalendar.propTypes = {
    className: PropTypes.any,
    endDate: PropTypes.object.isRequired,
    onSelect: PropTypes.any,
    plannedDates: PropTypes.array.isRequired,
    selectedDates: PropTypes.any,
    startDate: PropTypes.object.isRequired,
};
