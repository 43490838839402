import { useEffect, useState } from 'react';
import { rendimientosProvider } from '../../../api';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import BaseRendimiento from '../../servicios/Rendimiento/BaseRendimiento';

export default function Rendimiento() {
    const { data: tareas } = useEditFormData('tareas');

    const [rendimientoTareas, setRendimientoTareas] = useState(0);

    useEffect(() => {
        Promise.all(tareas.map((tarea) => rendimientosProvider.getByTareaId(tarea.id))).then((rendimientos) => {
            const rendimiento = rendimientos.reduce(
                (rendimientoAccum, rendimiento) => {
                    rendimientoAccum.total_comercial += rendimiento.total_comercial;
                    rendimientoAccum.total_facturado += rendimiento.total_facturado;
                    return rendimientoAccum;
                },
                {
                    total_comercial: 0,
                    total_facturado: 0,
                },
            );

            setRendimientoTareas(rendimiento);
        });
    }, [tareas]);

    return <BaseRendimiento rendimiento={rendimientoTareas} />;
}
