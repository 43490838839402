import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TareasAlbaranesPendientes from './TareasAlbaranesPendientes';
import AlbaranesList from './AlbaranesList';
import AlbaranesVacios from './AlbaranesVacios';
import React from 'react';

const useStyles = makeStyles(
    (theme) => ({
        header: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
        },
    }),
    { name: 'Facturacion' },
);

export default function Facturacion() {
    const classes = useStyles();
    const albaranesListRef = React.createRef();

    return (
        <>
            <div className={classes.header}>
                <Typography variant='h2' style={{ flex: 1 }}>
                    Facturación
                </Typography>
            </div>
            <TareasAlbaranesPendientes onTableUpdated={() => albaranesListRef.current.refresh()} />
            <AlbaranesList ref={albaranesListRef} />
            <AlbaranesVacios />
        </>
    );
}
