import { useCallback } from 'react';
import { dataProvider } from '../../api/equipos';
import BaseEquiposList from './BaseEquiposList';
import { useFetchData } from '../../hooks/useFetchData';

export default function EquiposList() {
    const fetchDataFn = useCallback(() => dataProvider.getAll(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    return (
        <>
            <BaseEquiposList
                tableId='equipos'
                data={data}
                fetchData={fetchData}
                ListViewProps={{
                    title: 'Equipos',
                }}
            />
        </>
    );
}
