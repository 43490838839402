import { useCallback, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useFetchData } from '../../hooks/useFetchData';
import { contratosMantenimientoProvider } from '../../api';
import Button from '../common/Button';
import AddIcon from '@material-ui/icons/Add';
import AddEquiposDialog from './AddEquiposDialog';
import BaseListView, { withTableState } from '../common/list/ListView';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import DeleteDialog from '../common/dialogs/DeleteDialog';
import EmptyListHero from '../common/EmptyListHero';
import { SwitchWithLabel } from '../common/fields/Switch';
import DoneIcon from '@material-ui/icons/Done';
import DeleteEquipoDialog from '../equipos/DeleteEquipoDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import EstadoLabel from '../equipos/EstadoLabel';

export default function EquiposList() {
    const { id } = useParams();
    const fetchDataFn = useCallback(() => contratosMantenimientoProvider.getEquipos(id), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    const ListView = useMemo(() => withTableState('equipos-contrato', BaseListView), []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const columns = useMemo(
        () => [
            {
                Header: 'Referencia',
                accessor: 'referencia',
            },
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
            {
                Header: 'Marca',
                accessor: 'marca',
            },
            {
                Header: 'Modelo',
                accessor: 'modelo',
            },
            {
                Header: 'Tipo',
                accessor: 'tipo_equipo',
            },
            {
                Header: 'Numero de serie',
                accessor: 'numero_serie',
            },
            {
                Header: 'Ubicación',
                accessor: 'ubicacion',
            },
            {
                Header: 'Estado',
                id: 'estado',
                accessor: (row) => (row.activo ? 'ACTIVO' : row.fecha_baja ? 'BAJA' : 'INACTIVO'),
                // eslint-disable-next-line react/prop-types
                Cell: ({ value, row: { original } }) => (
                    // eslint-disable-next-line react/prop-types
                    <EstadoLabel estado={value} fechaBaja={original.fecha_baja} motivoBaja={original.motivo_baja} />
                ),
            },
            {
                Header: 'Activo',
                accessor: 'activo',
                // eslint-disable-next-line react/prop-types
                Cell: ({ value }) => (value ? <DoneIcon /> : null),
                filter: 'equals',
                disableGlobalFilter: true,
                disableFilters: true,
                disableSortBy: true,
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            batchComponents: (
                <AddEquiposDialog
                    button={
                        <Button color='transparent' aria-label='Añadir equipos' startIcon={<AddIcon />}>
                            Añadir equipos
                        </Button>
                    }
                    onSave={fetchData}
                />
            ),
            tableOptions: {
                extraFilters: (setFilter, tableColumns) => {
                    const column = tableColumns.find((c) => c.id === 'activo');
                    const showingAll = column.filterValue === undefined;

                    return (
                        <SwitchWithLabel
                            label='Mostrar solo los equipos activos'
                            field={{
                                name: 'solo-operarios-activos',
                                onChange: () => column.setFilter(showingAll ? true : undefined),
                                value: !showingAll,
                            }}
                        />
                    );
                },
                forceHiddenColumns: ['activo'],
                initialState: {
                    filters: [{ id: 'activo', value: true }],
                },
            },
        }),
        [],
    );

    return (
        <>
            {data.length === 0 ? (
                <EmptyListHero
                    title='Equipos incluidos en el contrato'
                    action={
                        <AddEquiposDialog
                            button={
                                <Button color='info' aria-label='Añadir equipos' startIcon={<AddIcon />}>
                                    Añadir equipos
                                </Button>
                            }
                            onSave={fetchData}
                        />
                    }
                >
                    En esta lista verás los equipos de la instalación que estén incluidos en el contrato. Los equipos
                    que añadas estarán filtrados en funcion de los tipos de revisiones que hayas elegido al configurar
                    el contrato.
                </EmptyListHero>
            ) : (
                <ListView
                    tableTitle='Lista de equipos incluidos en el contrato'
                    basePath='/equipos'
                    columns={columns}
                    data={data}
                    options={options}
                    fetchData={fetchData}
                    extraActions={(row) => (
                        <>
                            <Tooltip title='Ver ficha'>
                                <Link to={`/equipos/${row.id}`}>
                                    <IconButton aria-label='Ver ficha'>
                                        <VisibilityIcon />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                            <DeleteDialog
                                text='¿Deseas quitar este equipo del contrato?'
                                button={
                                    <Tooltip title='Quitar equipo del contrato'>
                                        <IconButton aria-label='Quitar equipo del contrato'>
                                            <CloseIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                deleteButtonText='Quitar'
                                onConfirm={() => {
                                    contratosMantenimientoProvider.quitarEquipo(id, row.id).then(fetchData);
                                }}
                            />
                            <DeleteEquipoDialog
                                id={row.id}
                                button={
                                    <Tooltip title='Dar de baja el equipo'>
                                        <IconButton aria-label='Dar de baja el equipo'>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                onConfirm={() => {
                                    contratosMantenimientoProvider.quitarEquipo(id, row.id).then(fetchData);
                                }}
                            />
                        </>
                    )}
                />
            )}
        </>
    );
}
