import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import makeStyles from '@material-ui/core/styles/makeStyles';
import EditProfileDialog from './EditProfileDialog';
import FacturasList from './FacturasList';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import InfoBlockPaper from './InfoBlockPaper';
import EditDatosPagoDialog from './EditDatosPagoDialog';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        columns: {
            display: 'flex',
            gap: `${theme.spacing(3)}px`,
            '&>div': {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
            },
        },
        paperColumn: {
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'DatosFacturacionTab' },
);

export default function DatosFacturacionTab() {
    const classes = useStyles();
    const { data, fetchData } = useEditFormData();

    const lineaDireccionSecundaria = [data.codigo_postal, data.poblacion, data.provincia].filter(Boolean).join(', ');

    const facturacionAllData = data.name && data.direccion && lineaDireccionSecundaria && data.nif && data.telefono;
    const datosPagoAllData = data.datos_pago?.nombre && data.datos_pago?.numero_cuenta;

    return (
        <div className={classes.columns}>
            <div className={classes.paperColumn}>
                <InfoBlockPaper
                    title='Datos de facturación'
                    hasAllData={facturacionAllData}
                    editComponent={
                        <EditProfileDialog
                            onSave={fetchData}
                            button={
                                <IconButton size='small'>
                                    <EditIcon fontSize='small' />
                                </IconButton>
                            }
                        />
                    }
                >
                    <strong>{data.name}</strong>
                    <span>{data.direccion}</span>
                    <span>{lineaDireccionSecundaria}</span>
                    <span>{data.nif}</span>
                    <span>{data.telefono}</span>
                </InfoBlockPaper>
                <InfoBlockPaper
                    title='Datos de pago'
                    hasAllData={datosPagoAllData}
                    editComponent={
                        <EditDatosPagoDialog
                            onSave={fetchData}
                            button={
                                <IconButton size='small'>
                                    <EditIcon fontSize='small' />
                                </IconButton>
                            }
                        />
                    }
                >
                    {data.datos_pago ? (
                        <>
                            <span>{data.datos_pago.nombre}</span>
                            <span>{data.datos_pago.numero_cuenta}</span>
                        </>
                    ) : (
                        <span>No tienes datos de pago configurados</span>
                    )}
                </InfoBlockPaper>
            </div>
            <FacturasList />
        </div>
    );
}
