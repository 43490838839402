import {
    DndContext,
    DragOverlay,
    MeasuringStrategy,
    MouseSensor,
    pointerWithin,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import addDays from 'date-fns/addDays';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isBefore from 'date-fns/isBefore';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { uuid } from 'uuidv4';
import { operariosProvider, solicitudesProvider, tareasProvider, vehiculosProvider } from '../../api';
import { BACKGROUND_COLOR } from '../../App';
import useAuthState from '../../AuthState';
import { formatISODate, getInitials, setBeginOfDay, setEndOfDay } from '../../utils';
import ServicioInfoTooltip from '../agenda/ServicioInfoTooltip';
import {
    groupByToKey,
    GROUP_BY_OPERARIOS,
    GROUP_BY_SERVICIOS,
    GROUP_BY_VEHICULOS,
    useTransformEvents,
} from '../agenda/useTransformEvents';
import Button from '../common/Button';
import NuevaTareaDialog from '../tareas/NuevaTareaDialog';
import { createEmptyPlanificacion } from '../tareas/PlanificacionForm';
import CalendarioHeader from './CalendarioHeader';
import CalendarioHeaderFilters from './CalendarioHeaderFilters';
import CalendarioRow from './CalendarioRow';
import CalendarioRowDays from './CalendarioRowDays';
import { DAY_DROPPABLE_TYPE } from './DayPlaceholder';
import DraggableCalendarioRow from './DraggableCalendarioRow';
import { BaseEditPlanificacionDialog } from './EditPlanificacionDialog';
import { EVENTO_DRAGGABLE_TYPE } from './Evento';
import MoverServicioItem from './MoverServiciosDrawer/MoverServicioItem';
import MoverServiciosDrawer, {
    MOVER_DROPPABLE_TYPE,
    MOVER_SERVICIOS_HEIGHT,
} from './MoverServiciosDrawer/MoverServiciosDrawer';
import TareasSinPlanificarDrawer, { SIN_PLANIFICAR_HEIGHT } from './TareasSinPlanificarDrawer';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            width: 'fit-content',
            // gap: `${theme.spacing(1)}px`,
            marginTop: theme.spacing(8),
            paddingBottom: theme.spacing(3),
            '&.showSinPlanificar': {
                marginBottom: SIN_PLANIFICAR_HEIGHT,
            },
            '&.showMover': {
                marginBottom: MOVER_SERVICIOS_HEIGHT,
            },
            '&>:first-child': {
                background: BACKGROUND_COLOR,
                zIndex: 1,
            },
            '&.showSinPlanificar, &.showMover': {
                // transition: theme.transitions.create('margin', {
                //     easing: theme.transitions.easing.easeOut,
                //     duration: theme.transitions.duration.enteringScreen,
                // }),
            },
            marginBottom: 0,
            // transition: theme.transitions.create('margin', {
            //     easing: theme.transitions.easing.sharp,
            //     duration: theme.transitions.duration.leavingScreen,
            // }),
        },
        scrollable: {
            overflowX: 'auto',
            display: 'flex',
            flex: 1,
            '& $root': {
                flex: 1,
            },
        },
        nextWeekPlaceholder: {
            width: 48,
            height: 48,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: -50,
            right: -24,
            zIndex: 999,
            borderRadius: '4px 0 0 4px',
            background: theme.palette.primary.hover,
            color: theme.palette.primary.main,
            '&.show': {
                background: theme.palette.primary.main,
                color: 'white',
            },
        },
        emptyWrapper: {
            marginTop: 64,
        },
        empty: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            margin: theme.spacing(6, 'auto'),
            gap: `${theme.spacing(3)}px`,
            width: 430,
        },
        emptyText: {
            color: theme.palette.neutral.grey4,
        },
    }),
    { name: 'CalendarioContent' },
);

export const SIN_ASIGNAR = 'sin-asignar';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle,
});

const SORTED_OPERARIO_IDS_KEY = 'labory-sorted-operario-ids';
const SORTED_VEHICULO_IDS_KEY = 'labory-sorted-vehiculo-ids';

const CalendarioPlanificacionContext = createContext();

function CalendarioPlanificacionContextProvider({ startDate, children }) {
    const [hoveredEvento, setHoveredEvento] = useState(null);

    const {
        userInfo: { preferencias: { usar_dnd_calendario_planificacion: usarDndCalendarioPlanificacion } = {} },
    } = useAuthState();

    function onEventoHovered(evento) {
        setHoveredEvento(evento);
    }

    function checkIfEventoIsHovered(evento) {
        if (!hoveredEvento) return false;

        return evento.planificacionId === hoveredEvento.planificacionId;
    }

    return (
        <CalendarioPlanificacionContext.Provider
            value={{
                startDate,
                onEventoHovered,
                hoveredEvento,
                checkIfEventoIsHovered,
                isAnyEventoHovered: hoveredEvento !== null,
                enableDnd: usarDndCalendarioPlanificacion,
            }}
        >
            {children}
        </CalendarioPlanificacionContext.Provider>
    );
}

CalendarioPlanificacionContextProvider.propTypes = {
    children: PropTypes.node,
    startDate: PropTypes.any,
};

export function useCalendarioPlanificacion() {
    return useContext(CalendarioPlanificacionContext);
}

export default function CalendarioContent({
    showPendientesPlanificar,
    onClosePendientesPlanificar,
    showOnlyColumn,
    showOnlyOperarioActivos,
    start,
    end,
    days,
    hidePastPlanificaciones,
    view,
    colorSource,
    noLaborables,
    onFilter,
}) {
    const classes = useStyles();

    const numDays = days.length;

    const [tareas, setTareas] = useState(null);
    const [solicitudes, setSolicitudes] = useState([]);
    const [transformedTareas, setTransformedTareas] = useState([]);
    const [groupedTareas, setGroupedTareas] = useState({});
    const [operarios, setOperarios] = useState([]);
    const [vehiculos, setVehiculos] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [dragEvent, setDragEvent] = useState(null);
    const [moveEventList, setMoveEventList] = useState([]);
    const [sortedOperarioIds, setSortedOperarioIds] = useState([]);
    const [sortedVehiculoIds, setSortedVehiculoIds] = useState([]);
    const [selectedPendientePlanificar, setSelectedPendientePlanificar] = useState(null);
    const tareasSinPlanificarRef = React.createRef();
    const scrollableRef = React.createRef();
    const [noLaborablesOperarios, setNoLaborablesOperarios] = useState({});

    useEffect(() => {
        function onScroll() {
            document.querySelectorAll(`.${classes.root}`).forEach((el) => {
                const firstChild = el.firstChild;
                if (firstChild) {
                    firstChild.style.transform = `translateY(${window.scrollY}px)`;
                }
            });
        }

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    // attach handler to scrollable div con scroll
    useEffect(() => {
        const scrollable = scrollableRef.current;
        if (!scrollable) return;

        const handleScroll = () => {
            const thisLeft = scrollable.getBoundingClientRect().left;

            setTimeout(() => {
                const children = scrollable.getElementsByClassName('cp-evento-content');
                for (let i = 0; i < children.length; i++) {
                    const child = children[i];
                    const bounds = child.getBoundingClientRect();
                    const childLeft = bounds.left;
                    const diff = childLeft - thisLeft - 4;

                    if (diff < 0 && Math.abs(diff) < bounds.width) {
                        child.style.paddingLeft = `${Math.abs(diff)}px`;
                    } else {
                        child.style.paddingLeft = '0px';
                    }
                }
            });
        };

        scrollable.addEventListener('scroll', handleScroll);

        return () => {
            scrollable.removeEventListener('scroll', handleScroll);
        };
    }, [scrollableRef]);

    const snackbar = useSnackbar();
    const operariosColor = useMemo(() => Object.fromEntries(operarios.map((op) => [op.id, op.color])), [operarios]);

    const { updateTarea, transformTarea, transformSolicitud } = useTransformEvents(
        operariosColor,
        setTransformedTareas,
        setSelectedEvent,
        hidePastPlanificaciones,
        view,
        colorSource,
    );

    useEffect(() => {
        vehiculosProvider.getAsOptions().then((vehiculos) => {
            setVehiculos(vehiculos);

            const vehiculoIds = vehiculos.map((o) => o.id);

            let savedSortedVehiculoIds = localStorage.getItem(SORTED_VEHICULO_IDS_KEY);
            if (savedSortedVehiculoIds) savedSortedVehiculoIds = JSON.parse(savedSortedVehiculoIds);
            else savedSortedVehiculoIds = vehiculoIds;

            savedSortedVehiculoIds = savedSortedVehiculoIds.filter((id) => vehiculoIds.includes(id));
            const newVehiculoIds = vehiculoIds.filter((id) => !savedSortedVehiculoIds.includes(id));
            savedSortedVehiculoIds = [...savedSortedVehiculoIds, ...newVehiculoIds];

            setSortedVehiculoIds(savedSortedVehiculoIds);
        });

        operariosProvider.getAllAsOptions().then((operarios) => {
            setOperarios(
                operarios.map((operario, i) => ({
                    id: operario.id,
                    nombre: operario.nombre,
                    iniciales: getInitials(operario.nombre),
                    activo: operario.activo,
                })),
            );

            const operarioIds = operarios.map((o) => o.id);

            let savedSortedOperarioIds = localStorage.getItem(SORTED_OPERARIO_IDS_KEY);
            if (savedSortedOperarioIds) savedSortedOperarioIds = JSON.parse(savedSortedOperarioIds);
            else savedSortedOperarioIds = operarioIds;

            savedSortedOperarioIds = savedSortedOperarioIds.filter((id) => operarioIds.includes(id));
            const newOperarioIds = operarioIds.filter((id) => !savedSortedOperarioIds.includes(id));
            savedSortedOperarioIds = [...savedSortedOperarioIds, ...newOperarioIds];

            setSortedOperarioIds(savedSortedOperarioIds);
        });
    }, []);

    const dateRange = useMemo(() => ({ start, end }), [start, end]);
    const startYear = dateRange.start.getFullYear();
    const endYear = dateRange.end.getFullYear();

    useEffect(() => {
        const years = [startYear];
        if (startYear !== endYear) years.push(endYear);

        const noLaborablesYearsResults = years.map((year) => operariosProvider.getAll(`all/no_laborables/${year}`));

        Promise.all(noLaborablesYearsResults).then((results) => {
            const noLaborablesOperariosAccum = {};
            results.forEach((noLaborablesByOperarioId) => {
                Object.entries(noLaborablesByOperarioId).forEach(([operarioId, noLaborables]) => {
                    if (noLaborablesOperariosAccum[operarioId]) {
                        noLaborablesOperariosAccum[operarioId].push(...noLaborables);
                    } else {
                        noLaborablesOperariosAccum[operarioId] = noLaborables;
                    }
                });
            });
            setNoLaborablesOperarios(noLaborablesOperariosAccum);
        });
    }, [startYear, endYear]);

    const processGroupedTareas = useCallback(
        function processGroupedTareas(tareas, solicitudes, groups, view) {
            const key = groupByToKey[view];
            const groupedTareas = Object.fromEntries(
                groups.map((group) => [group.id, { events: [], maxRows: 0, group }]),
            );

            if (view !== GROUP_BY_SERVICIOS) groupedTareas[SIN_ASIGNAR] = { events: [], maxRows: 0, operario: null };

            const validItems = tareas.filter((t) => t.type === 'agenda');

            validItems.push(...solicitudes);

            validItems.forEach((event) => {
                const offset = differenceInCalendarDays(event.start, start);
                const length = differenceInCalendarDays(event.end, event.start) + 1;

                if (offset >= numDays || offset + length <= 0) return;

                event.offset = offset;
                event.length = length;
                event.fromPrevWeek = false;
                event.toNextWeek = false;

                if (event.offset < 0) {
                    event.length += event.offset;
                    event.offset = 0;
                    event.fromPrevWeek = true;
                }
                if (event.offset + event.length > numDays) {
                    event.length = numDays - event.offset;
                    event.toNextWeek = true;
                }

                event.row = null;

                const groupId = event[key] ?? SIN_ASIGNAR;

                if (groupedTareas[groupId]) groupedTareas[groupId].events.push(event);
            });

            Object.values(groupedTareas).forEach((tareas) => {
                const daySlots = new Array(days.length).fill(null).map(() => []);

                tareas.events
                    .sort(function (a, b) {
                        if (a.length > b.length) return -1;

                        if (b.length > a.length) return 1;

                        if (isBefore(a.start, b.start)) return -1;

                        return 1;
                    })
                    .forEach((event, idx) => {
                        if (event.dragging) return;

                        for (let s = 0; s < tareas.maxRows; s++) {
                            let valid = true;
                            for (let i = 0; i < event.length; i++) {
                                if (daySlots[event.offset + i][s] !== undefined) {
                                    valid = false;
                                    break;
                                }
                            }
                            if (valid) {
                                event.row = s;
                                break;
                            }
                        }
                        if (event.row === null) {
                            event.row = tareas.maxRows;
                            tareas.maxRows++;
                        }

                        for (let i = 0; i < event.length; i++) {
                            daySlots[event.offset + i][event.row] = idx;
                        }
                    });
            });

            return groupedTareas;
        },
        [days],
    );

    useEffect(() => {
        if (dateRange === null || operarios.length === 0) return;

        setTareas(null);

        const calendarioParams = `start=${formatISODate(dateRange.start)}&end=${formatISODate(
            dateRange.end,
        )}&solo_planificaciones=True`;
        tareasProvider
            .getAll(`calendario?${calendarioParams}`)
            .then((tareas) => setTareas(tareas.map((t) => ({ ...t, nombre: t.cliente }))));
        solicitudesProvider.getAll(`calendario?${calendarioParams}`).then((solicitudes) => {
            const transformedSolicitudes = [];
            solicitudes.forEach((solicitud) => {
                transformedSolicitudes.push(...transformSolicitud(solicitud));
            });
            setSolicitudes(transformedSolicitudes);
        });
    }, [operarios, dateRange]);

    useEffect(() => {
        if (tareas === null) return;

        const newTransformedTareas = [];
        tareas.forEach((tarea) => {
            newTransformedTareas.push(...transformTarea(tarea));
        });

        setTransformedTareas(newTransformedTareas);
        setGroupedTareas([]);
    }, [tareas, transformTarea]);

    useEffect(() => {
        if (tareas === null) return;
        if (view === GROUP_BY_OPERARIOS && operarios.length === 0) return;
        if (view === GROUP_BY_VEHICULOS && vehiculos.length === 0) return;

        if (view === GROUP_BY_OPERARIOS)
            setGroupedTareas(processGroupedTareas(transformedTareas, solicitudes, operarios, view));
        else if (view === GROUP_BY_VEHICULOS)
            setGroupedTareas(processGroupedTareas(transformedTareas, [], vehiculos, view));
        else if (view === GROUP_BY_SERVICIOS) {
            const clientes = tareas.map((t) => ({ id: t.cliente, nombre: t.cliente }));
            setGroupedTareas(processGroupedTareas(transformedTareas, [], clientes, view));
        }
    }, [operarios, vehiculos, transformedTareas, solicitudes]);

    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: {
            delay: 250,
            distance: 3,
            tolerance: 10,
        },
    });
    const sensors = useSensors(mouseSensor);

    function onDragEnd(result) {
        if (dragEvent) {
            setTransformedTareas((tareas) =>
                tareas
                    .filter((t) => t.virtual !== true)
                    .map((t) =>
                        t.planificacionId === dragEvent.planificacionId
                            ? { ...t, dragging: t.onMoveEventList === true }
                            : t,
                    ),
            );
        }

        setDragEvent(null);

        if (!result.active || !result.over) return;

        if (result.active.data.current.type === 'GRUPO' && result.over.data.current.type === 'GRUPO') {
            return onDragGrupos(result);
        }

        if (
            result.active.data.current.type === EVENTO_DRAGGABLE_TYPE &&
            result.over.data.current.type === DAY_DROPPABLE_TYPE
        ) {
            return onDragEventoToDay(result);
        }
        if (
            result.active.data.current.type === EVENTO_DRAGGABLE_TYPE &&
            result.over.data.current.type === MOVER_DROPPABLE_TYPE
        ) {
            return onDragEventoToMove(result);
        }
    }

    function onDragEventoToMove(result) {
        const evento = result.active.data.current.entity;

        setTransformedTareas((tareas) => {
            const uniqueId = uuid();

            setMoveEventList((eventos) => [
                ...eventos,
                {
                    ...evento,
                    uniqueId,
                    originalStartDate: formatISODate(start),
                    originalUniqueId: evento.uniqueId,
                    originalTransformedTareas: tareas.filter((t) => t.planificacionId === evento.planificacionId),
                    onRestore: () => {
                        setMoveEventList((eventos) => eventos.filter((ev) => ev.uniqueId !== uniqueId));
                        setTransformedTareas((tareas) => tareas.map((t) => ({ ...t, dragging: false })));
                    },
                    onUpdate: () => {
                        setMoveEventList((eventos) => eventos.filter((ev) => ev.uniqueId !== uniqueId));
                        refreshTarea(evento.id);
                    },
                },
            ]);

            return tareas.map((t) =>
                t.planificacionId !== dragEvent?.planificacionId ? t : { ...t, dragging: true, onMoveEventList: true },
            );
            // return tareas.filter((t) => t.planificacionId !== evento.planificacionId);
        });
    }

    function refreshTarea(tareaId) {
        tareasProvider.getByIdForCalendar(tareaId).then((tarea) => {
            tarea.nombre = tarea.cliente;
            setTareas((tareas) => {
                let found = false;
                const newTareas = [];
                tareas.forEach((oldTarea) => {
                    if (oldTarea.id === tareaId) {
                        found = true;
                        newTareas.push(tarea);
                    } else {
                        newTareas.push(oldTarea);
                    }
                });

                if (!found) newTareas.push(tarea);

                return newTareas;
            });
        });
    }

    function onDragEventoToDay(result) {
        const evento = result.active.data.current.entity;
        const destinationGroupId = result.over.data.current.groupId;
        const destinationGroup = result.over.data.current.group;
        const destinationDate = result.over.data.current.date;

        if (result.active.data.current.pendientePlanificar) {
            const planificacion = createEmptyPlanificacion();
            planificacion.fecha_inicio = setBeginOfDay(new Date(destinationDate));
            planificacion.fecha_fin = setEndOfDay(new Date(destinationDate));
            planificacion.allDay = true;
            planificacion.operarios =
                view === GROUP_BY_OPERARIOS && destinationGroupId !== SIN_ASIGNAR
                    ? [
                          {
                              fecha_salida: null,
                              visible: true,
                              operario: {
                                  id: destinationGroupId,
                                  nombre: destinationGroup.nombre,
                              },
                          },
                      ]
                    : [];
            planificacion.vehiculo =
                view === GROUP_BY_VEHICULOS && destinationGroupId !== SIN_ASIGNAR
                    ? { id: destinationGroupId, nombre: destinationGroup.nombre }
                    : null;

            setSelectedPendientePlanificar({
                ...evento,
                planificacion,
            });
            return;
        }

        const offsetDays = differenceInCalendarDays(destinationDate, evento.start);

        const newDestinationId = destinationGroupId === SIN_ASIGNAR ? null : destinationGroupId;
        const hasChanged = offsetDays !== 0 || evento[groupByToKey[evento.groupBy]] !== newDestinationId;

        if (!hasChanged) return;

        const newStartDate = addDays(evento.start, offsetDays);
        const newEndDate = addDays(evento.end, offsetDays);

        const payload = {
            fecha_inicio: newStartDate,
            fecha_fin: newEndDate,
        };

        if (view === GROUP_BY_VEHICULOS) {
            payload.vehiculo_id = newDestinationId;
        } else if (view === GROUP_BY_OPERARIOS) {
            payload.operario_change = {
                old_id: evento.operarioId,
                new_id: newDestinationId,
            };
        }

        tareasProvider
            .action(`planificaciones/${evento.planificacionId}`, {
                method: 'post',
                body: JSON.stringify(payload),
            })
            .then(() => {
                setMoveEventList((eventos) => eventos.filter((ev) => ev.uniqueId !== evento.uniqueId));
                refreshTarea(evento.id);
            })
            .catch((res) => {
                const error = res?.message || 'Error al mover el servicio';

                snackbar.showMessage(error, null, null, {
                    severity: 'error',
                    showCloseButton: true,
                });
            });
    }

    function onDragGrupos(result) {
        const fromIndex = result.active?.data.current.sortable.index;
        const toIndex = result.over?.data.current.sortable.index;

        if (fromIndex === undefined || toIndex === undefined) return;

        const [groupIds, setGroupIds] =
            view === GROUP_BY_OPERARIOS
                ? [sortedOperarioIds, setSortedOperarioIds]
                : [sortedVehiculoIds, setSortedVehiculoIds];
        const items = reorder(groupIds, fromIndex, toIndex);
        setGroupIds(items);

        localStorage.setItem(
            view === GROUP_BY_OPERARIOS ? SORTED_OPERARIO_IDS_KEY : SORTED_VEHICULO_IDS_KEY,
            JSON.stringify(items),
        );
    }

    function onDragStart(event) {
        let dragEvent = null;

        if (event.active.data.current.type === EVENTO_DRAGGABLE_TYPE) {
            dragEvent = {
                ...event.active.data.current.entity,
            };
        }
        setDragEvent(dragEvent);
        setTransformedTareas(
            // (tareas) => tareas.filter((t) => t.planificacionId !== dragEvent?.planificacionId),
            (tareas) =>
                tareas.map((t) => (t.planificacionId !== dragEvent?.planificacionId ? t : { ...t, dragging: true })),
        );
        setSelectedEvent(null);
    }

    function onDragCancel(result) {
        if (!dragEvent) return;

        setTransformedTareas((tareas) =>
            tareas
                .filter((t) => t.virtual !== true)
                .map((t) =>
                    t.planificacionId === dragEvent.planificacionId
                        ? { ...t, dragging: t.onMoveEventList === true }
                        : t,
                ),
        );
        setDragEvent(null);
    }

    function onDragOver(result) {
        if (!result.over) return;

        if (result.active.data.current.type !== EVENTO_DRAGGABLE_TYPE) return;
        if (result.over.data.current.type !== DAY_DROPPABLE_TYPE) return;

        const evento = result.active.data.current.entity;
        const destinationGroupId = result.over.data.current.groupId;
        const destinationDate = result.over.data.current.date;
        const offsetDays = differenceInCalendarDays(destinationDate, evento.start);

        const groupKey = groupByToKey[evento.groupBy];
        const srcGroupId = evento[groupKey];
        const destGroupId = destinationGroupId === SIN_ASIGNAR ? null : destinationGroupId;

        const newStartDate = addDays(evento.start, offsetDays);
        const newEndDate = addDays(evento.end, offsetDays);

        setTransformedTareas((tareas) => {
            const existingOperarioIds = [];
            const newTareas = tareas.filter((tarea) => !tarea.virtual);

            function generateNewTarea(tarea) {
                const newTarea = {
                    ...tarea,
                    uniqueId: uuid(),
                    start: newStartDate,
                    end: newEndDate,
                    virtual: true,
                    dragging: false,
                };
                if (newTarea[groupKey] === srcGroupId) {
                    newTarea[groupKey] = destGroupId;
                }
                if (view === GROUP_BY_OPERARIOS && !existingOperarioIds.includes(newTarea.operarioId)) {
                    existingOperarioIds.push(newTarea.operarioId);
                    newTareas.push(newTarea);
                } else if (view === GROUP_BY_VEHICULOS) {
                    newTareas.push(newTarea);
                }
            }

            newTareas
                .filter((tarea) => tarea.planificacionId === evento.planificacionId)
                .forEach((tarea) => {
                    generateNewTarea(tarea);
                });

            if (
                dragEvent.originalStartDate &&
                dragEvent.originalTransformedTareas &&
                dragEvent.originalStartDate !== formatISODate(start)
            ) {
                dragEvent.originalTransformedTareas.forEach((tarea) => {
                    newTareas.push({ ...tarea, dragging: true });

                    generateNewTarea(tarea);
                });
            }

            return newTareas;
        });
    }

    const showMoverDrawer = Boolean(dragEvent || moveEventList.length > 0);

    if (tareas === null) {
        return (
            <Paper elevation={0} className={classes.emptyWrapper}>
                <div className={classes.empty}>
                    <CircularProgress />
                    <Typography variant='h1'>Cargando servicios</Typography>
                    <Typography variant='body2' className={classes.emptyText}>
                        Cargando servicios planificados en las fechas seleccionadas.
                    </Typography>
                </div>
            </Paper>
        );
    }

    let groupIds = [];
    if (view === GROUP_BY_OPERARIOS) groupIds = sortedOperarioIds;
    else if (view === GROUP_BY_VEHICULOS) groupIds = sortedVehiculoIds;
    else if (view === GROUP_BY_SERVICIOS) groupIds = [...new Set(tareas.map((t) => t.cliente))];

    if (view === GROUP_BY_SERVICIOS && tareas.length === 0) {
        return (
            <Paper elevation={0} className={classes.emptyWrapper}>
                <div className={classes.empty}>
                    <img src='/images/empty-partes-trabajo.png' />
                    <Typography variant='h1'>No hay servicios planificados</Typography>
                    <Typography variant='body2' className={classes.emptyText}>
                        No se han encontrado servicios planificados en las fechas seleccionadas. Puedes crear un nuevo
                        servicio y planificarlo en el calendario.
                    </Typography>
                    <NuevaTareaDialog
                        onSave={(tarea) => refreshTarea(tarea.id)}
                        button={
                            <Button color='info' aria-label='Añadir servicio' startIcon={<AddIcon />}>
                                Añadir servicio
                            </Button>
                        }
                    />
                </div>
            </Paper>
        );
    }

    return (
        <CalendarioPlanificacionContextProvider startDate={start}>
            <DndContext
                sensors={sensors}
                collisionDetection={pointerWithin}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDragCancel={onDragCancel}
                onDragOver={onDragOver}
                measuring={{
                    droppable: {
                        frequency: 500,
                        strategy: MeasuringStrategy.Always,
                    },
                }}
            >
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <div
                        className={clsx(
                            classes.root,
                            showPendientesPlanificar ? 'showSinPlanificar' : null,
                            showMoverDrawer ? 'showMover' : null,
                        )}
                    >
                        <CalendarioHeaderFilters
                            showOnlyColumn={showOnlyColumn}
                            days={days}
                            noLaborables={noLaborables}
                            onFilter={onFilter}
                        />
                        <SortableContext items={groupIds} strategy={verticalListSortingStrategy}>
                            {groupedTareas[SIN_ASIGNAR] && (
                                <CalendarioRow
                                    showOnlyColumn={showOnlyColumn}
                                    group={groupedTareas[SIN_ASIGNAR].group}
                                    maxRows={groupedTareas[SIN_ASIGNAR].maxRows}
                                    linesPerRow={view === GROUP_BY_VEHICULOS ? 3 : 2}
                                    events={groupedTareas[SIN_ASIGNAR].events}
                                    onClick={(tarea, ev) => {
                                        const target = ev.currentTarget;

                                        if (selectedEvent && selectedEvent.anchorEl === target) setSelectedEvent(null);
                                        else
                                            setTimeout(
                                                () => setSelectedEvent({ tarea, anchorEl: target }),
                                                selectedEvent ? 300 : 0,
                                            );
                                    }}
                                />
                            )}
                            {groupIds.map((groupId) => {
                                if (!groupedTareas[groupId]) return null;
                                const { group, maxRows, events } = groupedTareas[groupId];

                                if (showOnlyOperarioActivos && view === GROUP_BY_OPERARIOS && !group.activo)
                                    return null;

                                return (
                                    <DraggableCalendarioRow
                                        key={groupId}
                                        showOnlyColumn={showOnlyColumn}
                                        group={group}
                                        maxRows={maxRows}
                                        linesPerRow={view === GROUP_BY_VEHICULOS ? 3 : 2}
                                        events={events}
                                        onClick={(tarea, ev) => {
                                            const target = ev.currentTarget;

                                            if (selectedEvent && selectedEvent.anchorEl === target)
                                                setSelectedEvent(null);
                                            else
                                                setTimeout(
                                                    () => setSelectedEvent({ tarea, anchorEl: target }),
                                                    selectedEvent ? 300 : 0,
                                                );
                                        }}
                                        draggable={view !== GROUP_BY_SERVICIOS}
                                    />
                                );
                            })}
                        </SortableContext>

                        <BaseEditPlanificacionDialog
                            open={Boolean(selectedPendientePlanificar)}
                            onClose={() => setSelectedPendientePlanificar(null)}
                            tarea={selectedPendientePlanificar}
                            onSave={() => {
                                refreshTarea(selectedPendientePlanificar.id);
                                setSelectedPendientePlanificar(null);
                                tareasSinPlanificarRef.current.refresh();
                            }}
                        />
                        <TareasSinPlanificarDrawer
                            ref={tareasSinPlanificarRef}
                            showPendientesPlanificar={showPendientesPlanificar}
                            onClosePendientesPlanificar={onClosePendientesPlanificar}
                        />
                        <MoverServiciosDrawer open={showMoverDrawer} eventos={moveEventList} />
                        <DragOverlay>
                            {dragEvent ? <MoverServicioItem event={dragEvent} isDragging /> : null}
                        </DragOverlay>
                    </div>
                    <div className={classes.scrollable} ref={scrollableRef}>
                        <div
                            className={clsx(
                                classes.root,
                                showPendientesPlanificar ? 'showSinPlanificar' : null,
                                showMoverDrawer ? 'showMover' : null,
                            )}
                        >
                            <CalendarioHeader
                                showOnlyColumn={showOnlyColumn}
                                days={days}
                                noLaborables={noLaborables}
                                onFilter={onFilter}
                            />
                            {groupedTareas[SIN_ASIGNAR] && (
                                <CalendarioRowDays
                                    numDays={numDays}
                                    showOnlyColumn={showOnlyColumn}
                                    group={groupedTareas[SIN_ASIGNAR].group}
                                    maxRows={groupedTareas[SIN_ASIGNAR].maxRows}
                                    linesPerRow={view === GROUP_BY_VEHICULOS ? 3 : 2}
                                    events={groupedTareas[SIN_ASIGNAR].events}
                                    onClick={(tarea, ev) => {
                                        const target = ev.currentTarget;

                                        if (selectedEvent && selectedEvent.anchorEl === target) setSelectedEvent(null);
                                        else
                                            setTimeout(
                                                () => setSelectedEvent({ tarea, anchorEl: target }),
                                                selectedEvent ? 300 : 0,
                                            );
                                    }}
                                />
                            )}
                            {groupIds.map((groupId) => {
                                if (!groupedTareas[groupId]) return null;
                                const { group, maxRows, events } = groupedTareas[groupId];

                                if (showOnlyOperarioActivos && view === GROUP_BY_OPERARIOS && !group.activo)
                                    return null;

                                const noLaborablesOperario =
                                    view === GROUP_BY_OPERARIOS ? noLaborablesOperarios[groupId] : [];

                                return (
                                    <CalendarioRowDays
                                        key={groupId}
                                        numDays={numDays}
                                        showOnlyColumn={showOnlyColumn}
                                        group={group}
                                        maxRows={maxRows}
                                        noLaborables={noLaborablesOperario}
                                        linesPerRow={view === GROUP_BY_VEHICULOS ? 3 : 2}
                                        events={events}
                                        onClick={(tarea, ev) => {
                                            const target = ev.currentTarget;

                                            if (selectedEvent && selectedEvent.anchorEl === target)
                                                setSelectedEvent(null);
                                            else
                                                setTimeout(
                                                    () => setSelectedEvent({ tarea, anchorEl: target }),
                                                    selectedEvent ? 300 : 0,
                                                );
                                        }}
                                        draggable
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </DndContext>

            <ServicioInfoTooltip
                event={selectedEvent}
                onClose={() => setSelectedEvent(null)}
                TareaActionsProps={{
                    canColorize: true,
                    onTareaChanged: (id) =>
                        tareasProvider.getByIdForCalendar(id).then((tarea) => {
                            setSelectedEvent(null);
                            setTareas((tareas) => tareas.map((oldTarea) => (oldTarea.id === id ? tarea : oldTarea)));
                            // updateTarea(tarea.id, tarea);
                        }),
                    updateTarea: (...args) => {
                        setSelectedEvent(null);
                        updateTarea(...args);
                    },
                }}
            />
        </CalendarioPlanificacionContextProvider>
    );
}

CalendarioContent.propTypes = {
    days: PropTypes.any,
    end: PropTypes.any,
    hidePastPlanificaciones: PropTypes.bool,
    showOnlyColumn: PropTypes.any,
    showOnlyOperarioActivos: PropTypes.any,
    start: PropTypes.any,
    view: PropTypes.any,
    showPendientesPlanificar: PropTypes.bool,
    onClosePendientesPlanificar: PropTypes.func,
    colorSource: PropTypes.any,
    noLaborables: PropTypes.any,
    onFilter: PropTypes.any,
};
