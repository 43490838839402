import DialogForm from '../common/forms/DialogForm';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { companiesProvider } from '../../api';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';

const ConfigurarHorquillaSchema = Yup.object().shape({
    minutos_tolerancia_fichajes: Yup.number().required('Requerido').min(0, 'Tiene que ser un número positivo'),
});

const useStyles = makeStyles(
    (theme) => ({
        helper: {
            color: theme.palette.neutral.primary,
        },
    }),
    { name: 'ConfigurarHorquillaDialog' },
);

function ConfigurarHorquillaDialog(props) {
    const [preferencias, setPreferencias] = useState({ minutos_tolerancia_fichajes: 0 });
    const classes = useStyles();
    const snackbar = useSnackbar();

    useEffect(() => {
        if (!props.open) return;

        companiesProvider.getPreferencias().then(setPreferencias);
    }, [props.open]);

    return (
        <DialogForm
            title='Configurar ajuste de marcajes'
            maxWidth='xs'
            FormikProps={{
                initialValues: {
                    minutos_tolerancia_fichajes: preferencias.minutos_tolerancia_fichajes,
                },
                enableReinitialize: true,
                validationSchema: ConfigurarHorquillaSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    companiesProvider
                        .savePreferencias({
                            ...preferencias,
                            ...values,
                        })
                        .then(() => {
                            snackbar.showMessage('Ajustes guardados con éxito');
                            props.onClose();
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ values, setFieldValue }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='subtitle2' className={classes.helper}>
                            En el caso de que los operarios marquen con unos minutos de más o de menos, con esta opción
                            se ajustarán las horas marcadas según su horario de trabajo, siempre y cuando la diferencia
                            entre la hora marcada y el horario del operario no supere el margen de tiempo establecido.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='subtitle2' className={classes.helper}>
                            <strong>Ejemplo práctico: </strong>
                            El operario tiene un horario de 8:00 a 12:00. Si marca a las 8:07, y hay configurado un
                            margen de al menos 7 minutos, la hora ajustada serán las 8:00. En caso contrario, no se
                            produce ningun cambio en la hora marcada.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name='minutos_tolerancia_fichajes'
                            type='number'
                            label='Márgen de tiempo'
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>minutos</InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(ConfigurarHorquillaDialog);

ConfigurarHorquillaDialog.propTypes = {
    onClose: PropTypes.any,
    open: PropTypes.any,
};
