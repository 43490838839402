import PreparacionesMaterialList from './PreparacionesMaterialList';
import ComprasMaterialList from './ComprasMaterialList';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SolicitudesMaterialList from './SolicitudesMaterialList';
import useAuthState from '../../AuthState';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(3)}px`,
        },
    }),
    { name: 'PreparacionesMaterial' },
);

export default function PreparacionesMaterial() {
    const classes = useStyles();
    const {
        userInfo: {
            preferencias: {
                usar_solicitudes_material: usarSolicitudesMaterial,
                usar_preparacion_materiales_lista: usarPreparacionMaterialesLista,
            } = {},
        },
    } = useAuthState();

    return (
        <div className={classes.root}>
            <Typography variant='h1'>Preparación de material</Typography>
            {usarSolicitudesMaterial && <SolicitudesMaterialList />}
            <PreparacionesMaterialList />
            {!usarPreparacionMaterialesLista && <ComprasMaterialList />}
        </div>
    );
}
