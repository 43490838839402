import React, { useEffect } from 'react';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

const Autocomplete = withStyles((theme) => ({
    root: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    inputRoot: {
        '& $input:first-child': {
            padding: theme.spacing(0.5),
        },
    },
    input: {},
    option: {
        display: 'flex',
        alignItems: 'center',
        '& > span': {
            flex: 1,
        },
        '& > svg': {
            fontSize: 18,
        },
    },
}))(MuiAutocomplete);

export default function SelectCompany({ companiesOptions, defaultCompanyId, onChange }) {
    const [inputValue, setInputValue] = React.useState('');
    const [value, setValue] = React.useState(null);

    useEffect(() => {
        if (companiesOptions.length === 0) return;

        if (!defaultCompanyId) {
            return;
            // setValue(companiesOptions[0]);
            // onChange(companiesOptions[0]);
        }

        setValue(companiesOptions.find((op) => op.id === defaultCompanyId) || companiesOptions[0]);
    }, [defaultCompanyId, companiesOptions]);

    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                onChange(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(option) => (
                <React.Fragment>
                    <span>{option.name}</span>
                </React.Fragment>
            )}
            getOptionSelected={(option, value) => option.id === value.id}
            id='controllable-states-demo'
            options={companiesOptions}
            style={{ width: 300 }}
            renderInput={(params) => (
                <TextField {...params} label='' variant='outlined' placeholder='Selecciona una empresa' />
            )}
        />
    );
}

SelectCompany.propTypes = {
    companiesOptions: PropTypes.any,
    defaultCompanyId: PropTypes.any,
    onChange: PropTypes.any,
};
