import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Alert from '@material-ui/lab/Alert';
import add from 'date-fns/add';
import sub from 'date-fns/sub';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fichajesProvider } from '../../api';
import DownloadIcon from '../icons/Download';
import SelectFecha from '../solicitudes/SelectFecha';
import SelectOperario from './SelectOperario';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        color: '#8F95AF',
        padding: theme.spacing(2, 3),
        marginBottom: theme.spacing(2),
    },
    helper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        textTransform: 'none',
        textDecoration: 'underline',
        color: 'white',
        padding: '1px 4px',
    },
}));

export default function FichajesFilters({ onChangeOperario, defaultOperarioId, currentMonth, setCurrentMonth }) {
    const history = useHistory();
    const classes = useStyles();
    const [selectedOperario, setSelectedOperario] = useState(null);

    const exportar = () => {
        fichajesProvider
            .exportar(currentMonth)
            .then(async (data) => {
                const uri = window.URL.createObjectURL(await data.blob());

                const a = document.createElement('a');
                a.style = { display: 'none' };
                a.href = uri;
                a.download = data.headers.get('Content-Disposition').split('filename=')[1];
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(uri);
                document.body.removeChild(a);
            })
            .catch((err) => {
                // snackbar.showMessage('Ha ocurrido un error durante la descarga');
                console.error(err);
            });
    };

    return (
        <>
            <Paper className={classes.root} elevation={0}>
                Fichajes de:
                <SelectOperario
                    defaultOperarioId={defaultOperarioId}
                    onChange={(option) => {
                        onChangeOperario(option);
                        setSelectedOperario(option);
                    }}
                />
                en la fecha:
                <IconButton onClick={() => setCurrentMonth((date) => sub(date, { months: 1 }))}>
                    <NavigateBeforeIcon />
                </IconButton>
                <SelectFecha fecha={currentMonth} onChange={setCurrentMonth} />
                <IconButton onClick={() => setCurrentMonth((date) => add(date, { months: 1 }))}>
                    <NavigateNextIcon />
                </IconButton>
                <Tooltip title='Descargar XLS con los fichajes de todos los operarios'>
                    <IconButton style={{ marginLeft: 'auto' }} onClick={exportar}>
                        <DownloadIcon />
                    </IconButton>
                </Tooltip>
            </Paper>
            {selectedOperario && !selectedOperario.has_horarios && (
                <Alert variant='filled' severity={'info'} style={{ marginBottom: 16 }}>
                    Este operario no tiene el horario de trabajo configurado. Sus horas extra se calcularán en base a
                    una jornada de 8 horas.
                    <Button
                        onClick={() => history.push(`/empleados/${selectedOperario.id}`)}
                        className={classes.button}
                    >
                        Configurar horario de trabajo
                    </Button>
                </Alert>
            )}
        </>
    );
}

FichajesFilters.propTypes = {
    currentMonth: PropTypes.any,
    defaultOperarioId: PropTypes.any,
    onChangeOperario: PropTypes.any,
    setCurrentMonth: PropTypes.any,
};
