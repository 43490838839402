import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import React from 'react';

export const MenuItem = withStyles(
    (theme) => ({
        root: {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                marginLeft: theme.spacing(1),
            },
        },
    }),
    { name: 'MenuItem' },
)(MuiMenuItem);

export default function MasterItemOptions({ children }) {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const openMenu = (event) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
    };

    const closeMenu = (event) => {
        event && event.stopPropagation();
        setMenuAnchorEl(null);
    };

    function renderMenuItem(label, onClick) {
        return (
            <MenuItem
                onClick={(ev) => {
                    closeMenu(ev);
                    onClick();
                }}
            >
                {label}
            </MenuItem>
        );
    }

    function renderButtonComponentMenuItem(label, Component, ComponentProps) {
        return (
            <Component
                {...ComponentProps}
                button={
                    <MenuItem
                        onClick={(ev) => {
                            closeMenu(ev);
                        }}
                    >
                        {label}
                    </MenuItem>
                }
            />
        );
    }

    return (
        <>
            <IconButton onClick={openMenu}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id='simple-menu'
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={closeMenu}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {children({ closeMenu, renderMenuItem, renderButtonComponentMenuItem })}
            </Menu>
        </>
    );
}

MasterItemOptions.propTypes = {
    children: PropTypes.any.isRequired,
};
