import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { contratosMantenimientoProvider } from '../../../api';
import { formatDate } from '../../../utils';
import Button from '../../common/Button';
import DeleteDialog from '../../common/dialogs/DeleteDialog';
import { SwitchWithLabel } from '../../common/fields/Switch';
import ServicioEstadoChip from '../../servicios/ServicioEstadoChip';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(1.5),
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(1)}px`,
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightMedium,
                flex: 1,
            },
        },
        emptyText: {
            color: theme.palette.neutral.primary,
        },
        cuestionarios: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(0.5)}px`,
        },
        label: {
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.neutral.label,
            marginTop: theme.spacing(0.5),
        },
        error: {
            color: theme.palette.error.main,
            fontWeight: theme.typography.fontWeightRegular,
        },
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
            gap: `${theme.spacing(1)}px`,
            marginTop: theme.spacing(2),
        },
    }),
    { name: 'ServicioPlanificador' },
);

export default function ServicioPlanificador({ servicioKey, operariosOptions, onDelete, onSave }) {
    const classes = useStyles();

    const [{ value: servicio }, { error, initialValue }, { setValue }] = useField(servicioKey);
    const [{ value: cuestionarios }, ,] = useField('cuestionarios');
    const [{ value: tiposEquipo }, ,] = useField('tipos_equipo');

    const cuestionariosDisponibles = cuestionarios.filter((c) =>
        c.tipos_equipo.some((t) => servicio.tipos_equipo.find((t2) => t2.id === t)),
    );

    const hasError = Boolean(error);

    let changed = false;
    if (servicio.id) {
        const initialTipos = initialValue.tipos_equipo.map((t) => t.id);
        const tiposEqual =
            initialTipos.length === servicio.tipos_equipo.length &&
            servicio.tipos_equipo.every((t) => initialTipos.includes(t.id));

        const initialCuestionarios = initialValue.cuestionarios.map((t) => t.id);
        const cuestionariosEqual =
            initialCuestionarios.length === servicio.cuestionarios.length &&
            servicio.cuestionarios.every((t) => initialCuestionarios.includes(t.id));

        changed = !tiposEqual || !cuestionariosEqual;
    }

    return (
        <Paper elevation={0} className={classes.root}>
            <div className={classes.title}>
                <Typography>{formatDate(servicio.fecha)}</Typography>
                {servicio.estado && <ServicioEstadoChip value={servicio.estado} />}

                {servicio.id && servicio.estado === 'PENDIENTE' && (
                    <DeleteDialog
                        text='¿Estás seguro que quieres eliminar este servicio?'
                        onConfirm={(ev) => {
                            contratosMantenimientoProvider.deleteTarea(servicio.id).then(onDelete);
                        }}
                        button={
                            <IconButton size='small'>
                                <DeleteIcon size='small' />
                            </IconButton>
                        }
                        confirmationText='borrar servicio'
                    />
                )}
                {!servicio.id && (
                    <IconButton size='small' onClick={onDelete}>
                        <CloseIcon size='small' />
                    </IconButton>
                )}
            </div>
            <div className={classes.cuestionarios}>
                <Typography variant='subtitle2' className={classes.label}>
                    Tipos de equipo a revisar
                </Typography>
                {tiposEquipo.map((tipo) => {
                    const checked = Boolean(servicio.tipos_equipo.find((t) => t.id === tipo.id));
                    return (
                        <SwitchWithLabel
                            key={tipo.id}
                            label={tipo.nombre}
                            field={{
                                value: checked,
                                onChange: (ev) => {
                                    setValue({
                                        ...servicio,
                                        cuestionarios: [],
                                        tipos_equipo: checked
                                            ? servicio.tipos_equipo.filter((t) => t.id !== tipo.id)
                                            : [...servicio.tipos_equipo, tipo],
                                    });
                                },
                            }}
                        />
                    );
                })}
                {cuestionariosDisponibles.length > 0 && (
                    <>
                        <Typography variant='subtitle2' className={classes.label}>
                            Cuestionarios a rellenar
                        </Typography>

                        {cuestionariosDisponibles.map((cuestionario) => {
                            const checked = Boolean(servicio.cuestionarios.find((c) => c.id === cuestionario.id));
                            return (
                                <SwitchWithLabel
                                    key={cuestionario.id}
                                    label={cuestionario.nombre}
                                    field={{
                                        value: checked,
                                        onChange: (ev) => {
                                            setValue({
                                                ...servicio,
                                                cuestionarios: checked
                                                    ? servicio.cuestionarios.filter((c) => c.id !== cuestionario.id)
                                                    : [...servicio.cuestionarios, cuestionario],
                                            });
                                        },
                                    }}
                                />
                            );
                        })}
                    </>
                )}
                {hasError && (
                    <Typography variant='subtitle2' className={classes.error}>
                        {error.tipos_equipo}
                        {!error.tipos_equipo && error.cuestionarios}
                    </Typography>
                )}
                {changed && (
                    <div className={classes.buttons}>
                        <Button color='outlined' onClick={() => setValue(initialValue)}>
                            Cancelar
                        </Button>
                        <Button color='info' onClick={() => onSave(servicio)}>
                            Guardar
                        </Button>
                    </div>
                )}
            </div>
        </Paper>
    );
}

ServicioPlanificador.propTypes = {
    onDelete: PropTypes.any,
    onSave: PropTypes.any,
    operariosOptions: PropTypes.any,
    servicioKey: PropTypes.any,
};
