import { DialogActions } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { subtareasProvider } from '../../../api';
import { useFetchData } from '../../../hooks/useFetchData';
import Button from '../../common/Button';
import ListView from '../../common/list/ListView';
import NewSubtareaDialog from '../../companies/subtareas/NewSubtareaDialog';

const useStyles = makeStyles(
    (theme) => ({
        root: {},
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            lineHeight: '50px',
            paddingLeft: theme.spacing(2),
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        body: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(3)}px`,
            padding: 0,
            '&>div': {
                display: 'flex',
            },
        },
        actions: {
            marginTop: theme.spacing(2),
            justifyContent: 'flex-end',
        },
    }),
    { name: 'SubtareasListDialog' },
);

function SubtareasListDialog({ open, onClose, onSave, currentSubtareaIds }) {
    const classes = useStyles();

    const fetchDataFn = useCallback(() => subtareasProvider.getAll(), [currentSubtareaIds]);
    const { data, fetchData } = useFetchData(fetchDataFn);
    const [selectedRowIds, setSelectedRowIds] = useState([]);

    const snackbar = useSnackbar();

    useEffect(() => {
        if (!open) return;
        fetchData();
    }, [open, fetchData]);

    const onDelete = (idx) => {
        subtareasProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Codigo',
                accessor: 'codigo',
            },
            {
                Header: 'Titulo',
                accessor: 'titulo',
            },
            {
                Header: 'Categoría',
                accessor: 'categoria',
            },
        ],
        [],
    );

    const options = useMemo(() => {
        const defaultSelectedRowIds =
            data.length === 0 || !currentSubtareaIds
                ? []
                : Object.fromEntries(currentSubtareaIds.map((id) => [data.findIndex((row) => row.id === id), true]));

        return {
            useActions: false,
            tableOptions: {
                fillEmptySpace: true,
                showTablaFiltrada: false,
                useSelect: true,
                initialState: {
                    selectedRowIds: defaultSelectedRowIds,
                },
            },
            canExportCsv: false,
            canToggleColumns: false,
            batchComponents: (
                <NewSubtareaDialog
                    button={
                        <Button color='transparent' aria-label='Nueva tarea' startIcon={<AddIcon />}>
                            Nueva tarea
                        </Button>
                    }
                    onSave={fetchData}
                />
            ),
        };
    }, [currentSubtareaIds, data]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            id='mouse-over-popover'
            classes={{
                paper: classes.root,
            }}
            fullWidth={true}
            maxWidth='sm'
        >
            <ListView
                tableTitle='Seleccionar tareas'
                basePath='/subtareas'
                columns={columns}
                data={data}
                options={options}
                onDelete={onDelete}
                onSelectedChange={(rowIds) => {
                    setSelectedRowIds(Object.keys(rowIds).map((x) => parseInt(x)));
                }}
            />
            <DialogActions>
                <Button color='outlined' onClick={onClose}>
                    Cancelar
                </Button>
                <Button
                    color='info'
                    onClick={() => {
                        onClose();
                        onSave(selectedRowIds.map((rowIdx) => data[rowIdx]));
                    }}
                >
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SubtareasListDialog;

SubtareasListDialog.propTypes = {
    currentSubtareaIds: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};
