import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiSwitch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

export const Switch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
    },
    switchBase: {
        padding: 2,
        color: '#F3F5FA',
        '&$checked': {
            transform: 'translateX(12px)',
            color: '#F3F5FA',
            '& + $track': {
                opacity: 1,
                backgroundColor: '#2196F3',
                border: 'none',
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        opacity: 1,
        backgroundColor: '#e2e2e2',
    },
    checked: {},
}))(MuiSwitch);

const FormControlLabel = withStyles(
    (theme) => ({
        root: {
            marginLeft: 0,
        },
        label: {
            fontSize: (props) => {
                const variant = props.labelVariant ?? 'body2';
                return theme.typography[variant].fontSize;
            },
            marginLeft: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            '& .MuiSvgIcon-root': {
                fontSize: 18,
            },
        },
    }),
    { name: 'FormControlLabel' },
)(({ labelVariant, ...props }) => React.createElement(MuiFormControlLabel, props));

export function SwitchWithLabel({ label, field, ...props }) {
    return (
        <FormControlLabel
            control={<Switch name={field.name} onChange={field.onChange} checked={field.value} />}
            label={label}
            {...props}
        />
    );
}

SwitchWithLabel.propTypes = {
    field: PropTypes.any,
    label: PropTypes.any,
    labelVariant: PropTypes.any,
};

export function SwitchWithoutLabel({ label, field, ...props }) {
    return <Switch name={field.name} onChange={field.onChange} checked={field.value} {...props} />;
}

SwitchWithoutLabel.propTypes = {
    field: PropTypes.any,
    label: PropTypes.any,
};

export function SwitchWithLabelField({ label: labelText, helpTooltip, ...props }) {
    let label = labelText;
    if (helpTooltip)
        label = (
            <>
                {label}
                <Tooltip title={helpTooltip} arrow>
                    <InfoIcon />
                </Tooltip>
            </>
        );

    return <Field component={SwitchWithLabel} label={label} {...props} />;
}

SwitchWithLabelField.propTypes = {
    label: PropTypes.any,
    helpTooltip: PropTypes.any,
    labelVariant: PropTypes.any,
};
