import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';

export default function PrecioFields({
    values,
    setFieldValue,
    disabled = false,
    precioCosteDisabled = false,
    gridWidth = 3,
    precioCosteKey = 'precio_coste',
    precioVentaKey = 'precio_venta',
    margenKey = 'margen',
    precioCosteLabel = 'Precio coste',
    precioVentaLabel = 'Precio venta',
    margenLabel = 'Margen',
}) {
    return (
        <>
            <Grid item xs={gridWidth}>
                <TextField
                    name={precioCosteKey}
                    label={precioCosteLabel}
                    fullWidth
                    type='number'
                    disabled={precioCosteDisabled || disabled}
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                        onBlur: (ev) => {
                            const precioCoste = ev.target.value;
                            const margen = values[margenKey];

                            const precioVenta = precioCoste * (1 + margen / 100);
                            setFieldValue(precioVentaKey, Math.round((precioVenta + Number.EPSILON) * 100) / 100);
                        },
                    }}
                />
            </Grid>
            <Grid item xs={gridWidth}>
                <TextField
                    name={precioVentaKey}
                    label={precioVentaLabel}
                    fullWidth
                    type='number'
                    disabled={disabled}
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                        onBlur: (ev) => {
                            const precioCoste = values[precioCosteKey];
                            const precioVenta = ev.target.value;

                            const margen = (precioVenta / precioCoste - 1) * 100;
                            setFieldValue(margenKey, Math.round((margen + Number.EPSILON) * 100) / 100);
                        },
                    }}
                />
            </Grid>
            <Grid item xs={gridWidth}>
                <TextField
                    name={margenKey}
                    label={margenLabel}
                    fullWidth
                    type='number'
                    disabled={disabled}
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                        onBlur: (ev) => {
                            const precioCoste = values[precioCosteKey];
                            const margen = ev.target.value;

                            const precioVenta = precioCoste * (1 + margen / 100);
                            setFieldValue(precioVentaKey, Math.round((precioVenta + Number.EPSILON) * 100) / 100);
                        },
                    }}
                />
            </Grid>
        </>
    );
}

PrecioFields.propTypes = {
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    precioCosteDisabled: PropTypes.bool,
    gridWidth: PropTypes.number,
    precioCosteKey: PropTypes.string,
    precioVentaKey: PropTypes.string,
    margenKey: PropTypes.string,
    precioCosteLabel: PropTypes.string,
    precioVentaLabel: PropTypes.string,
    margenLabel: PropTypes.string,
    disabled: PropTypes.bool,
};
