import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { presupuestosProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import DialogForm from '../../common/forms/DialogForm';
import PrecioFields from './PrecioFields';

const CapituloSchema = Yup.object().shape({
    referencia: Yup.string(),
    concepto: Yup.string().required('Requerido'),
    unidades: Yup.number()
        .required('Requerido')
        .test('gt-0', 'Debe ser mayor que 0', (value) => value > 0),
});

function EditCapituloDialog({ presupuestoId, capitulo, onSave, editable, ...props }) {
    return (
        <DialogForm
            title='Editar capítulo'
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    referencia: capitulo.referencia ?? '',
                    concepto: capitulo.concepto,
                    unidades: capitulo.unidades,
                    precio_coste: capitulo.precio_coste,
                    precio_venta: capitulo.precio_venta,
                    margen: capitulo.margen * 100,
                },
                validationSchema: CapituloSchema,
                onSubmit: ({ margen, ...values }, { setSubmitting, setFieldError }) => {
                    presupuestosProvider
                        .updateLinea(presupuestoId, capitulo.id, values)
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ values, setFieldValue }) => {
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField name='referencia' label='Referencia' fullWidth />
                        </Grid>
                        <Grid item xs={9}>
                            <TextField name='concepto' label='Concepto' fullWidth />
                        </Grid>
                        <PrecioFields
                            setFieldValue={setFieldValue}
                            values={values}
                            precioCosteDisabled
                            disabled={!editable}
                        />
                    </Grid>
                );
            }}
        </DialogForm>
    );
}

EditCapituloDialog.propTypes = {
    presupuestoId: PropTypes.string,
    capitulo: PropTypes.any,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    editable: PropTypes.bool,
};

export default withButtonOpener(EditCapituloDialog);
