import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { presupuestosProvider } from '../../api';
import { estadoPresupuestoOptions } from '../../api/presupuestos';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import ClienteField from '../common/fields/ClienteField';
import DireccionField from '../common/fields/DireccionField';
import FileUploader from '../common/fields/FileUploader';
import { KeyboardDatePicker } from '../common/fields/KeyboardDatePicker';
import ProyectoField from '../common/fields/ProyectoField';
import SelectField from '../common/fields/SelectField';
import DialogForm from '../common/forms/DialogForm';
import PrecioFields from './PresupuestoView/PrecioFields';

const PresupuestoSchema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
    cliente: Yup.mixed().required('Selecciona un cliente'),
    direccion: Yup.mixed().required('Selecciona una direccion'),
    importe_coste: Yup.number().required('Requerido'),
    importe_venta: Yup.number().required('Requerido'),
});

export function NewPresupuestoDialogBase({
    onSave,
    clienteId,
    direccionId,
    proyectoId,
    duplicarPresupuesto,
    ...props
}) {
    return (
        <DialogForm
            title={duplicarPresupuesto ? 'Duplicar presupuesto' : 'Añadir presupuesto'}
            FormikProps={{
                initialValues: {
                    cliente: clienteId ? { id: clienteId } : null,
                    direccion: direccionId ? { id: direccionId } : null,
                    proyecto: proyectoId ? { id: proyectoId } : null,
                    nombre: '',
                    fecha_creacion: new Date(),
                    fecha_envio: null,
                    fecha_final: null,
                    estado: 'PENDIENTE',
                    notas: '',
                    importe_coste: 0,
                    importe_venta: 0,
                    margen: 0,
                    files: [],
                },
                validationSchema: PresupuestoSchema,
                onSubmit: ({ cliente, direccion, proyecto, files, ...values }, { setSubmitting, setFieldError }) => {
                    const formData = new FormData();
                    const formValues = {};

                    Object.entries(values).forEach(([key, value]) => (formValues[key] = value));
                    formValues.cliente_id = cliente.id;
                    formValues.direccion_id = direccion.id;
                    formValues.proyecto_id = proyecto ? proyecto.id : null;
                    formValues.presupuesto_id = duplicarPresupuesto ? duplicarPresupuesto.id : null;
                    formData.append('params', JSON.stringify(formValues));

                    new Array(...files).forEach((file) => formData.append('files', file));

                    presupuestosProvider
                        .save(formData)
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ touched, errors, values, setFieldValue }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ClienteField
                            error={touched.cliente && !!errors.cliente}
                            helperText={touched.cliente && errors.cliente}
                            disabled={Boolean(clienteId)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DireccionField
                            error={touched.direccion && !!errors.direccion}
                            helperText={touched.direccion && errors.direccion}
                            disabled={Boolean(direccionId)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ProyectoField
                            error={touched.proyecto && !!errors.proyecto}
                            helperText={touched.proyecto && errors.proyecto}
                            disabled={Boolean(proyectoId)}
                            filterByDireccion
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='referencia_interna' label='Ref. interna' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <Field
                            name='estado'
                            id='estado'
                            component={SelectField}
                            label='Estado'
                            fullWidth
                            selectOptions={estadoPresupuestoOptions}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name='nombre' label='Nombre' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name='notas'
                            label='Notas'
                            fullWidth
                            multiline
                            minRows={4}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <KeyboardDatePicker
                            name='fecha_creacion'
                            label='Fecha de creación'
                            format='dd/MM/yyyy'
                            autoOk
                            ampm={false}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <KeyboardDatePicker
                            name='fecha_envio'
                            label='Fecha de envio'
                            format='dd/MM/yyyy'
                            autoOk
                            ampm={false}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <KeyboardDatePicker
                            name='fecha_final'
                            label='Fecha final'
                            format='dd/MM/yyyy'
                            autoOk
                            ampm={false}
                            fullWidth
                        />
                    </Grid>
                    <PrecioFields
                        setFieldValue={setFieldValue}
                        values={values}
                        precioCosteLabel='Importe coste'
                        precioCosteKey='importe_coste'
                        precioVentaLabel='Importe venta'
                        precioVentaKey='importe_venta'
                        gridWidth={4}
                    />
                    <Grid item xs={12}>
                        <FileUploader
                            id='upload-presupuesto-adjuntos-file'
                            label='Añadir adjuntos'
                            file={values.files}
                            setFile={(file) => setFieldValue('files', file ? [file] : [])}
                            isImage={false}
                            accept='image/jpeg,image/gif,image/png,application/pdf'
                        />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(NewPresupuestoDialogBase);

NewPresupuestoDialogBase.propTypes = {
    clienteId: PropTypes.any,
    direccionId: PropTypes.any,
    proyectoId: PropTypes.any,
    duplicarPresupuesto: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
