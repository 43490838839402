import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { companiesProvider } from '../../../../../api';
import { withButtonOpener } from '../../../../../hooks/withButtonOpener';
import Button from '../../../../common/Button';
import DialogForm from '../../../../common/forms/DialogForm';
import CategoriaColorEditor from './CategoriaColorEditor';

const CategoriaTareaSchema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
});

const useStyles = makeStyles(
    (theme) => ({
        dialogPaper: {},
        colorEditor: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'NewCategoriaTareaDialog' },
);

function NewCategoriaTareaDialog({ onSave, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    return (
        <DialogForm
            title='Nueva categoría de tarea'
            maxWidth='sm'
            className={classes.dialogPaper}
            FormikProps={{
                initialValues: {
                    nombre: '',
                    color_bg: null,
                    color_fg: null,
                },
                enableReinitialize: true,
                validationSchema: CategoriaTareaSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    companiesProvider
                        .createCategoriaTarea(values)
                        .then((data) => {
                            onSave && onSave(data.id);
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {({ values, setFieldValue }) => {
                const color = { color_bg: values.color_bg, color_fg: values.color_fg };
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField name='nombre' label='Nombre' fullWidth />
                        </Grid>
                        <Grid item xs={12} className={classes.colorEditor}>
                            <CategoriaColorEditor
                                color={color}
                                onChange={(color) => {
                                    setFieldValue('color_bg', color.color_bg);
                                    setFieldValue('color_fg', color.color_fg);
                                    return true;
                                }}
                                button={<Button color='info'>Cambiar color</Button>}
                            />
                        </Grid>
                    </Grid>
                );
            }}
        </DialogForm>
    );
}

export default withButtonOpener(NewCategoriaTareaDialog);

NewCategoriaTareaDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};
