import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import PartesSinRevisar from './PartesSinRevisar';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'RevisionPartes' },
);

export default function RevisionPartes() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant='h1' component='h1'>
                Revisión de partes de trabajo
            </Typography>
            <PartesSinRevisar />
        </div>
    );
}
