import MenuItem from '@material-ui/core/MenuItem';
import GestureIcon from '@material-ui/icons/Gesture';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { formatDateTime } from '../../../utils';
import DeleteDialog from '../../common/dialogs/DeleteDialog';
import ItemLabel from '../../common/forms/MasterDetailsView/ItemLabel';
import MasterItem from '../../common/forms/MasterDetailsView/MasterItem';
import MasterItemOptions from '../../common/forms/MasterDetailsView/MasterItemOptions';
import FirmaDialog from '../FirmaDialog';

const PENDIENTE = 'PENDIENTE';
const EN_PROGRESO = 'EN_PROGRESO';
const APTO = 'APTO';
const NO_APTO = 'NO_APTO';

const estadoLabels = {
    [PENDIENTE]: 'Pendiente',
    [EN_PROGRESO]: 'En progreso',
    [APTO]: 'Apto',
    [NO_APTO]: 'No apto',
};

const estadoColors = {
    [PENDIENTE]: null,
    [EN_PROGRESO]: 'warning',
    [APTO]: 'success',
    [NO_APTO]: 'error',
};

function getParteMantenimientoEstado(parteMantenimiento) {
    const terminado = parteMantenimiento.terminado;
    const apto = parteMantenimiento.apto;
    const empezado = parteMantenimiento.respuestas.length > 0;

    let estado = PENDIENTE;
    if (terminado) estado = apto ? APTO : NO_APTO;
    else if (empezado) estado = EN_PROGRESO;

    return estado;
}

export default function ParteMantenimientoItem({ item, href, navigate, onDelete, showHeader = false }) {
    const location = useLocation();
    const history = useHistory();

    const isSelected = location.pathname === href;

    const estado = getParteMantenimientoEstado(item);

    return (
        <MasterItem
            title={`Equipo: ${item.equipo.nombre}`}
            navigate={navigate}
            isSelected={isSelected}
            labels={
                <>
                    {item.firma_cliente && (
                        <ItemLabel
                            icon={<GestureIcon />}
                            label={item.firma_cliente.image_url ? 'Firmado' : 'No firmado'}
                            tooltip={
                                item.firma_cliente.image_url
                                    ? `Firmado el ${formatDateTime(item.firma_cliente.fecha)}`
                                    : ''
                            }
                        />
                    )}
                    <ItemLabel label={estadoLabels[estado]} color={estadoColors[estado]} uppercase width={80} />
                </>
            }
            actions={
                <MasterItemOptions>
                    {({ closeMenu }) => {
                        const options = [
                            <MenuItem
                                key={0}
                                onClick={(event) => {
                                    closeMenu(event);
                                    history.push(`/equipos/${item.equipo_id}`);
                                }}
                            >
                                Ver equipo
                            </MenuItem>,
                        ];

                        if (item.firma_operario)
                            options.push(
                                <FirmaDialog
                                    key={1}
                                    firma={item.firma_operario}
                                    button={<MenuItem onClick={closeMenu}>Ver firma del operario</MenuItem>}
                                />,
                            );
                        if (item.firma_cliente)
                            options.push(
                                <FirmaDialog
                                    key={2}
                                    firma={item.firma_cliente}
                                    button={<MenuItem onClick={closeMenu}>Ver firma del cliente</MenuItem>}
                                />,
                            );

                        options.push(
                            <DeleteDialog
                                key={3}
                                text='¿Estás seguro que quieres eliminar este parte de trabajo?'
                                onConfirm={(ev) => {
                                    closeMenu(ev);
                                    onDelete();
                                }}
                                button={<MenuItem onClick={closeMenu}>Eliminar</MenuItem>}
                                confirmationText='borrar parte de mantenimiento'
                            />,
                        );

                        return options;
                    }}
                </MasterItemOptions>
            }
        />
    );
}

ParteMantenimientoItem.propTypes = {
    item: PropTypes.object.isRequired,
    href: PropTypes.string.isRequired,
    navigate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    showHeader: PropTypes.bool,
};
