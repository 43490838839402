import { useState } from 'react';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { IconButton } from '@material-ui/core';
import { Calendario } from './Calendario';
import SelectFecha from './SelectFecha';
import sub from 'date-fns/sub';
import add from 'date-fns/add';
import { EditarVacacionesDialog } from './EditarVacaciones';
import { EditarAusenciaDialog } from './EditarAusencia';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
    (theme) => ({
        monthSelector: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 320,
            marginTop: -60,
            marginBottom: 24,
        },
    }),
    { name: 'SolicitudesCalendarioView' },
);

export default function SolicitudesCalendarioView() {
    const classes = useStyles();

    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedSolicitud, setSelectedSolicitud] = useState(null);

    return (
        <>
            <div className={classes.monthSelector}>
                <IconButton onClick={() => setCurrentMonth((date) => sub(date, { months: 1 }))}>
                    <NavigateBeforeIcon />
                </IconButton>
                <SelectFecha fecha={currentMonth} onChange={setCurrentMonth} />
                <IconButton onClick={() => setCurrentMonth((date) => add(date, { months: 1 }))}>
                    <NavigateNextIcon />
                </IconButton>
            </div>
            {selectedSolicitud && (
                <EditarVacacionesDialog
                    open={selectedSolicitud.solicitud.tipo === 'VACACIONES'}
                    onClose={() => setSelectedSolicitud(null)}
                    vacaciones={selectedSolicitud.solicitud}
                    onSave={(updatedItem) => {
                        selectedSolicitud.onSave(updatedItem);
                        setSelectedSolicitud(null);
                    }}
                />
            )}
            {selectedSolicitud && (
                <EditarAusenciaDialog
                    open={selectedSolicitud.solicitud.tipo === 'AUSENCIA'}
                    onClose={() => setSelectedSolicitud(null)}
                    ausencia={selectedSolicitud.solicitud}
                    onSave={(updatedItem) => {
                        selectedSolicitud.onSave(updatedItem);
                        setSelectedSolicitud(null);
                    }}
                />
            )}
            <Calendario
                currentMonth={currentMonth}
                onSelect={(solicitud, onSave) => {
                    setSelectedSolicitud({ solicitud, onSave });
                }}
            />
        </>
    );
}
