import withStyles from '@material-ui/core/styles/withStyles';
import Chip from '@material-ui/core/Chip';

const UrgenteChip = withStyles(
    (theme) => ({
        root: {
            color: 'white',
            backgroundColor: theme.palette.error.main,
        },
        label: {
            fontSize: theme.typography.subtitle2.fontSize,
            fontWeight: theme.typography.fontWeightMedium,
        },
        icon: {
            color: 'inherit',
            marginLeft: theme.spacing(0.5),
        },
    }),
    { name: 'UrgenteChip' },
)(Chip);

export default UrgenteChip;
