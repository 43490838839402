import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import MasterItem from '../../common/forms/MasterDetailsView/MasterItem';

export default function AprendeItem({ item, href, navigate }) {
    const location = useLocation();

    const isSelected = location.pathname === href;

    return <MasterItem title={item.label} navigate={navigate} isSelected={isSelected} />;
}

AprendeItem.propTypes = {
    href: PropTypes.any,
    item: PropTypes.any,
    navigate: PropTypes.any,
};
