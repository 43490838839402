import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { createRef } from 'react';
import Button from '../../../common/Button';
import AdjuntosList from '../../../common/fields/AdjuntosField/AdjuntosList';

const id = 'FromPcTab-1';

export default function FromPcTab({ files, setFiles }) {
    const adjuntarRef = createRef();

    const adjuntos = files.map((file, i) => ({
        id: i,
        path: file.name,
        type: file.type,
        existing: false,
        canDelete: true,
        isDocumento: false,
    }));

    return (
        <>
            <AdjuntosList
                adjuntos={adjuntos}
                style={{ marginTop: 20 }}
                onDelete={(adjunto) => {
                    files.splice(adjunto.id, 1);
                    setFiles([...files]);
                }}
            />
            <Grid item xs={12}>
                <label htmlFor={id}>
                    <input
                        ref={adjuntarRef}
                        id={id}
                        name={id}
                        style={{ display: 'none' }}
                        type='file'
                        accept='image/jpeg,image/gif,image/png,application/pdf'
                        onChange={(ev) => setFiles([...files, ...ev.target.files])}
                        multiple
                    />
                </label>

                <Button
                    color='primary'
                    startIcon={<AddIcon />}
                    size='small'
                    style={{ paddingLeft: 0 }}
                    onClick={() => adjuntarRef.current.click()}
                >
                    Seleccionar archivos
                </Button>
            </Grid>
        </>
    );
}

FromPcTab.propTypes = {
    files: PropTypes.array,
    setFiles: PropTypes.func,
};
