import { useCallback, useEffect, useMemo, useState } from 'react';
import { fichajesProvider } from '../../api';
import { useFetchData } from '../../hooks/useFetchData';
import { formatDate, formatDateTime, formatTime } from '../../utils';
import { DateColumnFilter, dateFilterFn } from '../common/list/DateColumnFilter';
import ListView from '../common/list/ListView';
import FiltersOperario from '../control_jornada/FiltersOperario';
import { defaultPrefs, STORAGE_KEY } from './RevisionFichajes';

export default function FichajeHistoricoList() {
    const [selectedDate, setSelectedDate] = useState(() => {
        const d = new Date();
        d.setHours(0, 0, 0, 0);
        return d;
    });
    const [operario, setOperario] = useState(null);
    const fetchDataFn = useCallback(
        () => fichajesProvider.getRevisionOperarioHistorico(operario.id, selectedDate),
        [operario, selectedDate],
    );
    const { data, fetchData } = useFetchData(fetchDataFn);

    useEffect(() => {
        if (!selectedDate) return;
        if (!operario) return;

        fetchData();
    }, [selectedDate, operario, fetchData]);

    function loadPrefs() {
        const prefs = JSON.parse(localStorage.getItem(STORAGE_KEY)) || defaultPrefs;
        setOperario(prefs.operario);
        if (prefs.selectedDate) setSelectedDate(new Date(prefs.selectedDate));
    }

    function updatePrefs() {
        localStorage.setItem(
            STORAGE_KEY,
            JSON.stringify({
                operario,
                selectedDate,
            }),
        );
    }
    useEffect(() => {
        loadPrefs();
    }, []);

    useEffect(() => {
        updatePrefs();
    }, [operario, selectedDate]);

    const columns = useMemo(
        () => [
            {
                Header: 'Fecha fichaje',
                accessor: 'fecha_fichaje',
                Cell: ({ value }) => formatDate(value),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Fecha modificacion',
                accessor: 'fecha_creacion',
                Cell: ({ value }) => formatDateTime(value),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Administrativo',
                accessor: 'usuario',
            },
            {
                Header: 'Motivo de administración',
                accessor: 'motivo',
            },
            {
                Header: 'Contenido anterior',
                id: 'contenido',
                accessor: (row) => {
                    const contenidoRows = row.contenido.marcajes.map(
                        (marcaje) =>
                            `De ${formatTime(marcaje.hora_entrada)} a ${
                                marcaje.hora_salida ? formatTime(marcaje.hora_salida) : '--:--'
                            }`,
                    );

                    let result = contenidoRows.join('. ');

                    if (row.contenido.marcaje_pausa) {
                        const marcajePausa = row.contenido.marcaje_pausa;
                        result += `. Descanso de ${formatTime(marcajePausa.hora_entrada)} a ${
                            marcajePausa.hora_salida ? formatTime(marcajePausa.hora_salida) : '--:--'
                        }`;
                    }

                    return result;
                },
            },
            {
                Header: 'Solicitud revisión',
                id: 'solicitud_revision',
                accessor: (row) =>
                    row.contenido.solicitud_revision
                        ? `[${formatDateTime(row.contenido.solicitud_revision.timestamp)}] ${
                              row.contenido.solicitud_revision.motivo
                          }`
                        : 'Sin solicitud',
            },
        ],
        [],
    );

    const options = useMemo(() => ({}), []);

    return (
        <>
            <FiltersOperario
                onChangeOperario={(operario) => setOperario(operario ?? null)}
                defaultOperarioId={operario?.id ?? null}
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                includeAdministrativos
            />
            <ListView
                tableTitle='Historico de modificaciones de fichajes'
                columns={columns}
                data={data}
                options={options}
            />
        </>
    );
}
