import withStyles from '@material-ui/core/styles/withStyles';
import MuiToggleButton from '@material-ui/lab/ToggleButton';
import MuiToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import PropTypes from 'prop-types';
import React from 'react';

const ToggleButtonGroup = withStyles(
    (theme) => ({
        grouped: {},
    }),
    { name: 'ToggleButtonGroup' },
)(MuiToggleButtonGroup);

const ToggleButton = withStyles(
    (theme) => ({
        root: {
            textTransform: 'capitalize',
            padding: theme.spacing(0.75, 2),
            height: 32,
            '&.Mui-selected': {
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.hover,
            },
        },
    }),
    { name: 'ToggleButton' },
)(MuiToggleButton);

export default function ViewToggler({ views, onViewChange, initialView }) {
    const [view, setView] = React.useState(initialView);

    const handleAlignment = (event, newView) => {
        if (newView === null) return;
        setView(newView);
        onViewChange(newView);
    };

    return (
        <ToggleButtonGroup value={view} exclusive onChange={handleAlignment} aria-label='view'>
            {views.map((view) => (
                <ToggleButton key={view.key} value={view.key} aria-label={view.label}>
                    {view.label}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
}

ViewToggler.propTypes = {
    views: PropTypes.array.isRequired,
    onViewChange: PropTypes.func.isRequired,
    initialView: PropTypes.string.isRequired,
};
