import { Route, Switch } from 'react-router-dom';
import EditCompanyForm from './EditCompanyForm';
import CreateCompanyForm from './CreateCompanyForm';
import CompaniesList from './CompaniesList';

export default function CompaniesModule() {
    const onError = (err) => console.error(err);

    return (
        <>
            <Switch>
                <Route
                    path='/companies/create'
                    render={({ match }) => <CreateCompanyForm basePath='/companies' match={match} />}
                />
                <Route
                    path='/companies/:id'
                    render={({ match }) => <EditCompanyForm basePath='/companies' match={match} onError={onError} />}
                />
                <Route path='/companies'>
                    <CompaniesList onError={onError} />
                </Route>
            </Switch>
        </>
    );
}
