import MenuItem from '@material-ui/core/MenuItem';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { tareasProvider } from '../../../api';
import MasterItemOptions from '../../common/forms/MasterDetailsView/MasterItemOptions';
import DelayedDownloadComponent from '../AlbaranList/DelayedDownloadComponent';
import GenerarPdfDialog from './GenerarPdfDialog';

export default function AlbaranListOptions({ tareaId }) {
    const snackbar = useSnackbar();

    return (
        <MasterItemOptions>
            {({ closeMenu }) => [
                <GenerarPdfDialog
                    key={2}
                    button={<MenuItem onClick={closeMenu}>Descargar todo en PDF</MenuItem>}
                    tareaId={tareaId}
                />,
                <DelayedDownloadComponent
                    key={1}
                    Component={MenuItem}
                    onClick={() => tareasProvider.getAll(`${tareaId}/albaranes/exportar-async`)}
                    closeMenu={() => {}}
                    altDownloadAction={() => {
                        tareasProvider
                            .fetchAttachment(`${tareaId}/albaranes/exportar`, { method: 'get' })
                            .catch((err) => {
                                console.error(err);
                                snackbar.showMessage(err.body.message);
                            });
                    }}
                >
                    Descargar todo en Excel
                </DelayedDownloadComponent>,
            ]}
        </MasterItemOptions>
    );
}

AlbaranListOptions.propTypes = {
    tareaId: PropTypes.string,
};
