import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useField } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';

// export const PreferenciasTelematelSchema = Yup.object().shape({
//     gastos: Yup.object().shape({
//         tipo_linea: Yup.string().required('Requerido'),
//     }),
//     tipo_linea_operarios: Yup.string().required('Requerido'),
// });

function EditPreferenciasDelsol({ classes }) {
    const [{ value }, ,] = useField('preferencias_delsol');

    if (value === null) return null;

    return (
        <>
            <Paper elevation={0}>
                <Typography className={classes.title}>Configuración de acceso a DELSOL</Typography>
                <Grid container spacing={4} className={classes.content}>
                    <Grid item xs={4}>
                        <TextField name='preferencias_delsol.codigo_cliente' label='Código de cliente' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField name='preferencias_delsol.base_datos' label='Base de datos' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField name='preferencias_delsol.password' label='Contraseña' type='password' fullWidth />
                    </Grid>
                </Grid>
            </Paper>
            <Paper elevation={0}>
                <Typography className={classes.title}>Configuración de albaranes</Typography>
                <Grid container spacing={4} className={classes.content}>
                    <Grid item xs={3}>
                        <TextField name='preferencias_delsol.serie_albaranes' label='Serie albaranes' fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField name='preferencias_delsol.porcentaje_iva_1' label='% IVA 1' fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField name='preferencias_delsol.porcentaje_iva_2' label='% IVA 2' fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField name='preferencias_delsol.porcentaje_iva_3' label='% IVA 3' fullWidth />
                    </Grid>
                </Grid>
            </Paper>
            <Paper elevation={0}>
                <Typography className={classes.title}>Artículos especiales</Typography>
                <Grid container spacing={4} className={classes.content}>
                    <Grid item xs={3}>
                        <TextField name='preferencias_delsol.articulo_mano_obra' label='Ref. mano de obra' fullWidth />
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            name='preferencias_delsol.descripcion_mano_obra'
                            label='Descripción mano de obra'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            name='preferencias_delsol.articulo_material_sin_referencia'
                            label='Material sin referencia'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={9}></Grid>
                    <Grid item xs={3}>
                        <TextField name='preferencias_delsol.articulo_dietas' label='Ref. dietas' fullWidth />
                    </Grid>
                    <Grid item xs={9}>
                        <TextField name='preferencias_delsol.descripcion_dietas' label='Descripción dietas' fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            name='preferencias_delsol.articulo_desplazamiento'
                            label='Ref. desplazamiento'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            name='preferencias_delsol.descripcion_desplazamiento'
                            label='Descripción desplazamiento'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField name='preferencias_delsol.articulo_parking' label='Ref. parking' fullWidth />
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            name='preferencias_delsol.descripcion_parking'
                            label='Descripción parking'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default EditPreferenciasDelsol;

EditPreferenciasDelsol.propTypes = {
    classes: PropTypes.any,
};
