import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import DialogForm from '../../../common/forms/DialogForm';
import { preparacionesMaterialProvider } from '../../../../api';
import ProveedorField from '../../../common/fields/ProveedorField';
import { KeyboardDatePicker } from '../../../common/fields/KeyboardDatePicker';
import { withButtonOpener } from '../../../../hooks/withButtonOpener';

const AlbaranCompraSchema = Yup.object().shape({
    referencia: Yup.string().required('Requerido'),
    proveedor: Yup.mixed().required('Selecciona un proveedor'),
    fecha_solicitud: Yup.date().typeError('Fecha inválida').required('Requerido'),
    fecha_llegada_prevista: Yup.date().typeError('Fecha inválida').nullable(),
});

function ToAlbaranCompraDialog({ preparacionMaterialId, selectedLineaIds, onSave, ...props }) {
    return (
        <DialogForm
            title='Crear albarán de compra'
            FormikProps={{
                initialValues: {
                    referencia: '',
                    proveedor: null,
                    fecha_solicitud: new Date(),
                    fecha_llegada_prevista: null,
                    notas: '',
                },
                validationSchema: AlbaranCompraSchema,
                onSubmit: ({ proveedor, ...values }, { setSubmitting, setFieldError }) => {
                    preparacionesMaterialProvider
                        .toAlbaranCompra(preparacionMaterialId, selectedLineaIds, {
                            ...values,
                            proveedor_id: proveedor.id,
                        })
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ touched, errors }) => (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField name='referencia' label='Referencia' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <ProveedorField
                            error={touched.proveedor && !!errors.proveedor}
                            helperText={touched.proveedor && errors.proveedor}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDatePicker
                            name='fecha_solicitud'
                            label='Fecha de solicitud'
                            format={'dd/MM/yyyy'}
                            fullWidth
                            autoOk
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDatePicker
                            name='fecha_llegada_prevista'
                            label='Fecha prevista de llegada'
                            format={'dd/MM/yyyy'}
                            fullWidth
                            autoOk
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name='notas'
                            label='Notas'
                            fullWidth
                            multiline
                            minRows={6}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(ToAlbaranCompraDialog);

ToAlbaranCompraDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    preparacionMaterialId: PropTypes.any,
    selectedLineaIds: PropTypes.any,
};
