import Tooltip from '@material-ui/core/Tooltip';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DescriptionIcon from '@material-ui/icons/Description';
import LockIcon from '@material-ui/icons/Lock';
import WarningIcon from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { formatDate, formatDateTime, formatFullDate, formatTiempo } from '../../../utils';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import ItemLabel from '../../common/forms/MasterDetailsView/ItemLabel';
import MasterItem from '../../common/forms/MasterDetailsView/MasterItem';
import ParteTrabajoItemOptions from './ParteTrabajoItemOptions';

const FacturadoIcon = DescriptionIcon;
const AlbaranIcon = DescriptionIcon;

export default function ParteTrabajoItem({ item, href, navigate, onDelete, showHeader = false, titleFn = null }) {
    const location = useLocation();
    const { fetchData } = useEditFormData();

    const isSelected = location.pathname === href;

    let subtitle = `Mano de obra: ${formatTiempo(item.tiempo)}`;

    if (item.marcajes.length > 0) {
        const marcajesAbiertos = item.marcajes.some((m) => m.hora_entrada !== null && m.hora_salida === null);

        subtitle = (
            <>
                {subtitle} (duración real: {formatTiempo(item.tiempo_marcajes)}
                {marcajesAbiertos ? (
                    <Tooltip arrow title='Hay marcajes abiertos en este parte de trabajo'>
                        <WarningIcon style={{ fontSize: 16, marginLeft: 4 }} />
                    </Tooltip>
                ) : null}
                )
            </>
        );
    }

    const itemTitle = titleFn ? titleFn(item) : item.operario ? item.operario : 'Imputación de material';

    return (
        <MasterItem
            icon={DescriptionIcon}
            title={itemTitle}
            subtitle={item.operario ? subtitle : null}
            navigate={navigate}
            header={showHeader && formatFullDate(item.fecha)}
            lastUpdate={item.fecha_actualizacion}
            lastUpdateLocation={item.last_updated_location}
            isSelected={isSelected}
            labels={
                <>
                    {item.operario && <ItemLabel icon={<AccessTimeIcon />} label={formatTiempo(item.tiempo)} />}
                    {item.facturada && (
                        <ItemLabel
                            icon={<FacturadoIcon />}
                            label='Facturado'
                            tooltip={`Facturado el ${formatDate(item.fecha_facturacion)}`}
                        />
                    )}
                    {item.revisada && (
                        <ItemLabel
                            icon={<LockIcon />}
                            label='Revisado'
                            tooltip={`Revisado el ${formatDateTime(item.fecha_revision)}`}
                        />
                    )}
                    {item.albaran_id && <ItemLabel icon={<AlbaranIcon />} label={`Albaran ${item.numero_albaran}`} />}
                </>
            }
            actions={<ParteTrabajoItemOptions item={item} onUpdate={fetchData} onDelete={onDelete} />}
        />
    );
}

ParteTrabajoItem.propTypes = {
    item: PropTypes.object.isRequired,
    href: PropTypes.string.isRequired,
    navigate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    showHeader: PropTypes.bool,
    titleFn: PropTypes.string,
};
