import { useDroppable } from '@dnd-kit/core';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { EVENTO_DRAGGABLE_TYPE } from '../Evento';
import MoverServicioItem from './MoverServicioItem';

export const MOVER_SERVICIOS_HEIGHT = 68;

const useStyles = makeStyles(
    (theme) => ({
        root: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
        },
        drawer: {
            flexShrink: 0,
        },
        drawerPaper: {
            height: 0,
            filter: 'drop-shadow(0px 4px 10px #c4c4c4)',
            borderTop: 'none',
            display: 'flex',
            flexDirection: 'row-reverse',
            flexWrap: 'wrap',
            gap: `${theme.spacing(1)}px`,
            zIndex: 1,
            '& .MuiTableCell-root.MuiTableCell-sizeSmall:first-of-type': {
                width: 0,
                padding: theme.spacing(0, 0, 0, 1),
            },
            // transition: theme.transitions.create('height', {
            //     easing: theme.transitions.easing.sharp,
            //     duration: theme.transitions.duration.leavingScreen,
            // }),
        },
        drawerOpen: {
            padding: theme.spacing(2),
            height: MOVER_SERVICIOS_HEIGHT,
            // transition: theme.transitions.create('height', {
            //     easing: theme.transitions.easing.easeOut,
            //     duration: theme.transitions.duration.enteringScreen,
            // }),
        },
        placeholder: {
            border: `2px dashed ${theme.palette.neutral.borderPressedColor}`,
            borderRadius: 4,
            height: 40,
            width: 240,
            padding: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&.isOver': {
                border: `2px solid ${theme.palette.neutral.borderPressedColor}`,
                color: 'white',
                backgroundColor: theme.palette.neutral.borderPressedColor,
            },
        },
    }),
    { name: 'MoverServiciosDrawer' },
);

export const MOVER_DROPPABLE_TYPE = 'MOVER';
export const MOVER_A_OTRA_SEMANA = 'mover-a-otra-semana';

export default function MoverServiciosDrawer({ open, eventos }) {
    const classes = useStyles();
    const { setNodeRef, isOver, active } = useDroppable({
        id: MOVER_A_OTRA_SEMANA,
        data: {
            type: MOVER_DROPPABLE_TYPE,
        },
    });

    const isOverValid =
        isOver &&
        active.data.current.type === EVENTO_DRAGGABLE_TYPE &&
        active.data.current.entity.originalUniqueId === undefined &&
        !eventos.some((ev) => ev.originalUniqueId === active.data.current.entity.uniqueId);

    return (
        <Paper className={clsx(classes.root, classes.drawerPaper, open ? classes.drawerOpen : null)} square>
            <div ref={setNodeRef} className={clsx(classes.placeholder, isOverValid ? 'isOver' : null)}>
                <Typography variant='h3' component='p'>
                    Mover a otra semana
                </Typography>
            </div>
            {eventos.map((evento, i) => (
                <MoverServicioItem key={i} event={evento} />
            ))}
        </Paper>
    );
}

MoverServiciosDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    eventos: PropTypes.array.isRequired,
};
