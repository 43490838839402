import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React from 'react';
import AddCapituloDialog from './AddCapituloDialog';
import AddDescomposicionDialog from './AddDescomposicionDialog';
import AddPartidaDialog from './AddPartidaDialog';

export function AddLineaButton({ presupuestoId, lineaId, tipoLinea, onSave }) {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

    const openMenu = (event) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
    };

    const closeMenu = (event) => {
        event && event.stopPropagation();
        setMenuAnchorEl(null);
    };

    if (tipoLinea === 'MANO_OBRA' || tipoLinea === 'MATERIAL') {
        return null;
    }
    return (
        <>
            <IconButton onClick={openMenu}>
                <AddIcon />
            </IconButton>
            <Menu id='simple-menu' anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={closeMenu}>
                {tipoLinea === 'CAPITULO' && (
                    <AddCapituloDialog
                        presupuestoId={presupuestoId}
                        parentId={lineaId}
                        button={<MenuItem onClick={closeMenu}>Añadir subcapítulo</MenuItem>}
                        onSave={onSave}
                    />
                )}
                {tipoLinea === 'CAPITULO' && (
                    <AddPartidaDialog
                        presupuestoId={presupuestoId}
                        parentId={lineaId}
                        button={<MenuItem onClick={closeMenu}>Añadir partida</MenuItem>}
                        onSave={onSave}
                    />
                )}
                {tipoLinea === 'PARTIDA' && (
                    <AddDescomposicionDialog
                        presupuestoId={presupuestoId}
                        parentId={lineaId}
                        tipoLinea='MANO_OBRA'
                        button={<MenuItem onClick={closeMenu}>Añadir mano de obra</MenuItem>}
                        onSave={onSave}
                    />
                )}
                {tipoLinea === 'PARTIDA' && (
                    <AddDescomposicionDialog
                        presupuestoId={presupuestoId}
                        parentId={lineaId}
                        tipoLinea='MATERIAL'
                        button={<MenuItem onClick={closeMenu}>Añadir material</MenuItem>}
                        onSave={onSave}
                    />
                )}
            </Menu>
        </>
    );
}

AddLineaButton.propTypes = {
    lineaId: PropTypes.any,
    presupuestoId: PropTypes.any,
    tipoLinea: PropTypes.any,
    onSave: PropTypes.any,
};
