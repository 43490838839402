import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: 400,
            margin: '40px auto',
            gap: `${theme.spacing(4)}px`,
            padding: theme.spacing(6),
        },
    }),
    { name: 'LoadingHero' },
);

export default function LoadingHero() {
    const classes = useStyles();

    return (
        <Paper elevation={0} className={classes.root}>
            <CircularProgress size={40} />
            <Typography>Cargando datos</Typography>
        </Paper>
    );
}
