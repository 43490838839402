import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import MarkReadIcon from '@material-ui/icons/Drafts';
import ErrorIcon from '@material-ui/icons/Error';
import UnreadIcon from '@material-ui/icons/FiberManualRecord';
import ImageIcon from '@material-ui/icons/Image';
import clsx from 'clsx';
import isBefore from 'date-fns/isBefore';
import PropTypes from 'prop-types';
import { formatFullDateTime } from '../../../utils';
import { ButtonFotoSlideshow } from '../../clientes/FotoSlideshow';
import { ButtonDialog } from '../../common/dialogs/ButtonDialog';
import DeleteDialog from '../../common/dialogs/DeleteDialog';
import DialogEditablePaper from './DialogEditablePaper';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            padding: theme.spacing(1.5),
            '&:last-of-type': {
                border: 'none',
            },
        },
        titulo: {
            display: 'flex',
            alignItems: 'center',
            '& .MuiTypography-root': {
                flex: 1,
                fontWeight: theme.typography.fontWeightRegular,
                color: theme.palette.neutral.primary,
            },
            '& .MuiIconButton-root': {
                padding: theme.spacing(0.5),
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        texto: {
            whiteSpace: 'pre-wrap',
        },
        empty: {
            fontStyle: 'italic',
            color: theme.palette.neutral.grey4,
        },
        resolucion: {
            marginTop: theme.spacing(2),
        },
        unreadIcon: {
            marginBottom: -2,
            marginRight: theme.spacing(1),
            fontSize: 12,
        },
        incidenciaIcon: {
            marginBottom: -4,
            marginRight: theme.spacing(1),
            fontSize: 20,
            '&.resuelta': {
                color: theme.palette.success.main,
            },
        },
    }),
    { name: 'Observaciones' },
);

export default function Observaciones({
    observacion,
    onDelete,
    onDeleteFoto,
    onRead,
    onResolver,
    onUpdateResolucion,
    className,
}) {
    const classes = useStyles();

    const {
        fecha,
        fotos,
        operario,
        texto,
        vista,
        incidencia,
        fecha_resolucion: fechaResolucion,
        fecha_actualizacion: fechaActualizacion,
    } = observacion;

    let title = '';
    if (fecha) {
        title += `El ${formatFullDateTime(fecha)}, `;
    }
    title += `${operario} escribió:`;

    const emptyText = texto === null || texto === '';
    const resuelta = fechaResolucion !== null && !isBefore(new Date(fechaResolucion), new Date(fechaActualizacion));

    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.titulo}>
                <Typography variant='subtitle2'>{title}</Typography>
                {incidencia && !resuelta && (
                    <ButtonDialog
                        button={
                            <IconButton>
                                <DoneIcon />
                            </IconButton>
                        }
                        tooltip='Marcar como resuelta'
                        onConfirm={() => onResolver(true)}
                        dialogTitle='Resolver incidencia'
                        dialogText='¿Estás seguro que quieres marcar esta incidencia como resuelta?'
                    />
                )}

                {incidencia && resuelta && (
                    <Tooltip title='Marcar como no resuelta'>
                        <IconButton onClick={() => onResolver(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {!vista && (
                    <Tooltip title='Marcar como leída' arrow>
                        <IconButton onClick={onRead}>
                            <MarkReadIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {fotos.length > 0 && (
                    <ButtonFotoSlideshow
                        button={
                            <IconButton>
                                <ImageIcon />
                            </IconButton>
                        }
                        tooltip='Ver fotos'
                        fotos={fotos}
                        onDelete={(fotoIdx) => {
                            onDeleteFoto(fotos[fotoIdx].id);
                        }}
                    />
                )}
                <DeleteDialog
                    text='¿Estás seguro que quieres eliminar estas observaciones?'
                    onConfirm={onDelete}
                    button={
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    }
                />
            </div>
            <Typography component='div' variant='body2' className={clsx(classes.texto, { [classes.empty]: emptyText })}>
                {!vista && !incidencia && <UnreadIcon color='primary' className={classes.unreadIcon} />}
                {incidencia && !resuelta && <ErrorIcon color='error' className={classes.incidenciaIcon} />}
                {incidencia && resuelta && <CheckCircleIcon className={clsx(classes.incidenciaIcon, 'resuelta')} />}
                {texto || 'Sin texto'}
                {incidencia && (
                    <DialogEditablePaper
                        title='Notas internas sobre la incidencia'
                        value={observacion.resolucion ?? ''}
                        onSave={onUpdateResolucion}
                        placeholder='Las notas que escribas aqui solo las verá administración'
                        className={classes.resolucion}
                    />
                )}
            </Typography>
        </div>
    );
}

Observaciones.propTypes = {
    className: PropTypes.any,
    observacion: PropTypes.any,
    onDelete: PropTypes.any,
    onDeleteFoto: PropTypes.any,
    onRead: PropTypes.any,
    onResolver: PropTypes.any,
    onUpdateResolucion: PropTypes.any,
};
