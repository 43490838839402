import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { useField, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { companiesProvider } from '../../../api';
import { createTiempo } from '../../../api/tareas-functions';
import useAuthState from '../../../AuthState';
import { useFetchData } from '../../../hooks/useFetchData';
import { fromMinutosAsFecha, toMinutosAsFecha } from '../../../utils';
import Button from '../../common/Button';
import CategoriaOperarioField from '../../common/fields/CategoriaOperarioField';
import { useHasChanged } from '../../common/fields/DateRangePickerNew';
import { KeyboardTimePicker } from '../../common/fields/KeyboardDatePicker';
import TipoHoraField from '../../common/fields/TipoHoraField';
import DialogForm from '../../common/forms/DialogForm';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 650,
        },
        dialogContent: {
            padding: 0,
        },
        searchIcon: {
            fontSize: 20,
            color: theme.palette.neutral.primary,
        },
        fields: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        field: {
            flex: 1,
        },
        content: {
            padding: theme.spacing(2),
        },
        subheader: {
            padding: theme.spacing(1, 2),
            backgroundColor: theme.palette.neutral.grey3,
        },
    }),
    { name: 'ManoObraEditorContent' },
);

function ManoObraEditorContent() {
    const classes = useStyles();

    const {
        userInfo: { preferencias: { usar_precios: usarPrecios } = {} },
    } = useAuthState();

    const { values, setFieldValue } = useFormikContext();
    const [showNocturna, setShowNocturna] = useState(fromMinutosAsFecha(values.minutos_nocturno_as_fecha) > 0);

    const [, { value: categoriaOperarioValue }] = useField('categoria_operario');
    const [, { value: tipoHoraValue }] = useField('tipo_hora');
    const [, { value: tipoHoraNocturnoValue }] = useField('tipo_hora_nocturno');
    const [, , { setValue: setPrecioCosteDiurnoValue }] = useField('precio_unitario_coste_diurno');
    const [, , { setValue: setPrecioVentaDiurnoValue }] = useField('precio_unitario_diurno');
    const [, , { setValue: setPrecioCosteNocturnoValue }] = useField('precio_unitario_coste_nocturno');
    const [, , { setValue: setPrecioVentaNocturnoValue }] = useField('precio_unitario_nocturno');

    const preciosFetchDataFn = useCallback(() => companiesProvider.getOperarioPrecios(), []);
    const { data: precios, fetchData: fetchPrecios } = useFetchData(preciosFetchDataFn);

    useEffect(() => {
        fetchPrecios();
    }, [fetchPrecios]);

    const groupedPreciosByCategoriaAndTipoHora = useMemo(
        () =>
            precios?.reduce((acc, precio) => {
                if (!acc[precio.categoria_operario_id]) {
                    acc[precio.categoria_operario_id] = {};
                }
                acc[precio.categoria_operario_id][precio.tipo_hora_id] = precio;
                return acc;
            }, {}),
        [precios],
    );

    const categoriaOperarioId = categoriaOperarioValue?.id;
    const tipoHoraId = tipoHoraValue?.id;
    const tipoHoraNocturnoId = tipoHoraNocturnoValue?.id;
    const categoriaOperarioChanged = useHasChanged(categoriaOperarioId);
    const tipoHoraChanged = useHasChanged(tipoHoraId);
    const tipoHoraNocturnoChanged = useHasChanged(tipoHoraNocturnoId);
    const precioDisabled = Boolean(categoriaOperarioId && tipoHoraId);

    useEffect(() => {
        if (!categoriaOperarioChanged && !tipoHoraChanged) return;

        if (!categoriaOperarioId || !tipoHoraId) return;

        const precio = groupedPreciosByCategoriaAndTipoHora[categoriaOperarioId]?.[tipoHoraId];
        if (!precio) return;

        setPrecioCosteDiurnoValue(precio.precio_coste);
        setPrecioVentaDiurnoValue(precio.precio_venta);
    }, [
        groupedPreciosByCategoriaAndTipoHora,
        categoriaOperarioId,
        tipoHoraId,
        categoriaOperarioChanged,
        tipoHoraChanged,
    ]);

    useEffect(() => {
        if (!categoriaOperarioChanged && !tipoHoraNocturnoChanged) return;

        if (!categoriaOperarioId || !tipoHoraNocturnoId) return;

        const precio = groupedPreciosByCategoriaAndTipoHora[categoriaOperarioId]?.[tipoHoraNocturnoId];
        if (!precio) return;

        setPrecioCosteNocturnoValue(precio.precio_coste);
        setPrecioVentaNocturnoValue(precio.precio_venta);
    }, [
        groupedPreciosByCategoriaAndTipoHora,
        categoriaOperarioId,
        tipoHoraNocturnoId,
        categoriaOperarioChanged,
        tipoHoraNocturnoChanged,
    ]);

    return (
        <>
            {showNocturna && (
                <>
                    <Typography className={classes.subheader}>Mano de obra total</Typography>
                    <div className={classes.content}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <KeyboardTimePicker
                                    label='Tiempo'
                                    name='minutos_as_fecha'
                                    fullWidth
                                    onBlur={() => {
                                        console.log(
                                            values.minutos_as_fecha,
                                            values.minutos_diurno_as_fecha,
                                            values.minutos_nocturno_as_fecha,
                                        );
                                        const minutos = fromMinutosAsFecha(values.minutos_as_fecha);
                                        const minutosNocturno = fromMinutosAsFecha(values.minutos_nocturno_as_fecha);
                                        const minutosDiurno = minutos - minutosNocturno;

                                        setFieldValue('minutos_diurno_as_fecha', toMinutosAsFecha(minutosDiurno));
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <CategoriaOperarioField />
                            </Grid>
                        </Grid>
                    </div>
                    <Typography className={classes.subheader}>Mano de obra diurna</Typography>
                </>
            )}
            <div className={classes.content}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <KeyboardTimePicker
                            label='Tiempo'
                            name='minutos_diurno_as_fecha'
                            fullWidth
                            onBlur={() => {
                                if (showNocturna) {
                                    const minutos = fromMinutosAsFecha(values.minutos_as_fecha);
                                    const minutosDiurno = fromMinutosAsFecha(values.minutos_diurno_as_fecha);
                                    const minutosNocturno = minutos - minutosDiurno;

                                    if (minutosDiurno > minutos) {
                                        setFieldValue('minutos_as_fecha', toMinutosAsFecha(minutosDiurno));
                                        setFieldValue('minutos_nocturno_as_fecha', toMinutosAsFecha(0));
                                    } else {
                                        setFieldValue('minutos_nocturno_as_fecha', toMinutosAsFecha(minutosNocturno));
                                    }
                                } else {
                                    setFieldValue('minutos_as_fecha', new Date(values.minutos_diurno_as_fecha));
                                    setFieldValue('minutos_nocturno_as_fecha', toMinutosAsFecha(0));
                                }
                            }}
                        />
                    </Grid>
                    {!showNocturna && (
                        <Grid item xs={4}>
                            <CategoriaOperarioField />
                        </Grid>
                    )}
                    <Grid item xs={showNocturna ? 8 : 4}>
                        <TipoHoraField />
                    </Grid>
                    {usarPrecios && (
                        <Grid item xs={12} className={classes.fields}>
                            <TextField
                                name='precio_unitario_coste_diurno'
                                label='Precio coste'
                                type='number'
                                step='.01'
                                disabled={precioDisabled}
                                InputProps={{
                                    endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                                }}
                            />
                            <TextField
                                name='precio_unitario_diurno'
                                label='Precio venta'
                                type='number'
                                step='.01'
                                disabled={precioDisabled}
                                InputProps={{
                                    endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                                }}
                            />
                            <TextField
                                name='descuento_diurno'
                                label='Dto'
                                type='number'
                                step='.01'
                                disabled={precioDisabled}
                                InputProps={{
                                    endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </div>
            {showNocturna && (
                <>
                    <Typography className={classes.subheader}>Mano de obra nocturna</Typography>
                    <div className={classes.content}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <KeyboardTimePicker
                                    label='Tiempo'
                                    name='minutos_nocturno_as_fecha'
                                    fullWidth
                                    onBlur={() => {
                                        const minutos = fromMinutosAsFecha(values.minutos_as_fecha);
                                        const minutosNocturno = fromMinutosAsFecha(values.minutos_nocturno_as_fecha);
                                        const minutosDiurno = minutos - minutosNocturno;

                                        if (minutosNocturno > minutos) {
                                            setFieldValue('minutos_as_fecha', toMinutosAsFecha(minutosNocturno));
                                            setFieldValue('minutos_diurno_as_fecha', toMinutosAsFecha(0));
                                        } else {
                                            setFieldValue('minutos_diurno_as_fecha', toMinutosAsFecha(minutosDiurno));
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TipoHoraField name='tipo_hora_nocturno' />
                            </Grid>
                            {usarPrecios && (
                                <Grid item xs={12} className={classes.fields}>
                                    <TextField
                                        name='precio_unitario_coste_nocturno'
                                        label='Precio coste'
                                        type='number'
                                        step='.01'
                                        disabled={precioDisabled}
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                                        }}
                                    />
                                    <TextField
                                        name='precio_unitario_nocturno'
                                        label='Precio venta'
                                        type='number'
                                        step='.01'
                                        disabled={precioDisabled}
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                                        }}
                                    />
                                    <TextField
                                        name='descuento_nocturno'
                                        label='Dto'
                                        type='number'
                                        step='.01'
                                        disabled={precioDisabled}
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </>
            )}

            {!showNocturna && (
                <Button
                    color='primary'
                    startIcon={<AddIcon />}
                    size='small'
                    style={{ alignSelf: 'flex-start' }}
                    onClick={() => setShowNocturna(true)}
                >
                    Añadir mano de obra nocturna
                </Button>
            )}
        </>
    );
}

export default function ManoObraEditor({ jornada, onSave, ...props }) {
    const classes = useStyles();
    const ManoObraSchema = useMemo(
        () =>
            Yup.object().shape({
                minutos_as_fecha: Yup.date().typeError('Invalido').required('Requerido'),

                minutos_diurno_as_fecha: Yup.date().typeError('Invalido').required('Requerido'),
                precio_unitario_coste_diurno: Yup.number().required('Requerido'),
                precio_unitario_diurno: Yup.number().required('Requerido'),
                descuento_diurno: Yup.number().required('Requerido'),

                minutos_nocturno_as_fecha: Yup.date().typeError('Invalido').required('Requerido'),
                precio_unitario_coste_nocturno: Yup.number().required('Requerido'),
                precio_unitario_nocturno: Yup.number().required('Requerido'),
                descuento_nocturno: Yup.number().required('Requerido'),
            }),
        [],
    );

    const minutos = jornada?.tiempo?.horas * 60 + jornada?.tiempo?.minutos;
    const minutosNocturno = jornada?.tiempo_nocturno?.horas * 60 + jornada?.tiempo_nocturno?.minutos;
    const minutosDiurno = minutos - minutosNocturno;

    return (
        <DialogForm
            title='Editar mano de obra'
            FormikProps={{
                initialValues: {
                    categoria_operario: jornada?.categoria_operario_id ? { id: jornada.categoria_operario_id } : null,

                    minutos_as_fecha: toMinutosAsFecha(minutos),

                    // Diurno
                    minutos_diurno_as_fecha: toMinutosAsFecha(minutosDiurno),
                    precio_unitario_coste_diurno: jornada?.precio_unitario_coste_diurno ?? 0,
                    precio_unitario_diurno: jornada?.precio_unitario_diurno ?? 0,
                    descuento_diurno: jornada?.descuento_diurno ?? 0,
                    tipo_hora: jornada?.tipo_hora_id ? { id: jornada.tipo_hora_id } : null,

                    // Nocturno
                    minutos_nocturno_as_fecha: toMinutosAsFecha(minutosNocturno),
                    precio_unitario_coste_nocturno: jornada?.precio_unitario_coste_nocturno ?? 0,
                    precio_unitario_nocturno: jornada?.precio_unitario_nocturno ?? 0,
                    descuento_nocturno: jornada?.descuento_nocturno ?? 0,
                    tipo_hora_nocturno: jornada?.tipo_hora_nocturno_id ? { id: jornada.tipo_hora_nocturno_id } : null,
                },
                validationSchema: ManoObraSchema,
                onSubmit: (
                    {
                        categoria_operario: categoria,
                        tipo_hora: tipoHora,
                        tipo_hora_nocturno: tipoHoraNocturno,
                        minutos_as_fecha: minutosAsFecha,
                        minutos_nocturno_as_fecha: minutosNocturnoAsFecha,
                        minutos_diurno_as_fecha: minutosDiurnoAsFecha,
                        ...values
                    },
                    { setSubmitting, setFieldError },
                ) => {
                    const payload = {
                        ...values,
                        tiempo_diurno: createTiempo(fromMinutosAsFecha(minutosDiurnoAsFecha)),
                        tiempo_nocturno: createTiempo(fromMinutosAsFecha(minutosNocturnoAsFecha)),
                        categoria_operario_id: categoria ? categoria.id : null,
                        tipo_hora_id: tipoHora ? tipoHora.id : null,
                        tipo_hora_nocturno_id: tipoHoraNocturno ? tipoHoraNocturno.id : null,
                    };

                    onSave(payload).catch((err) => {
                        console.error(err);
                        if (err.status === 400) {
                            for (const [field, errors] of Object.entries(err.message)) {
                                setFieldError(field, errors.join('\n'));
                            }
                        }
                        setSubmitting(false);
                    });
                },
            }}
            classes={{
                content: classes.dialogContent,
            }}
            {...props}
        >
            {() => <ManoObraEditorContent open={props.open} />}
        </DialogForm>
    );
}

ManoObraEditor.propTypes = {
    jornada: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};
