import * as Yup from 'yup';
import { useSnackbar } from 'material-ui-snackbar-provider';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { companiesProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import DialogForm from '../../common/forms/DialogForm';
import PropTypes from 'prop-types';
import AuthService from '../../auth/AuthService';
import useAuthState from '../../../AuthState';
import API_ROOT from '../../../api/api-config';

const ClientesSchema = Yup.object().shape({
    name: Yup.string().required('Requerido'),
    nif: Yup.string().required('Requerido'),
});

function EditProfileDialog(props) {
    const snackbar = useSnackbar();
    const { updateLoginInfo } = useAuthState();

    const {
        // eslint-disable-next-line camelcase
        data: { name, contact_name, nif, direccion, telefono, codigo_postal, poblacion, provincia },
        fetchData,
    } = useEditFormData();

    return (
        <DialogForm
            title='Editar datos de facturación'
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    name,
                    contact_name,
                    nif,
                    direccion,
                    telefono,
                    codigo_postal,
                    poblacion,
                    provincia,
                },
                validationSchema: ClientesSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    companiesProvider
                        .save(values, 'profile')
                        .then(() => {
                            const authService = new AuthService(API_ROOT);
                            authService.updateUserInfo().then(updateLoginInfo);

                            fetchData();
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField name='name' label='Denominación social' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='contact_name' label='Nombre comercial' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='nif' label='NIF' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='telefono' label='Telefono' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='direccion' label='Direccion' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='codigo_postal' label='Codigo postal' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='poblacion' label='Poblacion' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='provincia' label='Provincia' fullWidth />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(EditProfileDialog);

EditProfileDialog.propTypes = {
    onClose: PropTypes.any,
};
