import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { clientesProvider } from '../../api';
import DeleteDialog from '../common/dialogs/DeleteDialog';
import { SwitchWithLabel } from '../common/fields/Switch';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';

export function EditProyectoMenuOptions() {
    const history = useHistory();
    const {
        data: { id, cliente_id: clienteId },
    } = useEditFormData();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const [deleteCascadeServicios, setDeleteCascadeServicios] = React.useState(false);

    const openMenu = (event) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
    };

    const closeMenu = (event) => {
        event && event.stopPropagation();
        setMenuAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={openMenu}>
                <MoreHorizIcon />
            </IconButton>
            <Menu id='simple-menu' anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={closeMenu}>
                {/* <MenuItem */}
                {/*    onClick={(ev) => { */}
                {/*        closeMenu(ev); */}
                {/*        window.open(proyectosProvider.getLibroMantenimientoPdfUrl(id)); */}
                {/*    }} */}
                {/* > */}
                {/*    Descargar libro de mantenimiento en PDF */}
                {/* </MenuItem> */}
                <DeleteDialog
                    text='Al eliminar el proyecto no podrás recuperar sus datos. ¿Deseas eliminarlo de todos modos?'
                    onConfirm={(ev) => {
                        closeMenu(ev);

                        clientesProvider
                            .deleteProyecto(clienteId, id, deleteCascadeServicios)
                            .then(() => history.push('/proyectos'));
                    }}
                    button={<MenuItem onClick={closeMenu}>Eliminar</MenuItem>}
                    confirmationText='borrar proyecto'
                >
                    <SwitchWithLabel
                        field={{
                            name: 'cascade',
                            value: deleteCascadeServicios,
                            onChange: (ev) => setDeleteCascadeServicios(ev.target.checked),
                        }}
                        label='Borrar los servicios que contiene el proyecto'
                    />
                </DeleteDialog>
            </Menu>
        </>
    );
}
