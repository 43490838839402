import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { presupuestosProvider } from '../../../api';

const Autocomplete = withStyles(
    (theme) => ({
        root: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            flex: 1,
        },
        inputRoot: {
            padding: theme.spacing(0.5, 1),
            '& $input:first-child': {
                padding: theme.spacing(0.5),
            },
        },
        input: {},
        option: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& > div.info': {
                color: theme.palette.neutral.grey4,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
            },
            '& > div.info > .MuiTypography-root': {
                flex: 1,
            },
            '& > div.info > strong': {
                fontWeight: theme.typography.fontWeightMedium,
                marginRight: theme.spacing(0.5),
            },
            '& > svg': {
                fontSize: 18,
            },
        },
    }),
    { name: 'Autocomplete' },
)(MuiAutocomplete);

export default function SelectPresupuesto({ defaultPresupuestoId, onChange }) {
    const [presupuestosOptions, setPresupuestosOptions] = useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const [value, setValue] = React.useState(null);

    useEffect(() => {
        presupuestosProvider.getAsOptions().then((presupuestos) => {
            setPresupuestosOptions(presupuestos);
        });
    }, []);

    useEffect(() => {
        if (presupuestosOptions.length === 0) return;

        if (!defaultPresupuestoId) {
            return;
        }

        setValue(presupuestosOptions.find((op) => op.id === defaultPresupuestoId) || presupuestosOptions[0]);
    }, [defaultPresupuestoId, presupuestosOptions]);

    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                onChange(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            getOptionLabel={(option) => `${option.nombre} | ${option.proyecto ?? '(sin proyecto)'} | ${option.cliente}`}
            renderOption={(option) => (
                <React.Fragment>
                    <Typography>{option.nombre}</Typography>
                    <div className='info'>
                        <Typography variant='subtitle2'>
                            <strong>Proyecto:</strong> {option.proyecto ?? <em>Sin proyecto</em>}
                        </Typography>
                        <Typography variant='subtitle2'>
                            <strong>Cliente:</strong> {option.cliente}
                        </Typography>
                    </div>
                </React.Fragment>
            )}
            getOptionSelected={(option, value) => option.id === value.id}
            id='controllable-states-demo'
            options={presupuestosOptions}
            renderInput={(params) => <TextField {...params} label='' placeholder='Selecciona un presupuesto' />}
        />
    );
}

SelectPresupuesto.propTypes = {
    defaultPresupuestoId: PropTypes.any,
    onChange: PropTypes.any,
};
