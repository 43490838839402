import OperarioInfoTooltip from '../servicios/OperarioInfoTooltip';
import PropTypes from 'prop-types';

const OperariosCell = ({ value, hideOperariosExtra }) => {
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
            {value.map((operario, i) => (
                <OperarioInfoTooltip key={i} operario={operario} hideIfNoMostrado={hideOperariosExtra} />
            ))}
        </div>
    );
};

export default OperariosCell;

OperariosCell.propTypes = {
    hideOperariosExtra: PropTypes.any,
    value: PropTypes.any,
};
