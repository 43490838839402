import DataProvider from './DataProvider';

export const empty = {
    referencia: '',
    nombre: '',
    descripcion: '',
    secciones: [],
    activo: true,
    has_partes_mantenimiento: false,
    tipos_equipo: null,
};

export const tipoSeccionLabels = {
    CHOICE: 'Elección',
    TEXT: 'Texto',
};

export const tipoSeccionOptions = Object.entries(tipoSeccionLabels).map(([value, label]) => ({ value, label }));

class CuestionariosProvider extends DataProvider {
    constructor() {
        super('cuestionarios', empty);
    }

    getOnlyActiveAsOptions = async () => this.getAll('as_options');

    addSeccion = async (cuestionarioId, values) => {
        return this.actionOnId(cuestionarioId, 'secciones', values);
    };

    updateSeccion = async (cuestionarioId, seccionId, values) => {
        return this.actionOnId(cuestionarioId, `secciones/${seccionId}`, values);
    };

    deleteSeccion = async (cuestionarioId, seccionId) => {
        return this.actionOnId(cuestionarioId, `secciones/${seccionId}`, null, { method: 'delete' });
    };

    moveSeccionUp = async (cuestionarioId, seccionId) => {
        return this.actionOnId(cuestionarioId, `secciones/${seccionId}/move-up`);
    };

    moveSeccionDown = async (cuestionarioId, seccionId) => {
        return this.actionOnId(cuestionarioId, `secciones/${seccionId}/move-down`);
    };

    updatePreguntaSeccion = async (cuestionarioId, seccionId, preguntaId, texto) => {
        let url = `secciones/${seccionId}/preguntas`;
        if (preguntaId != null) url += `/${preguntaId}`;

        return this.actionOnId(cuestionarioId, url, { texto });
    };

    deletePreguntaSeccion = async (cuestionarioId, seccionId, preguntaId) => {
        return this.actionOnId(cuestionarioId, `secciones/${seccionId}/preguntas/${preguntaId}`, null, {
            method: 'delete',
        });
    };

    movePreguntaUp = async (cuestionarioId, seccionId, preguntaId) => {
        return this.actionOnId(cuestionarioId, `secciones/${seccionId}/preguntas/${preguntaId}/move-up`);
    };

    movePreguntaDown = async (cuestionarioId, seccionId, preguntaId) => {
        return this.actionOnId(cuestionarioId, `secciones/${seccionId}/preguntas/${preguntaId}/move-down`);
    };

    duplicar = async (id) => this.actionOnId(id, 'duplicar');
}

export const dataProvider = new CuestionariosProvider();
