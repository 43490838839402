import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { FieldArray } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { uuid } from 'uuidv4';
import * as Yup from 'yup';
import { checklistsProvider } from '../../../../../api';
import { withButtonOpener } from '../../../../../hooks/withButtonOpener';
import { getItemStyle } from '../../../../calendario_planificacion/CalendarioContent';
import Button from '../../../../common/Button';
import DialogForm from '../../../../common/forms/DialogForm';

const ChecklistSchema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
});

const useStyles = makeStyles(
    (theme) => ({
        dialogPaper: {},
        itemsContainer: {
            maxHeight: 400,
            overflowY: 'scroll',
        },
        item: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'EditChecklistDialog' },
);

function EditChecklistDialog({ id, onSave, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const [checklist, setChecklist] = useState(null);

    useEffect(() => {
        if (!props.open) {
            setChecklist(null);
            return;
        }

        checklistsProvider.getById(id).then(setChecklist);
    }, [id, props.open]);

    if (!checklist) return null;

    return (
        <DialogForm
            title='Editar plantilla de lista de tareas'
            maxWidth='md'
            className={classes.dialogPaper}
            FormikProps={{
                initialValues: {
                    nombre: checklist.nombre,
                    activo: checklist.activo,
                    items: checklist.items,
                },
                enableReinitialize: true,
                validationSchema: ChecklistSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    checklistsProvider
                        .save(values, id)
                        .then((data) => {
                            onSave && onSave(data.id);
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {({ values }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField name='nombre' label='Nombre' fullWidth />
                    </Grid>
                    <FieldArray name='items'>
                        {({ remove, push, move }) => {
                            function onDragEnd(result) {
                                // dropped outside the list
                                if (!result.destination) {
                                    return;
                                }

                                move(result.source.index, result.destination.index);
                            }

                            return (
                                <>
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId='droppable'>
                                            {(provided, snapshot) => (
                                                <Grid
                                                    container
                                                    item
                                                    xs={12}
                                                    className={classes.itemsContainer}
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {values.items.map((item, index) => (
                                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                                            {(provided, snapshot) => (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    key={index}
                                                                    className={classes.item}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style,
                                                                    )}
                                                                >
                                                                    <DragIndicatorIcon className={classes.dragHandle} />
                                                                    <TextField
                                                                        name={`items.${index}.texto`}
                                                                        fullWidth
                                                                        placeholder='Nuevo item'
                                                                    />
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            remove(index);
                                                                        }}
                                                                    >
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </Grid>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    <Grid item xs={12}>
                                        <Button
                                            color='primary'
                                            startIcon={<AddIcon />}
                                            size='small'
                                            style={{ paddingLeft: 0 }}
                                            onClick={() => push({ id: uuid(), nombre: '' })}
                                        >
                                            Añadir tarea puntual
                                        </Button>
                                    </Grid>
                                </>
                            );
                        }}
                    </FieldArray>
                    <Grid item xs={12}>
                        <CheckboxWithLabel type='checkbox' name='activo' Label={{ label: 'Activo' }} />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(EditChecklistDialog);

EditChecklistDialog.propTypes = {
    id: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};
