import DataProvider from './DataProvider';

export const empty = {
    nombre: '',
    cliente: null,
    direccion: null,
    cliente_id: null,
    adjuntos: [],
    activo: true,
};

class InstalacionesProvider extends DataProvider {
    constructor() {
        super('instalaciones', empty);
    }

    getLibroMantenimientoPdfUrl = (id) => this.generateUrl(`${id}/libro-mantenimiento-pdf`);

    getTareas = async (id) => this.actionOnId(id, 'tareas', null, { method: 'get' });

    getContratosMantenimiento = async (id) => this.actionOnId(id, 'contratos-mantenimiento', null, { method: 'get' });
}

export const dataProvider = new InstalacionesProvider();
