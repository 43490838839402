import DateFnsUtils from '@date-io/date-fns';
import { Snackbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { esES } from '@material-ui/core/locale';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import esLocale from 'date-fns/locale/es';
import { SnackbarProvider } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { AuthStateProvider } from './AuthState';
import ServiceWorkerUpdateChecker from './components/common/ServiceWorkerUpdateChecker';
import MainApp from './MainApp';

export const COLORS = {
    primary: '#4C60FE',
    error: '#F24822',
    neutral: {
        primary: '#A3ACBA',
        hudBackground: '#222222',
        grey1: '#E3E6ED',
        grey2: '#EEEEEE',
        grey3: '#F3F4F6',
        grey4: '#6B6C7E',
        label: '#0000008A',
        hudBackgroundDivider: '#444444',
        hoverCell: '#F9F9F9',
        borderPressedColor: '#94A3B8',
        borderInputDisable: '#E2E8F0',
        backgroundPrimary: '#F3F5FA',
    },
};

export const BACKGROUND_COLOR = COLORS.neutral.grey3;

const theme = createTheme(
    {
        palette: {
            primary: {
                main: COLORS.primary,
                hover: '#EDEFFF',
                hoverBackground: 'rgba(76, 96, 254, 0.8)',
                backgroundTint: '#E6E9FF',
            },
            secondary: { main: '#A3ACBA' },
            error: { main: COLORS.error, backgroundTint: '#FFEBEB' },
            warning: { main: '#FDA729', backgroundTint: '#FFF5E8' },
            success: { main: '#1BC47D', backgroundTint: '#E5F8EB' },
            text: {
                primary: '#272833',
            },
            divider: '#eeeeee',
            neutral: {
                ...COLORS.neutral,
            },
        },
        typography: {
            fontFamily: 'Inter, sans-serif',
            h1: {
                fontSize: 24,
                fontWeight: 500,
            },
            h2: {
                fontSize: 20,
                fontWeight: 500,
            },
            h3: {
                fontSize: 18,
                fontWeight: 'normal',
            },
            h6: {
                fontSize: 16,
                fontWeight: 500,
            },
            body1: {
                fontSize: 16,
            },
            body2: {
                fontSize: 14,
                lineHeight: '24px',
            },
            subtitle1: {
                fontSize: 13,
                lineHeight: '24px',
            },
            subtitle2: {
                fontSize: 12,
                lineHeight: '24px',
            },
            caption: {
                fontSize: 10,
                lineHeight: '16px',
                fontWeight: 500,
            },
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    body: {
                        backgroundColor: BACKGROUND_COLOR,
                    },
                },
            },
            MuiButton: {
                contained: {
                    height: 48,
                    fontSize: 14,
                    fontWeight: 500,
                    letterSpacing: 1.25,
                },
            },
            MuiPaper: {
                rounded: {
                    borderRadius: 8,
                },
            },
            MuiTableCell: {
                head: {
                    '&.MuiTableCell-sizeSmall': {
                        fontSize: 13,
                        whiteSpace: 'nowrap',
                    },
                },
                body: {
                    '&.MuiTableCell-sizeSmall': {
                        fontSize: 12,
                    },
                },
            },
            MuiDialogTitle: {
                root: {
                    padding: 0,
                    paddingLeft: 16,
                    borderBottom: '1px solid #eee',
                    height: 50,
                    display: 'flex',
                    alignItems: 'center',
                },
            },
            MuiDialogContent: {
                root: {
                    '&+&': {
                        borderTop: '1px solid #eee',
                    },
                },
            },
            MuiDialogActions: {
                root: {
                    padding: 16,
                },
            },
            MuiTooltip: {
                tooltip: {
                    backgroundColor: '#222222',
                    fontSize: 12,
                },
                arrow: {
                    color: '#222222',
                },
            },
            MuiMenu: {
                list: {
                    padding: 0,
                },
                paper: {
                    borderRadius: 2,
                },
            },
            MuiMenuItem: {
                root: {
                    fontSize: 12,
                    '&:not(:first-of-type)': {
                        borderTop: '1px solid #eeeeee',
                    },
                },
            },
            MuiAlert: {
                root: {
                    alignItems: 'center',
                },
                message: {
                    display: 'flex',
                    alignItems: 'center',
                    padding: 0,
                    flexWrap: 'wrap',
                },
                icon: {
                    padding: 0,
                },
                filledInfo: {
                    backgroundColor: COLORS.primary,
                },
            },
            MuiOutlinedInput: {
                root: {
                    borderRadius: 8,
                    borderColor: COLORS.neutral.primary,
                },
                input: {
                    padding: '8px 16px',
                },
            },
            MuiAutocomplete: {
                tag: {
                    height: 26,
                },
            },
            MuiChip: {
                iconSmall: {
                    width: 16,
                    height: 16,
                    marginLeft: 8,
                },
            },
        },
    },
    esES,
);

function DefaultSnackbar({ message, action, ButtonProps, SnackbarProps, customParameters }) {
    const showCloseButton = customParameters?.showCloseButton ?? false;

    return (
        <Snackbar
            {...SnackbarProps}
            ClickAwayListenerProps={{
                ...SnackbarProps.ClickAwayListenerProps,
                mouseEvent: showCloseButton ? false : 'onClick',
            }}
        >
            <Alert
                elevation={6}
                variant='filled'
                severity={customParameters?.severity ?? 'info'}
                onClose={showCloseButton ? SnackbarProps.onClose : undefined}
                action={
                    action && (
                        <Button color='inherit' size='small' {...ButtonProps}>
                            {action}
                        </Button>
                    )
                }
            >
                {message || ''}
            </Alert>
        </Snackbar>
    );
}

DefaultSnackbar.propTypes = {
    ButtonProps: PropTypes.any,
    SnackbarProps: PropTypes.any,
    action: PropTypes.any,
    customParameters: PropTypes.any,
    message: PropTypes.any,
};

export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <SnackbarProvider SnackbarProps={{ autoHideDuration: null }} SnackbarComponent={DefaultSnackbar}>
                        <ServiceWorkerUpdateChecker />
                        <AuthStateProvider>
                            <MainApp />
                        </AuthStateProvider>
                    </SnackbarProvider>
                </MuiPickersUtilsProvider>
            </Router>
        </ThemeProvider>
    );
}
