import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ScheduleIcon from '@material-ui/icons/Schedule';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { getHoras } from '../../../../api/tareas-functions';
import { formatDate, formatTiempo } from '../../../../utils';
import { useEditFormData } from '../../../common/forms/EditForm/EditFormContext';
import { DateColumnFilter, dateFilterFn } from '../../../common/list/DateColumnFilter';
import ListView, { numberOperatorFn } from '../../../common/list/ListView';
import { NumberFilter } from '../../../common/list/NumberFilter';
import { multipleSelectFilterFn, SelectColumnFilter } from '../../../common/list/SelectColumnFilter';
import { JORNADAS_ROUTE } from '../../EditServicioForm';
import MarcajesDialog from '../../ParteTrabajoList/MarcajesDialog/MarcajesDialog';

export default function PartesTrabajoChecklist({ jornadas, subtareas }) {
    const { id: tareaId, fetchData } = useEditFormData();
    const history = useHistory();

    const match = useRouteMatch();
    const location = useLocation();
    const selectedDate = match.isExact ? null : location.pathname.split('/').pop();

    function routeForJornada(tareaId, jornadaId) {
        const basePath = match.url.split('/')[1];
        return `/${basePath}/${tareaId}/${JORNADAS_ROUTE}/${jornadaId}`;
    }

    const columns = useMemo(() => {
        const options = [];

        if (!selectedDate) {
            options.push({
                Header: 'Fecha',
                accessor: 'fecha',
                Cell: ({ value }) => formatDate(new Date(value)),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            });
        }

        options.push(
            {
                Header: 'Operario',
                accessor: 'operario',
            },
            {
                Header: 'Tareas',
                accessor: 'subtareas',
                Cell: ({ value }) => value.join(', '),
                Filter: SelectColumnFilter,
                filter: multipleSelectFilterFn,
                filterOptions: {
                    options: subtareas.map((s) => ({ value: s.titulo, label: s.titulo })),
                    multiple: true,
                },
            },
            {
                Header: 'Horas',
                accessor: 'tiempo_marcajes',
                // eslint-disable-next-line react/prop-types
                Cell: function ({ value, row: { original: jornada } }) {
                    return (
                        <>
                            <MarcajesDialog
                                button={
                                    <Tooltip arrow title={'Ver marcajes'}>
                                        <IconButton size='small'>
                                            <ScheduleIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                }
                                tareaId={tareaId}
                                jornada={jornada}
                                onSave={fetchData}
                                subtareas={subtareas}
                            />
                            {formatTiempo(value)}
                        </>
                    );
                },
                Filter: NumberFilter,
                filter: numberOperatorFn(getHoras),
                filterOptions: {
                    units: 'h',
                },
            },
        );
        return options;
    }, [selectedDate]);

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            hideDelete: true,
            canToggleColumns: false,
        }),
        [],
    );

    return (
        <ListView
            tableTitle='Partes de trabajo'
            data={jornadas}
            columns={columns}
            options={options}
            extraActions={(row) => (
                <IconButton onClick={() => history.push(routeForJornada(tareaId, row.id))}>
                    <VisibilityIcon />
                </IconButton>
            )}
        />
    );
}

PartesTrabajoChecklist.propTypes = {
    jornadas: PropTypes.array,
    subtareas: PropTypes.array,
};
