import { startOfDay } from 'date-fns';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { uuid } from 'uuidv4';
import * as Yup from 'yup';
import { checklistsProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import { formatDate } from '../../../utils';
import DialogForm from '../../common/forms/DialogForm';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import EditChecklistForm from '../../tareas/checklists/EditChecklistDialog/EditChecklistForm';

function EditChecklistDialog({ checklist, defaultFechaInicio = new Date(), onSave, duplicate = false, ...props }) {
    const snackbar = useSnackbar();
    const { id: tareaId } = useEditFormData();

    const plantillaId = checklist ? checklist.checklist_plantilla_id : null;
    const checklistItems = checklist ? checklist.items : [];

    const currentSubtareaIds = useMemo(
        () => [...new Set(checklistItems.map((i) => i.subtarea_id).filter(Boolean))],
        [checklistItems],
    );

    const EditChecklistSchema = useMemo(
        () =>
            Yup.object().shape({
                items: Yup.array()
                    .of(
                        Yup.object().shape({
                            texto: Yup.string().required('Requerido'),
                        }),
                    )
                    .test(
                        'solo-subtareas',
                        'Si se tiene que imputar mano de obra, solo se pueden añadir tareas predefinidas',
                        function (value) {
                            if (!this.parent.contar_tiempo) return true;

                            return value.every((item) => item.subtarea_id !== null);
                        },
                    )
                    .min(1, 'Debe añadir al menos una tarea'),
                fecha_inicio: Yup.date()
                    .required('Requerido')
                    .min(
                        startOfDay(defaultFechaInicio),
                        `La fecha de inicio no puede ser anterior al ${formatDate(defaultFechaInicio)}`,
                    ),
                contar_tiempo: Yup.boolean(),
                marcar_completado: Yup.boolean(),
            }),
        [defaultFechaInicio],
    );

    const initialValues = useMemo(
        () => ({
            fecha_inicio: checklist && !duplicate ? new Date(checklist.fecha_inicio) : defaultFechaInicio ?? new Date(),
            items: checklistItems.map((item) => ({
                id: duplicate ? uuid() : item.id,
                texto: item.texto,
                subtarea_id: item.subtarea_id,
                orden: item.orden,
                subtarea: item.subtarea,
            })),
            newPlantillaName: '',
            plantillaChanged: false,
            checklist_plantilla_id: plantillaId,
            plantilla: plantillaId ? { id: plantillaId } : null,
            contar_tiempo: checklist ? checklist.contar_tiempo : false,
            marcar_completado: checklist ? checklist.marcar_completado : false,
        }),
        [checklist, duplicate, plantillaId],
    );

    return (
        <DialogForm
            title={checklist ? 'Editar lista de tareas' : 'Crear lista de tareas'}
            FormikProps={{
                initialValues,
                enableReinitialize: true,
                validationSchema: EditChecklistSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    const url = checklist && !duplicate ? `tareas/${checklist.id}` : 'tareas/create';

                    if (!checklist || duplicate) values.tarea_id = tareaId;

                    checklistsProvider
                        .getAll(url, {
                            method: 'post',
                            body: JSON.stringify(values),
                        })
                        .then(() => {
                            props.onClose();
                            setSubmitting(false);
                            onSave();
                        })
                        .catch((err) => {
                            console.error(err);
                            if (err.status === 400) {
                                for (const [key, value] of Object.entries(err.message)) {
                                    setFieldError(key, value[0]);
                                }
                            } else {
                                snackbar.showMessage('Ha ocurrido un error');
                            }

                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {() => <EditChecklistForm currentSubtareaIds={currentSubtareaIds} />}
        </DialogForm>
    );
}

EditChecklistDialog.propTypes = {
    checklist: PropTypes.object,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    duplicate: PropTypes.bool,
    defaultFechaInicio: PropTypes.any,
};

export default withButtonOpener(EditChecklistDialog);
