import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(0)}px ${theme.spacing(4)}px`,
            flexWrap: 'wrap',
            paddingTop: 14,
            paddingBottom: 14,
        },
    }),
    { name: 'EditFormHeaderItemList' },
);

export default function EditFormHeaderItemList({ fixedWidth = false, children }) {
    const classes = useStyles();
    const ref = React.createRef();

    useEffect(() => {
        if (!ref.current || !fixedWidth) return;

        const items = [...ref.current.children];
        const maxWidth = items.reduce((m, c) => Math.max(m, c.clientWidth), 0);
        items.forEach((item) => {
            item.style.width = `${maxWidth}px`;
        });
    }, [ref.current]);

    return (
        <div className={classes.root} ref={ref}>
            {children}
        </div>
    );
}

EditFormHeaderItemList.propTypes = {
    children: PropTypes.any.isRequired,
    fixedWidth: PropTypes.bool,
};
