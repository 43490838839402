import { useEffect, useMemo, useState } from 'react';
import { equiposProvider } from '../../api';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

const Autocomplete = withStyles(
    (theme) => ({
        root: {},
        inputRoot: {
            '& $input:first-child': {
                padding: theme.spacing(0.5),
            },
        },
        input: {},
    }),
    { name: 'SelectEquipoAutocomplete' },
)(MuiAutocomplete);

export function SelectEquipo({ defaultEquipos, onChange, direccionId, fixedEquipoIds, disabled }) {
    const [equiposOptions, setEquiposOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState([]);

    useEffect(() => {
        if (!direccionId) {
            setEquiposOptions([]);
            return;
        }

        equiposProvider.getByDireccionClienteAsOptions(direccionId).then((equipos) => {
            setEquiposOptions(equipos);
        });
    }, [direccionId]);

    const fixedOptions = useMemo(
        () => equiposOptions.filter((e) => fixedEquipoIds.includes(e.id)),
        [fixedEquipoIds, equiposOptions],
    );

    useEffect(() => {
        if (equiposOptions.length === 0) return;

        setValue(equiposOptions.filter((op) => defaultEquipos.includes(op.id)));
    }, [defaultEquipos, equiposOptions]);

    return (
        <>
            <Autocomplete
                disabled={disabled}
                multiple
                disableCloseOnSelect
                value={value}
                onChange={(event, newValue) => {
                    const value = [
                        ...fixedOptions,
                        ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
                    ];
                    setValue(value);
                    onChange(value);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                getOptionLabel={(option) => option.nombre}
                getOptionSelected={(option, value) => option.id === value.id}
                id='controllable-states-demo'
                options={equiposOptions}
                renderInput={(params) => (
                    <TextField {...params} label='Equipos a revisar' InputLabelProps={{ shrink: true }} fullWidth />
                )}
                fullWidth
            />
        </>
    );
}

SelectEquipo.propTypes = {
    defaultEquipos: PropTypes.any,
    direccionId: PropTypes.any,
    disabled: PropTypes.any,
    fixedEquipoIds: PropTypes.any,
    onChange: PropTypes.any,
};
