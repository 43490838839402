import { useMemo } from 'react';
import { useEditFormData } from '../../../common/forms/EditForm/EditFormContext';
import MasterDetailsHeader from '../../../common/forms/MasterDetailsView/MasterDetailsHeader';
import { useJornadasBetweenDates } from './ChecklistDetails';
import PartesTrabajoChecklist from './PartesTrabajoChecklist';
import TiempoTareaChecklist from './TiempoTareaChecklist';

export default function ResumenChecklists() {
    const { data: checklists } = useEditFormData('checklists_tarea');

    const jornadas = useJornadasBetweenDates();

    const { subtareas, items, contarTiempo, marcarCompletado } = useMemo(() => {
        const uniqueSubtareas = {};
        const items = [];
        let contarTiempo = false;
        let marcarCompletado = false;
        checklists.forEach((checklist) => {
            contarTiempo = contarTiempo || checklist.contar_tiempo;
            marcarCompletado = marcarCompletado || checklist.marcar_completado;
            checklist.items.forEach((item) => {
                if (item.subtarea && !uniqueSubtareas[item.subtarea.id]) {
                    uniqueSubtareas[item.subtarea.id] = item.subtarea;
                    items.push(item);
                } else if (!item.subtarea) {
                    items.push(item);
                }
            });
        });

        return { subtareas: Object.values(uniqueSubtareas), items, marcarCompletado, contarTiempo };
    }, [checklists]);

    return (
        <>
            <MasterDetailsHeader title='Resumen general' />
            <TiempoTareaChecklist
                jornadas={jornadas}
                items={items}
                contarTiempo={contarTiempo}
                marcarCompletado={marcarCompletado}
            />
            <PartesTrabajoChecklist jornadas={jornadas} subtareas={subtareas} />
        </>
    );
}
