import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { companiesProvider } from '../../api';
import Button from '../common/Button';
import InlineDialog from '../common/forms/InlineDialog';
import { SelectControl } from '../common/list/SelectControl';

export default function AddPrimerosPasos({ companyId, onSave }) {
    const [options, setOptions] = useState([]);
    const [version, setVersion] = useState(null);

    useEffect(() => {
        companiesProvider
            .getPrimerosPasosOptions()
            .then((options) => setOptions(options.map((option) => ({ value: option.id, label: option.label }))));
    }, []);

    return (
        <InlineDialog
            title='Añadir primeros pasos'
            titleActions={
                <Button
                    color='dark'
                    onClick={() => {
                        companiesProvider.createPrimerosPasos(companyId, version).then(onSave);
                    }}
                >
                    Añadir
                </Button>
            }
        >
            <SelectControl
                name='version'
                options={options}
                value={version}
                onChange={(ev) => setVersion(ev.target.value)}
            />
        </InlineDialog>
    );
}

AddPrimerosPasos.propTypes = {
    companyId: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
};
