import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { equiposProvider } from '../../api';
import { estadoLabels } from '../../api/tareas';
import { useFetchData } from '../../hooks/useFetchData';
import { formatDate } from '../../utils';
import ListView from '../common/list/ListView';
import { PARTES_MANTENIMIENTO_ROUTE } from '../servicios/EditServicioForm';
import ServicioEstadoChip from '../servicios/ServicioEstadoChip';

const useStyles = makeStyles((theme) => ({
    title: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flex: 1,
    },
    button: {
        marginLeft: theme.spacing(1),
        '& svg': {
            marginRight: theme.spacing(1),
        },
    },
    estadoLabel: {
        backgroundColor: '#ccc',
        borderRadius: 4,
        fontSize: 11,
        padding: 6,
        textTransform: 'uppercase',
        '&.pendiente': {
            color: '#F03738',
            backgroundColor: '#FDEBEB',
        },
        '&.en_progreso': {
            color: '#F3AC3D',
            backgroundColor: '#f8ead3',
        },
        '&.finalizada': {
            color: '#2CBD96',
            backgroundColor: '#CFF3E9',
        },
    },
}));

function EstadoCell({ value }) {
    const styles = useStyles();

    return <span className={clsx(styles.estadoLabel, value.toLowerCase())}>{estadoLabels[value]}</span>;
}

export default function TrabajosEquipo({ clienteId, direccionId, proyectoId }) {
    const history = useHistory();
    const { id } = useParams();

    const styles = useStyles();
    const fetchDataFn = useCallback(() => equiposProvider.getTareas(id), [id]);
    const { data, fetchData, setData } = useFetchData(fetchDataFn);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const columns = useMemo(
        () => [
            {
                Header: 'Referencia',
                accessor: 'tarea.numero',
                Cell: ({ value }) => (value ? value.toString().padStart(4, '0') : 'N/D'),
            },
            {
                Header: 'Título',
                accessor: 'tarea.descripcion',
            },
            // {
            //     Header: 'Tipo',
            //     id: 'tarea.tipo',
            //     accessor: (row) => tipoLabels[row.tarea.tipo],
            // },
            {
                Header: 'Inicio previsto',
                accessor: 'tarea.fecha',
                Cell: ({ value }) => (value ? formatDate(new Date(value)) : 'Sin fecha'),
            },
            {
                Header: 'Fin previsto',
                accessor: 'tarea.fecha_fin',
                Cell: ({ value }) => (value ? formatDate(new Date(value)) : 'Sin fecha'),
            },
            {
                Header: 'Operarios',
                id: 'operarios',
                accessor: (row) => row.tarea.operarios.map((o) => o.nombre).join(', '),
            },
            {
                Header: 'Estado',
                accessor: 'tarea.estado',
                Cell: ServicioEstadoChip,
            },
        ],
        [styles.estadoLabel],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            hideDelete: true,
            // batchComponents: (
            //     <NuevaTareaDialog
            //         clienteId={clienteId}
            //         direccionId={direccionId}
            //         equipoIds={[id]}
            //         isMantenimiento
            //         button={
            //             <Button color='transparent' aria-label='Añadir servicio' startIcon={<AddIcon />}>
            //                 Añadir servicio
            //             </Button>
            //         }
            //         onSave={({ id }) => history.push(`/servicios/${id}`)}
            //     />
            // ),
        }),
        [],
    );

    return (
        <ListView
            columns={columns}
            data={data}
            options={options}
            extraActions={(row) => (
                <IconButton
                    onClick={() => history.push(`/servicios/${row.tarea.id}/${PARTES_MANTENIMIENTO_ROUTE}/${row.id}`)}
                >
                    <VisibilityIcon />
                </IconButton>
            )}
            tableId='trabajosEquipo'
            tareas={data}
            setTareas={setData}
            basePath='/equipos'
            tableTitle='Lista de servicios de mantenimiento'
        />
    );
}

EstadoCell.propTypes = {
    value: PropTypes.any,
};

TrabajosEquipo.propTypes = {
    clienteId: PropTypes.any,
    direccionId: PropTypes.any,
    proyectoId: PropTypes.any,
};
