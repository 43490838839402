import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

export const EditFormContext = React.createContext();

export function EditFormDataProvider({ data, fetchData, children }) {
    const [localData, setLocalData] = useState(data);
    const { id } = useParams();

    useEffect(() => {
        setLocalData(data);
    }, [data]);

    return (
        <EditFormContext.Provider
            value={{
                id,
                data: localData,
                fetchData,
                updateData: setLocalData,
            }}
        >
            {children}
        </EditFormContext.Provider>
    );
}

EditFormDataProvider.propTypes = {
    data: PropTypes.object.isRequired,
    fetchData: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired,
};

export function useEditFormData(key) {
    const { id, data: allData, fetchData, updateData } = useContext(EditFormContext);

    const keyParts = useMemo(() => (key ? key.split('.') : []), [key]);

    const updater = useMemo(() => {
        const thisKeyParts = keyParts;

        function updater(key, value) {
            const keyParts = [...thisKeyParts];
            if (key) keyParts.push(...key.split('.'));

            const lastKey = keyParts.pop();
            const accessedObject = keyParts.reduce((obj, key) => obj[key], allData);

            accessedObject[lastKey] = value;

            updateData({ ...allData });
        }

        return updater;
    }, [keyParts, allData]);

    return {
        id,
        data: keyParts.reduce((obj, key) => obj[key], allData),
        fetchData,
        updateLocalData: updater,
        updateData,
    };
}
