import DeleteDialog from '../common/dialogs/DeleteDialog';
import { equiposProvider } from '../../api';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { KeyboardDatePicker } from '../common/fields/KeyboardDatePicker';
import { TextField } from 'formik-material-ui';
import { Formik, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'material-ui-snackbar-provider';

function DeleteEquipoDialogBody({ id, open }) {
    const {
        values: { usado },
        setFieldValue,
    } = useFormikContext();

    useEffect(() => {
        if (!open) return;

        equiposProvider.action(`${id}/can-delete`).then((res) => setFieldValue('usado', !res.result));
    }, [open]);

    if (!usado) return null;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Alert variant='filled' severity='warning'>
                    Este equipo está en uso. Por favor, proporciona una fecha de baja y un motivo para proceder.
                </Alert>
            </Grid>
            <Grid item xs={12}>
                <KeyboardDatePicker name='fecha_baja' label='Fecha de baja' fullWidth />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name='motivo_baja'
                    label='Motivo de la baja'
                    fullWidth
                    multiline
                    minRows={4}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
        </Grid>
    );
}

export default function DeleteEquipoDialog({ id, button }) {
    const history = useHistory();
    const snackbar = useSnackbar();

    return (
        <Formik
            initialValues={{
                usado: false,
                fecha_baja: new Date(),
                motivo_baja: '',
            }}
            onSubmit={({ usado, ...values }, { setSubmitting, setFieldValue, setFieldError }) => {
                equiposProvider
                    .delete(id, usado ? values : null)
                    .then(() => history.push('/equipos'))
                    .catch((err) => {
                        if (err.status === 400) {
                            for (const [field, errors] of Object.entries(err.message)) {
                                setFieldError(field, errors.join('\n'));
                            }
                        }
                        if (values.usado) snackbar.showMessage('No se ha podido borrar el equipo');
                        else setFieldValue('usado', true);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {({ submitForm }) => (
                <DeleteDialog
                    text='Al eliminar el equipo no podrás recuperar sus datos. ¿Deseas eliminarlo de todos modos?'
                    onConfirm={(ev) => {
                        // button.props.onClick && button.props.onClick(ev);
                        submitForm();
                    }}
                    button={button}
                    confirmationText='borrar equipo'
                >
                    {({ open }) => <DeleteEquipoDialogBody id={id} open={open} />}
                </DeleteDialog>
            )}
        </Formik>
    );
}

DeleteEquipoDialog.propTypes = {
    button: PropTypes.any,
    id: PropTypes.any,
};

DeleteEquipoDialogBody.propTypes = {
    id: PropTypes.any,
    open: PropTypes.any,
};
