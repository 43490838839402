import MuiTextField from '@material-ui/core/TextField';
import { Field, useField } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { companiesProvider } from '../../../api';

export default function CategoriaOperarioField() {
    const [categoriasOperario, setCategoriasOperario] = useState([]);
    const [{ value }, , { setValue }] = useField('categoria_operario');

    useEffect(() => {
        companiesProvider.getCategoriasOperarioAsOptions().then(setCategoriasOperario);
    }, []);

    useEffect(() => {
        if (categoriasOperario.length === 0) return;

        setValue(value ? categoriasOperario.find((d) => d.id === value.id) : null);
    }, [value, categoriasOperario]);

    return (
        <Field
            name='categoria_operario'
            fullWidth
            component={Autocomplete}
            options={categoriasOperario}
            getOptionSelected={(option, value) => option.id === value?.id}
            getOptionLabel={(option) => `[${option.codigo ? `${option.codigo}] ` : ''}${option.nombre}` ?? ''}
            renderInput={(params) => (
                <MuiTextField
                    {...params}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label='Categoría'
                    placeholder={!value ? 'No especificado' : ''}
                />
            )}
        />
    );
}

CategoriaOperarioField.propTypes = {
    disabled: PropTypes.any,
    error: PropTypes.any,
    helperText: PropTypes.any,
};
