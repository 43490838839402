import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            whiteSpace: 'nowrap',
            color: (props) => (props.color ? 'white' : theme.palette.primary.main),
            backgroundColor: (props) => (props.color ? theme.palette[props.color].main : theme.palette.neutral.grey3),
            borderRadius: 4,
            padding: theme.spacing(0.5, 1),
            '&>svg': {
                fontSize: 14,
                color: 'inherit',
                marginRight: theme.spacing(0.5),
            },
            '&.uppercase': {
                textTransform: 'uppercase',
            },
            '&.disabled': {
                opacity: 0.5,
            },
        },
    }),
    { name: 'ItemLabel' },
);

export default function ItemLabel({
    icon,
    label,
    tooltip = '',
    color,
    uppercase = false,
    disabled = false,
    width = 'fit-content',
}) {
    const classes = useStyles({ color });

    return (
        <Tooltip title={tooltip} arrow>
            <div className={clsx(classes.root, { uppercase, disabled })} style={{ width }}>
                {icon}
                <Typography variant='caption'>{label}</Typography>
            </div>
        </Tooltip>
    );
}

ItemLabel.propTypes = {
    color: PropTypes.any,
    disabled: PropTypes.bool,
    icon: PropTypes.object,
    label: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    uppercase: PropTypes.bool,
    width: PropTypes.any,
};
