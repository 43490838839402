import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { rendimientosProvider } from '../../../api';
import useAuthState from '../../../AuthState';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterDetailsHeader from '../../common/forms/MasterDetailsView/MasterDetailsHeader';
import MasterItemOptions from '../../common/forms/MasterDetailsView/MasterItemOptions';
import BaseRendimiento from './BaseRendimiento';
import EditRendimientoDialog from './EditRendimientoDialog';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
            overflowY: 'auto',
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: theme.spacing(4),
            gap: `${theme.spacing(1)}px`,
            minHeight: 32,
        },
        editButton: {
            minWidth: 0,
            maxWidth: 32,
        },
    }),
    { name: 'Rendimiento' },
);

export default function Rendimiento({ title }) {
    const classes = useStyles();
    const { id } = useParams();
    const { data: rendimiento, updateLocalData } = useEditFormData('rendimiento');
    const {
        userInfo: {
            preferencias: { usar_precios: usarPrecios },
        },
    } = useAuthState();

    function refreshRendimiento() {
        rendimientosProvider.getByTareaId(id).then(({ operarios, materiales, gastos, ...rendimiento }) => {
            updateLocalData(null, rendimiento);
        });
    }

    useEffect(() => {
        if (!rendimiento) refreshRendimiento();
    }, [rendimiento]);

    return (
        <div className={classes.root}>
            <MasterDetailsHeader
                title={title}
                actions={
                    usarPrecios && (
                        <MasterItemOptions>
                            {({ closeMenu }) => (
                                <EditRendimientoDialog
                                    button={<MenuItem onClick={closeMenu}>Configurar parámetros</MenuItem>}
                                    onSave={() => refreshRendimiento()}
                                />
                            )}
                        </MasterItemOptions>
                    )
                }
            />
            <BaseRendimiento rendimiento={rendimiento} />
        </div>
    );
}
Rendimiento.propTypes = {
    title: PropTypes.string,
};
