import { Route, Switch } from 'react-router-dom';
import VehiculosList from './VehiculosList';
import EditVehiculoForm from './EditVehiculoForm';

export default function VehiculosModule() {
    return (
        <>
            <Switch>
                <Route
                    path='/vehiculos/:id'
                    render={({ match }) => <EditVehiculoForm basePath='/vehiculos' match={match} />}
                />
                <Route path='/vehiculos'>
                    <VehiculosList />
                </Route>
            </Switch>
        </>
    );
}
