import { Form, Formik } from 'formik';
import { companiesProvider } from '../../api';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '../common/Button';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 600,
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
    }),
    { name: 'DatosAccesoDialog' },
);
const ChangePasswordSchema = Yup.object().shape({
    username: Yup.string().required('Requerido'),
    email: Yup.string().email(),
    new_password: Yup.string(),
    confirm_password: Yup.string().oneOf([Yup.ref('new_password'), null], 'Las contraseñas tienen que ser iguales'),
});

export const FormDatosAccesoDialog = withButtonOpener(function FormDatosAccesoDialog(props) {
    const { data: user, fetchData } = useEditFormData('user');

    return <DatosAccesoDialog user={user} onSave={fetchData} {...props} />;
});

function DatosAccesoDialog({ open, onClose, user, onSave }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby='nueva-operario-title'
            open={open}
            classes={{
                paper: classes.root,
            }}
            fullWidth={false}
            maxWidth='lg'
        >
            <Formik
                initialValues={{
                    username: user.username,
                    email: user.email,
                    new_password: '',
                    confirm_password: '',
                }}
                enableReinitialize
                validationSchema={ChangePasswordSchema}
                onSubmit={(values, { setSubmitting, setFieldError }) => {
                    companiesProvider
                        .action(`users/${user.id}`, {
                            method: 'post',
                            body: JSON.stringify(values),
                        })
                        .then((result) => {
                            onSave && onSave();
                            onClose();
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                if (err.message instanceof Object) {
                                    for (const [field, errors] of Object.entries(err.message)) {
                                        setFieldError(field, errors.join('\n'));
                                    }
                                } else {
                                    snackbar.showMessage(err.message);
                                }
                            }
                        })
                        .finally(() => setSubmitting(false));
                }}
            >
                {({ isSubmitting }) => (
                    <Form className={classes.form}>
                        <DialogTitle id='nueva-operario-title'>Datos de acceso</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField name='username' label='Nombre de usuario' fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name='email'
                                        label='Correo electrónico'
                                        helperText='Se utiliza para recuperar la contraseña'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name='new_password'
                                        label='Contraseña'
                                        type='password'
                                        fullWidth
                                        helperText='Déjalo en blanco si no quieres cambiarla'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name='confirm_password'
                                        label='Confirmar contraseña'
                                        type='password'
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button color='outlined' disabled={isSubmitting} onClick={onClose}>
                                Cancelar
                            </Button>
                            <Button type='submit' color='info' disabled={isSubmitting}>
                                Guardar
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}

export default withButtonOpener(DatosAccesoDialog);

DatosAccesoDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
    user: PropTypes.any,
};
