import { FormControl } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { format } from '../../utils';

const Select = withStyles((theme) => ({
    root: {
        paddingTop: 13,
        paddingBottom: 13,
    },
}))(MuiSelect);

const useTextFieldStyles = makeStyles(
    (theme) => ({
        input: {
            height: 42,
            maxWidth: 80,
            margin: '0 8px',
        },
    }),
    { name: 'TextField' },
);

const SelectControl = ({ options, variant = 'outlined', ...props }) => (
    <FormControl variant={variant} style={{ margin: '0 8px' }}>
        <Select {...props}>
            {options.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                    {label}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

const monthOptions = new Array(12)
    .fill(null)
    .map((x, i) => ({ label: format(new Date(2021, i, 1), 'MMMM'), value: i + 1 }));
const yearOptions = new Array(5).fill(null).map((x, i) => ({ label: 2021 + i, value: 2021 + i }));

export default function SelectFecha({ fecha, onChange, canChangeDay = false }) {
    const classes = useTextFieldStyles();
    const handleChange = (event) => {
        event.persist();

        const newDate = new Date(fecha);
        if (event.target.name === 'month') newDate.setMonth(event.target.value - 1);
        else if (event.target.name === 'year') newDate.setFullYear(event.target.value);
        else if (event.target.name === 'day') newDate.setDate(event.target.value);

        onChange(newDate);
    };

    return (
        <>
            {canChangeDay && (
                <>
                    <TextField
                        variant='outlined'
                        name='day'
                        value={fecha.getDate()}
                        onChange={handleChange}
                        InputProps={{
                            classes: {
                                root: classes.input,
                            },
                        }}
                    />
                    de
                </>
            )}
            <SelectControl name='month' options={monthOptions} value={fecha.getMonth() + 1} onChange={handleChange} />
            de
            <SelectControl name='year' options={yearOptions} value={fecha.getFullYear()} onChange={handleChange} />
        </>
    );
}

SelectFecha.propTypes = {
    canChangeDay: PropTypes.bool,
    fecha: PropTypes.object,
    onChange: PropTypes.any,
};

SelectControl.propTypes = {
    options: PropTypes.array.isRequired,
    variant: PropTypes.string,
};
