import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { preparacionesMaterialProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import { KeyboardDateTimePicker } from '../../common/fields/KeyboardDatePicker';
import DialogForm from '../../common/forms/DialogForm';

const useStyles = makeStyles(
    (theme) => ({
        fields: {
            gap: `${theme.spacing(2)}px`,
        },
        field: {
            width: 100,
            '& .MuiInput-input': {
                textAlign: 'right',
            },
        },
    }),
    { name: 'TimeEditor' },
);

const PreparacionMaterialSchema = Yup.object().shape({
    fecha_limite: Yup.date().nullable().min(new Date(), 'No se puede poner una fecha pasada'),
});

function FechaEditor({ preparacionMaterial, onSave, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    return (
        <DialogForm
            title='Editar fecha límite'
            maxWidth='xs'
            FormikProps={{
                initialValues: {
                    fecha_limite: preparacionMaterial.fecha_limite ? new Date(preparacionMaterial.fecha_limite) : null,
                },
                enableReinitialize: true,
                validationSchema: PreparacionMaterialSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    preparacionesMaterialProvider
                        .save({ fecha_limite: values.fecha_limite }, preparacionMaterial.id)
                        .then(() => {
                            props.onClose();
                            setSubmitting(false);
                            onSave();
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [key, value] of Object.entries(err.message)) {
                                    setFieldError(key, value[0]);
                                }
                            } else {
                                snackbar.showMessage('Ha ocurrido un error');
                            }

                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {() => (
                <div className={classes.fields}>
                    <KeyboardDateTimePicker label='Límite de uso del material preparado' name='fecha_limite' autoOk />
                </div>
            )}
        </DialogForm>
    );
}

FechaEditor.propTypes = {
    albaran: PropTypes.object,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    preparacionMaterial: PropTypes.any,
};

export default withButtonOpener(FechaEditor);
