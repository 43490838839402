import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { cuestionariosProvider } from '../../../api';
import Button from '../../common/Button';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterDetailsView from '../../common/forms/MasterDetailsViewOld/MasterDetailsView';
import NewSeccionDialog from './NewSeccionDialog';
import SeccionDetails from './SeccionDetails';
import SeccionItem from './SeccionItem';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            marginTop: theme.spacing(4),
        },
        actions: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        empty: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            margin: theme.spacing(6, 'auto'),
            gap: `${theme.spacing(3)}px`,
            width: 430,
        },
        emptyText: {
            color: theme.palette.neutral.grey4,
        },
        button: {
            marginLeft: 'auto',
        },
    }),
    { name: 'SeccionList' },
);

export default function SeccionList() {
    const classes = useStyles();

    const { data: cuestionario, updateData } = useEditFormData();
    const secciones = cuestionario.secciones;
    const disabled = cuestionario.has_partes_mantenimiento;

    return (
        <MasterDetailsView
            masterComponent={SeccionItem}
            detailsComponent={SeccionDetails}
            items={secciones}
            masterExtraProps={(seccion) => ({
                disabled: disabled,
                onDelete: () => {
                    cuestionariosProvider.deleteSeccion(cuestionario.id, seccion.id).then(updateData);
                },
            })}
            className={classes.root}
            emptyComponent={
                <Paper elevation={0}>
                    <div className={classes.empty}>
                        <img src='/images/empty-partes-trabajo.png' />
                        <Typography variant='h1'>Secciones</Typography>
                        <Typography variant='body2' className={classes.emptyText}>
                            Aquí configurarás las secciones que tiene el cuestionario. Cada sección contiene una lista
                            de preguntas de un determinado tipo: elección o texto.
                        </Typography>
                        <NewSeccionDialog button={<Button color='info'>Añadir sección</Button>} />
                    </div>
                </Paper>
            }
            actions={
                <>
                    <NewSeccionDialog
                        button={
                            <Button color='info' disabled={disabled} className={classes.button}>
                                Añadir sección
                            </Button>
                        }
                    />
                </>
            }
        />
    );
}

SeccionList.propTypes = {};
