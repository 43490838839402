import { NavLink } from 'react-router-dom';
import DataProvider from './DataProvider';

export const empty = {
    fecha: null,
    accion: '',
    user: null,
};

const MASCULINO = 'o';
const FEMENINO = 'a';

function createPrimaryEntityCallbacks(entityType, entityName, basePath, options = {}) {
    const { nameGender = MASCULINO, addNavLink = true } = options;

    return {
        [`${entityType}.create`]: (row) => (
            <>
                {entityName} cread{nameGender}
                {addNavLink && (
                    <>
                        : <NavLink to={`/${basePath}/${row.entity_id}`}>{row.entity_string}</NavLink>
                    </>
                )}
            </>
        ),
        [`${entityType}.update`]: (row) => (
            <>
                {entityName} actualizad{nameGender}
                {addNavLink && (
                    <>
                        : <NavLink to={`/${basePath}/${row.entity_id}`}>{row.entity_string}</NavLink>
                    </>
                )}
            </>
        ),
        [`${entityType}.delete`]: (row) => (
            <>
                {entityName} eliminad{nameGender}: {row.entity_string}
            </>
        ),
    };
}

function createSecondaryEntityCallbacks(entityType, entityName, basePath, options = {}) {
    const { nameGender = MASCULINO, addNavLink = true } = options;

    const [primaryBasePath, secondaryBasePath] = basePath.split('/');
    return {
        [`${entityType}.create`]: (row) => (
            <>
                {entityName} &quot;{row.secondary_entity_string}&quot; cread{nameGender}
                {addNavLink && (
                    <>
                        :{' '}
                        <NavLink
                            to={`/${primaryBasePath}/${row.entity_id}/${secondaryBasePath}/${row.secondary_entity_id}`}
                        >
                            {row.entity_string}
                        </NavLink>
                    </>
                )}
            </>
        ),
        [`${entityType}.update`]: (row) => (
            <>
                {entityName} &quot;{row.secondary_entity_string}&quot; actualizad{nameGender}
                {addNavLink && (
                    <>
                        :{' '}
                        <NavLink
                            to={`/${primaryBasePath}/${row.entity_id}/${secondaryBasePath}/${row.secondary_entity_id}`}
                        >
                            {row.entity_string}
                        </NavLink>
                    </>
                )}
            </>
        ),
        [`${entityType}.delete`]: (row) => (
            <>
                {entityName} &quot;{row.secondary_entity_string}&quot; eliminad{nameGender}
                {addNavLink && `: ${row.entity_string}`}
            </>
        ),
    };
}

export const entityAccionLabels = {
    ...createPrimaryEntityCallbacks('cliente', 'Cliente', 'clientes', { addNavLink: false }),
    ...createPrimaryEntityCallbacks('operario', 'Empleado', 'empleados', { addNavLink: false }),
    ...createSecondaryEntityCallbacks('cliente.direccion', 'Dirección', 'clientes/direcciones', {
        nameGender: FEMENINO,
        addNavLink: false,
    }),
    ...createPrimaryEntityCallbacks('servicio', 'Servicio', 'servicios', { addNavLink: false }),
    'servicio.cerrar': (row) => <>Servicio cerrado</>,
    'servicio.abrir': (row) => <>Servicio reabierto</>,
    'servicio.parte_trabajo.revisar': (row) => <>Parte de trabajo {row.secondary_entity_string} revisado</>,
    ...createSecondaryEntityCallbacks('servicio.parte_trabajo', 'Parte de trabajo', 'servicios/partes-trabajo', {
        addNavLink: false,
    }),
    ...createSecondaryEntityCallbacks(
        'servicio.albaran_servicio',
        'Albarán de servicio',
        'servicios/albaranes-servicio',
        { addNavLink: false },
    ),
    'servicio.albaran_servicio.enviar': (row) => <>Albarán de servicio {row.secondary_entity_string} enviado</>,
    ...createSecondaryEntityCallbacks(
        'servicio.revision_mantenimiento',
        'Revisión de equipo',
        'servicios/revisiones-equipos',
        { addNavLink: false, nameGender: FEMENINO },
    ),
    ...createPrimaryEntityCallbacks('instalacion', 'Instalación', 'instalaciones', {
        nameGender: FEMENINO,
        addNavLink: false,
    }),
    ...createSecondaryEntityCallbacks(
        'instalacion.contrato_mantenimiento',
        'Contrato de mantenimiento',
        'instalaciones/contratos-mantenimiento',
        { addNavLink: false },
    ),
    ...createSecondaryEntityCallbacks('instalacion.equipo', 'Equipo', 'instalaciones/equipos', { addNavLink: false }),
    ...createPrimaryEntityCallbacks('cuestionario', 'Cuestionario', 'cuestionarios', { addNavLink: false }),
    ...createPrimaryEntityCallbacks('solicitud', 'Vacaciones/ausencia', 'solicitudes', {
        nameGender: FEMENINO,
        addNavLink: false,
    }),
    ...createPrimaryEntityCallbacks('albaran_compra', 'Albarán de compra', 'albaranes-compra', {
        addNavLink: false,
    }),
};

export const accionLabels = {
    'token.get': 'Inicio de sesión',
    ...createPrimaryEntityCallbacks('cliente', 'Cliente', 'clientes'),
    ...createPrimaryEntityCallbacks('operario', 'Empleado', 'empleados'),
    ...createSecondaryEntityCallbacks('cliente.direccion', 'Dirección', 'clientes/direcciones', {
        nameGender: FEMENINO,
    }),
    ...createPrimaryEntityCallbacks('servicio', 'Servicio', 'servicios'),
    'servicio.cerrar': (row) => (
        <>
            Servicio cerrado: <NavLink to={`/servicios/${row.entity_id}`}>{row.entity_string}</NavLink>
        </>
    ),
    'servicio.parte_trabajo.revisar': (row) => (
        <>
            Parte de trabajo {row.secondary_entity_string} revisado:{' '}
            <NavLink to={`/servicios/${row.entity_id}/partes-trabajo/${row.secondary_entity_id}`}>
                {row.entity_string}
            </NavLink>
        </>
    ),
    ...createSecondaryEntityCallbacks('servicio.parte_trabajo', 'Parte de trabajo', 'servicios/partes-trabajo'),
    ...createSecondaryEntityCallbacks(
        'servicio.albaran_servicio',
        'Albarán de servicio',
        'servicios/albaranes-servicio',
    ),
    ...createSecondaryEntityCallbacks(
        'servicio.revision_mantenimiento',
        'Revisión de equipo',
        'servicios/revisiones-equipos',
    ),
    ...createPrimaryEntityCallbacks('instalacion', 'Instalación', 'instalaciones', { nameGender: FEMENINO }),
    ...createSecondaryEntityCallbacks(
        'instalacion.contrato_mantenimiento',
        'Contrato de mantenimiento',
        'instalaciones/contratos-mantenimiento',
    ),
    ...createSecondaryEntityCallbacks('instalacion.equipo', 'Equipo', 'instalaciones/equipos'),
    ...createPrimaryEntityCallbacks('cuestionario', 'Cuestionario', 'cuestionarios'),
    ...createPrimaryEntityCallbacks('solicitud', 'Vacaciones/ausencia', 'solicitudes', { nameGender: FEMENINO }),
    ...createPrimaryEntityCallbacks('albaran_compra', 'Albarán de compra', 'albaranes-compra'),
};

class EventosProvider extends DataProvider {
    constructor() {
        super('log_eventos', empty);
    }

    getAllForEntity = async (entityId) => this.getAll(entityId);
}

export const dataProvider = new EventosProvider();
