import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'nowrap',
            gap: '8px',
            '& svg': {
                color: theme.palette.success.main,
                fontSize: 18,
            },
        },
    }),
    { name: 'DireccionValidadaChip' },
);

export default function DireccionValidadaChip({ value, row: { original: { google_place_id: placeId } = {} } = {} }) {
    const classes = useStyles();
    const isValidada = Boolean(placeId);

    return (
        <div className={classes.root}>
            {value}
            {isValidada && (
                <Tooltip arrow title='Verificada en Google Maps'>
                    <VerifiedUserIcon color='success' />
                </Tooltip>
            )}
        </div>
    );
}

DireccionValidadaChip.propTypes = {
    value: PropTypes.string.isRequired,
    row: PropTypes.any,
};
