import { useCallback, useEffect, useMemo } from 'react';
import BaseListView, { withTableState } from '../common/list/ListView';
import { dataProvider } from '../../api/cuestionarios';
import DoneIcon from '@material-ui/icons/Done';
import { useHistory } from 'react-router-dom';
import { useFetchData } from '../../hooks/useFetchData';
import { useSnackbar } from 'material-ui-snackbar-provider';
import Button from '../common/Button';
import AddIcon from '@material-ui/icons/Add';
import CopyIcon from '@material-ui/icons/FileCopy';
import NewCuestionarioDialog from './NewCuestionarioDialog';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { cuestionariosProvider } from '../../api';

const ListView = withTableState('cuestionarios', BaseListView);

export default function CuestionariosList() {
    const history = useHistory();
    const fetchDataFn = useCallback(() => dataProvider.getAll(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        dataProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Referencia',
                accessor: 'referencia',
            },
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
            {
                Header: 'Descripcion',
                accessor: 'descripcion',
            },
            {
                Header: 'Tipos de equipo compatibles',
                accessor: 'tipos_equipo',
                Cell: ({ value }) => value.map((t) => t.nombre).join(', '),
            },
            {
                Header: 'Activo',
                accessor: 'activo',
                // eslint-disable-next-line react/prop-types
                Cell: ({ value }) => (value ? <DoneIcon /> : null),
                filter: 'equals',
                disableGlobalFilter: true,
                disableFilters: true,
                disableSortBy: true,
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            deleteConfirmationText: 'borrar cuestionario',
        }),
        [],
    );

    return (
        <>
            {/* <Alert severity='info' variant='filled' style={{ marginBottom: 24 }}> */}
            {/*    Esta funcionalidad esta en fase beta. Puede ser que haya cambios importantes. ¿Dudas o sugerencias? */}
            {/*    Envianos un email a hola@labory.es */}
            {/* </Alert> */}
            <ListView
                title='Cuestionarios'
                tableTitle='Lista de cuestionarios'
                titleActions={
                    <NewCuestionarioDialog
                        button={
                            <Button color='info' aria-label='Nuevo cuestionario' startIcon={<AddIcon />}>
                                Nuevo cuestionario
                            </Button>
                        }
                        onSave={(id) => history.push(`cuestionarios/${id}`)}
                    />
                }
                basePath='/cuestionarios'
                columns={columns}
                data={data}
                options={options}
                onDelete={onDelete}
                extraActions={(row) => (
                    <Tooltip title='Duplicar cuestionario'>
                        <IconButton onClick={() => cuestionariosProvider.duplicar(row.id).then(fetchData)}>
                            <CopyIcon />
                        </IconButton>
                    </Tooltip>
                )}
            />
        </>
    );
}
