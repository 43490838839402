import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../common/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import ListView from '../common/list/ListView';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { useParams } from 'react-router-dom';
import { contratosMantenimientoProvider } from '../../api';
import { useFetchData } from '../../hooks/useFetchData';

const useStyles = makeStyles(
    (theme) => ({
        root: {},
        content: {
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            height: '100%',
        },
    }),
    { name: 'AddEquiposDialog' },
);

function AddEquiposDialog({ open, onClose, onSave }) {
    const classes = useStyles();
    const { id } = useParams();
    const fetchDataFn = useCallback(() => contratosMantenimientoProvider.getEquiposToAdd(id), []);
    const { data, fetchData } = useFetchData(fetchDataFn);
    const [selectedRowIds, setSelectedRowIds] = useState([]);

    useEffect(() => {
        if (open) fetchData();
    }, [open]);

    const columns = useMemo(
        () => [
            {
                Header: 'Referencia',
                accessor: 'referencia',
            },
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
            {
                Header: 'Marca',
                accessor: 'marca',
            },
            {
                Header: 'Modelo',
                accessor: 'modelo',
            },
            {
                Header: 'Tipo',
                accessor: 'tipo_equipo',
            },
            {
                Header: 'Numero de serie',
                accessor: 'numero_serie',
            },
            {
                Header: 'Ubicación',
                accessor: 'ubicacion',
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: false,
            tableOptions: {
                useSelect: true,
            },
        }),
        [],
    );

    return (
        <Dialog
            onClose={onClose}
            open={open}
            classes={{
                paper: classes.root,
            }}
            fullWidth={true}
            maxWidth='lg'
        >
            <DialogTitle>Añadir equipos al contrato</DialogTitle>
            <DialogContent className={classes.content}>
                <ListView
                    columns={columns}
                    data={data}
                    options={options}
                    onSelectedChange={(rowIds) => setSelectedRowIds(Object.keys(rowIds).map((x) => parseInt(x)))}
                />
            </DialogContent>
            <DialogActions>
                <Button color='outlined' onClick={onClose}>
                    Cancelar
                </Button>
                <Button
                    color='info'
                    disabled={selectedRowIds.length === 0}
                    onClick={() => {
                        contratosMantenimientoProvider
                            .addEquipos(
                                id,
                                selectedRowIds.map((idx) => data[idx].id),
                            )
                            .then(() => {
                                onClose();
                                onSave();
                            });
                    }}
                >
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

AddEquiposDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};

export default withButtonOpener(AddEquiposDialog);
