import { Field, useField } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import MuiTextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import { operariosProvider } from '../../api';

export default function OperariosResponsablesField({ error, helperText, disabled, operariosKey = 'operarios' }) {
    const [{ value }, , { setValue }] = useField(operariosKey);
    const [operariosOptions, setOperariosOptions] = useState([]);

    useEffect(() => {
        operariosProvider.getOnlyActiveAsOptions().then((options) =>
            setOperariosOptions(
                options.map((option) => ({
                    operario: {
                        id: option.id,
                        nombre: option.nombre,
                    },
                })),
            ),
        );
    }, []);

    return (
        <Field
            name={operariosKey}
            fullWidth
            multiple
            disabled={disabled}
            component={Autocomplete}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            options={operariosOptions}
            getOptionLabel={(option) => option.operario.nombre}
            getOptionSelected={(option, value) => option.operario.id === value.operario.id}
            renderInput={(params) => (
                <MuiTextField
                    {...params}
                    error={error}
                    helperText={helperText}
                    label='Operarios responsables'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={disabled}
                    placeholder={value?.length === 0 ? 'Puedes dejarlo vacío para asignarlo más tarde' : ''}
                />
            )}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip key={index} label={option.operario.nombre} {...getTagProps({ index })} />
                ))
            }
            renderOption={(option) => option.operario.nombre}
        />
    );
}

OperariosResponsablesField.propTypes = {
    disabled: PropTypes.any,
    error: PropTypes.any,
    helperText: PropTypes.any,
    operariosKey: PropTypes.string,
};
