import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { usePreferencias } from '../../../../AuthState';
import { SwitchWithLabel } from '../../../common/fields/Switch';
import SubtareasList from '../../subtareas/SubtareasList';
import AjustesChecklists from './Checklists/AjustesChecklists';

export default function AjustesServicios({ classes }) {
    const usarChecklists = usePreferencias('usar_checklists');

    return (
        <>
            <Paper elevation={0}>
                <Typography className={classes.title}>Servicios</Typography>
                <Grid container spacing={4} className={classes.content}>
                    <Grid item xs={12}>
                        <Field
                            name='copiar_tiempo_operarios'
                            component={SwitchWithLabel}
                            label='Actualizar tiempo de operarios que comparten servicio (App móvil)'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name='usar_marcajes_jornadas'
                            component={SwitchWithLabel}
                            label='Hacer que los operarios marquen hora de entrada y salida en los servicios (App móvil)'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name='usar_referencias_tareas'
                            component={SwitchWithLabel}
                            label='Usar referencias internas en los servicios'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name='usar_ajuste_planificaciones_al_cerrar'
                            component={SwitchWithLabel}
                            label='Ajustar planificación automáticamente al cerrar el servicio'
                        />
                    </Grid>
                </Grid>
            </Paper>
            {usarChecklists && (
                <>
                    <AjustesChecklists />
                    <SubtareasList open />
                </>
            )}
        </>
    );
}

AjustesServicios.propTypes = {
    classes: PropTypes.any,
};
