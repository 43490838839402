import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { companiesProvider, operariosProvider } from '../../../api';
import { horarioDbValuesToFormValues, horarioFormValuesToDbValues } from '../../../api/companies';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import { setBeginOfDay } from '../../../utils';
import { KeyboardDatePicker } from '../../common/fields/KeyboardDatePicker';
import DialogEditor from '../../common/forms/DialogEditor';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import HorariosForm from '../../companies/profile/Ajustes/Horarios/HorariosForm';
import SelectHorarioEmpresaField from './SelectHorarioEmpresaField';

const useStyles = makeStyles(
    (theme) => ({
        root: {},
        searchIcon: {
            fontSize: 20,
            color: theme.palette.neutral.primary,
        },
        fields: {
            gap: `${theme.spacing(2)}px`,
        },
        field: {
            flex: 1,
        },
    }),
    { name: 'NuevoHorarioDialog' },
);

const HorarioSchema = Yup.object().shape({
    fecha: Yup.mixed().required('Escoge una fecha'),
});

function generateEmptyHorario() {
    return new Array(7).fill(null).map(() => ({
        activo: false,
        horario: [],
        total_minutos: setBeginOfDay(new Date()),
        max_minutos: setBeginOfDay(new Date()),
    }));
}

function NuevoHorarioDialog({ onSave, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const {
        data: { id: operarioId, horarioActual },
        fetchData,
    } = useEditFormData();

    return (
        <Formik
            initialValues={{
                fecha: new Date(),
                horarioEmpresa: null,
                dias: generateEmptyHorario(),
            }}
            enableReinitialize
            validationSchema={HorarioSchema}
            onSubmit={({ fecha, dias, horarioEmpresa }, actions) => {
                operariosProvider
                    .addHorarios(operarioId, fecha, {
                        ...horarioFormValuesToDbValues(dias),
                        horario_id: horarioEmpresa !== null ? horarioEmpresa.id : null,
                    })
                    .then((result) => {
                        props.onClose();
                        fetchData();
                    })
                    .catch((err) => {
                        if (err.status === 400) {
                            if (err.message instanceof Object) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    actions.setFieldError(field, errors.join('\n'));
                                }
                            } else {
                                snackbar.showMessage(err.message);
                            }
                        }
                    })
                    .finally(() => actions.setSubmitting(false));
            }}
        >
            {({ values, submitForm, setFieldValue, dirty, resetForm }) => {
                // useEffect(() => {
                //     console.log('effect', values.horarioEmpresa);
                //     if (!values.horarioEmpresa?.id) {
                //         return;
                //     }
                //
                //     companiesProvider.getHorarioEmpresa(values.horarioEmpresa.id).then((result) => {
                //         resetForm({
                //             values: {
                //                 ...values,
                //                 dias: horarioDbValuesToFormValues(result.horarios),
                //             },
                //         });
                //     });
                // }, [values.horarioEmpresa?.id]);

                useEffect(() => {
                    if (dirty && values.horarioEmpresa !== null) setFieldValue('horarioEmpresa', null);
                }, [dirty, values.horarioEmpresa]);

                return (
                    <Form style={{ position: 'absolute' }}>
                        <DialogEditor
                            title={horarioActual ? 'Registrar un cambio de horario' : 'Configurar horario del operario'}
                            onSave={submitForm}
                            className={classes.root}
                            {...props}
                        >
                            <Grid container className={classes.root} spacing={2} direction='column'>
                                {horarioActual && (
                                    <Grid item xs={12}>
                                        <KeyboardDatePicker
                                            label='El nuevo horario entra en vigor el'
                                            name='fecha'
                                            autoOk
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <SelectHorarioEmpresaField
                                        value={values.horarioEmpresa}
                                        onChange={(value) => {
                                            if (!value) {
                                                setFieldValue('horarioEmpresa', null);
                                                return;
                                            }

                                            companiesProvider.getHorarioEmpresa(value.id).then((result) => {
                                                resetForm({
                                                    values: {
                                                        ...values,
                                                        horarioEmpresa: value,
                                                        dias: horarioDbValuesToFormValues(result.horarios),
                                                    },
                                                });
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <HorariosForm values={values} setFieldValue={setFieldValue} />
                                </Grid>
                            </Grid>
                        </DialogEditor>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default withButtonOpener(NuevoHorarioDialog);

NuevoHorarioDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
