import MuiBadge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import EditIcon from '@material-ui/icons/Edit';
import MailIcon from '@material-ui/icons/Mail';
import { useParams } from 'react-router-dom';
import { tipoLabels } from '../../api/tareas';
import { usePreferencias } from '../../AuthState';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import EditFormHeader from '../common/forms/EditForm/EditFormHeader';
import EditFormHeaderItem from '../common/forms/EditForm/EditFormHeaderItem';
import EditFormHeaderItemList from '../common/forms/EditForm/EditFormHeaderItemList';
import { FormTab, FormTabs } from '../common/forms/EditForm/FormTabs';
import ProyectoLink from '../dashboard/ProyectoLink';
import EditarTareaButtonDialog from '../tareas/EditarTareaDialog';
import AlbaranList from './AlbaranList/AlbaranList';
import ChecklistsList from './ChecklistsList/ChecklistsList';
import ClienteInfoTooltip from './ClienteInfoTooltip';
import DescargaFotosDialog from './DescargaFotosDialog';
import DireccionInfoTooltip from './DireccionInfoTooltip';
import {
    ALBARANES_ROUTE,
    CHECKLISTS_ROUTE,
    JORNADAS_ROUTE,
    PARTES_MANTENIMIENTO_ROUTE,
    PREPARACIONES_MATERIAL_ROUTE,
    RENDIMIENTO_ROUTE,
} from './EditServicioForm';
import { EditServicioMenuOptions } from './EditServicioMenuOptions';
import NotasDialog from './NotasDialog/NotasDialog';
import OperarioInfoTooltip from './OperarioInfoTooltip';
import { OperariosServicioList } from './OperariosServicioList';
import ParteMantenimientoList from './ParteMantenimientoList/ParteMantenimientoList';
import ParteTrabajoList from './ParteTrabajoList/ParteTrabajoList';
import PreparacionMaterialList from './PreparacionesMaterialList/PreparacionMaterialList';
import Rendimiento from './Rendimiento/Rendimiento';
import ServicioEstadoChip from './ServicioEstadoChip';
import ServicioFacturadoChip from './ServicioFacturadoChip';
import ServicioUrgenteChip from './ServicioUrgenteChip';

const Badge = withStyles((theme) => ({
    badge: {
        border: `2px solid ${theme.palette.background.paper}`,
        width: 10,
        height: 10,
        borderRadius: 5,
        backgroundColor: (props) => (props.color === 'default' ? '#757575' : theme.palette.error.main),
    },
}))(MuiBadge);

const useStyles = makeStyles(
    (theme) => ({
        operarios: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            '& .MuiTypography-root': {
                display: 'flex',
                alignItems: 'center',
            },
            '& svg': {
                fontSize: 20,
                marginRight: theme.spacing(0.25),
            },
        },
    }),
    { name: 'EditServicioFormContent' },
);

export function EditServicioFormContent() {
    const classes = useStyles();
    const { data, fetchData, updateData } = useEditFormData();
    const { id } = useParams();

    const [usarReferencias, usarPreparacionMateriales, usarChecklists] = usePreferencias(
        'usar_referencias_tareas',
        'usar_preparacion_materiales',
        'usar_checklists',
    );

    const hasObservacionesNoVistas =
        data && data.jornadas.some((jornada) => jornada.notas.length > 0 && !jornada.notas[0].vista);

    const numJornadasFacturadas = data.jornadas.filter((j) => j.facturada).length;
    const numJornadas = data.jornadas.length;

    let estadoFacturacion;

    if (data.facturable) {
        if (numJornadasFacturadas === 0) estadoFacturacion = 'NO_FACTURADO';
        else if (numJornadasFacturadas === numJornadas) estadoFacturacion = 'FACTURADO';
        else estadoFacturacion = 'FACTURADO_PARCIALMENTE';
    } else {
        estadoFacturacion = 'NO_FACTURABLE';
    }

    const tabs = [
        <FormTab key={JORNADAS_ROUTE} path={JORNADAS_ROUTE} label='Partes de trabajo'>
            <ParteTrabajoList />
        </FormTab>,
        <FormTab key={ALBARANES_ROUTE} path={ALBARANES_ROUTE} label='Albaranes de servicio'>
            <AlbaranList />
        </FormTab>,
    ];

    if (usarChecklists) {
        tabs.push(
            <FormTab key={CHECKLISTS_ROUTE} path={CHECKLISTS_ROUTE} label='Tareas'>
                <ChecklistsList />
            </FormTab>,
        );
    }
    if (data.tipo === 'MANTENIMIENTO')
        tabs.push(
            <FormTab key={PARTES_MANTENIMIENTO_ROUTE} path={PARTES_MANTENIMIENTO_ROUTE} label='Revisiones de equipos'>
                <ParteMantenimientoList />
            </FormTab>,
        );
    if (usarPreparacionMateriales)
        tabs.push(
            <FormTab
                key={PREPARACIONES_MATERIAL_ROUTE}
                path={PREPARACIONES_MATERIAL_ROUTE}
                label='Preparación de materiales'
            >
                <PreparacionMaterialList />
            </FormTab>,
        );

    tabs.push(
        <FormTab key={RENDIMIENTO_ROUTE} path={RENDIMIENTO_ROUTE} label='Rendimiento'>
            <Rendimiento title='Rendimiento del servicio' />
        </FormTab>,
    );

    return (
        <>
            <EditFormHeader
                label='Servicio'
                name={
                    <>
                        {data.descripcion} <ServicioEstadoChip value={data.estado} style={{ marginLeft: 8 }} />
                        <ServicioFacturadoChip value={estadoFacturacion} style={{ marginLeft: 8 }} />
                        {data.urgente && <ServicioUrgenteChip style={{ marginLeft: 8 }} />}
                    </>
                }
                actions={
                    <>
                        <DescargaFotosDialog
                            button={
                                <IconButton>
                                    <DownloadIcon />
                                </IconButton>
                            }
                            tooltip='Descargar fotos'
                            id={id}
                        />
                        <NotasDialog
                            id={data.id}
                            button={
                                <IconButton>
                                    <Badge
                                        overlap='circular'
                                        variant='dot'
                                        color={hasObservacionesNoVistas ? 'error' : 'default'}
                                    >
                                        <MailIcon />
                                    </Badge>
                                </IconButton>
                            }
                            onUpdateNotasInternas={(notasInternas) => {
                                updateData((tarea) => ({
                                    ...tarea,
                                    notas_internas: notasInternas,
                                }));
                            }}
                            onUpdateObservaciones={(updatedJornadas) => {
                                updateData((tarea) => ({
                                    ...tarea,
                                    jornadas: tarea.jornadas.map((jornada) => {
                                        const updatedJornada = updatedJornadas.find((j) => j.id === jornada.id) ?? {};

                                        return {
                                            ...jornada,
                                            ...updatedJornada,
                                        };
                                    }),
                                }));
                            }}
                        />
                        <EditarTareaButtonDialog
                            id={data.id}
                            button={
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            }
                            onSave={fetchData}
                        />
                        <EditServicioMenuOptions />
                    </>
                }
            >
                <EditFormHeaderItemList>
                    <EditFormHeaderItem label='Cliente'>
                        <ClienteInfoTooltip id={data.cliente_id} label={data.cliente} />
                    </EditFormHeaderItem>
                    <EditFormHeaderItem label='Direccion'>
                        <DireccionInfoTooltip
                            direccion={data.direccion.direccion_completa}
                            label={data.direccion.nombre}
                        />
                    </EditFormHeaderItem>
                    {data.proyecto && (
                        <EditFormHeaderItem label='Proyecto'>
                            <ProyectoLink
                                id={data.proyecto_id}
                                nombre={data.proyecto.nombre}
                                targetBlank={false}
                                variant='subtitle1'
                            />
                        </EditFormHeaderItem>
                    )}
                    <EditFormHeaderItem label='Tipo de servicio'>{tipoLabels[data.tipo]}</EditFormHeaderItem>
                    <EditFormHeaderItem label='Referencia'>
                        {data.numero ? data.numero.toString() : ''}
                    </EditFormHeaderItem>
                    {usarReferencias && (
                        <EditFormHeaderItem label='Ref. interna'>{data.referencia_interna}</EditFormHeaderItem>
                    )}
                    <EditFormHeaderItem label='Operarios'>
                        {data.operarios.length < 3 ? (
                            <div className={classes.operarios}>
                                {data.operarios.map((operario, i) => (
                                    <OperarioInfoTooltip key={i} operario={operario} />
                                ))}
                            </div>
                        ) : (
                            <OperariosServicioList operarios={data.operarios} />
                        )}
                    </EditFormHeaderItem>
                </EditFormHeaderItemList>
            </EditFormHeader>
            <FormTabs>{tabs}</FormTabs>
        </>
    );
}
