import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

const Select = withStyles((theme) => ({
    root: {
        paddingTop: 7,
        paddingBottom: 7,
        fontSize: 14,
    },
}))(MuiSelect);

export const SelectControl = ({
    label,
    options,
    includeAllOption,
    fullWidth,
    variant = 'outlined',
    classes,
    InputLabelProps,
    children,
    ...props
}) => {
    return (
        <FormControl
            fullWidth={fullWidth}
            variant={variant}
            style={{ margin: classes?.formControl ? undefined : 0 }}
            className={classes?.formControl}
        >
            {label && <InputLabel {...InputLabelProps}>{label}</InputLabel>}
            <Select {...props} className={classes?.select}>
                {includeAllOption && <MenuItem value={includeAllOption.value}>{includeAllOption.label}</MenuItem>}
                {options.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
            {children}
        </FormControl>
    );
};

SelectControl.propTypes = {
    label: PropTypes.string,
    fullWidth: PropTypes.any,
    includeAllOption: PropTypes.any,
    options: PropTypes.any,
    variant: PropTypes.string,
    classes: PropTypes.any,
    InputLabelProps: PropTypes.any,
    children: PropTypes.any,
};
