import * as Yup from 'yup';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { equiposProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import DialogForm from '../common/forms/DialogForm';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FichaTecnicaForm } from './FichaTecnicaForm';

const EquiposSchema = Yup.object().shape({
    referencia: Yup.string().required('Requerido'),
    nombre: Yup.string().required('Requerido'),
    cliente: Yup.mixed().required('Selecciona un cliente'),
    direccion: Yup.mixed().required('Selecciona una direccion'),
    instalacion: Yup.mixed().required('Selecciona una instalación'),
    tipo_equipo_id: Yup.mixed().required('Selecciona un tipo de equipo'),
});

export function NewEquipoDialogBase({ onSave, clienteId, direccionId, instalacionId, equipoId, ...props }) {
    const defaultEquipo = {
        referencia: '',
        nombre: '',
        marca: '',
        modelo: '',
        numero_serie: '',
        fecha_fin_garantia: null,
        tipo_refrigerante_carburante: '',
        potencia: 0,
        tipo_maquina: '',
        ubicacion: '',
        cliente: clienteId ? { id: clienteId } : null,
        instalacion: instalacionId ? { id: instalacionId } : null,
        direccion: direccionId ? { id: direccionId } : null,
        tipo_equipo_id: null,
    };
    const [equipo, setEquipo] = useState(defaultEquipo);
    const snackbar = useSnackbar();

    useEffect(() => {
        if (!equipoId) return;

        equiposProvider.getById(equipoId).then((equipo) => {
            const newEquipo = Object.fromEntries(Object.keys(defaultEquipo).map((key) => [key, equipo[key]]));
            newEquipo.cliente = { id: equipo.cliente_id };
            newEquipo.direccion = { id: equipo.direccion_id };
            newEquipo.instalacion = { id: equipo.instalacion_id };

            setEquipo(newEquipo);
        });
    }, [equipoId]);

    return (
        <DialogForm
            title='Añadir equipo'
            maxWidth='md'
            FormikProps={{
                initialValues: equipo,
                enableReinitialize: true,
                validationSchema: EquiposSchema,
                onSubmit: (
                    { cliente, direccion, instalacion, tipo_equipo_id: tipoEquipoId, ...values },
                    { setSubmitting, setFieldError },
                ) => {
                    equiposProvider
                        .save({
                            ...values,
                            cliente_id: cliente.id,
                            direccion_id: direccion.id,
                            instalacion_id: instalacion ? instalacion.id : null,
                            tipo_equipo_id: tipoEquipoId === '' ? null : tipoEquipoId,
                        })
                        .then((data) => {
                            onSave && onSave(data.id);
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {() => <FichaTecnicaForm />}
        </DialogForm>
    );
}

export default withButtonOpener(NewEquipoDialogBase);

NewEquipoDialogBase.propTypes = {
    clienteId: PropTypes.any,
    direccionId: PropTypes.any,
    instalacionId: PropTypes.any,
    equipoId: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
