import { Route, Switch } from 'react-router-dom';
import EquiposList from './EquiposList';
import EditEquipoForm from './EditEquipoForm';

export default function EquiposModule() {
    return (
        <>
            <Switch>
                <Route
                    path='/equipos/:id'
                    render={({ match }) => <EditEquipoForm basePath='/equipos' match={match} />}
                />
                <Route path='/equipos'>
                    <EquiposList />
                </Route>
            </Switch>
        </>
    );
}
