import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const MyListItem = ({ label, icon, classes, ...props }, ref) => {
    // const styles = useStyles();

    return (
        <MuiListItem button className={classes.root} {...props} ref={ref}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            {label && <ListItemText primary={label} />}
        </MuiListItem>
    );
};

export const ListItem = withStyles(
    (theme) => ({
        root: {
            margin: 0,
            width: 'auto',
            paddingTop: 2,
            paddingBottom: 2,
            borderRadius: 4,
            '& .MuiListItemIcon-root': {
                minWidth: 0,
            },
            '& .MuiListItemText-primary': {
                fontSize: 14,
                fontWeight: 500,
            },
            '&:hover': {
                backgroundColor: 'rgba(56, 65, 81, 0.6)',
            },
        },
    }),
    { name: 'ListItem' },
)(React.forwardRef(MyListItem));

export const NavListItem = withStyles(
    (theme) => ({
        active: {
            color: theme.palette.primary.main,
            '& .MuiListItemIcon-root': {
                color: 'inherit',
            },
        },
    }),
    { name: 'NavListItem' },
)(({ classes, ...props }) => {
    // const styles = useStyles();

    return <ListItem component={NavLink} {...props} />;
});

MyListItem.propTypes = {
    classes: PropTypes.any,
    icon: PropTypes.any,
    label: PropTypes.any,
};
