import { useHistory } from 'react-router-dom';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import DeleteDialog from '../common/dialogs/DeleteDialog';
import { vehiculosProvider } from '../../api';
import MenuItem from '@material-ui/core/MenuItem';

export function EditVehiculoMenuOptions() {
    const history = useHistory();
    const {
        data: { id },
    } = useEditFormData();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

    const openMenu = (event) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
    };

    const closeMenu = (event) => {
        event && event.stopPropagation();
        setMenuAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={openMenu}>
                <MoreHorizIcon />
            </IconButton>
            <Menu id='simple-menu' anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={closeMenu}>
                {/* <MenuItem */}
                {/*    onClick={(ev) => { */}
                {/*        closeMenu(ev); */}
                {/*        window.open(proyectosProvider.getLibroMantenimientoPdfUrl(id)); */}
                {/*    }} */}
                {/* > */}
                {/*    Descargar libro de mantenimiento en PDF */}
                {/* </MenuItem> */}
                <DeleteDialog
                    text='Al eliminar el vehículo no podrás recuperar sus datos. ¿Deseas eliminarlo de todos modos?'
                    onConfirm={(ev) => {
                        closeMenu(ev);

                        vehiculosProvider.delete(id).then(() => history.push('/vehiculos'));
                    }}
                    button={<MenuItem onClick={closeMenu}>Eliminar</MenuItem>}
                    confirmationText='borrar vehiculo'
                />
            </Menu>
        </>
    );
}
