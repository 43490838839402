import { FormControl } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { documentosProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import OperariosField from '../common/fields/OperariosField';
import DialogForm from '../common/forms/DialogForm';

const DocumentosSchema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
    descripcion: Yup.string(),
});

function EditFicheroDialog({ id, onSave, ...props }) {
    const snackbar = useSnackbar();
    const [fichero, setFichero] = useState(null);

    useEffect(() => {
        if (!props.open) {
            setFichero(null);
            return;
        }

        documentosProvider.getAll(`ficheros/${id}`).then(setFichero);
    }, [id, props.open]);

    if (!fichero) return null;

    const isImage = fichero.content_type.startsWith('image');

    return (
        <DialogForm
            title='Editar fichero'
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    nombre: fichero.nombre,
                    descripcion: fichero.descripcion,
                    files: [],
                    publico: fichero.operarios.length === 0,
                    operarios: fichero.operarios,
                },
                validationSchema: DocumentosSchema,
                onSubmit: ({ files, publico, ...values }, { setSubmitting, setFieldError }) => {
                    if (publico) values.operarios = [];

                    const formData = new FormData();
                    const formValues = {};

                    Object.entries(values).forEach(([key, value]) => (formValues[key] = value));
                    formData.append('params', JSON.stringify(formValues));

                    new Array(...files).forEach((file) => formData.append('files', file));

                    documentosProvider
                        .saveFichero(formData, id)
                        .then(() => {
                            onSave && onSave();
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                if (err.message instanceof Object) {
                                    for (const [field, errors] of Object.entries(err.message)) {
                                        setFieldError(field, errors.join('\n'));
                                    }
                                } else {
                                    snackbar.showMessage(err.message);
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {({ values }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField name='nombre' label='Nombre' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name='descripcion' label='Descripcion' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <CheckboxWithLabel
                            type='checkbox'
                            name='publico'
                            Label={{
                                label: 'Visible para todos los operarios',
                            }}
                        />
                    </Grid>
                    {!values.publico && (
                        <Grid item xs={12}>
                            <OperariosField />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel shrink>Fichero</InputLabel>
                            <List dense style={{ marginTop: 16 }}>
                                <ListItem
                                    key={fichero.id}
                                    dense
                                    button
                                    onClick={() => window.open(fichero.image_url || fichero.original_url, '_blank')}
                                >
                                    <ListItemAvatar>
                                        {isImage ? (
                                            <Avatar variant='square' src={fichero.thumbnail_url} />
                                        ) : (
                                            <Avatar variant='square'>
                                                <PictureAsPdfIcon />
                                            </Avatar>
                                        )}
                                    </ListItemAvatar>
                                    <ListItemText primary={fichero.original_url.split('/').pop()} />
                                </ListItem>
                            </List>
                        </FormControl>
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(EditFicheroDialog);

EditFicheroDialog.propTypes = {
    id: PropTypes.any,
    open: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
