import { useCallback, useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { proyectosProvider } from '../../api';
import { useFetchData } from '../../hooks/useFetchData';
import EditForm from '../common/forms/EditForm/EditForm';
import { EditProyectoFormContent } from './EditProyectoFormContent';

export const PRESUPUESTOS_ROUTE = 'presupuestos';
export const SERVICIOS_ROUTE = 'servicios';
export const PARTES_TRABAJO_ROUTE = 'partes-trabajo';
export const ALBARANES_ROUTE = 'albaranes';
export const AVANCES_OBRA_ROUTE = 'avances-obra';
export const CERTIFICACIONES_OBRA_ROUTE = 'certificaciones-obra';
export const RENDIMIENTO_ROUTE = 'rendimiento';

export default function EditProyectoForm() {
    const { id } = useParams();
    const { isExact: isRouteExact, url } = useRouteMatch();
    const history = useHistory();

    const fetchDataFn = useCallback(
        () =>
            proyectosProvider.getAll(id).then((proyecto) => ({
                ...proyecto,
                avances_obra: null,
                certificaciones_obra: null,
            })),
        [id],
    );
    const { data, fetchData } = useFetchData(fetchDataFn, null);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (!isRouteExact || !data) return;

        history.replace(`${url}/${SERVICIOS_ROUTE}`);
    }, [data, url, isRouteExact]);

    return (
        data && (
            <>
                <EditForm data={data} fetchData={fetchData}>
                    <EditProyectoFormContent />
                </EditForm>
            </>
        )
    );
}
