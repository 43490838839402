import * as Yup from 'yup';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { equiposProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import DialogForm from '../common/forms/DialogForm';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FichaTecnicaForm } from './FichaTecnicaForm';

const EquiposSchema = Yup.object().shape({
    referencia: Yup.string().required('Requerido'),
    nombre: Yup.string().required('Requerido'),
    cliente: Yup.mixed().required('Selecciona un cliente'),
    direccion: Yup.mixed().required('Selecciona una direccion'),
    instalacion: Yup.mixed().required('Selecciona un instalacion'),
    tipo_equipo_id: Yup.mixed().required('Selecciona un tipo de equipo'),
});

const useStyles = makeStyles(
    (theme) => ({
        dialogPaper: {},
    }),
    { name: 'EditEquipoDialog' },
);

function EditEquipoDialog({ id, onSave, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const [equipo, setEquipo] = useState(null);

    useEffect(() => {
        if (!props.open) {
            setEquipo(null);
            return;
        }

        equiposProvider.getAll(id).then(setEquipo);
    }, [id, props.open]);

    if (!equipo) return null;

    const {
        id: equipoId,
        referencia,
        nombre,
        marca,
        modelo,
        numero_serie: numeroSerie,
        fecha_fin_garantia: fechaFinGarantia,
        tipo_refrigerante_carburante: tipoRefrigeranteCarburante,
        potencia,
        tipo_maquina: tipoMaquina,
        ubicacion,
        cliente_id: clienteId,
        instalacion_id: instalacionId,
        direccion_id: direccionId,
        revisiones,
        activo,
        tipo_equipo_id: tipoEquipoId,
    } = equipo;

    return (
        <DialogForm
            title='Editar equipo'
            maxWidth='md'
            className={classes.dialogPaper}
            FormikProps={{
                initialValues: {
                    referencia,
                    nombre,
                    marca,
                    modelo,
                    numero_serie: numeroSerie,
                    fecha_fin_garantia: fechaFinGarantia,
                    tipo_refrigerante_carburante: tipoRefrigeranteCarburante,
                    potencia,
                    tipo_maquina: tipoMaquina,
                    ubicacion,
                    cliente: { id: clienteId },
                    instalacion: instalacionId ? { id: instalacionId } : null,
                    direccion: { id: direccionId },
                    activo,
                    revisiones,
                    tipo_equipo_id: tipoEquipoId,
                },
                enableReinitialize: true,
                validationSchema: EquiposSchema,
                onSubmit: (
                    { cliente, direccion, instalacion, tipo_equipo_id: tipoEquipoId, ...values },
                    { setSubmitting, setFieldError },
                ) => {
                    equiposProvider
                        .save(
                            {
                                ...values,
                                cliente_id: cliente.id,
                                direccion_id: direccion.id,
                                instalacion_id: instalacion ? instalacion.id : null,
                                tipo_equipo_id: tipoEquipoId === '' ? null : tipoEquipoId,
                            },
                            equipoId,
                        )
                        .then((data) => {
                            onSave && onSave(data.id);
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {() => <FichaTecnicaForm />}
        </DialogForm>
    );
}

export default withButtonOpener(EditEquipoDialog);

EditEquipoDialog.propTypes = {
    id: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};
