import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { tareasProvider } from '../../../../api';
import { withButtonOpener } from '../../../../hooks/withButtonOpener';
import DialogEditor from '../../../common/forms/DialogEditor';
import { Tab, Tabs } from '../../../common/Tabs';
import FromFotosTab from './FromFotosTab';
import FromPcTab from './FromPcTab';

const useStyles = makeStyles(
    (theme) => ({
        tabsRoot: {
            marginTop: 0,
        },
        tabsContainer: {
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'AddAdjuntosDialog' },
);

const FOTOS = 'fotos';
const FROM_PC = 'from-pc';

function AddAdjuntosDialog({ albaranId, initialFiles, initialSelectedFotoIds, onAccept, ...props }) {
    const classes = useStyles();
    const [files, setFiles] = useState([]);

    const [tipo, setTipo] = useState(FOTOS);

    const [fotos, setFotos] = useState([]);

    useEffect(() => {
        if (!props.open) {
            setFotos([]);
            setFiles([]);
            return;
        }

        setFiles(initialFiles);

        tareasProvider
            .getAlbaranFotos(albaranId)
            .then((fotos) =>
                setFotos(fotos.map((foto) => ({ ...foto, selected: initialSelectedFotoIds.includes(foto.id) }))),
            );
    }, [props.open, albaranId, initialSelectedFotoIds, initialFiles]);

    return (
        <DialogEditor
            title='Añadir adjuntos'
            maxWidth='md'
            saveButtonText='Aceptar'
            onSave={() => {
                props.onClose();

                return onAccept({
                    files,
                    fotos: fotos.filter((foto) => foto.selected),
                });
            }}
            {...props}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Tabs
                        disableGutters
                        value={tipo}
                        onChange={(ev, newValue) => setTipo(newValue)}
                        aria-label='Tipo de direccion'
                        classes={{
                            root: classes.tabsRoot,
                            flexContainer: classes.tabsContainer,
                        }}
                    >
                        <Tab label='Fotos del albarán' value={FOTOS} />
                        <Tab label='Seleccionar del PC' value={FROM_PC} />
                    </Tabs>
                </Grid>
                {tipo === FROM_PC && <FromPcTab files={files} setFiles={setFiles} />}
                {tipo === FOTOS && (
                    <FromFotosTab
                        fotos={fotos}
                        onToggleSelectedFoto={(selectedFoto) =>
                            setFotos((fotos) =>
                                fotos.map((foto) =>
                                    foto.id !== selectedFoto.id ? foto : { ...foto, selected: !foto.selected },
                                ),
                            )
                        }
                    />
                )}
            </Grid>
        </DialogEditor>
    );
}

AddAdjuntosDialog.propTypes = {
    albaranId: PropTypes.string,
    onAccept: PropTypes.func,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    initialFiles: PropTypes.array,
    initialSelectedFotoIds: PropTypes.array,
};

export default withButtonOpener(AddAdjuntosDialog);
