import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Button from '../Button';

const AlertDialog = ({
    open,
    onClose,
    onConfirm,
    dialogTitle,
    dialogText,
    dialogContent: overrideDialogContent,
    confirmationText = null,
    closeButtonText = 'No',
    confirmButtonText = 'Si',
}) => {
    const [confirmationValue, setConfirmationValue] = useState('');

    useEffect(() => {
        setConfirmationValue('');
    }, [open]);

    const dialogContent =
        overrideDialogContent ??
        (Array.isArray(dialogText) ? (
            dialogText.map((text, i) => <DialogContentText key={i}>{text}</DialogContentText>)
        ) : (
            <DialogContentText>{dialogText}</DialogContentText>
        ));

    const canConfirm = confirmationText === null || confirmationValue === confirmationText;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title'>{dialogTitle}</DialogTitle>
            <DialogContent>
                {dialogContent}
                {confirmationText && (
                    <div style={{ marginTop: 24 }}>
                        <TextField
                            fullWidth
                            value={confirmationValue}
                            onChange={(ev) => setConfirmationValue(ev.target.value)}
                            onBlur={(ev) => setConfirmationValue(ev.target.value)}
                            error={!canConfirm}
                            helperText={`Tienes que escribir "${confirmationText}" para poder confirmar`}
                        />
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color='outlined'>
                    {closeButtonText}
                </Button>
                {onConfirm && (
                    <Button onClick={onConfirm} color='info' autoFocus disabled={!canConfirm}>
                        {confirmButtonText}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

AlertDialog.propTypes = {
    confirmationText: PropTypes.any,
    dialogText: PropTypes.any,
    dialogTitle: PropTypes.string,
    dialogContent: PropTypes.any,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    open: PropTypes.bool.isRequired,
    closeButtonText: PropTypes.string,
    confirmButtonText: PropTypes.string,
};

export default AlertDialog;
