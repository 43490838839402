import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { instalacionesProvider } from '../../api';
import DialogForm from '../common/forms/DialogForm';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import ClienteField from '../common/fields/ClienteField';
import PropTypes from 'prop-types';
import FileUploader from '../common/fields/FileUploader';
import DireccionField from '../common/fields/DireccionField';

const Instalacioneschema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
    cliente: Yup.mixed().required('Selecciona un cliente'),
    direccion: Yup.mixed().required('Selecciona una direccion'),
});

function NewInstalacionDialog({ onSave, clienteId, ...props }) {
    return (
        <DialogForm
            title='Añadir instalación'
            FormikProps={{
                initialValues: {
                    cliente: clienteId ? { id: clienteId } : null,
                    direccion: null,
                    nombre: '',
                    files: [],
                },
                validationSchema: Instalacioneschema,
                onSubmit: ({ cliente, direccion, files, ...values }, { setSubmitting, setFieldError }) => {
                    const formData = new FormData();
                    Object.entries(values).forEach(([key, value]) => formData.append(key, value));
                    formData.append('cliente_id', cliente.id);
                    formData.append('direccion_id', direccion.id);
                    new Array(...files).forEach((file) => formData.append('files', file));

                    instalacionesProvider
                        .save(formData)
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ touched, errors, values, setFieldValue }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ClienteField
                            error={touched.cliente && !!errors.cliente}
                            helperText={touched.cliente && errors.cliente}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DireccionField
                            error={touched.direccion && !!errors.direccion}
                            helperText={touched.direccion && errors.direccion}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name='nombre' label='Nombre' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <FileUploader
                            id='upload-instalacion-adjuntos-file'
                            label='Añadir adjuntos'
                            file={values.files}
                            setFile={(files) => setFieldValue('files', files)}
                            multiple={true}
                            isImage={false}
                            accept='image/jpeg,image/gif,image/png,application/pdf'
                        />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(NewInstalacionDialog);

NewInstalacionDialog.propTypes = {
    clienteId: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
