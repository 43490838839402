import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BaseListView, { numberOperatorFn, withTableState } from '../common/list/ListView';
import { dataProvider, estadosFichaje, estadosFichajeLabels, estadosFichajeSelectOptions } from '../../api/fichajes';
import { useFetchData } from '../../hooks/useFetchData';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { formatDate, formatTiempo } from '../../utils';
import EstadoChip from './EstadoChip';
import FichajesFilters from './FichajesFilters';
import MarcajesPopover from './MarcajesPopover';
import { VerFichajeButton } from './VerFichaje';
import { EditFichajeButton } from './EditarFichaje';
import { EditarMultiplesFichajesButton } from './EditarMultiplesFichajes';
import IconButton from '@material-ui/core/IconButton';
import LockIcon from '@material-ui/icons/Lock';
import { ButtonDialog } from '../common/dialogs/ButtonDialog';
import { fichajesProvider } from '../../api';
import { CrearFichajeButton } from './CrearFichaje';
import InfoMensualPopover from './InfoMensualPopover';
import { SelectColumnFilter } from '../common/list/SelectColumnFilter';
import { NumberFilter } from '../common/list/NumberFilter';
import { getHoras } from '../../api/tareas-functions';
import InfoIcon from '@material-ui/icons/Info';
import Aviso from '../common/Aviso';
import DownloadIcon from '../icons/Download';
import { fichajeEstadoIcons } from '../agenda/constants';
import CertificadoFichajesIcon from '../icons/CertificadoFichajes';
import Tooltip from '@material-ui/core/Tooltip';
import SettingsIcon from '@material-ui/icons/Settings';
import { FilledIconButton } from './FilledIconButton';
import ConfigurarHorquillaDialog from './ConfigurarHorquillaDialog';
import PropTypes from 'prop-types';

const ListView = withTableState('fichajes', BaseListView);

export default function FichajesList() {
    const [operarioId, setOperarioId] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(() => {
        const d = new Date();
        d.setHours(0, 0, 0, 0);
        return d;
    });

    const fetchDataFn = useCallback(
        () => dataProvider.getAllByOperario(operarioId, currentMonth),
        [operarioId, currentMonth],
    );
    const { data, fetchData } = useFetchData(fetchDataFn);

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        dataProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Operario',
                accessor: 'operario_id',
            },
            {
                Header: 'Fecha',
                accessor: 'fecha',
                Cell: ({ value }) => formatDate(value),
                filter: 'dateRange',
                disableFilters: true,
            },
            {
                Header: 'Fichajes',
                accessor: 'marcajes',
                // eslint-disable-next-line react/prop-types
                Cell: ({ value }) => <MarcajesPopover marcajes={value} />,
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'Estado',
                accessor: 'estado',
                // eslint-disable-next-line react/prop-types
                Cell: ({ value }) => (
                    <EstadoChip
                        size='small'
                        icon={React.createElement(fichajeEstadoIcons[value])}
                        label={estadosFichajeLabels[value]}
                        className={value}
                    />
                ),
                Filter: SelectColumnFilter,
                filterOptions: {
                    options: estadosFichajeSelectOptions,
                    includeAllOption: {
                        value: 'todos',
                        label: 'Todos',
                    },
                },
            },
            {
                Header: 'Horas ajustadas',
                helperText:
                    'Son las horas resultantes tras haber ajustado los marcajes aplicando el margen de tolerancia sobre el horario de trabajo',
                accessor: 'total_horas',
                Cell: ({ value }) => formatTiempo(value),
                Filter: NumberFilter,
                filter: numberOperatorFn(getHoras),
                filterOptions: {
                    units: 'h',
                },
            },
            {
                Header: 'Horas extras',
                helperText: 'Se calculan sobre las horas ajustadas',
                accessor: 'total_extras',
                Cell: ({ value }) => formatTiempo(value),
                Filter: NumberFilter,
                filter: numberOperatorFn(getHoras),
                filterOptions: {
                    units: 'h',
                },
            },
            {
                Header: 'Horas marcadas',
                helperText: 'Son las horas marcadas por el operario, sin aplicar ningun ajuste de tiempo',
                accessor: 'total_horas_real',
                Cell: ({ value }) => formatTiempo(value),
                Filter: NumberFilter,
                filter: numberOperatorFn(getHoras),
                filterOptions: {
                    units: 'h',
                },
            },
        ],
        [],
    );

    // console.log('fecha', fecha)

    const mesBloqueado = useMemo(() => data.length > 0 && data.every((row) => row.bloqueado), [data]);
    const pastMonthSelected = useMemo(() => {
        const today = new Date();
        return currentMonth.getMonth() < today.getMonth() || currentMonth.getFullYear() < today.getFullYear();
    }, [currentMonth]);

    const exportar = () => {
        fichajesProvider
            .exportar(currentMonth, operarioId)
            .then(async (data) => {
                const uri = window.URL.createObjectURL(await data.blob());

                const a = document.createElement('a');
                a.style = { display: 'none' };
                a.href = uri;
                a.download = data.headers.get('Content-Disposition').split('filename=')[1];
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(uri);
                document.body.removeChild(a);
            })
            .catch((err) => {
                // snackbar.showMessage('Ha ocurrido un error durante la descarga');
                console.error(err);
            });
    };

    const options = useMemo(
        () => ({
            useActions: true,
            deleteConfirmationText: 'borrar fichaje',
            tableOptions: {
                useSelect: true,
                hideSelectColumn: mesBloqueado,
                forceHiddenColumns: ['operario_id'],
                usePagination: false,
            },
            canToggleColumns: false,
            canExportCsv: false,
            batchComponents: ({ selectedFlatRows, rows, tableColumns }) => {
                const canBloquear =
                    !mesBloqueado &&
                    pastMonthSelected &&
                    rows.length > 0 &&
                    rows.filter((row) => row.original.estado !== estadosFichaje.VERIFICADO).length === 0;

                return (
                    <>
                        {mesBloqueado && (
                            <Aviso
                                icon={<InfoIcon />}
                                text='Esta lista de fichajes esta cerrada y no se puede editar'
                                color='#F3AC3D'
                            />
                        )}
                        <InfoMensualPopover operarioId={operarioId} fecha={currentMonth} />
                        <IconButton onClick={exportar}>
                            <DownloadIcon />
                        </IconButton>
                        {mesBloqueado && (
                            <Tooltip title='Descargar certificado jornada laboral'>
                                <IconButton
                                    aria-label='Descargar certificado'
                                    onClick={() =>
                                        window.open(fichajesProvider.getCertificadoURL(currentMonth, operarioId))
                                    }
                                >
                                    <CertificadoFichajesIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {!mesBloqueado && (
                            <>
                                <ButtonDialog
                                    button={
                                        <IconButton disabled={!canBloquear}>
                                            <LockIcon />
                                        </IconButton>
                                    }
                                    dialogTitle='Bloquear hoja de horas'
                                    dialogText={[
                                        'Despues de bloquear la hoja de horas de este mes ya no se podran editar los fichajes de este operario.',
                                        'Esta opcion no se puede deshacer. ¿Deseas continuar?',
                                    ]}
                                    confirmationText='bloquear'
                                    onConfirm={() => {
                                        fichajesProvider.bloquear(operarioId, currentMonth).then(fetchData);
                                    }}
                                />
                                <RevisarFichajesButton rows={selectedFlatRows} onSave={fetchData} />
                                <EditarMultiplesFichajesButton
                                    onSave={fetchData}
                                    fichajes={selectedFlatRows.map((row) => row.original)}
                                    disabled={selectedFlatRows.length === 0}
                                />
                                <CrearFichajeButton onSave={fetchData} />
                            </>
                        )}
                    </>
                );
            },
        }),
        [fetchData, currentMonth, operarioId, mesBloqueado],
    );

    return (
        <>
            <ListView
                title='Fichajes'
                titleActions={
                    <ConfigurarHorquillaDialog
                        button={
                            <FilledIconButton>
                                <SettingsIcon />
                            </FilledIconButton>
                        }
                    />
                }
                tableTitle='Lista de fichajes'
                basePath='/fichajes'
                columns={columns}
                data={data}
                options={options}
                onDelete={onDelete}
                beforeListComponent={() => (
                    <FichajesFilters
                        onChangeOperario={(operario) => setOperarioId(operario ? operario.id : null)}
                        defaultOperarioId={operarioId}
                        setCurrentMonth={setCurrentMonth}
                        currentMonth={currentMonth}
                    />
                )}
                extraActions={(row) => (
                    <>
                        <VerFichajeButton fichaje={row} />
                        {!row.bloqueado && <EditFichajeButton onSave={fetchData} fichaje={row} />}
                    </>
                )}
            />
        </>
    );
}

function RevisarFichajesButton({ rows, onSave }) {
    const hasPendientes = rows.filter((row) => row.original.estado === estadosFichaje.PENDIENTE).length > 0;

    const VerificarIcon = fichajeEstadoIcons.VERIFICADO;

    return (
        <ButtonDialog
            button={
                <IconButton disabled={!hasPendientes}>
                    <VerificarIcon />
                </IconButton>
            }
            dialogTitle='Verificar los días seleccionados'
            dialogText='Esta operación no se puede deshacer. ¿Estás seguro que continuar?'
            onConfirm={() => {
                const ids = rows.map((row) => row.original.id);
                fichajesProvider.revisar(ids).then(onSave);
            }}
        />
    );
}

RevisarFichajesButton.propTypes = {
    onSave: PropTypes.any,
    rows: PropTypes.any,
};
