import { formatISODate } from '../utils';
import DataProvider from './DataProvider';

export const empty = {
    referencia: '',
    proveedor: null,
    factura_compra: '',
    estado: '',
    fecha_solicitud: '',
    fecha_llegada_prevista: '',
    fecha_llegada: '',
};

export const estadoRevisionLabels = {
    PTE_REVISAR: 'Pte. revisar',
    PTE_TRAMITAR: 'Pte. tramitar',
    TRAMITADO: 'Tramitado',
    PTE_ABONO: 'Pte. abono',
    VERIFICADO: 'Verificado',
};

export const estadoDevolucionLabels = {
    SIN_DEVOLUCION: 'Sin devolución',
    PTE_TRAMITAR: 'Pte. tramitar',
    TRAMITADO: 'Tramitado',
    PTE_ABONO: 'Pte. abono',
    ABONADO: 'Abonado',
};

export const estadoRevisionOptions = Object.entries(estadoRevisionLabels).map(([value, label]) => ({ value, label }));
export const estadoDevolucionOptions = Object.entries(estadoDevolucionLabels).map(([value, label]) => ({
    value,
    label,
}));

class AlbaranesCompraProvider extends DataProvider {
    constructor() {
        super('albaranes_compra', empty);
    }

    updateLinea = async (albaranCompraId, lineaId, linea) => {
        let url = `${albaranCompraId}/lineas`;
        if (lineaId != null) url += `/${lineaId}`;

        return this.getAll(url, {
            body: JSON.stringify(linea),
            method: 'post',
        });
    };

    deleteLinea = async (albaranCompraId, lineaId) => {
        return this.getAll(`${albaranCompraId}/lineas/${lineaId}`, {
            method: 'delete',
        });
    };

    checkImputacion = async (tareaId, fecha) => {
        return this.getAll(`check-imputar/${tareaId}/${formatISODate(fecha)}`);
    };

    imputar = async (albaranCompraId, tareaId, fecha, ids, margen, mergePartes = true) => {
        return this.getAll(`${albaranCompraId}/imputar`, {
            body: JSON.stringify({
                tarea_id: tareaId,
                fecha,
                ids,
                margen,
                merge_partes: mergePartes,
            }),
            method: 'post',
        });
    };

    getAlbaranesCompraByLineas = async (lineas) => {
        return this.getAll('by-lineas', {
            body: JSON.stringify({ linea_ids: lineas }),
            method: 'post',
        });
    };

    verificarLineas = async (albaranCompraId, lineaIds) => {
        return this.getAll(`${albaranCompraId}/verificar-lineas`, {
            body: JSON.stringify({ ids: lineaIds }),
            method: 'post',
        });
    };
}

export const dataProvider = new AlbaranesCompraProvider();
