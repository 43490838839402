import React, { useEffect } from 'react';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { formatTiempo } from '../../utils';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import { operariosProvider } from '../../api';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const List = withStyles((theme) => ({
    root: {
        '& .MuiListItem-divider:last-of-type': {
            borderBottom: 0,
        },
    },
    padding: {
        padding: 0,
    },
}))(MuiList);

const ListItem = withStyles((theme) => ({
    root: {
        '& strong': {
            width: 200,
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&.seccion': {
            color: theme.palette.neutral.primary,
            paddingTop: 0,
            paddingBottom: 0,
            textTransform: 'uppercase',
        },
        '&.seccion .MuiTypography-root': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
}))(MuiListItem);

export default function InfoMensualPopover({ operarioId, fecha }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [resumen, setResumen] = React.useState({});

    const { laborables, trabajadas, extras, reales, diferencia } = resumen;

    useEffect(() => {
        if (operarioId === null) return;

        operariosProvider.getResumenHorasMes(operarioId, fecha).then(setResumen);
    }, [operarioId, fecha]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <IconButton aria-describedby={id} onClick={handleClick}>
                <InfoIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List>
                    <ListItem divider>
                        <strong>Horas laborables:</strong> {laborables}h
                    </ListItem>
                    <ListItem divider className='seccion'>
                        <Typography variant='subtitle1'>Según la hora de marcaje ajustada</Typography>
                    </ListItem>
                    <ListItem divider>
                        <strong>Horas trabajadas:</strong> {formatTiempo(trabajadas, false)}
                    </ListItem>
                    <ListItem divider>
                        <strong>Horas extras/flotantes:</strong> {formatTiempo(extras, false)}
                    </ListItem>
                    <ListItem divider className='seccion'>
                        <Typography variant='subtitle1'>Según la hora de marcaje real</Typography>
                    </ListItem>
                    <ListItem divider>
                        <strong>Horas trabajadas:</strong> {formatTiempo(reales, false)}
                    </ListItem>
                    <ListItem divider>
                        <strong>Diferencia:</strong> {formatTiempo(diferencia, false)}
                    </ListItem>
                </List>
            </Popover>
        </>
    );
}

InfoMensualPopover.propTypes = {
    fecha: PropTypes.any,
    operarioId: PropTypes.any,
};
