import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { ParteTrabajoAlbaranFilter } from './ParteTrabajoAlbaranFilter';
import { ParteTrabajoFechaFilter } from './ParteTrabajoFechaFilter';
import { ParteTrabajoOperarioFilter } from './ParteTrabajoOperarioFilter';
import { ParteTrabajoRevisadoFilter } from './ParteTrabajoRevisadoFilter';

export default function ParteTrabajoListFilters({ items: jornadas, onFilter }) {
    const operariosList = useMemo(
        () =>
            Object.entries(
                jornadas
                    .filter((j) => j.operario_id)
                    .reduce((list, jornada) => {
                        list[jornada.operario_id] = jornada.operario;
                        return list;
                    }, {}),
            ).map(([id, nombre]) => ({ id, nombre })),
        [jornadas],
    );

    const [filters, setFilters] = useState({
        fecha: null,
        operario: null,
        revisado: null,
        albaran: null,
    });

    function onFilterChange(key, value) {
        setFilters((filters) => ({ ...filters, [key]: value }));
    }

    useEffect(() => {
        if (filters.fecha) {
            jornadas = jornadas.filter(
                (j) =>
                    isAfter(new Date(j.fecha), filters.fecha.start) && isBefore(new Date(j.fecha), filters.fecha.end),
            );
        }
        if (filters.operario) {
            jornadas = jornadas.filter((j) => j.operario_id === filters.operario);
        }
        if (filters.revisado !== null) {
            jornadas = jornadas.filter((j) => j.revisada === filters.revisado);
        }
        if (filters.albaran !== null) {
            jornadas = jornadas.filter((j) => (filters.albaran ? j.albaran_id !== null : j.albaran_id === null));
        }

        onFilter(jornadas);
    }, [jornadas, filters]);

    return (
        <>
            <ParteTrabajoFechaFilter onFilterChange={(range) => onFilterChange('fecha', range)} />
            <ParteTrabajoOperarioFilter
                operarios={operariosList}
                onFilterChange={(operarioId) => onFilterChange('operario', operarioId)}
            />
            <ParteTrabajoRevisadoFilter onFilterChange={(revisado) => onFilterChange('revisado', revisado)} />
            <ParteTrabajoAlbaranFilter onFilterChange={(albaran) => onFilterChange('albaran', albaran)} />
        </>
    );
}

ParteTrabajoListFilters.propTypes = {
    items: PropTypes.any,
    onFilter: PropTypes.any,
};
