import { useEffect, useState } from 'react';
import { clientesProvider } from '../../../api';
import { Field, useField } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import MuiTextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

export default function InstalacionField({ error, helperText, disabled }) {
    const [instalacionesOptions, setInstalacionesOptions] = useState([]);

    const [{ value: instalacion }, , { setValue }] = useField('instalacion');
    const [{ value: cliente }, ,] = useField('cliente');
    const [{ value: direccion }, ,] = useField('direccion');

    const clienteId = cliente?.id;
    const direccionId = direccion?.id;
    const instalacionId = instalacion?.id;

    function queryInstalacionesOptions() {
        if (!clienteId || !direccionId) {
            setInstalacionesOptions([]);
            return;
        }

        clientesProvider
            .actionOnId(clienteId, `instalaciones/${direccionId}/as_options`, null, { method: 'get' })
            .then((instalaciones) => {
                setInstalacionesOptions(instalaciones);
                setValue(instalacionId ? instalaciones.find((d) => d.id === instalacionId) : null);
            });
    }

    useEffect(queryInstalacionesOptions, [clienteId, direccionId, instalacionId]);

    return (
        <Field
            name='instalacion'
            fullWidth
            disabled={disabled}
            component={Autocomplete}
            options={instalacionesOptions}
            getOptionSelected={(option, value) => option.id === value?.id}
            getOptionLabel={(option) => option.nombre ?? ''}
            renderInput={(params) => (
                <MuiTextField {...params} error={error} helperText={helperText} label='Instalación' />
            )}
        />
    );
}

InstalacionField.propTypes = {
    disabled: PropTypes.any,
    error: PropTypes.any,
    helperText: PropTypes.any,
};
