import DataProvider from './DataProvider';

export const empty = {
    codigo: '',
    nif: '',
    nombre: '',
    email: '',
    email_envios_list: '',
    telefono: '',
    direccion: '',
    codigo_postal: '',
    poblacion: '',
    provincia: '',
    persona_contacto: '',
    activo: true,
    desglosar_explicacion_operarios: false,
};

class ClientesProvider extends DataProvider {
    constructor() {
        super('clientes', empty);
    }

    getTareas = async (id) => this.actionOnId(id, 'tareas', null, { method: 'get' });

    getDirecciones = async (clienteId) => this.actionOnId(clienteId, 'direcciones', null, { method: 'get' });
    deleteDireccion = async (clienteId, idx) =>
        this.actionOnId(clienteId, `direcciones/${idx}`, null, { method: 'delete' });

    getProyectos = async (clienteId) => this.actionOnId(clienteId, 'proyectos', null, { method: 'get' });
    deleteProyecto = async (clienteId, idx, cascade = false) =>
        this.actionOnId(clienteId, `proyectos/${idx}${cascade ? '?cascade' : ''}`, null, { method: 'delete' });

    getObservaciones = async (clienteId) =>
        this.actionOnId(clienteId, 'observaciones', null, { method: 'get' }).then((observaciones) => {
            observaciones.forEach((observacion) => {
                if (observacion.notas.length === 0) return;

                observacion.texto = observacion.notas[0].texto;
            });
            return observaciones;
        });

    deleteObservacion = async (clienteId, idx) =>
        this.actionOnId(clienteId, `observaciones/${idx}`, null, { method: 'delete' });

    search = async (query) => {
        return this.getAll('buscar', {
            method: 'post',
            body: JSON.stringify({ query }),
        });
    };
}

export const dataProvider = new ClientesProvider();
