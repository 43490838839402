import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

export function withButtonOpener(Component) {
    function Wrapper({ button, tooltip, TooltipProps, onClose, ...props }) {
        const [open, setOpen] = useState(false);

        let formButton = React.cloneElement(button, {
            onClick: (ev) => {
                setOpen(true);
                button.props.onClick && button.props.onClick(ev);
            },
        });

        if (tooltip) {
            formButton = (
                <Tooltip arrow title={tooltip} {...TooltipProps}>
                    <span>{formButton}</span>
                </Tooltip>
            );
        }

        return (
            <>
                {formButton}
                <Component
                    onClose={(ev) => {
                        ev && ev.stopPropagation && ev.stopPropagation();
                        setOpen(false);
                        onClose && onClose(ev);
                    }}
                    open={open}
                    {...props}
                />
            </>
        );
    }

    Wrapper.propTypes = {
        button: PropTypes.any,
        onClick: PropTypes.any,
        onClose: PropTypes.any,
        tooltip: PropTypes.any,
        TooltipProps: PropTypes.any,
    };
    return Wrapper;
}
