import { Badge } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import isToday from 'date-fns/isToday';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { notasDiaProvider } from '../../api';
import useAuthState from '../../AuthState';
import { useFetchData } from '../../hooks/useFetchData';
import { format, formatISODate } from '../../utils';
import NotasDiaDialog from './NotasDiaDialog/NotasDiaDialog';

export const GROUP_SPACE = 300;
export const GROUP_GAP = 4;

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            height: 48,
        },
        dayHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 84,
            textAlign: 'center',
            color: theme.palette.neutral.primary,
            textTransform: 'capitalize',
            margin: '0 auto',
            '& .MuiTypography-root': {
                padding: theme.spacing(0, 2),
            },
            '& .noLaborable': {
                color: theme.palette.error.main,
            },
            '& .today': {
                background: theme.palette.primary.main,
                color: 'white',
                borderRadius: 20,
            },
            // '&:hover $notasDiaButton': {
            //     display: 'block',
            // },
            // '&:hover $day': {
            //     display: 'none',
            // },
        },
        dayHeaderLink: {
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        day: {},
        notasDiaButton: {
            display: 'none',
            background: 'white',
            padding: 8,
            opacity: 0.5,
            transition: 'opacity 0.2s',
            '&.hasNotas': {
                opacity: 1,
                color: theme.palette.primary.main,
            },
            '&:hover': {
                opacity: 1,
                background: 'white',
            },
        },
        notasBadge: {
            transform: 'translateY(-12px)',
        },
    }),
    { name: 'CalendarioHeader' },
);

export default function CalendarioHeader({ showOnlyColumn, days, noLaborables, onFilter }) {
    const classes = useStyles();

    const fetchDataFn = useCallback(
        () => notasDiaProvider.getAll(`has-notas/${formatISODate(days[0])}/${formatISODate(days[days.length - 1])}`),
        [days],
    );
    const { data: hasNotasDia, fetchData } = useFetchData(fetchDataFn);
    const {
        userInfo: { preferencias: { usar_notas_dia: usarNotasDia } = {} },
    } = useAuthState();

    useEffect(() => {
        if (!usarNotasDia) return;

        fetchData();
    }, [fetchData]);

    const headerDays = useMemo(
        () =>
            days
                .filter((d, i) => showOnlyColumn === null || showOnlyColumn === i)
                .map((d, i) => {
                    let element = (
                        <Typography
                            variant='body2'
                            className={clsx(classes.day, {
                                noLaborable: noLaborables.includes(formatISODate(d)),
                                today: isToday(d),
                                [classes.dayHeaderLink]: usarNotasDia,
                            })}
                        >
                            {isToday(d) ? 'HOY' : format(d, 'eee d')}
                        </Typography>
                    );

                    if (usarNotasDia) {
                        element = (
                            <NotasDiaDialog
                                fecha={d}
                                button={
                                    <Tooltip arrow title='Ver notas para los operarios'>
                                        {element}
                                    </Tooltip>
                                }
                                onSave={fetchData}
                            />
                        );
                    }

                    return (
                        <div key={i} className={classes.dayHeader}>
                            {element}
                            <Badge
                                className={classes.notasBadge}
                                badgeContent={hasNotasDia[formatISODate(d)]}
                                color='primary'
                            />
                        </div>
                    );
                }),
        [days, noLaborables, showOnlyColumn, hasNotasDia],
    );

    return <div className={classes.root}>{headerDays}</div>;
}

CalendarioHeader.propTypes = {
    days: PropTypes.any,
    noLaborables: PropTypes.any,
    onFilter: PropTypes.any,
    showOnlyColumn: PropTypes.any,
};
