import * as Yup from 'yup';
import { useSnackbar } from 'material-ui-snackbar-provider';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { companiesProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import DialogForm from '../../common/forms/DialogForm';
import PropTypes from 'prop-types';

const UsuarioSchema = Yup.object().shape({
    username: Yup.string().required('Requerido'),
    email: Yup.string().email(),
    new_password: Yup.string(),
    confirm_password: Yup.string().oneOf([Yup.ref('new_password'), null], 'Las contraseñas tienen que ser iguales'),
});

function EditUsuarioDialog({ usuario, onSave, ...props }) {
    const snackbar = useSnackbar();

    return (
        <DialogForm
            title='Editar datos de acceso'
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    username: usuario.username,
                    email: usuario.email,
                    new_password: '',
                    confirm_password: '',
                },
                validationSchema: UsuarioSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    companiesProvider
                        .getAll(`users/${usuario.id}`, {
                            method: 'post',
                            body: JSON.stringify(values),
                        })
                        .then(() => {
                            onSave();
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField name='username' label='Nombre de usuario' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name='email'
                            label='Correo electrónico'
                            helperText='Se utiliza para recuperar la contraseña'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name='new_password'
                            label='Contraseña'
                            type='password'
                            fullWidth
                            helperText='Déjalo en blanco si no quieres cambiarla'
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='confirm_password' label='Confirmar contraseña' type='password' fullWidth />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(EditUsuarioDialog);

EditUsuarioDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    usuario: PropTypes.any,
};
