import { Route, Switch } from 'react-router-dom';
import CuestionariosList from './CuestionariosList';
import EditCuestionarioForm from './EditCuestionarioForm';

export default function CuestionariosModule() {
    return (
        <>
            <Switch>
                <Route
                    path='/cuestionarios/:id'
                    render={({ match }) => <EditCuestionarioForm basePath='/cuestionarios' match={match} />}
                />
                <Route path='/cuestionarios'>
                    <CuestionariosList />
                </Route>
            </Switch>
        </>
    );
}
