import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import BusinessIcon from '@material-ui/icons/Business';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formatDate } from '../../../../utils';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles(
    (theme) => ({
        tooltip: {
            backgroundColor: theme.palette.neutral.hudBackground,
            opacity: 1,
            padding: theme.spacing(1),
        },
        tooltipArrow: {
            color: theme.palette.neutral.hudBackground,
        },
        label: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                color: theme.palette.neutral.primary,
                paddingLeft: theme.spacing(0.5),
            },
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                fontSize: 20,
            },
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightBold,
                flex: 1,
                marginLeft: theme.spacing(1),
            },
        },
        button: {
            color: 'white',
        },
        info: {
            paddingLeft: theme.spacing(3.5),
            marginTop: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
            '&:not(:last-of-type)': {
                paddingBottom: theme.spacing(1),
                borderBottom: '1px solid white',
            },
            '& .MuiTypography-root:first-of-type': {
                display: 'flex',
                alignItems: 'center',
            },
            '& .MuiTypography-root:first-of-type .MuiIconButton-root': {
                marginLeft: 'auto',
            },
            '& svg': {
                fontSize: 20,
            },
        },
    }),
    { name: 'AlbaranesCompraInfoTooltip' },
);

export default function AlbaranesCompraInfoTooltip({ albaranes }) {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Tooltip
            arrow
            interactive
            classes={{
                tooltip: classes.tooltip,
                arrow: classes.tooltipArrow,
            }}
            title={
                <>
                    <div className={classes.header}>
                        <BusinessIcon />
                        <Typography variant='subtitle2'>
                            {albaranes.length === 1 ? 'Albaran' : 'Albaranes'} de compra
                        </Typography>
                        {albaranes.length === 1 && (
                            <IconButton
                                onClick={() => history.push(`/albaranes-compra/${albaranes[0].albaran_compra.id}`)}
                                size='small'
                                className={classes.button}
                            >
                                <VisibilityIcon />
                            </IconButton>
                        )}
                    </div>
                    {albaranes.map((albaranCompraLinea, index) => {
                        const albaranCompra = albaranCompraLinea.albaran_compra;
                        return (
                            <div className={classes.info} key={index}>
                                <Typography variant='subtitle2'>
                                    <strong>Ref: </strong> {albaranCompra.referencia}
                                    {albaranes.length > 1 && (
                                        <IconButton
                                            onClick={() => history.push(`/albaranes-compra/${albaranCompra.id}`)}
                                            size='small'
                                            className={classes.button}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    )}
                                </Typography>
                                <Typography variant='subtitle2'>
                                    <strong>Núm. Factura: </strong> {albaranCompra.factura_compra}
                                </Typography>
                                <Typography variant='subtitle2'>
                                    <strong>Proveedor: </strong> {albaranCompra.proveedor.nombre}
                                </Typography>
                                <Typography variant='subtitle2'>
                                    <strong>Fecha de pedido: </strong> {formatDate(albaranCompra.fecha_solicitud)}
                                </Typography>
                                <Typography variant='subtitle2'>
                                    <strong>Unidades compradas: </strong> {albaranCompraLinea.unidades}
                                    {albaranCompraLinea.verificada ? ' (Verficado)' : ''}
                                </Typography>
                            </div>
                        );
                    })}
                </>
            }
        >
            <Chip
                size='small'
                icon={<InfoIcon />}
                label={
                    albaranes.length === 1
                        ? `Ref. ${albaranes[0].albaran_compra.referencia}`
                        : `${albaranes.length} albaranes`
                }
            />
        </Tooltip>
    );
}

AlbaranesCompraInfoTooltip.propTypes = {
    albaranes: PropTypes.any,
};
