import PaperTable from './PaperTable';
import PropTypes from 'prop-types';
import BeneficiosPaper from './BeneficiosPaper';

export default function CalculoRendimientoPaper({ manoObra, materiales, gastos, totalComercial, totalFacturado }) {
    const currency = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' });

    const rows = [
        {
            id: '1',
            concepto: 'Mano de obra',
            importeCoste: manoObra.importeCoste,
            importeVenta: manoObra.importeVenta,
            facturado: null,
        },
        {
            id: '2',
            concepto: 'Materiales',
            importeCoste: materiales.importeCoste,
            importeVenta: materiales.importeVenta,
            facturado: null,
        },
        {
            id: '3',
            concepto: 'Gastos',
            importeCoste: gastos.importeCoste,
            importeVenta: gastos.importeVenta,
            facturado: null,
        },
        // {
        //     id: '4',
        //     concepto: 'Comercial',
        //     importeCoste: totalComercial,
        //     importeVenta: null,
        //     facturado: null,
        // },
    ];

    const totales = rows.reduce(
        (totales, row) => ({
            importeCoste: totales.importeCoste + row.importeCoste,
            importeVenta: totales.importeVenta + (row.importeVenta ?? 0),
        }),
        { importeCoste: 0, importeVenta: 0 },
    );

    totales.facturado = totalFacturado;

    const columns = [
        {
            label: 'Concepto',
            renderCell: (row) => row.concepto,
            footer: 'Total',
        },
        {
            label: 'Total coste',
            renderCell: (row) => currency.format(row.importeCoste),
            isNumber: true,
        },
        {
            label: 'Total venta',
            renderCell: (row) => (row.importeVenta ? currency.format(row.importeVenta) : null),
            isNumber: true,
        },

        {
            label: 'Total facturado',
            renderCell: (row) => (row.facturado ? currency.format(row.facturado) : null),
            isNumber: true,
        },
    ];

    return (
        <>
            <PaperTable columns={columns} title='Calculo de rendimiento' rows={rows} footerRow={totales} />
            <BeneficiosPaper
                totalFacturado={totalFacturado}
                totalCoste={totales.importeCoste}
                totalVenta={totales.importeVenta}
            />
        </>
    );
}

CalculoRendimientoPaper.propTypes = {
    gastos: PropTypes.any,
    manoObra: PropTypes.any,
    materiales: PropTypes.any,
    totalComercial: PropTypes.any,
    totalFacturado: PropTypes.any,
};
