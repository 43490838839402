import { Dialog, DialogActions } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { KeyboardDatePicker as MuiKeyboardDatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import isBefore from 'date-fns/isBefore';
import isValid from 'date-fns/isValid';
import { es } from 'date-fns/locale';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { setEndOfDay } from '../../../utils';
import Button from '../Button';

const useStyles = makeStyles(
    (theme) => ({
        content: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
            minHeight: 400,
        },
        datePickersWrapper: {
            width: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
            '& .rdp': {
                margin: 0,
            },
            '& .rdp-day_range_middle': {
                backgroundColor: 'var(--rdp-background-color)',
                color: 'var(--rdp-text-color)',
            },
        },
        dateFields: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            '& .MuiFormControl-root': {
                flex: 1,
            },
            '& .MuiInputAdornment-root': {
                display: 'none',
            },
        },
    }),
    { name: 'DateRangePickerDialog' },
);

export default function DateRangePickerDialog({ open, onClose, onAccept, defaultDateRange }) {
    const classes = useStyles();
    const [dateRange, setDateRange] = useState(defaultDateRange);

    useEffect(() => {
        if (open) setDateRange(defaultDateRange);
    }, [open]);

    useEffect(() => {
        setDateRange(defaultDateRange);
    }, [defaultDateRange]);

    return (
        <Dialog maxWidth='md' open={open} onClose={onClose}>
            <DialogTitle>Selecciona un rango de fechas</DialogTitle>
            <DialogContent className={classes.content}>
                <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
            </DialogContent>
            <DialogActions>
                <Button
                    color='outlined'
                    onClick={() => {
                        onClose();
                    }}
                >
                    Cancelar
                </Button>
                <Button
                    color='info'
                    onClick={() => {
                        onClose();
                        onAccept({
                            from: new Date(dateRange.from),
                            to: setEndOfDay(new Date(dateRange.to)),
                        });
                    }}
                    disabled={!dateRange || !dateRange.to}
                >
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

DateRangePickerDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
    defaultDateRange: PropTypes.object,
};

export function DateRangePicker({ dateRange, setDateRange, className }) {
    const classes = useStyles();
    const [dateRangeFrom, setDateRangeFrom] = useState(dateRange?.from ?? null);
    const [dateRangeTo, setDateRangeTo] = useState(dateRange?.to ?? null);

    useEffect(() => {
        setDateRangeFrom(dateRange?.from ?? null);
        setDateRangeTo(dateRange?.to ?? null);
    }, [dateRange]);

    return (
        <div className={clsx(classes.datePickersWrapper, className)}>
            <div className={classes.dateFields}>
                <MuiKeyboardDatePicker
                    label='Desde'
                    placeholder='Selecciona una fecha'
                    InputLabelProps={{ shrink: true }}
                    clearable
                    autoOk={true}
                    format='dd/MM/yyyy'
                    name='daterange-from'
                    value={dateRangeFrom}
                    onChange={(date) => {
                        // console.log(date, dateRange);
                        setDateRangeFrom(date);
                    }}
                    onBlur={() => {
                        setDateRange((range) => {
                            if (!dateRangeFrom || !isValid(dateRangeFrom)) return undefined;
                            if (!range || !range.to) return { from: dateRangeFrom, to: undefined };

                            return {
                                from: dateRangeFrom,
                                to: isBefore(dateRangeFrom, range.to) ? range.to : dateRangeFrom,
                            };
                        });
                    }}
                />
                <MuiKeyboardDatePicker
                    label='Hasta'
                    placeholder='Selecciona una fecha'
                    InputLabelProps={{ shrink: true }}
                    clearable
                    autoOk={true}
                    format='dd/MM/yyyy'
                    name='daterange-to'
                    value={dateRangeTo}
                    onChange={(date) => {
                        // console.log(date, dateRange);
                        setDateRangeTo(date);
                    }}
                    onBlur={() => {
                        setDateRange((range) => {
                            if (!dateRangeTo || !isValid(dateRangeTo)) return { ...range, to: undefined };

                            return {
                                from: !range?.from || isBefore(dateRangeTo, range.from) ? dateRangeTo : range.from,
                                to: dateRangeTo,
                            };
                        });
                    }}
                />
            </div>
            <DayPicker
                captionLayout='dropdown-buttons'
                fromYear={2020}
                toYear={new Date().getFullYear() + 2}
                locale={es}
                mode='range'
                selected={dateRange}
                defaultMonth={dateRange?.from ?? new Date()}
                onSelect={(range) => {
                    if (range === undefined) {
                        setDateRange({
                            from: dateRange?.from,
                            to: undefined,
                        });
                    } else if (dateRange && dateRange.to === undefined && isBefore(range.from, dateRange.from)) {
                        setDateRange({
                            from: range.from,
                            to: undefined,
                        });
                    } else if (dateRange && dateRange.from && dateRange.to) {
                        setDateRange({
                            from: dateRange.from !== range.from ? range.from : range.to,
                            to: undefined,
                        });
                    } else {
                        setDateRange(range);
                    }
                }}
                numberOfMonths={2}
            />
        </div>
    );
}

DateRangePicker.propTypes = {
    dateRange: PropTypes.object,
    setDateRange: PropTypes.func.isRequired,
    className: PropTypes.string,
};
