import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { formatCurrency } from '../../utils';
import HistoricoPreciosTooltip from './HistoricoPreciosTooltip';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '& .MuiSvgIcon-root': {
                fontSize: 20,
            },
        },
        sinHistorico: {
            paddingRight: 26,
        },
    }),
    { name: 'PreciosCell' },
);

export default function PreciosCell({ row, setData }) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, row.original.material_interno_id ? null : classes.sinHistorico)}>
            {formatCurrency(row.original.precio_unitario, 2, 4)}
            {row.original.material_interno_id && (
                <HistoricoPreciosTooltip
                    linea={row.original}
                    onLoad={(precios) => {
                        setData((lineas) => lineas.map((l) => (l.id === row.original.id ? { ...l, precios } : l)));
                    }}
                />
            )}
        </div>
    );
}

PreciosCell.propTypes = {
    row: PropTypes.object.isRequired,
    setData: PropTypes.func,
};
