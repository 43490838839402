import AssignmentIcon from '@material-ui/icons/Assignment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import SinPreparacionIcon from '../components/icons/SinPreparacion';
import DataProvider from './DataProvider';

export const empty = {
    lista_material: '',
    notas: '',
    fecha_creacion: null,
    fecha_actualizacion: null,
    estado: '',
    compras_material: null,
    solicitudes_material: null,
    type: '',
    fecha_limite: null,
    lineas: [],
};

export const estadoPreparacionLabels = {
    SIN_PREPARACION: 'Sin preparación',
    PENDIENTE: 'Pendiente',
    EN_PREPARACION: 'En preparación',
    PEDIDO_PROVEEDOR: 'Pedido proveedor',
    PREPARADO: 'Preparado',
};

export const estadoPreparacionColors = {
    PENDIENTE: 'error',
    EN_PREPARACION: 'warning',
    PEDIDO_PROVEEDOR: 'warning',
    PREPARADO: 'success',
};

export const estadoPreparacionIcons = {
    SIN_PREPARACION: SinPreparacionIcon,
    PENDIENTE: ScheduleIcon,
    EN_PREPARACION: TimelapseIcon,
    PEDIDO_PROVEEDOR: AssignmentIcon,
    PREPARADO: CheckCircleIcon,
};

export const estadoPreparacionOptions = Object.entries(estadoPreparacionLabels).map(([value, label]) => ({
    value,
    label,
}));

export const estadoCompraLabels = {
    PENDIENTE: 'Pendiente',
    PEDIDO: 'Pedido',
    RECIBIDO_INCOMPLETO: 'Recibido incompleto',
    RECIBIDO_COMPLETO: 'Recibido',
    VERIFICADO: 'Verificado',
    CANCELADO: 'Cancelado',
};

export const estadoCompraOptions = Object.entries(estadoCompraLabels).map(([value, label]) => ({
    value,
    label,
}));

class PreparacionesMaterialProvider extends DataProvider {
    constructor() {
        super('preparaciones_material', empty);
    }

    pendiente = async (id) => this.actionOnId(id, 'pendiente');
    iniciar = async (id) => this.actionOnId(id, 'iniciar');
    pedirProveedor = async (id) => this.actionOnId(id, 'pedido-proveedor');
    terminar = async (id) => this.actionOnId(id, 'terminar');

    updateListaMaterial = async (id, value) => this.save({ lista_material: value }, id);
    updateNotas = async (id, value) => this.save({ notas: value }, id);

    getByTarea = async (tareaId) => this.action(`by-tarea/${tareaId}`);
    addToTarea = async (tareaId, values) =>
        this.action(`by-tarea/${tareaId}`, { body: JSON.stringify(values), method: 'post' });

    getAllCompras = async () => this.getAll('compras');
    getCompraById = async (compraMaterialId) => this.action(`compras/${compraMaterialId}`);

    addCompra = async (preparacionMaterialId, values) =>
        this.action(`${preparacionMaterialId}/compras`, { body: JSON.stringify(values), method: 'post' });

    updateCompra = async (compraMaterialId, values) =>
        this.action(`compras/${compraMaterialId}`, { body: JSON.stringify(values), method: 'post' });

    deleteCompra = async (compraMaterialId) => this.action(`compras/${compraMaterialId}`, { method: 'delete' });

    updateNotasCompra = async (compraMaterialId, value) => this.updateCompra(compraMaterialId, { notas: value });

    getAllSolicitudes = async () => this.getAll('solicitudes');

    addSolicitudToPreparacion = async (solicitudId) =>
        this.action(`solicitudes/${solicitudId}/add-to-preparacion`, {
            method: 'post',
        });

    procesarSolicitud = async (solicitudId) => this.action(`solicitudes/${solicitudId}/procesar`, { method: 'post' });
    borrarSolicitud = async (solicitudId) => this.action(`solicitudes/${solicitudId}`, { method: 'delete' });

    updateLinea = async (preparacionMaterialId, lineaId, material) => {
        let url = `${preparacionMaterialId}/lineas`;
        if (lineaId != null) url += `/${lineaId}`;

        return this.getAll(url, {
            body: JSON.stringify(material),
            method: 'post',
        });
    };

    deleteLinea = async (preparacionMaterialId, lineaId) => {
        return this.getAll(`${preparacionMaterialId}/lineas/${lineaId}`, { method: 'delete' });
    };

    setLineasPreparadas = async (preparacionMaterialId, lineaIds) => {
        return this.getAll(`${preparacionMaterialId}/lineas/preparadas`, {
            body: JSON.stringify({ ids: lineaIds }),
            method: 'post',
        });
    };

    toAlbaranCompra = async (preparacionMaterialId, lineaIds, albaranInfo) => {
        return this.getAll(`${preparacionMaterialId}/lineas/to-albaran-compra`, {
            method: 'post',
            body: JSON.stringify({ ids: lineaIds, ...albaranInfo }),
        });
    };
}

export const dataProvider = new PreparacionesMaterialProvider();
