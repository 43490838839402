import MuiTextField from '@material-ui/core/TextField';
import debounce from '@material-ui/core/utils/debounce';
import isValid from 'date-fns/isValid';
import { Field, useField } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { vehiculosProvider } from '../../../api';
import PlanificacionFormItemSelectOption from '../../tareas/PlanificacionFormItemSelectOption';

export default function VehiculoField({
    error,
    helperText,
    disabled,
    vehiculoKey = 'vehiculo',
    fechaInicio,
    fechaFin,
    operarios,
    planificacionId,
    className,
}) {
    const [vehiculosOptions, setVehiculosOptions] = useState([]);
    const [{ value }, , { setValue }] = useField(vehiculoKey);

    const updateVehiculosOptions = useCallback(
        debounce((fechaInicio, fechaFin, operarios) => {
            vehiculosProvider
                .getAsOptionsForPlanificacion(
                    fechaInicio,
                    fechaFin,
                    operarios.map((o) => o.operario.id),
                    planificacionId,
                )
                .then(setVehiculosOptions);
        }, 1000),
        [],
    );

    useEffect(() => {
        if (!fechaInicio || !fechaFin || !isValid(fechaInicio) || !isValid(fechaFin)) {
            setVehiculosOptions((options) => (options.length > 0 ? [] : options));
            return;
        }
        updateVehiculosOptions(fechaInicio, fechaFin, operarios);
    }, [fechaInicio, fechaFin, operarios]);

    useEffect(() => {
        if (vehiculosOptions.length === 0) return;

        setValue(value ? vehiculosOptions.find((d) => d.id === value.id) : null);
    }, [value, vehiculosOptions]);

    return (
        <Field
            name={vehiculoKey}
            className={className}
            fullWidth
            component={Autocomplete}
            options={vehiculosOptions}
            getOptionSelected={(option, value) => option.id === value?.id}
            getOptionLabel={(option) => option.nombre ?? ''}
            disabled={disabled}
            renderInput={(params) => (
                <MuiTextField
                    {...params}
                    error={Boolean(error)}
                    helperText={helperText}
                    label='Vehículo'
                    placeholder='Sin vehículo asignado'
                    InputLabelProps={{ shrink: true }}
                />
            )}
            renderOption={(option) => (
                <PlanificacionFormItemSelectOption label={option.nombre} planificaciones={option.planificaciones} />
            )}
        />
    );
}

VehiculoField.propTypes = {
    disabled: PropTypes.any,
    error: PropTypes.any,
    fechaFin: PropTypes.any,
    fechaInicio: PropTypes.any,
    helperText: PropTypes.any,
    operarios: PropTypes.any,
    planificacionId: PropTypes.any,
    vehiculoKey: PropTypes.string,
    className: PropTypes.string,
};
