import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        label: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                color: theme.palette.neutral.primary,
                paddingLeft: theme.spacing(0.5),
            },
        },
    }),
    { name: 'ClienteInfoTooltip' },
);

export default function DireccionInfoTooltip({ direccion, label }) {
    const classes = useStyles();

    return (
        <Tooltip arrow title={direccion}>
            <Typography variant='subtitle1' className={classes.label}>
                {label} <InfoIcon />
            </Typography>
        </Tooltip>
    );
}

DireccionInfoTooltip.propTypes = {
    direccion: PropTypes.any,
    label: PropTypes.any,
};
