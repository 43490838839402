import withStyles from '@material-ui/core/styles/withStyles';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';

export const TableCell = withStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(0.5, 2),
            borderBottom: '1px solid #eee',
            whiteSpace: 'nowrap',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
            '.MuiTableRow-head:nth-of-type(2) &.MuiTableCell-head.MuiTableCell-stickyHeader': {
                top: 33,
            },
            '&.fixed': {
                backgroundColor: theme.palette.neutral.hoverCell,
                minWidth: 106,
                width: 106,
                maxWidth: 106,
            },
            '&.marcaje': {
                width: 172,
                minWidth: 172,
                maxWidth: 172,
            },
            '&.fill': {
                width: '100%',
            },
            '&.error': {
                color: theme.palette.error.main,
            },
            '& div.error': {
                display: 'flex',
                alignItems: 'center',
                gap: `${theme.spacing(0.5)}px`,
                color: theme.palette.error.main,
            },
            '& div.error .MuiSvgIcon-root': {
                fontSize: 20,
            },
            '& .touched .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.warning.main,
            },
            '& .touched .MuiOutlinedInput-root': {
                backgroundColor: theme.palette.warning.backgroundTint,
            },
            '&:nth-last-child(2)': {
                width: 190,
                minWidth: 190,
                maxWidth: 190,
            },
            '&:last-child': {
                width: 96,
                minWidth: 96,
                maxWidth: 96,
            },
            '.MuiTableRow-root:not(.empty):hover &': {
                backgroundColor: theme.palette.neutral.grey3,
            },
        },
        footer: {
            color: theme.palette.text.primary,
            fontWeight: 500,
        },
        sizeSmall: {
            '&:last-child': {
                padding: 0,
            },
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

export const TableRow = withStyles(
    (theme) => ({
        root: {
            '&:not(.empty):hover': {
                backgroundColor: theme.palette.neutral.grey3,
            },
        },
    }),
    { name: 'TableRow' },
)(MuiTableRow);
