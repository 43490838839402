import MenuItem from '@material-ui/core/MenuItem';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { preparacionesMaterialProvider } from '../../../api';
import { ButtonDialog } from '../../common/dialogs/ButtonDialog';
import DeleteDialog from '../../common/dialogs/DeleteDialog';
import MasterItemOptions from '../../common/forms/MasterDetailsView/MasterItemOptions';
import FechaEditor from './FechaEditor';

export default function PreparacionMaterialItemOptions({ item, fetchData, onDelete }) {
    const snackbar = useSnackbar();
    return (
        <MasterItemOptions>
            {({ closeMenu }) => {
                const options = [];

                if (item.estado !== 'PENDIENTE')
                    options.push(
                        <ButtonDialog
                            key={0}
                            button={<MenuItem onClick={closeMenu}>Pendiente</MenuItem>}
                            dialogTitle='Cambiar estado de la preparación de material'
                            dialogText='¿Quieres dejar la preparación como pendiente?'
                            onConfirm={() =>
                                preparacionesMaterialProvider
                                    .pendiente(item.id)
                                    .then(fetchData)
                                    .catch((err) => snackbar.showMessage(err.message))
                            }
                        />,
                    );
                if (item.estado !== 'EN_PREPARACION')
                    options.push(
                        <ButtonDialog
                            key={1}
                            button={<MenuItem onClick={closeMenu}>En preparación</MenuItem>}
                            dialogTitle='Cambiar estado de la preparación de material'
                            dialogText='¿Quieres empezar a preparar el material para este servicio?'
                            onConfirm={() =>
                                preparacionesMaterialProvider
                                    .iniciar(item.id)
                                    .then(fetchData)
                                    .catch((err) => snackbar.showMessage(err.message))
                            }
                        />,
                    );
                if (item.estado !== 'PEDIDO_PROVEEDOR')
                    options.push(
                        <ButtonDialog
                            key={2}
                            button={<MenuItem onClick={closeMenu}>Pedido a proveedor</MenuItem>}
                            dialogTitle='Cambiar estado de la preparación de material'
                            dialogText='¿Quieres dejar la preparación como pedida a proveedor?'
                            onConfirm={() =>
                                preparacionesMaterialProvider
                                    .pedirProveedor(item.id)
                                    .then(fetchData)
                                    .catch((err) => snackbar.showMessage(err.message))
                            }
                        />,
                    );
                if (item.estado !== 'PREPARADO')
                    options.push(
                        <ButtonDialog
                            key={3}
                            button={<MenuItem onClick={closeMenu}>Preparado</MenuItem>}
                            dialogTitle='Cambiar estado de la preparación de material'
                            dialogText='¿Has terminado de preparar el material de este servicio?'
                            onConfirm={() =>
                                preparacionesMaterialProvider
                                    .terminar(item.id)
                                    .then(fetchData)
                                    .catch((err) => snackbar.showMessage(err.message))
                            }
                        />,
                    );

                options.push(
                    <FechaEditor
                        key={4}
                        preparacionMaterial={item}
                        onSave={fetchData}
                        button={<MenuItem onClick={closeMenu}>Editar fecha limite</MenuItem>}
                    />,
                    <DeleteDialog
                        key={5}
                        text='¿Estás seguro que quieres eliminar esta preparación de material?'
                        onConfirm={(ev) => {
                            closeMenu(ev);
                            onDelete();
                        }}
                        button={<MenuItem onClick={closeMenu}>Eliminar</MenuItem>}
                        confirmationText='borrar preparacion'
                    />,
                );
                return options;
            }}
        </MasterItemOptions>
    );
}

PreparacionMaterialItemOptions.propTypes = {
    item: PropTypes.object.isRequired,
    fetchData: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};
