import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ReplayIcon from '@material-ui/icons/Replay';
import { useSnackbar } from 'material-ui-snackbar-provider';
import React, { useState } from 'react';
import { companiesProvider } from '../../api';
import useAuthState from '../../AuthState';
import Button from '../common/Button';
import { Tab, Tabs } from '../common/Tabs';
import NuevaTareaDialog from '../tareas/NuevaTareaDialog';
import AllTareasList from './AllTareasList';
import DashboardAdmin from './DashboardAdmin';
import TareasSinPlanificar from './TareasSinPlanificar';

const useStyles = makeStyles(
    (theme) => ({
        header: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
        },
        tabsRoot: {
            flexShrink: 0,
            margin: 0,
            flex: 1,
        },
        tabsContainer: {
            gap: `${theme.spacing(3)}px`,
        },
    }),
    { name: 'Dashboard' },
);

const TODOS = 'todos';
const SIN_PLANIFICAR = 'sin_planificar';

export default function Dashboard() {
    const classes = useStyles();
    const { username, isCompany } = useAuthState();
    const snackbar = useSnackbar();
    const [resetting, setResetting] = useState(false);
    const [viewMode, setViewMode] = useState(TODOS);

    const allTareasRef = React.createRef();
    const tareasSinPlanificarRef = React.createRef();

    if (!isCompany) return <DashboardAdmin />;

    return (
        <>
            <div className={classes.header}>
                <Typography variant='h2' style={{ flex: 1 }}>
                    Servicios
                </Typography>

                <Tabs
                    disableGutters
                    value={viewMode}
                    onChange={(ev, newValue) => setViewMode(newValue)}
                    aria-label='Vista'
                    classes={{
                        root: classes.tabsRoot,
                        flexContainer: classes.tabsContainer,
                    }}
                >
                    <Tab label='Todos los servicios' value={TODOS} />
                    <Tab label='Pendientes de planificar' value={SIN_PLANIFICAR} />
                </Tabs>
                {username === 'laborydemo' && (
                    <Button
                        color='normal'
                        aria-label='Reiniciar demo'
                        style={{ marginRight: 16 }}
                        onClick={() => {
                            setResetting(true);
                            companiesProvider
                                .getAll('reset_demo', { method: 'post' })
                                .then(() => {
                                    setResetting(false);
                                    snackbar.showMessage('Se ha reiniciado la base de datos con éxito.');
                                })
                                .catch(() => {
                                    setResetting(false);
                                    snackbar.showMessage('No se ha podido reiniciar la base de datos.');
                                });
                        }}
                        disabled={resetting}
                    >
                        {resetting ? (
                            <CircularProgress style={{ color: 'inherit', marginRight: 8 }} size={24} />
                        ) : (
                            <ReplayIcon style={{ marginRight: 8 }} />
                        )}
                        Reiniciar demo
                    </Button>
                )}
                <NuevaTareaDialog
                    button={
                        <Button color='info' aria-label='Añadir servicio'>
                            <AddIcon style={{ marginRight: 8 }} />
                            Añadir servicio
                        </Button>
                    }
                    onSave={() => {
                        allTareasRef.current && allTareasRef.current.refresh();
                        tareasSinPlanificarRef.current && tareasSinPlanificarRef.current.refresh();
                    }}
                />
            </div>

            {viewMode === TODOS && <AllTareasList ref={allTareasRef} />}
            {viewMode === SIN_PLANIFICAR && <TareasSinPlanificar ref={tareasSinPlanificarRef} />}
        </>
    );
}
