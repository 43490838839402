import ButtonMenu from './ButtonMenu';
import { ListItem } from './AppBarMenu';
import PropTypes from 'prop-types';

export default function CalendarioMenu({ disabled }) {
    return (
        <ButtonMenu
            button={<ListItem label='Calendarios' disabled={disabled} />}
            items={[
                {
                    path: '/calendario',
                    label: 'Calendario general',
                },
                {
                    path: '/calendario-planificacion',
                    label: 'Calendario de planificación',
                },
            ]}
        />
    );
}

CalendarioMenu.propTypes = {
    disabled: PropTypes.any,
};
