import { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Field, useField, useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import { Autocomplete } from 'formik-material-ui-lab';
import SearchIcon from '@material-ui/icons/Search';
import MuiTextField from '@material-ui/core/TextField';
import { TextField } from 'formik-material-ui';
import { materialesProvider } from '../../../../api';
import DialogForm from '../../../common/forms/DialogForm';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 650,
        },
        searchIcon: {
            fontSize: 20,
            color: theme.palette.neutral.primary,
        },
        fields: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        field: {
            flex: 1,
        },
    }),
    { name: 'LineaEditor' },
);

function LineaEditorContent({ open, linea }) {
    const classes = useStyles();
    const [materialesOptions, setMaterialesOptions] = useState([]);

    const isAlbaranCompra =
        linea && linea.albaran_compra_linea_id !== null && linea.albaran_compra_linea_id !== undefined;

    const { setFieldValue } = useFormikContext();
    const [{ value: materialInternoValue }, , { setValue: setMaterialInternoValue }] = useField('material_interno');

    useEffect(() => {
        if (!open || isAlbaranCompra || materialesOptions.length > 0) return;

        materialesProvider.getAsOptions().then(setMaterialesOptions);
    }, [open]);

    useEffect(() => {
        if (materialesOptions.length === 0) return;

        setMaterialInternoValue(
            materialInternoValue ? materialesOptions.find((d) => d.id === materialInternoValue.id) : null,
        );
    }, [materialInternoValue, materialesOptions]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Field
                    name='material_interno'
                    fullWidth
                    component={Autocomplete}
                    options={materialesOptions}
                    getOptionSelected={(option, value) => option.id === value?.id}
                    getOptionLabel={(option) => option?.descripcion ?? ''}
                    renderInput={({ InputProps, ...params }) => {
                        return (
                            <MuiTextField
                                fullWidth
                                InputProps={{
                                    ...InputProps,
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <SearchIcon className={classes.searchIcon} />
                                        </InputAdornment>
                                    ),
                                }}
                                placeholder='Buscar materiales por referencia o descripción'
                                {...params}
                            />
                        );
                    }}
                    onChange={(ev, newValue) => {
                        setFieldValue('material_interno', newValue);
                        if (newValue) {
                            setFieldValue('descripcion', newValue.descripcion, false);
                        }
                    }}
                    disabled={isAlbaranCompra}
                />
            </Grid>
            <Grid item xs={12} className={classes.fields}>
                <TextField
                    name='descripcion'
                    label='Descripción'
                    className={classes.field}
                    disabled={isAlbaranCompra}
                />
            </Grid>
            <Grid item xs={12} className={classes.fields}>
                <TextField
                    name='unidades'
                    label='Uds. a preparar'
                    type='number'
                    step='.01'
                    disabled={isAlbaranCompra}
                    fullWidth
                />
                <TextField
                    name='unidades_pedidas_pendientes'
                    label='Uds. ptes. albarán'
                    type='number'
                    step='.01'
                    fullWidth
                    disabled
                />
                <TextField
                    name='unidades_preparadas'
                    label='Uds. preparadas'
                    type='number'
                    step='.01'
                    fullWidth
                    disabled={isAlbaranCompra}
                />
            </Grid>
        </Grid>
    );
}
const MaterialSchema = Yup.object().shape({
    descripcion: Yup.string().required('Requerido'),
    unidades: Yup.number().typeError('Numérico'),
    unidades_maximas_a_preparar: Yup.number().typeError('Numérico'),
    unidades_preparadas: Yup.number()
        .typeError('Numérico')
        .max(Yup.ref('unidades_maximas_a_preparar'), 'No hay tantas unidades a preparar'),
});

export default function LineaEditor({ linea, onSave, ...props }) {
    return (
        <DialogForm
            title='Editar material'
            FormikProps={{
                initialValues: {
                    descripcion: linea?.descripcion ?? '',
                    unidades: linea?.unidades ?? 0,
                    unidades_preparadas: linea?.unidades_preparadas ?? 0,
                    unidades_pedidas_pendientes: linea?.unidades_pedidas_pendientes ?? 0,
                    unidades_maximas_a_preparar: (linea?.unidades ?? 0) - (linea?.unidades_pedidas_pendientes ?? 0),
                    material_interno: linea && linea.material_interno_id ? { id: linea.material_interno_id } : null,
                },
                validationSchema: MaterialSchema,
                onSubmit: ({ material_interno: materialInterno, ...values }, { setSubmitting, setFieldError }) => {
                    onSave({ ...values, material_interno_id: materialInterno ? materialInterno.id : null }).catch(
                        (err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        },
                    );
                },
            }}
            {...props}
        >
            {() => <LineaEditorContent open={props.open} linea={linea} />}
        </DialogForm>
    );
}

LineaEditor.propTypes = {
    linea: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};

LineaEditorContent.propTypes = {
    linea: PropTypes.any,
    open: PropTypes.any,
};
