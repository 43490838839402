import DataProvider from './DataProvider';

export const empty = {
    nombre: '',
    descripcion: '',
};

class DocumentosProvider extends DataProvider {
    constructor() {
        super('documentos', empty);
    }

    saveCarpeta = async (values, id) => {
        let url = 'carpetas/';
        if (id) url += id;

        return this.action(url, { method: 'post', body: values instanceof FormData ? values : JSON.stringify(values) });
    };

    saveFichero = async (values, id) => {
        let url = 'ficheros/';
        if (id) url += id;

        return this.action(url, { method: 'post', body: values instanceof FormData ? values : JSON.stringify(values) });
    };
}

export const dataProvider = new DocumentosProvider();
