import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 32,
            height: 24,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }),
    { name: 'ColorSwatch' },
);
export default function ColorSwatch({ color, selected = false, ...props }) {
    const classes = useStyles();

    return (
        <div className={classes.root} style={{ background: color.color_bg, color: color.color_fg }} {...props}>
            {selected ? <DoneIcon style={{ color: 'inherit' }} /> : <Typography variant='body2'>Aa</Typography>}
        </div>
    );
}

ColorSwatch.propTypes = {
    selected: PropTypes.bool,
    color: PropTypes.any,
};
