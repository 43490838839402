import ButtonMenu from './ButtonMenu';
import { ListItem } from './AppBarMenu';
import PropTypes from 'prop-types';

export default function MantenimientoMenu({ disabled }) {
    return (
        <ButtonMenu
            button={<ListItem label='Mantenimientos' disabled={disabled} />}
            items={[
                {
                    path: '/contratos-mantenimiento/calendario',
                    label: 'Calendario',
                },
                {
                    path: '/instalaciones',
                    label: 'Instalaciones',
                },
                {
                    path: '/contratos-mantenimiento',
                    label: 'Contratos de mantenimiento',
                },
                {
                    path: '/equipos',
                    label: 'Equipos',
                },
                {
                    path: '/cuestionarios',
                    label: 'Cuestionarios',
                },
            ]}
        />
    );
}

MantenimientoMenu.propTypes = {
    disabled: PropTypes.any,
};
