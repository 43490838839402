import SvgIcon from '@material-ui/core/SvgIcon';

export default function CalendarioWeekIcon(props) {
    return (
        <SvgIcon viewBox='0 0 24 24' {...props}>
            <path fillRule='evenodd' clipRule='evenodd' d='M8.83437 3.25195V6.53539H7.23438V3.25195H8.83437Z' />
            <path fillRule='evenodd' clipRule='evenodd' d='M16.8656 3.25195V6.53539H15.2656V3.25195H16.8656Z' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.29922 7.74824C5.29922 7.0855 5.83648 6.54824 6.49922 6.54824H17.4992C18.162 6.54824 18.6992 7.0855 18.6992 7.74824V9.44516H5.29922V7.74824ZM5.29922 11.0452H18.6992V18.7482C18.6992 19.411 18.162 19.9482 17.4992 19.9482H6.49922C5.83648 19.9482 5.29922 19.411 5.29922 18.7482V11.0452ZM6.49922 4.94824C4.95283 4.94824 3.69922 6.20184 3.69922 7.74824V18.7482C3.69922 20.2946 4.95282 21.5482 6.49922 21.5482H17.4992C19.0456 21.5482 20.2992 20.2946 20.2992 18.7482V7.74824C20.2992 6.20184 19.0456 4.94824 17.4992 4.94824H6.49922ZM6.89921 12.3194V18.5099H8.49921V12.3194H6.89921ZM11.1992 18.5099V12.3194H12.7992V18.5099H11.1992ZM15.4992 12.3194V18.5099H17.0992V12.3194H15.4992Z'
            />
        </SvgIcon>
    );
}
