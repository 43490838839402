import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';

const EstadoChip = withStyles(
    (theme) => ({
        root: {
            width: 112,
            color: 'white',
            borderRadius: 4,
            textTransform: 'uppercase',
            '&.PENDIENTE': {
                backgroundColor: theme.palette.neutral.primary,
            },
            '&.APROBADA': {
                backgroundColor: theme.palette.success.main,
            },
            '&.RECHAZADA': {
                backgroundColor: theme.palette.error.main,
            },
        },
    }),
    { name: 'EstadoChip' },
)(Chip);

export default EstadoChip;
