import { useCallback, useEffect, useMemo } from 'react';
import BaseListView, { withTableState } from '../common/list/ListView';
import { dataProvider } from '../../api/instalaciones';
import { useHistory } from 'react-router-dom';
import { useFetchData } from '../../hooks/useFetchData';
import { useSnackbar } from 'material-ui-snackbar-provider';
import Button from '../common/Button';
import AddIcon from '@material-ui/icons/Add';
import NewInstalacionDialog from './NewInstalacionDialog';
import ConfigurarHorquillaDialog from '../equipos/ConfigurarHorquillaDialog';
import { FilledIconButton } from '../fichajes/FilledIconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import useAuthState from '../../AuthState';

const ListView = withTableState('instalaciones', BaseListView);

export default function InstalacionesList() {
    const history = useHistory();
    const fetchDataFn = useCallback(() => dataProvider.getAll(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    const {
        userInfo: { preferencias: { dias_margen_revision: diasMargenRevision } = {} },
    } = useAuthState();

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        dataProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
            {
                Header: 'Cliente',
                accessor: 'cliente',
            },
            {
                Header: 'Direccion',
                accessor: 'direccion',
            },
            {
                Header: 'Número de servicios',
                accessor: 'num_tareas',
            },
            {
                Header: 'Número de equipos',
                accessor: 'num_equipos',
            },
        ],
        [diasMargenRevision],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            deleteConfirmationText: 'borrar instalacion',
        }),
        [],
    );

    return (
        <>
            {/* <Alert severity='info' variant='filled' style={{ marginBottom: 24 }}> */}
            {/*    Esta funcionalidad esta en fase beta. Puede ser que haya cambios importantes. ¿Dudas o sugerencias? */}
            {/*    Envianos un email a hola@labory.es */}
            {/* </Alert> */}
            <ListView
                title='Instalaciones'
                tableTitle='Lista de instalaciones'
                titleActions={
                    <>
                        <NewInstalacionDialog
                            button={
                                <Button color='info' aria-label='Nuevo instalación' startIcon={<AddIcon />}>
                                    Nueva instalación
                                </Button>
                            }
                            onSave={({ id }) => history.push(`instalaciones/${id}`)}
                        />
                        <ConfigurarHorquillaDialog
                            button={
                                <FilledIconButton>
                                    <SettingsIcon />
                                </FilledIconButton>
                            }
                            // onSave={setDiasMargenRevision}
                        />
                    </>
                }
                basePath='/instalaciones'
                columns={columns}
                data={data}
                options={options}
                onDelete={onDelete}
            />
        </>
    );
}
