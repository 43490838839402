import ButtonMenu from './ButtonMenu';
import { ListItem } from './AppBarMenu';
import PropTypes from 'prop-types';
import useAuthState from '../../AuthState';

export default function ProyectosMenu({ disabled }) {
    const {
        userInfo: { preferencias: { usar_presupuestos: usarPresupuestos } = {} },
    } = useAuthState();

    const items = [
        {
            path: '/proyectos',
            label: 'Proyectos',
        },
    ];
    if (usarPresupuestos) {
        items.push({
            path: '/presupuestos',
            label: 'Presupuestos',
        });
    }

    return <ButtonMenu button={<ListItem label='Proyectos' disabled={disabled} />} items={items} />;
}

ProyectosMenu.propTypes = {
    disabled: PropTypes.any,
};
