import { gastosLabels } from '../../../api/tareas';
import { createTiempo, getHoras, getMinutos } from '../../../api/tareas-functions';
import { addTiempo } from '../../../utils';

export default function useResumenJornadas(jornadas) {
    const tiempoOperarios = {};
    const tiempoCategorias = {};
    const materialesFecha = {};
    const materiales = [];
    const gastos = Object.fromEntries(
        Object.keys(gastosLabels).map((key) => [
            key,
            {
                unidades: 0,
                precioCoste: null,
                precioVenta: null,
                descuento: null,
                importeCoste: null,
                importeVenta: null,
                unidadesList: [],
                descuentosList: [],
                preciosCosteList: [],
                preciosVentaList: [],
            },
        ]),
    );

    let totalMaterialCoste = 0;
    let totalMaterialVenta = 0;

    jornadas.forEach((jornada) => {
        materiales.push(...jornada.materiales.map((material) => ({ ...material, jornada_id: jornada.id })));

        totalMaterialVenta += jornada.materiales.reduce((total, material) => total + material.importe, 0);
        totalMaterialCoste += jornada.materiales.reduce((total, material) => total + material.importe_coste, 0);

        if (!materialesFecha[jornada.fecha]) {
            materialesFecha[jornada.fecha] = {
                importeCoste: 0,
                importeVenta: 0,
                materiales: [],
            };
        }

        jornada.materiales.forEach((material) => {
            materialesFecha[jornada.fecha].importeCoste += material.importe_coste;
            materialesFecha[jornada.fecha].importeVenta += material.importe;
            materialesFecha[jornada.fecha].materiales.push({ ...material, jornada_id: jornada.id });
        });

        if (!jornada.operario) return;

        if (!tiempoOperarios[jornada.operario]) {
            tiempoOperarios[jornada.operario] = {
                tiempo: null,
                unidades: 0,
                precioCoste: null,
                precioVenta: null,
                descuento: null,
                importeCoste: null,
                importeVenta: null,
                unidadesList: [],
                descuentosList: [],
                preciosCosteList: [],
                preciosVentaList: [],
            };
        }

        const categoriaId = jornada.categoria_operario ?? 'Sin categoria';

        if (!tiempoCategorias[categoriaId]) {
            tiempoCategorias[categoriaId] = {
                tiempo: null,
                unidades: 0,
                precioCoste: null,
                precioVenta: null,
                descuento: null,
                importeCoste: null,
                importeVenta: null,
                unidadesList: [],
                descuentosList: [],
                preciosCosteList: [],
                preciosVentaList: [],
            };
        }

        const unidades = getMinutos(jornada.tiempo);
        tiempoOperarios[jornada.operario].tiempo = addTiempo(tiempoOperarios[jornada.operario].tiempo, jornada.tiempo);
        tiempoOperarios[jornada.operario].unidades += unidades;
        tiempoOperarios[jornada.operario].unidadesList.push(unidades);
        tiempoOperarios[jornada.operario].descuentosList.push(jornada.descuento);
        tiempoOperarios[jornada.operario].preciosCosteList.push(jornada.precio_unitario_coste);
        tiempoOperarios[jornada.operario].preciosVentaList.push(jornada.precio_unitario);

        tiempoCategorias[categoriaId].tiempo = addTiempo(tiempoCategorias[categoriaId].tiempo, jornada.tiempo);
        tiempoCategorias[categoriaId].unidades += unidades;
        tiempoCategorias[categoriaId].unidadesList.push(unidades);
        tiempoCategorias[categoriaId].descuentosList.push(jornada.descuento);
        tiempoCategorias[categoriaId].preciosCosteList.push(jornada.precio_unitario_coste);
        tiempoCategorias[categoriaId].preciosVentaList.push(jornada.precio_unitario);

        Object.keys(gastosLabels).forEach((key) => {
            if (!jornada.gastos) return;

            if (jornada.gastos[key]) {
                const unidades = parseFloat(jornada.gastos[key]);
                gastos[key].unidades += unidades;
                gastos[key].unidadesList.push(unidades);
                gastos[key].descuentosList.push(jornada.gastos[`descuento_${key}`]);
                gastos[key].preciosCosteList.push(jornada.gastos[`precio_coste_${key}`]);
                gastos[key].preciosVentaList.push(jornada.gastos[`precio_venta_${key}`]);
            }
        });
    });

    Object.values(tiempoOperarios).forEach((tiempoOperario) => {
        tiempoOperario.precioCoste = tiempoOperario.unidadesList.reduce(
            (total, unidades, idx) =>
                total +
                (tiempoOperario.unidades > 0
                    ? (unidades * tiempoOperario.preciosCosteList[idx]) / tiempoOperario.unidades
                    : 0),
            0,
        );
        tiempoOperario.precioVenta = tiempoOperario.unidadesList.reduce(
            (total, unidades, idx) =>
                total +
                (tiempoOperario.unidades > 0
                    ? (unidades * tiempoOperario.preciosVentaList[idx]) / tiempoOperario.unidades
                    : 0),
            0,
        );

        tiempoOperario.descuento = tiempoOperario.unidadesList.reduce(
            (total, unidades, idx) =>
                total +
                (tiempoOperario.unidades > 0
                    ? (unidades * tiempoOperario.descuentosList[idx]) / tiempoOperario.unidades
                    : 0),
            0,
        );

        tiempoOperario.importeCoste = tiempoOperario.precioCoste * getHoras(createTiempo(tiempoOperario.unidades));

        tiempoOperario.importeVenta =
            (tiempoOperario.precioVenta *
                getHoras(createTiempo(tiempoOperario.unidades)) *
                (100 - tiempoOperario.descuento)) /
            100;
    });

    Object.values(tiempoCategorias).forEach((tiempoCategoria) => {
        tiempoCategoria.precioCoste = tiempoCategoria.unidadesList.reduce(
            (total, unidades, idx) =>
                total +
                (tiempoCategoria.unidades > 0
                    ? (unidades * tiempoCategoria.preciosCosteList[idx]) / tiempoCategoria.unidades
                    : 0),
            0,
        );
        tiempoCategoria.precioVenta = tiempoCategoria.unidadesList.reduce(
            (total, unidades, idx) =>
                total +
                (tiempoCategoria.unidades > 0
                    ? (unidades * tiempoCategoria.preciosVentaList[idx]) / tiempoCategoria.unidades
                    : 0),
            0,
        );

        tiempoCategoria.descuento = tiempoCategoria.unidadesList.reduce(
            (total, unidades, idx) =>
                total +
                (tiempoCategoria.unidades > 0
                    ? (unidades * tiempoCategoria.descuentosList[idx]) / tiempoCategoria.unidades
                    : 0),
            0,
        );

        tiempoCategoria.importeCoste = tiempoCategoria.precioCoste * getHoras(createTiempo(tiempoCategoria.unidades));

        tiempoCategoria.importeVenta =
            (tiempoCategoria.precioVenta *
                getHoras(createTiempo(tiempoCategoria.unidades)) *
                (100 - tiempoCategoria.descuento)) /
            100;
    });

    Object.values(gastos).forEach((gasto) => {
        gasto.precioCoste =
            gasto.unidades === 0
                ? 0
                : gasto.unidadesList.reduce(
                      (total, unidades, idx) => total + (unidades * gasto.preciosCosteList[idx]) / gasto.unidades,
                      0,
                  );

        gasto.precioVenta =
            gasto.unidades === 0
                ? 0
                : gasto.unidadesList.reduce(
                      (total, unidades, idx) => total + (unidades * gasto.preciosVentaList[idx]) / gasto.unidades,
                      0,
                  );

        gasto.descuento =
            gasto.unidades === 0
                ? 0
                : gasto.unidadesList.reduce(
                      (total, unidades, idx) => total + (unidades * gasto.descuentosList[idx]) / gasto.unidades,
                      0,
                  );

        gasto.importeCoste += gasto.precioCoste * gasto.unidades;
        gasto.importeVenta += (gasto.precioVenta * gasto.unidades * (100 - gasto.descuento)) / 100;
    });

    return {
        tiempoOperarios,
        tiempoCategorias,
        materiales,
        gastos,
        totalMaterialVenta,
        totalMaterialCoste,
        materialesFecha,
    };
}
