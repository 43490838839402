import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import FichaTecnicaEquipo from './FichaTecnicaEquipo';
import { cuestionariosProvider } from '../../../api';
import CuestionarioDetails from './CuestionarioDetails';

export default function ParteMantenimientoDetails({ index }) {
    const { data: parteMantenimiento, updateLocalData } = useEditFormData(`partes_mantenimiento.${index}`);

    useEffect(() => {
        if (
            parteMantenimiento.cuestionarios.length === 0 ||
            parteMantenimiento.cuestionarios.every((c) => c.secciones !== undefined)
        )
            return;

        const cuestionarioIds = parteMantenimiento.cuestionarios.map((c) => c.id);
        const promises = cuestionarioIds.map((id) => cuestionariosProvider.getAll(id));
        Promise.all(promises).then((cuestionarios) => updateLocalData('cuestionarios', cuestionarios));
    }, [parteMantenimiento]);

    const equipo = parteMantenimiento.equipo;

    const respuestas = useMemo(() => {
        return Object.fromEntries(
            parteMantenimiento.respuestas.map((respuesta) => [
                respuesta.pregunta_id,
                { valor: respuesta.valor, notas: respuesta.notas },
            ]),
        );
    }, [parteMantenimiento.respuestas]);

    return (
        <>
            <FichaTecnicaEquipo equipo={equipo} notas={parteMantenimiento.notas} />
            {parteMantenimiento.cuestionarios.map((cuestionario) => (
                <CuestionarioDetails key={cuestionario.id} cuestionario={cuestionario} respuestas={respuestas} />
            ))}
        </>
    );
}

ParteMantenimientoDetails.propTypes = {
    index: PropTypes.any.isRequired,
};
