import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DoneIcon from '@material-ui/icons/Done';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useCallback, useEffect, useMemo } from 'react';
import { materialesProvider } from '../../api';
import { useFetchData } from '../../hooks/useFetchData';
import { SwitchWithLabel } from '../common/fields/Switch';
import BaseListView, { withTableState } from '../common/list/ListView';
import UploadFileDialog from './UploadFileDialog';

const ListView = withTableState('materiales', BaseListView);

export default function MaterialesList() {
    const fetchDataFn = useCallback(() => materialesProvider.getAll(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        materialesProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Referencia',
                accessor: 'referencia',
            },
            {
                Header: 'Descripcion',
                accessor: 'descripcion',
            },
            {
                Header: 'Marca',
                accessor: 'marca.nombre',
            },
            {
                Header: 'Precio unitario venta',
                accessor: 'precio_unitario',
            },
            {
                Header: 'Descuento venta',
                accessor: 'descuento',
            },
            {
                Header: 'Precio unitario coste',
                accessor: 'precio_unitario_coste',
            },
            {
                Header: 'Descuento coste',
                accessor: 'descuento_coste',
            },
            {
                Header: 'Activo',
                accessor: 'activo',
                // eslint-disable-next-line react/prop-types
                Cell: ({ value }) => (value ? <DoneIcon /> : null),
                filter: 'equals',
                disableGlobalFilter: true,
                disableFilters: true,
                disableSortBy: true,
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            canToggleColumns: false,
            canExportCsv: true,
            tableOptions: {
                extraFilters: (setFilter, tableColumns) => {
                    const column = tableColumns.find((c) => c.id === 'activo');
                    const showingAll = column.filterValue === undefined;

                    return (
                        <SwitchWithLabel
                            label='Mostrar solo los materiales activos'
                            field={{
                                name: 'solo-materiales-activos',
                                onChange: () => column.setFilter(showingAll ? true : undefined),
                                value: !showingAll,
                            }}
                        />
                    );
                },
                initialState: {
                    filters: [{ id: 'activo', value: true }],
                },
            },
            batchComponents: () => (
                <UploadFileDialog
                    button={
                        <Tooltip title='Importar materiales de Excel'>
                            <IconButton>
                                <CloudUploadIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    onSave={fetchData}
                />
            ),
        }),
        [fetchData],
    );

    return (
        <>
            <ListView
                title='Materiales'
                tableTitle='Lista de materiales'
                basePath='/materiales'
                columns={columns}
                data={data}
                options={options}
                onDelete={onDelete}
            />
        </>
    );
}
