import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { companiesProvider } from '../../../../../api';
import { horarioDbValuesToFormValues, horarioFormValuesToDbValues } from '../../../../../api/companies';
import { withButtonOpener } from '../../../../../hooks/withButtonOpener';
import { formatHora, setBeginOfDay } from '../../../../../utils';
import DialogForm from '../../../../common/forms/DialogForm';
import HorariosForm from './HorariosForm';

const HorariosSchema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
    dias: Yup.array().of(
        Yup.object().shape({
            activo: Yup.bool(),
            horario: Yup.array().of(
                Yup.object().shape({
                    hora_entrada: Yup.date()
                        .typeError('Hora de entrada inválida')
                        .required('Hora de entrada requerida'),
                    hora_salida: Yup.date().typeError('Hora de salida inválida').required('Hora de salida requerida'),
                }),
            ),
            total_minutos: Yup.date()
                .typeError('Total del dia inválido')
                .required('Total del día requerido')
                .max(Yup.ref('max_minutos'), ({ max }) => `El tiempo total no puede ser mayor de ${formatHora(max)}`),
            max_minutos: Yup.mixed(),
        }),
    ),
});

const useStyles = makeStyles(
    (theme) => ({
        dialogPaper: {},
        colorEditor: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        totalSemana: {
            display: 'flex',
            justifyContent: 'flex-end',
            fontWeight: 'bold',
        },
    }),
    { name: 'NewHorariosDialog' },
);

function NewHorariosDialog({ onSave, duplicateId = null, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const [horario, setHorario] = useState(null);

    useEffect(() => {
        if (!props.open || !duplicateId) {
            setHorario(null);
            return;
        }

        companiesProvider.getHorarioEmpresa(duplicateId).then(setHorario);
    }, [duplicateId, props.open]);

    return (
        <DialogForm
            title='Añadir horarios'
            maxWidth='sm'
            className={classes.dialogPaper}
            FormikProps={{
                initialValues: {
                    nombre: horario ? `Copia de ${horario.nombre}` : '',
                    dias: horario
                        ? horarioDbValuesToFormValues(horario.horarios)
                        : new Array(7).fill(null).map(() => ({
                              activo: false,
                              horario: [],
                              total_minutos: setBeginOfDay(new Date()),
                              max_minutos: setBeginOfDay(new Date()),
                          })),
                },
                enableReinitialize: true,
                validationSchema: HorariosSchema,
                onSubmit: ({ nombre, dias }, { setSubmitting, setFieldError }) => {
                    const payload = {
                        nombre,
                        horarios: horarioFormValuesToDbValues(dias),
                    };

                    companiesProvider
                        .createHorarioEmpresa(payload)
                        .then((data) => {
                            onSave && onSave(data.id);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            snackbar.showMessage('Ha ocurrido un error');
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ values, setFieldValue }) => (
                <Grid container spacing={2} direction='column'>
                    <Grid item xs={12}>
                        <TextField name='nombre' label='Nombre' fullWidth />
                    </Grid>
                    <HorariosForm values={values} setFieldValue={setFieldValue} />
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(NewHorariosDialog);

NewHorariosDialog.propTypes = {
    duplicateId: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};
