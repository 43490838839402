import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContent } from '@material-ui/core';
import clsx from 'clsx';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../../common/Button';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 600,
            maxWidth: 600,
        },
        body: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(3)}px`,
            padding: theme.spacing(2),
        },
    }),
    { name: 'NotasDialog' },
);

function NotasDialog({ open, onClose, className, notas }) {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            id='mouse-over-popover'
            classes={{
                paper: clsx(classes.root, className),
            }}
        >
            <DialogTitle id='alert-dialog-title'>Notas</DialogTitle>
            <DialogContent className={classes.body}>{notas}</DialogContent>

            <DialogActions>
                <Button color='outlined' onClick={onClose}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withButtonOpener(NotasDialog);

NotasDialog.propTypes = {
    className: PropTypes.any,
    notas: PropTypes.any,
    onClose: PropTypes.any,
    open: PropTypes.any,
};
