import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import isAfter from 'date-fns/isAfter';
import isPast from 'date-fns/isPast';
import { Form, Formik, useFormikContext } from 'formik';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { contratosMantenimientoProvider, operariosProvider, tareasProvider } from '../../api';
import { DatosGeneralesForm } from './DatosGeneralesForm';
import { EquiposForm } from './EquiposForm';
import { PlanificacionForm } from './PlanificacionForm';
import Stepper from './Stepper';
import TareaDialogActions from './TareaDialogActions';

const EditarTareaSchema = Yup.object().shape({
    direccion: Yup.object()
        .nullable()
        .shape({
            // google_place_id: Yup.string().required('La dirección no esta validada en Google Maps'),
        })
        .required('Selecciona una direccion'),
    descripcion: Yup.string().required('Tienes que proporcionar una descripcion'),
    planificaciones: Yup.array().of(
        Yup.object().shape({
            fecha_inicio: Yup.date().typeError('Hay que introducir una fecha').required('Hay que introducir una fecha'),
            fecha_fin: Yup.date()
                .typeError('Hay que introducir una fecha')
                .required('Hay que introducir una fecha')
                .min(Yup.ref('fecha_inicio'), 'La hora de fin debe ser posterior a la de inicio'),
            operarios: Yup.array()
                .of(
                    Yup.object().shape({
                        es_responsable: Yup.boolean(),
                    }),
                )
                .test('unique-responsable', 'Solo puede haber un responsable', (operarios) => {
                    const responsablesCount = operarios.filter((operario) => operario.es_responsable).length;
                    return responsablesCount <= 1;
                }),
        }),
    ),
    checklists_tarea: Yup.array().of(
        Yup.object().shape({
            items: Yup.array()
                .of(
                    Yup.object().shape({
                        texto: Yup.string().required('Requerido'),
                    }),
                )
                .test(
                    'solo-subtareas',
                    'Si se tiene que imputar mano de obra, solo se pueden añadir tareas predefinidas',
                    function (value) {
                        if (!this.parent.contar_tiempo) return true;

                        return value.every((item) => item.subtarea_id !== null);
                    },
                )
                .min(1, 'Debe añadir al menos una tarea'),
            fecha_inicio: Yup.date().required('Requerido'),
            contar_tiempo: Yup.boolean().test('this-or-marcar-completado', 'Debes seleccionar una opción', function () {
                return this.parent.contar_tiempo || this.parent.marcar_completado;
            }),
            marcar_completado: Yup.boolean(),
        }),
    ),
});

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 1050,
            height: 840,
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            height: '100%',
        },
        finalizadaWarning: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(1),
            '& svg': {
                marginRight: 4,
                fontSize: 16,
            },
            '& *': {
                fontSize: 12,
                opacity: 0.6,
            },
        },
    }),
    { name: 'EditarTareaDialog' },
);

export default function EditarTareaButtonDialog({ button, ...props }) {
    const [open, setOpen] = useState(false);

    const formButton = React.cloneElement(button, { onClick: () => setOpen(true) });

    return (
        <>
            {formButton}
            <EditarTareaDialog open={open} onClose={() => setOpen(false)} {...props} />
        </>
    );
}

export function EditarTareaDialog({ id, open, onClose, onSave }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const [activeStep, setActiveStep] = useState(0);
    const [tarea, setTarea] = useState(null);
    const [operariosOptions, setOperariosOptions] = useState([]);

    const [mantenimientoPreventivo, setMantenimientoPreventivo] = useState(null);

    useEffect(() => {
        setActiveStep(0);
    }, [open]);

    useEffect(() => {
        if (!open || id === null || id === undefined) {
            setTarea(null);
            return;
        }

        const promises = [
            operariosProvider.getAll('as_options'),
            tareasProvider.getById(id),
            contratosMantenimientoProvider.action(`tareas/${id}`),
        ];

        Promise.all(promises).then(([operariosOptions, tarea, mantenimientoPreventivo]) => {
            setOperariosOptions(operariosOptions);
            setTarea(tarea);
            setMantenimientoPreventivo(mantenimientoPreventivo);
        });

        return () => setTarea(null);
    }, [id, open]);

    const { initialValues, initialErrors, initialTouched } = useMemo(() => {
        const params = {
            initialValues: {},
            initialErrors: {},
            initialTouched: {},
        };

        if (!tarea) return params;

        const tareaOperarioIds = tarea.operarios.map((op) => op.id);

        params.initialValues = {
            referencia_interna: tarea.referencia_interna,
            cliente: { id: tarea?.cliente_id },
            proyecto: tarea && tarea.proyecto_id ? { id: tarea.proyecto_id } : null,
            tipo: tarea?.tipo || '',
            direccion: tarea ? { id: tarea.direccion_id } : null,
            categoria: tarea ? tarea.categoria : null,
            descripcion: tarea?.descripcion || '',
            detalles: tarea?.detalles || '',
            operarios: tarea ? operariosOptions.filter((op) => tareaOperarioIds.includes(op.id)) : [],
            adjuntos: tarea?.adjuntos,
            files: [],
            partes_mantenimiento: tarea?.partes_mantenimiento,
            jornadas: tarea?.jornadas,
            notas_internas: tarea?.notas_internas || '',
            mantenimientoPreventivo,
            subtareas: tarea.subtareas,
            urgente: tarea.urgente,
            libre: tarea.libre,
            tiempoEstimadoHoras: Math.floor(tarea.minutos_estimados / 60),
            tiempoEstimadoMinutos: tarea.minutos_estimados % 60,
            checklists_tarea: tarea.checklists_tarea.map((checklist) => ({
                ...checklist,
                fecha_inicio: new Date(checklist.fecha_inicio),
                fecha_fin: checklist.fecha_fin ? new Date(checklist.fecha_fin) : null,
                newPlantillaName: '',
                plantillaChanged: false,
                plantilla: checklist.checklist_plantilla_id ? { id: checklist.checklist_plantilla_id } : null,
            })),
            documentos: tarea.documentos,
            documentosSeleccionados: [],
            planificaciones: tarea
                ? tarea.planificaciones
                      .map((planificacion) => {
                          const fechaInicio = new Date(planificacion.fecha_inicio);
                          const fechaFin = new Date(planificacion.fecha_fin);

                          const allDay =
                              fechaFin === null ||
                              (fechaInicio.getHours() === 0 &&
                                  fechaInicio.getMinutes() === 0 &&
                                  fechaFin.getHours() === 23 &&
                                  fechaFin.getMinutes() === 59);

                          return {
                              ...planificacion,
                              editable: !isPast(fechaFin),
                              fecha_inicio: fechaInicio,
                              fecha_fin: fechaFin,
                              usarFechaInicioJornada: planificacion.fecha_inicio_jornada !== null,
                              fecha_inicio_jornada: planificacion.fecha_inicio_jornada
                                  ? new Date(planificacion.fecha_inicio_jornada)
                                  : null,
                              fecha_visita: planificacion.fecha_visita ? new Date(planificacion.fecha_visita) : null,
                              allDay,
                              vehiculo: planificacion.vehiculo_id
                                  ? { id: planificacion.vehiculo_id, nombre: planificacion.vehiculo.nombre }
                                  : null,
                          };
                      })
                      .sort((a, b) => {
                          if (isAfter(a.fecha_inicio, b.fecha_inicio)) return -1;

                          if (isAfter(b.fecha_fin, a.fecha_fin)) return 1;

                          if (isAfter(a.fecha_fin, b.fecha_fin)) return -1;

                          return 0;
                      })
                : [],
        };

        if (open) {
            try {
                EditarTareaSchema.validateSync(params.initialValues, { abortEarly: false });
            } catch (err) {
                console.log(err);

                err.inner.forEach((fieldError) => {
                    params.initialErrors[fieldError.path] = fieldError.errors.join('. ');
                    params.initialTouched[fieldError.path] = true;
                });
            }
        }

        return params;
    }, [tarea, open, operariosOptions, mantenimientoPreventivo]);

    return (
        <>
            {/* {formButton} */}
            <Dialog
                onClose={onClose}
                aria-labelledby='nueva-tarea-title'
                open={open}
                classes={{
                    paper: classes.root,
                }}
                fullWidth={false}
                maxWidth='lg'
            >
                {tarea && (
                    <Formik
                        initialValues={initialValues}
                        initialErrors={initialErrors}
                        initialTouched={initialTouched}
                        enableReinitialize
                        validationSchema={EditarTareaSchema}
                        onSubmit={(
                            {
                                files,
                                checklists_tarea: checklists,
                                planificaciones,
                                cliente,
                                direccion,
                                proyecto,
                                categoria,
                                adjuntos,
                                jornadas,
                                documentos,
                                documentosSeleccionados,
                                tiempoEstimadoHoras,
                                tiempoEstimadoMinutos,
                                ...otherValues
                            },
                            { setSubmitting },
                        ) => {
                            const values = {
                                ...otherValues,
                                documentos: documentos.map((doc) => ({ id: doc.id })),
                                documentosSeleccionados: documentosSeleccionados.map((doc) => ({ id: doc.id })),
                                cliente_id: cliente.id,
                                direccion_id: direccion.id,
                                proyecto_id: proyecto ? proyecto.id : null,
                                categoria_id: categoria ? categoria.id : null,
                                checklists_tarea: checklists.map(({ plantilla, ...checklists }) => ({
                                    ...checklists,
                                    checklist_plantilla_id: plantilla ? plantilla.id : null,
                                })),
                                planificaciones: planificaciones.map(
                                    // eslint-disable-next-line camelcase
                                    ({ fecha_inicio, fecha_fin, allDay, vehiculo, ...rest }) => {
                                        if (allDay) {
                                            fecha_inicio.setHours(0, 0, 0, 0);
                                            fecha_fin.setHours(23, 59, 59, 0);
                                        }
                                        return {
                                            ...rest,
                                            fecha_inicio,
                                            fecha_fin,
                                            vehiculo_id: vehiculo ? vehiculo.id : null,
                                            fecha_cambio: new Date(),
                                            extender_automaticamente: allDay ? rest.extender_automaticamente : false,
                                        };
                                    },
                                ),
                                minutos_estimados: tiempoEstimadoHoras * 60 + tiempoEstimadoMinutos,
                            };

                            const formData = new FormData();
                            formData.append('values', JSON.stringify(values));
                            new Array(...files).forEach((file) => formData.append('files', file));

                            tareasProvider
                                .save(formData, id)
                                .then((data) => {
                                    setSubmitting(false);
                                    onClose();
                                    snackbar.showMessage('El servicio se ha actualizado con éxito');
                                    onSave(data);
                                })
                                .catch((err) => {
                                    console.error(err);
                                    setSubmitting(false);
                                    snackbar.showMessage('Ha ocurrido un error');
                                });
                        }}
                    >
                        {({ isSubmitting, values: { direccion } }) => (
                            <Form className={classes.form}>
                                <DialogTitle id='nueva-tarea-title' disableTypography>
                                    <Typography variant='h6' style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                                        Editar servicio
                                        {direccion && direccion.google_place_id === null && (
                                            <Alert
                                                variant='filled'
                                                severity='warning'
                                                style={{ fontSize: 12, padding: '4px 12px', marginLeft: 'auto' }}
                                            >
                                                Valida la dirección en Google Maps para que el GPS de tus operarios vaya
                                                al destino correcto
                                            </Alert>
                                        )}
                                    </Typography>
                                    <IconButton onClick={onClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <EditarTareaForm
                                        activeStep={activeStep}
                                        setActiveStep={setActiveStep}
                                        disabled={tarea?.estado === 'FINALIZADA'}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <TareaDialogActions
                                        disabled={isSubmitting}
                                        onCancelar={onClose}
                                        activeStep={activeStep}
                                        setActiveStep={setActiveStep}
                                    />
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                )}
            </Dialog>
        </>
    );
}

function EditarTareaForm({ activeStep, setActiveStep, disabled }) {
    const {
        values: { tipo },
        touched,
        errors,
    } = useFormikContext();

    const isMantenimiento = tipo === 'MANTENIMIENTO';
    const lastStep = isMantenimiento ? 2 : 1;

    const hiddenStyle = { display: 'none' };

    const steps = [
        { label: 'Datos generales', error: DatosGeneralesForm.fieldNames.some((key) => touched[key] && !!errors[key]) },
    ];
    if (isMantenimiento) steps.push({ label: 'Mantenimiento' });
    steps.push({ label: 'Planificación' });

    return (
        <>
            <Stepper steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
            <DatosGeneralesForm style={activeStep === 0 ? null : hiddenStyle} disabled={disabled} />
            {isMantenimiento && <EquiposForm style={activeStep === 1 ? null : hiddenStyle} disabled={disabled} />}
            <PlanificacionForm style={activeStep === lastStep ? null : hiddenStyle} disabled={disabled} />
        </>
    );
}

EditarTareaDialog.propTypes = {
    id: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};

EditarTareaForm.propTypes = {
    activeStep: PropTypes.any,
    disabled: PropTypes.any,
    setActiveStep: PropTypes.any,
};

EditarTareaButtonDialog.propTypes = {
    button: PropTypes.any,
};
