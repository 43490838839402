import { useDraggable } from '@dnd-kit/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import PropTypes from 'prop-types';
import { EVENTO_DRAGGABLE_TYPE } from './Evento';

const useStyles = makeStyles(
    (theme) => ({
        dragHandle: {
            cursor: 'grab',
            color: theme.palette.neutral.primary,
        },
    }),
    { name: 'DraggableTableRowHandle' },
);

export default function DraggableTableRowHandle({ row, ...props }) {
    const classes = useStyles();

    const { attributes, listeners, setNodeRef } = useDraggable({
        id: row.id,
        data: {
            type: EVENTO_DRAGGABLE_TYPE,
            pendientePlanificar: true,
            entity: {
                uniqueId: row.original.id,
                id: row.original.id,
                cliente: row.original.cliente,
                descripcion: row.original.descripcion,
            },
        },
    });
    const style = {
        // transform: CSS.Translate.toString(transform),
    };

    return (
        <DragIndicatorIcon
            className={classes.dragHandle}
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
        />
    );
}

DraggableTableRowHandle.propTypes = {
    row: PropTypes.any,
};
