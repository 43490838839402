import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { formatCurrency, formatNumber } from '../../utils';
import PaperList from '../proyectos/PaperList/PaperList';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 400,
            alignSelf: 'flex-end',
        },
    }),
    { name: 'TotalesPresupuesto' },
);
export function getTotalesPresupuesto(presupuesto) {
    if (!presupuesto.lineas)
        return {
            totalCoste: 0,
            totalVentaSinDescuento: 0,
            totalVenta: 0,
            descuento: 0,
            margen: 0,
            margenPorcentaje: 0,
        };

    const margen = presupuesto.total_venta - presupuesto.importe_coste;
    const margenPorcentaje = presupuesto.total_venta !== 0 ? (margen / presupuesto.importe_coste) * 100 : 0;

    const totales = {
        totalCoste: presupuesto.importe_coste,
        totalVentaSinDescuento: presupuesto.importe_venta,
        totalVenta: presupuesto.total_venta,
        descuento: presupuesto.descuento_venta,
        margen,
        margenPorcentaje,
    };

    return totales;
}

export default function TotalesPresupuesto({ presupuesto }) {
    const classes = useStyles();

    const totales = useMemo(() => {
        if (!presupuesto.lineas) return [];

        const totales = getTotalesPresupuesto(presupuesto);

        return [
            {
                label: 'Total coste',
                value: formatCurrency(totales.totalCoste),
            },
            {
                label: 'Total venta (sin descuento)',
                value: formatCurrency(totales.totalVentaSinDescuento),
            },
            {
                label: 'Descuento',
                value: `${formatNumber(totales.descuento)} %`,
            },
            {
                label: 'Total venta',
                value: formatCurrency(totales.totalVenta),
                color: 'info',
            },
            {
                label: 'Margen',
                value: `(${formatNumber(totales.margenPorcentaje)}%) ${formatCurrency(totales.margen)}`,
                color: 'success',
            },
        ];
    }, [presupuesto]);

    return <PaperList lineas={totales} className={classes.root} />;
}

TotalesPresupuesto.propTypes = {
    presupuesto: PropTypes.object.isRequired,
};
