import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { tareasProvider } from '../../../api';
import DeleteDialog from '../../common/dialogs/DeleteDialog';
import MasterItemOptions from '../../common/forms/MasterDetailsView/MasterItemOptions';
import DelayedDownloadComponent from '../AlbaranList/DelayedDownloadComponent';
import FechaEditor from './FechaEditor';

export default function ParteTrabajoItemOptions({ item, onUpdate, onDelete }) {
    return (
        <MasterItemOptions>
            {({ closeMenu }) => {
                const options = [
                    <DelayedDownloadComponent
                        key={0}
                        Component={MenuItem}
                        onClick={() => tareasProvider.getAll(`jornadas/${item.id}/exportar-async`)}
                        closeMenu={closeMenu}
                        altDownloadAction={() =>
                            tareasProvider
                                .fetchAttachment(`jornadas/${item.id}/exportar`, { method: 'get' })
                                .catch((err) => {
                                    console.error(err);
                                })
                        }
                    >
                        Descargar en Excel
                    </DelayedDownloadComponent>,
                    <MenuItem
                        key={1}
                        onClick={(ev) => {
                            closeMenu(ev);
                            tareasProvider.marcarJornadaRevisada(item.id, !item.revisada).then(onUpdate);
                        }}
                    >
                        Marcar como {item.revisada ? 'no revisado' : 'revisado'}
                    </MenuItem>,
                    <FechaEditor
                        key={2}
                        jornada={item}
                        onSave={onUpdate}
                        button={<MenuItem onClick={closeMenu}>Editar fecha</MenuItem>}
                    />,
                ];

                if (onDelete)
                    options.push(
                        <DeleteDialog
                            key={3}
                            text='¿Estás seguro que quieres eliminar este parte de trabajo?'
                            onConfirm={(ev) => {
                                closeMenu(ev);
                                onDelete();
                            }}
                            button={<MenuItem onClick={closeMenu}>Eliminar</MenuItem>}
                            confirmationText='borrar parte de trabajo'
                        />,
                    );
                return options;
            }}
        </MasterItemOptions>
    );
}

ParteTrabajoItemOptions.propTypes = {
    item: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};
