import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import Button from '../common/Button';
import EmptyListHero from '../common/EmptyListHero';
import AddCapituloDialog from './PresupuestoView/AddCapituloDialog';
import UploadFileDialog from './UploadFileDialog';

const useStyles = makeStyles(
    (theme) => ({
        actions: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'EmptyPresupuestoHero' },
);

export default function EmptyPresupuestoHero({ presupuestoId, onSave }) {
    const classes = useStyles();

    return (
        <EmptyListHero
            title='Lineas de presupuesto'
            action={
                <div className={classes.actions}>
                    <AddCapituloDialog
                        presupuestoId={presupuestoId}
                        button={
                            <Button color='info' aria-label='Añadir capítulo' startIcon={<AddIcon />}>
                                Añadir capítulo
                            </Button>
                        }
                        onSave={onSave}
                    />
                    <UploadFileDialog
                        presupuestoId={presupuestoId}
                        button={
                            <Button color='info' aria-label='Importar' startIcon={<AddIcon />}>
                                Importar
                            </Button>
                        }
                        onSave={onSave}
                    />
                </div>
            }
        >
            Este presupuesto está vacío. Puedes empezar añadiendo capítulos o bien importar un fichero BC3.
        </EmptyListHero>
    );
}

EmptyPresupuestoHero.propTypes = {
    onSave: PropTypes.func.isRequired,
    presupuestoId: PropTypes.string,
};
