import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CrearVacacionesDialog from './CrearVacaciones';
import AddIcon from '@material-ui/icons/Add';
import CrearAusenciaDialog from './CrearAusencias';
import Button from '../common/Button';
import ViewToggler from './ViewToggler';
import SolicitudesCalendarioView from './SolicitudesCalendarioView';
import SolicitudesListView from './SolicitudesListView';

const useStyles = makeStyles(
    (theme) => ({
        title: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
            gap: `${theme.spacing(2)}px`,
        },
        button: {
            textTransform: 'none',
            textDecoration: 'underline',
            color: 'white',
            padding: '1px 4px',
        },
    }),
    { name: 'SolicitudesList' },
);

export default function SolicitudesList() {
    const classes = useStyles();
    const history = useHistory();
    const vacacionesRef = React.createRef();
    const ausenciasRef = React.createRef();
    const { isExact: listadoViewSelected } = useRouteMatch();

    return (
        <>
            <div className={classes.title}>
                <Typography variant='h2' component='h2' style={{ flex: 1 }}>
                    Vacaciones y ausencias
                </Typography>

                <ViewToggler
                    views={[
                        {
                            key: 'listado',
                            label: 'Listado',
                        },
                        {
                            key: 'calendario',
                            label: 'Calendario',
                        },
                    ]}
                    initialView={listadoViewSelected ? 'listado' : 'calendario'}
                    onViewChange={(view) =>
                        view === 'calendario' ? history.push('/solicitudes/calendario') : history.push('/solicitudes')
                    }
                />
                <CrearVacacionesDialog
                    button={
                        <Button color='info' startIcon={<AddIcon />}>
                            Añadir vacaciones
                        </Button>
                    }
                    onSave={() => vacacionesRef.current.refresh()}
                />
                <CrearAusenciaDialog
                    button={
                        <Button color='info' startIcon={<AddIcon />}>
                            Añadir ausencia
                        </Button>
                    }
                    onSave={() => ausenciasRef.current.refresh()}
                />
            </div>

            <Switch>
                <Route path='/solicitudes/calendario'>
                    <SolicitudesCalendarioView />
                </Route>
                <Route path='/solicitudes'>
                    <SolicitudesListView vacacionesRef={vacacionesRef} ausenciasRef={ausenciasRef} />
                </Route>
            </Switch>
        </>
    );
}
