import { useEffect, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import VacacionesList from './VacacionesList';
import AusenciasList from './AusenciasList';
import { useHistory } from 'react-router-dom';
import { solicitudesProvider } from '../../api';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '../common/Button';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        alert: {
            marginBottom: theme.spacing(2),
        },
        button: {
            textTransform: 'none',
            textDecoration: 'underline',
            color: 'white',
            padding: '0px 4px',
            height: 24,
        },
    }),
    { name: 'SolicitudesListView' },
);

export default function SolicitudesListView({ vacacionesRef, ausenciasRef }) {
    const classes = useStyles();
    const history = useHistory();
    const [operariosSinVacaciones, setOperariosSinVacaciones] = useState([]);

    useEffect(() => {
        solicitudesProvider.getOperariosSinVacaciones().then(setOperariosSinVacaciones);
    }, []);

    return (
        <>
            {operariosSinVacaciones.length > 0 && (
                <Alert variant='filled' severity={'info'} className={classes.alert}>
                    Los siguientes operarios aún no tienen días de vacaciones configurados:
                    {operariosSinVacaciones.map((operario, i) => (
                        <Button
                            key={i}
                            onClick={() => history.push(`/empleados/${operario.id}/vacaciones`)}
                            className={classes.button}
                        >
                            {operario.nombre}
                        </Button>
                    ))}
                </Alert>
            )}
            <VacacionesList ref={vacacionesRef} />
            <AusenciasList ref={ausenciasRef} />
        </>
    );
}

SolicitudesListView.propTypes = {
    ausenciasRef: PropTypes.any,
    vacacionesRef: PropTypes.any,
};
