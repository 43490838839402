import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { diasSemana } from '../../../../../api/companies';
import { createTiempo } from '../../../../../api/tareas-functions';
import { formatTiempo, setBeginOfDay } from '../../../../../utils';
import HorariosDiaField from './HorariosDiaField';

const useStyles = makeStyles(
    (theme) => ({
        totalSemana: {
            display: 'flex',
            justifyContent: 'flex-end',
            fontWeight: 'bold',
        },
    }),
    { name: 'HorariosForm' },
);

export default function HorariosForm({ values, setFieldValue }) {
    const classes = useStyles();
    let minutosSemana = 0;

    const beginOfToday = setBeginOfDay(new Date());
    values.dias
        .filter((dia) => dia.activo)
        .forEach((dia) => {
            const totalMinutos = Math.floor((dia.total_minutos - beginOfToday) / 60000);
            minutosSemana += totalMinutos;
        });

    const duplicateDia = (fromIndex) => (selectedIndices) => {
        const newDias = [...values.dias];
        selectedIndices.forEach((toIndex) => {
            newDias[toIndex] = { ...newDias[fromIndex] };
        });

        setFieldValue('dias', newDias);
    };

    return (
        <>
            {values.dias.map((dia, i) => (
                <HorariosDiaField
                    key={i}
                    diaIndex={i}
                    name={`dias[${i}]`}
                    label={`${diasSemana[i]}`}
                    onCopy={duplicateDia(i)}
                />
            ))}
            <Grid item xs={12} className={classes.totalSemana}>
                <Typography variant='subtitle2'>
                    Total: {formatTiempo(createTiempo(isNaN(minutosSemana) ? 0 : minutosSemana), false)} semanales
                </Typography>
            </Grid>
        </>
    );
}

HorariosForm.propTypes = {
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
};
