import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { solicitudesProvider } from '../../api';
import { convertDates, solicitudEstadoSelectOptions } from '../../api/solicitudes';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { formatDateTime, isAllDay, setBeginOfDay, setEndOfDay } from '../../utils';
import Button from '../common/Button';
import { DateRangePicker } from '../common/fields/DateRangePicker';
import InfoField from '../common/fields/InfoField';
import SelectField from '../common/fields/SelectField';

const AusenciaSchema = Yup.object().shape({
    motivo: Yup.string().required('Requerido'),
    fecha_inicio: Yup.date().typeError('La fecha de inicio debe ser una fecha').required('Requerido'),
    fecha_fin: Yup.date()
        .typeError('La fecha de fin debe ser una fecha')
        .required('Requerido')
        .min(Yup.ref('fecha_inicio'), 'La fecha de fin debe ser posterior a la de inicio'),
});

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 600,
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
    }),
    { name: 'EditarVacacionesDialog' },
);

export function EditarAusenciaDialog({ open, onClose, onSave, ausencia: originalAusencia, styles }) {
    const classes = useStyles();
    const [ausencia, setAusencia] = useState(convertDates(originalAusencia));
    useEffect(() => {
        setAusencia(convertDates(originalAusencia));
    }, [originalAusencia]);

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby='nueva-ausencia-title'
            open={open}
            classes={{
                paper: classes.root,
            }}
            fullWidth={false}
            maxWidth='lg'
        >
            <Formik
                initialValues={{
                    estado: ausencia.estado,
                    motivo: ausencia.motivo,
                    fecha_inicio: new Date(ausencia.fecha_inicio),
                    fecha_fin: new Date(ausencia.fecha_fin),
                    observaciones: ausencia.observaciones,
                    allDay: isAllDay(ausencia.fecha_inicio, ausencia.fecha_fin),
                    retribuida: ausencia.retribuida,
                }}
                enableReinitialize={true}
                validationSchema={AusenciaSchema}
                onSubmit={(
                    // eslint-disable-next-line camelcase
                    { operario, allDay, fecha_inicio, fecha_fin, ...values },
                    { setSubmitting, setFieldError },
                ) => {
                    if (allDay) {
                        setBeginOfDay(fecha_inicio);
                        setEndOfDay(fecha_fin);
                    }
                    solicitudesProvider
                        .save(
                            {
                                ...values,
                                tipo: ausencia.tipo,
                                fecha_inicio,
                                fecha_fin,
                            },
                            ausencia.id,
                        )
                        .then((updatedAusencia) => {
                            setSubmitting(false);
                            onSave(updatedAusencia);
                            onClose();
                        });
                }}
            >
                {({ isSubmitting, values, errors, setFieldValue }) => {
                    return (
                        <Form className={classes.form}>
                            <DialogTitle id='parte-trabajo-title'>Editar ausencia</DialogTitle>
                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <InfoField label='Operario' value={ausencia.operario} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InfoField label='Fecha de registro' value={formatDateTime(ausencia.fecha)} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {ausencia.estado === 'PENDIENTE' ? (
                                            <TextField name='motivo' label='Motivo' fullWidth />
                                        ) : (
                                            <InfoField label='Motivo' value={values.motivo} />
                                        )}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name='estado'
                                            id='estado'
                                            component={SelectField}
                                            label='Estado'
                                            fullWidth
                                            selectOptions={solicitudEstadoSelectOptions}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DateRangePicker
                                            label='Fechas'
                                            allDay={values.allDay}
                                            fechaInicioKey='fecha_inicio'
                                            fechaFinKey='fecha_fin'
                                            autoAdjustTime
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CheckboxWithLabel
                                            type='checkbox'
                                            name='allDay'
                                            Label={{ label: 'Todo el dia' }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CheckboxWithLabel
                                            type='checkbox'
                                            name='retribuida'
                                            Label={{ label: 'Retribuida' }}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button color='outlined' disabled={isSubmitting} onClick={onClose}>
                                    Cancelar
                                </Button>
                                <Button type='submit' color='info' disabled={isSubmitting}>
                                    Guardar
                                </Button>
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}

export const EditarAusenciaDialogButton = withButtonOpener(EditarAusenciaDialog);

EditarAusenciaDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
    styles: PropTypes.any,
    ausencia: PropTypes.any,
};
