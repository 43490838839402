import GestureIcon from '@material-ui/icons/Gesture';
import PropTypes from 'prop-types';
import { formatDateTime } from '../../../utils';
import ItemLabel from '../../common/forms/MasterDetailsView/ItemLabel';

export default function FirmadoLabel({ value }) {
    if (!value) return null;

    return (
        <ItemLabel
            icon={<GestureIcon />}
            label={value?.image_url ? 'Firmado' : 'No firmado'}
            tooltip={value?.image_url ? `Firmado el ${formatDateTime(value.fecha)}` : ''}
            disabled={!value}
        />
    );
}

FirmadoLabel.propTypes = {
    value: PropTypes.any,
};
