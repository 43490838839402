import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

function renderHighlighted(text, query) {
    const matches = match(text, query);
    const parts = parse(text, matches);

    return parts.map((part, index) => (
        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
        </span>
    ));
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            overflowY: 'scroll',
        },
    }),
    { name: 'SearchResults' },
);

export default function SearchResults({ query, results, onResultClick }) {
    const history = useHistory();
    const classes = useStyles();

    return (
        <List disablePadding className={classes.root}>
            {results.map((result, i) => (
                <ListItem
                    key={i}
                    button
                    onClick={() => {
                        onResultClick();
                        history.push(result.url);
                    }}
                >
                    <ListItemText
                        primary={renderHighlighted(result.primary, query)}
                        secondary={renderHighlighted(result.secondary, query)}
                    />
                    <ListItemAvatar>{result.chip}</ListItemAvatar>
                </ListItem>
            ))}
        </List>
    );
}

SearchResults.propTypes = {
    onResultClick: PropTypes.any,
    query: PropTypes.any,
    results: PropTypes.any,
};
