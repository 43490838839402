import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import { navigate } from 'react-big-calendar/lib/utils/constants';
import Button from '../common/Button';
import { SwitchWithLabel } from '../common/fields/Switch';
import { ToggleButton, ToggleButtonGroup } from '../common/ToggleButtonGroup';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(3)}px`,
            marginBottom: theme.spacing(3),
            '& .MuiButton-root': {
                height: 32,
                padding: 0,
                backgroundColor: 'white',
            },
        },
        navigation: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            '& .MuiButton-root': {
                width: 32,
                minWidth: 0,
            },
        },
        fecha: {
            fontWeight: theme.typography.fontWeightMedium,
            textTransform: 'capitalize',
        },
    }),
    { name: 'AgendaToolbar' },
);

function Toolbar({
    localizer: { messages },
    label,
    onNavigate,
    onView,
    views: viewNames,
    view,
    onHidePastChanged,
    hidePast,
}) {
    const classes = useStyles();
    const onChangeView = (event, newView) => {
        onView(newView);
    };

    return (
        <div className={classes.root}>
            <div className={classes.navigation}>
                <Button color='outlined' onClick={() => onNavigate(navigate.PREVIOUS)}>
                    <ChevronLeftIcon />
                </Button>
                <Button color='outlined' onClick={() => onNavigate(navigate.NEXT)}>
                    <ChevronRightIcon />
                </Button>
            </div>

            <Typography variant='h2' className={classes.fecha}>
                {label}
            </Typography>

            <Button color='outlined' onClick={() => onNavigate(navigate.TODAY)}>
                Hoy
            </Button>

            <ToggleButtonGroup value={view} exclusive onChange={onChangeView}>
                {viewNames.map((name) => (
                    <ToggleButton key={name} value={name}>
                        {messages[name]}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>

            <SwitchWithLabel
                label={
                    <>
                        Ocultar planificaciones no visibles{' '}
                        <Tooltip
                            arrow
                            title='Se ocultarán los eventos que no vean los operarios (han salido del servicio o no se les muestran) y su fecha de fin haya pasado'
                        >
                            <InfoIcon />
                        </Tooltip>
                    </>
                }
                field={{
                    name: 'ocultar-no-visibles',
                    onChange: () => onHidePastChanged(!hidePast),
                    value: hidePast,
                }}
            />
        </div>
    );
}

Toolbar.propTypes = {
    hidePast: PropTypes.any,
    label: PropTypes.node.isRequired,
    localizer: PropTypes.object,
    onHidePastChanged: PropTypes.any,
    onNavigate: PropTypes.func.isRequired,
    onView: PropTypes.func.isRequired,
    view: PropTypes.string.isRequired,
    views: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Toolbar;
