import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { materialesProvider } from '../../api';
import { Form } from 'formik';
import { useSnackbar } from 'material-ui-snackbar-provider';
import FileUploader from '../common/fields/FileUploader';
import DialogForm from '../common/forms/DialogForm';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import PropTypes from 'prop-types';

const UploadSchema = Yup.object().shape({
    file: Yup.mixed().required('Requerido'),
});

function UploadFileDialog({ onSave, ...props }) {
    const snackbar = useSnackbar();

    return (
        <DialogForm
            title='Subir fichero de materiales'
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    file: null,
                },
                validationSchema: UploadSchema,
                onSubmit: ({ file, ...values }, { setSubmitting, setFieldError }) => {
                    const formData = new FormData();
                    Object.entries(values).forEach(([key, value]) => formData.append(key, value));
                    formData.append('file', file);

                    materialesProvider
                        .getAll('importar', {
                            method: 'post',
                            body: formData,
                        })
                        .then((result) => {
                            props.onClose();
                            onSave(result);
                            snackbar.showMessage('Se ha actualizado los materiales con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ isSubmitting, values, errors, setFieldValue }) => {
                return (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FileUploader
                                    id='upload-materiales-internos-file'
                                    label='Añadir adjuntos'
                                    file={values.file}
                                    setFile={(file) => setFieldValue('file', file)}
                                    multiple={false}
                                    isImage={false}
                                    accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                                />
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </DialogForm>
    );
}

export default withButtonOpener(UploadFileDialog);

UploadFileDialog.propTypes = {
    onSave: PropTypes.any,
    onClose: PropTypes.any,
};
