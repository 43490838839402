import * as Yup from 'yup';
import { useSnackbar } from 'material-ui-snackbar-provider';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { cuestionariosProvider, equiposProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import DialogForm from '../common/forms/DialogForm';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import SelectField from '../common/fields/SelectField';
import { useEffect, useState } from 'react';

const CuestionariosSchema = Yup.object().shape({
    referencia: Yup.string().max(40, 'La referencia no puede tener más de 40 caracteres').required('Requerido'),
    nombre: Yup.string().required('Requerido'),
    descripcion: Yup.string(),
    tipos_equipo: Yup.array().min(1, 'Selecciona al menos un tipo de equipo').required('Requerido'),
});

function NewCuestionarioDialog({ onSave, ...props }) {
    const snackbar = useSnackbar();
    const [tiposOptions, setTiposOptions] = useState([]);

    useEffect(() => {
        equiposProvider
            .getTipos()
            .then((tipos) => setTiposOptions(tipos.map((tipo) => ({ label: tipo.nombre, value: tipo.id }))));
    }, []);

    return (
        <DialogForm
            title='Añadir cuestionario'
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    referencia: '',
                    nombre: '',
                    descripcion: '',
                    tipos_equipo: [],
                },
                validationSchema: CuestionariosSchema,
                onSubmit: ({ tipos_equipo: tiposEquipo, ...values }, { setSubmitting, setFieldError }) => {
                    cuestionariosProvider
                        .save({ tipos_equipo: tiposEquipo.map((id) => ({ id })), ...values })
                        .then((data) => {
                            onSave && onSave(data.id);
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                if (err.message instanceof Object) {
                                    for (const [field, errors] of Object.entries(err.message)) {
                                        setFieldError(field, errors.join('\n'));
                                    }
                                } else {
                                    snackbar.showMessage(err.message);
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {({ setFieldValue, values }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField name='referencia' label='Referencia' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name='nombre' label='Nombre' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name='descripcion' label='Descripcion' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name='tipos_equipo'
                            id='tipos_equipo'
                            component={SelectField}
                            label='Tipos de equipo compatibles'
                            fullWidth
                            selectOptions={tiposOptions}
                            multiple
                        />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(NewCuestionarioDialog);

NewCuestionarioDialog.propTypes = {
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
