import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import Chip from '@material-ui/core/Chip';

export const OperarioChip = withStyles((theme) => ({
    root: {
        color: (props) => props.textColor,
        backgroundColor: (props) => props.backgroundColor,
        border: (props) => `1px solid ${props.backgroundColor}`,
        height: 28,
        width: 'fit-content',
        '&.Mui-disabled': {
            cursor: 'pointer',
            pointerEvents: 'all',
            opacity: 1,
            backgroundColor: 'transparent',
        },
        '& .MuiChip-avatar': {
            backgroundColor: 'white',
            color: 'black',
            opacity: 0.5,
            width: 28,
            height: 26,
            marginLeft: 3,
        },
    },
    label: {
        flex: 1,
    },
    clickable: {
        '&:focus': {
            backgroundColor: (props) => props.backgroundColor,
        },
        '&:hover': {
            backgroundColor: (props) => props.backgroundColor,
        },
        '&.Mui-disabled:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-disabled:focus': {
            backgroundColor: 'transparent',
        },
    },
}))(({ backgroundColor, textColor, ...props }) => React.createElement(Chip, props));
