import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiTextField from '@material-ui/core/TextField';
import { Field, useField } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { operariosProvider } from '../../../api';
import Button from '../Button';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'flex-end',
            gap: `${theme.spacing(1)}px`,
            '& .MuiAutocomplete-root': {
                flex: 1,
            },
        },
    }),
    { name: 'OperariosField' },
);

export default function OperariosField({
    disabled,
    operariosKey = 'operarios',
    getDefaultOption,
    getOperarioFromOption,
    addSelectAll = false,
    multiple = true,
    label = 'Operarios',
    operariosOptionsFn = null,
    placeholder = 'Puedes dejarlo vacío para asignarlo más tarde',
}) {
    const classes = useStyles();
    const [operariosOptions, setOperariosOptions] = useState([]);
    const [, { value, error }, { setValue }] = useField(operariosKey);

    useEffect(() => {
        (operariosOptionsFn ? operariosOptionsFn() : operariosProvider.getAll('as_options')).then((options) =>
            setOperariosOptions(options.map((op) => (getDefaultOption ? getDefaultOption(op) : op))),
        );
    }, []);

    const field = (
        <Field
            name={operariosKey}
            fullWidth={!addSelectAll}
            multiple={multiple}
            component={Autocomplete}
            options={operariosOptions}
            getOptionLabel={(option) => (getOperarioFromOption ? getOperarioFromOption(option) : option).nombre}
            getOptionSelected={(option, value) =>
                (getOperarioFromOption ? getOperarioFromOption(option) : option).id ===
                (getOperarioFromOption ? getOperarioFromOption(value) : value).id
            }
            renderInput={(params) => (
                <MuiTextField
                    {...params}
                    error={Boolean(error)}
                    helperText={error}
                    label={label}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={disabled}
                    placeholder={value?.length === 0 ? placeholder : ''}
                />
            )}
        />
    );

    if (!addSelectAll) {
        return field;
    }

    return (
        <div className={classes.root}>
            {field}
            <Button color='outlined' onClick={() => setValue(operariosOptions)}>
                Seleccionar todos
            </Button>
        </div>
    );
}

OperariosField.propTypes = {
    disabled: PropTypes.bool,
    operariosKey: PropTypes.string,
    getDefaultOption: PropTypes.func,
    getOperarioFromOption: PropTypes.func,
    addSelectAll: PropTypes.bool,
    multiple: PropTypes.bool,
    label: PropTypes.string,
    operariosOptionsFn: PropTypes.func,
    placeholder: PropTypes.string,
};
