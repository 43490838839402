import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { tareasProvider } from '../../api';
import { estadoLabels, estadoTareaOptions } from '../../api/tareas';
import useAuthState from '../../AuthState';
import { useFetchData } from '../../hooks/useFetchData';
import { formatDate } from '../../utils';
import Button from '../common/Button';
import { ButtonDialog } from '../common/dialogs/ButtonDialog';
import { DateColumnFilter, dateFilterFn } from '../common/list/DateColumnFilter';
import BaseListView, { withTableState } from '../common/list/ListView';
import { SelectColumnFilter } from '../common/list/SelectColumnFilter';
import { ClienteLinkCell } from '../dashboard/ClienteLink';
import ServicioEstadoChip from '../servicios/ServicioEstadoChip';
import JornadaDrawer from './JornadaDrawer';

const ListView = withTableState('revision-partes', BaseListView);

export default function PartesSinRevisar() {
    const fetchDataFn = useCallback(() => tareasProvider.getAll('jornadas/sin-revisar'), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const {
        userInfo: { preferencias: { usar_referencias_tareas: usarReferencias } = {} },
    } = useAuthState();

    const [drawerJornada, setDrawerJornada] = useState(false);

    const toggleDrawerJornada = (jornada) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawerJornada(jornada);
    };

    const { jornadasRevisadas, jornadasSinRevisar } = useMemo(() => {
        const jornadasRevisadas = [];
        const jornadasSinRevisar = [];
        if (data) {
            data.forEach((jornada) => {
                if (jornada.revisada) {
                    jornadasRevisadas.push(jornada);
                } else {
                    jornadasSinRevisar.push(jornada);
                }
            });
        }
        return { jornadasRevisadas, jornadasSinRevisar };
    }, [data]);

    const columns = useMemo(() => {
        const columns = [];

        if (usarReferencias)
            columns.push({
                Header: 'Ref. interna',
                accessor: 'referencia_interna',
            });

        columns.push(
            {
                Header: 'Referencia',
                id: 'numero',
                accessor: (row) => (row.numero ? row.numero.toString().padStart(4, '0') : 'N/D'),
            },
            {
                Header: 'Título',
                accessor: 'descripcion',
            },
            {
                Header: 'Cliente',
                accessor: 'cliente',
                Cell: ClienteLinkCell,
            },
            {
                Header: 'Proyecto',
                id: 'proyecto',
                accessor: (row) => row.proyecto ?? 'Sin proyecto',
            },
            {
                Header: 'Direccion',
                accessor: 'direccion',
            },
            {
                Header: 'Operario',
                accessor: 'operario',
            },
            {
                Header: 'Fecha',
                accessor: 'fecha',
                Cell: ({ value }) => formatDate(new Date(value)),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Estado servicio',
                accessor: 'estado',
                Cell: ServicioEstadoChip,
                Filter: SelectColumnFilter,
                filterOptions: {
                    options: estadoTareaOptions,
                    includeAllOption: {
                        value: 'todos',
                        label: 'Todos',
                    },
                },
                exportValue: ({ value }) => estadoLabels[value],
            },
        );
        return columns;
    }, [usarReferencias]);

    const { optionsJornadasSinRevisar, optionsJornadasRevisadas } = useMemo(() => {
        const optionsJornadasRevisadas = {
            useActions: true,
            hideEdit: true,
            hideDelete: true,
            tableOptions: {
                expandedTable: true,
            },
            batchComponents: ({ rows }) => (
                <ButtonDialog
                    dialogTitle='Marcar las observaciones seleccionadas como leidas'
                    dialogText={[
                        `Estás a punto de marcar automáticamente ${rows.length} observaciones como leidas. ¿Deseas continuar?`,
                    ]}
                    onConfirm={() => {
                        const ids = rows.map((r) => r.original.id);
                        tareasProvider
                            .action('jornadas/leer', { method: 'post', body: JSON.stringify({ ids }) })
                            .then(() => {
                                fetchData();
                            });
                    }}
                    button={
                        <Tooltip title='Marcar las observaciones seleccionadas como leidas'>
                            <Button color='transparent' startIcon={<VisibilityIcon />} disabled={rows.length === 0}>
                                Marcar todo como leido
                            </Button>
                        </Tooltip>
                    }
                />
            ),
        };

        const optionsJornadasSinRevisar = {
            ...optionsJornadasRevisadas,

            batchComponents: ({ rows }) => (
                <ButtonDialog
                    dialogTitle='Marcar los partes seleccionados como revisados'
                    dialogText={[
                        `Estás a punto de revisar automáticamente ${rows.length} partes de trabajo. ¿Deseas continuar?`,
                    ]}
                    onConfirm={() => {
                        const ids = rows.map((r) => r.original.id);
                        tareasProvider
                            .action('jornadas/revisar', { method: 'post', body: JSON.stringify({ ids }) })
                            .then(() => {
                                fetchData();
                            });
                    }}
                    button={
                        <Tooltip title='Marcar los partes seleccionados como revisados'>
                            <Button color='transparent' startIcon={<DescriptionIcon />} disabled={rows.length === 0}>
                                Revisar todo
                            </Button>
                        </Tooltip>
                    }
                />
            ),
        };

        return { optionsJornadasSinRevisar, optionsJornadasRevisadas };
    }, []);

    return (
        <>
            <JornadaDrawer
                jornada={drawerJornada}
                onClose={toggleDrawerJornada(false)}
                onRevisar={(jornadaId) => tareasProvider.marcarJornadaRevisada(jornadaId).then(fetchData)}
                onUpdate={fetchData}
            />
            <ListView
                tableTitle='Partes de trabajo sin revisar'
                basePath='/partes-trabajo'
                columns={columns}
                data={jornadasSinRevisar}
                options={optionsJornadasSinRevisar}
                extraActions={(row) => (
                    <>
                        <IconButton
                            style={{ padding: 6 }}
                            aria-label='Ver parte de trabajo'
                            onClick={toggleDrawerJornada(row)}
                        >
                            <VisibilityIcon fontSize='small' />
                        </IconButton>
                    </>
                )}
            />
            <ListView
                tableTitle='Partes de trabajo revisados con notas sin leer'
                basePath='/partes-trabajo'
                columns={columns}
                data={jornadasRevisadas}
                options={optionsJornadasRevisadas}
                extraActions={(row) => (
                    <>
                        <IconButton
                            style={{ padding: 6 }}
                            aria-label='Ver parte de trabajo'
                            onClick={toggleDrawerJornada(row)}
                        >
                            <VisibilityIcon fontSize='small' />
                        </IconButton>
                    </>
                )}
            />
        </>
    );
}
