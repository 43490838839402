import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import DialogForm from '../common/forms/DialogForm';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import PropTypes from 'prop-types';
import { proveedoresProvider } from '../../api';

const Proveedoreschema = Yup.object().shape({
    codigo: Yup.string().required('Requerido'),
    nombre: Yup.string().required('Requerido'),
});

function EditProveedorDialog({ onSave, proveedor: originalProveedor, ...props }) {
    const [proveedor, setProveedor] = useState(originalProveedor);
    useEffect(() => setProveedor(originalProveedor), [originalProveedor]);

    return (
        <DialogForm
            title='Editar proveedor'
            FormikProps={{
                initialValues: {
                    codigo: proveedor.codigo,
                    nombre: proveedor.nombre,
                    activo: proveedor.activo,
                },
                validationSchema: Proveedoreschema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    proveedoresProvider
                        .save(values, proveedor.id)
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField name='codigo' label='Código' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='nombre' label='nombre' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <CheckboxWithLabel type='checkbox' name='activo' Label={{ label: 'Activo' }} />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(EditProveedorDialog);

EditProveedorDialog.propTypes = {
    proveedor: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
