import { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogEditor from '../../common/forms/DialogEditor';
import PropTypes from 'prop-types';
import useAuthState from '../../../AuthState';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles(
    (theme) => ({
        fields: {
            gap: `${theme.spacing(2)}px`,
        },
        field: {
            width: 120,
            '& .MuiInput-input': {
                textAlign: 'right',
            },
        },
    }),
    { name: 'GastoEditor' },
);

export default function GastoEditor({
    unidades: originalUnidades,
    precioCoste: originalPrecioCoste,
    precioVenta: originalPrecioVenta,
    descuento: originalDescuento,
    onSave,
    ...props
}) {
    const classes = useStyles();
    const [unidades, setUnidades] = useState(originalUnidades);
    const [precioCoste, setPrecioCoste] = useState(originalPrecioCoste);
    const [precioVenta, setPrecioVenta] = useState(originalPrecioVenta);
    const [descuento, setDescuento] = useState(originalDescuento);

    const {
        userInfo: { preferencias: { usar_precios: usarPrecios } = {} },
    } = useAuthState();

    return (
        <DialogEditor
            title='Editar gasto'
            onSave={() => onSave(unidades, precioCoste, precioVenta, descuento)}
            {...props}
        >
            <div className={classes.fields}>
                <TextField
                    label='Unidades'
                    value={unidades}
                    onChange={(ev) => setUnidades(parseFloat(ev.target.value) || 0)}
                    className={classes.field}
                    type='number'
                    step='.01'
                />
                {usarPrecios && (
                    <>
                        <TextField
                            label='Precio coste'
                            value={precioCoste}
                            onChange={(ev) => setPrecioCoste(parseFloat(ev.target.value) || 0)}
                            className={classes.field}
                            type='number'
                            step='.01'
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                            }}
                        />
                        <TextField
                            label='Precio venta'
                            value={precioVenta}
                            onChange={(ev) => setPrecioVenta(parseFloat(ev.target.value) || 0)}
                            className={classes.field}
                            type='number'
                            step='.01'
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                            }}
                        />
                        <TextField
                            label='Descuento'
                            value={descuento}
                            onChange={(ev) => setDescuento(parseFloat(ev.target.value) || 0)}
                            className={classes.field}
                            type='number'
                            step='.01'
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                            }}
                        />
                    </>
                )}
            </div>
        </DialogEditor>
    );
}

GastoEditor.propTypes = {
    onSave: PropTypes.any,
    unidades: PropTypes.any,
    precioCoste: PropTypes.any,
    precioVenta: PropTypes.any,
    descuento: PropTypes.any,
};
