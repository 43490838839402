import SvgIcon from '@material-ui/core/SvgIcon';

export default function LaboryLogo(props) {
    return (
        <SvgIcon viewBox='0 0 24 24' {...props}>
            <path
                d='M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z'
                fill='#4C60FE'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.3007 4.65008C15.9068 4.90496 16.1916 5.60297 15.9367 6.20913L12.86 13.5261C12.6052 14.1323 11.9072 14.4171 11.301 14.1622L8.00834 12.7777C7.40218 12.5228 7.11741 11.8248 7.37229 11.2186C7.62717 10.6125 8.32518 10.3277 8.93134 10.5826L11.1264 11.5056L13.7416 5.28613C13.9965 4.67997 14.6945 4.3952 15.3007 4.65008Z'
                fill='white'
            />
        </SvgIcon>
    );
}
