import Chip from '@material-ui/core/Chip';
import MuiTextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import uuid from 'uuid';
import { albaranesCompraProvider } from '../../api';

const filter = createFilterOptions();

export default function TagsField({ error, helperText, disabled, tagsKey = 'tags' }) {
    const [{ value }, , { setValue }] = useField(tagsKey);
    const [tagsOptions, setTagsOptions] = useState([]);

    useEffect(() => {
        albaranesCompraProvider.getAll('revision-material/tags').then(setTagsOptions);
    }, []);

    return (
        <Autocomplete
            fullWidth
            multiple
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            disabled={disabled}
            value={value}
            onChange={(event, newValue) => {
                setValue(
                    newValue.map((tag) => {
                        if (typeof tag === 'string') {
                            const newOption = {
                                id: uuid.v4(),
                                nombre: tag,
                            };
                            setTagsOptions([...tagsOptions, newOption]);
                            return newOption;
                        } else if (tag && tag.isNew) {
                            const newOption = {
                                id: tag.id,
                                nombre: tag.nombre,
                            };
                            setTagsOptions([...tagsOptions, newOption]);
                            return newOption;
                        } else {
                            return tag;
                        }
                    }),
                );
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                    filtered.push({
                        nombre: params.inputValue,
                        id: uuid.v4(),
                        isNew: true,
                        label: `Añadir "${params.inputValue}"`,
                    });
                }

                return filtered;
            }}
            options={tagsOptions}
            getOptionLabel={(option) => option.label ?? option.nombre}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
                <MuiTextField
                    {...params}
                    error={error}
                    helperText={helperText}
                    label='Tags'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={disabled}
                    placeholder={value?.length === 0 ? 'Añade etiquetas para controlar el estado del material' : ''}
                />
            )}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => <Chip key={index} label={option.nombre} {...getTagProps({ index })} />)
            }
            renderOption={(option) => option.nombre}
        />
    );
}

TagsField.propTypes = {
    disabled: PropTypes.any,
    error: PropTypes.any,
    helperText: PropTypes.any,
    tagsKey: PropTypes.string,
};
