import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { albaranesCompraProvider } from '../../../api';
import useAuthState from '../../../AuthState';
import { addTiempo } from '../../../utils';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import useResumenJornadas from '../AlbaranList/useResumenJornadas';
import CalculoRendimientoPaper from './CalculoRendimientoPaper';
import GastosPaper from './GastosPaper';
import ManoObraPaper from './ManoObraPaper';
import MaterialesPaper from './MaterialesPaper';

const useStyles = makeStyles(
    (theme) => ({
        wrapper: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
            '&>div': {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: `${theme.spacing(2)}px`,
            },
        },
        betaVersion: {
            marginBottom: theme.spacing(4),
        },
    }),
    { name: 'BaseRendimiento' },
);
export default function BaseRendimiento({ rendimiento }) {
    const classes = useStyles();

    const [manoObraDesglosada, setManoObraDesglosada] = useState(false);
    const [materialesDesglosados, setMaterialesDesglosados] = useState(false);
    const { data: jornadas } = useEditFormData('jornadas');
    const { tiempoOperarios, tiempoCategorias, gastos, materialesFecha, materiales } = useResumenJornadas(jornadas);
    const {
        userInfo: {
            preferencias: { usar_precios: usarPrecios },
        },
    } = useAuthState();

    const [albaranesCompraMap, setAlbaranesCompraMap] = useState({
        map: {},
        albaranes: {},
    });

    useEffect(() => {
        const missingAlbaranCompraLineaIds = jornadas
            .reduce((materiales, jornada) => [...materiales, ...jornada.materiales], [])
            .map((m) => m.albaran_compra_linea_id)
            .filter(Boolean)
            .filter((id) => !albaranesCompraMap.map[id]);

        if (missingAlbaranCompraLineaIds.length > 0) {
            albaranesCompraProvider
                .getAlbaranesCompraByLineas(missingAlbaranCompraLineaIds)
                .then(setAlbaranesCompraMap);
        }
    }, []);

    const [operarioRows, operarioTotales] = useMemo(() => {
        const operarioRows = Object.entries(manoObraDesglosada ? tiempoOperarios : tiempoCategorias).map(
            ([operario, { tiempo, precioCoste, precioVenta, importeCoste, importeVenta }]) => ({
                operario,
                tiempo,
                precioCoste,
                precioVenta,
                importeCoste,
                importeVenta,
            }),
        );

        const operarioTotales = operarioRows.reduce(
            (totales, row) => ({
                tiempo: addTiempo(totales.tiempo, row.tiempo),
                importeCoste: totales.importeCoste + row.importeCoste,
                importeVenta: totales.importeVenta + row.importeVenta,
            }),
            { importeCoste: 0, importeVenta: 0, tiempo: null },
        );
        return [operarioRows, operarioTotales];
    }, [tiempoOperarios, tiempoCategorias, manoObraDesglosada]);

    const [materialesRows, materialesTotales, materialesDesglosadoTotales] = useMemo(() => {
        const materialesRows = Object.entries(materialesFecha)
            .map(([fecha, { importeCoste, importeVenta, materiales }]) => ({
                fecha,
                importeCoste,
                importeVenta,
                materiales,
            }))
            .filter((row) => row.materiales.length > 0);

        const materialesTotales = materialesRows.reduce(
            (totales, row) => ({
                importeCoste: totales.importeCoste + row.importeCoste,
                importeVenta: totales.importeVenta + row.importeVenta,
            }),
            { importeCoste: 0, importeVenta: 0 },
        );
        const materialesDesglosadoTotales = {
            importe_coste: materialesTotales.importeCoste,
            importe: materialesTotales.importeVenta,
        };
        return [materialesRows, materialesTotales, materialesDesglosadoTotales];
    }, [materialesFecha]);

    const [gastosRows, gastosTotales] = useMemo(() => {
        const gastosRows = Object.entries(gastos).map(
            ([concepto, { unidades, precioCoste, precioVenta, importeCoste, importeVenta }]) => ({
                concepto,
                unidades,
                precioCoste,
                precioVenta,
                importeCoste,
                importeVenta,
            }),
        );

        const gastosTotales = gastosRows.reduce(
            (totales, row) => ({
                unidades: totales.unidades + row.unidades,
                importeCoste: totales.importeCoste + row.importeCoste,
                importeVenta: totales.importeVenta + row.importeVenta,
            }),
            { importeCoste: 0, importeVenta: 0, unidades: 0 },
        );
        return [gastosRows, gastosTotales];
    }, [gastos]);

    return (
        <div className={classes.wrapper}>
            <div>
                <ManoObraPaper
                    rows={operarioRows}
                    totales={operarioTotales}
                    desglosado={manoObraDesglosada}
                    setDesglosado={setManoObraDesglosada}
                />
                <MaterialesPaper
                    rows={materialesDesglosados ? materiales : materialesRows}
                    totales={materialesDesglosados ? materialesDesglosadoTotales : materialesTotales}
                    desglosado={materialesDesglosados}
                    setDesglosado={setMaterialesDesglosados}
                    albaranesCompraMap={albaranesCompraMap}
                />
                <GastosPaper rows={gastosRows} totales={gastosTotales} />
            </div>
            {usarPrecios && (
                <div>
                    <CalculoRendimientoPaper
                        manoObra={operarioTotales}
                        materiales={materialesTotales}
                        gastos={gastosTotales}
                        totalComercial={rendimiento?.total_comercial ?? 0}
                        totalFacturado={rendimiento?.total_facturado ?? 0}
                    />
                </div>
            )}
        </div>
    );
}

BaseRendimiento.propTypes = {
    rendimiento: PropTypes.any,
};
