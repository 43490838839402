import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormControl } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(3),
        '& label .MuiButton-label': {
            whiteSpace: 'nowrap',
        },
    },
    fileName: {
        maxWidth: 300,
        width: 300,
        margin: theme.spacing(0, 2),
        '& div': {
            whiteSpace: 'nowrap',
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    image: {
        marginTop: theme.spacing(3),
    },
}));

export default function FileUploader({
    id,
    label,
    value,
    error,
    file,
    setFile,
    accept,
    isImage = true,
    multiple = false,
}) {
    const classes = useStyles();

    let preview = null;

    if (value) {
        if (isImage)
            preview = (
                <div className={classes.image}>
                    <img alt='Imagen subida' src={value} />
                </div>
            );
    }

    const isFileArray = Array.isArray(file) || file instanceof FileList;

    return (
        <FormControl style={{ width: '100%' }} error={Boolean(error)}>
            <InputLabel shrink>{label}</InputLabel>
            {preview}
            <div className={classes.root}>
                <label htmlFor={id}>
                    <input
                        id={id}
                        name={id}
                        style={{ display: 'none' }}
                        type='file'
                        accept={accept}
                        onChange={(ev) => setFile(multiple ? [...file, ...ev.target.files] : ev.target.files[0])}
                        // onChange={(ev) => setFile(multiple ? ev.target.files : ev.target.files[0])}
                        multiple={multiple}
                    />
                    <Button variant='outlined' component='span'>
                        Seleccionar un fichero
                    </Button>
                </label>
                <div className={classes.fileName}>
                    {file
                        ? isFileArray
                            ? [...file].map((f) => f.name).map((name, i) => <div key={i}>{name}</div>)
                            : file.name
                        : 'No hay ningun fichero seleccionado'}
                </div>
            </div>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
}

FileUploader.propTypes = {
    accept: PropTypes.any,
    file: PropTypes.any,
    id: PropTypes.any,
    isImage: PropTypes.bool,
    label: PropTypes.any,
    multiple: PropTypes.bool,
    setFile: PropTypes.any,
    value: PropTypes.any,
    error: PropTypes.any,
};
