import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import DialogForm from '../../common/forms/DialogForm';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import PropTypes from 'prop-types';
import { preparacionesMaterialProvider } from '../../../api';
import { KeyboardDatePicker, KeyboardDateTimePicker } from '../../common/fields/KeyboardDatePicker';
import { Field } from 'formik';
import SelectField from '../../common/fields/SelectField';
import { estadoCompraOptions } from '../../../api/preparaciones_material';
import { useEffect, useState } from 'react';

const CompraMaterialSchema = Yup.object().shape({
    notas: Yup.string().required('Requerido'),
});

function EditarCompraMaterialDialog({ onSave, compraMaterial: initialCompraMaterial, compraMaterialId, ...props }) {
    const [compraMaterial, setCompraMaterial] = useState(initialCompraMaterial);
    useEffect(() => {
        setCompraMaterial(initialCompraMaterial);
    }, [initialCompraMaterial]);

    useEffect(() => {
        if (!props.open || !compraMaterialId) return;

        preparacionesMaterialProvider.getCompraById(compraMaterialId).then(setCompraMaterial);
    }, [props.open, compraMaterialId]);

    if (!compraMaterial) return null;

    return (
        <DialogForm
            title='Editar compra de material'
            FormikProps={{
                initialValues: {
                    referencia: compraMaterial.referencia,
                    fecha_llegada_prevista: compraMaterial.fecha_llegada_prevista
                        ? new Date(compraMaterial.fecha_llegada_prevista)
                        : null,
                    fecha_llegada: compraMaterial.fecha_llegada ? new Date(compraMaterial.fecha_llegada) : null,
                    notas: compraMaterial.notas,
                    estado: compraMaterial.estado,
                },
                validationSchema: CompraMaterialSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    preparacionesMaterialProvider
                        .updateCompra(compraMaterial.id, values)
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ touched, errors, values, setFieldValue }) => (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField name='referencia' label='Referencia' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <Field
                            name='estado'
                            id='estado'
                            component={SelectField}
                            label='Estado'
                            fullWidth
                            selectOptions={estadoCompraOptions}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDatePicker label='Fecha prevista de llegada' name='fecha_llegada_prevista' autoOk />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDateTimePicker label='Fecha de llegada' name='fecha_llegada' autoOk />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name='notas'
                            label='Notas'
                            fullWidth
                            multiline
                            minRows={6}
                            InputLabelProps={{ shrink: true }}
                            placeholder='Escribe aqui notas como por ejemplo la lista de productos pedidos'
                        />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(EditarCompraMaterialDialog);

EditarCompraMaterialDialog.propTypes = {
    compraMaterial: PropTypes.any,
    compraMaterialId: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};
