import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import CopyIcon from '@material-ui/icons/FileCopy';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { presupuestosProvider } from '../../api';
import { estadoCompraLabels } from '../../api/preparaciones_material';
import { dataProvider, estadoPresupuestoOptions } from '../../api/presupuestos';
import { useFetchData } from '../../hooks/useFetchData';
import { formatCurrency, formatDate } from '../../utils';
import Button from '../common/Button';
import { DateColumnFilter, dateFilterFn } from '../common/list/DateColumnFilter';
import BaseListView, { withTableState } from '../common/list/ListView';
import { SelectColumnFilter } from '../common/list/SelectColumnFilter';
import NewPresupuestoDialog, { NewPresupuestoDialogBase } from './NewPresupuestoDialog';
import PresupuestoEstadoChip from './PresupuestoEstadoChip';

const ListView = withTableState('presupuestos', BaseListView);

export default function PresupuestosList() {
    const fetchDataFn = useCallback(() => presupuestosProvider.getAll(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);
    const [duplicarPresupuesto, setDuplicarPresupuesto] = useState(null);

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        dataProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Numero',
                accessor: 'numero',
            },
            {
                Header: 'Ref. Interna',
                accessor: 'referencia_interna',
            },
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
            {
                Header: 'Cliente',
                accessor: 'cliente',
            },
            {
                Header: 'Dieccion',
                accessor: 'direccion',
            },
            {
                Header: 'Proyecto',
                accessor: 'proyecto',
            },
            {
                Header: 'Estado',
                accessor: 'estado',
                // eslint-disable-next-line react/prop-types
                Cell: ({ value }) => <PresupuestoEstadoChip value={value} />,
                Filter: SelectColumnFilter,
                filter: 'equals',
                filterOptions: {
                    options: estadoPresupuestoOptions,
                    includeAllOption: {
                        value: 'todos',
                        label: 'Todos',
                    },
                },
                exportValue: ({ value }) => estadoCompraLabels[value],
            },
            {
                Header: 'Fecha creacion',
                accessor: 'fecha_creacion',
                Cell: ({ value }) => formatDate(value),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Fecha envio',
                accessor: 'fecha_envio',
                Cell: ({ value }) => (value ? formatDate(value) : 'Sin fecha'),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Fecha final',
                accessor: 'fecha_final',
                Cell: ({ value }) => (value ? formatDate(value) : 'Sin fecha'),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Importe',
                accessor: 'importe_venta',
                Cell: ({ value }) => formatCurrency(value),
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            deleteConfirmationText: 'borrar presupuesto',
            hideEdit: false,
        }),
        [],
    );

    return (
        <>
            <NewPresupuestoDialogBase
                open={Boolean(duplicarPresupuesto)}
                duplicarPresupuesto={duplicarPresupuesto}
                onClose={() => setDuplicarPresupuesto(null)}
                onSave={() => {
                    setDuplicarPresupuesto(null);
                    fetchData();
                }}
            />
            <ListView
                tableTitle='Lista de presupuestos'
                title='Presupuestos'
                titleActions={
                    <NewPresupuestoDialog
                        button={
                            <Button color='info' aria-label='Nuevo presupuesto' startIcon={<AddIcon />}>
                                Nuevo presupuesto
                            </Button>
                        }
                        onSave={() => fetchData()}
                    />
                }
                basePath='/presupuestos'
                columns={columns}
                data={data}
                options={options}
                onDelete={onDelete}
                extraActions={(row) => (
                    <Tooltip title='Duplicar presupuesto'>
                        <IconButton onClick={() => setDuplicarPresupuesto(row)}>
                            <CopyIcon />
                        </IconButton>
                    </Tooltip>
                )}
            />
        </>
    );
}
