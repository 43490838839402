import List from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import { endOfDay, startOfDay } from 'date-fns';
import endOfWeek from 'date-fns/endOfWeek';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import esES from 'date-fns/locale/es';
import startOfWeek from 'date-fns/startOfWeek';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { format } from '../../../utils';
import Button from '../Button';
import { DateRangePicker } from '../fields/DateRangePickerDialog';

const ListItem = withStyles(
    (theme) => ({
        button: {
            padding: theme.spacing(0.25, 2),
            '&:hover': {
                borderRadius: 4,
            },
        },
    }),
    { name: 'ListItem' },
)(MuiListItem);

const ListItemText = withStyles(
    (theme) => ({
        primary: {
            fontSize: theme.typography.subtitle1.fontSize,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                fontSize: 18,
            },
        },
    }),
    { name: 'ListItemText' },
)(MuiListItemText);

const useStyles = makeStyles(
    (theme) => ({
        paper: {},
        content: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        dateRangePicker: {
            padding: theme.spacing(2),
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            padding: theme.spacing(1, 2, 2, 2),
            gap: theme.spacing(2),
        },
        button: {
            fontSize: 12,
            fontWeight: 'normal',
            padding: theme.spacing(2, 0),
            '&:hover .MuiButton-label': {
                textDecoration: 'underline',
            },
        },
    }),
    { name: 'DateColumnFilter' },
);

export function dateFilterFn(rows, id, filterValue) {
    if (!filterValue) return rows;

    const from = filterValue.from ? new Date(filterValue.from) : null;
    const to = filterValue.to ? new Date(filterValue.to) : null;

    return rows.filter(
        (row) =>
            (filterValue.empty === true && row.values[id] === null) ||
            (from !== null &&
                to !== null &&
                !isBefore(new Date(row.values[id]), from) &&
                !isAfter(new Date(row.values[id]), to)),
    );
}

export function DateColumnFilter({ column }) {
    const classes = useStyles();
    const { filterValue, setFilter, filterOptions: { includeEmpty = false } = {} } = column;

    const [personalizar, setPersonalizar] = useState(false);
    const [dateRange, setDateRange] = useState(filterValue);
    const [datePickerAnchorEl, setDatePickerAnchorEl] = useState(null);
    const open = Boolean(datePickerAnchorEl);

    function resetFilter() {
        setDateRange({
            from: filterValue?.from ? new Date(filterValue.from) : null,
            to: filterValue?.to ? new Date(filterValue.to) : null,
        });
    }

    useEffect(() => {
        if (!open) return;

        resetFilter();
    }, [open]);

    const onClose = () => {
        setDatePickerAnchorEl(null);
        setTimeout(() => {
            setPersonalizar(false);
        }, 500);
    };

    const rangeShortcuts = [
        {
            label: 'Hoy',
            getDateRange: () => {
                const today = new Date();
                return { from: startOfDay(today), to: endOfDay(today) };
            },
        },
        {
            label: 'Ayer',
            getDateRange: () => {
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                return { from: startOfDay(yesterday), to: endOfDay(yesterday) };
            },
        },
        {
            label: 'Mañana',
            getDateRange: () => {
                const tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1);
                return { from: startOfDay(tomorrow), to: endOfDay(tomorrow) };
            },
        },
        {
            label: 'Esta semana',
            getDateRange: () => {
                const today = new Date();
                const lastWeek = new Date(today);
                lastWeek.setDate(today.getDate());
                return {
                    from: startOfDay(startOfWeek(lastWeek, { locale: esES })),
                    to: endOfDay(endOfWeek(lastWeek, { locale: esES })),
                };
            },
        },
        {
            label: 'Semana pasada',
            getDateRange: () => {
                const today = new Date();
                const lastWeek = new Date(today);
                lastWeek.setDate(today.getDate() - 7);
                return {
                    from: startOfDay(startOfWeek(lastWeek, { locale: esES })),
                    to: endOfDay(endOfWeek(lastWeek, { locale: esES })),
                };
            },
        },
        {
            label: 'Próxima semana',
            getDateRange: () => {
                const today = new Date();
                const nextWeek = new Date(today);
                nextWeek.setDate(today.getDate() + 7);
                return {
                    from: startOfDay(startOfWeek(nextWeek, { locale: esES })),
                    to: endOfDay(endOfWeek(nextWeek, { locale: esES })),
                };
            },
        },
        {
            label: 'Este mes',
            getDateRange: () => {
                const today = new Date();
                return {
                    from: startOfDay(new Date(today.getFullYear(), today.getMonth(), 1)),
                    to: endOfDay(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
                };
            },
        },
        {
            label: 'Mes pasado',
            getDateRange: () => {
                const today = new Date();
                const lastMonth = new Date(today);
                lastMonth.setMonth(today.getMonth() - 1);
                return {
                    from: startOfDay(new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1)),
                    to: endOfDay(new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0)),
                };
            },
        },
        {
            label: 'Próximo mes',
            getDateRange: () => {
                const today = new Date();
                const nextMonth = new Date(today);
                nextMonth.setMonth(today.getMonth() + 1);
                return {
                    from: startOfDay(new Date(nextMonth.getFullYear(), nextMonth.getMonth(), 1)),
                    to: endOfDay(new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 0)),
                };
            },
        },
        {
            label: 'Este año',
            getDateRange: () => {
                const today = new Date();
                return {
                    from: startOfDay(new Date(today.getFullYear(), 0, 1)),
                    to: endOfDay(new Date(today.getFullYear(), 11, 31)),
                };
            },
        },
        {
            label: 'Año pasado',
            getDateRange: () => {
                const today = new Date();
                const lastYear = new Date(today);
                lastYear.setFullYear(today.getFullYear() - 1);
                return {
                    from: startOfDay(new Date(lastYear.getFullYear(), 0, 1)),
                    to: endOfDay(new Date(lastYear.getFullYear(), 11, 31)),
                };
            },
        },
    ];

    return (
        <>
            <Button
                color='transparent'
                onClick={(e) => setDatePickerAnchorEl({ element: e.currentTarget })}
                className={classes.button}
            >
                {filterValue && filterValue.from && filterValue.to
                    ? `${format(filterValue.from, 'dd/MM')} - ${format(filterValue.to, 'dd/MM/yyyy')}`
                    : filterValue?.empty === true
                    ? 'Sin fecha'
                    : 'Seleccionar rango'}
            </Button>
            <Popover
                open={open}
                anchorEl={datePickerAnchorEl?.element}
                onClose={() => setDatePickerAnchorEl(null)}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Paper className={classes.paper}>
                    <div className={classes.content}>
                        {personalizar && (
                            <DateRangePicker
                                dateRange={dateRange}
                                setDateRange={setDateRange}
                                className={classes.dateRangePicker}
                            />
                        )}
                        <List disablePadding style={{ marginTop: personalizar ? 16 : 0 }}>
                            {!personalizar && filterValue && (
                                <ListItem
                                    button
                                    divider
                                    onClick={() => {
                                        setFilter(undefined);
                                        onClose();
                                    }}
                                >
                                    <ListItemText primary='Quitar filtro' />
                                </ListItem>
                            )}
                            {!personalizar && includeEmpty && (
                                <ListItem
                                    button
                                    onClick={() => {
                                        setFilter({ empty: true });
                                        onClose();
                                    }}
                                    divider
                                >
                                    <ListItemText primary='Sin fecha' />
                                </ListItem>
                            )}
                            {rangeShortcuts.map((shortcut, i) => (
                                <ListItem
                                    button
                                    key={i}
                                    onClick={() => {
                                        const range = shortcut.getDateRange();
                                        if (personalizar) setDateRange(range);
                                        else {
                                            setFilter(shortcut.getDateRange());
                                            onClose();
                                        }
                                    }}
                                    divider={!personalizar && i === rangeShortcuts.length - 1}
                                >
                                    <ListItemText primary={shortcut.label} />
                                </ListItem>
                            ))}
                            {!personalizar && (
                                <ListItem button onClick={() => setPersonalizar(true)}>
                                    <ListItemText primary='Personalizado' />
                                </ListItem>
                            )}
                        </List>
                    </div>
                    {personalizar && (
                        <div className={classes.actions}>
                            <Button
                                color='outlined'
                                onClick={() => {
                                    setFilter(undefined);
                                    onClose();
                                }}
                            >
                                Quitar filtro
                            </Button>
                            <Button
                                color='outlined'
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                color='info'
                                onClick={() => {
                                    onClose();
                                    setFilter(dateRange);
                                }}
                                disabled={!dateRange || !dateRange.to}
                            >
                                Aceptar
                            </Button>
                        </div>
                    )}
                </Paper>
            </Popover>
        </>
    );
}

DateColumnFilter.propTypes = {
    column: PropTypes.any,
};
