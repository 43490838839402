import PropTypes from 'prop-types';
import ChecklistDetails from './ChecklistDetails';
import ResumenChecklists from './ResumenChecklists';

export default function MultiChecklistDetails({ index }) {
    if (index === 0) {
        return <ResumenChecklists />;
    }

    return <ChecklistDetails index={index - 1} />;
}

MultiChecklistDetails.propTypes = {
    index: PropTypes.number.isRequired,
};
