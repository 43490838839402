import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiTextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import clsx from 'clsx';
import { Field, useField } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { clientesProvider } from '../../../api';
import { NewDireccionDialogButton } from '../../clientes/direcciones/NewDireccionDialog';
import ValidarDireccionDialog from '../../clientes/direcciones/ValidarDireccionDialog';
import Button from '../Button';
import { useHasChanged } from './DateRangePickerNew';

const useStyles = makeStyles(
    (theme) => ({
        direccionHelper: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 13,
            fontWeight: 400,
            color: theme.palette.neutral.grey4,
            '&.no_validada': {
                color: theme.palette.error.main,
            },
            '&.validada': {
                color: theme.palette.success.main,
            },
            '& svg': {
                fontSize: 16,
                marginRight: theme.spacing(0.5),
            },
            '& .MuiButton-root .MuiSvgIcon-root': {
                fontSize: 15,
            },
            '& .MuiButton-root': {
                marginLeft: theme.spacing(0.5),
                padding: theme.spacing(0.5),
                fontSize: 13,
                height: 24,
            },
        },
        verNotas: {
            color: theme.palette.primary.main,
            fontWeight: 500,
            marginLeft: theme.spacing(0.5),
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        verNotasTooltip: {
            whiteSpace: 'pre-line',
        },
        addButton: {
            '$direccionHelper &': {
                marginLeft: 'auto',
            },
        },
    }),
    { name: 'DireccionField' },
);

export default function DireccionField({ error, helperText, disabled }) {
    const classes = useStyles();
    const [direccionesOptions, setDireccionesOptions] = useState([]);
    const [{ value: direccion }, , { setValue: setDireccionValue }] = useField('direccion');
    const [{ value: cliente }, ,] = useField('cliente');

    const snackbar = useSnackbar();

    const clienteId = cliente?.id;
    const direccionId = direccion?.id;

    const isValidada = Boolean(direccion && direccion.google_place_id);

    const clienteHasChanged = useHasChanged(clienteId);

    function queryDireccionOptions(overrideId) {
        if (!clienteId) {
            setDireccionesOptions([]);
            return;
        }

        clientesProvider
            .actionOnId(clienteId, 'direcciones/as_options', null, { method: 'get' })
            .then((direcciones) => {
                setDireccionesOptions(direcciones);

                const newDireccionId =
                    overrideId ??
                    (clienteHasChanged ? (direcciones.length === 1 ? direcciones[0].id : null) : direccionId);
                setDireccionValue(newDireccionId ? direcciones.find((d) => d.id === newDireccionId) : null);
            });
    }

    useEffect(queryDireccionOptions, [clienteId]);

    const inputHelperText = isValidada ? (
        <>
            <CheckCircleIcon />
            Esta dirección está validada en Google Maps
        </>
    ) : (
        <>
            {/* <InfoIcon /> */}
            Esta dirección no está validada en Google Maps
            <ValidarDireccionDialog
                currentDireccionCompleta={direccion && direccion.direccion_completa}
                button={<Button color='primary'>Validar dirección</Button>}
                onSave={(result) => {
                    const params = {
                        google_place_id: result.placeId,
                        ...result.address,
                    };

                    clientesProvider
                        .actionOnId(clienteId, `direcciones/${direccion.id}`, params)
                        .then((result) => {
                            snackbar.showMessage('La dirección se ha validado con éxito');
                            setDireccionesOptions((options) =>
                                options.map((op) => (op.id === result.id ? result : op)),
                            );
                            setDireccionValue(result);
                        })
                        .catch(() => {
                            snackbar.showMessage('Se ha producido un error al actualizar la dirección');
                        });
                }}
            />
        </>
    );

    return (
        <div>
            <Field
                name='direccion'
                fullWidth
                component={Autocomplete}
                options={direccionesOptions}
                getOptionSelected={(option, value) => option.id === value?.id}
                getOptionLabel={(option) => `${option.nombre} - ${option.direccion_completa}`}
                getOptionDisabled={(option) => !option.activo}
                renderOption={(option, { selected }) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {option.nombre}
                        <span style={{ fontSize: '80%', opacity: 0.6 }}>{option.direccion_completa}</span>
                    </div>
                )}
                disabled={disabled}
                renderInput={(params) => (
                    <MuiTextField
                        {...params}
                        error={error}
                        helperText={helperText instanceof Object ? null : helperText}
                        label='Dirección'
                    />
                )}
            />
            <div className={clsx(classes.direccionHelper, isValidada ? 'validada' : 'no_validada')}>
                {direccion !== null ? (
                    inputHelperText
                ) : (
                    <NewDireccionDialogButton
                        clienteId={clienteId}
                        button={
                            <Button
                                color='primary'
                                startIcon={<AddIcon />}
                                disabled={!clienteId}
                                className={classes.addButton}
                            >
                                Añadir dirección
                            </Button>
                        }
                        onSave={(res) => {
                            queryDireccionOptions(res.id);
                        }}
                    />
                )}
            </div>
            {direccion && direccion.notas !== null && direccion.notas !== '' && (
                <div className={clsx(classes.direccionHelper)}>
                    <InfoIcon />
                    Esta dirección tiene notas
                    <Tooltip arrow title={direccion.notas ?? ''} classes={{ tooltip: classes.verNotasTooltip }}>
                        <span className={classes.verNotas}>Ver notas</span>
                    </Tooltip>
                </div>
            )}
        </div>
    );
}

DireccionField.propTypes = {
    disabled: PropTypes.any,
    error: PropTypes.any,
    helperText: PropTypes.any,
};
