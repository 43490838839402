import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopy';
import addMinutes from 'date-fns/addMinutes';
import isBefore from 'date-fns/isBefore';
import { FieldArray, useField } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { setBeginOfDay } from '../../../../../utils';
import { SwitchWithLabelField } from '../../../../common/fields/Switch';
import CopiarHorariosDiaDialog from './CopiarHorariosDiaDialog';
import MarcajeDiaField from './MarcajeDiaField';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'flex-start',
            marginTop: theme.spacing(1),
            '& .MuiFormControlLabel-root': {
                height: 36,
                width: 100,
            },
        },
        horas: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        marcajes: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(1)}px`,
        },
        timePicker: {
            '& .MuiInputBase-input': {
                width: 36,
                fontSize: theme.typography.subtitle2.fontSize,
            },
            '& .MuiInputAdornment-positionEnd': {
                marginLeft: 0,
                display: 'none',
            },
        },
        actions: {
            display: 'flex',
            marginLeft: 'auto',
            '& .MuiIconButton-root': {
                padding: 8,
            },
            width: 72,
        },
        error: {
            color: theme.palette.error.main,
        },
    }),
    { name: 'HorariosDiaField' },
);

export default function HorariosDiaField({ name, diaIndex, label, onCopy }) {
    const classes = useStyles();
    const [{ value }, { error }, { setValue }] = useField(name);

    useEffect(() => {
        if (value.activo) {
            if (value.horario.length === 0) {
                setValue({
                    ...value,
                    horario: [
                        {
                            hora_entrada: new Date(),
                            hora_salida: new Date(),
                        },
                    ],
                    total_minutos: setBeginOfDay(new Date()),
                });
            }
        } else if (!value.activo) {
            setValue({
                ...value,
                horario: [],
            });
        }
    }, [value.activo, value.horario.length]);

    const flattenedErrors = useMemo(() => {
        function flatten(obj) {
            let result = [];
            for (const key in obj) {
                if (typeof obj[key] === 'string') {
                    result.push(obj[key]);
                } else if (Array.isArray(obj[key])) {
                    obj[key].forEach((item) => {
                        result = result.concat(flatten(item));
                    });
                } else if (typeof obj[key] === 'object') {
                    result = result.concat(flatten(obj[key]));
                }
            }
            return result;
        }

        return flatten(error || {});
    }, [error]);

    function updateTotalMinutos() {
        let total = setBeginOfDay(new Date());

        value.horario.forEach((horario, i) => {
            if (i > 0 && isBefore(horario.hora_entrada, value.horario[i - 1].hora_salida)) {
                horario.hora_entrada = new Date(value.horario[i - 1].hora_salida);
            }

            if (isBefore(horario.hora_salida, horario.hora_entrada)) {
                horario.hora_salida = new Date(horario.hora_entrada);
                return;
            }

            const diffMinutos = Math.floor((horario.hora_salida - horario.hora_entrada) / 60000);

            total = addMinutes(total, diffMinutos);
        });
        setValue({
            ...value,
            total_minutos: total,
            max_minutos: total,
        });
    }

    return (
        <div className={classes.root}>
            <SwitchWithLabelField name={`${name}.activo`} label={label} labelVariant='subtitle1' />
            <div className={classes.horas}>
                <FieldArray
                    name={`${name}.horario`}
                    render={({ push, remove }) => (
                        <>
                            {value.horario.map((horario, index) => (
                                <div key={index} className={classes.marcajes}>
                                    <MarcajeDiaField
                                        name={name}
                                        index={index}
                                        showTotal={index === value.horario.length - 1}
                                        onUpdate={updateTotalMinutos}
                                    />
                                    <div className={classes.actions}>
                                        {index === 0 ? (
                                            <>
                                                <CopiarHorariosDiaDialog
                                                    button={
                                                        <IconButton>
                                                            <CopyIcon fontSize='small' />
                                                        </IconButton>
                                                    }
                                                    selectedDia={diaIndex}
                                                    onAccept={onCopy}
                                                />
                                                <IconButton
                                                    onClick={() => {
                                                        const lastHorario = value.horario[value.horario.length - 1];
                                                        push({
                                                            hora_entrada: new Date(lastHorario.hora_salida),
                                                            hora_salida: new Date(lastHorario.hora_salida),
                                                        });
                                                    }}
                                                >
                                                    <AddIcon fontSize='small' />
                                                </IconButton>
                                            </>
                                        ) : (
                                            <IconButton onClick={() => remove(index)}>
                                                <DeleteIcon fontSize='small' />
                                            </IconButton>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                />
                {flattenedErrors.map((error, index) => (
                    <Typography key={index} variant='subtitle2' className={classes.error}>
                        {error}
                    </Typography>
                ))}
            </div>
        </div>
    );
}

HorariosDiaField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    diaIndex: PropTypes.number.isRequired,
    onCopy: PropTypes.func,
};
