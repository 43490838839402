import DialogEditor from '../common/forms/DialogEditor';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '../common/Button';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InfoIcon from '@material-ui/icons/Info';
import { tareasProvider } from '../../api';
import DataProvider from '../../api/DataProvider';

const stateLabels = {
    PENDING: 'Procesando',
    SUCCESS: 'Terminado',
    FAILURE: 'Error',
};

const useStyles = makeStyles(
    (theme) => ({
        row: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(1)}px`,
        },
        col: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: `${theme.spacing(1)}px`,
        },
        icon: {
            fontSize: 18,
            alignSelf: 'flex-start',
            marginTop: 4,
        },
    }),
    { name: 'DescargaFotosDialog' },
);

function DescargaFotosDialog({ id, ...props }) {
    const classes = useStyles();
    const open = props.open;
    const [startingDownload, setStartingDownload] = useState(false);
    const [status, setStatus] = useState(null);
    const [numFotos, setNumFotos] = useState(0);
    const updateStatusRef = useRef(false);

    useEffect(() => {
        tareasProvider.actionOnId(id, 'tiene-fotos', null, { method: 'get' }).then((res) => {
            setNumFotos(res.num_fotos);
        });
    }, []);

    useEffect(() => {
        if (!open) {
            setStatus(null);
            setStartingDownload(false);
            if (updateStatusRef.current) clearInterval(updateStatusRef.current);
        }
    }, [open]);

    useEffect(() => {
        if (!startingDownload) {
            return;
        }

        tareasProvider.action(`${id}/fotos/backup`, { method: 'post' }).then((res) => {
            function updateTaskStatus() {
                const dataProvider = new DataProvider('backup-status', null);
                dataProvider.action(res.task_id).then((status) => {
                    setStatus(status);
                    setStartingDownload(false);

                    if (status.state === 'SUCCESS') clearInterval(updateStatusRef.current);
                });
            }
            updateStatusRef.current = setInterval(updateTaskStatus, 2000);
        });
    }, [id, startingDownload]);

    return (
        <DialogEditor title='Descargar fotos del servicio' maxWidth='xs' cancelButtonText='Cerrar' {...props}>
            {status ? (
                <>
                    <div className={classes.row}>
                        <Typography variant='subtitle1'>Estado de la descarga: {stateLabels[status.state]}</Typography>
                        {status.state === 'PENDING' && <CircularProgress size={16} />}
                    </div>
                    {status.url ? (
                        <Button color='info' aria-label='Descargar' href={status.url} component={Link}>
                            Descargar fichero ZIP
                        </Button>
                    ) : (
                        <div className={classes.row}>
                            <InfoIcon className={classes.icon} />
                            <Typography variant='subtitle1'>
                                Si no quieres esperar, puedes cerrar esta ventana. Cuando esté lista te enviaremos un
                                email con un enlace de descarga.
                            </Typography>
                        </div>
                    )}
                </>
            ) : startingDownload ? (
                <div className={classes.row}>
                    <CircularProgress size={16} />
                    <Typography variant='subtitle1'>Se está iniciando la descarga de las fotos.</Typography>
                </div>
            ) : (
                <div className={classes.col}>
                    <Typography variant='subtitle1'>
                        {numFotos > 0
                            ? `Este servicio tiene ${numFotos} fotos. ¿Deseas descargarlas?`
                            : 'No hay fotos que descargar'}
                    </Typography>
                    {numFotos > 0 && (
                        <Button color='info' onClick={() => setStartingDownload(true)}>
                            Iniciar descarga
                        </Button>
                    )}
                </div>
            )}
        </DialogEditor>
    );
}

export default withButtonOpener(DescargaFotosDialog);

DescargaFotosDialog.propTypes = {
    id: PropTypes.any,
    open: PropTypes.any,
};
