import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            borderRadius: 4,
            color: 'white',
            padding: theme.spacing(0, 1),
            whiteSpace: 'nowrap',
            textTransform: 'uppercase',
            width: 'fit-content',
            backgroundColor: theme.palette.error.main,
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
    }),
    { name: 'ServicioFacturadoChip' },
);

export default function ServicioUrgenteChip({ style }) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root)} style={style}>
            <Typography variant='subtitle2'>Urgente</Typography>
        </div>
    );
}

ServicioUrgenteChip.propTypes = {
    style: PropTypes.object,
};
