import { Paper } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Button from '../Button';

const useStyles = makeStyles(
    (theme) => ({
        title: {
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.divider}`,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            '& .MuiTypography-root': {
                flex: 1,
                fontWeight: theme.typography.fontWeightMedium,
                lineHeight: '50px',
            },
            '& .MuiIconButton-root': {
                padding: 8,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        body: {
            padding: theme.spacing(2),
        },
        textarea: {
            '& .MuiInput-root': {
                fontSize: 14,
            },
            '& .Mui-disabled': {
                color: theme.palette.text.primary,
            },
            '& .MuiInput-underline.Mui-disabled::before': {
                borderWidth: 0,
            },
        },
        actions: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
    { name: 'PaperTextEditor' },
);

export default function PaperTextEditor({
    title,
    value: initialValue,
    onSave,
    placeholder = '',
    className,
    extraActions,
    canEdit = true,
    forceEdit = false,
    children,
}) {
    const classes = useStyles();

    const [editing, setEditing] = useState(forceEdit);

    const [value, setValue] = useState(initialValue || '');

    const initialRows = Math.max(4, initialValue ? initialValue.split('\n').length : 0);

    useEffect(() => {
        setValue(initialValue || '');
        setEditing(false);
    }, [initialValue]);

    return (
        <Paper elevation={0} className={className}>
            <div className={classes.title}>
                <Typography component='div' variant='body1'>
                    {title}
                </Typography>
                {extraActions}
                {!editing && canEdit && !forceEdit && (
                    <IconButton onClick={() => setEditing(true)}>
                        <EditIcon />
                    </IconButton>
                )}
            </div>
            {children}
            <div className={classes.body}>
                <TextField
                    fullWidth
                    multiline
                    minRows={initialRows}
                    value={value}
                    disabled={!editing && !forceEdit}
                    placeholder={placeholder}
                    onChange={(ev) => setValue(ev.target.value)}
                    className={classes.textarea}
                />
            </div>
            <Collapse in={editing || (forceEdit && value !== initialValue)}>
                <div className={classes.actions}>
                    <Button
                        color='secondary'
                        onClick={() => {
                            setEditing(false);
                            setValue(initialValue);
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button color='primary' onClick={() => onSave(value)}>
                        Guardar
                    </Button>
                </div>
            </Collapse>
        </Paper>
    );
}

PaperTextEditor.propTypes = {
    canEdit: PropTypes.bool,
    children: PropTypes.any,
    className: PropTypes.string,
    extraActions: PropTypes.any,
    forceEdit: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    title: PropTypes.string.isRequired,
    value: PropTypes.string,
};
