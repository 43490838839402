import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { clientesProvider, tareasProvider } from '../../api';
import { useFetchData } from '../../hooks/useFetchData';
import { formatDate } from '../../utils';
import { ButtonDialog } from '../common/dialogs/ButtonDialog';
import { DateColumnFilter, dateFilterFn } from '../common/list/DateColumnFilter';
import ListView from '../common/list/ListView';
import { ButtonFotoSlideshow } from './FotoSlideshow';

const useStyles = makeStyles((theme) => ({
    textoCell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 200,
        display: 'block',
    },
}));

export function ObservacionesList() {
    const classes = useStyles();
    const { id: clienteId } = useParams();

    const fetchDataFn = useCallback(() => clientesProvider.getObservaciones(clienteId), [clienteId]);
    const { data, fetchData, setData } = useFetchData(fetchDataFn);

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        clientesProvider
            .deleteObservacion(clienteId, idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Servicio',
                accessor: 'tarea',
            },
            {
                Header: 'Direccion',
                accessor: 'direccion',
            },
            {
                Header: 'Proyecto',
                accessor: 'proyecto',
            },
            {
                Header: 'Operario',
                accessor: 'operario',
            },
            {
                Header: 'Fecha',
                accessor: 'fecha',
                Cell: ({ value }) => formatDate(value),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Observaciones',
                accessor: 'texto',
                // eslint-disable-next-line react/prop-types
                Cell: ({ value }) => (
                    <span className={classes.textoCell} title={value}>
                        {value}
                    </span>
                ),
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            addRoute: null,
            useActions: true,
            tableOptions: {
                usePagination: false,
                useSorting: false,
            },
        }),
        [],
    );

    return (
        <ListView
            tableTitle='Observaciones'
            basePath={`/clientes/${clienteId}/observaciones`}
            columns={columns}
            data={data}
            options={options}
            onDelete={onDelete}
            extraActions={(row, rowIdx, original) => (
                <>
                    {row.texto !== '' && (
                        <ObservacionDialog
                            button={
                                <IconButton style={{ padding: 6 }}>
                                    <VisibilityIcon fontSize='small' />
                                </IconButton>
                            }
                            texto={row.texto}
                            tooltip='Ver notas'
                        />
                    )}
                    {row.fotos.length > 0 && (
                        <ButtonFotoSlideshow
                            button={
                                <IconButton style={{ padding: 6 }}>
                                    <PhotoLibraryIcon fontSize='small' />
                                </IconButton>
                            }
                            fotos={row.fotos}
                            tooltip='Ver fotos'
                            onDelete={(fotoIdx) => {
                                clientesProvider
                                    .actionOnId(clienteId, `observaciones/fotos/${row.fotos[fotoIdx].id}`, null, {
                                        method: 'delete',
                                    })
                                    .then((newRow) =>
                                        setData((data) => data.map((r, i) => (i === rowIdx ? newRow : r))),
                                    );
                            }}
                        />
                    )}
                    <ButtonDialog
                        button={
                            <IconButton style={{ padding: 6 }} aria-label='Borrar'>
                                <DeleteIcon fontSize='small' />
                            </IconButton>
                        }
                        tooltip='Borrar'
                        dialogTitle='Borrar observaciones'
                        dialogText='Esta operación no se puede deshacer. ¿Seguro que deseas continuar?'
                        onConfirm={() => {
                            tareasProvider
                                .deleteJornadaNotas(row.id)
                                .then(() => setData((data) => data.filter((r, i) => i !== rowIdx)))
                                .catch((err) => {
                                    console.log(err);
                                    snackbar.showMessage(err.body.message);
                                });
                        }}
                    />
                </>
            )}
        />
    );
}

export function ObservacionDialog({ button, tooltip, texto }) {
    const [open, setOpen] = useState(false);

    let formButton = React.cloneElement(button, { onClick: () => setOpen(true) });

    if (tooltip) {
        formButton = <Tooltip title={tooltip}>{formButton}</Tooltip>;
    }

    return (
        <>
            {formButton}
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'md'} style={{ padding: 0 }}>
                <DialogTitle>Observaciones del operario</DialogTitle>
                <DialogContent>
                    <div style={{ whiteSpace: 'pre-line', marginBottom: 8 }}>{texto}</div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color='primary'>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

ObservacionDialog.propTypes = {
    button: PropTypes.any,
    texto: PropTypes.any,
    tooltip: PropTypes.any,
};
