import MuiTextField from '@material-ui/core/TextField';
import { Field, useField } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { companiesProvider } from '../../../api';

export default function TipoHoraField({ name = 'tipo_hora', label = 'Tipo de hora' }) {
    const [tiposHora, setTiposHora] = useState([]);
    const [{ value }, , { setValue }] = useField(name);

    useEffect(() => {
        companiesProvider.getTiposHoraAsOptions().then(setTiposHora);
    }, []);

    useEffect(() => {
        if (tiposHora.length === 0) return;

        setValue(value ? tiposHora.find((d) => d.id === value.id) : null);
    }, [value, tiposHora]);

    return (
        <Field
            name={name}
            fullWidth
            component={Autocomplete}
            options={tiposHora}
            getOptionSelected={(option, value) => option.id === value?.id}
            getOptionLabel={(option) => `[${option.codigo ? `${option.codigo}] ` : ''}${option.nombre}` ?? ''}
            renderInput={(params) => (
                <MuiTextField
                    {...params}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label={label}
                    placeholder={!value ? 'No especificado' : ''}
                />
            )}
        />
    );
}

TipoHoraField.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
};
