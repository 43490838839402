import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { tareasProvider } from '../../../api';
import { dataProvider, estadoIncidenciaOptions } from '../../../api/tareas';
import useAuthState from '../../../AuthState';
import { useFetchData } from '../../../hooks/useFetchData';
import { formatDate } from '../../../utils';
import { DateColumnFilter, dateFilterFn } from '../../common/list/DateColumnFilter';
import BaseListView, { withTableState } from '../../common/list/ListView';
import { SelectColumnFilter } from '../../common/list/SelectColumnFilter';
import { ClienteLinkCell } from '../../dashboard/ClienteLink';
import JornadaDrawer from '../JornadaDrawer';
import IncidenciaEstadoChip from './IncidenciaEstadoChip';

const ListView = withTableState('incidencias', BaseListView);

export default function IncidenciasList() {
    const fetchDataFn = useCallback(() => tareasProvider.getIncidencias(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const [drawerJornada, setDrawerJornada] = useState(false);

    const toggleDrawerJornada = (jornada) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawerJornada(jornada);
    };

    const onDelete = (idx) => {
        dataProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const {
        userInfo: { preferencias: { usar_referencias_tareas: usarReferencias } = {} },
    } = useAuthState();

    const columns = useMemo(() => {
        const columns = [];

        if (usarReferencias)
            columns.push({
                Header: 'Ref. interna',
                accessor: 'referencia_interna',
            });

        columns.push(
            ...[
                {
                    Header: 'Referencia',
                    id: 'numero',
                    accessor: (row) => (row.numero ? row.numero.toString().padStart(4, '0') : 'N/D'),
                },
                {
                    Header: 'Título',
                    accessor: 'descripcion',
                },
                {
                    Header: 'Cliente',
                    accessor: 'cliente',
                    Cell: ClienteLinkCell,
                },
                {
                    Header: 'Direccion',
                    accessor: 'direccion',
                },
                {
                    Header: 'Fecha',
                    accessor: 'fecha',
                    Cell: ({ value }) => formatDate(new Date(value)),
                    Filter: DateColumnFilter,
                    filter: dateFilterFn,
                },
                {
                    Header: 'Operario',
                    accessor: 'operario',
                },
                {
                    Header: 'Estado',
                    accessor: 'estado',
                    Cell: IncidenciaEstadoChip,
                    Filter: SelectColumnFilter,
                    filter: 'equals',
                    filterOptions: {
                        options: estadoIncidenciaOptions,
                        includeAllOption: {
                            value: 'todos',
                            label: 'Todos',
                        },
                    },
                },
                {
                    Header: 'Contenido',
                    accessor: 'nota',
                },
            ],
        );

        return columns;
    }, [usarReferencias]);

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            hideDelete: true,
            // tableOptions: {
            //     expandedTable: false,
            // },
        }),
        [],
    );

    return (
        <>
            <JornadaDrawer jornada={drawerJornada} onClose={toggleDrawerJornada(false)} onUpdate={fetchData} />
            <ListView
                tableTitle='Lista de incidencias'
                basePath='/incidencias'
                columns={columns}
                data={data}
                options={options}
                onDelete={onDelete}
                extraActions={(row) => (
                    <>
                        <IconButton
                            style={{ padding: 6 }}
                            aria-label='Ver parte de trabajo'
                            onClick={toggleDrawerJornada(row)}
                        >
                            <VisibilityIcon fontSize='small' />
                        </IconButton>
                    </>
                )}
            />
        </>
    );
}
