import { Route, Switch } from 'react-router-dom';
import EditPresupuestoForm from './EditPresupuestoForm';
import PresupuestosList from './PresupuestosList';

export default function PresupuestosModule() {
    return (
        <>
            <Switch>
                <Route
                    path='/presupuestos/:id'
                    render={({ match }) => <EditPresupuestoForm basePath='/presupuestos' match={match} />}
                />
                <Route path='/presupuestos'>
                    <PresupuestosList />
                </Route>
            </Switch>
        </>
    );
}
