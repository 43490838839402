import { Typography } from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import SearchIcon from '@material-ui/icons/Search';
import { useEffect, useState } from 'react';
import { clientesProvider, tareasProvider } from '../../../api';
import { usePermisos } from '../../../AuthState';
import { Tab, Tabs } from '../../common/Tabs';
import ServicioEstadoChip from '../../servicios/ServicioEstadoChip';
import { EstadoFilter } from './EstadoFilter';
import SearchResults from './SearchResults';

const Dialog = withStyles(
    (theme) => ({
        paper: {
            width: 688,
            marginTop: 100,
        },
        container: {
            alignItems: 'flex-start',
        },
        paperScrollPaper: {
            maxHeight: 'calc(100% - 164px)',
        },
    }),
    { name: 'Dialog' },
)(MuiDialog);

const useStyles = makeStyles(
    (theme) => ({
        searchIcon: {
            color: 'white',
        },
        searchBar: {
            padding: theme.spacing(2),
            color: theme.palette.text.primary,
            borderBottom: `1px solid ${theme.palette.neutral.grey2}`,
            fontSize: 24,
            '& svg': {
                fontSize: 32,
                color: theme.palette.neutral.grey4,
            },
        },
        tabsRoot: {
            flexShrink: 0,
            margin: 0,
            padding: theme.spacing(2, 1.5),
            backgroundColor: theme.palette.neutral.grey3,
        },
        tabsContainer: {
            gap: `${theme.spacing(2)}px`,
        },
        filters: {
            padding: theme.spacing(0.5, 2, 2, 2),
            backgroundColor: theme.palette.neutral.grey3,
        },
        ejemplos: {
            padding: theme.spacing(2),
            color: theme.palette.neutral.grey4,
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            '& > div': {
                display: 'flex',
                flexDirection: 'column',
                gap: `${theme.spacing(1)}px`,
            },
        },
    }),
    { name: 'SearchBar' },
);

const TAREAS = 'tareas';
const CLIENTES = 'clientes';

function tareaResultsToList(results) {
    if (!results || !results.tareas) return [];

    return results.tareas.map((tarea) => {
        const numero =
            tarea.referencia_interna && tarea.referencia_interna !== ''
                ? tarea.referencia_interna
                : tarea.numero
                ? tarea.numero.toString().padStart(4, '0')
                : 'N/A';

        const primaryText = `${numero} | ${tarea.descripcion}`;

        let secondaryText = tarea.cliente;
        if (tarea.direccion !== 'Principal') secondaryText += ` | ${tarea.direccion}`;

        return {
            id: tarea.id,
            primary: primaryText,
            secondary: secondaryText,
            url: `/servicios/${tarea.id}`,
            chip: <ServicioEstadoChip value={tarea.estado} />,
        };
    });
}

function clienteResultsToList(results) {
    if (!results || !results.clientes) return [];

    return results.clientes.map((cliente) => {
        let primaryText = cliente.nombre;
        if (cliente.codigo && cliente.codigo !== '') primaryText = `${cliente.codigo} | ${primaryText}`;

        let secondaryText = cliente.direccion;
        if (cliente.direccion_secundaria) secondaryText += ` ${cliente.direccion_secundaria}`;
        if (cliente.poblacion) secondaryText += `, ${cliente.poblacion}`;
        if (cliente.provincia) secondaryText += ` (${cliente.provincia})`;

        return {
            id: cliente.id,
            primary: primaryText,
            secondary: secondaryText,
            url: `/clientes/${cliente.id}`,
            chip: null,
        };
    });
}

const placeholderByTipo = {
    [TAREAS]: 'Busca servicios por referencia, descripción o cliente',
    [CLIENTES]: 'Busca clientes por nombre, código o dirección',
};

const ejemplosByTipo = {
    [TAREAS]: ['Corte Inglés, reparar aire', 'Instalación aire, Barcelona', '0325'],
    [CLIENTES]: ['Corte Inglés, Diagonal', 'Balmes, Barcelona', 'Bar 08080'],
};

export default function SearchBar() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [searchMode, setSearchMode] = useState(TAREAS);
    const [estadoFilter, setEstadoFilter] = useState([]);
    const gestionarClientes = usePermisos('gestionar_clientes');

    useEffect(() => {
        if (query === '') {
            setResults([]);
            return;
        }

        const promise =
            searchMode === TAREAS ? tareasProvider.search(query, estadoFilter) : clientesProvider.search(query);
        promise.then((results) => setResults(transformFn(results)));
    }, [query, estadoFilter]);

    useEffect(() => {
        setResults([]);
        setQuery('');
    }, [searchMode]);

    useEffect(() => {
        if (open) setQuery('');
    }, [open]);

    const transformFn = searchMode === TAREAS ? tareaResultsToList : clienteResultsToList;

    return (
        <>
            <IconButton className={classes.searchIcon} onClick={() => setOpen(true)}>
                <SearchIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                classes={{
                    paper: classes.dialog,
                }}
                fullWidth={false}
                maxWidth='lg'
            >
                <InputBase
                    autoFocus
                    placeholder={placeholderByTipo[searchMode]}
                    fullWidth
                    endAdornment={<SearchIcon />}
                    className={classes.searchBar}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                />
                {gestionarClientes && (
                    <Tabs
                        disableGutters
                        value={searchMode}
                        onChange={(ev, newValue) => setSearchMode(newValue)}
                        aria-label='Tipo de búsqueda'
                        classes={{
                            root: classes.tabsRoot,
                            flexContainer: classes.tabsContainer,
                        }}
                    >
                        <Tab label='Servicios' value={TAREAS} />
                        <Tab label='Clientes' value={CLIENTES} />
                    </Tabs>
                )}
                {searchMode === TAREAS && (
                    <div className={classes.filters}>
                        <EstadoFilter onFilterChange={setEstadoFilter} filterValue={estadoFilter} />
                    </div>
                )}
                <SearchResults results={results} onResultClick={() => setOpen(false)} query={query} />
                {query === '' && (
                    <div className={classes.ejemplos}>
                        <div>
                            <Typography variant='body2'>Ejemplos:</Typography>
                        </div>
                        <div>
                            {ejemplosByTipo[searchMode].map((ejemplo, i) => (
                                <Typography key={i} variant='body2'>
                                    {ejemplo}
                                </Typography>
                            ))}
                        </div>
                    </div>
                )}
            </Dialog>
        </>
    );
}
