import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import ErrorIcon from '@material-ui/icons/Error';
import VisitaConfirmadaIcon from '@material-ui/icons/EventAvailable';
import isPast from 'date-fns/isPast';
import PropTypes from 'prop-types';
import React from 'react';
import { formatDate, formatDateTime, formatTiempo } from '../../utils';
import EditPlanificacionDialog from '../calendario_planificacion/EditPlanificacionDialog';
import { TareaActions } from '../clientes/TareaActions';
import { eventIcons } from './constants';
import OperarioInfo from './OperarioInfo';
import UrgenteChip from './UrgenteChip';

const useStyles = makeStyles(
    (theme) => ({
        tooltip: {
            color: 'white',
            backgroundColor: theme.palette.neutral.hudBackground,
            opacity: 1,
            padding: theme.spacing(1),
            width: 'fit-content',
            minWidth: 460,
        },
        tooltipArrow: {
            color: theme.palette.neutral.hudBackground,
        },
        popper: {
            zIndex: 999,
            '&[x-placement*="bottom"] $arrow': {
                top: 0,
                left: 0,
                marginTop: '-0.9em',
                width: '3em',
                height: '1em',
                '&::before': {
                    borderWidth: '0 1em 1em 1em',
                    borderColor: `transparent transparent ${theme.palette.neutral.hudBackground} transparent`,
                },
            },
        },
        arrow: {
            position: 'absolute',
            fontSize: 7,
            width: '3em',
            height: '3em',
            '&::before': {
                content: '""',
                margin: 'auto',
                display: 'block',
                width: 0,
                height: 0,
                borderStyle: 'solid',
            },
        },
        label: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                color: theme.palette.neutral.primary,
                paddingLeft: theme.spacing(0.5),
            },
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                fontSize: 18,
            },
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightBold,
                flex: 1,
                marginLeft: theme.spacing(1),
            },
            '& .MuiIconButton-root': {
                color: 'white',
            },
        },
        button: {
            color: 'white',
        },
        info: {
            paddingLeft: theme.spacing(3.5),
            marginTop: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
            '& .MuiTypography-root': {
                display: 'flex',
                alignItems: 'center',
                gap: `${theme.spacing(0.5)}px`,
            },
            '& .MuiIconButton-root': {
                color: 'white',
            },
        },
        error: {
            color: theme.palette.error.main,
            '&.MuiSvgIcon-root': {
                fontSize: 18,
            },
        },
        confirmada: {
            display: 'flex',
            gap: `${theme.spacing(0.5)}px`,
            borderRadius: 12,
            height: 24,
            border: `1px solid ${theme.palette.neutral.grey4}`,
            padding: theme.spacing(0, 0.75, 0, 0.5),
            marginLeft: theme.spacing(1),
            alignItems: 'center',
            '& svg': {
                fontSize: 16,
            },
        },
        operariosList: {
            display: 'flex',
            flexDirection: 'column',
        },
    }),
    { name: 'ServicioInfoTooltip' },
);

export default function ServicioInfoTooltip({ event, onClose, TareaActionsProps }) {
    const classes = useStyles();
    const [arrowRef, setArrowRef] = React.useState(null);

    const contextMenuOpen = Boolean(event);
    const contextMenuId = contextMenuOpen ? 'simple-popover' : undefined;

    const tarea = event ? event.tarea : {};

    const Icon = eventIcons[tarea.icon];

    const title = tarea.type === 'agenda' ? 'Planificación' : 'Parte de trabajo';

    const formatFn = tarea.allDay ? formatDate : formatDateTime;

    const isParteTrabajo = tarea.type === 'jornada';

    return (
        <Popper
            id={contextMenuId}
            open={contextMenuOpen}
            anchorEl={event ? event.anchorEl : null}
            role={undefined}
            modifiers={{
                preventOverflow: {
                    enabled: true,
                    boundariesElement: 'scrollParent',
                },
                arrow: {
                    enabled: true,
                    element: arrowRef,
                },
                offset: {
                    enabled: true,
                    offset: '0, 10',
                },
            }}
            placement='bottom'
            className={classes.popper}
        >
            <span className={classes.arrow} ref={setArrowRef} />
            <Paper className={classes.tooltip}>
                <ClickAwayListener
                    onClickAway={(ev) => {
                        if (document.getElementById('root').contains(ev.target)) onClose(ev);
                    }}
                >
                    <div className={classes.wrapper}>
                        <div className={classes.header}>
                            {Icon && <Icon />}
                            <Typography variant='subtitle2'>
                                {title} del servicio {tarea.original?.numero}
                            </Typography>
                            {tarea.urgente && <UrgenteChip size='small' label='Urgente' icon={<ErrorIcon />} />}
                            <TareaActions
                                tarea={tarea.original}
                                {...TareaActionsProps}
                                canEdit={!isParteTrabajo}
                                jornadaId={isParteTrabajo ? tarea.jornadaId : null}
                                planificacionId={!isParteTrabajo ? tarea.planificacionId : null}
                            />
                        </div>
                        <div className={classes.info}>
                            {tarea.original?.referencia_interna && (
                                <Typography variant='subtitle2'>
                                    <strong>Ref. interna: </strong> {tarea.original?.referencia_interna}
                                </Typography>
                            )}
                            <Typography variant='subtitle2'>
                                <strong>Servicio: </strong> {tarea.descripcion}
                            </Typography>
                            <Typography variant='subtitle2'>
                                <strong>Cliente: </strong> {tarea.cliente}
                            </Typography>
                            {tarea.direccion && (
                                <Typography variant='subtitle2'>
                                    <strong>Dirección: </strong> {tarea.direccion}
                                </Typography>
                            )}
                            {tarea.tiempo && (
                                <Typography variant='subtitle2'>
                                    <strong>Mano de obra: </strong> {formatTiempo(tarea.tiempo)}
                                </Typography>
                            )}
                            {tarea.type === 'agenda' && tarea.start && (
                                <>
                                    <Typography variant='subtitle2'>
                                        <strong>Planificación: </strong> Del {formatFn(tarea.start)} al{' '}
                                        <span className={isPast(tarea.end) ? classes.error : null}>
                                            {formatFn(tarea.end)}
                                        </span>
                                        {tarea.confirmada && (
                                            <span className={classes.confirmada}>
                                                <VisitaConfirmadaIcon /> Confirmada
                                            </span>
                                        )}
                                        <EditPlanificacionDialog
                                            tarea={{
                                                id: tarea.id,
                                                cliente: tarea.cliente,
                                                descripcion: tarea.descripcion,
                                            }}
                                            planificacionId={tarea.planificacionId}
                                            onSave={() => TareaActionsProps.onTareaChanged(tarea.id)}
                                            button={
                                                <IconButton size='small'>
                                                    <EditIcon fontSize='small' />
                                                </IconButton>
                                            }
                                        />
                                    </Typography>
                                    {tarea.operarios ? (
                                        <Typography variant='subtitle2' component='div'>
                                            <strong style={{ alignSelf: 'flex-start' }}>Operarios: </strong>
                                            <div className={classes.operariosList}>
                                                {tarea.operarios.length === 0 && 'Sin asignar'}
                                                {tarea.operarios.map((operario, i) => (
                                                    <OperarioInfo key={i} operario={operario} />
                                                ))}
                                            </div>
                                        </Typography>
                                    ) : (
                                        <Typography variant='subtitle2'>
                                            <strong>Operario: </strong>
                                            <OperarioInfo operario={tarea} />
                                        </Typography>
                                    )}
                                </>
                            )}
                            {tarea.vehiculo && (
                                <Typography variant='subtitle2'>
                                    <strong>Vehículo: </strong> {tarea.vehiculo.nombre}
                                </Typography>
                            )}
                        </div>
                    </div>
                </ClickAwayListener>
            </Paper>
        </Popper>
    );
}

ServicioInfoTooltip.propTypes = {
    TareaActionsProps: PropTypes.any,
    event: PropTypes.any,
    onClose: PropTypes.any,
};
