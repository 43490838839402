import { useSnackbar } from 'material-ui-snackbar-provider';
import { useEffect } from 'react';
import { useServiceWorker } from '../../utils/useServiceWorker';

export default function ServiceWorkerUpdateChecker() {
    const { waitingWorker, showReload, reloadPage } = useServiceWorker();
    const snackbar = useSnackbar();

    // decides when to show the toast
    useEffect(() => {
        if (showReload && waitingWorker) {
            snackbar.showMessage('Hay una nueva versión', 'Actualizar', () => reloadPage(), {
                showCloseButton: true,
            });
        }
    }, [waitingWorker, showReload, reloadPage]);

    return null;
}
