import Typography from '@material-ui/core/Typography';
import SeccionDetails from './SeccionDetails';
import Paper from '@material-ui/core/Paper';
import { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        cuestionario: {
            display: 'flex',
            flexDirection: 'column',
            '& .MuiTable-root:last-of-type .MuiTableBody-root .MuiTableRow-root:last-of-type .MuiTableCell-root': {
                borderBottom: 'none',
            },
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            lineHeight: '50px',
            paddingLeft: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
            '& strong': {
                marginRight: theme.spacing(0.5),
                fontWeight: theme.typography.fontWeightMedium,
            },
            '&.expanded': {
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
        },
        expandButton: {
            marginLeft: 'auto',
            marginRight: theme.spacing(1),
        },
        secciones: {
            display: 'flex',
            flexDirection: 'column',
        },
    }),
    { name: 'CuestionarioDetails' },
);

export default function CuestionarioDetails({ cuestionario, respuestas }) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(true);

    const toggleExpanded = () => setExpanded((expanded) => !expanded);

    return (
        <Paper key={cuestionario.id} elevation={0} className={classes.cuestionario}>
            <Typography variant='body2' className={clsx(classes.title, { expanded })}>
                <strong>Cuestionario:</strong>
                {cuestionario.nombre}
                <IconButton className={classes.expandButton} onClick={toggleExpanded}>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Typography>
            <Collapse in={expanded}>
                <div className={classes.secciones}>
                    {cuestionario.secciones &&
                        cuestionario.secciones.map((seccion) => (
                            <SeccionDetails key={seccion.id} seccion={seccion} respuestas={respuestas} />
                        ))}
                </div>
            </Collapse>
        </Paper>
    );
}

CuestionarioDetails.propTypes = {
    cuestionario: PropTypes.any,
    respuestas: PropTypes.any,
};
